import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  json: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: Maybe<Scalars["Boolean"]>;
  _gt?: Maybe<Scalars["Boolean"]>;
  _gte?: Maybe<Scalars["Boolean"]>;
  _in?: Maybe<Array<Scalars["Boolean"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Boolean"]>;
  _lte?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<Scalars["Boolean"]>;
  _nin?: Maybe<Array<Scalars["Boolean"]>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: Maybe<Scalars["Int"]>;
  _gt?: Maybe<Scalars["Int"]>;
  _gte?: Maybe<Scalars["Int"]>;
  _in?: Maybe<Array<Scalars["Int"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Int"]>;
  _lte?: Maybe<Scalars["Int"]>;
  _neq?: Maybe<Scalars["Int"]>;
  _nin?: Maybe<Array<Scalars["Int"]>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: Maybe<Scalars["String"]>;
  _gt?: Maybe<Scalars["String"]>;
  _gte?: Maybe<Scalars["String"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars["String"]>;
  _in?: Maybe<Array<Scalars["String"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars["String"]>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars["String"]>;
  _lt?: Maybe<Scalars["String"]>;
  _lte?: Maybe<Scalars["String"]>;
  _neq?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars["String"]>;
  _nin?: Maybe<Array<Scalars["String"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars["String"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars["String"]>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "async_job_summaries" */
export type AsyncJobSummaries = {
  created_at: Scalars["timestamptz"];
  date: Scalars["date"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  metadata_info: Scalars["json"];
  name: Scalars["String"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "async_job_summaries" */
export type AsyncJobSummariesMetadataInfoArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "async_job_summaries" */
export type AsyncJobSummariesAggregate = {
  aggregate?: Maybe<AsyncJobSummariesAggregateFields>;
  nodes: Array<AsyncJobSummaries>;
};

/** aggregate fields of "async_job_summaries" */
export type AsyncJobSummariesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<AsyncJobSummariesMaxFields>;
  min?: Maybe<AsyncJobSummariesMinFields>;
};

/** aggregate fields of "async_job_summaries" */
export type AsyncJobSummariesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AsyncJobSummariesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "async_job_summaries". All fields are combined with a logical 'AND'. */
export type AsyncJobSummariesBoolExp = {
  _and?: Maybe<Array<AsyncJobSummariesBoolExp>>;
  _not?: Maybe<AsyncJobSummariesBoolExp>;
  _or?: Maybe<Array<AsyncJobSummariesBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  metadata_info?: Maybe<JsonComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "async_job_summaries" */
export enum AsyncJobSummariesConstraint {
  /** unique or primary key constraint */
  AsyncJobSummariesPkey = "async_job_summaries_pkey",
}

/** input type for inserting data into table "async_job_summaries" */
export type AsyncJobSummariesInsertInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  date?: Maybe<Scalars["date"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  metadata_info?: Maybe<Scalars["json"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type AsyncJobSummariesMaxFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  date?: Maybe<Scalars["date"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type AsyncJobSummariesMinFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  date?: Maybe<Scalars["date"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "async_job_summaries" */
export type AsyncJobSummariesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<AsyncJobSummaries>;
};

/** on_conflict condition type for table "async_job_summaries" */
export type AsyncJobSummariesOnConflict = {
  constraint: AsyncJobSummariesConstraint;
  update_columns?: Array<AsyncJobSummariesUpdateColumn>;
  where?: Maybe<AsyncJobSummariesBoolExp>;
};

/** Ordering options when selecting data from "async_job_summaries". */
export type AsyncJobSummariesOrderBy = {
  created_at?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  metadata_info?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: async_job_summaries */
export type AsyncJobSummariesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "async_job_summaries" */
export enum AsyncJobSummariesSelectColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Date = "date",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  MetadataInfo = "metadata_info",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "async_job_summaries" */
export type AsyncJobSummariesSetInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  date?: Maybe<Scalars["date"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  metadata_info?: Maybe<Scalars["json"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "async_job_summaries" */
export enum AsyncJobSummariesUpdateColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Date = "date",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  MetadataInfo = "metadata_info",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

/** columns and relationships of "async_jobs" */
export type AsyncJobs = {
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  ended_at?: Maybe<Scalars["timestamptz"]>;
  err_details?: Maybe<Scalars["json"]>;
  id: Scalars["uuid"];
  initialized_at?: Maybe<Scalars["timestamptz"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  is_high_priority: Scalars["Boolean"];
  job_payload?: Maybe<Scalars["json"]>;
  name: Scalars["String"];
  num_retries: Scalars["Int"];
  queued_at: Scalars["timestamptz"];
  retry_payload?: Maybe<Scalars["json"]>;
  started_at?: Maybe<Scalars["timestamptz"]>;
  status: Scalars["String"];
  /** An object relationship */
  submitted_by_user?: Maybe<Users>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "async_jobs" */
export type AsyncJobsErrDetailsArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "async_jobs" */
export type AsyncJobsJobPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "async_jobs" */
export type AsyncJobsRetryPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "async_jobs" */
export type AsyncJobsAggregate = {
  aggregate?: Maybe<AsyncJobsAggregateFields>;
  nodes: Array<AsyncJobs>;
};

/** aggregate fields of "async_jobs" */
export type AsyncJobsAggregateFields = {
  avg?: Maybe<AsyncJobsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<AsyncJobsMaxFields>;
  min?: Maybe<AsyncJobsMinFields>;
  stddev?: Maybe<AsyncJobsStddevFields>;
  stddev_pop?: Maybe<AsyncJobsStddevPopFields>;
  stddev_samp?: Maybe<AsyncJobsStddevSampFields>;
  sum?: Maybe<AsyncJobsSumFields>;
  var_pop?: Maybe<AsyncJobsVarPopFields>;
  var_samp?: Maybe<AsyncJobsVarSampFields>;
  variance?: Maybe<AsyncJobsVarianceFields>;
};

/** aggregate fields of "async_jobs" */
export type AsyncJobsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AsyncJobsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type AsyncJobsAvgFields = {
  num_retries?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "async_jobs". All fields are combined with a logical 'AND'. */
export type AsyncJobsBoolExp = {
  _and?: Maybe<Array<AsyncJobsBoolExp>>;
  _not?: Maybe<AsyncJobsBoolExp>;
  _or?: Maybe<Array<AsyncJobsBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  ended_at?: Maybe<TimestamptzComparisonExp>;
  err_details?: Maybe<JsonComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  initialized_at?: Maybe<TimestamptzComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  is_high_priority?: Maybe<BooleanComparisonExp>;
  job_payload?: Maybe<JsonComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  num_retries?: Maybe<IntComparisonExp>;
  queued_at?: Maybe<TimestamptzComparisonExp>;
  retry_payload?: Maybe<JsonComparisonExp>;
  started_at?: Maybe<TimestamptzComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  submitted_by_user?: Maybe<UsersBoolExp>;
  submitted_by_user_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "async_jobs" */
export enum AsyncJobsConstraint {
  /** unique or primary key constraint */
  AsyncJobsPkey = "async_jobs_pkey",
}

/** input type for incrementing numeric columns in table "async_jobs" */
export type AsyncJobsIncInput = {
  num_retries?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "async_jobs" */
export type AsyncJobsInsertInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  ended_at?: Maybe<Scalars["timestamptz"]>;
  err_details?: Maybe<Scalars["json"]>;
  id?: Maybe<Scalars["uuid"]>;
  initialized_at?: Maybe<Scalars["timestamptz"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  is_high_priority?: Maybe<Scalars["Boolean"]>;
  job_payload?: Maybe<Scalars["json"]>;
  name?: Maybe<Scalars["String"]>;
  num_retries?: Maybe<Scalars["Int"]>;
  queued_at?: Maybe<Scalars["timestamptz"]>;
  retry_payload?: Maybe<Scalars["json"]>;
  started_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  submitted_by_user?: Maybe<UsersObjRelInsertInput>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type AsyncJobsMaxFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  ended_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  initialized_at?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  num_retries?: Maybe<Scalars["Int"]>;
  queued_at?: Maybe<Scalars["timestamptz"]>;
  started_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type AsyncJobsMinFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  ended_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  initialized_at?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  num_retries?: Maybe<Scalars["Int"]>;
  queued_at?: Maybe<Scalars["timestamptz"]>;
  started_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "async_jobs" */
export type AsyncJobsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<AsyncJobs>;
};

/** on_conflict condition type for table "async_jobs" */
export type AsyncJobsOnConflict = {
  constraint: AsyncJobsConstraint;
  update_columns?: Array<AsyncJobsUpdateColumn>;
  where?: Maybe<AsyncJobsBoolExp>;
};

/** Ordering options when selecting data from "async_jobs". */
export type AsyncJobsOrderBy = {
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  ended_at?: Maybe<OrderBy>;
  err_details?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  initialized_at?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  is_high_priority?: Maybe<OrderBy>;
  job_payload?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  num_retries?: Maybe<OrderBy>;
  queued_at?: Maybe<OrderBy>;
  retry_payload?: Maybe<OrderBy>;
  started_at?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  submitted_by_user?: Maybe<UsersOrderBy>;
  submitted_by_user_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: async_jobs */
export type AsyncJobsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "async_jobs" */
export enum AsyncJobsSelectColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  EndedAt = "ended_at",
  /** column name */
  ErrDetails = "err_details",
  /** column name */
  Id = "id",
  /** column name */
  InitializedAt = "initialized_at",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  IsHighPriority = "is_high_priority",
  /** column name */
  JobPayload = "job_payload",
  /** column name */
  Name = "name",
  /** column name */
  NumRetries = "num_retries",
  /** column name */
  QueuedAt = "queued_at",
  /** column name */
  RetryPayload = "retry_payload",
  /** column name */
  StartedAt = "started_at",
  /** column name */
  Status = "status",
  /** column name */
  SubmittedByUserId = "submitted_by_user_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "async_jobs" */
export type AsyncJobsSetInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  ended_at?: Maybe<Scalars["timestamptz"]>;
  err_details?: Maybe<Scalars["json"]>;
  id?: Maybe<Scalars["uuid"]>;
  initialized_at?: Maybe<Scalars["timestamptz"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  is_high_priority?: Maybe<Scalars["Boolean"]>;
  job_payload?: Maybe<Scalars["json"]>;
  name?: Maybe<Scalars["String"]>;
  num_retries?: Maybe<Scalars["Int"]>;
  queued_at?: Maybe<Scalars["timestamptz"]>;
  retry_payload?: Maybe<Scalars["json"]>;
  started_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type AsyncJobsStddevFields = {
  num_retries?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type AsyncJobsStddevPopFields = {
  num_retries?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type AsyncJobsStddevSampFields = {
  num_retries?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type AsyncJobsSumFields = {
  num_retries?: Maybe<Scalars["Int"]>;
};

/** update columns of table "async_jobs" */
export enum AsyncJobsUpdateColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  EndedAt = "ended_at",
  /** column name */
  ErrDetails = "err_details",
  /** column name */
  Id = "id",
  /** column name */
  InitializedAt = "initialized_at",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  IsHighPriority = "is_high_priority",
  /** column name */
  JobPayload = "job_payload",
  /** column name */
  Name = "name",
  /** column name */
  NumRetries = "num_retries",
  /** column name */
  QueuedAt = "queued_at",
  /** column name */
  RetryPayload = "retry_payload",
  /** column name */
  StartedAt = "started_at",
  /** column name */
  Status = "status",
  /** column name */
  SubmittedByUserId = "submitted_by_user_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type AsyncJobsVarPopFields = {
  num_retries?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type AsyncJobsVarSampFields = {
  num_retries?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type AsyncJobsVarianceFields = {
  num_retries?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "async_pipelines" */
export type AsyncPipelines = {
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  internal_state: Scalars["json"];
  name: Scalars["String"];
  params: Scalars["json"];
  status: Scalars["String"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "async_pipelines" */
export type AsyncPipelinesInternalStateArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "async_pipelines" */
export type AsyncPipelinesParamsArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "async_pipelines" */
export type AsyncPipelinesAggregate = {
  aggregate?: Maybe<AsyncPipelinesAggregateFields>;
  nodes: Array<AsyncPipelines>;
};

/** aggregate fields of "async_pipelines" */
export type AsyncPipelinesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<AsyncPipelinesMaxFields>;
  min?: Maybe<AsyncPipelinesMinFields>;
};

/** aggregate fields of "async_pipelines" */
export type AsyncPipelinesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AsyncPipelinesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "async_pipelines". All fields are combined with a logical 'AND'. */
export type AsyncPipelinesBoolExp = {
  _and?: Maybe<Array<AsyncPipelinesBoolExp>>;
  _not?: Maybe<AsyncPipelinesBoolExp>;
  _or?: Maybe<Array<AsyncPipelinesBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  internal_state?: Maybe<JsonComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  params?: Maybe<JsonComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "async_pipelines" */
export enum AsyncPipelinesConstraint {
  /** unique or primary key constraint */
  AsyncPipelinesPkey = "async_pipelines_pkey",
}

/** input type for inserting data into table "async_pipelines" */
export type AsyncPipelinesInsertInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  internal_state?: Maybe<Scalars["json"]>;
  name?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["json"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type AsyncPipelinesMaxFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type AsyncPipelinesMinFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "async_pipelines" */
export type AsyncPipelinesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<AsyncPipelines>;
};

/** on_conflict condition type for table "async_pipelines" */
export type AsyncPipelinesOnConflict = {
  constraint: AsyncPipelinesConstraint;
  update_columns?: Array<AsyncPipelinesUpdateColumn>;
  where?: Maybe<AsyncPipelinesBoolExp>;
};

/** Ordering options when selecting data from "async_pipelines". */
export type AsyncPipelinesOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  internal_state?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  params?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: async_pipelines */
export type AsyncPipelinesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "async_pipelines" */
export enum AsyncPipelinesSelectColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InternalState = "internal_state",
  /** column name */
  Name = "name",
  /** column name */
  Params = "params",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "async_pipelines" */
export type AsyncPipelinesSetInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  internal_state?: Maybe<Scalars["json"]>;
  name?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["json"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "async_pipelines" */
export enum AsyncPipelinesUpdateColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InternalState = "internal_state",
  /** column name */
  Name = "name",
  /** column name */
  Params = "params",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
}

/** Capture information about business actions */
export type AuditEvents = {
  action: Scalars["String"];
  company_id?: Maybe<Scalars["uuid"]>;
  created_at: Scalars["timestamptz"];
  data?: Maybe<Scalars["jsonb"]>;
  error?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  is_system: Scalars["Boolean"];
  outcome?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** Capture information about business actions */
export type AuditEventsDataArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "audit_events" */
export type AuditEventsAggregate = {
  aggregate?: Maybe<AuditEventsAggregateFields>;
  nodes: Array<AuditEvents>;
};

/** aggregate fields of "audit_events" */
export type AuditEventsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<AuditEventsMaxFields>;
  min?: Maybe<AuditEventsMinFields>;
};

/** aggregate fields of "audit_events" */
export type AuditEventsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AuditEventsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AuditEventsAppendInput = {
  data?: Maybe<Scalars["jsonb"]>;
};

/** Boolean expression to filter rows from the table "audit_events". All fields are combined with a logical 'AND'. */
export type AuditEventsBoolExp = {
  _and?: Maybe<Array<AuditEventsBoolExp>>;
  _not?: Maybe<AuditEventsBoolExp>;
  _or?: Maybe<Array<AuditEventsBoolExp>>;
  action?: Maybe<StringComparisonExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  data?: Maybe<JsonbComparisonExp>;
  error?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_system?: Maybe<BooleanComparisonExp>;
  outcome?: Maybe<StringComparisonExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "audit_events" */
export enum AuditEventsConstraint {
  /** unique or primary key constraint */
  AuditEventsPkey = "audit_events_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AuditEventsDeleteAtPathInput = {
  data?: Maybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AuditEventsDeleteElemInput = {
  data?: Maybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AuditEventsDeleteKeyInput = {
  data?: Maybe<Scalars["String"]>;
};

/** input type for inserting data into table "audit_events" */
export type AuditEventsInsertInput = {
  action?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  data?: Maybe<Scalars["jsonb"]>;
  error?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_system?: Maybe<Scalars["Boolean"]>;
  outcome?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type AuditEventsMaxFields = {
  action?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  error?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  outcome?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type AuditEventsMinFields = {
  action?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  error?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  outcome?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "audit_events" */
export type AuditEventsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<AuditEvents>;
};

/** on_conflict condition type for table "audit_events" */
export type AuditEventsOnConflict = {
  constraint: AuditEventsConstraint;
  update_columns?: Array<AuditEventsUpdateColumn>;
  where?: Maybe<AuditEventsBoolExp>;
};

/** Ordering options when selecting data from "audit_events". */
export type AuditEventsOrderBy = {
  action?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  data?: Maybe<OrderBy>;
  error?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_system?: Maybe<OrderBy>;
  outcome?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: audit_events */
export type AuditEventsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AuditEventsPrependInput = {
  data?: Maybe<Scalars["jsonb"]>;
};

/** select columns of table "audit_events" */
export enum AuditEventsSelectColumn {
  /** column name */
  Action = "action",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Data = "data",
  /** column name */
  Error = "error",
  /** column name */
  Id = "id",
  /** column name */
  IsSystem = "is_system",
  /** column name */
  Outcome = "outcome",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "audit_events" */
export type AuditEventsSetInput = {
  action?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  data?: Maybe<Scalars["jsonb"]>;
  error?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_system?: Maybe<Scalars["Boolean"]>;
  outcome?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "audit_events" */
export enum AuditEventsUpdateColumn {
  /** column name */
  Action = "action",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Data = "data",
  /** column name */
  Error = "error",
  /** column name */
  Id = "id",
  /** column name */
  IsSystem = "is_system",
  /** column name */
  Outcome = "outcome",
  /** column name */
  UserId = "user_id",
}

/** columns and relationships of "bank_accounts" */
export type BankAccounts = {
  account_number: Scalars["String"];
  account_title?: Maybe<Scalars["String"]>;
  account_type: Scalars["String"];
  ach_default_memo?: Maybe<Scalars["String"]>;
  /** An array relationship */
  assigned_companies_for_advances_in_settings: Array<CompanySettings>;
  /** An aggregate relationship */
  assigned_companies_for_advances_in_settings_aggregate: CompanySettingsAggregate;
  /** An array relationship */
  assigned_companies_for_collection_in_settings: Array<CompanySettings>;
  /** An aggregate relationship */
  assigned_companies_for_collection_in_settings_aggregate: CompanySettingsAggregate;
  bank_address?: Maybe<Scalars["String"]>;
  /** An object relationship */
  bank_instructions?: Maybe<Files>;
  bank_instructions_file_id?: Maybe<Scalars["uuid"]>;
  bank_name: Scalars["String"];
  can_ach: Scalars["Boolean"];
  can_wire: Scalars["Boolean"];
  /** An object relationship */
  company?: Maybe<Companies>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  intermediary_account_name?: Maybe<Scalars["String"]>;
  intermediary_account_number?: Maybe<Scalars["String"]>;
  intermediary_bank_address?: Maybe<Scalars["String"]>;
  intermediary_bank_name?: Maybe<Scalars["String"]>;
  is_bank_international?: Maybe<Scalars["Boolean"]>;
  is_cannabis_compliant: Scalars["Boolean"];
  is_deleted?: Maybe<Scalars["Boolean"]>;
  is_wire_intermediary?: Maybe<Scalars["Boolean"]>;
  recipient_address?: Maybe<Scalars["String"]>;
  recipient_address_2?: Maybe<Scalars["String"]>;
  recipient_name?: Maybe<Scalars["String"]>;
  routing_number?: Maybe<Scalars["String"]>;
  torrey_pines_template_name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
  verified_at?: Maybe<Scalars["timestamptz"]>;
  verified_date?: Maybe<Scalars["date"]>;
  wire_default_memo?: Maybe<Scalars["String"]>;
  wire_routing_number?: Maybe<Scalars["String"]>;
  wire_template_name?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "bank_accounts" */
export type BankAccountsAssignedCompaniesForAdvancesInSettingsArgs = {
  distinct_on?: Maybe<Array<CompanySettingsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanySettingsOrderBy>>;
  where?: Maybe<CompanySettingsBoolExp>;
};

/** columns and relationships of "bank_accounts" */
export type BankAccountsAssignedCompaniesForAdvancesInSettingsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanySettingsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanySettingsOrderBy>>;
  where?: Maybe<CompanySettingsBoolExp>;
};

/** columns and relationships of "bank_accounts" */
export type BankAccountsAssignedCompaniesForCollectionInSettingsArgs = {
  distinct_on?: Maybe<Array<CompanySettingsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanySettingsOrderBy>>;
  where?: Maybe<CompanySettingsBoolExp>;
};

/** columns and relationships of "bank_accounts" */
export type BankAccountsAssignedCompaniesForCollectionInSettingsAggregateArgs =
  {
    distinct_on?: Maybe<Array<CompanySettingsSelectColumn>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<CompanySettingsOrderBy>>;
    where?: Maybe<CompanySettingsBoolExp>;
  };

/** aggregated selection of "bank_accounts" */
export type BankAccountsAggregate = {
  aggregate?: Maybe<BankAccountsAggregateFields>;
  nodes: Array<BankAccounts>;
};

/** aggregate fields of "bank_accounts" */
export type BankAccountsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<BankAccountsMaxFields>;
  min?: Maybe<BankAccountsMinFields>;
};

/** aggregate fields of "bank_accounts" */
export type BankAccountsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BankAccountsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "bank_accounts" */
export type BankAccountsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<BankAccountsMaxOrderBy>;
  min?: Maybe<BankAccountsMinOrderBy>;
};

/** input type for inserting array relation for remote table "bank_accounts" */
export type BankAccountsArrRelInsertInput = {
  data: Array<BankAccountsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<BankAccountsOnConflict>;
};

/** Boolean expression to filter rows from the table "bank_accounts". All fields are combined with a logical 'AND'. */
export type BankAccountsBoolExp = {
  _and?: Maybe<Array<BankAccountsBoolExp>>;
  _not?: Maybe<BankAccountsBoolExp>;
  _or?: Maybe<Array<BankAccountsBoolExp>>;
  account_number?: Maybe<StringComparisonExp>;
  account_title?: Maybe<StringComparisonExp>;
  account_type?: Maybe<StringComparisonExp>;
  ach_default_memo?: Maybe<StringComparisonExp>;
  assigned_companies_for_advances_in_settings?: Maybe<CompanySettingsBoolExp>;
  assigned_companies_for_collection_in_settings?: Maybe<CompanySettingsBoolExp>;
  bank_address?: Maybe<StringComparisonExp>;
  bank_instructions?: Maybe<FilesBoolExp>;
  bank_instructions_file_id?: Maybe<UuidComparisonExp>;
  bank_name?: Maybe<StringComparisonExp>;
  can_ach?: Maybe<BooleanComparisonExp>;
  can_wire?: Maybe<BooleanComparisonExp>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  intermediary_account_name?: Maybe<StringComparisonExp>;
  intermediary_account_number?: Maybe<StringComparisonExp>;
  intermediary_bank_address?: Maybe<StringComparisonExp>;
  intermediary_bank_name?: Maybe<StringComparisonExp>;
  is_bank_international?: Maybe<BooleanComparisonExp>;
  is_cannabis_compliant?: Maybe<BooleanComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  is_wire_intermediary?: Maybe<BooleanComparisonExp>;
  recipient_address?: Maybe<StringComparisonExp>;
  recipient_address_2?: Maybe<StringComparisonExp>;
  recipient_name?: Maybe<StringComparisonExp>;
  routing_number?: Maybe<StringComparisonExp>;
  torrey_pines_template_name?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  us_state?: Maybe<StringComparisonExp>;
  verified_at?: Maybe<TimestamptzComparisonExp>;
  verified_date?: Maybe<DateComparisonExp>;
  wire_default_memo?: Maybe<StringComparisonExp>;
  wire_routing_number?: Maybe<StringComparisonExp>;
  wire_template_name?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "bank_accounts" */
export enum BankAccountsConstraint {
  /** unique or primary key constraint */
  CompanyBanksPkey = "company_banks_pkey",
}

/** input type for inserting data into table "bank_accounts" */
export type BankAccountsInsertInput = {
  account_number?: Maybe<Scalars["String"]>;
  account_title?: Maybe<Scalars["String"]>;
  account_type?: Maybe<Scalars["String"]>;
  ach_default_memo?: Maybe<Scalars["String"]>;
  assigned_companies_for_advances_in_settings?: Maybe<CompanySettingsArrRelInsertInput>;
  assigned_companies_for_collection_in_settings?: Maybe<CompanySettingsArrRelInsertInput>;
  bank_address?: Maybe<Scalars["String"]>;
  bank_instructions?: Maybe<FilesObjRelInsertInput>;
  bank_instructions_file_id?: Maybe<Scalars["uuid"]>;
  bank_name?: Maybe<Scalars["String"]>;
  can_ach?: Maybe<Scalars["Boolean"]>;
  can_wire?: Maybe<Scalars["Boolean"]>;
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  intermediary_account_name?: Maybe<Scalars["String"]>;
  intermediary_account_number?: Maybe<Scalars["String"]>;
  intermediary_bank_address?: Maybe<Scalars["String"]>;
  intermediary_bank_name?: Maybe<Scalars["String"]>;
  is_bank_international?: Maybe<Scalars["Boolean"]>;
  is_cannabis_compliant?: Maybe<Scalars["Boolean"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  is_wire_intermediary?: Maybe<Scalars["Boolean"]>;
  recipient_address?: Maybe<Scalars["String"]>;
  recipient_address_2?: Maybe<Scalars["String"]>;
  recipient_name?: Maybe<Scalars["String"]>;
  routing_number?: Maybe<Scalars["String"]>;
  torrey_pines_template_name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
  verified_at?: Maybe<Scalars["timestamptz"]>;
  verified_date?: Maybe<Scalars["date"]>;
  wire_default_memo?: Maybe<Scalars["String"]>;
  wire_routing_number?: Maybe<Scalars["String"]>;
  wire_template_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type BankAccountsMaxFields = {
  account_number?: Maybe<Scalars["String"]>;
  account_title?: Maybe<Scalars["String"]>;
  account_type?: Maybe<Scalars["String"]>;
  ach_default_memo?: Maybe<Scalars["String"]>;
  bank_address?: Maybe<Scalars["String"]>;
  bank_instructions_file_id?: Maybe<Scalars["uuid"]>;
  bank_name?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  intermediary_account_name?: Maybe<Scalars["String"]>;
  intermediary_account_number?: Maybe<Scalars["String"]>;
  intermediary_bank_address?: Maybe<Scalars["String"]>;
  intermediary_bank_name?: Maybe<Scalars["String"]>;
  recipient_address?: Maybe<Scalars["String"]>;
  recipient_address_2?: Maybe<Scalars["String"]>;
  recipient_name?: Maybe<Scalars["String"]>;
  routing_number?: Maybe<Scalars["String"]>;
  torrey_pines_template_name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
  verified_at?: Maybe<Scalars["timestamptz"]>;
  verified_date?: Maybe<Scalars["date"]>;
  wire_default_memo?: Maybe<Scalars["String"]>;
  wire_routing_number?: Maybe<Scalars["String"]>;
  wire_template_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "bank_accounts" */
export type BankAccountsMaxOrderBy = {
  account_number?: Maybe<OrderBy>;
  account_title?: Maybe<OrderBy>;
  account_type?: Maybe<OrderBy>;
  ach_default_memo?: Maybe<OrderBy>;
  bank_address?: Maybe<OrderBy>;
  bank_instructions_file_id?: Maybe<OrderBy>;
  bank_name?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  intermediary_account_name?: Maybe<OrderBy>;
  intermediary_account_number?: Maybe<OrderBy>;
  intermediary_bank_address?: Maybe<OrderBy>;
  intermediary_bank_name?: Maybe<OrderBy>;
  recipient_address?: Maybe<OrderBy>;
  recipient_address_2?: Maybe<OrderBy>;
  recipient_name?: Maybe<OrderBy>;
  routing_number?: Maybe<OrderBy>;
  torrey_pines_template_name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
  verified_at?: Maybe<OrderBy>;
  verified_date?: Maybe<OrderBy>;
  wire_default_memo?: Maybe<OrderBy>;
  wire_routing_number?: Maybe<OrderBy>;
  wire_template_name?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type BankAccountsMinFields = {
  account_number?: Maybe<Scalars["String"]>;
  account_title?: Maybe<Scalars["String"]>;
  account_type?: Maybe<Scalars["String"]>;
  ach_default_memo?: Maybe<Scalars["String"]>;
  bank_address?: Maybe<Scalars["String"]>;
  bank_instructions_file_id?: Maybe<Scalars["uuid"]>;
  bank_name?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  intermediary_account_name?: Maybe<Scalars["String"]>;
  intermediary_account_number?: Maybe<Scalars["String"]>;
  intermediary_bank_address?: Maybe<Scalars["String"]>;
  intermediary_bank_name?: Maybe<Scalars["String"]>;
  recipient_address?: Maybe<Scalars["String"]>;
  recipient_address_2?: Maybe<Scalars["String"]>;
  recipient_name?: Maybe<Scalars["String"]>;
  routing_number?: Maybe<Scalars["String"]>;
  torrey_pines_template_name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
  verified_at?: Maybe<Scalars["timestamptz"]>;
  verified_date?: Maybe<Scalars["date"]>;
  wire_default_memo?: Maybe<Scalars["String"]>;
  wire_routing_number?: Maybe<Scalars["String"]>;
  wire_template_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "bank_accounts" */
export type BankAccountsMinOrderBy = {
  account_number?: Maybe<OrderBy>;
  account_title?: Maybe<OrderBy>;
  account_type?: Maybe<OrderBy>;
  ach_default_memo?: Maybe<OrderBy>;
  bank_address?: Maybe<OrderBy>;
  bank_instructions_file_id?: Maybe<OrderBy>;
  bank_name?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  intermediary_account_name?: Maybe<OrderBy>;
  intermediary_account_number?: Maybe<OrderBy>;
  intermediary_bank_address?: Maybe<OrderBy>;
  intermediary_bank_name?: Maybe<OrderBy>;
  recipient_address?: Maybe<OrderBy>;
  recipient_address_2?: Maybe<OrderBy>;
  recipient_name?: Maybe<OrderBy>;
  routing_number?: Maybe<OrderBy>;
  torrey_pines_template_name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
  verified_at?: Maybe<OrderBy>;
  verified_date?: Maybe<OrderBy>;
  wire_default_memo?: Maybe<OrderBy>;
  wire_routing_number?: Maybe<OrderBy>;
  wire_template_name?: Maybe<OrderBy>;
};

/** response of any mutation on the table "bank_accounts" */
export type BankAccountsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<BankAccounts>;
};

/** input type for inserting object relation for remote table "bank_accounts" */
export type BankAccountsObjRelInsertInput = {
  data: BankAccountsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<BankAccountsOnConflict>;
};

/** on_conflict condition type for table "bank_accounts" */
export type BankAccountsOnConflict = {
  constraint: BankAccountsConstraint;
  update_columns?: Array<BankAccountsUpdateColumn>;
  where?: Maybe<BankAccountsBoolExp>;
};

/** Ordering options when selecting data from "bank_accounts". */
export type BankAccountsOrderBy = {
  account_number?: Maybe<OrderBy>;
  account_title?: Maybe<OrderBy>;
  account_type?: Maybe<OrderBy>;
  ach_default_memo?: Maybe<OrderBy>;
  assigned_companies_for_advances_in_settings_aggregate?: Maybe<CompanySettingsAggregateOrderBy>;
  assigned_companies_for_collection_in_settings_aggregate?: Maybe<CompanySettingsAggregateOrderBy>;
  bank_address?: Maybe<OrderBy>;
  bank_instructions?: Maybe<FilesOrderBy>;
  bank_instructions_file_id?: Maybe<OrderBy>;
  bank_name?: Maybe<OrderBy>;
  can_ach?: Maybe<OrderBy>;
  can_wire?: Maybe<OrderBy>;
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  intermediary_account_name?: Maybe<OrderBy>;
  intermediary_account_number?: Maybe<OrderBy>;
  intermediary_bank_address?: Maybe<OrderBy>;
  intermediary_bank_name?: Maybe<OrderBy>;
  is_bank_international?: Maybe<OrderBy>;
  is_cannabis_compliant?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  is_wire_intermediary?: Maybe<OrderBy>;
  recipient_address?: Maybe<OrderBy>;
  recipient_address_2?: Maybe<OrderBy>;
  recipient_name?: Maybe<OrderBy>;
  routing_number?: Maybe<OrderBy>;
  torrey_pines_template_name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
  verified_at?: Maybe<OrderBy>;
  verified_date?: Maybe<OrderBy>;
  wire_default_memo?: Maybe<OrderBy>;
  wire_routing_number?: Maybe<OrderBy>;
  wire_template_name?: Maybe<OrderBy>;
};

/** primary key columns input for table: bank_accounts */
export type BankAccountsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "bank_accounts" */
export enum BankAccountsSelectColumn {
  /** column name */
  AccountNumber = "account_number",
  /** column name */
  AccountTitle = "account_title",
  /** column name */
  AccountType = "account_type",
  /** column name */
  AchDefaultMemo = "ach_default_memo",
  /** column name */
  BankAddress = "bank_address",
  /** column name */
  BankInstructionsFileId = "bank_instructions_file_id",
  /** column name */
  BankName = "bank_name",
  /** column name */
  CanAch = "can_ach",
  /** column name */
  CanWire = "can_wire",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IntermediaryAccountName = "intermediary_account_name",
  /** column name */
  IntermediaryAccountNumber = "intermediary_account_number",
  /** column name */
  IntermediaryBankAddress = "intermediary_bank_address",
  /** column name */
  IntermediaryBankName = "intermediary_bank_name",
  /** column name */
  IsBankInternational = "is_bank_international",
  /** column name */
  IsCannabisCompliant = "is_cannabis_compliant",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  IsWireIntermediary = "is_wire_intermediary",
  /** column name */
  RecipientAddress = "recipient_address",
  /** column name */
  RecipientAddress_2 = "recipient_address_2",
  /** column name */
  RecipientName = "recipient_name",
  /** column name */
  RoutingNumber = "routing_number",
  /** column name */
  TorreyPinesTemplateName = "torrey_pines_template_name",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
  /** column name */
  VerifiedAt = "verified_at",
  /** column name */
  VerifiedDate = "verified_date",
  /** column name */
  WireDefaultMemo = "wire_default_memo",
  /** column name */
  WireRoutingNumber = "wire_routing_number",
  /** column name */
  WireTemplateName = "wire_template_name",
}

/** input type for updating data in table "bank_accounts" */
export type BankAccountsSetInput = {
  account_number?: Maybe<Scalars["String"]>;
  account_title?: Maybe<Scalars["String"]>;
  account_type?: Maybe<Scalars["String"]>;
  ach_default_memo?: Maybe<Scalars["String"]>;
  bank_address?: Maybe<Scalars["String"]>;
  bank_instructions_file_id?: Maybe<Scalars["uuid"]>;
  bank_name?: Maybe<Scalars["String"]>;
  can_ach?: Maybe<Scalars["Boolean"]>;
  can_wire?: Maybe<Scalars["Boolean"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  intermediary_account_name?: Maybe<Scalars["String"]>;
  intermediary_account_number?: Maybe<Scalars["String"]>;
  intermediary_bank_address?: Maybe<Scalars["String"]>;
  intermediary_bank_name?: Maybe<Scalars["String"]>;
  is_bank_international?: Maybe<Scalars["Boolean"]>;
  is_cannabis_compliant?: Maybe<Scalars["Boolean"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  is_wire_intermediary?: Maybe<Scalars["Boolean"]>;
  recipient_address?: Maybe<Scalars["String"]>;
  recipient_address_2?: Maybe<Scalars["String"]>;
  recipient_name?: Maybe<Scalars["String"]>;
  routing_number?: Maybe<Scalars["String"]>;
  torrey_pines_template_name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
  verified_at?: Maybe<Scalars["timestamptz"]>;
  verified_date?: Maybe<Scalars["date"]>;
  wire_default_memo?: Maybe<Scalars["String"]>;
  wire_routing_number?: Maybe<Scalars["String"]>;
  wire_template_name?: Maybe<Scalars["String"]>;
};

/** update columns of table "bank_accounts" */
export enum BankAccountsUpdateColumn {
  /** column name */
  AccountNumber = "account_number",
  /** column name */
  AccountTitle = "account_title",
  /** column name */
  AccountType = "account_type",
  /** column name */
  AchDefaultMemo = "ach_default_memo",
  /** column name */
  BankAddress = "bank_address",
  /** column name */
  BankInstructionsFileId = "bank_instructions_file_id",
  /** column name */
  BankName = "bank_name",
  /** column name */
  CanAch = "can_ach",
  /** column name */
  CanWire = "can_wire",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IntermediaryAccountName = "intermediary_account_name",
  /** column name */
  IntermediaryAccountNumber = "intermediary_account_number",
  /** column name */
  IntermediaryBankAddress = "intermediary_bank_address",
  /** column name */
  IntermediaryBankName = "intermediary_bank_name",
  /** column name */
  IsBankInternational = "is_bank_international",
  /** column name */
  IsCannabisCompliant = "is_cannabis_compliant",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  IsWireIntermediary = "is_wire_intermediary",
  /** column name */
  RecipientAddress = "recipient_address",
  /** column name */
  RecipientAddress_2 = "recipient_address_2",
  /** column name */
  RecipientName = "recipient_name",
  /** column name */
  RoutingNumber = "routing_number",
  /** column name */
  TorreyPinesTemplateName = "torrey_pines_template_name",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
  /** column name */
  VerifiedAt = "verified_at",
  /** column name */
  VerifiedDate = "verified_date",
  /** column name */
  WireDefaultMemo = "wire_default_memo",
  /** column name */
  WireRoutingNumber = "wire_routing_number",
  /** column name */
  WireTemplateName = "wire_template_name",
}

/** This is the financial summary for the bank, where each row represents one day for a particular product type */
export type BankFinancialSummaries = {
  adjusted_total_limit?: Maybe<Scalars["numeric"]>;
  available_limit: Scalars["numeric"];
  created_at: Scalars["timestamptz"];
  date: Scalars["date"];
  id: Scalars["uuid"];
  interest_accrued_today?: Maybe<Scalars["numeric"]>;
  late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  product_type: Scalars["String"];
  total_limit: Scalars["numeric"];
  total_outstanding_fees: Scalars["numeric"];
  total_outstanding_interest: Scalars["numeric"];
  total_outstanding_principal: Scalars["numeric"];
  total_outstanding_principal_for_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["numeric"]>;
  total_principal_in_requested_state: Scalars["numeric"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "bank_financial_summaries" */
export type BankFinancialSummariesAggregate = {
  aggregate?: Maybe<BankFinancialSummariesAggregateFields>;
  nodes: Array<BankFinancialSummaries>;
};

/** aggregate fields of "bank_financial_summaries" */
export type BankFinancialSummariesAggregateFields = {
  avg?: Maybe<BankFinancialSummariesAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<BankFinancialSummariesMaxFields>;
  min?: Maybe<BankFinancialSummariesMinFields>;
  stddev?: Maybe<BankFinancialSummariesStddevFields>;
  stddev_pop?: Maybe<BankFinancialSummariesStddevPopFields>;
  stddev_samp?: Maybe<BankFinancialSummariesStddevSampFields>;
  sum?: Maybe<BankFinancialSummariesSumFields>;
  var_pop?: Maybe<BankFinancialSummariesVarPopFields>;
  var_samp?: Maybe<BankFinancialSummariesVarSampFields>;
  variance?: Maybe<BankFinancialSummariesVarianceFields>;
};

/** aggregate fields of "bank_financial_summaries" */
export type BankFinancialSummariesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BankFinancialSummariesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type BankFinancialSummariesAvgFields = {
  adjusted_total_limit?: Maybe<Scalars["Float"]>;
  available_limit?: Maybe<Scalars["Float"]>;
  interest_accrued_today?: Maybe<Scalars["Float"]>;
  late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  total_limit?: Maybe<Scalars["Float"]>;
  total_outstanding_fees?: Maybe<Scalars["Float"]>;
  total_outstanding_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["Float"]>;
  total_principal_in_requested_state?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "bank_financial_summaries". All fields are combined with a logical 'AND'. */
export type BankFinancialSummariesBoolExp = {
  _and?: Maybe<Array<BankFinancialSummariesBoolExp>>;
  _not?: Maybe<BankFinancialSummariesBoolExp>;
  _or?: Maybe<Array<BankFinancialSummariesBoolExp>>;
  adjusted_total_limit?: Maybe<NumericComparisonExp>;
  available_limit?: Maybe<NumericComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  interest_accrued_today?: Maybe<NumericComparisonExp>;
  late_fees_accrued_today?: Maybe<NumericComparisonExp>;
  product_type?: Maybe<StringComparisonExp>;
  total_limit?: Maybe<NumericComparisonExp>;
  total_outstanding_fees?: Maybe<NumericComparisonExp>;
  total_outstanding_interest?: Maybe<NumericComparisonExp>;
  total_outstanding_principal?: Maybe<NumericComparisonExp>;
  total_outstanding_principal_for_interest?: Maybe<NumericComparisonExp>;
  total_outstanding_principal_past_due?: Maybe<NumericComparisonExp>;
  total_principal_in_requested_state?: Maybe<NumericComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "bank_financial_summaries" */
export enum BankFinancialSummariesConstraint {
  /** unique or primary key constraint */
  BankFinancialSummariesDateProductTypeKey = "bank_financial_summaries_date_product_type_key",
  /** unique or primary key constraint */
  BankFinancialSummariesPkey = "bank_financial_summaries_pkey",
}

/** input type for incrementing numeric columns in table "bank_financial_summaries" */
export type BankFinancialSummariesIncInput = {
  adjusted_total_limit?: Maybe<Scalars["numeric"]>;
  available_limit?: Maybe<Scalars["numeric"]>;
  interest_accrued_today?: Maybe<Scalars["numeric"]>;
  late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  total_limit?: Maybe<Scalars["numeric"]>;
  total_outstanding_fees?: Maybe<Scalars["numeric"]>;
  total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["numeric"]>;
  total_principal_in_requested_state?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "bank_financial_summaries" */
export type BankFinancialSummariesInsertInput = {
  adjusted_total_limit?: Maybe<Scalars["numeric"]>;
  available_limit?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  interest_accrued_today?: Maybe<Scalars["numeric"]>;
  late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  product_type?: Maybe<Scalars["String"]>;
  total_limit?: Maybe<Scalars["numeric"]>;
  total_outstanding_fees?: Maybe<Scalars["numeric"]>;
  total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["numeric"]>;
  total_principal_in_requested_state?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type BankFinancialSummariesMaxFields = {
  adjusted_total_limit?: Maybe<Scalars["numeric"]>;
  available_limit?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  interest_accrued_today?: Maybe<Scalars["numeric"]>;
  late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  product_type?: Maybe<Scalars["String"]>;
  total_limit?: Maybe<Scalars["numeric"]>;
  total_outstanding_fees?: Maybe<Scalars["numeric"]>;
  total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["numeric"]>;
  total_principal_in_requested_state?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type BankFinancialSummariesMinFields = {
  adjusted_total_limit?: Maybe<Scalars["numeric"]>;
  available_limit?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  interest_accrued_today?: Maybe<Scalars["numeric"]>;
  late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  product_type?: Maybe<Scalars["String"]>;
  total_limit?: Maybe<Scalars["numeric"]>;
  total_outstanding_fees?: Maybe<Scalars["numeric"]>;
  total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["numeric"]>;
  total_principal_in_requested_state?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "bank_financial_summaries" */
export type BankFinancialSummariesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<BankFinancialSummaries>;
};

/** on_conflict condition type for table "bank_financial_summaries" */
export type BankFinancialSummariesOnConflict = {
  constraint: BankFinancialSummariesConstraint;
  update_columns?: Array<BankFinancialSummariesUpdateColumn>;
  where?: Maybe<BankFinancialSummariesBoolExp>;
};

/** Ordering options when selecting data from "bank_financial_summaries". */
export type BankFinancialSummariesOrderBy = {
  adjusted_total_limit?: Maybe<OrderBy>;
  available_limit?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  interest_accrued_today?: Maybe<OrderBy>;
  late_fees_accrued_today?: Maybe<OrderBy>;
  product_type?: Maybe<OrderBy>;
  total_limit?: Maybe<OrderBy>;
  total_outstanding_fees?: Maybe<OrderBy>;
  total_outstanding_interest?: Maybe<OrderBy>;
  total_outstanding_principal?: Maybe<OrderBy>;
  total_outstanding_principal_for_interest?: Maybe<OrderBy>;
  total_outstanding_principal_past_due?: Maybe<OrderBy>;
  total_principal_in_requested_state?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: bank_financial_summaries */
export type BankFinancialSummariesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "bank_financial_summaries" */
export enum BankFinancialSummariesSelectColumn {
  /** column name */
  AdjustedTotalLimit = "adjusted_total_limit",
  /** column name */
  AvailableLimit = "available_limit",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Date = "date",
  /** column name */
  Id = "id",
  /** column name */
  InterestAccruedToday = "interest_accrued_today",
  /** column name */
  LateFeesAccruedToday = "late_fees_accrued_today",
  /** column name */
  ProductType = "product_type",
  /** column name */
  TotalLimit = "total_limit",
  /** column name */
  TotalOutstandingFees = "total_outstanding_fees",
  /** column name */
  TotalOutstandingInterest = "total_outstanding_interest",
  /** column name */
  TotalOutstandingPrincipal = "total_outstanding_principal",
  /** column name */
  TotalOutstandingPrincipalForInterest = "total_outstanding_principal_for_interest",
  /** column name */
  TotalOutstandingPrincipalPastDue = "total_outstanding_principal_past_due",
  /** column name */
  TotalPrincipalInRequestedState = "total_principal_in_requested_state",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "bank_financial_summaries" */
export type BankFinancialSummariesSetInput = {
  adjusted_total_limit?: Maybe<Scalars["numeric"]>;
  available_limit?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  interest_accrued_today?: Maybe<Scalars["numeric"]>;
  late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  product_type?: Maybe<Scalars["String"]>;
  total_limit?: Maybe<Scalars["numeric"]>;
  total_outstanding_fees?: Maybe<Scalars["numeric"]>;
  total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["numeric"]>;
  total_principal_in_requested_state?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type BankFinancialSummariesStddevFields = {
  adjusted_total_limit?: Maybe<Scalars["Float"]>;
  available_limit?: Maybe<Scalars["Float"]>;
  interest_accrued_today?: Maybe<Scalars["Float"]>;
  late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  total_limit?: Maybe<Scalars["Float"]>;
  total_outstanding_fees?: Maybe<Scalars["Float"]>;
  total_outstanding_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["Float"]>;
  total_principal_in_requested_state?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type BankFinancialSummariesStddevPopFields = {
  adjusted_total_limit?: Maybe<Scalars["Float"]>;
  available_limit?: Maybe<Scalars["Float"]>;
  interest_accrued_today?: Maybe<Scalars["Float"]>;
  late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  total_limit?: Maybe<Scalars["Float"]>;
  total_outstanding_fees?: Maybe<Scalars["Float"]>;
  total_outstanding_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["Float"]>;
  total_principal_in_requested_state?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type BankFinancialSummariesStddevSampFields = {
  adjusted_total_limit?: Maybe<Scalars["Float"]>;
  available_limit?: Maybe<Scalars["Float"]>;
  interest_accrued_today?: Maybe<Scalars["Float"]>;
  late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  total_limit?: Maybe<Scalars["Float"]>;
  total_outstanding_fees?: Maybe<Scalars["Float"]>;
  total_outstanding_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["Float"]>;
  total_principal_in_requested_state?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type BankFinancialSummariesSumFields = {
  adjusted_total_limit?: Maybe<Scalars["numeric"]>;
  available_limit?: Maybe<Scalars["numeric"]>;
  interest_accrued_today?: Maybe<Scalars["numeric"]>;
  late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  total_limit?: Maybe<Scalars["numeric"]>;
  total_outstanding_fees?: Maybe<Scalars["numeric"]>;
  total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["numeric"]>;
  total_principal_in_requested_state?: Maybe<Scalars["numeric"]>;
};

/** update columns of table "bank_financial_summaries" */
export enum BankFinancialSummariesUpdateColumn {
  /** column name */
  AdjustedTotalLimit = "adjusted_total_limit",
  /** column name */
  AvailableLimit = "available_limit",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Date = "date",
  /** column name */
  Id = "id",
  /** column name */
  InterestAccruedToday = "interest_accrued_today",
  /** column name */
  LateFeesAccruedToday = "late_fees_accrued_today",
  /** column name */
  ProductType = "product_type",
  /** column name */
  TotalLimit = "total_limit",
  /** column name */
  TotalOutstandingFees = "total_outstanding_fees",
  /** column name */
  TotalOutstandingInterest = "total_outstanding_interest",
  /** column name */
  TotalOutstandingPrincipal = "total_outstanding_principal",
  /** column name */
  TotalOutstandingPrincipalForInterest = "total_outstanding_principal_for_interest",
  /** column name */
  TotalOutstandingPrincipalPastDue = "total_outstanding_principal_past_due",
  /** column name */
  TotalPrincipalInRequestedState = "total_principal_in_requested_state",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type BankFinancialSummariesVarPopFields = {
  adjusted_total_limit?: Maybe<Scalars["Float"]>;
  available_limit?: Maybe<Scalars["Float"]>;
  interest_accrued_today?: Maybe<Scalars["Float"]>;
  late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  total_limit?: Maybe<Scalars["Float"]>;
  total_outstanding_fees?: Maybe<Scalars["Float"]>;
  total_outstanding_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["Float"]>;
  total_principal_in_requested_state?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type BankFinancialSummariesVarSampFields = {
  adjusted_total_limit?: Maybe<Scalars["Float"]>;
  available_limit?: Maybe<Scalars["Float"]>;
  interest_accrued_today?: Maybe<Scalars["Float"]>;
  late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  total_limit?: Maybe<Scalars["Float"]>;
  total_outstanding_fees?: Maybe<Scalars["Float"]>;
  total_outstanding_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["Float"]>;
  total_principal_in_requested_state?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type BankFinancialSummariesVarianceFields = {
  adjusted_total_limit?: Maybe<Scalars["Float"]>;
  available_limit?: Maybe<Scalars["Float"]>;
  interest_accrued_today?: Maybe<Scalars["Float"]>;
  late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  total_limit?: Maybe<Scalars["Float"]>;
  total_outstanding_fees?: Maybe<Scalars["Float"]>;
  total_outstanding_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["Float"]>;
  total_principal_in_requested_state?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "bespoke_catalog_brands" */
export type BespokeCatalogBrands = {
  /** An array relationship */
  bespoke_catalog_sku_groups: Array<BespokeCatalogSkuGroups>;
  /** An aggregate relationship */
  bespoke_catalog_sku_groups_aggregate: BespokeCatalogSkuGroupsAggregate;
  brand_name: Scalars["String"];
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  is_deleted: Scalars["Boolean"];
  /** An object relationship */
  parent_company?: Maybe<ParentCompanies>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  updated_at: Scalars["timestamptz"];
  website_url?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "bespoke_catalog_brands" */
export type BespokeCatalogBrandsBespokeCatalogSkuGroupsArgs = {
  distinct_on?: Maybe<Array<BespokeCatalogSkuGroupsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BespokeCatalogSkuGroupsOrderBy>>;
  where?: Maybe<BespokeCatalogSkuGroupsBoolExp>;
};

/** columns and relationships of "bespoke_catalog_brands" */
export type BespokeCatalogBrandsBespokeCatalogSkuGroupsAggregateArgs = {
  distinct_on?: Maybe<Array<BespokeCatalogSkuGroupsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BespokeCatalogSkuGroupsOrderBy>>;
  where?: Maybe<BespokeCatalogSkuGroupsBoolExp>;
};

/** aggregated selection of "bespoke_catalog_brands" */
export type BespokeCatalogBrandsAggregate = {
  aggregate?: Maybe<BespokeCatalogBrandsAggregateFields>;
  nodes: Array<BespokeCatalogBrands>;
};

/** aggregate fields of "bespoke_catalog_brands" */
export type BespokeCatalogBrandsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<BespokeCatalogBrandsMaxFields>;
  min?: Maybe<BespokeCatalogBrandsMinFields>;
};

/** aggregate fields of "bespoke_catalog_brands" */
export type BespokeCatalogBrandsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BespokeCatalogBrandsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "bespoke_catalog_brands". All fields are combined with a logical 'AND'. */
export type BespokeCatalogBrandsBoolExp = {
  _and?: Maybe<Array<BespokeCatalogBrandsBoolExp>>;
  _not?: Maybe<BespokeCatalogBrandsBoolExp>;
  _or?: Maybe<Array<BespokeCatalogBrandsBoolExp>>;
  bespoke_catalog_sku_groups?: Maybe<BespokeCatalogSkuGroupsBoolExp>;
  brand_name?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  parent_company?: Maybe<ParentCompaniesBoolExp>;
  parent_company_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  website_url?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "bespoke_catalog_brands" */
export enum BespokeCatalogBrandsConstraint {
  /** unique or primary key constraint */
  BespokeCatalogBrandsBrandNameKey = "bespoke_catalog_brands_brand_name_key",
  /** unique or primary key constraint */
  BespokeCatalogBrandsPkey = "bespoke_catalog_brands_pkey",
}

/** input type for inserting data into table "bespoke_catalog_brands" */
export type BespokeCatalogBrandsInsertInput = {
  bespoke_catalog_sku_groups?: Maybe<BespokeCatalogSkuGroupsArrRelInsertInput>;
  brand_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  parent_company?: Maybe<ParentCompaniesObjRelInsertInput>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  website_url?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type BespokeCatalogBrandsMaxFields = {
  brand_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  website_url?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type BespokeCatalogBrandsMinFields = {
  brand_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  website_url?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "bespoke_catalog_brands" */
export type BespokeCatalogBrandsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<BespokeCatalogBrands>;
};

/** input type for inserting object relation for remote table "bespoke_catalog_brands" */
export type BespokeCatalogBrandsObjRelInsertInput = {
  data: BespokeCatalogBrandsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<BespokeCatalogBrandsOnConflict>;
};

/** on_conflict condition type for table "bespoke_catalog_brands" */
export type BespokeCatalogBrandsOnConflict = {
  constraint: BespokeCatalogBrandsConstraint;
  update_columns?: Array<BespokeCatalogBrandsUpdateColumn>;
  where?: Maybe<BespokeCatalogBrandsBoolExp>;
};

/** Ordering options when selecting data from "bespoke_catalog_brands". */
export type BespokeCatalogBrandsOrderBy = {
  bespoke_catalog_sku_groups_aggregate?: Maybe<BespokeCatalogSkuGroupsAggregateOrderBy>;
  brand_name?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  parent_company?: Maybe<ParentCompaniesOrderBy>;
  parent_company_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  website_url?: Maybe<OrderBy>;
};

/** primary key columns input for table: bespoke_catalog_brands */
export type BespokeCatalogBrandsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "bespoke_catalog_brands" */
export enum BespokeCatalogBrandsSelectColumn {
  /** column name */
  BrandName = "brand_name",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  ParentCompanyId = "parent_company_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WebsiteUrl = "website_url",
}

/** input type for updating data in table "bespoke_catalog_brands" */
export type BespokeCatalogBrandsSetInput = {
  brand_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  website_url?: Maybe<Scalars["String"]>;
};

/** update columns of table "bespoke_catalog_brands" */
export enum BespokeCatalogBrandsUpdateColumn {
  /** column name */
  BrandName = "brand_name",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  ParentCompanyId = "parent_company_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WebsiteUrl = "website_url",
}

/** columns and relationships of "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroups = {
  /** An object relationship */
  bespoke_catalog_brand: BespokeCatalogBrands;
  bespoke_catalog_brand_id: Scalars["uuid"];
  /** An array relationship */
  bespoke_catalog_skus: Array<BespokeCatalogSkus>;
  /** An aggregate relationship */
  bespoke_catalog_skus_aggregate: BespokeCatalogSkusAggregate;
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamp"]>;
  id: Scalars["uuid"];
  is_deleted: Scalars["Boolean"];
  sku_group_name: Scalars["String"];
  unit_of_measure?: Maybe<Scalars["String"]>;
  unit_quantity?: Maybe<Scalars["numeric"]>;
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsBespokeCatalogSkusArgs = {
  distinct_on?: Maybe<Array<BespokeCatalogSkusSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BespokeCatalogSkusOrderBy>>;
  where?: Maybe<BespokeCatalogSkusBoolExp>;
};

/** columns and relationships of "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsBespokeCatalogSkusAggregateArgs = {
  distinct_on?: Maybe<Array<BespokeCatalogSkusSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BespokeCatalogSkusOrderBy>>;
  where?: Maybe<BespokeCatalogSkusBoolExp>;
};

/** aggregated selection of "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsAggregate = {
  aggregate?: Maybe<BespokeCatalogSkuGroupsAggregateFields>;
  nodes: Array<BespokeCatalogSkuGroups>;
};

/** aggregate fields of "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsAggregateFields = {
  avg?: Maybe<BespokeCatalogSkuGroupsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<BespokeCatalogSkuGroupsMaxFields>;
  min?: Maybe<BespokeCatalogSkuGroupsMinFields>;
  stddev?: Maybe<BespokeCatalogSkuGroupsStddevFields>;
  stddev_pop?: Maybe<BespokeCatalogSkuGroupsStddevPopFields>;
  stddev_samp?: Maybe<BespokeCatalogSkuGroupsStddevSampFields>;
  sum?: Maybe<BespokeCatalogSkuGroupsSumFields>;
  var_pop?: Maybe<BespokeCatalogSkuGroupsVarPopFields>;
  var_samp?: Maybe<BespokeCatalogSkuGroupsVarSampFields>;
  variance?: Maybe<BespokeCatalogSkuGroupsVarianceFields>;
};

/** aggregate fields of "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BespokeCatalogSkuGroupsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsAggregateOrderBy = {
  avg?: Maybe<BespokeCatalogSkuGroupsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<BespokeCatalogSkuGroupsMaxOrderBy>;
  min?: Maybe<BespokeCatalogSkuGroupsMinOrderBy>;
  stddev?: Maybe<BespokeCatalogSkuGroupsStddevOrderBy>;
  stddev_pop?: Maybe<BespokeCatalogSkuGroupsStddevPopOrderBy>;
  stddev_samp?: Maybe<BespokeCatalogSkuGroupsStddevSampOrderBy>;
  sum?: Maybe<BespokeCatalogSkuGroupsSumOrderBy>;
  var_pop?: Maybe<BespokeCatalogSkuGroupsVarPopOrderBy>;
  var_samp?: Maybe<BespokeCatalogSkuGroupsVarSampOrderBy>;
  variance?: Maybe<BespokeCatalogSkuGroupsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsArrRelInsertInput = {
  data: Array<BespokeCatalogSkuGroupsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<BespokeCatalogSkuGroupsOnConflict>;
};

/** aggregate avg on columns */
export type BespokeCatalogSkuGroupsAvgFields = {
  unit_quantity?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsAvgOrderBy = {
  unit_quantity?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "bespoke_catalog_sku_groups". All fields are combined with a logical 'AND'. */
export type BespokeCatalogSkuGroupsBoolExp = {
  _and?: Maybe<Array<BespokeCatalogSkuGroupsBoolExp>>;
  _not?: Maybe<BespokeCatalogSkuGroupsBoolExp>;
  _or?: Maybe<Array<BespokeCatalogSkuGroupsBoolExp>>;
  bespoke_catalog_brand?: Maybe<BespokeCatalogBrandsBoolExp>;
  bespoke_catalog_brand_id?: Maybe<UuidComparisonExp>;
  bespoke_catalog_skus?: Maybe<BespokeCatalogSkusBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  deleted_at?: Maybe<TimestampComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  sku_group_name?: Maybe<StringComparisonExp>;
  unit_of_measure?: Maybe<StringComparisonExp>;
  unit_quantity?: Maybe<NumericComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "bespoke_catalog_sku_groups" */
export enum BespokeCatalogSkuGroupsConstraint {
  /** unique or primary key constraint */
  BespokeCatalogSkuGroupsBespokeCatalogBrandIdSkuGroKey = "bespoke_catalog_sku_groups_bespoke_catalog_brand_id_sku_gro_key",
  /** unique or primary key constraint */
  BespokeCatalogSkuGroupsPkey = "bespoke_catalog_sku_groups_pkey",
}

/** input type for incrementing numeric columns in table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsIncInput = {
  unit_quantity?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsInsertInput = {
  bespoke_catalog_brand?: Maybe<BespokeCatalogBrandsObjRelInsertInput>;
  bespoke_catalog_brand_id?: Maybe<Scalars["uuid"]>;
  bespoke_catalog_skus?: Maybe<BespokeCatalogSkusArrRelInsertInput>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  sku_group_name?: Maybe<Scalars["String"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
  unit_quantity?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type BespokeCatalogSkuGroupsMaxFields = {
  bespoke_catalog_brand_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["uuid"]>;
  sku_group_name?: Maybe<Scalars["String"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
  unit_quantity?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsMaxOrderBy = {
  bespoke_catalog_brand_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sku_group_name?: Maybe<OrderBy>;
  unit_of_measure?: Maybe<OrderBy>;
  unit_quantity?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type BespokeCatalogSkuGroupsMinFields = {
  bespoke_catalog_brand_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["uuid"]>;
  sku_group_name?: Maybe<Scalars["String"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
  unit_quantity?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsMinOrderBy = {
  bespoke_catalog_brand_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sku_group_name?: Maybe<OrderBy>;
  unit_of_measure?: Maybe<OrderBy>;
  unit_quantity?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<BespokeCatalogSkuGroups>;
};

/** input type for inserting object relation for remote table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsObjRelInsertInput = {
  data: BespokeCatalogSkuGroupsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<BespokeCatalogSkuGroupsOnConflict>;
};

/** on_conflict condition type for table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsOnConflict = {
  constraint: BespokeCatalogSkuGroupsConstraint;
  update_columns?: Array<BespokeCatalogSkuGroupsUpdateColumn>;
  where?: Maybe<BespokeCatalogSkuGroupsBoolExp>;
};

/** Ordering options when selecting data from "bespoke_catalog_sku_groups". */
export type BespokeCatalogSkuGroupsOrderBy = {
  bespoke_catalog_brand?: Maybe<BespokeCatalogBrandsOrderBy>;
  bespoke_catalog_brand_id?: Maybe<OrderBy>;
  bespoke_catalog_skus_aggregate?: Maybe<BespokeCatalogSkusAggregateOrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  sku_group_name?: Maybe<OrderBy>;
  unit_of_measure?: Maybe<OrderBy>;
  unit_quantity?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: bespoke_catalog_sku_groups */
export type BespokeCatalogSkuGroupsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "bespoke_catalog_sku_groups" */
export enum BespokeCatalogSkuGroupsSelectColumn {
  /** column name */
  BespokeCatalogBrandId = "bespoke_catalog_brand_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  SkuGroupName = "sku_group_name",
  /** column name */
  UnitOfMeasure = "unit_of_measure",
  /** column name */
  UnitQuantity = "unit_quantity",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsSetInput = {
  bespoke_catalog_brand_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  sku_group_name?: Maybe<Scalars["String"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
  unit_quantity?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type BespokeCatalogSkuGroupsStddevFields = {
  unit_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsStddevOrderBy = {
  unit_quantity?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type BespokeCatalogSkuGroupsStddevPopFields = {
  unit_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsStddevPopOrderBy = {
  unit_quantity?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type BespokeCatalogSkuGroupsStddevSampFields = {
  unit_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsStddevSampOrderBy = {
  unit_quantity?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type BespokeCatalogSkuGroupsSumFields = {
  unit_quantity?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsSumOrderBy = {
  unit_quantity?: Maybe<OrderBy>;
};

/** update columns of table "bespoke_catalog_sku_groups" */
export enum BespokeCatalogSkuGroupsUpdateColumn {
  /** column name */
  BespokeCatalogBrandId = "bespoke_catalog_brand_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  SkuGroupName = "sku_group_name",
  /** column name */
  UnitOfMeasure = "unit_of_measure",
  /** column name */
  UnitQuantity = "unit_quantity",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type BespokeCatalogSkuGroupsVarPopFields = {
  unit_quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsVarPopOrderBy = {
  unit_quantity?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type BespokeCatalogSkuGroupsVarSampFields = {
  unit_quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsVarSampOrderBy = {
  unit_quantity?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type BespokeCatalogSkuGroupsVarianceFields = {
  unit_quantity?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "bespoke_catalog_sku_groups" */
export type BespokeCatalogSkuGroupsVarianceOrderBy = {
  unit_quantity?: Maybe<OrderBy>;
};

/** columns and relationships of "bespoke_catalog_skus" */
export type BespokeCatalogSkus = {
  /** An object relationship */
  bespoke_catalog_sku_group: BespokeCatalogSkuGroups;
  bespoke_catalog_sku_group_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  is_deleted: Scalars["Boolean"];
  link?: Maybe<Scalars["String"]>;
  /** An array relationship */
  metrc_to_bespoke_catalog_skus: Array<MetrcToBespokeCatalogSkus>;
  /** An aggregate relationship */
  metrc_to_bespoke_catalog_skus_aggregate: MetrcToBespokeCatalogSkusAggregate;
  picture?: Maybe<Scalars["String"]>;
  sku: Scalars["String"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "bespoke_catalog_skus" */
export type BespokeCatalogSkusMetrcToBespokeCatalogSkusArgs = {
  distinct_on?: Maybe<Array<MetrcToBespokeCatalogSkusSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcToBespokeCatalogSkusOrderBy>>;
  where?: Maybe<MetrcToBespokeCatalogSkusBoolExp>;
};

/** columns and relationships of "bespoke_catalog_skus" */
export type BespokeCatalogSkusMetrcToBespokeCatalogSkusAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcToBespokeCatalogSkusSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcToBespokeCatalogSkusOrderBy>>;
  where?: Maybe<MetrcToBespokeCatalogSkusBoolExp>;
};

/** aggregated selection of "bespoke_catalog_skus" */
export type BespokeCatalogSkusAggregate = {
  aggregate?: Maybe<BespokeCatalogSkusAggregateFields>;
  nodes: Array<BespokeCatalogSkus>;
};

/** aggregate fields of "bespoke_catalog_skus" */
export type BespokeCatalogSkusAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<BespokeCatalogSkusMaxFields>;
  min?: Maybe<BespokeCatalogSkusMinFields>;
};

/** aggregate fields of "bespoke_catalog_skus" */
export type BespokeCatalogSkusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BespokeCatalogSkusSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "bespoke_catalog_skus" */
export type BespokeCatalogSkusAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<BespokeCatalogSkusMaxOrderBy>;
  min?: Maybe<BespokeCatalogSkusMinOrderBy>;
};

/** input type for inserting array relation for remote table "bespoke_catalog_skus" */
export type BespokeCatalogSkusArrRelInsertInput = {
  data: Array<BespokeCatalogSkusInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<BespokeCatalogSkusOnConflict>;
};

/** Boolean expression to filter rows from the table "bespoke_catalog_skus". All fields are combined with a logical 'AND'. */
export type BespokeCatalogSkusBoolExp = {
  _and?: Maybe<Array<BespokeCatalogSkusBoolExp>>;
  _not?: Maybe<BespokeCatalogSkusBoolExp>;
  _or?: Maybe<Array<BespokeCatalogSkusBoolExp>>;
  bespoke_catalog_sku_group?: Maybe<BespokeCatalogSkuGroupsBoolExp>;
  bespoke_catalog_sku_group_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  link?: Maybe<StringComparisonExp>;
  metrc_to_bespoke_catalog_skus?: Maybe<MetrcToBespokeCatalogSkusBoolExp>;
  picture?: Maybe<StringComparisonExp>;
  sku?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "bespoke_catalog_skus" */
export enum BespokeCatalogSkusConstraint {
  /** unique or primary key constraint */
  BespokeCatalogSkusBespokeCatalogSkuGroupIdSkuKey = "bespoke_catalog_skus_bespoke_catalog_sku_group_id_sku_key",
  /** unique or primary key constraint */
  BespokeCatalogSkusPkey = "bespoke_catalog_skus_pkey",
  /** unique or primary key constraint */
  BespokeCatalogSkusSkuKey = "bespoke_catalog_skus_sku_key",
}

/** input type for inserting data into table "bespoke_catalog_skus" */
export type BespokeCatalogSkusInsertInput = {
  bespoke_catalog_sku_group?: Maybe<BespokeCatalogSkuGroupsObjRelInsertInput>;
  bespoke_catalog_sku_group_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  link?: Maybe<Scalars["String"]>;
  metrc_to_bespoke_catalog_skus?: Maybe<MetrcToBespokeCatalogSkusArrRelInsertInput>;
  picture?: Maybe<Scalars["String"]>;
  sku?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type BespokeCatalogSkusMaxFields = {
  bespoke_catalog_sku_group_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  link?: Maybe<Scalars["String"]>;
  picture?: Maybe<Scalars["String"]>;
  sku?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "bespoke_catalog_skus" */
export type BespokeCatalogSkusMaxOrderBy = {
  bespoke_catalog_sku_group_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  link?: Maybe<OrderBy>;
  picture?: Maybe<OrderBy>;
  sku?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type BespokeCatalogSkusMinFields = {
  bespoke_catalog_sku_group_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  link?: Maybe<Scalars["String"]>;
  picture?: Maybe<Scalars["String"]>;
  sku?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "bespoke_catalog_skus" */
export type BespokeCatalogSkusMinOrderBy = {
  bespoke_catalog_sku_group_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  link?: Maybe<OrderBy>;
  picture?: Maybe<OrderBy>;
  sku?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "bespoke_catalog_skus" */
export type BespokeCatalogSkusMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<BespokeCatalogSkus>;
};

/** input type for inserting object relation for remote table "bespoke_catalog_skus" */
export type BespokeCatalogSkusObjRelInsertInput = {
  data: BespokeCatalogSkusInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<BespokeCatalogSkusOnConflict>;
};

/** on_conflict condition type for table "bespoke_catalog_skus" */
export type BespokeCatalogSkusOnConflict = {
  constraint: BespokeCatalogSkusConstraint;
  update_columns?: Array<BespokeCatalogSkusUpdateColumn>;
  where?: Maybe<BespokeCatalogSkusBoolExp>;
};

/** Ordering options when selecting data from "bespoke_catalog_skus". */
export type BespokeCatalogSkusOrderBy = {
  bespoke_catalog_sku_group?: Maybe<BespokeCatalogSkuGroupsOrderBy>;
  bespoke_catalog_sku_group_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  link?: Maybe<OrderBy>;
  metrc_to_bespoke_catalog_skus_aggregate?: Maybe<MetrcToBespokeCatalogSkusAggregateOrderBy>;
  picture?: Maybe<OrderBy>;
  sku?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: bespoke_catalog_skus */
export type BespokeCatalogSkusPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "bespoke_catalog_skus" */
export enum BespokeCatalogSkusSelectColumn {
  /** column name */
  BespokeCatalogSkuGroupId = "bespoke_catalog_sku_group_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  Link = "link",
  /** column name */
  Picture = "picture",
  /** column name */
  Sku = "sku",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "bespoke_catalog_skus" */
export type BespokeCatalogSkusSetInput = {
  bespoke_catalog_sku_group_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  link?: Maybe<Scalars["String"]>;
  picture?: Maybe<Scalars["String"]>;
  sku?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "bespoke_catalog_skus" */
export enum BespokeCatalogSkusUpdateColumn {
  /** column name */
  BespokeCatalogSkuGroupId = "bespoke_catalog_sku_group_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  Link = "link",
  /** column name */
  Picture = "picture",
  /** column name */
  Sku = "sku",
  /** column name */
  UpdatedAt = "updated_at",
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: Maybe<Scalars["bigint"]>;
  _gt?: Maybe<Scalars["bigint"]>;
  _gte?: Maybe<Scalars["bigint"]>;
  _in?: Maybe<Array<Scalars["bigint"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["bigint"]>;
  _lte?: Maybe<Scalars["bigint"]>;
  _neq?: Maybe<Scalars["bigint"]>;
  _nin?: Maybe<Array<Scalars["bigint"]>>;
};

/** columns and relationships of "blaze_preapprovals" */
export type BlazePreapprovals = {
  annual_interest_rate: Scalars["numeric"];
  created_at: Scalars["timestamptz"];
  expiration_date: Scalars["date"];
  external_blaze_company_id: Scalars["String"];
  external_blaze_shop_id: Scalars["String"];
  id: Scalars["uuid"];
  max_credit_limit: Scalars["numeric"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "blaze_preapprovals" */
export type BlazePreapprovalsAggregate = {
  aggregate?: Maybe<BlazePreapprovalsAggregateFields>;
  nodes: Array<BlazePreapprovals>;
};

/** aggregate fields of "blaze_preapprovals" */
export type BlazePreapprovalsAggregateFields = {
  avg?: Maybe<BlazePreapprovalsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<BlazePreapprovalsMaxFields>;
  min?: Maybe<BlazePreapprovalsMinFields>;
  stddev?: Maybe<BlazePreapprovalsStddevFields>;
  stddev_pop?: Maybe<BlazePreapprovalsStddevPopFields>;
  stddev_samp?: Maybe<BlazePreapprovalsStddevSampFields>;
  sum?: Maybe<BlazePreapprovalsSumFields>;
  var_pop?: Maybe<BlazePreapprovalsVarPopFields>;
  var_samp?: Maybe<BlazePreapprovalsVarSampFields>;
  variance?: Maybe<BlazePreapprovalsVarianceFields>;
};

/** aggregate fields of "blaze_preapprovals" */
export type BlazePreapprovalsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BlazePreapprovalsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type BlazePreapprovalsAvgFields = {
  annual_interest_rate?: Maybe<Scalars["Float"]>;
  max_credit_limit?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "blaze_preapprovals". All fields are combined with a logical 'AND'. */
export type BlazePreapprovalsBoolExp = {
  _and?: Maybe<Array<BlazePreapprovalsBoolExp>>;
  _not?: Maybe<BlazePreapprovalsBoolExp>;
  _or?: Maybe<Array<BlazePreapprovalsBoolExp>>;
  annual_interest_rate?: Maybe<NumericComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  expiration_date?: Maybe<DateComparisonExp>;
  external_blaze_company_id?: Maybe<StringComparisonExp>;
  external_blaze_shop_id?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  max_credit_limit?: Maybe<NumericComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "blaze_preapprovals" */
export enum BlazePreapprovalsConstraint {
  /** unique or primary key constraint */
  BlazePreapprovalsExternalBlazeCompanyIdExternalBlazeKey = "blaze_preapprovals_external_blaze_company_id_external_blaze_key",
  /** unique or primary key constraint */
  BlazePreapprovalsPkey = "blaze_preapprovals_pkey",
}

/** input type for incrementing numeric columns in table "blaze_preapprovals" */
export type BlazePreapprovalsIncInput = {
  annual_interest_rate?: Maybe<Scalars["numeric"]>;
  max_credit_limit?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "blaze_preapprovals" */
export type BlazePreapprovalsInsertInput = {
  annual_interest_rate?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expiration_date?: Maybe<Scalars["date"]>;
  external_blaze_company_id?: Maybe<Scalars["String"]>;
  external_blaze_shop_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  max_credit_limit?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type BlazePreapprovalsMaxFields = {
  annual_interest_rate?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expiration_date?: Maybe<Scalars["date"]>;
  external_blaze_company_id?: Maybe<Scalars["String"]>;
  external_blaze_shop_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  max_credit_limit?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type BlazePreapprovalsMinFields = {
  annual_interest_rate?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expiration_date?: Maybe<Scalars["date"]>;
  external_blaze_company_id?: Maybe<Scalars["String"]>;
  external_blaze_shop_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  max_credit_limit?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "blaze_preapprovals" */
export type BlazePreapprovalsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<BlazePreapprovals>;
};

/** on_conflict condition type for table "blaze_preapprovals" */
export type BlazePreapprovalsOnConflict = {
  constraint: BlazePreapprovalsConstraint;
  update_columns?: Array<BlazePreapprovalsUpdateColumn>;
  where?: Maybe<BlazePreapprovalsBoolExp>;
};

/** Ordering options when selecting data from "blaze_preapprovals". */
export type BlazePreapprovalsOrderBy = {
  annual_interest_rate?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  expiration_date?: Maybe<OrderBy>;
  external_blaze_company_id?: Maybe<OrderBy>;
  external_blaze_shop_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  max_credit_limit?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: blaze_preapprovals */
export type BlazePreapprovalsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "blaze_preapprovals" */
export enum BlazePreapprovalsSelectColumn {
  /** column name */
  AnnualInterestRate = "annual_interest_rate",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExpirationDate = "expiration_date",
  /** column name */
  ExternalBlazeCompanyId = "external_blaze_company_id",
  /** column name */
  ExternalBlazeShopId = "external_blaze_shop_id",
  /** column name */
  Id = "id",
  /** column name */
  MaxCreditLimit = "max_credit_limit",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "blaze_preapprovals" */
export type BlazePreapprovalsSetInput = {
  annual_interest_rate?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expiration_date?: Maybe<Scalars["date"]>;
  external_blaze_company_id?: Maybe<Scalars["String"]>;
  external_blaze_shop_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  max_credit_limit?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type BlazePreapprovalsStddevFields = {
  annual_interest_rate?: Maybe<Scalars["Float"]>;
  max_credit_limit?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type BlazePreapprovalsStddevPopFields = {
  annual_interest_rate?: Maybe<Scalars["Float"]>;
  max_credit_limit?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type BlazePreapprovalsStddevSampFields = {
  annual_interest_rate?: Maybe<Scalars["Float"]>;
  max_credit_limit?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type BlazePreapprovalsSumFields = {
  annual_interest_rate?: Maybe<Scalars["numeric"]>;
  max_credit_limit?: Maybe<Scalars["numeric"]>;
};

/** update columns of table "blaze_preapprovals" */
export enum BlazePreapprovalsUpdateColumn {
  /** column name */
  AnnualInterestRate = "annual_interest_rate",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExpirationDate = "expiration_date",
  /** column name */
  ExternalBlazeCompanyId = "external_blaze_company_id",
  /** column name */
  ExternalBlazeShopId = "external_blaze_shop_id",
  /** column name */
  Id = "id",
  /** column name */
  MaxCreditLimit = "max_credit_limit",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type BlazePreapprovalsVarPopFields = {
  annual_interest_rate?: Maybe<Scalars["Float"]>;
  max_credit_limit?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type BlazePreapprovalsVarSampFields = {
  annual_interest_rate?: Maybe<Scalars["Float"]>;
  max_credit_limit?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type BlazePreapprovalsVarianceFields = {
  annual_interest_rate?: Maybe<Scalars["Float"]>;
  max_credit_limit?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "blaze_shop_entries" */
export type BlazeShopEntries = {
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  external_blaze_shop_id: Scalars["String"];
  id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "blaze_shop_entries" */
export type BlazeShopEntriesAggregate = {
  aggregate?: Maybe<BlazeShopEntriesAggregateFields>;
  nodes: Array<BlazeShopEntries>;
};

/** aggregate fields of "blaze_shop_entries" */
export type BlazeShopEntriesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<BlazeShopEntriesMaxFields>;
  min?: Maybe<BlazeShopEntriesMinFields>;
};

/** aggregate fields of "blaze_shop_entries" */
export type BlazeShopEntriesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BlazeShopEntriesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "blaze_shop_entries". All fields are combined with a logical 'AND'. */
export type BlazeShopEntriesBoolExp = {
  _and?: Maybe<Array<BlazeShopEntriesBoolExp>>;
  _not?: Maybe<BlazeShopEntriesBoolExp>;
  _or?: Maybe<Array<BlazeShopEntriesBoolExp>>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  external_blaze_shop_id?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "blaze_shop_entries" */
export enum BlazeShopEntriesConstraint {
  /** unique or primary key constraint */
  BlazeShopEntriesExternalBlazeShopIdKey = "blaze_shop_entries_external_blaze_shop_id_key",
  /** unique or primary key constraint */
  BlazeShopEntriesPkey = "blaze_shop_entries_pkey",
}

/** input type for inserting data into table "blaze_shop_entries" */
export type BlazeShopEntriesInsertInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_blaze_shop_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type BlazeShopEntriesMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_blaze_shop_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type BlazeShopEntriesMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_blaze_shop_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "blaze_shop_entries" */
export type BlazeShopEntriesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<BlazeShopEntries>;
};

/** on_conflict condition type for table "blaze_shop_entries" */
export type BlazeShopEntriesOnConflict = {
  constraint: BlazeShopEntriesConstraint;
  update_columns?: Array<BlazeShopEntriesUpdateColumn>;
  where?: Maybe<BlazeShopEntriesBoolExp>;
};

/** Ordering options when selecting data from "blaze_shop_entries". */
export type BlazeShopEntriesOrderBy = {
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  external_blaze_shop_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: blaze_shop_entries */
export type BlazeShopEntriesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "blaze_shop_entries" */
export enum BlazeShopEntriesSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExternalBlazeShopId = "external_blaze_shop_id",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "blaze_shop_entries" */
export type BlazeShopEntriesSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_blaze_shop_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "blaze_shop_entries" */
export enum BlazeShopEntriesUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExternalBlazeShopId = "external_blaze_shop_id",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** columns and relationships of "blaze_users" */
export type BlazeUsers = {
  created_at: Scalars["timestamptz"];
  external_blaze_user_id: Scalars["String"];
  id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  user_id: Scalars["uuid"];
};

/** aggregated selection of "blaze_users" */
export type BlazeUsersAggregate = {
  aggregate?: Maybe<BlazeUsersAggregateFields>;
  nodes: Array<BlazeUsers>;
};

/** aggregate fields of "blaze_users" */
export type BlazeUsersAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<BlazeUsersMaxFields>;
  min?: Maybe<BlazeUsersMinFields>;
};

/** aggregate fields of "blaze_users" */
export type BlazeUsersAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BlazeUsersSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "blaze_users". All fields are combined with a logical 'AND'. */
export type BlazeUsersBoolExp = {
  _and?: Maybe<Array<BlazeUsersBoolExp>>;
  _not?: Maybe<BlazeUsersBoolExp>;
  _or?: Maybe<Array<BlazeUsersBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  external_blaze_user_id?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "blaze_users" */
export enum BlazeUsersConstraint {
  /** unique or primary key constraint */
  BlazeUsersExternalBlazeUserIdKey = "blaze_users_external_blaze_user_id_key",
  /** unique or primary key constraint */
  BlazeUsersPkey = "blaze_users_pkey",
}

/** input type for inserting data into table "blaze_users" */
export type BlazeUsersInsertInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_blaze_user_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type BlazeUsersMaxFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_blaze_user_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type BlazeUsersMinFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_blaze_user_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "blaze_users" */
export type BlazeUsersMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<BlazeUsers>;
};

/** on_conflict condition type for table "blaze_users" */
export type BlazeUsersOnConflict = {
  constraint: BlazeUsersConstraint;
  update_columns?: Array<BlazeUsersUpdateColumn>;
  where?: Maybe<BlazeUsersBoolExp>;
};

/** Ordering options when selecting data from "blaze_users". */
export type BlazeUsersOrderBy = {
  created_at?: Maybe<OrderBy>;
  external_blaze_user_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: blaze_users */
export type BlazeUsersPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "blaze_users" */
export enum BlazeUsersSelectColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExternalBlazeUserId = "external_blaze_user_id",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "blaze_users" */
export type BlazeUsersSetInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_blaze_user_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "blaze_users" */
export enum BlazeUsersUpdateColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExternalBlazeUserId = "external_blaze_user_id",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserId = "user_id",
}

/** columns and relationships of "companies" */
export type Companies = {
  address?: Maybe<Scalars["String"]>;
  /** An array relationship */
  agreements: Array<CompanyAgreements>;
  /** An aggregate relationship */
  agreements_aggregate: CompanyAgreementsAggregate;
  /** An array relationship */
  bank_accounts: Array<BankAccounts>;
  /** An aggregate relationship */
  bank_accounts_aggregate: BankAccountsAggregate;
  city?: Maybe<Scalars["String"]>;
  /** An array relationship */
  company_facilities: Array<CompanyFacilities>;
  /** An aggregate relationship */
  company_facilities_aggregate: CompanyFacilitiesAggregate;
  /** An array relationship */
  company_licenses: Array<CompanyLicenses>;
  /** An aggregate relationship */
  company_licenses_aggregate: CompanyLicensesAggregate;
  /** An array relationship */
  company_payor_partnerships: Array<CompanyPayorPartnerships>;
  /** An aggregate relationship */
  company_payor_partnerships_aggregate: CompanyPayorPartnershipsAggregate;
  /** An array relationship */
  company_payor_partnerships_by_payor: Array<CompanyPayorPartnerships>;
  /** An aggregate relationship */
  company_payor_partnerships_by_payor_aggregate: CompanyPayorPartnershipsAggregate;
  company_settings_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  company_vendor_partnerships: Array<CompanyVendorPartnerships>;
  /** An aggregate relationship */
  company_vendor_partnerships_aggregate: CompanyVendorPartnershipsAggregate;
  /** An array relationship */
  company_vendor_partnerships_by_vendor: Array<CompanyVendorPartnerships>;
  /** An aggregate relationship */
  company_vendor_partnerships_by_vendor_aggregate: CompanyVendorPartnershipsAggregate;
  /** An object relationship */
  contract?: Maybe<Contracts>;
  contract_id?: Maybe<Scalars["uuid"]>;
  contract_name?: Maybe<Scalars["String"]>;
  /** An array relationship */
  contracts: Array<Contracts>;
  /** An aggregate relationship */
  contracts_aggregate: ContractsAggregate;
  country?: Maybe<Scalars["String"]>;
  created_at: Scalars["timestamptz"];
  /** An array relationship */
  customer_surveillance_results: Array<CustomerSurveillanceResults>;
  /** An aggregate relationship */
  customer_surveillance_results_aggregate: CustomerSurveillanceResultsAggregate;
  dba_name?: Maybe<Scalars["String"]>;
  debt_facility_status?: Maybe<Scalars["String"]>;
  debt_facility_waiver_date?: Maybe<Scalars["date"]>;
  debt_facility_waiver_expiration_date?: Maybe<Scalars["date"]>;
  /** An array relationship */
  ebba_applications: Array<EbbaApplications>;
  /** An aggregate relationship */
  ebba_applications_aggregate: EbbaApplicationsAggregate;
  employer_identification_number?: Maybe<Scalars["String"]>;
  /** An array relationship */
  financial_summaries: Array<FinancialSummaries>;
  /** An aggregate relationship */
  financial_summaries_aggregate: FinancialSummariesAggregate;
  id: Scalars["uuid"];
  identifier?: Maybe<Scalars["String"]>;
  /** An array relationship */
  invoice_by_payor: Array<Invoices>;
  /** An aggregate relationship */
  invoice_by_payor_aggregate: InvoicesAggregate;
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: InvoicesAggregate;
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  is_customer?: Maybe<Scalars["Boolean"]>;
  is_payor?: Maybe<Scalars["Boolean"]>;
  is_super_vendor: Scalars["Boolean"];
  is_vendor?: Maybe<Scalars["Boolean"]>;
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier: Scalars["Int"];
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier: Scalars["Int"];
  latest_repayment_identifier: Scalars["Int"];
  /** An array relationship */
  licenses: Array<CompanyLicenses>;
  /** An aggregate relationship */
  licenses_aggregate: CompanyLicensesAggregate;
  /** An array relationship */
  loans: Array<Loans>;
  /** An aggregate relationship */
  loans_aggregate: LoansAggregate;
  /** An array relationship */
  metrc_api_keys: Array<MetrcApiKeys>;
  /** An aggregate relationship */
  metrc_api_keys_aggregate: MetrcApiKeysAggregate;
  /** An array relationship */
  monthly_summary_calculations: Array<MonthlySummaryCalculations>;
  /** An aggregate relationship */
  monthly_summary_calculations_aggregate: MonthlySummaryCalculationsAggregate;
  name: Scalars["String"];
  needs_balance_recomputed: Scalars["Boolean"];
  /** An object relationship */
  parent_company?: Maybe<ParentCompanies>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregate relationship */
  payments_aggregate: PaymentsAggregate;
  phone_number?: Maybe<Scalars["String"]>;
  /** An array relationship */
  purchase_orders: Array<PurchaseOrders>;
  /** An aggregate relationship */
  purchase_orders_aggregate: PurchaseOrdersAggregate;
  /** An array relationship */
  purchase_orders_by_vendor: Array<PurchaseOrders>;
  /** An aggregate relationship */
  purchase_orders_by_vendor_aggregate: PurchaseOrdersAggregate;
  qualify_for?: Maybe<Scalars["String"]>;
  /** An object relationship */
  settings?: Maybe<CompanySettings>;
  state?: Maybe<Scalars["String"]>;
  surveillance_status?: Maybe<Scalars["String"]>;
  surveillance_status_note?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: UsersAggregate;
  zip_code?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "companies" */
export type CompaniesAgreementsArgs = {
  distinct_on?: Maybe<Array<CompanyAgreementsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyAgreementsOrderBy>>;
  where?: Maybe<CompanyAgreementsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesAgreementsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyAgreementsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyAgreementsOrderBy>>;
  where?: Maybe<CompanyAgreementsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesBankAccountsArgs = {
  distinct_on?: Maybe<Array<BankAccountsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BankAccountsOrderBy>>;
  where?: Maybe<BankAccountsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesBankAccountsAggregateArgs = {
  distinct_on?: Maybe<Array<BankAccountsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BankAccountsOrderBy>>;
  where?: Maybe<BankAccountsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesCompanyFacilitiesArgs = {
  distinct_on?: Maybe<Array<CompanyFacilitiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyFacilitiesOrderBy>>;
  where?: Maybe<CompanyFacilitiesBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesCompanyFacilitiesAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyFacilitiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyFacilitiesOrderBy>>;
  where?: Maybe<CompanyFacilitiesBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesCompanyLicensesArgs = {
  distinct_on?: Maybe<Array<CompanyLicensesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyLicensesOrderBy>>;
  where?: Maybe<CompanyLicensesBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesCompanyLicensesAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyLicensesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyLicensesOrderBy>>;
  where?: Maybe<CompanyLicensesBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesCompanyPayorPartnershipsArgs = {
  distinct_on?: Maybe<Array<CompanyPayorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorPartnershipsOrderBy>>;
  where?: Maybe<CompanyPayorPartnershipsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesCompanyPayorPartnershipsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyPayorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorPartnershipsOrderBy>>;
  where?: Maybe<CompanyPayorPartnershipsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesCompanyPayorPartnershipsByPayorArgs = {
  distinct_on?: Maybe<Array<CompanyPayorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorPartnershipsOrderBy>>;
  where?: Maybe<CompanyPayorPartnershipsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesCompanyPayorPartnershipsByPayorAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyPayorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorPartnershipsOrderBy>>;
  where?: Maybe<CompanyPayorPartnershipsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesCompanyVendorPartnershipsArgs = {
  distinct_on?: Maybe<Array<CompanyVendorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorPartnershipsOrderBy>>;
  where?: Maybe<CompanyVendorPartnershipsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesCompanyVendorPartnershipsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyVendorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorPartnershipsOrderBy>>;
  where?: Maybe<CompanyVendorPartnershipsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesCompanyVendorPartnershipsByVendorArgs = {
  distinct_on?: Maybe<Array<CompanyVendorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorPartnershipsOrderBy>>;
  where?: Maybe<CompanyVendorPartnershipsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesCompanyVendorPartnershipsByVendorAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyVendorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorPartnershipsOrderBy>>;
  where?: Maybe<CompanyVendorPartnershipsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesContractsArgs = {
  distinct_on?: Maybe<Array<ContractsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ContractsOrderBy>>;
  where?: Maybe<ContractsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesContractsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ContractsOrderBy>>;
  where?: Maybe<ContractsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesCustomerSurveillanceResultsArgs = {
  distinct_on?: Maybe<Array<CustomerSurveillanceResultsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CustomerSurveillanceResultsOrderBy>>;
  where?: Maybe<CustomerSurveillanceResultsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesCustomerSurveillanceResultsAggregateArgs = {
  distinct_on?: Maybe<Array<CustomerSurveillanceResultsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CustomerSurveillanceResultsOrderBy>>;
  where?: Maybe<CustomerSurveillanceResultsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesEbbaApplicationsArgs = {
  distinct_on?: Maybe<Array<EbbaApplicationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EbbaApplicationsOrderBy>>;
  where?: Maybe<EbbaApplicationsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesEbbaApplicationsAggregateArgs = {
  distinct_on?: Maybe<Array<EbbaApplicationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EbbaApplicationsOrderBy>>;
  where?: Maybe<EbbaApplicationsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesFinancialSummariesArgs = {
  distinct_on?: Maybe<Array<FinancialSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FinancialSummariesOrderBy>>;
  where?: Maybe<FinancialSummariesBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesFinancialSummariesAggregateArgs = {
  distinct_on?: Maybe<Array<FinancialSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FinancialSummariesOrderBy>>;
  where?: Maybe<FinancialSummariesBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesInvoiceByPayorArgs = {
  distinct_on?: Maybe<Array<InvoicesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoicesOrderBy>>;
  where?: Maybe<InvoicesBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesInvoiceByPayorAggregateArgs = {
  distinct_on?: Maybe<Array<InvoicesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoicesOrderBy>>;
  where?: Maybe<InvoicesBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesInvoicesArgs = {
  distinct_on?: Maybe<Array<InvoicesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoicesOrderBy>>;
  where?: Maybe<InvoicesBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesInvoicesAggregateArgs = {
  distinct_on?: Maybe<Array<InvoicesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoicesOrderBy>>;
  where?: Maybe<InvoicesBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesLicensesArgs = {
  distinct_on?: Maybe<Array<CompanyLicensesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyLicensesOrderBy>>;
  where?: Maybe<CompanyLicensesBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesLicensesAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyLicensesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyLicensesOrderBy>>;
  where?: Maybe<CompanyLicensesBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesLoansArgs = {
  distinct_on?: Maybe<Array<LoansSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoansOrderBy>>;
  where?: Maybe<LoansBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesLoansAggregateArgs = {
  distinct_on?: Maybe<Array<LoansSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoansOrderBy>>;
  where?: Maybe<LoansBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesMetrcApiKeysArgs = {
  distinct_on?: Maybe<Array<MetrcApiKeysSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcApiKeysOrderBy>>;
  where?: Maybe<MetrcApiKeysBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesMetrcApiKeysAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcApiKeysSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcApiKeysOrderBy>>;
  where?: Maybe<MetrcApiKeysBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesMonthlySummaryCalculationsArgs = {
  distinct_on?: Maybe<Array<MonthlySummaryCalculationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MonthlySummaryCalculationsOrderBy>>;
  where?: Maybe<MonthlySummaryCalculationsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesMonthlySummaryCalculationsAggregateArgs = {
  distinct_on?: Maybe<Array<MonthlySummaryCalculationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MonthlySummaryCalculationsOrderBy>>;
  where?: Maybe<MonthlySummaryCalculationsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesPaymentsArgs = {
  distinct_on?: Maybe<Array<PaymentsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PaymentsOrderBy>>;
  where?: Maybe<PaymentsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesPaymentsAggregateArgs = {
  distinct_on?: Maybe<Array<PaymentsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PaymentsOrderBy>>;
  where?: Maybe<PaymentsBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesPurchaseOrdersArgs = {
  distinct_on?: Maybe<Array<PurchaseOrdersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrdersOrderBy>>;
  where?: Maybe<PurchaseOrdersBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesPurchaseOrdersAggregateArgs = {
  distinct_on?: Maybe<Array<PurchaseOrdersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrdersOrderBy>>;
  where?: Maybe<PurchaseOrdersBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesPurchaseOrdersByVendorArgs = {
  distinct_on?: Maybe<Array<PurchaseOrdersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrdersOrderBy>>;
  where?: Maybe<PurchaseOrdersBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesPurchaseOrdersByVendorAggregateArgs = {
  distinct_on?: Maybe<Array<PurchaseOrdersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrdersOrderBy>>;
  where?: Maybe<PurchaseOrdersBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesUsersArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

/** columns and relationships of "companies" */
export type CompaniesUsersAggregateArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

/** aggregated selection of "companies" */
export type CompaniesAggregate = {
  aggregate?: Maybe<CompaniesAggregateFields>;
  nodes: Array<Companies>;
};

/** aggregate fields of "companies" */
export type CompaniesAggregateFields = {
  avg?: Maybe<CompaniesAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<CompaniesMaxFields>;
  min?: Maybe<CompaniesMinFields>;
  stddev?: Maybe<CompaniesStddevFields>;
  stddev_pop?: Maybe<CompaniesStddevPopFields>;
  stddev_samp?: Maybe<CompaniesStddevSampFields>;
  sum?: Maybe<CompaniesSumFields>;
  var_pop?: Maybe<CompaniesVarPopFields>;
  var_samp?: Maybe<CompaniesVarSampFields>;
  variance?: Maybe<CompaniesVarianceFields>;
};

/** aggregate fields of "companies" */
export type CompaniesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CompaniesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "companies" */
export type CompaniesAggregateOrderBy = {
  avg?: Maybe<CompaniesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<CompaniesMaxOrderBy>;
  min?: Maybe<CompaniesMinOrderBy>;
  stddev?: Maybe<CompaniesStddevOrderBy>;
  stddev_pop?: Maybe<CompaniesStddevPopOrderBy>;
  stddev_samp?: Maybe<CompaniesStddevSampOrderBy>;
  sum?: Maybe<CompaniesSumOrderBy>;
  var_pop?: Maybe<CompaniesVarPopOrderBy>;
  var_samp?: Maybe<CompaniesVarSampOrderBy>;
  variance?: Maybe<CompaniesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "companies" */
export type CompaniesArrRelInsertInput = {
  data: Array<CompaniesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<CompaniesOnConflict>;
};

/** aggregate avg on columns */
export type CompaniesAvgFields = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<Scalars["Float"]>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
  latest_repayment_identifier?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "companies" */
export type CompaniesAvgOrderBy = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<OrderBy>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<OrderBy>;
  latest_repayment_identifier?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "companies". All fields are combined with a logical 'AND'. */
export type CompaniesBoolExp = {
  _and?: Maybe<Array<CompaniesBoolExp>>;
  _not?: Maybe<CompaniesBoolExp>;
  _or?: Maybe<Array<CompaniesBoolExp>>;
  address?: Maybe<StringComparisonExp>;
  agreements?: Maybe<CompanyAgreementsBoolExp>;
  bank_accounts?: Maybe<BankAccountsBoolExp>;
  city?: Maybe<StringComparisonExp>;
  company_facilities?: Maybe<CompanyFacilitiesBoolExp>;
  company_licenses?: Maybe<CompanyLicensesBoolExp>;
  company_payor_partnerships?: Maybe<CompanyPayorPartnershipsBoolExp>;
  company_payor_partnerships_by_payor?: Maybe<CompanyPayorPartnershipsBoolExp>;
  company_settings_id?: Maybe<UuidComparisonExp>;
  company_vendor_partnerships?: Maybe<CompanyVendorPartnershipsBoolExp>;
  company_vendor_partnerships_by_vendor?: Maybe<CompanyVendorPartnershipsBoolExp>;
  contract?: Maybe<ContractsBoolExp>;
  contract_id?: Maybe<UuidComparisonExp>;
  contract_name?: Maybe<StringComparisonExp>;
  contracts?: Maybe<ContractsBoolExp>;
  country?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  customer_surveillance_results?: Maybe<CustomerSurveillanceResultsBoolExp>;
  dba_name?: Maybe<StringComparisonExp>;
  debt_facility_status?: Maybe<StringComparisonExp>;
  debt_facility_waiver_date?: Maybe<DateComparisonExp>;
  debt_facility_waiver_expiration_date?: Maybe<DateComparisonExp>;
  ebba_applications?: Maybe<EbbaApplicationsBoolExp>;
  employer_identification_number?: Maybe<StringComparisonExp>;
  financial_summaries?: Maybe<FinancialSummariesBoolExp>;
  id?: Maybe<UuidComparisonExp>;
  identifier?: Maybe<StringComparisonExp>;
  invoice_by_payor?: Maybe<InvoicesBoolExp>;
  invoices?: Maybe<InvoicesBoolExp>;
  is_cannabis?: Maybe<BooleanComparisonExp>;
  is_customer?: Maybe<BooleanComparisonExp>;
  is_payor?: Maybe<BooleanComparisonExp>;
  is_super_vendor?: Maybe<BooleanComparisonExp>;
  is_vendor?: Maybe<BooleanComparisonExp>;
  latest_disbursement_identifier?: Maybe<IntComparisonExp>;
  latest_loan_identifier?: Maybe<IntComparisonExp>;
  latest_repayment_identifier?: Maybe<IntComparisonExp>;
  licenses?: Maybe<CompanyLicensesBoolExp>;
  loans?: Maybe<LoansBoolExp>;
  metrc_api_keys?: Maybe<MetrcApiKeysBoolExp>;
  monthly_summary_calculations?: Maybe<MonthlySummaryCalculationsBoolExp>;
  name?: Maybe<StringComparisonExp>;
  needs_balance_recomputed?: Maybe<BooleanComparisonExp>;
  parent_company?: Maybe<ParentCompaniesBoolExp>;
  parent_company_id?: Maybe<UuidComparisonExp>;
  payments?: Maybe<PaymentsBoolExp>;
  phone_number?: Maybe<StringComparisonExp>;
  purchase_orders?: Maybe<PurchaseOrdersBoolExp>;
  purchase_orders_by_vendor?: Maybe<PurchaseOrdersBoolExp>;
  qualify_for?: Maybe<StringComparisonExp>;
  settings?: Maybe<CompanySettingsBoolExp>;
  state?: Maybe<StringComparisonExp>;
  surveillance_status?: Maybe<StringComparisonExp>;
  surveillance_status_note?: Maybe<StringComparisonExp>;
  timezone?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  users?: Maybe<UsersBoolExp>;
  zip_code?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "companies" */
export enum CompaniesConstraint {
  /** unique or primary key constraint */
  CompaniesCompanySettingsIdKey = "companies_company_settings_id_key",
  /** unique or primary key constraint */
  CompaniesPkey = "companies_pkey",
}

/** input type for incrementing numeric columns in table "companies" */
export type CompaniesIncInput = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<Scalars["Int"]>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
  latest_repayment_identifier?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "companies" */
export type CompaniesInsertInput = {
  address?: Maybe<Scalars["String"]>;
  agreements?: Maybe<CompanyAgreementsArrRelInsertInput>;
  bank_accounts?: Maybe<BankAccountsArrRelInsertInput>;
  city?: Maybe<Scalars["String"]>;
  company_facilities?: Maybe<CompanyFacilitiesArrRelInsertInput>;
  company_licenses?: Maybe<CompanyLicensesArrRelInsertInput>;
  company_payor_partnerships?: Maybe<CompanyPayorPartnershipsArrRelInsertInput>;
  company_payor_partnerships_by_payor?: Maybe<CompanyPayorPartnershipsArrRelInsertInput>;
  company_settings_id?: Maybe<Scalars["uuid"]>;
  company_vendor_partnerships?: Maybe<CompanyVendorPartnershipsArrRelInsertInput>;
  company_vendor_partnerships_by_vendor?: Maybe<CompanyVendorPartnershipsArrRelInsertInput>;
  contract?: Maybe<ContractsObjRelInsertInput>;
  contract_id?: Maybe<Scalars["uuid"]>;
  contract_name?: Maybe<Scalars["String"]>;
  contracts?: Maybe<ContractsArrRelInsertInput>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  customer_surveillance_results?: Maybe<CustomerSurveillanceResultsArrRelInsertInput>;
  dba_name?: Maybe<Scalars["String"]>;
  debt_facility_status?: Maybe<Scalars["String"]>;
  debt_facility_waiver_date?: Maybe<Scalars["date"]>;
  debt_facility_waiver_expiration_date?: Maybe<Scalars["date"]>;
  ebba_applications?: Maybe<EbbaApplicationsArrRelInsertInput>;
  employer_identification_number?: Maybe<Scalars["String"]>;
  financial_summaries?: Maybe<FinancialSummariesArrRelInsertInput>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  invoice_by_payor?: Maybe<InvoicesArrRelInsertInput>;
  invoices?: Maybe<InvoicesArrRelInsertInput>;
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  is_customer?: Maybe<Scalars["Boolean"]>;
  is_payor?: Maybe<Scalars["Boolean"]>;
  is_super_vendor?: Maybe<Scalars["Boolean"]>;
  is_vendor?: Maybe<Scalars["Boolean"]>;
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<Scalars["Int"]>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
  latest_repayment_identifier?: Maybe<Scalars["Int"]>;
  licenses?: Maybe<CompanyLicensesArrRelInsertInput>;
  loans?: Maybe<LoansArrRelInsertInput>;
  metrc_api_keys?: Maybe<MetrcApiKeysArrRelInsertInput>;
  monthly_summary_calculations?: Maybe<MonthlySummaryCalculationsArrRelInsertInput>;
  name?: Maybe<Scalars["String"]>;
  needs_balance_recomputed?: Maybe<Scalars["Boolean"]>;
  parent_company?: Maybe<ParentCompaniesObjRelInsertInput>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  payments?: Maybe<PaymentsArrRelInsertInput>;
  phone_number?: Maybe<Scalars["String"]>;
  purchase_orders?: Maybe<PurchaseOrdersArrRelInsertInput>;
  purchase_orders_by_vendor?: Maybe<PurchaseOrdersArrRelInsertInput>;
  qualify_for?: Maybe<Scalars["String"]>;
  settings?: Maybe<CompanySettingsObjRelInsertInput>;
  state?: Maybe<Scalars["String"]>;
  surveillance_status?: Maybe<Scalars["String"]>;
  surveillance_status_note?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  users?: Maybe<UsersArrRelInsertInput>;
  zip_code?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type CompaniesMaxFields = {
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company_settings_id?: Maybe<Scalars["uuid"]>;
  contract_id?: Maybe<Scalars["uuid"]>;
  contract_name?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  debt_facility_status?: Maybe<Scalars["String"]>;
  debt_facility_waiver_date?: Maybe<Scalars["date"]>;
  debt_facility_waiver_expiration_date?: Maybe<Scalars["date"]>;
  employer_identification_number?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<Scalars["Int"]>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
  latest_repayment_identifier?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  phone_number?: Maybe<Scalars["String"]>;
  qualify_for?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  surveillance_status?: Maybe<Scalars["String"]>;
  surveillance_status_note?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  zip_code?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "companies" */
export type CompaniesMaxOrderBy = {
  address?: Maybe<OrderBy>;
  city?: Maybe<OrderBy>;
  company_settings_id?: Maybe<OrderBy>;
  contract_id?: Maybe<OrderBy>;
  contract_name?: Maybe<OrderBy>;
  country?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  dba_name?: Maybe<OrderBy>;
  debt_facility_status?: Maybe<OrderBy>;
  debt_facility_waiver_date?: Maybe<OrderBy>;
  debt_facility_waiver_expiration_date?: Maybe<OrderBy>;
  employer_identification_number?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  identifier?: Maybe<OrderBy>;
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<OrderBy>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<OrderBy>;
  latest_repayment_identifier?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  parent_company_id?: Maybe<OrderBy>;
  phone_number?: Maybe<OrderBy>;
  qualify_for?: Maybe<OrderBy>;
  state?: Maybe<OrderBy>;
  surveillance_status?: Maybe<OrderBy>;
  surveillance_status_note?: Maybe<OrderBy>;
  timezone?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  zip_code?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type CompaniesMinFields = {
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company_settings_id?: Maybe<Scalars["uuid"]>;
  contract_id?: Maybe<Scalars["uuid"]>;
  contract_name?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  debt_facility_status?: Maybe<Scalars["String"]>;
  debt_facility_waiver_date?: Maybe<Scalars["date"]>;
  debt_facility_waiver_expiration_date?: Maybe<Scalars["date"]>;
  employer_identification_number?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<Scalars["Int"]>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
  latest_repayment_identifier?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  phone_number?: Maybe<Scalars["String"]>;
  qualify_for?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  surveillance_status?: Maybe<Scalars["String"]>;
  surveillance_status_note?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  zip_code?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "companies" */
export type CompaniesMinOrderBy = {
  address?: Maybe<OrderBy>;
  city?: Maybe<OrderBy>;
  company_settings_id?: Maybe<OrderBy>;
  contract_id?: Maybe<OrderBy>;
  contract_name?: Maybe<OrderBy>;
  country?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  dba_name?: Maybe<OrderBy>;
  debt_facility_status?: Maybe<OrderBy>;
  debt_facility_waiver_date?: Maybe<OrderBy>;
  debt_facility_waiver_expiration_date?: Maybe<OrderBy>;
  employer_identification_number?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  identifier?: Maybe<OrderBy>;
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<OrderBy>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<OrderBy>;
  latest_repayment_identifier?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  parent_company_id?: Maybe<OrderBy>;
  phone_number?: Maybe<OrderBy>;
  qualify_for?: Maybe<OrderBy>;
  state?: Maybe<OrderBy>;
  surveillance_status?: Maybe<OrderBy>;
  surveillance_status_note?: Maybe<OrderBy>;
  timezone?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  zip_code?: Maybe<OrderBy>;
};

/** response of any mutation on the table "companies" */
export type CompaniesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Companies>;
};

/** input type for inserting object relation for remote table "companies" */
export type CompaniesObjRelInsertInput = {
  data: CompaniesInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<CompaniesOnConflict>;
};

/** on_conflict condition type for table "companies" */
export type CompaniesOnConflict = {
  constraint: CompaniesConstraint;
  update_columns?: Array<CompaniesUpdateColumn>;
  where?: Maybe<CompaniesBoolExp>;
};

/** Ordering options when selecting data from "companies". */
export type CompaniesOrderBy = {
  address?: Maybe<OrderBy>;
  agreements_aggregate?: Maybe<CompanyAgreementsAggregateOrderBy>;
  bank_accounts_aggregate?: Maybe<BankAccountsAggregateOrderBy>;
  city?: Maybe<OrderBy>;
  company_facilities_aggregate?: Maybe<CompanyFacilitiesAggregateOrderBy>;
  company_licenses_aggregate?: Maybe<CompanyLicensesAggregateOrderBy>;
  company_payor_partnerships_aggregate?: Maybe<CompanyPayorPartnershipsAggregateOrderBy>;
  company_payor_partnerships_by_payor_aggregate?: Maybe<CompanyPayorPartnershipsAggregateOrderBy>;
  company_settings_id?: Maybe<OrderBy>;
  company_vendor_partnerships_aggregate?: Maybe<CompanyVendorPartnershipsAggregateOrderBy>;
  company_vendor_partnerships_by_vendor_aggregate?: Maybe<CompanyVendorPartnershipsAggregateOrderBy>;
  contract?: Maybe<ContractsOrderBy>;
  contract_id?: Maybe<OrderBy>;
  contract_name?: Maybe<OrderBy>;
  contracts_aggregate?: Maybe<ContractsAggregateOrderBy>;
  country?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  customer_surveillance_results_aggregate?: Maybe<CustomerSurveillanceResultsAggregateOrderBy>;
  dba_name?: Maybe<OrderBy>;
  debt_facility_status?: Maybe<OrderBy>;
  debt_facility_waiver_date?: Maybe<OrderBy>;
  debt_facility_waiver_expiration_date?: Maybe<OrderBy>;
  ebba_applications_aggregate?: Maybe<EbbaApplicationsAggregateOrderBy>;
  employer_identification_number?: Maybe<OrderBy>;
  financial_summaries_aggregate?: Maybe<FinancialSummariesAggregateOrderBy>;
  id?: Maybe<OrderBy>;
  identifier?: Maybe<OrderBy>;
  invoice_by_payor_aggregate?: Maybe<InvoicesAggregateOrderBy>;
  invoices_aggregate?: Maybe<InvoicesAggregateOrderBy>;
  is_cannabis?: Maybe<OrderBy>;
  is_customer?: Maybe<OrderBy>;
  is_payor?: Maybe<OrderBy>;
  is_super_vendor?: Maybe<OrderBy>;
  is_vendor?: Maybe<OrderBy>;
  latest_disbursement_identifier?: Maybe<OrderBy>;
  latest_loan_identifier?: Maybe<OrderBy>;
  latest_repayment_identifier?: Maybe<OrderBy>;
  licenses_aggregate?: Maybe<CompanyLicensesAggregateOrderBy>;
  loans_aggregate?: Maybe<LoansAggregateOrderBy>;
  metrc_api_keys_aggregate?: Maybe<MetrcApiKeysAggregateOrderBy>;
  monthly_summary_calculations_aggregate?: Maybe<MonthlySummaryCalculationsAggregateOrderBy>;
  name?: Maybe<OrderBy>;
  needs_balance_recomputed?: Maybe<OrderBy>;
  parent_company?: Maybe<ParentCompaniesOrderBy>;
  parent_company_id?: Maybe<OrderBy>;
  payments_aggregate?: Maybe<PaymentsAggregateOrderBy>;
  phone_number?: Maybe<OrderBy>;
  purchase_orders_aggregate?: Maybe<PurchaseOrdersAggregateOrderBy>;
  purchase_orders_by_vendor_aggregate?: Maybe<PurchaseOrdersAggregateOrderBy>;
  qualify_for?: Maybe<OrderBy>;
  settings?: Maybe<CompanySettingsOrderBy>;
  state?: Maybe<OrderBy>;
  surveillance_status?: Maybe<OrderBy>;
  surveillance_status_note?: Maybe<OrderBy>;
  timezone?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  users_aggregate?: Maybe<UsersAggregateOrderBy>;
  zip_code?: Maybe<OrderBy>;
};

/** primary key columns input for table: companies */
export type CompaniesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "companies" */
export enum CompaniesSelectColumn {
  /** column name */
  Address = "address",
  /** column name */
  City = "city",
  /** column name */
  CompanySettingsId = "company_settings_id",
  /** column name */
  ContractId = "contract_id",
  /** column name */
  ContractName = "contract_name",
  /** column name */
  Country = "country",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DbaName = "dba_name",
  /** column name */
  DebtFacilityStatus = "debt_facility_status",
  /** column name */
  DebtFacilityWaiverDate = "debt_facility_waiver_date",
  /** column name */
  DebtFacilityWaiverExpirationDate = "debt_facility_waiver_expiration_date",
  /** column name */
  EmployerIdentificationNumber = "employer_identification_number",
  /** column name */
  Id = "id",
  /** column name */
  Identifier = "identifier",
  /** column name */
  IsCannabis = "is_cannabis",
  /** column name */
  IsCustomer = "is_customer",
  /** column name */
  IsPayor = "is_payor",
  /** column name */
  IsSuperVendor = "is_super_vendor",
  /** column name */
  IsVendor = "is_vendor",
  /** column name */
  LatestDisbursementIdentifier = "latest_disbursement_identifier",
  /** column name */
  LatestLoanIdentifier = "latest_loan_identifier",
  /** column name */
  LatestRepaymentIdentifier = "latest_repayment_identifier",
  /** column name */
  Name = "name",
  /** column name */
  NeedsBalanceRecomputed = "needs_balance_recomputed",
  /** column name */
  ParentCompanyId = "parent_company_id",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  QualifyFor = "qualify_for",
  /** column name */
  State = "state",
  /** column name */
  SurveillanceStatus = "surveillance_status",
  /** column name */
  SurveillanceStatusNote = "surveillance_status_note",
  /** column name */
  Timezone = "timezone",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  ZipCode = "zip_code",
}

/** input type for updating data in table "companies" */
export type CompaniesSetInput = {
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company_settings_id?: Maybe<Scalars["uuid"]>;
  contract_id?: Maybe<Scalars["uuid"]>;
  contract_name?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  debt_facility_status?: Maybe<Scalars["String"]>;
  debt_facility_waiver_date?: Maybe<Scalars["date"]>;
  debt_facility_waiver_expiration_date?: Maybe<Scalars["date"]>;
  employer_identification_number?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  is_customer?: Maybe<Scalars["Boolean"]>;
  is_payor?: Maybe<Scalars["Boolean"]>;
  is_super_vendor?: Maybe<Scalars["Boolean"]>;
  is_vendor?: Maybe<Scalars["Boolean"]>;
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<Scalars["Int"]>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
  latest_repayment_identifier?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  needs_balance_recomputed?: Maybe<Scalars["Boolean"]>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  phone_number?: Maybe<Scalars["String"]>;
  qualify_for?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  surveillance_status?: Maybe<Scalars["String"]>;
  surveillance_status_note?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  zip_code?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type CompaniesStddevFields = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<Scalars["Float"]>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
  latest_repayment_identifier?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "companies" */
export type CompaniesStddevOrderBy = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<OrderBy>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<OrderBy>;
  latest_repayment_identifier?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CompaniesStddevPopFields = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<Scalars["Float"]>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
  latest_repayment_identifier?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "companies" */
export type CompaniesStddevPopOrderBy = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<OrderBy>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<OrderBy>;
  latest_repayment_identifier?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CompaniesStddevSampFields = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<Scalars["Float"]>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
  latest_repayment_identifier?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "companies" */
export type CompaniesStddevSampOrderBy = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<OrderBy>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<OrderBy>;
  latest_repayment_identifier?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type CompaniesSumFields = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<Scalars["Int"]>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
  latest_repayment_identifier?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "companies" */
export type CompaniesSumOrderBy = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<OrderBy>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<OrderBy>;
  latest_repayment_identifier?: Maybe<OrderBy>;
};

/** update columns of table "companies" */
export enum CompaniesUpdateColumn {
  /** column name */
  Address = "address",
  /** column name */
  City = "city",
  /** column name */
  CompanySettingsId = "company_settings_id",
  /** column name */
  ContractId = "contract_id",
  /** column name */
  ContractName = "contract_name",
  /** column name */
  Country = "country",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DbaName = "dba_name",
  /** column name */
  DebtFacilityStatus = "debt_facility_status",
  /** column name */
  DebtFacilityWaiverDate = "debt_facility_waiver_date",
  /** column name */
  DebtFacilityWaiverExpirationDate = "debt_facility_waiver_expiration_date",
  /** column name */
  EmployerIdentificationNumber = "employer_identification_number",
  /** column name */
  Id = "id",
  /** column name */
  Identifier = "identifier",
  /** column name */
  IsCannabis = "is_cannabis",
  /** column name */
  IsCustomer = "is_customer",
  /** column name */
  IsPayor = "is_payor",
  /** column name */
  IsSuperVendor = "is_super_vendor",
  /** column name */
  IsVendor = "is_vendor",
  /** column name */
  LatestDisbursementIdentifier = "latest_disbursement_identifier",
  /** column name */
  LatestLoanIdentifier = "latest_loan_identifier",
  /** column name */
  LatestRepaymentIdentifier = "latest_repayment_identifier",
  /** column name */
  Name = "name",
  /** column name */
  NeedsBalanceRecomputed = "needs_balance_recomputed",
  /** column name */
  ParentCompanyId = "parent_company_id",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  QualifyFor = "qualify_for",
  /** column name */
  State = "state",
  /** column name */
  SurveillanceStatus = "surveillance_status",
  /** column name */
  SurveillanceStatusNote = "surveillance_status_note",
  /** column name */
  Timezone = "timezone",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  ZipCode = "zip_code",
}

/** aggregate var_pop on columns */
export type CompaniesVarPopFields = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<Scalars["Float"]>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
  latest_repayment_identifier?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "companies" */
export type CompaniesVarPopOrderBy = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<OrderBy>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<OrderBy>;
  latest_repayment_identifier?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CompaniesVarSampFields = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<Scalars["Float"]>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
  latest_repayment_identifier?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "companies" */
export type CompaniesVarSampOrderBy = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<OrderBy>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<OrderBy>;
  latest_repayment_identifier?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type CompaniesVarianceFields = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<Scalars["Float"]>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
  latest_repayment_identifier?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "companies" */
export type CompaniesVarianceOrderBy = {
  /** The latest disbursement (payment) identifier assigned to loans belonging to this company when an advance is made; increment this value to get a new disbursement identifier for a new payment */
  latest_disbursement_identifier?: Maybe<OrderBy>;
  /** The latest loan identifier created for loans belonging to this company; increment this value to get a new loan identifier for a new loan */
  latest_loan_identifier?: Maybe<OrderBy>;
  latest_repayment_identifier?: Maybe<OrderBy>;
};

/** Agreements that a company signs with Bespoke, this can be for vendors or customers signing agreeements */
export type CompanyAgreements = {
  /** An object relationship */
  company: Companies;
  company_id: Scalars["uuid"];
  /** An array relationship */
  company_payor_partnerships: Array<CompanyPayorPartnerships>;
  /** An aggregate relationship */
  company_payor_partnerships_aggregate: CompanyPayorPartnershipsAggregate;
  /** An array relationship */
  company_vendor_partnerships: Array<CompanyVendorPartnerships>;
  /** An aggregate relationship */
  company_vendor_partnerships_aggregate: CompanyVendorPartnershipsAggregate;
  file_id: Scalars["uuid"];
  id: Scalars["uuid"];
};

/** Agreements that a company signs with Bespoke, this can be for vendors or customers signing agreeements */
export type CompanyAgreementsCompanyPayorPartnershipsArgs = {
  distinct_on?: Maybe<Array<CompanyPayorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorPartnershipsOrderBy>>;
  where?: Maybe<CompanyPayorPartnershipsBoolExp>;
};

/** Agreements that a company signs with Bespoke, this can be for vendors or customers signing agreeements */
export type CompanyAgreementsCompanyPayorPartnershipsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyPayorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorPartnershipsOrderBy>>;
  where?: Maybe<CompanyPayorPartnershipsBoolExp>;
};

/** Agreements that a company signs with Bespoke, this can be for vendors or customers signing agreeements */
export type CompanyAgreementsCompanyVendorPartnershipsArgs = {
  distinct_on?: Maybe<Array<CompanyVendorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorPartnershipsOrderBy>>;
  where?: Maybe<CompanyVendorPartnershipsBoolExp>;
};

/** Agreements that a company signs with Bespoke, this can be for vendors or customers signing agreeements */
export type CompanyAgreementsCompanyVendorPartnershipsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyVendorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorPartnershipsOrderBy>>;
  where?: Maybe<CompanyVendorPartnershipsBoolExp>;
};

/** aggregated selection of "company_agreements" */
export type CompanyAgreementsAggregate = {
  aggregate?: Maybe<CompanyAgreementsAggregateFields>;
  nodes: Array<CompanyAgreements>;
};

/** aggregate fields of "company_agreements" */
export type CompanyAgreementsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<CompanyAgreementsMaxFields>;
  min?: Maybe<CompanyAgreementsMinFields>;
};

/** aggregate fields of "company_agreements" */
export type CompanyAgreementsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CompanyAgreementsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "company_agreements" */
export type CompanyAgreementsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<CompanyAgreementsMaxOrderBy>;
  min?: Maybe<CompanyAgreementsMinOrderBy>;
};

/** input type for inserting array relation for remote table "company_agreements" */
export type CompanyAgreementsArrRelInsertInput = {
  data: Array<CompanyAgreementsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<CompanyAgreementsOnConflict>;
};

/** Boolean expression to filter rows from the table "company_agreements". All fields are combined with a logical 'AND'. */
export type CompanyAgreementsBoolExp = {
  _and?: Maybe<Array<CompanyAgreementsBoolExp>>;
  _not?: Maybe<CompanyAgreementsBoolExp>;
  _or?: Maybe<Array<CompanyAgreementsBoolExp>>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  company_payor_partnerships?: Maybe<CompanyPayorPartnershipsBoolExp>;
  company_vendor_partnerships?: Maybe<CompanyVendorPartnershipsBoolExp>;
  file_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "company_agreements" */
export enum CompanyAgreementsConstraint {
  /** unique or primary key constraint */
  VendorAgreementsPkey = "vendor_agreements_pkey",
}

/** input type for inserting data into table "company_agreements" */
export type CompanyAgreementsInsertInput = {
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  company_payor_partnerships?: Maybe<CompanyPayorPartnershipsArrRelInsertInput>;
  company_vendor_partnerships?: Maybe<CompanyVendorPartnershipsArrRelInsertInput>;
  file_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type CompanyAgreementsMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  file_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "company_agreements" */
export type CompanyAgreementsMaxOrderBy = {
  company_id?: Maybe<OrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type CompanyAgreementsMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  file_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "company_agreements" */
export type CompanyAgreementsMinOrderBy = {
  company_id?: Maybe<OrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "company_agreements" */
export type CompanyAgreementsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CompanyAgreements>;
};

/** input type for inserting object relation for remote table "company_agreements" */
export type CompanyAgreementsObjRelInsertInput = {
  data: CompanyAgreementsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<CompanyAgreementsOnConflict>;
};

/** on_conflict condition type for table "company_agreements" */
export type CompanyAgreementsOnConflict = {
  constraint: CompanyAgreementsConstraint;
  update_columns?: Array<CompanyAgreementsUpdateColumn>;
  where?: Maybe<CompanyAgreementsBoolExp>;
};

/** Ordering options when selecting data from "company_agreements". */
export type CompanyAgreementsOrderBy = {
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  company_payor_partnerships_aggregate?: Maybe<CompanyPayorPartnershipsAggregateOrderBy>;
  company_vendor_partnerships_aggregate?: Maybe<CompanyVendorPartnershipsAggregateOrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** primary key columns input for table: company_agreements */
export type CompanyAgreementsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "company_agreements" */
export enum CompanyAgreementsSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  FileId = "file_id",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "company_agreements" */
export type CompanyAgreementsSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  file_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "company_agreements" */
export enum CompanyAgreementsUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  FileId = "file_id",
  /** column name */
  Id = "id",
}

/** columns and relationships of "company_deliveries" */
export type CompanyDeliveries = {
  /** An object relationship */
  company: Companies;
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  delivery_row_id: Scalars["uuid"];
  delivery_type: Scalars["String"];
  id: Scalars["uuid"];
  license_number: Scalars["String"];
  /** An object relationship */
  metrc_delivery: MetrcDeliveries;
  /** An object relationship */
  metrc_transfer: MetrcTransfers;
  /** An object relationship */
  payor?: Maybe<Payors>;
  payor_id?: Maybe<Scalars["uuid"]>;
  transfer_row_id: Scalars["uuid"];
  transfer_type: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  us_state: Scalars["String"];
  /** An object relationship */
  vendor?: Maybe<Vendors>;
  vendor_id?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "company_deliveries" */
export type CompanyDeliveriesAggregate = {
  aggregate?: Maybe<CompanyDeliveriesAggregateFields>;
  nodes: Array<CompanyDeliveries>;
};

/** aggregate fields of "company_deliveries" */
export type CompanyDeliveriesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<CompanyDeliveriesMaxFields>;
  min?: Maybe<CompanyDeliveriesMinFields>;
};

/** aggregate fields of "company_deliveries" */
export type CompanyDeliveriesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CompanyDeliveriesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "company_deliveries" */
export type CompanyDeliveriesAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<CompanyDeliveriesMaxOrderBy>;
  min?: Maybe<CompanyDeliveriesMinOrderBy>;
};

/** input type for inserting array relation for remote table "company_deliveries" */
export type CompanyDeliveriesArrRelInsertInput = {
  data: Array<CompanyDeliveriesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<CompanyDeliveriesOnConflict>;
};

/** Boolean expression to filter rows from the table "company_deliveries". All fields are combined with a logical 'AND'. */
export type CompanyDeliveriesBoolExp = {
  _and?: Maybe<Array<CompanyDeliveriesBoolExp>>;
  _not?: Maybe<CompanyDeliveriesBoolExp>;
  _or?: Maybe<Array<CompanyDeliveriesBoolExp>>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  delivery_row_id?: Maybe<UuidComparisonExp>;
  delivery_type?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  license_number?: Maybe<StringComparisonExp>;
  metrc_delivery?: Maybe<MetrcDeliveriesBoolExp>;
  metrc_transfer?: Maybe<MetrcTransfersBoolExp>;
  payor?: Maybe<PayorsBoolExp>;
  payor_id?: Maybe<UuidComparisonExp>;
  transfer_row_id?: Maybe<UuidComparisonExp>;
  transfer_type?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  us_state?: Maybe<StringComparisonExp>;
  vendor?: Maybe<VendorsBoolExp>;
  vendor_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "company_deliveries" */
export enum CompanyDeliveriesConstraint {
  /** unique or primary key constraint */
  CompanyDeliveriesPkey = "company_deliveries_pkey",
  /** unique or primary key constraint */
  CompanyDeliveriesUsStateLicenseNumberCompanyIdTransfer = "company_deliveries_us_state_license_number_company_id_transfer_",
}

/** input type for inserting data into table "company_deliveries" */
export type CompanyDeliveriesInsertInput = {
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  delivery_row_id?: Maybe<Scalars["uuid"]>;
  delivery_type?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  license_number?: Maybe<Scalars["String"]>;
  metrc_delivery?: Maybe<MetrcDeliveriesObjRelInsertInput>;
  metrc_transfer?: Maybe<MetrcTransfersObjRelInsertInput>;
  payor?: Maybe<PayorsObjRelInsertInput>;
  payor_id?: Maybe<Scalars["uuid"]>;
  transfer_row_id?: Maybe<Scalars["uuid"]>;
  transfer_type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
  vendor?: Maybe<VendorsObjRelInsertInput>;
  vendor_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type CompanyDeliveriesMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  delivery_row_id?: Maybe<Scalars["uuid"]>;
  delivery_type?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  license_number?: Maybe<Scalars["String"]>;
  payor_id?: Maybe<Scalars["uuid"]>;
  transfer_row_id?: Maybe<Scalars["uuid"]>;
  transfer_type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
  vendor_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "company_deliveries" */
export type CompanyDeliveriesMaxOrderBy = {
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  delivery_row_id?: Maybe<OrderBy>;
  delivery_type?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  payor_id?: Maybe<OrderBy>;
  transfer_row_id?: Maybe<OrderBy>;
  transfer_type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type CompanyDeliveriesMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  delivery_row_id?: Maybe<Scalars["uuid"]>;
  delivery_type?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  license_number?: Maybe<Scalars["String"]>;
  payor_id?: Maybe<Scalars["uuid"]>;
  transfer_row_id?: Maybe<Scalars["uuid"]>;
  transfer_type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
  vendor_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "company_deliveries" */
export type CompanyDeliveriesMinOrderBy = {
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  delivery_row_id?: Maybe<OrderBy>;
  delivery_type?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  payor_id?: Maybe<OrderBy>;
  transfer_row_id?: Maybe<OrderBy>;
  transfer_type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "company_deliveries" */
export type CompanyDeliveriesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CompanyDeliveries>;
};

/** on_conflict condition type for table "company_deliveries" */
export type CompanyDeliveriesOnConflict = {
  constraint: CompanyDeliveriesConstraint;
  update_columns?: Array<CompanyDeliveriesUpdateColumn>;
  where?: Maybe<CompanyDeliveriesBoolExp>;
};

/** Ordering options when selecting data from "company_deliveries". */
export type CompanyDeliveriesOrderBy = {
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  delivery_row_id?: Maybe<OrderBy>;
  delivery_type?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  metrc_delivery?: Maybe<MetrcDeliveriesOrderBy>;
  metrc_transfer?: Maybe<MetrcTransfersOrderBy>;
  payor?: Maybe<PayorsOrderBy>;
  payor_id?: Maybe<OrderBy>;
  transfer_row_id?: Maybe<OrderBy>;
  transfer_type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
  vendor?: Maybe<VendorsOrderBy>;
  vendor_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: company_deliveries */
export type CompanyDeliveriesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "company_deliveries" */
export enum CompanyDeliveriesSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeliveryRowId = "delivery_row_id",
  /** column name */
  DeliveryType = "delivery_type",
  /** column name */
  Id = "id",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  PayorId = "payor_id",
  /** column name */
  TransferRowId = "transfer_row_id",
  /** column name */
  TransferType = "transfer_type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
  /** column name */
  VendorId = "vendor_id",
}

/** input type for updating data in table "company_deliveries" */
export type CompanyDeliveriesSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  delivery_row_id?: Maybe<Scalars["uuid"]>;
  delivery_type?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  license_number?: Maybe<Scalars["String"]>;
  payor_id?: Maybe<Scalars["uuid"]>;
  transfer_row_id?: Maybe<Scalars["uuid"]>;
  transfer_type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
  vendor_id?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "company_deliveries" */
export enum CompanyDeliveriesUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeliveryRowId = "delivery_row_id",
  /** column name */
  DeliveryType = "delivery_type",
  /** column name */
  Id = "id",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  PayorId = "payor_id",
  /** column name */
  TransferRowId = "transfer_row_id",
  /** column name */
  TransferType = "transfer_type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
  /** column name */
  VendorId = "vendor_id",
}

/** columns and relationships of "company_facilities" */
export type CompanyFacilities = {
  address: Scalars["String"];
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_deleted: Scalars["Boolean"];
  name: Scalars["String"];
  underwriting_mode?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "company_facilities" */
export type CompanyFacilitiesAggregate = {
  aggregate?: Maybe<CompanyFacilitiesAggregateFields>;
  nodes: Array<CompanyFacilities>;
};

/** aggregate fields of "company_facilities" */
export type CompanyFacilitiesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<CompanyFacilitiesMaxFields>;
  min?: Maybe<CompanyFacilitiesMinFields>;
};

/** aggregate fields of "company_facilities" */
export type CompanyFacilitiesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CompanyFacilitiesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "company_facilities" */
export type CompanyFacilitiesAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<CompanyFacilitiesMaxOrderBy>;
  min?: Maybe<CompanyFacilitiesMinOrderBy>;
};

/** input type for inserting array relation for remote table "company_facilities" */
export type CompanyFacilitiesArrRelInsertInput = {
  data: Array<CompanyFacilitiesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<CompanyFacilitiesOnConflict>;
};

/** Boolean expression to filter rows from the table "company_facilities". All fields are combined with a logical 'AND'. */
export type CompanyFacilitiesBoolExp = {
  _and?: Maybe<Array<CompanyFacilitiesBoolExp>>;
  _not?: Maybe<CompanyFacilitiesBoolExp>;
  _or?: Maybe<Array<CompanyFacilitiesBoolExp>>;
  address?: Maybe<StringComparisonExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  underwriting_mode?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "company_facilities" */
export enum CompanyFacilitiesConstraint {
  /** unique or primary key constraint */
  CompanyFacilitiesCompanyIdNameKey = "company_facilities_company_id_name_key",
  /** unique or primary key constraint */
  CompanyFacilitiesPkey = "company_facilities_pkey",
}

/** input type for inserting data into table "company_facilities" */
export type CompanyFacilitiesInsertInput = {
  address?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  underwriting_mode?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type CompanyFacilitiesMaxFields = {
  address?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  underwriting_mode?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "company_facilities" */
export type CompanyFacilitiesMaxOrderBy = {
  address?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  underwriting_mode?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type CompanyFacilitiesMinFields = {
  address?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  underwriting_mode?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "company_facilities" */
export type CompanyFacilitiesMinOrderBy = {
  address?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  underwriting_mode?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "company_facilities" */
export type CompanyFacilitiesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CompanyFacilities>;
};

/** input type for inserting object relation for remote table "company_facilities" */
export type CompanyFacilitiesObjRelInsertInput = {
  data: CompanyFacilitiesInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<CompanyFacilitiesOnConflict>;
};

/** on_conflict condition type for table "company_facilities" */
export type CompanyFacilitiesOnConflict = {
  constraint: CompanyFacilitiesConstraint;
  update_columns?: Array<CompanyFacilitiesUpdateColumn>;
  where?: Maybe<CompanyFacilitiesBoolExp>;
};

/** Ordering options when selecting data from "company_facilities". */
export type CompanyFacilitiesOrderBy = {
  address?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  underwriting_mode?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: company_facilities */
export type CompanyFacilitiesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "company_facilities" */
export enum CompanyFacilitiesSelectColumn {
  /** column name */
  Address = "address",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  Name = "name",
  /** column name */
  UnderwritingMode = "underwriting_mode",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "company_facilities" */
export type CompanyFacilitiesSetInput = {
  address?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  underwriting_mode?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "company_facilities" */
export enum CompanyFacilitiesUpdateColumn {
  /** column name */
  Address = "address",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  Name = "name",
  /** column name */
  UnderwritingMode = "underwriting_mode",
  /** column name */
  UpdatedAt = "updated_at",
}

/** Licenses that a company or vendor upload to our system */
export type CompanyLicenses = {
  /** An object relationship */
  company?: Maybe<Companies>;
  /** An object relationship */
  company_facility?: Maybe<CompanyFacilities>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at: Scalars["timestamptz"];
  dba_name?: Maybe<Scalars["String"]>;
  estimate_latitude?: Maybe<Scalars["numeric"]>;
  estimate_longitude?: Maybe<Scalars["numeric"]>;
  estimate_zip?: Maybe<Scalars["String"]>;
  expiration_date?: Maybe<Scalars["date"]>;
  facility_row_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["uuid"];
  is_current?: Maybe<Scalars["Boolean"]>;
  is_deleted: Scalars["Boolean"];
  is_underwriting_enabled?: Maybe<Scalars["Boolean"]>;
  legal_name?: Maybe<Scalars["String"]>;
  license_category?: Maybe<Scalars["String"]>;
  license_description?: Maybe<Scalars["String"]>;
  license_number?: Maybe<Scalars["String"]>;
  license_status?: Maybe<Scalars["String"]>;
  rollup_id?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "company_licenses" */
export type CompanyLicensesAggregate = {
  aggregate?: Maybe<CompanyLicensesAggregateFields>;
  nodes: Array<CompanyLicenses>;
};

/** aggregate fields of "company_licenses" */
export type CompanyLicensesAggregateFields = {
  avg?: Maybe<CompanyLicensesAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<CompanyLicensesMaxFields>;
  min?: Maybe<CompanyLicensesMinFields>;
  stddev?: Maybe<CompanyLicensesStddevFields>;
  stddev_pop?: Maybe<CompanyLicensesStddevPopFields>;
  stddev_samp?: Maybe<CompanyLicensesStddevSampFields>;
  sum?: Maybe<CompanyLicensesSumFields>;
  var_pop?: Maybe<CompanyLicensesVarPopFields>;
  var_samp?: Maybe<CompanyLicensesVarSampFields>;
  variance?: Maybe<CompanyLicensesVarianceFields>;
};

/** aggregate fields of "company_licenses" */
export type CompanyLicensesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CompanyLicensesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "company_licenses" */
export type CompanyLicensesAggregateOrderBy = {
  avg?: Maybe<CompanyLicensesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<CompanyLicensesMaxOrderBy>;
  min?: Maybe<CompanyLicensesMinOrderBy>;
  stddev?: Maybe<CompanyLicensesStddevOrderBy>;
  stddev_pop?: Maybe<CompanyLicensesStddevPopOrderBy>;
  stddev_samp?: Maybe<CompanyLicensesStddevSampOrderBy>;
  sum?: Maybe<CompanyLicensesSumOrderBy>;
  var_pop?: Maybe<CompanyLicensesVarPopOrderBy>;
  var_samp?: Maybe<CompanyLicensesVarSampOrderBy>;
  variance?: Maybe<CompanyLicensesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "company_licenses" */
export type CompanyLicensesArrRelInsertInput = {
  data: Array<CompanyLicensesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<CompanyLicensesOnConflict>;
};

/** aggregate avg on columns */
export type CompanyLicensesAvgFields = {
  estimate_latitude?: Maybe<Scalars["Float"]>;
  estimate_longitude?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "company_licenses" */
export type CompanyLicensesAvgOrderBy = {
  estimate_latitude?: Maybe<OrderBy>;
  estimate_longitude?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "company_licenses". All fields are combined with a logical 'AND'. */
export type CompanyLicensesBoolExp = {
  _and?: Maybe<Array<CompanyLicensesBoolExp>>;
  _not?: Maybe<CompanyLicensesBoolExp>;
  _or?: Maybe<Array<CompanyLicensesBoolExp>>;
  company?: Maybe<CompaniesBoolExp>;
  company_facility?: Maybe<CompanyFacilitiesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  dba_name?: Maybe<StringComparisonExp>;
  estimate_latitude?: Maybe<NumericComparisonExp>;
  estimate_longitude?: Maybe<NumericComparisonExp>;
  estimate_zip?: Maybe<StringComparisonExp>;
  expiration_date?: Maybe<DateComparisonExp>;
  facility_row_id?: Maybe<UuidComparisonExp>;
  file?: Maybe<FilesBoolExp>;
  file_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_current?: Maybe<BooleanComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  is_underwriting_enabled?: Maybe<BooleanComparisonExp>;
  legal_name?: Maybe<StringComparisonExp>;
  license_category?: Maybe<StringComparisonExp>;
  license_description?: Maybe<StringComparisonExp>;
  license_number?: Maybe<StringComparisonExp>;
  license_status?: Maybe<StringComparisonExp>;
  rollup_id?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  us_state?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "company_licenses" */
export enum CompanyLicensesConstraint {
  /** unique or primary key constraint */
  CompanyLicensePkey = "company_license_pkey",
  /** unique or primary key constraint */
  CompanyLicensesLicenseNumberKey = "company_licenses_license_number_key",
}

/** input type for incrementing numeric columns in table "company_licenses" */
export type CompanyLicensesIncInput = {
  estimate_latitude?: Maybe<Scalars["numeric"]>;
  estimate_longitude?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "company_licenses" */
export type CompanyLicensesInsertInput = {
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_facility?: Maybe<CompanyFacilitiesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  estimate_latitude?: Maybe<Scalars["numeric"]>;
  estimate_longitude?: Maybe<Scalars["numeric"]>;
  estimate_zip?: Maybe<Scalars["String"]>;
  expiration_date?: Maybe<Scalars["date"]>;
  facility_row_id?: Maybe<Scalars["uuid"]>;
  file?: Maybe<FilesObjRelInsertInput>;
  file_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_current?: Maybe<Scalars["Boolean"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  is_underwriting_enabled?: Maybe<Scalars["Boolean"]>;
  legal_name?: Maybe<Scalars["String"]>;
  license_category?: Maybe<Scalars["String"]>;
  license_description?: Maybe<Scalars["String"]>;
  license_number?: Maybe<Scalars["String"]>;
  license_status?: Maybe<Scalars["String"]>;
  rollup_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type CompanyLicensesMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  estimate_latitude?: Maybe<Scalars["numeric"]>;
  estimate_longitude?: Maybe<Scalars["numeric"]>;
  estimate_zip?: Maybe<Scalars["String"]>;
  expiration_date?: Maybe<Scalars["date"]>;
  facility_row_id?: Maybe<Scalars["uuid"]>;
  file_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  legal_name?: Maybe<Scalars["String"]>;
  license_category?: Maybe<Scalars["String"]>;
  license_description?: Maybe<Scalars["String"]>;
  license_number?: Maybe<Scalars["String"]>;
  license_status?: Maybe<Scalars["String"]>;
  rollup_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "company_licenses" */
export type CompanyLicensesMaxOrderBy = {
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  dba_name?: Maybe<OrderBy>;
  estimate_latitude?: Maybe<OrderBy>;
  estimate_longitude?: Maybe<OrderBy>;
  estimate_zip?: Maybe<OrderBy>;
  expiration_date?: Maybe<OrderBy>;
  facility_row_id?: Maybe<OrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  legal_name?: Maybe<OrderBy>;
  license_category?: Maybe<OrderBy>;
  license_description?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  license_status?: Maybe<OrderBy>;
  rollup_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type CompanyLicensesMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  estimate_latitude?: Maybe<Scalars["numeric"]>;
  estimate_longitude?: Maybe<Scalars["numeric"]>;
  estimate_zip?: Maybe<Scalars["String"]>;
  expiration_date?: Maybe<Scalars["date"]>;
  facility_row_id?: Maybe<Scalars["uuid"]>;
  file_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  legal_name?: Maybe<Scalars["String"]>;
  license_category?: Maybe<Scalars["String"]>;
  license_description?: Maybe<Scalars["String"]>;
  license_number?: Maybe<Scalars["String"]>;
  license_status?: Maybe<Scalars["String"]>;
  rollup_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "company_licenses" */
export type CompanyLicensesMinOrderBy = {
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  dba_name?: Maybe<OrderBy>;
  estimate_latitude?: Maybe<OrderBy>;
  estimate_longitude?: Maybe<OrderBy>;
  estimate_zip?: Maybe<OrderBy>;
  expiration_date?: Maybe<OrderBy>;
  facility_row_id?: Maybe<OrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  legal_name?: Maybe<OrderBy>;
  license_category?: Maybe<OrderBy>;
  license_description?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  license_status?: Maybe<OrderBy>;
  rollup_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** response of any mutation on the table "company_licenses" */
export type CompanyLicensesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CompanyLicenses>;
};

/** on_conflict condition type for table "company_licenses" */
export type CompanyLicensesOnConflict = {
  constraint: CompanyLicensesConstraint;
  update_columns?: Array<CompanyLicensesUpdateColumn>;
  where?: Maybe<CompanyLicensesBoolExp>;
};

/** Ordering options when selecting data from "company_licenses". */
export type CompanyLicensesOrderBy = {
  company?: Maybe<CompaniesOrderBy>;
  company_facility?: Maybe<CompanyFacilitiesOrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  dba_name?: Maybe<OrderBy>;
  estimate_latitude?: Maybe<OrderBy>;
  estimate_longitude?: Maybe<OrderBy>;
  estimate_zip?: Maybe<OrderBy>;
  expiration_date?: Maybe<OrderBy>;
  facility_row_id?: Maybe<OrderBy>;
  file?: Maybe<FilesOrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_current?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  is_underwriting_enabled?: Maybe<OrderBy>;
  legal_name?: Maybe<OrderBy>;
  license_category?: Maybe<OrderBy>;
  license_description?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  license_status?: Maybe<OrderBy>;
  rollup_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** primary key columns input for table: company_licenses */
export type CompanyLicensesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "company_licenses" */
export enum CompanyLicensesSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DbaName = "dba_name",
  /** column name */
  EstimateLatitude = "estimate_latitude",
  /** column name */
  EstimateLongitude = "estimate_longitude",
  /** column name */
  EstimateZip = "estimate_zip",
  /** column name */
  ExpirationDate = "expiration_date",
  /** column name */
  FacilityRowId = "facility_row_id",
  /** column name */
  FileId = "file_id",
  /** column name */
  Id = "id",
  /** column name */
  IsCurrent = "is_current",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  IsUnderwritingEnabled = "is_underwriting_enabled",
  /** column name */
  LegalName = "legal_name",
  /** column name */
  LicenseCategory = "license_category",
  /** column name */
  LicenseDescription = "license_description",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  LicenseStatus = "license_status",
  /** column name */
  RollupId = "rollup_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** input type for updating data in table "company_licenses" */
export type CompanyLicensesSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  estimate_latitude?: Maybe<Scalars["numeric"]>;
  estimate_longitude?: Maybe<Scalars["numeric"]>;
  estimate_zip?: Maybe<Scalars["String"]>;
  expiration_date?: Maybe<Scalars["date"]>;
  facility_row_id?: Maybe<Scalars["uuid"]>;
  file_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_current?: Maybe<Scalars["Boolean"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  is_underwriting_enabled?: Maybe<Scalars["Boolean"]>;
  legal_name?: Maybe<Scalars["String"]>;
  license_category?: Maybe<Scalars["String"]>;
  license_description?: Maybe<Scalars["String"]>;
  license_number?: Maybe<Scalars["String"]>;
  license_status?: Maybe<Scalars["String"]>;
  rollup_id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type CompanyLicensesStddevFields = {
  estimate_latitude?: Maybe<Scalars["Float"]>;
  estimate_longitude?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "company_licenses" */
export type CompanyLicensesStddevOrderBy = {
  estimate_latitude?: Maybe<OrderBy>;
  estimate_longitude?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CompanyLicensesStddevPopFields = {
  estimate_latitude?: Maybe<Scalars["Float"]>;
  estimate_longitude?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "company_licenses" */
export type CompanyLicensesStddevPopOrderBy = {
  estimate_latitude?: Maybe<OrderBy>;
  estimate_longitude?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CompanyLicensesStddevSampFields = {
  estimate_latitude?: Maybe<Scalars["Float"]>;
  estimate_longitude?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "company_licenses" */
export type CompanyLicensesStddevSampOrderBy = {
  estimate_latitude?: Maybe<OrderBy>;
  estimate_longitude?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type CompanyLicensesSumFields = {
  estimate_latitude?: Maybe<Scalars["numeric"]>;
  estimate_longitude?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "company_licenses" */
export type CompanyLicensesSumOrderBy = {
  estimate_latitude?: Maybe<OrderBy>;
  estimate_longitude?: Maybe<OrderBy>;
};

/** update columns of table "company_licenses" */
export enum CompanyLicensesUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DbaName = "dba_name",
  /** column name */
  EstimateLatitude = "estimate_latitude",
  /** column name */
  EstimateLongitude = "estimate_longitude",
  /** column name */
  EstimateZip = "estimate_zip",
  /** column name */
  ExpirationDate = "expiration_date",
  /** column name */
  FacilityRowId = "facility_row_id",
  /** column name */
  FileId = "file_id",
  /** column name */
  Id = "id",
  /** column name */
  IsCurrent = "is_current",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  IsUnderwritingEnabled = "is_underwriting_enabled",
  /** column name */
  LegalName = "legal_name",
  /** column name */
  LicenseCategory = "license_category",
  /** column name */
  LicenseDescription = "license_description",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  LicenseStatus = "license_status",
  /** column name */
  RollupId = "rollup_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** aggregate var_pop on columns */
export type CompanyLicensesVarPopFields = {
  estimate_latitude?: Maybe<Scalars["Float"]>;
  estimate_longitude?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "company_licenses" */
export type CompanyLicensesVarPopOrderBy = {
  estimate_latitude?: Maybe<OrderBy>;
  estimate_longitude?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CompanyLicensesVarSampFields = {
  estimate_latitude?: Maybe<Scalars["Float"]>;
  estimate_longitude?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "company_licenses" */
export type CompanyLicensesVarSampOrderBy = {
  estimate_latitude?: Maybe<OrderBy>;
  estimate_longitude?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type CompanyLicensesVarianceFields = {
  estimate_latitude?: Maybe<Scalars["Float"]>;
  estimate_longitude?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "company_licenses" */
export type CompanyLicensesVarianceOrderBy = {
  estimate_latitude?: Maybe<OrderBy>;
  estimate_longitude?: Maybe<OrderBy>;
};

/** columns and relationships of "company_partnership_invitations" */
export type CompanyPartnershipInvitations = {
  closed_at?: Maybe<Scalars["timestamptz"]>;
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  email: Scalars["String"];
  id: Scalars["uuid"];
  metadata_info: Scalars["json"];
  requested_at: Scalars["timestamptz"];
  /** An object relationship */
  requesting_company: Companies;
  requesting_company_id: Scalars["uuid"];
  /** An object relationship */
  submitted_by_user?: Maybe<Users>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "company_partnership_invitations" */
export type CompanyPartnershipInvitationsMetadataInfoArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "company_partnership_invitations" */
export type CompanyPartnershipInvitationsAggregate = {
  aggregate?: Maybe<CompanyPartnershipInvitationsAggregateFields>;
  nodes: Array<CompanyPartnershipInvitations>;
};

/** aggregate fields of "company_partnership_invitations" */
export type CompanyPartnershipInvitationsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<CompanyPartnershipInvitationsMaxFields>;
  min?: Maybe<CompanyPartnershipInvitationsMinFields>;
};

/** aggregate fields of "company_partnership_invitations" */
export type CompanyPartnershipInvitationsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CompanyPartnershipInvitationsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "company_partnership_invitations". All fields are combined with a logical 'AND'. */
export type CompanyPartnershipInvitationsBoolExp = {
  _and?: Maybe<Array<CompanyPartnershipInvitationsBoolExp>>;
  _not?: Maybe<CompanyPartnershipInvitationsBoolExp>;
  _or?: Maybe<Array<CompanyPartnershipInvitationsBoolExp>>;
  closed_at?: Maybe<TimestamptzComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  metadata_info?: Maybe<JsonComparisonExp>;
  requested_at?: Maybe<TimestamptzComparisonExp>;
  requesting_company?: Maybe<CompaniesBoolExp>;
  requesting_company_id?: Maybe<UuidComparisonExp>;
  submitted_by_user?: Maybe<UsersBoolExp>;
  submitted_by_user_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "company_partnership_invitations" */
export enum CompanyPartnershipInvitationsConstraint {
  /** unique or primary key constraint */
  CompanyPartnershipInvitationsPkey = "company_partnership_invitations_pkey",
}

/** input type for inserting data into table "company_partnership_invitations" */
export type CompanyPartnershipInvitationsInsertInput = {
  closed_at?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  metadata_info?: Maybe<Scalars["json"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  requesting_company?: Maybe<CompaniesObjRelInsertInput>;
  requesting_company_id?: Maybe<Scalars["uuid"]>;
  submitted_by_user?: Maybe<UsersObjRelInsertInput>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type CompanyPartnershipInvitationsMaxFields = {
  closed_at?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  requesting_company_id?: Maybe<Scalars["uuid"]>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type CompanyPartnershipInvitationsMinFields = {
  closed_at?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  requesting_company_id?: Maybe<Scalars["uuid"]>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "company_partnership_invitations" */
export type CompanyPartnershipInvitationsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CompanyPartnershipInvitations>;
};

/** on_conflict condition type for table "company_partnership_invitations" */
export type CompanyPartnershipInvitationsOnConflict = {
  constraint: CompanyPartnershipInvitationsConstraint;
  update_columns?: Array<CompanyPartnershipInvitationsUpdateColumn>;
  where?: Maybe<CompanyPartnershipInvitationsBoolExp>;
};

/** Ordering options when selecting data from "company_partnership_invitations". */
export type CompanyPartnershipInvitationsOrderBy = {
  closed_at?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  metadata_info?: Maybe<OrderBy>;
  requested_at?: Maybe<OrderBy>;
  requesting_company?: Maybe<CompaniesOrderBy>;
  requesting_company_id?: Maybe<OrderBy>;
  submitted_by_user?: Maybe<UsersOrderBy>;
  submitted_by_user_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: company_partnership_invitations */
export type CompanyPartnershipInvitationsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "company_partnership_invitations" */
export enum CompanyPartnershipInvitationsSelectColumn {
  /** column name */
  ClosedAt = "closed_at",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  MetadataInfo = "metadata_info",
  /** column name */
  RequestedAt = "requested_at",
  /** column name */
  RequestingCompanyId = "requesting_company_id",
  /** column name */
  SubmittedByUserId = "submitted_by_user_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "company_partnership_invitations" */
export type CompanyPartnershipInvitationsSetInput = {
  closed_at?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  metadata_info?: Maybe<Scalars["json"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  requesting_company_id?: Maybe<Scalars["uuid"]>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "company_partnership_invitations" */
export enum CompanyPartnershipInvitationsUpdateColumn {
  /** column name */
  ClosedAt = "closed_at",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  MetadataInfo = "metadata_info",
  /** column name */
  RequestedAt = "requested_at",
  /** column name */
  RequestingCompanyId = "requesting_company_id",
  /** column name */
  SubmittedByUserId = "submitted_by_user_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** columns and relationships of "company_partnership_requests" */
export type CompanyPartnershipRequests = {
  company_name: Scalars["String"];
  company_type: CompanyTypeEnum;
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  license_info?: Maybe<Scalars["json"]>;
  request_info?: Maybe<Scalars["json"]>;
  /** An object relationship */
  requested_by_user?: Maybe<Users>;
  requested_by_user_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  requesting_company: Companies;
  requesting_company_id: Scalars["uuid"];
  settled_at?: Maybe<Scalars["timestamptz"]>;
  settled_by_user_id?: Maybe<Scalars["uuid"]>;
  two_factor_message_method: Scalars["String"];
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_info: Scalars["json"];
};

/** columns and relationships of "company_partnership_requests" */
export type CompanyPartnershipRequestsLicenseInfoArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "company_partnership_requests" */
export type CompanyPartnershipRequestsRequestInfoArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "company_partnership_requests" */
export type CompanyPartnershipRequestsUserInfoArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "company_partnership_requests" */
export type CompanyPartnershipRequestsAggregate = {
  aggregate?: Maybe<CompanyPartnershipRequestsAggregateFields>;
  nodes: Array<CompanyPartnershipRequests>;
};

/** aggregate fields of "company_partnership_requests" */
export type CompanyPartnershipRequestsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<CompanyPartnershipRequestsMaxFields>;
  min?: Maybe<CompanyPartnershipRequestsMinFields>;
};

/** aggregate fields of "company_partnership_requests" */
export type CompanyPartnershipRequestsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CompanyPartnershipRequestsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "company_partnership_requests". All fields are combined with a logical 'AND'. */
export type CompanyPartnershipRequestsBoolExp = {
  _and?: Maybe<Array<CompanyPartnershipRequestsBoolExp>>;
  _not?: Maybe<CompanyPartnershipRequestsBoolExp>;
  _or?: Maybe<Array<CompanyPartnershipRequestsBoolExp>>;
  company_name?: Maybe<StringComparisonExp>;
  company_type?: Maybe<CompanyTypeEnumComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_cannabis?: Maybe<BooleanComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  license_info?: Maybe<JsonComparisonExp>;
  request_info?: Maybe<JsonComparisonExp>;
  requested_by_user?: Maybe<UsersBoolExp>;
  requested_by_user_id?: Maybe<UuidComparisonExp>;
  requesting_company?: Maybe<CompaniesBoolExp>;
  requesting_company_id?: Maybe<UuidComparisonExp>;
  settled_at?: Maybe<TimestamptzComparisonExp>;
  settled_by_user_id?: Maybe<UuidComparisonExp>;
  two_factor_message_method?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  user_info?: Maybe<JsonComparisonExp>;
};

/** unique or primary key constraints on table "company_partnership_requests" */
export enum CompanyPartnershipRequestsConstraint {
  /** unique or primary key constraint */
  CompanyPartnershipRequestsPkey = "company_partnership_requests_pkey",
}

/** input type for inserting data into table "company_partnership_requests" */
export type CompanyPartnershipRequestsInsertInput = {
  company_name?: Maybe<Scalars["String"]>;
  company_type?: Maybe<CompanyTypeEnum>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  license_info?: Maybe<Scalars["json"]>;
  request_info?: Maybe<Scalars["json"]>;
  requested_by_user?: Maybe<UsersObjRelInsertInput>;
  requested_by_user_id?: Maybe<Scalars["uuid"]>;
  requesting_company?: Maybe<CompaniesObjRelInsertInput>;
  requesting_company_id?: Maybe<Scalars["uuid"]>;
  settled_at?: Maybe<Scalars["timestamptz"]>;
  settled_by_user_id?: Maybe<Scalars["uuid"]>;
  two_factor_message_method?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_info?: Maybe<Scalars["json"]>;
};

/** aggregate max on columns */
export type CompanyPartnershipRequestsMaxFields = {
  company_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  requested_by_user_id?: Maybe<Scalars["uuid"]>;
  requesting_company_id?: Maybe<Scalars["uuid"]>;
  settled_at?: Maybe<Scalars["timestamptz"]>;
  settled_by_user_id?: Maybe<Scalars["uuid"]>;
  two_factor_message_method?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type CompanyPartnershipRequestsMinFields = {
  company_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  requested_by_user_id?: Maybe<Scalars["uuid"]>;
  requesting_company_id?: Maybe<Scalars["uuid"]>;
  settled_at?: Maybe<Scalars["timestamptz"]>;
  settled_by_user_id?: Maybe<Scalars["uuid"]>;
  two_factor_message_method?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "company_partnership_requests" */
export type CompanyPartnershipRequestsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CompanyPartnershipRequests>;
};

/** on_conflict condition type for table "company_partnership_requests" */
export type CompanyPartnershipRequestsOnConflict = {
  constraint: CompanyPartnershipRequestsConstraint;
  update_columns?: Array<CompanyPartnershipRequestsUpdateColumn>;
  where?: Maybe<CompanyPartnershipRequestsBoolExp>;
};

/** Ordering options when selecting data from "company_partnership_requests". */
export type CompanyPartnershipRequestsOrderBy = {
  company_name?: Maybe<OrderBy>;
  company_type?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_cannabis?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  license_info?: Maybe<OrderBy>;
  request_info?: Maybe<OrderBy>;
  requested_by_user?: Maybe<UsersOrderBy>;
  requested_by_user_id?: Maybe<OrderBy>;
  requesting_company?: Maybe<CompaniesOrderBy>;
  requesting_company_id?: Maybe<OrderBy>;
  settled_at?: Maybe<OrderBy>;
  settled_by_user_id?: Maybe<OrderBy>;
  two_factor_message_method?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_info?: Maybe<OrderBy>;
};

/** primary key columns input for table: company_partnership_requests */
export type CompanyPartnershipRequestsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "company_partnership_requests" */
export enum CompanyPartnershipRequestsSelectColumn {
  /** column name */
  CompanyName = "company_name",
  /** column name */
  CompanyType = "company_type",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsCannabis = "is_cannabis",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  LicenseInfo = "license_info",
  /** column name */
  RequestInfo = "request_info",
  /** column name */
  RequestedByUserId = "requested_by_user_id",
  /** column name */
  RequestingCompanyId = "requesting_company_id",
  /** column name */
  SettledAt = "settled_at",
  /** column name */
  SettledByUserId = "settled_by_user_id",
  /** column name */
  TwoFactorMessageMethod = "two_factor_message_method",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserInfo = "user_info",
}

/** input type for updating data in table "company_partnership_requests" */
export type CompanyPartnershipRequestsSetInput = {
  company_name?: Maybe<Scalars["String"]>;
  company_type?: Maybe<CompanyTypeEnum>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  license_info?: Maybe<Scalars["json"]>;
  request_info?: Maybe<Scalars["json"]>;
  requested_by_user_id?: Maybe<Scalars["uuid"]>;
  requesting_company_id?: Maybe<Scalars["uuid"]>;
  settled_at?: Maybe<Scalars["timestamptz"]>;
  settled_by_user_id?: Maybe<Scalars["uuid"]>;
  two_factor_message_method?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_info?: Maybe<Scalars["json"]>;
};

/** update columns of table "company_partnership_requests" */
export enum CompanyPartnershipRequestsUpdateColumn {
  /** column name */
  CompanyName = "company_name",
  /** column name */
  CompanyType = "company_type",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsCannabis = "is_cannabis",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  LicenseInfo = "license_info",
  /** column name */
  RequestInfo = "request_info",
  /** column name */
  RequestedByUserId = "requested_by_user_id",
  /** column name */
  RequestingCompanyId = "requesting_company_id",
  /** column name */
  SettledAt = "settled_at",
  /** column name */
  SettledByUserId = "settled_by_user_id",
  /** column name */
  TwoFactorMessageMethod = "two_factor_message_method",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserInfo = "user_info",
}

/** columns and relationships of "company_payor_contacts" */
export type CompanyPayorContacts = {
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  partnership_id: Scalars["uuid"];
  payor_user_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "company_payor_contacts" */
export type CompanyPayorContactsAggregate = {
  aggregate?: Maybe<CompanyPayorContactsAggregateFields>;
  nodes: Array<CompanyPayorContacts>;
};

/** aggregate fields of "company_payor_contacts" */
export type CompanyPayorContactsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<CompanyPayorContactsMaxFields>;
  min?: Maybe<CompanyPayorContactsMinFields>;
};

/** aggregate fields of "company_payor_contacts" */
export type CompanyPayorContactsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CompanyPayorContactsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "company_payor_contacts" */
export type CompanyPayorContactsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<CompanyPayorContactsMaxOrderBy>;
  min?: Maybe<CompanyPayorContactsMinOrderBy>;
};

/** input type for inserting array relation for remote table "company_payor_contacts" */
export type CompanyPayorContactsArrRelInsertInput = {
  data: Array<CompanyPayorContactsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<CompanyPayorContactsOnConflict>;
};

/** Boolean expression to filter rows from the table "company_payor_contacts". All fields are combined with a logical 'AND'. */
export type CompanyPayorContactsBoolExp = {
  _and?: Maybe<Array<CompanyPayorContactsBoolExp>>;
  _not?: Maybe<CompanyPayorContactsBoolExp>;
  _or?: Maybe<Array<CompanyPayorContactsBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  partnership_id?: Maybe<UuidComparisonExp>;
  payor_user_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "company_payor_contacts" */
export enum CompanyPayorContactsConstraint {
  /** unique or primary key constraint */
  CompanyPayorContactsPkey = "company_payor_contacts_pkey",
}

/** input type for inserting data into table "company_payor_contacts" */
export type CompanyPayorContactsInsertInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  partnership_id?: Maybe<Scalars["uuid"]>;
  payor_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user?: Maybe<UsersObjRelInsertInput>;
};

/** aggregate max on columns */
export type CompanyPayorContactsMaxFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  partnership_id?: Maybe<Scalars["uuid"]>;
  payor_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "company_payor_contacts" */
export type CompanyPayorContactsMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  partnership_id?: Maybe<OrderBy>;
  payor_user_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type CompanyPayorContactsMinFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  partnership_id?: Maybe<Scalars["uuid"]>;
  payor_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "company_payor_contacts" */
export type CompanyPayorContactsMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  partnership_id?: Maybe<OrderBy>;
  payor_user_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "company_payor_contacts" */
export type CompanyPayorContactsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CompanyPayorContacts>;
};

/** on_conflict condition type for table "company_payor_contacts" */
export type CompanyPayorContactsOnConflict = {
  constraint: CompanyPayorContactsConstraint;
  update_columns?: Array<CompanyPayorContactsUpdateColumn>;
  where?: Maybe<CompanyPayorContactsBoolExp>;
};

/** Ordering options when selecting data from "company_payor_contacts". */
export type CompanyPayorContactsOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  partnership_id?: Maybe<OrderBy>;
  payor_user_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
};

/** primary key columns input for table: company_payor_contacts */
export type CompanyPayorContactsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "company_payor_contacts" */
export enum CompanyPayorContactsSelectColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  PartnershipId = "partnership_id",
  /** column name */
  PayorUserId = "payor_user_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "company_payor_contacts" */
export type CompanyPayorContactsSetInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  partnership_id?: Maybe<Scalars["uuid"]>;
  payor_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "company_payor_contacts" */
export enum CompanyPayorContactsUpdateColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  PartnershipId = "partnership_id",
  /** column name */
  PayorUserId = "payor_user_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** columns and relationships of "company_payor_partnerships" */
export type CompanyPayorPartnerships = {
  approved_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  company: Companies;
  company_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  payor?: Maybe<Payors>;
  /** An object relationship */
  payor_agreement?: Maybe<CompanyAgreements>;
  payor_agreement_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  payor_contacts: Array<CompanyPayorContacts>;
  /** An aggregate relationship */
  payor_contacts_aggregate: CompanyPayorContactsAggregate;
  payor_id: Scalars["uuid"];
  /** Currently not used */
  payor_license_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  payor_limited?: Maybe<Payors>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** columns and relationships of "company_payor_partnerships" */
export type CompanyPayorPartnershipsPayorContactsArgs = {
  distinct_on?: Maybe<Array<CompanyPayorContactsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorContactsOrderBy>>;
  where?: Maybe<CompanyPayorContactsBoolExp>;
};

/** columns and relationships of "company_payor_partnerships" */
export type CompanyPayorPartnershipsPayorContactsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyPayorContactsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorContactsOrderBy>>;
  where?: Maybe<CompanyPayorContactsBoolExp>;
};

/** aggregated selection of "company_payor_partnerships" */
export type CompanyPayorPartnershipsAggregate = {
  aggregate?: Maybe<CompanyPayorPartnershipsAggregateFields>;
  nodes: Array<CompanyPayorPartnerships>;
};

/** aggregate fields of "company_payor_partnerships" */
export type CompanyPayorPartnershipsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<CompanyPayorPartnershipsMaxFields>;
  min?: Maybe<CompanyPayorPartnershipsMinFields>;
};

/** aggregate fields of "company_payor_partnerships" */
export type CompanyPayorPartnershipsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CompanyPayorPartnershipsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "company_payor_partnerships" */
export type CompanyPayorPartnershipsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<CompanyPayorPartnershipsMaxOrderBy>;
  min?: Maybe<CompanyPayorPartnershipsMinOrderBy>;
};

/** input type for inserting array relation for remote table "company_payor_partnerships" */
export type CompanyPayorPartnershipsArrRelInsertInput = {
  data: Array<CompanyPayorPartnershipsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<CompanyPayorPartnershipsOnConflict>;
};

/** Boolean expression to filter rows from the table "company_payor_partnerships". All fields are combined with a logical 'AND'. */
export type CompanyPayorPartnershipsBoolExp = {
  _and?: Maybe<Array<CompanyPayorPartnershipsBoolExp>>;
  _not?: Maybe<CompanyPayorPartnershipsBoolExp>;
  _or?: Maybe<Array<CompanyPayorPartnershipsBoolExp>>;
  approved_at?: Maybe<TimestamptzComparisonExp>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  payor?: Maybe<PayorsBoolExp>;
  payor_agreement?: Maybe<CompanyAgreementsBoolExp>;
  payor_agreement_id?: Maybe<UuidComparisonExp>;
  payor_contacts?: Maybe<CompanyPayorContactsBoolExp>;
  payor_id?: Maybe<UuidComparisonExp>;
  payor_license_id?: Maybe<UuidComparisonExp>;
  payor_limited?: Maybe<PayorsBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "company_payor_partnerships" */
export enum CompanyPayorPartnershipsConstraint {
  /** unique or primary key constraint */
  CompanyPayorPartnershipsCompanyIdPayorIdKey = "company_payor_partnerships_company_id_payor_id_key",
  /** unique or primary key constraint */
  CompanyPayorPartnershipsPkey = "company_payor_partnerships_pkey",
}

/** input type for inserting data into table "company_payor_partnerships" */
export type CompanyPayorPartnershipsInsertInput = {
  approved_at?: Maybe<Scalars["timestamptz"]>;
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  payor?: Maybe<PayorsObjRelInsertInput>;
  payor_agreement?: Maybe<CompanyAgreementsObjRelInsertInput>;
  payor_agreement_id?: Maybe<Scalars["uuid"]>;
  payor_contacts?: Maybe<CompanyPayorContactsArrRelInsertInput>;
  payor_id?: Maybe<Scalars["uuid"]>;
  /** Currently not used */
  payor_license_id?: Maybe<Scalars["uuid"]>;
  payor_limited?: Maybe<PayorsObjRelInsertInput>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type CompanyPayorPartnershipsMaxFields = {
  approved_at?: Maybe<Scalars["timestamptz"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  payor_agreement_id?: Maybe<Scalars["uuid"]>;
  payor_id?: Maybe<Scalars["uuid"]>;
  /** Currently not used */
  payor_license_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "company_payor_partnerships" */
export type CompanyPayorPartnershipsMaxOrderBy = {
  approved_at?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  payor_agreement_id?: Maybe<OrderBy>;
  payor_id?: Maybe<OrderBy>;
  /** Currently not used */
  payor_license_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type CompanyPayorPartnershipsMinFields = {
  approved_at?: Maybe<Scalars["timestamptz"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  payor_agreement_id?: Maybe<Scalars["uuid"]>;
  payor_id?: Maybe<Scalars["uuid"]>;
  /** Currently not used */
  payor_license_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "company_payor_partnerships" */
export type CompanyPayorPartnershipsMinOrderBy = {
  approved_at?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  payor_agreement_id?: Maybe<OrderBy>;
  payor_id?: Maybe<OrderBy>;
  /** Currently not used */
  payor_license_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "company_payor_partnerships" */
export type CompanyPayorPartnershipsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CompanyPayorPartnerships>;
};

/** on_conflict condition type for table "company_payor_partnerships" */
export type CompanyPayorPartnershipsOnConflict = {
  constraint: CompanyPayorPartnershipsConstraint;
  update_columns?: Array<CompanyPayorPartnershipsUpdateColumn>;
  where?: Maybe<CompanyPayorPartnershipsBoolExp>;
};

/** Ordering options when selecting data from "company_payor_partnerships". */
export type CompanyPayorPartnershipsOrderBy = {
  approved_at?: Maybe<OrderBy>;
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  payor?: Maybe<PayorsOrderBy>;
  payor_agreement?: Maybe<CompanyAgreementsOrderBy>;
  payor_agreement_id?: Maybe<OrderBy>;
  payor_contacts_aggregate?: Maybe<CompanyPayorContactsAggregateOrderBy>;
  payor_id?: Maybe<OrderBy>;
  payor_license_id?: Maybe<OrderBy>;
  payor_limited?: Maybe<PayorsOrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: company_payor_partnerships */
export type CompanyPayorPartnershipsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "company_payor_partnerships" */
export enum CompanyPayorPartnershipsSelectColumn {
  /** column name */
  ApprovedAt = "approved_at",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  PayorAgreementId = "payor_agreement_id",
  /** column name */
  PayorId = "payor_id",
  /** column name */
  PayorLicenseId = "payor_license_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "company_payor_partnerships" */
export type CompanyPayorPartnershipsSetInput = {
  approved_at?: Maybe<Scalars["timestamptz"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  payor_agreement_id?: Maybe<Scalars["uuid"]>;
  payor_id?: Maybe<Scalars["uuid"]>;
  /** Currently not used */
  payor_license_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "company_payor_partnerships" */
export enum CompanyPayorPartnershipsUpdateColumn {
  /** column name */
  ApprovedAt = "approved_at",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  PayorAgreementId = "payor_agreement_id",
  /** column name */
  PayorId = "payor_id",
  /** column name */
  PayorLicenseId = "payor_license_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** Settings are configuration details associated with a company, but are not within a time range like contracts are */
export type CompanySettings = {
  /** An object relationship */
  active_borrowing_base?: Maybe<EbbaApplications>;
  /** If relevant, this foreign key points to the current active borrowing_base (ebba_application) for this company */
  active_borrowing_base_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  active_ebba_application?: Maybe<EbbaApplications>;
  /** If relevant, this foreign key points to the current active ebba_application for this company */
  active_ebba_application_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  active_financial_report?: Maybe<EbbaApplications>;
  /** If relevant, this foreign key points to the current active financial_report (ebba_application) for this company */
  active_financial_report_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  advances_bank_account?: Maybe<BankAccounts>;
  /** For CUSTOMER companies, this is the bank account which Bespoke Financial sends advances TO */
  advances_bank_account_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  advances_bespoke_bank_account?: Maybe<BankAccounts>;
  /** For CUSTOMER companies, this is the bank account which Bespoke Financial sends advances FROM */
  advances_bespoke_bank_account_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  business_development_user?: Maybe<Users>;
  business_development_user_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  client_success_user?: Maybe<Users>;
  client_success_user_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  collections_bank_account?: Maybe<BankAccounts>;
  /** For CUSTOMER and PAYOR companies, this is the bank account which company sends payments FROM */
  collections_bank_account_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  collections_bespoke_bank_account?: Maybe<BankAccounts>;
  /** For CUSTOMER and PAYOR companies, this is the Bespoke Financial bank account company sends payments TO */
  collections_bespoke_bank_account_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  company?: Maybe<Companies>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at: Scalars["timestamptz"];
  /** JSON blob which records custom messages for a company (messages shown in various places in app) */
  custom_messages_payload?: Maybe<Scalars["jsonb"]>;
  /** JSON blob which records what features are on / off for a company */
  feature_flags_payload?: Maybe<Scalars["jsonb"]>;
  has_autofinancing?: Maybe<Scalars["Boolean"]>;
  id: Scalars["uuid"];
  interest_end_date?: Maybe<Scalars["date"]>;
  is_autogenerate_repayments_enabled?: Maybe<Scalars["Boolean"]>;
  is_dummy_account: Scalars["Boolean"];
  late_fees_end_date?: Maybe<Scalars["date"]>;
  /** An object relationship */
  metrc_api_key?: Maybe<MetrcApiKeys>;
  metrc_api_key_id?: Maybe<Scalars["uuid"]>;
  payor_agreement_docusign_template?: Maybe<Scalars["String"]>;
  two_factor_message_method?: Maybe<Scalars["String"]>;
  /** An object relationship */
  underwriter_user?: Maybe<Users>;
  underwriter_user_id?: Maybe<Scalars["uuid"]>;
  updated_at: Scalars["timestamptz"];
  vendor_agreement_docusign_template?: Maybe<Scalars["String"]>;
  vendor_onboarding_link?: Maybe<Scalars["String"]>;
  write_off_date?: Maybe<Scalars["date"]>;
};

/** Settings are configuration details associated with a company, but are not within a time range like contracts are */
export type CompanySettingsCustomMessagesPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** Settings are configuration details associated with a company, but are not within a time range like contracts are */
export type CompanySettingsFeatureFlagsPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "company_settings" */
export type CompanySettingsAggregate = {
  aggregate?: Maybe<CompanySettingsAggregateFields>;
  nodes: Array<CompanySettings>;
};

/** aggregate fields of "company_settings" */
export type CompanySettingsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<CompanySettingsMaxFields>;
  min?: Maybe<CompanySettingsMinFields>;
};

/** aggregate fields of "company_settings" */
export type CompanySettingsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CompanySettingsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "company_settings" */
export type CompanySettingsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<CompanySettingsMaxOrderBy>;
  min?: Maybe<CompanySettingsMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CompanySettingsAppendInput = {
  /** JSON blob which records custom messages for a company (messages shown in various places in app) */
  custom_messages_payload?: Maybe<Scalars["jsonb"]>;
  /** JSON blob which records what features are on / off for a company */
  feature_flags_payload?: Maybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "company_settings" */
export type CompanySettingsArrRelInsertInput = {
  data: Array<CompanySettingsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<CompanySettingsOnConflict>;
};

/** Boolean expression to filter rows from the table "company_settings". All fields are combined with a logical 'AND'. */
export type CompanySettingsBoolExp = {
  _and?: Maybe<Array<CompanySettingsBoolExp>>;
  _not?: Maybe<CompanySettingsBoolExp>;
  _or?: Maybe<Array<CompanySettingsBoolExp>>;
  active_borrowing_base?: Maybe<EbbaApplicationsBoolExp>;
  active_borrowing_base_id?: Maybe<UuidComparisonExp>;
  active_ebba_application?: Maybe<EbbaApplicationsBoolExp>;
  active_ebba_application_id?: Maybe<UuidComparisonExp>;
  active_financial_report?: Maybe<EbbaApplicationsBoolExp>;
  active_financial_report_id?: Maybe<UuidComparisonExp>;
  advances_bank_account?: Maybe<BankAccountsBoolExp>;
  advances_bank_account_id?: Maybe<UuidComparisonExp>;
  advances_bespoke_bank_account?: Maybe<BankAccountsBoolExp>;
  advances_bespoke_bank_account_id?: Maybe<UuidComparisonExp>;
  business_development_user?: Maybe<UsersBoolExp>;
  business_development_user_id?: Maybe<UuidComparisonExp>;
  client_success_user?: Maybe<UsersBoolExp>;
  client_success_user_id?: Maybe<UuidComparisonExp>;
  collections_bank_account?: Maybe<BankAccountsBoolExp>;
  collections_bank_account_id?: Maybe<UuidComparisonExp>;
  collections_bespoke_bank_account?: Maybe<BankAccountsBoolExp>;
  collections_bespoke_bank_account_id?: Maybe<UuidComparisonExp>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  custom_messages_payload?: Maybe<JsonbComparisonExp>;
  feature_flags_payload?: Maybe<JsonbComparisonExp>;
  has_autofinancing?: Maybe<BooleanComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  interest_end_date?: Maybe<DateComparisonExp>;
  is_autogenerate_repayments_enabled?: Maybe<BooleanComparisonExp>;
  is_dummy_account?: Maybe<BooleanComparisonExp>;
  late_fees_end_date?: Maybe<DateComparisonExp>;
  metrc_api_key?: Maybe<MetrcApiKeysBoolExp>;
  metrc_api_key_id?: Maybe<UuidComparisonExp>;
  payor_agreement_docusign_template?: Maybe<StringComparisonExp>;
  two_factor_message_method?: Maybe<StringComparisonExp>;
  underwriter_user?: Maybe<UsersBoolExp>;
  underwriter_user_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  vendor_agreement_docusign_template?: Maybe<StringComparisonExp>;
  vendor_onboarding_link?: Maybe<StringComparisonExp>;
  write_off_date?: Maybe<DateComparisonExp>;
};

/** unique or primary key constraints on table "company_settings" */
export enum CompanySettingsConstraint {
  /** unique or primary key constraint */
  CompanySettingsPkey = "company_settings_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CompanySettingsDeleteAtPathInput = {
  /** JSON blob which records custom messages for a company (messages shown in various places in app) */
  custom_messages_payload?: Maybe<Array<Scalars["String"]>>;
  /** JSON blob which records what features are on / off for a company */
  feature_flags_payload?: Maybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CompanySettingsDeleteElemInput = {
  /** JSON blob which records custom messages for a company (messages shown in various places in app) */
  custom_messages_payload?: Maybe<Scalars["Int"]>;
  /** JSON blob which records what features are on / off for a company */
  feature_flags_payload?: Maybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CompanySettingsDeleteKeyInput = {
  /** JSON blob which records custom messages for a company (messages shown in various places in app) */
  custom_messages_payload?: Maybe<Scalars["String"]>;
  /** JSON blob which records what features are on / off for a company */
  feature_flags_payload?: Maybe<Scalars["String"]>;
};

/** input type for inserting data into table "company_settings" */
export type CompanySettingsInsertInput = {
  active_borrowing_base?: Maybe<EbbaApplicationsObjRelInsertInput>;
  /** If relevant, this foreign key points to the current active borrowing_base (ebba_application) for this company */
  active_borrowing_base_id?: Maybe<Scalars["uuid"]>;
  active_ebba_application?: Maybe<EbbaApplicationsObjRelInsertInput>;
  /** If relevant, this foreign key points to the current active ebba_application for this company */
  active_ebba_application_id?: Maybe<Scalars["uuid"]>;
  active_financial_report?: Maybe<EbbaApplicationsObjRelInsertInput>;
  /** If relevant, this foreign key points to the current active financial_report (ebba_application) for this company */
  active_financial_report_id?: Maybe<Scalars["uuid"]>;
  advances_bank_account?: Maybe<BankAccountsObjRelInsertInput>;
  /** For CUSTOMER companies, this is the bank account which Bespoke Financial sends advances TO */
  advances_bank_account_id?: Maybe<Scalars["uuid"]>;
  advances_bespoke_bank_account?: Maybe<BankAccountsObjRelInsertInput>;
  /** For CUSTOMER companies, this is the bank account which Bespoke Financial sends advances FROM */
  advances_bespoke_bank_account_id?: Maybe<Scalars["uuid"]>;
  business_development_user?: Maybe<UsersObjRelInsertInput>;
  business_development_user_id?: Maybe<Scalars["uuid"]>;
  client_success_user?: Maybe<UsersObjRelInsertInput>;
  client_success_user_id?: Maybe<Scalars["uuid"]>;
  collections_bank_account?: Maybe<BankAccountsObjRelInsertInput>;
  /** For CUSTOMER and PAYOR companies, this is the bank account which company sends payments FROM */
  collections_bank_account_id?: Maybe<Scalars["uuid"]>;
  collections_bespoke_bank_account?: Maybe<BankAccountsObjRelInsertInput>;
  /** For CUSTOMER and PAYOR companies, this is the Bespoke Financial bank account company sends payments TO */
  collections_bespoke_bank_account_id?: Maybe<Scalars["uuid"]>;
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** JSON blob which records custom messages for a company (messages shown in various places in app) */
  custom_messages_payload?: Maybe<Scalars["jsonb"]>;
  /** JSON blob which records what features are on / off for a company */
  feature_flags_payload?: Maybe<Scalars["jsonb"]>;
  has_autofinancing?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["uuid"]>;
  interest_end_date?: Maybe<Scalars["date"]>;
  is_autogenerate_repayments_enabled?: Maybe<Scalars["Boolean"]>;
  is_dummy_account?: Maybe<Scalars["Boolean"]>;
  late_fees_end_date?: Maybe<Scalars["date"]>;
  metrc_api_key?: Maybe<MetrcApiKeysObjRelInsertInput>;
  metrc_api_key_id?: Maybe<Scalars["uuid"]>;
  payor_agreement_docusign_template?: Maybe<Scalars["String"]>;
  two_factor_message_method?: Maybe<Scalars["String"]>;
  underwriter_user?: Maybe<UsersObjRelInsertInput>;
  underwriter_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vendor_agreement_docusign_template?: Maybe<Scalars["String"]>;
  vendor_onboarding_link?: Maybe<Scalars["String"]>;
  write_off_date?: Maybe<Scalars["date"]>;
};

/** aggregate max on columns */
export type CompanySettingsMaxFields = {
  /** If relevant, this foreign key points to the current active borrowing_base (ebba_application) for this company */
  active_borrowing_base_id?: Maybe<Scalars["uuid"]>;
  /** If relevant, this foreign key points to the current active ebba_application for this company */
  active_ebba_application_id?: Maybe<Scalars["uuid"]>;
  /** If relevant, this foreign key points to the current active financial_report (ebba_application) for this company */
  active_financial_report_id?: Maybe<Scalars["uuid"]>;
  /** For CUSTOMER companies, this is the bank account which Bespoke Financial sends advances TO */
  advances_bank_account_id?: Maybe<Scalars["uuid"]>;
  /** For CUSTOMER companies, this is the bank account which Bespoke Financial sends advances FROM */
  advances_bespoke_bank_account_id?: Maybe<Scalars["uuid"]>;
  business_development_user_id?: Maybe<Scalars["uuid"]>;
  client_success_user_id?: Maybe<Scalars["uuid"]>;
  /** For CUSTOMER and PAYOR companies, this is the bank account which company sends payments FROM */
  collections_bank_account_id?: Maybe<Scalars["uuid"]>;
  /** For CUSTOMER and PAYOR companies, this is the Bespoke Financial bank account company sends payments TO */
  collections_bespoke_bank_account_id?: Maybe<Scalars["uuid"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interest_end_date?: Maybe<Scalars["date"]>;
  late_fees_end_date?: Maybe<Scalars["date"]>;
  metrc_api_key_id?: Maybe<Scalars["uuid"]>;
  payor_agreement_docusign_template?: Maybe<Scalars["String"]>;
  two_factor_message_method?: Maybe<Scalars["String"]>;
  underwriter_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vendor_agreement_docusign_template?: Maybe<Scalars["String"]>;
  vendor_onboarding_link?: Maybe<Scalars["String"]>;
  write_off_date?: Maybe<Scalars["date"]>;
};

/** order by max() on columns of table "company_settings" */
export type CompanySettingsMaxOrderBy = {
  /** If relevant, this foreign key points to the current active borrowing_base (ebba_application) for this company */
  active_borrowing_base_id?: Maybe<OrderBy>;
  /** If relevant, this foreign key points to the current active ebba_application for this company */
  active_ebba_application_id?: Maybe<OrderBy>;
  /** If relevant, this foreign key points to the current active financial_report (ebba_application) for this company */
  active_financial_report_id?: Maybe<OrderBy>;
  /** For CUSTOMER companies, this is the bank account which Bespoke Financial sends advances TO */
  advances_bank_account_id?: Maybe<OrderBy>;
  /** For CUSTOMER companies, this is the bank account which Bespoke Financial sends advances FROM */
  advances_bespoke_bank_account_id?: Maybe<OrderBy>;
  business_development_user_id?: Maybe<OrderBy>;
  client_success_user_id?: Maybe<OrderBy>;
  /** For CUSTOMER and PAYOR companies, this is the bank account which company sends payments FROM */
  collections_bank_account_id?: Maybe<OrderBy>;
  /** For CUSTOMER and PAYOR companies, this is the Bespoke Financial bank account company sends payments TO */
  collections_bespoke_bank_account_id?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  interest_end_date?: Maybe<OrderBy>;
  late_fees_end_date?: Maybe<OrderBy>;
  metrc_api_key_id?: Maybe<OrderBy>;
  payor_agreement_docusign_template?: Maybe<OrderBy>;
  two_factor_message_method?: Maybe<OrderBy>;
  underwriter_user_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  vendor_agreement_docusign_template?: Maybe<OrderBy>;
  vendor_onboarding_link?: Maybe<OrderBy>;
  write_off_date?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type CompanySettingsMinFields = {
  /** If relevant, this foreign key points to the current active borrowing_base (ebba_application) for this company */
  active_borrowing_base_id?: Maybe<Scalars["uuid"]>;
  /** If relevant, this foreign key points to the current active ebba_application for this company */
  active_ebba_application_id?: Maybe<Scalars["uuid"]>;
  /** If relevant, this foreign key points to the current active financial_report (ebba_application) for this company */
  active_financial_report_id?: Maybe<Scalars["uuid"]>;
  /** For CUSTOMER companies, this is the bank account which Bespoke Financial sends advances TO */
  advances_bank_account_id?: Maybe<Scalars["uuid"]>;
  /** For CUSTOMER companies, this is the bank account which Bespoke Financial sends advances FROM */
  advances_bespoke_bank_account_id?: Maybe<Scalars["uuid"]>;
  business_development_user_id?: Maybe<Scalars["uuid"]>;
  client_success_user_id?: Maybe<Scalars["uuid"]>;
  /** For CUSTOMER and PAYOR companies, this is the bank account which company sends payments FROM */
  collections_bank_account_id?: Maybe<Scalars["uuid"]>;
  /** For CUSTOMER and PAYOR companies, this is the Bespoke Financial bank account company sends payments TO */
  collections_bespoke_bank_account_id?: Maybe<Scalars["uuid"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  interest_end_date?: Maybe<Scalars["date"]>;
  late_fees_end_date?: Maybe<Scalars["date"]>;
  metrc_api_key_id?: Maybe<Scalars["uuid"]>;
  payor_agreement_docusign_template?: Maybe<Scalars["String"]>;
  two_factor_message_method?: Maybe<Scalars["String"]>;
  underwriter_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vendor_agreement_docusign_template?: Maybe<Scalars["String"]>;
  vendor_onboarding_link?: Maybe<Scalars["String"]>;
  write_off_date?: Maybe<Scalars["date"]>;
};

/** order by min() on columns of table "company_settings" */
export type CompanySettingsMinOrderBy = {
  /** If relevant, this foreign key points to the current active borrowing_base (ebba_application) for this company */
  active_borrowing_base_id?: Maybe<OrderBy>;
  /** If relevant, this foreign key points to the current active ebba_application for this company */
  active_ebba_application_id?: Maybe<OrderBy>;
  /** If relevant, this foreign key points to the current active financial_report (ebba_application) for this company */
  active_financial_report_id?: Maybe<OrderBy>;
  /** For CUSTOMER companies, this is the bank account which Bespoke Financial sends advances TO */
  advances_bank_account_id?: Maybe<OrderBy>;
  /** For CUSTOMER companies, this is the bank account which Bespoke Financial sends advances FROM */
  advances_bespoke_bank_account_id?: Maybe<OrderBy>;
  business_development_user_id?: Maybe<OrderBy>;
  client_success_user_id?: Maybe<OrderBy>;
  /** For CUSTOMER and PAYOR companies, this is the bank account which company sends payments FROM */
  collections_bank_account_id?: Maybe<OrderBy>;
  /** For CUSTOMER and PAYOR companies, this is the Bespoke Financial bank account company sends payments TO */
  collections_bespoke_bank_account_id?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  interest_end_date?: Maybe<OrderBy>;
  late_fees_end_date?: Maybe<OrderBy>;
  metrc_api_key_id?: Maybe<OrderBy>;
  payor_agreement_docusign_template?: Maybe<OrderBy>;
  two_factor_message_method?: Maybe<OrderBy>;
  underwriter_user_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  vendor_agreement_docusign_template?: Maybe<OrderBy>;
  vendor_onboarding_link?: Maybe<OrderBy>;
  write_off_date?: Maybe<OrderBy>;
};

/** response of any mutation on the table "company_settings" */
export type CompanySettingsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CompanySettings>;
};

/** input type for inserting object relation for remote table "company_settings" */
export type CompanySettingsObjRelInsertInput = {
  data: CompanySettingsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<CompanySettingsOnConflict>;
};

/** on_conflict condition type for table "company_settings" */
export type CompanySettingsOnConflict = {
  constraint: CompanySettingsConstraint;
  update_columns?: Array<CompanySettingsUpdateColumn>;
  where?: Maybe<CompanySettingsBoolExp>;
};

/** Ordering options when selecting data from "company_settings". */
export type CompanySettingsOrderBy = {
  active_borrowing_base?: Maybe<EbbaApplicationsOrderBy>;
  active_borrowing_base_id?: Maybe<OrderBy>;
  active_ebba_application?: Maybe<EbbaApplicationsOrderBy>;
  active_ebba_application_id?: Maybe<OrderBy>;
  active_financial_report?: Maybe<EbbaApplicationsOrderBy>;
  active_financial_report_id?: Maybe<OrderBy>;
  advances_bank_account?: Maybe<BankAccountsOrderBy>;
  advances_bank_account_id?: Maybe<OrderBy>;
  advances_bespoke_bank_account?: Maybe<BankAccountsOrderBy>;
  advances_bespoke_bank_account_id?: Maybe<OrderBy>;
  business_development_user?: Maybe<UsersOrderBy>;
  business_development_user_id?: Maybe<OrderBy>;
  client_success_user?: Maybe<UsersOrderBy>;
  client_success_user_id?: Maybe<OrderBy>;
  collections_bank_account?: Maybe<BankAccountsOrderBy>;
  collections_bank_account_id?: Maybe<OrderBy>;
  collections_bespoke_bank_account?: Maybe<BankAccountsOrderBy>;
  collections_bespoke_bank_account_id?: Maybe<OrderBy>;
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  custom_messages_payload?: Maybe<OrderBy>;
  feature_flags_payload?: Maybe<OrderBy>;
  has_autofinancing?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  interest_end_date?: Maybe<OrderBy>;
  is_autogenerate_repayments_enabled?: Maybe<OrderBy>;
  is_dummy_account?: Maybe<OrderBy>;
  late_fees_end_date?: Maybe<OrderBy>;
  metrc_api_key?: Maybe<MetrcApiKeysOrderBy>;
  metrc_api_key_id?: Maybe<OrderBy>;
  payor_agreement_docusign_template?: Maybe<OrderBy>;
  two_factor_message_method?: Maybe<OrderBy>;
  underwriter_user?: Maybe<UsersOrderBy>;
  underwriter_user_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  vendor_agreement_docusign_template?: Maybe<OrderBy>;
  vendor_onboarding_link?: Maybe<OrderBy>;
  write_off_date?: Maybe<OrderBy>;
};

/** primary key columns input for table: company_settings */
export type CompanySettingsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CompanySettingsPrependInput = {
  /** JSON blob which records custom messages for a company (messages shown in various places in app) */
  custom_messages_payload?: Maybe<Scalars["jsonb"]>;
  /** JSON blob which records what features are on / off for a company */
  feature_flags_payload?: Maybe<Scalars["jsonb"]>;
};

/** select columns of table "company_settings" */
export enum CompanySettingsSelectColumn {
  /** column name */
  ActiveBorrowingBaseId = "active_borrowing_base_id",
  /** column name */
  ActiveEbbaApplicationId = "active_ebba_application_id",
  /** column name */
  ActiveFinancialReportId = "active_financial_report_id",
  /** column name */
  AdvancesBankAccountId = "advances_bank_account_id",
  /** column name */
  AdvancesBespokeBankAccountId = "advances_bespoke_bank_account_id",
  /** column name */
  BusinessDevelopmentUserId = "business_development_user_id",
  /** column name */
  ClientSuccessUserId = "client_success_user_id",
  /** column name */
  CollectionsBankAccountId = "collections_bank_account_id",
  /** column name */
  CollectionsBespokeBankAccountId = "collections_bespoke_bank_account_id",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CustomMessagesPayload = "custom_messages_payload",
  /** column name */
  FeatureFlagsPayload = "feature_flags_payload",
  /** column name */
  HasAutofinancing = "has_autofinancing",
  /** column name */
  Id = "id",
  /** column name */
  InterestEndDate = "interest_end_date",
  /** column name */
  IsAutogenerateRepaymentsEnabled = "is_autogenerate_repayments_enabled",
  /** column name */
  IsDummyAccount = "is_dummy_account",
  /** column name */
  LateFeesEndDate = "late_fees_end_date",
  /** column name */
  MetrcApiKeyId = "metrc_api_key_id",
  /** column name */
  PayorAgreementDocusignTemplate = "payor_agreement_docusign_template",
  /** column name */
  TwoFactorMessageMethod = "two_factor_message_method",
  /** column name */
  UnderwriterUserId = "underwriter_user_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VendorAgreementDocusignTemplate = "vendor_agreement_docusign_template",
  /** column name */
  VendorOnboardingLink = "vendor_onboarding_link",
  /** column name */
  WriteOffDate = "write_off_date",
}

/** input type for updating data in table "company_settings" */
export type CompanySettingsSetInput = {
  /** If relevant, this foreign key points to the current active borrowing_base (ebba_application) for this company */
  active_borrowing_base_id?: Maybe<Scalars["uuid"]>;
  /** If relevant, this foreign key points to the current active ebba_application for this company */
  active_ebba_application_id?: Maybe<Scalars["uuid"]>;
  /** If relevant, this foreign key points to the current active financial_report (ebba_application) for this company */
  active_financial_report_id?: Maybe<Scalars["uuid"]>;
  /** For CUSTOMER companies, this is the bank account which Bespoke Financial sends advances TO */
  advances_bank_account_id?: Maybe<Scalars["uuid"]>;
  /** For CUSTOMER companies, this is the bank account which Bespoke Financial sends advances FROM */
  advances_bespoke_bank_account_id?: Maybe<Scalars["uuid"]>;
  business_development_user_id?: Maybe<Scalars["uuid"]>;
  client_success_user_id?: Maybe<Scalars["uuid"]>;
  /** For CUSTOMER and PAYOR companies, this is the bank account which company sends payments FROM */
  collections_bank_account_id?: Maybe<Scalars["uuid"]>;
  /** For CUSTOMER and PAYOR companies, this is the Bespoke Financial bank account company sends payments TO */
  collections_bespoke_bank_account_id?: Maybe<Scalars["uuid"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** JSON blob which records custom messages for a company (messages shown in various places in app) */
  custom_messages_payload?: Maybe<Scalars["jsonb"]>;
  /** JSON blob which records what features are on / off for a company */
  feature_flags_payload?: Maybe<Scalars["jsonb"]>;
  has_autofinancing?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["uuid"]>;
  interest_end_date?: Maybe<Scalars["date"]>;
  is_autogenerate_repayments_enabled?: Maybe<Scalars["Boolean"]>;
  is_dummy_account?: Maybe<Scalars["Boolean"]>;
  late_fees_end_date?: Maybe<Scalars["date"]>;
  metrc_api_key_id?: Maybe<Scalars["uuid"]>;
  payor_agreement_docusign_template?: Maybe<Scalars["String"]>;
  two_factor_message_method?: Maybe<Scalars["String"]>;
  underwriter_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vendor_agreement_docusign_template?: Maybe<Scalars["String"]>;
  vendor_onboarding_link?: Maybe<Scalars["String"]>;
  write_off_date?: Maybe<Scalars["date"]>;
};

/** update columns of table "company_settings" */
export enum CompanySettingsUpdateColumn {
  /** column name */
  ActiveBorrowingBaseId = "active_borrowing_base_id",
  /** column name */
  ActiveEbbaApplicationId = "active_ebba_application_id",
  /** column name */
  ActiveFinancialReportId = "active_financial_report_id",
  /** column name */
  AdvancesBankAccountId = "advances_bank_account_id",
  /** column name */
  AdvancesBespokeBankAccountId = "advances_bespoke_bank_account_id",
  /** column name */
  BusinessDevelopmentUserId = "business_development_user_id",
  /** column name */
  ClientSuccessUserId = "client_success_user_id",
  /** column name */
  CollectionsBankAccountId = "collections_bank_account_id",
  /** column name */
  CollectionsBespokeBankAccountId = "collections_bespoke_bank_account_id",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CustomMessagesPayload = "custom_messages_payload",
  /** column name */
  FeatureFlagsPayload = "feature_flags_payload",
  /** column name */
  HasAutofinancing = "has_autofinancing",
  /** column name */
  Id = "id",
  /** column name */
  InterestEndDate = "interest_end_date",
  /** column name */
  IsAutogenerateRepaymentsEnabled = "is_autogenerate_repayments_enabled",
  /** column name */
  IsDummyAccount = "is_dummy_account",
  /** column name */
  LateFeesEndDate = "late_fees_end_date",
  /** column name */
  MetrcApiKeyId = "metrc_api_key_id",
  /** column name */
  PayorAgreementDocusignTemplate = "payor_agreement_docusign_template",
  /** column name */
  TwoFactorMessageMethod = "two_factor_message_method",
  /** column name */
  UnderwriterUserId = "underwriter_user_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VendorAgreementDocusignTemplate = "vendor_agreement_docusign_template",
  /** column name */
  VendorOnboardingLink = "vendor_onboarding_link",
  /** column name */
  WriteOffDate = "write_off_date",
}

/** columns and relationships of "company_type" */
export type CompanyType = {
  value: Scalars["String"];
};

/** aggregated selection of "company_type" */
export type CompanyTypeAggregate = {
  aggregate?: Maybe<CompanyTypeAggregateFields>;
  nodes: Array<CompanyType>;
};

/** aggregate fields of "company_type" */
export type CompanyTypeAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<CompanyTypeMaxFields>;
  min?: Maybe<CompanyTypeMinFields>;
};

/** aggregate fields of "company_type" */
export type CompanyTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CompanyTypeSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "company_type". All fields are combined with a logical 'AND'. */
export type CompanyTypeBoolExp = {
  _and?: Maybe<Array<CompanyTypeBoolExp>>;
  _not?: Maybe<CompanyTypeBoolExp>;
  _or?: Maybe<Array<CompanyTypeBoolExp>>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "company_type" */
export enum CompanyTypeConstraint {
  /** unique or primary key constraint */
  CompanyTypePkey = "company_type_pkey",
}

export enum CompanyTypeEnum {
  Customer = "customer",
  Payor = "payor",
  Vendor = "vendor",
}

/** Boolean expression to compare columns of type "company_type_enum". All fields are combined with logical 'AND'. */
export type CompanyTypeEnumComparisonExp = {
  _eq?: Maybe<CompanyTypeEnum>;
  _in?: Maybe<Array<CompanyTypeEnum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<CompanyTypeEnum>;
  _nin?: Maybe<Array<CompanyTypeEnum>>;
};

/** input type for inserting data into table "company_type" */
export type CompanyTypeInsertInput = {
  value?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type CompanyTypeMaxFields = {
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type CompanyTypeMinFields = {
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "company_type" */
export type CompanyTypeMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CompanyType>;
};

/** on_conflict condition type for table "company_type" */
export type CompanyTypeOnConflict = {
  constraint: CompanyTypeConstraint;
  update_columns?: Array<CompanyTypeUpdateColumn>;
  where?: Maybe<CompanyTypeBoolExp>;
};

/** Ordering options when selecting data from "company_type". */
export type CompanyTypeOrderBy = {
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: company_type */
export type CompanyTypePkColumnsInput = {
  value: Scalars["String"];
};

/** select columns of table "company_type" */
export enum CompanyTypeSelectColumn {
  /** column name */
  Value = "value",
}

/** input type for updating data in table "company_type" */
export type CompanyTypeSetInput = {
  value?: Maybe<Scalars["String"]>;
};

/** update columns of table "company_type" */
export enum CompanyTypeUpdateColumn {
  /** column name */
  Value = "value",
}

/** columns and relationships of "company_vendor_contacts" */
export type CompanyVendorContacts = {
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_active?: Maybe<Scalars["Boolean"]>;
  partnership_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  user: Users;
  vendor_user_id: Scalars["uuid"];
};

/** aggregated selection of "company_vendor_contacts" */
export type CompanyVendorContactsAggregate = {
  aggregate?: Maybe<CompanyVendorContactsAggregateFields>;
  nodes: Array<CompanyVendorContacts>;
};

/** aggregate fields of "company_vendor_contacts" */
export type CompanyVendorContactsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<CompanyVendorContactsMaxFields>;
  min?: Maybe<CompanyVendorContactsMinFields>;
};

/** aggregate fields of "company_vendor_contacts" */
export type CompanyVendorContactsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CompanyVendorContactsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "company_vendor_contacts" */
export type CompanyVendorContactsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<CompanyVendorContactsMaxOrderBy>;
  min?: Maybe<CompanyVendorContactsMinOrderBy>;
};

/** input type for inserting array relation for remote table "company_vendor_contacts" */
export type CompanyVendorContactsArrRelInsertInput = {
  data: Array<CompanyVendorContactsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<CompanyVendorContactsOnConflict>;
};

/** Boolean expression to filter rows from the table "company_vendor_contacts". All fields are combined with a logical 'AND'. */
export type CompanyVendorContactsBoolExp = {
  _and?: Maybe<Array<CompanyVendorContactsBoolExp>>;
  _not?: Maybe<CompanyVendorContactsBoolExp>;
  _or?: Maybe<Array<CompanyVendorContactsBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_active?: Maybe<BooleanComparisonExp>;
  partnership_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<UsersBoolExp>;
  vendor_user_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "company_vendor_contacts" */
export enum CompanyVendorContactsConstraint {
  /** unique or primary key constraint */
  CompanyVendorContactsPkey = "company_vendor_contacts_pkey",
}

/** input type for inserting data into table "company_vendor_contacts" */
export type CompanyVendorContactsInsertInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_active?: Maybe<Scalars["Boolean"]>;
  partnership_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user?: Maybe<UsersObjRelInsertInput>;
  vendor_user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type CompanyVendorContactsMaxFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  partnership_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vendor_user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "company_vendor_contacts" */
export type CompanyVendorContactsMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  partnership_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  vendor_user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type CompanyVendorContactsMinFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  partnership_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vendor_user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "company_vendor_contacts" */
export type CompanyVendorContactsMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  partnership_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  vendor_user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "company_vendor_contacts" */
export type CompanyVendorContactsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CompanyVendorContacts>;
};

/** on_conflict condition type for table "company_vendor_contacts" */
export type CompanyVendorContactsOnConflict = {
  constraint: CompanyVendorContactsConstraint;
  update_columns?: Array<CompanyVendorContactsUpdateColumn>;
  where?: Maybe<CompanyVendorContactsBoolExp>;
};

/** Ordering options when selecting data from "company_vendor_contacts". */
export type CompanyVendorContactsOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_active?: Maybe<OrderBy>;
  partnership_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
  vendor_user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: company_vendor_contacts */
export type CompanyVendorContactsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "company_vendor_contacts" */
export enum CompanyVendorContactsSelectColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  PartnershipId = "partnership_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VendorUserId = "vendor_user_id",
}

/** input type for updating data in table "company_vendor_contacts" */
export type CompanyVendorContactsSetInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_active?: Maybe<Scalars["Boolean"]>;
  partnership_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vendor_user_id?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "company_vendor_contacts" */
export enum CompanyVendorContactsUpdateColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "is_active",
  /** column name */
  PartnershipId = "partnership_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VendorUserId = "vendor_user_id",
}

/** columns and relationships of "company_vendor_partnerships" */
export type CompanyVendorPartnerships = {
  /** Serves dual purpose of telling us when the vendor was approved */
  approved_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  company: Companies;
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  vendor?: Maybe<Vendors>;
  /** An object relationship */
  vendor_agreement?: Maybe<CompanyAgreements>;
  vendor_agreement_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  vendor_bank_account?: Maybe<BankAccounts>;
  /** Bank account which Bespoke Financial sends advances to */
  vendor_bank_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  vendor_contacts: Array<CompanyVendorContacts>;
  /** An aggregate relationship */
  vendor_contacts_aggregate: CompanyVendorContactsAggregate;
  vendor_id: Scalars["uuid"];
  /** Currently not used */
  vendor_license_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  vendor_limited?: Maybe<Vendors>;
};

/** columns and relationships of "company_vendor_partnerships" */
export type CompanyVendorPartnershipsVendorContactsArgs = {
  distinct_on?: Maybe<Array<CompanyVendorContactsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorContactsOrderBy>>;
  where?: Maybe<CompanyVendorContactsBoolExp>;
};

/** columns and relationships of "company_vendor_partnerships" */
export type CompanyVendorPartnershipsVendorContactsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyVendorContactsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorContactsOrderBy>>;
  where?: Maybe<CompanyVendorContactsBoolExp>;
};

/** aggregated selection of "company_vendor_partnerships" */
export type CompanyVendorPartnershipsAggregate = {
  aggregate?: Maybe<CompanyVendorPartnershipsAggregateFields>;
  nodes: Array<CompanyVendorPartnerships>;
};

/** aggregate fields of "company_vendor_partnerships" */
export type CompanyVendorPartnershipsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<CompanyVendorPartnershipsMaxFields>;
  min?: Maybe<CompanyVendorPartnershipsMinFields>;
};

/** aggregate fields of "company_vendor_partnerships" */
export type CompanyVendorPartnershipsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CompanyVendorPartnershipsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "company_vendor_partnerships" */
export type CompanyVendorPartnershipsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<CompanyVendorPartnershipsMaxOrderBy>;
  min?: Maybe<CompanyVendorPartnershipsMinOrderBy>;
};

/** input type for inserting array relation for remote table "company_vendor_partnerships" */
export type CompanyVendorPartnershipsArrRelInsertInput = {
  data: Array<CompanyVendorPartnershipsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<CompanyVendorPartnershipsOnConflict>;
};

/** Boolean expression to filter rows from the table "company_vendor_partnerships". All fields are combined with a logical 'AND'. */
export type CompanyVendorPartnershipsBoolExp = {
  _and?: Maybe<Array<CompanyVendorPartnershipsBoolExp>>;
  _not?: Maybe<CompanyVendorPartnershipsBoolExp>;
  _or?: Maybe<Array<CompanyVendorPartnershipsBoolExp>>;
  approved_at?: Maybe<TimestamptzComparisonExp>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  vendor?: Maybe<VendorsBoolExp>;
  vendor_agreement?: Maybe<CompanyAgreementsBoolExp>;
  vendor_agreement_id?: Maybe<UuidComparisonExp>;
  vendor_bank_account?: Maybe<BankAccountsBoolExp>;
  vendor_bank_id?: Maybe<UuidComparisonExp>;
  vendor_contacts?: Maybe<CompanyVendorContactsBoolExp>;
  vendor_id?: Maybe<UuidComparisonExp>;
  vendor_license_id?: Maybe<UuidComparisonExp>;
  vendor_limited?: Maybe<VendorsBoolExp>;
};

/** unique or primary key constraints on table "company_vendor_partnerships" */
export enum CompanyVendorPartnershipsConstraint {
  /** unique or primary key constraint */
  CompanyVendorPartnershipPkey = "company_vendor_partnership_pkey",
  /** unique or primary key constraint */
  CompanyVendorPartnershipsCompanyIdVendorIdKey = "company_vendor_partnerships_company_id_vendor_id_key",
}

/** input type for inserting data into table "company_vendor_partnerships" */
export type CompanyVendorPartnershipsInsertInput = {
  /** Serves dual purpose of telling us when the vendor was approved */
  approved_at?: Maybe<Scalars["timestamptz"]>;
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vendor?: Maybe<VendorsObjRelInsertInput>;
  vendor_agreement?: Maybe<CompanyAgreementsObjRelInsertInput>;
  vendor_agreement_id?: Maybe<Scalars["uuid"]>;
  vendor_bank_account?: Maybe<BankAccountsObjRelInsertInput>;
  /** Bank account which Bespoke Financial sends advances to */
  vendor_bank_id?: Maybe<Scalars["uuid"]>;
  vendor_contacts?: Maybe<CompanyVendorContactsArrRelInsertInput>;
  vendor_id?: Maybe<Scalars["uuid"]>;
  /** Currently not used */
  vendor_license_id?: Maybe<Scalars["uuid"]>;
  vendor_limited?: Maybe<VendorsObjRelInsertInput>;
};

/** aggregate max on columns */
export type CompanyVendorPartnershipsMaxFields = {
  /** Serves dual purpose of telling us when the vendor was approved */
  approved_at?: Maybe<Scalars["timestamptz"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vendor_agreement_id?: Maybe<Scalars["uuid"]>;
  /** Bank account which Bespoke Financial sends advances to */
  vendor_bank_id?: Maybe<Scalars["uuid"]>;
  vendor_id?: Maybe<Scalars["uuid"]>;
  /** Currently not used */
  vendor_license_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "company_vendor_partnerships" */
export type CompanyVendorPartnershipsMaxOrderBy = {
  /** Serves dual purpose of telling us when the vendor was approved */
  approved_at?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  vendor_agreement_id?: Maybe<OrderBy>;
  /** Bank account which Bespoke Financial sends advances to */
  vendor_bank_id?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
  /** Currently not used */
  vendor_license_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type CompanyVendorPartnershipsMinFields = {
  /** Serves dual purpose of telling us when the vendor was approved */
  approved_at?: Maybe<Scalars["timestamptz"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vendor_agreement_id?: Maybe<Scalars["uuid"]>;
  /** Bank account which Bespoke Financial sends advances to */
  vendor_bank_id?: Maybe<Scalars["uuid"]>;
  vendor_id?: Maybe<Scalars["uuid"]>;
  /** Currently not used */
  vendor_license_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "company_vendor_partnerships" */
export type CompanyVendorPartnershipsMinOrderBy = {
  /** Serves dual purpose of telling us when the vendor was approved */
  approved_at?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  vendor_agreement_id?: Maybe<OrderBy>;
  /** Bank account which Bespoke Financial sends advances to */
  vendor_bank_id?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
  /** Currently not used */
  vendor_license_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "company_vendor_partnerships" */
export type CompanyVendorPartnershipsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CompanyVendorPartnerships>;
};

/** on_conflict condition type for table "company_vendor_partnerships" */
export type CompanyVendorPartnershipsOnConflict = {
  constraint: CompanyVendorPartnershipsConstraint;
  update_columns?: Array<CompanyVendorPartnershipsUpdateColumn>;
  where?: Maybe<CompanyVendorPartnershipsBoolExp>;
};

/** Ordering options when selecting data from "company_vendor_partnerships". */
export type CompanyVendorPartnershipsOrderBy = {
  approved_at?: Maybe<OrderBy>;
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  vendor?: Maybe<VendorsOrderBy>;
  vendor_agreement?: Maybe<CompanyAgreementsOrderBy>;
  vendor_agreement_id?: Maybe<OrderBy>;
  vendor_bank_account?: Maybe<BankAccountsOrderBy>;
  vendor_bank_id?: Maybe<OrderBy>;
  vendor_contacts_aggregate?: Maybe<CompanyVendorContactsAggregateOrderBy>;
  vendor_id?: Maybe<OrderBy>;
  vendor_license_id?: Maybe<OrderBy>;
  vendor_limited?: Maybe<VendorsOrderBy>;
};

/** primary key columns input for table: company_vendor_partnerships */
export type CompanyVendorPartnershipsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "company_vendor_partnerships" */
export enum CompanyVendorPartnershipsSelectColumn {
  /** column name */
  ApprovedAt = "approved_at",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VendorAgreementId = "vendor_agreement_id",
  /** column name */
  VendorBankId = "vendor_bank_id",
  /** column name */
  VendorId = "vendor_id",
  /** column name */
  VendorLicenseId = "vendor_license_id",
}

/** input type for updating data in table "company_vendor_partnerships" */
export type CompanyVendorPartnershipsSetInput = {
  /** Serves dual purpose of telling us when the vendor was approved */
  approved_at?: Maybe<Scalars["timestamptz"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vendor_agreement_id?: Maybe<Scalars["uuid"]>;
  /** Bank account which Bespoke Financial sends advances to */
  vendor_bank_id?: Maybe<Scalars["uuid"]>;
  vendor_id?: Maybe<Scalars["uuid"]>;
  /** Currently not used */
  vendor_license_id?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "company_vendor_partnerships" */
export enum CompanyVendorPartnershipsUpdateColumn {
  /** column name */
  ApprovedAt = "approved_at",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VendorAgreementId = "vendor_agreement_id",
  /** column name */
  VendorBankId = "vendor_bank_id",
  /** column name */
  VendorId = "vendor_id",
  /** column name */
  VendorLicenseId = "vendor_license_id",
}

/** Contracts are stored for a date range and associated with a company_id */
export type Contracts = {
  /** either the end date, or the termination_date if set */
  adjusted_end_date?: Maybe<Scalars["date"]>;
  /** An array relationship */
  companies: Array<Companies>;
  /** An aggregate relationship */
  companies_aggregate: CompaniesAggregate;
  /** An object relationship */
  company?: Maybe<Companies>;
  company_id?: Maybe<Scalars["uuid"]>;
  end_date?: Maybe<Scalars["date"]>;
  id: Scalars["uuid"];
  is_deleted?: Maybe<Scalars["Boolean"]>;
  modified_at: Scalars["timestamptz"];
  /** An object relationship */
  modified_by_user?: Maybe<Users>;
  modified_by_user_id?: Maybe<Scalars["uuid"]>;
  product_config: Scalars["jsonb"];
  product_type: Scalars["String"];
  start_date: Scalars["date"];
  terminated_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  terminated_by_user?: Maybe<Users>;
  terminated_by_user_id?: Maybe<Scalars["uuid"]>;
};

/** Contracts are stored for a date range and associated with a company_id */
export type ContractsCompaniesArgs = {
  distinct_on?: Maybe<Array<CompaniesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompaniesOrderBy>>;
  where?: Maybe<CompaniesBoolExp>;
};

/** Contracts are stored for a date range and associated with a company_id */
export type ContractsCompaniesAggregateArgs = {
  distinct_on?: Maybe<Array<CompaniesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompaniesOrderBy>>;
  where?: Maybe<CompaniesBoolExp>;
};

/** Contracts are stored for a date range and associated with a company_id */
export type ContractsProductConfigArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "contracts" */
export type ContractsAggregate = {
  aggregate?: Maybe<ContractsAggregateFields>;
  nodes: Array<Contracts>;
};

/** aggregate fields of "contracts" */
export type ContractsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<ContractsMaxFields>;
  min?: Maybe<ContractsMinFields>;
};

/** aggregate fields of "contracts" */
export type ContractsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "contracts" */
export type ContractsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ContractsMaxOrderBy>;
  min?: Maybe<ContractsMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ContractsAppendInput = {
  product_config?: Maybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "contracts" */
export type ContractsArrRelInsertInput = {
  data: Array<ContractsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<ContractsOnConflict>;
};

/** Boolean expression to filter rows from the table "contracts". All fields are combined with a logical 'AND'. */
export type ContractsBoolExp = {
  _and?: Maybe<Array<ContractsBoolExp>>;
  _not?: Maybe<ContractsBoolExp>;
  _or?: Maybe<Array<ContractsBoolExp>>;
  adjusted_end_date?: Maybe<DateComparisonExp>;
  companies?: Maybe<CompaniesBoolExp>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  end_date?: Maybe<DateComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  modified_at?: Maybe<TimestamptzComparisonExp>;
  modified_by_user?: Maybe<UsersBoolExp>;
  modified_by_user_id?: Maybe<UuidComparisonExp>;
  product_config?: Maybe<JsonbComparisonExp>;
  product_type?: Maybe<StringComparisonExp>;
  start_date?: Maybe<DateComparisonExp>;
  terminated_at?: Maybe<TimestamptzComparisonExp>;
  terminated_by_user?: Maybe<UsersBoolExp>;
  terminated_by_user_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "contracts" */
export enum ContractsConstraint {
  /** unique or primary key constraint */
  ContractsPkey = "contracts_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ContractsDeleteAtPathInput = {
  product_config?: Maybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ContractsDeleteElemInput = {
  product_config?: Maybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ContractsDeleteKeyInput = {
  product_config?: Maybe<Scalars["String"]>;
};

/** input type for inserting data into table "contracts" */
export type ContractsInsertInput = {
  /** either the end date, or the termination_date if set */
  adjusted_end_date?: Maybe<Scalars["date"]>;
  companies?: Maybe<CompaniesArrRelInsertInput>;
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  end_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by_user?: Maybe<UsersObjRelInsertInput>;
  modified_by_user_id?: Maybe<Scalars["uuid"]>;
  product_config?: Maybe<Scalars["jsonb"]>;
  product_type?: Maybe<Scalars["String"]>;
  start_date?: Maybe<Scalars["date"]>;
  terminated_at?: Maybe<Scalars["timestamptz"]>;
  terminated_by_user?: Maybe<UsersObjRelInsertInput>;
  terminated_by_user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type ContractsMaxFields = {
  /** either the end date, or the termination_date if set */
  adjusted_end_date?: Maybe<Scalars["date"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  end_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by_user_id?: Maybe<Scalars["uuid"]>;
  product_type?: Maybe<Scalars["String"]>;
  start_date?: Maybe<Scalars["date"]>;
  terminated_at?: Maybe<Scalars["timestamptz"]>;
  terminated_by_user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "contracts" */
export type ContractsMaxOrderBy = {
  /** either the end date, or the termination_date if set */
  adjusted_end_date?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  end_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  modified_at?: Maybe<OrderBy>;
  modified_by_user_id?: Maybe<OrderBy>;
  product_type?: Maybe<OrderBy>;
  start_date?: Maybe<OrderBy>;
  terminated_at?: Maybe<OrderBy>;
  terminated_by_user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ContractsMinFields = {
  /** either the end date, or the termination_date if set */
  adjusted_end_date?: Maybe<Scalars["date"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  end_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by_user_id?: Maybe<Scalars["uuid"]>;
  product_type?: Maybe<Scalars["String"]>;
  start_date?: Maybe<Scalars["date"]>;
  terminated_at?: Maybe<Scalars["timestamptz"]>;
  terminated_by_user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "contracts" */
export type ContractsMinOrderBy = {
  /** either the end date, or the termination_date if set */
  adjusted_end_date?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  end_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  modified_at?: Maybe<OrderBy>;
  modified_by_user_id?: Maybe<OrderBy>;
  product_type?: Maybe<OrderBy>;
  start_date?: Maybe<OrderBy>;
  terminated_at?: Maybe<OrderBy>;
  terminated_by_user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "contracts" */
export type ContractsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Contracts>;
};

/** input type for inserting object relation for remote table "contracts" */
export type ContractsObjRelInsertInput = {
  data: ContractsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<ContractsOnConflict>;
};

/** on_conflict condition type for table "contracts" */
export type ContractsOnConflict = {
  constraint: ContractsConstraint;
  update_columns?: Array<ContractsUpdateColumn>;
  where?: Maybe<ContractsBoolExp>;
};

/** Ordering options when selecting data from "contracts". */
export type ContractsOrderBy = {
  adjusted_end_date?: Maybe<OrderBy>;
  companies_aggregate?: Maybe<CompaniesAggregateOrderBy>;
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  end_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  modified_at?: Maybe<OrderBy>;
  modified_by_user?: Maybe<UsersOrderBy>;
  modified_by_user_id?: Maybe<OrderBy>;
  product_config?: Maybe<OrderBy>;
  product_type?: Maybe<OrderBy>;
  start_date?: Maybe<OrderBy>;
  terminated_at?: Maybe<OrderBy>;
  terminated_by_user?: Maybe<UsersOrderBy>;
  terminated_by_user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: contracts */
export type ContractsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ContractsPrependInput = {
  product_config?: Maybe<Scalars["jsonb"]>;
};

/** select columns of table "contracts" */
export enum ContractsSelectColumn {
  /** column name */
  AdjustedEndDate = "adjusted_end_date",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  EndDate = "end_date",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  ModifiedByUserId = "modified_by_user_id",
  /** column name */
  ProductConfig = "product_config",
  /** column name */
  ProductType = "product_type",
  /** column name */
  StartDate = "start_date",
  /** column name */
  TerminatedAt = "terminated_at",
  /** column name */
  TerminatedByUserId = "terminated_by_user_id",
}

/** input type for updating data in table "contracts" */
export type ContractsSetInput = {
  /** either the end date, or the termination_date if set */
  adjusted_end_date?: Maybe<Scalars["date"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  end_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by_user_id?: Maybe<Scalars["uuid"]>;
  product_config?: Maybe<Scalars["jsonb"]>;
  product_type?: Maybe<Scalars["String"]>;
  start_date?: Maybe<Scalars["date"]>;
  terminated_at?: Maybe<Scalars["timestamptz"]>;
  terminated_by_user_id?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "contracts" */
export enum ContractsUpdateColumn {
  /** column name */
  AdjustedEndDate = "adjusted_end_date",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  EndDate = "end_date",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  ModifiedByUserId = "modified_by_user_id",
  /** column name */
  ProductConfig = "product_config",
  /** column name */
  ProductType = "product_type",
  /** column name */
  StartDate = "start_date",
  /** column name */
  TerminatedAt = "terminated_at",
  /** column name */
  TerminatedByUserId = "terminated_by_user_id",
}

/** This is used to track historical product qualifications for the CS dashboard */
export type CustomerSurveillanceResults = {
  bank_note?: Maybe<Scalars["String"]>;
  /** An object relationship */
  company: Companies;
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  is_deleted?: Maybe<Scalars["Boolean"]>;
  metadata_info: Scalars["json"];
  qualifying_date: Scalars["date"];
  qualifying_product?: Maybe<Scalars["String"]>;
  /** An object relationship */
  submitting_user: Users;
  submitting_user_id: Scalars["uuid"];
  surveillance_info?: Maybe<Scalars["json"]>;
  surveillance_status?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
};

/** This is used to track historical product qualifications for the CS dashboard */
export type CustomerSurveillanceResultsMetadataInfoArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** This is used to track historical product qualifications for the CS dashboard */
export type CustomerSurveillanceResultsSurveillanceInfoArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "customer_surveillance_results" */
export type CustomerSurveillanceResultsAggregate = {
  aggregate?: Maybe<CustomerSurveillanceResultsAggregateFields>;
  nodes: Array<CustomerSurveillanceResults>;
};

/** aggregate fields of "customer_surveillance_results" */
export type CustomerSurveillanceResultsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<CustomerSurveillanceResultsMaxFields>;
  min?: Maybe<CustomerSurveillanceResultsMinFields>;
};

/** aggregate fields of "customer_surveillance_results" */
export type CustomerSurveillanceResultsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CustomerSurveillanceResultsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "customer_surveillance_results" */
export type CustomerSurveillanceResultsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<CustomerSurveillanceResultsMaxOrderBy>;
  min?: Maybe<CustomerSurveillanceResultsMinOrderBy>;
};

/** input type for inserting array relation for remote table "customer_surveillance_results" */
export type CustomerSurveillanceResultsArrRelInsertInput = {
  data: Array<CustomerSurveillanceResultsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<CustomerSurveillanceResultsOnConflict>;
};

/** Boolean expression to filter rows from the table "customer_surveillance_results". All fields are combined with a logical 'AND'. */
export type CustomerSurveillanceResultsBoolExp = {
  _and?: Maybe<Array<CustomerSurveillanceResultsBoolExp>>;
  _not?: Maybe<CustomerSurveillanceResultsBoolExp>;
  _or?: Maybe<Array<CustomerSurveillanceResultsBoolExp>>;
  bank_note?: Maybe<StringComparisonExp>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  metadata_info?: Maybe<JsonComparisonExp>;
  qualifying_date?: Maybe<DateComparisonExp>;
  qualifying_product?: Maybe<StringComparisonExp>;
  submitting_user?: Maybe<UsersBoolExp>;
  submitting_user_id?: Maybe<UuidComparisonExp>;
  surveillance_info?: Maybe<JsonComparisonExp>;
  surveillance_status?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "customer_surveillance_results" */
export enum CustomerSurveillanceResultsConstraint {
  /** unique or primary key constraint */
  CompanyProductQualificationsCompanyIdQualifyingDateKey = "company_product_qualifications_company_id_qualifying_date_key",
  /** unique or primary key constraint */
  CompanyProductQualificationsPkey = "company_product_qualifications_pkey",
}

/** input type for inserting data into table "customer_surveillance_results" */
export type CustomerSurveillanceResultsInsertInput = {
  bank_note?: Maybe<Scalars["String"]>;
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  metadata_info?: Maybe<Scalars["json"]>;
  qualifying_date?: Maybe<Scalars["date"]>;
  qualifying_product?: Maybe<Scalars["String"]>;
  submitting_user?: Maybe<UsersObjRelInsertInput>;
  submitting_user_id?: Maybe<Scalars["uuid"]>;
  surveillance_info?: Maybe<Scalars["json"]>;
  surveillance_status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type CustomerSurveillanceResultsMaxFields = {
  bank_note?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  qualifying_date?: Maybe<Scalars["date"]>;
  qualifying_product?: Maybe<Scalars["String"]>;
  submitting_user_id?: Maybe<Scalars["uuid"]>;
  surveillance_status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "customer_surveillance_results" */
export type CustomerSurveillanceResultsMaxOrderBy = {
  bank_note?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  qualifying_date?: Maybe<OrderBy>;
  qualifying_product?: Maybe<OrderBy>;
  submitting_user_id?: Maybe<OrderBy>;
  surveillance_status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type CustomerSurveillanceResultsMinFields = {
  bank_note?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  qualifying_date?: Maybe<Scalars["date"]>;
  qualifying_product?: Maybe<Scalars["String"]>;
  submitting_user_id?: Maybe<Scalars["uuid"]>;
  surveillance_status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "customer_surveillance_results" */
export type CustomerSurveillanceResultsMinOrderBy = {
  bank_note?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  qualifying_date?: Maybe<OrderBy>;
  qualifying_product?: Maybe<OrderBy>;
  submitting_user_id?: Maybe<OrderBy>;
  surveillance_status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "customer_surveillance_results" */
export type CustomerSurveillanceResultsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CustomerSurveillanceResults>;
};

/** on_conflict condition type for table "customer_surveillance_results" */
export type CustomerSurveillanceResultsOnConflict = {
  constraint: CustomerSurveillanceResultsConstraint;
  update_columns?: Array<CustomerSurveillanceResultsUpdateColumn>;
  where?: Maybe<CustomerSurveillanceResultsBoolExp>;
};

/** Ordering options when selecting data from "customer_surveillance_results". */
export type CustomerSurveillanceResultsOrderBy = {
  bank_note?: Maybe<OrderBy>;
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  metadata_info?: Maybe<OrderBy>;
  qualifying_date?: Maybe<OrderBy>;
  qualifying_product?: Maybe<OrderBy>;
  submitting_user?: Maybe<UsersOrderBy>;
  submitting_user_id?: Maybe<OrderBy>;
  surveillance_info?: Maybe<OrderBy>;
  surveillance_status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: customer_surveillance_results */
export type CustomerSurveillanceResultsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "customer_surveillance_results" */
export enum CustomerSurveillanceResultsSelectColumn {
  /** column name */
  BankNote = "bank_note",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  MetadataInfo = "metadata_info",
  /** column name */
  QualifyingDate = "qualifying_date",
  /** column name */
  QualifyingProduct = "qualifying_product",
  /** column name */
  SubmittingUserId = "submitting_user_id",
  /** column name */
  SurveillanceInfo = "surveillance_info",
  /** column name */
  SurveillanceStatus = "surveillance_status",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "customer_surveillance_results" */
export type CustomerSurveillanceResultsSetInput = {
  bank_note?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  metadata_info?: Maybe<Scalars["json"]>;
  qualifying_date?: Maybe<Scalars["date"]>;
  qualifying_product?: Maybe<Scalars["String"]>;
  submitting_user_id?: Maybe<Scalars["uuid"]>;
  surveillance_info?: Maybe<Scalars["json"]>;
  surveillance_status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "customer_surveillance_results" */
export enum CustomerSurveillanceResultsUpdateColumn {
  /** column name */
  BankNote = "bank_note",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  MetadataInfo = "metadata_info",
  /** column name */
  QualifyingDate = "qualifying_date",
  /** column name */
  QualifyingProduct = "qualifying_product",
  /** column name */
  SubmittingUserId = "submitting_user_id",
  /** column name */
  SurveillanceInfo = "surveillance_info",
  /** column name */
  SurveillanceStatus = "surveillance_status",
  /** column name */
  UpdatedAt = "updated_at",
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: Maybe<Scalars["date"]>;
  _gt?: Maybe<Scalars["date"]>;
  _gte?: Maybe<Scalars["date"]>;
  _in?: Maybe<Array<Scalars["date"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["date"]>;
  _lte?: Maybe<Scalars["date"]>;
  _neq?: Maybe<Scalars["date"]>;
  _nin?: Maybe<Array<Scalars["date"]>>;
};

/** columns and relationships of "debt_facilities" */
export type DebtFacilities = {
  /** An array relationship */
  debt_facility_capacities: Array<DebtFacilityCapacities>;
  /** An aggregate relationship */
  debt_facility_capacities_aggregate: DebtFacilityCapacitiesAggregate;
  id: Scalars["uuid"];
  name: Scalars["String"];
  product_types?: Maybe<Scalars["json"]>;
};

/** columns and relationships of "debt_facilities" */
export type DebtFacilitiesDebtFacilityCapacitiesArgs = {
  distinct_on?: Maybe<Array<DebtFacilityCapacitiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<DebtFacilityCapacitiesOrderBy>>;
  where?: Maybe<DebtFacilityCapacitiesBoolExp>;
};

/** columns and relationships of "debt_facilities" */
export type DebtFacilitiesDebtFacilityCapacitiesAggregateArgs = {
  distinct_on?: Maybe<Array<DebtFacilityCapacitiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<DebtFacilityCapacitiesOrderBy>>;
  where?: Maybe<DebtFacilityCapacitiesBoolExp>;
};

/** columns and relationships of "debt_facilities" */
export type DebtFacilitiesProductTypesArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "debt_facilities" */
export type DebtFacilitiesAggregate = {
  aggregate?: Maybe<DebtFacilitiesAggregateFields>;
  nodes: Array<DebtFacilities>;
};

/** aggregate fields of "debt_facilities" */
export type DebtFacilitiesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<DebtFacilitiesMaxFields>;
  min?: Maybe<DebtFacilitiesMinFields>;
};

/** aggregate fields of "debt_facilities" */
export type DebtFacilitiesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DebtFacilitiesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "debt_facilities". All fields are combined with a logical 'AND'. */
export type DebtFacilitiesBoolExp = {
  _and?: Maybe<Array<DebtFacilitiesBoolExp>>;
  _not?: Maybe<DebtFacilitiesBoolExp>;
  _or?: Maybe<Array<DebtFacilitiesBoolExp>>;
  debt_facility_capacities?: Maybe<DebtFacilityCapacitiesBoolExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  product_types?: Maybe<JsonComparisonExp>;
};

/** unique or primary key constraints on table "debt_facilities" */
export enum DebtFacilitiesConstraint {
  /** unique or primary key constraint */
  DebtFacilitiesPkey = "debt_facilities_pkey",
}

/** input type for inserting data into table "debt_facilities" */
export type DebtFacilitiesInsertInput = {
  debt_facility_capacities?: Maybe<DebtFacilityCapacitiesArrRelInsertInput>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  product_types?: Maybe<Scalars["json"]>;
};

/** aggregate max on columns */
export type DebtFacilitiesMaxFields = {
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type DebtFacilitiesMinFields = {
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "debt_facilities" */
export type DebtFacilitiesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DebtFacilities>;
};

/** input type for inserting object relation for remote table "debt_facilities" */
export type DebtFacilitiesObjRelInsertInput = {
  data: DebtFacilitiesInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<DebtFacilitiesOnConflict>;
};

/** on_conflict condition type for table "debt_facilities" */
export type DebtFacilitiesOnConflict = {
  constraint: DebtFacilitiesConstraint;
  update_columns?: Array<DebtFacilitiesUpdateColumn>;
  where?: Maybe<DebtFacilitiesBoolExp>;
};

/** Ordering options when selecting data from "debt_facilities". */
export type DebtFacilitiesOrderBy = {
  debt_facility_capacities_aggregate?: Maybe<DebtFacilityCapacitiesAggregateOrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  product_types?: Maybe<OrderBy>;
};

/** primary key columns input for table: debt_facilities */
export type DebtFacilitiesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "debt_facilities" */
export enum DebtFacilitiesSelectColumn {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  ProductTypes = "product_types",
}

/** input type for updating data in table "debt_facilities" */
export type DebtFacilitiesSetInput = {
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  product_types?: Maybe<Scalars["json"]>;
};

/** update columns of table "debt_facilities" */
export enum DebtFacilitiesUpdateColumn {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  ProductTypes = "product_types",
}

/** columns and relationships of "debt_facility_capacities" */
export type DebtFacilityCapacities = {
  amount: Scalars["numeric"];
  capacity_type?: Maybe<Scalars["String"]>;
  changed_at: Scalars["timestamptz"];
  changed_by?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  debt_facility?: Maybe<DebtFacilities>;
  debt_facility_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "debt_facility_capacities" */
export type DebtFacilityCapacitiesAggregate = {
  aggregate?: Maybe<DebtFacilityCapacitiesAggregateFields>;
  nodes: Array<DebtFacilityCapacities>;
};

/** aggregate fields of "debt_facility_capacities" */
export type DebtFacilityCapacitiesAggregateFields = {
  avg?: Maybe<DebtFacilityCapacitiesAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<DebtFacilityCapacitiesMaxFields>;
  min?: Maybe<DebtFacilityCapacitiesMinFields>;
  stddev?: Maybe<DebtFacilityCapacitiesStddevFields>;
  stddev_pop?: Maybe<DebtFacilityCapacitiesStddevPopFields>;
  stddev_samp?: Maybe<DebtFacilityCapacitiesStddevSampFields>;
  sum?: Maybe<DebtFacilityCapacitiesSumFields>;
  var_pop?: Maybe<DebtFacilityCapacitiesVarPopFields>;
  var_samp?: Maybe<DebtFacilityCapacitiesVarSampFields>;
  variance?: Maybe<DebtFacilityCapacitiesVarianceFields>;
};

/** aggregate fields of "debt_facility_capacities" */
export type DebtFacilityCapacitiesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DebtFacilityCapacitiesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "debt_facility_capacities" */
export type DebtFacilityCapacitiesAggregateOrderBy = {
  avg?: Maybe<DebtFacilityCapacitiesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DebtFacilityCapacitiesMaxOrderBy>;
  min?: Maybe<DebtFacilityCapacitiesMinOrderBy>;
  stddev?: Maybe<DebtFacilityCapacitiesStddevOrderBy>;
  stddev_pop?: Maybe<DebtFacilityCapacitiesStddevPopOrderBy>;
  stddev_samp?: Maybe<DebtFacilityCapacitiesStddevSampOrderBy>;
  sum?: Maybe<DebtFacilityCapacitiesSumOrderBy>;
  var_pop?: Maybe<DebtFacilityCapacitiesVarPopOrderBy>;
  var_samp?: Maybe<DebtFacilityCapacitiesVarSampOrderBy>;
  variance?: Maybe<DebtFacilityCapacitiesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "debt_facility_capacities" */
export type DebtFacilityCapacitiesArrRelInsertInput = {
  data: Array<DebtFacilityCapacitiesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<DebtFacilityCapacitiesOnConflict>;
};

/** aggregate avg on columns */
export type DebtFacilityCapacitiesAvgFields = {
  amount?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "debt_facility_capacities" */
export type DebtFacilityCapacitiesAvgOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "debt_facility_capacities". All fields are combined with a logical 'AND'. */
export type DebtFacilityCapacitiesBoolExp = {
  _and?: Maybe<Array<DebtFacilityCapacitiesBoolExp>>;
  _not?: Maybe<DebtFacilityCapacitiesBoolExp>;
  _or?: Maybe<Array<DebtFacilityCapacitiesBoolExp>>;
  amount?: Maybe<NumericComparisonExp>;
  capacity_type?: Maybe<StringComparisonExp>;
  changed_at?: Maybe<TimestamptzComparisonExp>;
  changed_by?: Maybe<UuidComparisonExp>;
  debt_facility?: Maybe<DebtFacilitiesBoolExp>;
  debt_facility_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  user?: Maybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "debt_facility_capacities" */
export enum DebtFacilityCapacitiesConstraint {
  /** unique or primary key constraint */
  DebtFacilityCapacitiesPkey = "debt_facility_capacities_pkey",
}

/** input type for incrementing numeric columns in table "debt_facility_capacities" */
export type DebtFacilityCapacitiesIncInput = {
  amount?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "debt_facility_capacities" */
export type DebtFacilityCapacitiesInsertInput = {
  amount?: Maybe<Scalars["numeric"]>;
  capacity_type?: Maybe<Scalars["String"]>;
  changed_at?: Maybe<Scalars["timestamptz"]>;
  changed_by?: Maybe<Scalars["uuid"]>;
  debt_facility?: Maybe<DebtFacilitiesObjRelInsertInput>;
  debt_facility_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  user?: Maybe<UsersObjRelInsertInput>;
};

/** aggregate max on columns */
export type DebtFacilityCapacitiesMaxFields = {
  amount?: Maybe<Scalars["numeric"]>;
  capacity_type?: Maybe<Scalars["String"]>;
  changed_at?: Maybe<Scalars["timestamptz"]>;
  changed_by?: Maybe<Scalars["uuid"]>;
  debt_facility_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "debt_facility_capacities" */
export type DebtFacilityCapacitiesMaxOrderBy = {
  amount?: Maybe<OrderBy>;
  capacity_type?: Maybe<OrderBy>;
  changed_at?: Maybe<OrderBy>;
  changed_by?: Maybe<OrderBy>;
  debt_facility_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DebtFacilityCapacitiesMinFields = {
  amount?: Maybe<Scalars["numeric"]>;
  capacity_type?: Maybe<Scalars["String"]>;
  changed_at?: Maybe<Scalars["timestamptz"]>;
  changed_by?: Maybe<Scalars["uuid"]>;
  debt_facility_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "debt_facility_capacities" */
export type DebtFacilityCapacitiesMinOrderBy = {
  amount?: Maybe<OrderBy>;
  capacity_type?: Maybe<OrderBy>;
  changed_at?: Maybe<OrderBy>;
  changed_by?: Maybe<OrderBy>;
  debt_facility_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "debt_facility_capacities" */
export type DebtFacilityCapacitiesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DebtFacilityCapacities>;
};

/** on_conflict condition type for table "debt_facility_capacities" */
export type DebtFacilityCapacitiesOnConflict = {
  constraint: DebtFacilityCapacitiesConstraint;
  update_columns?: Array<DebtFacilityCapacitiesUpdateColumn>;
  where?: Maybe<DebtFacilityCapacitiesBoolExp>;
};

/** Ordering options when selecting data from "debt_facility_capacities". */
export type DebtFacilityCapacitiesOrderBy = {
  amount?: Maybe<OrderBy>;
  capacity_type?: Maybe<OrderBy>;
  changed_at?: Maybe<OrderBy>;
  changed_by?: Maybe<OrderBy>;
  debt_facility?: Maybe<DebtFacilitiesOrderBy>;
  debt_facility_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
};

/** primary key columns input for table: debt_facility_capacities */
export type DebtFacilityCapacitiesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "debt_facility_capacities" */
export enum DebtFacilityCapacitiesSelectColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  CapacityType = "capacity_type",
  /** column name */
  ChangedAt = "changed_at",
  /** column name */
  ChangedBy = "changed_by",
  /** column name */
  DebtFacilityId = "debt_facility_id",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "debt_facility_capacities" */
export type DebtFacilityCapacitiesSetInput = {
  amount?: Maybe<Scalars["numeric"]>;
  capacity_type?: Maybe<Scalars["String"]>;
  changed_at?: Maybe<Scalars["timestamptz"]>;
  changed_by?: Maybe<Scalars["uuid"]>;
  debt_facility_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type DebtFacilityCapacitiesStddevFields = {
  amount?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "debt_facility_capacities" */
export type DebtFacilityCapacitiesStddevOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DebtFacilityCapacitiesStddevPopFields = {
  amount?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "debt_facility_capacities" */
export type DebtFacilityCapacitiesStddevPopOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DebtFacilityCapacitiesStddevSampFields = {
  amount?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "debt_facility_capacities" */
export type DebtFacilityCapacitiesStddevSampOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DebtFacilityCapacitiesSumFields = {
  amount?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "debt_facility_capacities" */
export type DebtFacilityCapacitiesSumOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** update columns of table "debt_facility_capacities" */
export enum DebtFacilityCapacitiesUpdateColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  CapacityType = "capacity_type",
  /** column name */
  ChangedAt = "changed_at",
  /** column name */
  ChangedBy = "changed_by",
  /** column name */
  DebtFacilityId = "debt_facility_id",
  /** column name */
  Id = "id",
}

/** aggregate var_pop on columns */
export type DebtFacilityCapacitiesVarPopFields = {
  amount?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "debt_facility_capacities" */
export type DebtFacilityCapacitiesVarPopOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DebtFacilityCapacitiesVarSampFields = {
  amount?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "debt_facility_capacities" */
export type DebtFacilityCapacitiesVarSampOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DebtFacilityCapacitiesVarianceFields = {
  amount?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "debt_facility_capacities" */
export type DebtFacilityCapacitiesVarianceOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** columns and relationships of "debt_facility_events" */
export type DebtFacilityEvents = {
  company_id?: Maybe<Scalars["uuid"]>;
  event_amount?: Maybe<Scalars["numeric"]>;
  event_category: Scalars["String"];
  event_comments?: Maybe<Scalars["String"]>;
  event_date: Scalars["date"];
  event_payload?: Maybe<Scalars["jsonb"]>;
  id: Scalars["uuid"];
  loan_report_id?: Maybe<Scalars["uuid"]>;
};

/** columns and relationships of "debt_facility_events" */
export type DebtFacilityEventsEventPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "debt_facility_events" */
export type DebtFacilityEventsAggregate = {
  aggregate?: Maybe<DebtFacilityEventsAggregateFields>;
  nodes: Array<DebtFacilityEvents>;
};

/** aggregate fields of "debt_facility_events" */
export type DebtFacilityEventsAggregateFields = {
  avg?: Maybe<DebtFacilityEventsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<DebtFacilityEventsMaxFields>;
  min?: Maybe<DebtFacilityEventsMinFields>;
  stddev?: Maybe<DebtFacilityEventsStddevFields>;
  stddev_pop?: Maybe<DebtFacilityEventsStddevPopFields>;
  stddev_samp?: Maybe<DebtFacilityEventsStddevSampFields>;
  sum?: Maybe<DebtFacilityEventsSumFields>;
  var_pop?: Maybe<DebtFacilityEventsVarPopFields>;
  var_samp?: Maybe<DebtFacilityEventsVarSampFields>;
  variance?: Maybe<DebtFacilityEventsVarianceFields>;
};

/** aggregate fields of "debt_facility_events" */
export type DebtFacilityEventsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DebtFacilityEventsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DebtFacilityEventsAppendInput = {
  event_payload?: Maybe<Scalars["jsonb"]>;
};

/** aggregate avg on columns */
export type DebtFacilityEventsAvgFields = {
  event_amount?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "debt_facility_events". All fields are combined with a logical 'AND'. */
export type DebtFacilityEventsBoolExp = {
  _and?: Maybe<Array<DebtFacilityEventsBoolExp>>;
  _not?: Maybe<DebtFacilityEventsBoolExp>;
  _or?: Maybe<Array<DebtFacilityEventsBoolExp>>;
  company_id?: Maybe<UuidComparisonExp>;
  event_amount?: Maybe<NumericComparisonExp>;
  event_category?: Maybe<StringComparisonExp>;
  event_comments?: Maybe<StringComparisonExp>;
  event_date?: Maybe<DateComparisonExp>;
  event_payload?: Maybe<JsonbComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  loan_report_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "debt_facility_events" */
export enum DebtFacilityEventsConstraint {
  /** unique or primary key constraint */
  DebtFacilityEventsPkey = "debt_facility_events_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DebtFacilityEventsDeleteAtPathInput = {
  event_payload?: Maybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DebtFacilityEventsDeleteElemInput = {
  event_payload?: Maybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DebtFacilityEventsDeleteKeyInput = {
  event_payload?: Maybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "debt_facility_events" */
export type DebtFacilityEventsIncInput = {
  event_amount?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "debt_facility_events" */
export type DebtFacilityEventsInsertInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  event_amount?: Maybe<Scalars["numeric"]>;
  event_category?: Maybe<Scalars["String"]>;
  event_comments?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["date"]>;
  event_payload?: Maybe<Scalars["jsonb"]>;
  id?: Maybe<Scalars["uuid"]>;
  loan_report_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type DebtFacilityEventsMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  event_amount?: Maybe<Scalars["numeric"]>;
  event_category?: Maybe<Scalars["String"]>;
  event_comments?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  loan_report_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type DebtFacilityEventsMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  event_amount?: Maybe<Scalars["numeric"]>;
  event_category?: Maybe<Scalars["String"]>;
  event_comments?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  loan_report_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "debt_facility_events" */
export type DebtFacilityEventsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DebtFacilityEvents>;
};

/** on_conflict condition type for table "debt_facility_events" */
export type DebtFacilityEventsOnConflict = {
  constraint: DebtFacilityEventsConstraint;
  update_columns?: Array<DebtFacilityEventsUpdateColumn>;
  where?: Maybe<DebtFacilityEventsBoolExp>;
};

/** Ordering options when selecting data from "debt_facility_events". */
export type DebtFacilityEventsOrderBy = {
  company_id?: Maybe<OrderBy>;
  event_amount?: Maybe<OrderBy>;
  event_category?: Maybe<OrderBy>;
  event_comments?: Maybe<OrderBy>;
  event_date?: Maybe<OrderBy>;
  event_payload?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  loan_report_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: debt_facility_events */
export type DebtFacilityEventsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DebtFacilityEventsPrependInput = {
  event_payload?: Maybe<Scalars["jsonb"]>;
};

/** select columns of table "debt_facility_events" */
export enum DebtFacilityEventsSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  EventAmount = "event_amount",
  /** column name */
  EventCategory = "event_category",
  /** column name */
  EventComments = "event_comments",
  /** column name */
  EventDate = "event_date",
  /** column name */
  EventPayload = "event_payload",
  /** column name */
  Id = "id",
  /** column name */
  LoanReportId = "loan_report_id",
}

/** input type for updating data in table "debt_facility_events" */
export type DebtFacilityEventsSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  event_amount?: Maybe<Scalars["numeric"]>;
  event_category?: Maybe<Scalars["String"]>;
  event_comments?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["date"]>;
  event_payload?: Maybe<Scalars["jsonb"]>;
  id?: Maybe<Scalars["uuid"]>;
  loan_report_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type DebtFacilityEventsStddevFields = {
  event_amount?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type DebtFacilityEventsStddevPopFields = {
  event_amount?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type DebtFacilityEventsStddevSampFields = {
  event_amount?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type DebtFacilityEventsSumFields = {
  event_amount?: Maybe<Scalars["numeric"]>;
};

/** update columns of table "debt_facility_events" */
export enum DebtFacilityEventsUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  EventAmount = "event_amount",
  /** column name */
  EventCategory = "event_category",
  /** column name */
  EventComments = "event_comments",
  /** column name */
  EventDate = "event_date",
  /** column name */
  EventPayload = "event_payload",
  /** column name */
  Id = "id",
  /** column name */
  LoanReportId = "loan_report_id",
}

/** aggregate var_pop on columns */
export type DebtFacilityEventsVarPopFields = {
  event_amount?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type DebtFacilityEventsVarSampFields = {
  event_amount?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type DebtFacilityEventsVarianceFields = {
  event_amount?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "ebba_application_files" */
export type EbbaApplicationFiles = {
  /** An object relationship */
  ebba_application: EbbaApplications;
  ebba_application_id: Scalars["uuid"];
  /** An object relationship */
  file: Files;
  file_id: Scalars["uuid"];
};

/** aggregated selection of "ebba_application_files" */
export type EbbaApplicationFilesAggregate = {
  aggregate?: Maybe<EbbaApplicationFilesAggregateFields>;
  nodes: Array<EbbaApplicationFiles>;
};

/** aggregate fields of "ebba_application_files" */
export type EbbaApplicationFilesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<EbbaApplicationFilesMaxFields>;
  min?: Maybe<EbbaApplicationFilesMinFields>;
};

/** aggregate fields of "ebba_application_files" */
export type EbbaApplicationFilesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<EbbaApplicationFilesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ebba_application_files" */
export type EbbaApplicationFilesAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<EbbaApplicationFilesMaxOrderBy>;
  min?: Maybe<EbbaApplicationFilesMinOrderBy>;
};

/** input type for inserting array relation for remote table "ebba_application_files" */
export type EbbaApplicationFilesArrRelInsertInput = {
  data: Array<EbbaApplicationFilesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<EbbaApplicationFilesOnConflict>;
};

/** Boolean expression to filter rows from the table "ebba_application_files". All fields are combined with a logical 'AND'. */
export type EbbaApplicationFilesBoolExp = {
  _and?: Maybe<Array<EbbaApplicationFilesBoolExp>>;
  _not?: Maybe<EbbaApplicationFilesBoolExp>;
  _or?: Maybe<Array<EbbaApplicationFilesBoolExp>>;
  ebba_application?: Maybe<EbbaApplicationsBoolExp>;
  ebba_application_id?: Maybe<UuidComparisonExp>;
  file?: Maybe<FilesBoolExp>;
  file_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "ebba_application_files" */
export enum EbbaApplicationFilesConstraint {
  /** unique or primary key constraint */
  EbbaApplicationFilesPkey = "ebba_application_files_pkey",
}

/** input type for inserting data into table "ebba_application_files" */
export type EbbaApplicationFilesInsertInput = {
  ebba_application?: Maybe<EbbaApplicationsObjRelInsertInput>;
  ebba_application_id?: Maybe<Scalars["uuid"]>;
  file?: Maybe<FilesObjRelInsertInput>;
  file_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type EbbaApplicationFilesMaxFields = {
  ebba_application_id?: Maybe<Scalars["uuid"]>;
  file_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "ebba_application_files" */
export type EbbaApplicationFilesMaxOrderBy = {
  ebba_application_id?: Maybe<OrderBy>;
  file_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type EbbaApplicationFilesMinFields = {
  ebba_application_id?: Maybe<Scalars["uuid"]>;
  file_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "ebba_application_files" */
export type EbbaApplicationFilesMinOrderBy = {
  ebba_application_id?: Maybe<OrderBy>;
  file_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "ebba_application_files" */
export type EbbaApplicationFilesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<EbbaApplicationFiles>;
};

/** on_conflict condition type for table "ebba_application_files" */
export type EbbaApplicationFilesOnConflict = {
  constraint: EbbaApplicationFilesConstraint;
  update_columns?: Array<EbbaApplicationFilesUpdateColumn>;
  where?: Maybe<EbbaApplicationFilesBoolExp>;
};

/** Ordering options when selecting data from "ebba_application_files". */
export type EbbaApplicationFilesOrderBy = {
  ebba_application?: Maybe<EbbaApplicationsOrderBy>;
  ebba_application_id?: Maybe<OrderBy>;
  file?: Maybe<FilesOrderBy>;
  file_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: ebba_application_files */
export type EbbaApplicationFilesPkColumnsInput = {
  ebba_application_id: Scalars["uuid"];
  file_id: Scalars["uuid"];
};

/** select columns of table "ebba_application_files" */
export enum EbbaApplicationFilesSelectColumn {
  /** column name */
  EbbaApplicationId = "ebba_application_id",
  /** column name */
  FileId = "file_id",
}

/** input type for updating data in table "ebba_application_files" */
export type EbbaApplicationFilesSetInput = {
  ebba_application_id?: Maybe<Scalars["uuid"]>;
  file_id?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "ebba_application_files" */
export enum EbbaApplicationFilesUpdateColumn {
  /** column name */
  EbbaApplicationId = "ebba_application_id",
  /** column name */
  FileId = "file_id",
}

/** EBBA stands for Eligible Borrowing Base Amount: this is a table of applications to borrow via a line of credit with information required to determine a Borrowing Base */
export type EbbaApplications = {
  amount_cash_in_daca?: Maybe<Scalars["numeric"]>;
  amount_custom?: Maybe<Scalars["numeric"]>;
  amount_custom_note?: Maybe<Scalars["String"]>;
  application_date: Scalars["date"];
  approved_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  approved_by_user?: Maybe<Users>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  bank_note?: Maybe<Scalars["String"]>;
  calculated_borrowing_base?: Maybe<Scalars["numeric"]>;
  category?: Maybe<Scalars["String"]>;
  /** An object relationship */
  company: Companies;
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  /** An array relationship */
  ebba_application_files: Array<EbbaApplicationFiles>;
  /** An aggregate relationship */
  ebba_application_files_aggregate: EbbaApplicationFilesAggregate;
  expires_date: Scalars["date"];
  id: Scalars["uuid"];
  is_deleted: Scalars["Boolean"];
  monthly_accounts_receivable?: Maybe<Scalars["numeric"]>;
  monthly_cash?: Maybe<Scalars["numeric"]>;
  monthly_inventory?: Maybe<Scalars["numeric"]>;
  rejected_at?: Maybe<Scalars["timestamp"]>;
  /** An object relationship */
  rejected_by_user?: Maybe<Users>;
  rejected_by_user_id?: Maybe<Scalars["uuid"]>;
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  status: RequestStatusEnum;
  /** An object relationship */
  submitted_by_user?: Maybe<Users>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  updated_at: Scalars["timestamptz"];
};

/** EBBA stands for Eligible Borrowing Base Amount: this is a table of applications to borrow via a line of credit with information required to determine a Borrowing Base */
export type EbbaApplicationsEbbaApplicationFilesArgs = {
  distinct_on?: Maybe<Array<EbbaApplicationFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EbbaApplicationFilesOrderBy>>;
  where?: Maybe<EbbaApplicationFilesBoolExp>;
};

/** EBBA stands for Eligible Borrowing Base Amount: this is a table of applications to borrow via a line of credit with information required to determine a Borrowing Base */
export type EbbaApplicationsEbbaApplicationFilesAggregateArgs = {
  distinct_on?: Maybe<Array<EbbaApplicationFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EbbaApplicationFilesOrderBy>>;
  where?: Maybe<EbbaApplicationFilesBoolExp>;
};

/** aggregated selection of "ebba_applications" */
export type EbbaApplicationsAggregate = {
  aggregate?: Maybe<EbbaApplicationsAggregateFields>;
  nodes: Array<EbbaApplications>;
};

/** aggregate fields of "ebba_applications" */
export type EbbaApplicationsAggregateFields = {
  avg?: Maybe<EbbaApplicationsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<EbbaApplicationsMaxFields>;
  min?: Maybe<EbbaApplicationsMinFields>;
  stddev?: Maybe<EbbaApplicationsStddevFields>;
  stddev_pop?: Maybe<EbbaApplicationsStddevPopFields>;
  stddev_samp?: Maybe<EbbaApplicationsStddevSampFields>;
  sum?: Maybe<EbbaApplicationsSumFields>;
  var_pop?: Maybe<EbbaApplicationsVarPopFields>;
  var_samp?: Maybe<EbbaApplicationsVarSampFields>;
  variance?: Maybe<EbbaApplicationsVarianceFields>;
};

/** aggregate fields of "ebba_applications" */
export type EbbaApplicationsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<EbbaApplicationsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ebba_applications" */
export type EbbaApplicationsAggregateOrderBy = {
  avg?: Maybe<EbbaApplicationsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<EbbaApplicationsMaxOrderBy>;
  min?: Maybe<EbbaApplicationsMinOrderBy>;
  stddev?: Maybe<EbbaApplicationsStddevOrderBy>;
  stddev_pop?: Maybe<EbbaApplicationsStddevPopOrderBy>;
  stddev_samp?: Maybe<EbbaApplicationsStddevSampOrderBy>;
  sum?: Maybe<EbbaApplicationsSumOrderBy>;
  var_pop?: Maybe<EbbaApplicationsVarPopOrderBy>;
  var_samp?: Maybe<EbbaApplicationsVarSampOrderBy>;
  variance?: Maybe<EbbaApplicationsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "ebba_applications" */
export type EbbaApplicationsArrRelInsertInput = {
  data: Array<EbbaApplicationsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<EbbaApplicationsOnConflict>;
};

/** aggregate avg on columns */
export type EbbaApplicationsAvgFields = {
  amount_cash_in_daca?: Maybe<Scalars["Float"]>;
  amount_custom?: Maybe<Scalars["Float"]>;
  calculated_borrowing_base?: Maybe<Scalars["Float"]>;
  monthly_accounts_receivable?: Maybe<Scalars["Float"]>;
  monthly_cash?: Maybe<Scalars["Float"]>;
  monthly_inventory?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ebba_applications" */
export type EbbaApplicationsAvgOrderBy = {
  amount_cash_in_daca?: Maybe<OrderBy>;
  amount_custom?: Maybe<OrderBy>;
  calculated_borrowing_base?: Maybe<OrderBy>;
  monthly_accounts_receivable?: Maybe<OrderBy>;
  monthly_cash?: Maybe<OrderBy>;
  monthly_inventory?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "ebba_applications". All fields are combined with a logical 'AND'. */
export type EbbaApplicationsBoolExp = {
  _and?: Maybe<Array<EbbaApplicationsBoolExp>>;
  _not?: Maybe<EbbaApplicationsBoolExp>;
  _or?: Maybe<Array<EbbaApplicationsBoolExp>>;
  amount_cash_in_daca?: Maybe<NumericComparisonExp>;
  amount_custom?: Maybe<NumericComparisonExp>;
  amount_custom_note?: Maybe<StringComparisonExp>;
  application_date?: Maybe<DateComparisonExp>;
  approved_at?: Maybe<TimestamptzComparisonExp>;
  approved_by_user?: Maybe<UsersBoolExp>;
  approved_by_user_id?: Maybe<UuidComparisonExp>;
  bank_note?: Maybe<StringComparisonExp>;
  calculated_borrowing_base?: Maybe<NumericComparisonExp>;
  category?: Maybe<StringComparisonExp>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  ebba_application_files?: Maybe<EbbaApplicationFilesBoolExp>;
  expires_date?: Maybe<DateComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  monthly_accounts_receivable?: Maybe<NumericComparisonExp>;
  monthly_cash?: Maybe<NumericComparisonExp>;
  monthly_inventory?: Maybe<NumericComparisonExp>;
  rejected_at?: Maybe<TimestampComparisonExp>;
  rejected_by_user?: Maybe<UsersBoolExp>;
  rejected_by_user_id?: Maybe<UuidComparisonExp>;
  rejection_note?: Maybe<StringComparisonExp>;
  requested_at?: Maybe<TimestamptzComparisonExp>;
  status?: Maybe<RequestStatusEnumComparisonExp>;
  submitted_by_user?: Maybe<UsersBoolExp>;
  submitted_by_user_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "ebba_applications" */
export enum EbbaApplicationsConstraint {
  /** unique or primary key constraint */
  EbbaApplicationsPkey = "ebba_applications_pkey",
}

/** input type for incrementing numeric columns in table "ebba_applications" */
export type EbbaApplicationsIncInput = {
  amount_cash_in_daca?: Maybe<Scalars["numeric"]>;
  amount_custom?: Maybe<Scalars["numeric"]>;
  calculated_borrowing_base?: Maybe<Scalars["numeric"]>;
  monthly_accounts_receivable?: Maybe<Scalars["numeric"]>;
  monthly_cash?: Maybe<Scalars["numeric"]>;
  monthly_inventory?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "ebba_applications" */
export type EbbaApplicationsInsertInput = {
  amount_cash_in_daca?: Maybe<Scalars["numeric"]>;
  amount_custom?: Maybe<Scalars["numeric"]>;
  amount_custom_note?: Maybe<Scalars["String"]>;
  application_date?: Maybe<Scalars["date"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user?: Maybe<UsersObjRelInsertInput>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  bank_note?: Maybe<Scalars["String"]>;
  calculated_borrowing_base?: Maybe<Scalars["numeric"]>;
  category?: Maybe<Scalars["String"]>;
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  ebba_application_files?: Maybe<EbbaApplicationFilesArrRelInsertInput>;
  expires_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  monthly_accounts_receivable?: Maybe<Scalars["numeric"]>;
  monthly_cash?: Maybe<Scalars["numeric"]>;
  monthly_inventory?: Maybe<Scalars["numeric"]>;
  rejected_at?: Maybe<Scalars["timestamp"]>;
  rejected_by_user?: Maybe<UsersObjRelInsertInput>;
  rejected_by_user_id?: Maybe<Scalars["uuid"]>;
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<RequestStatusEnum>;
  submitted_by_user?: Maybe<UsersObjRelInsertInput>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type EbbaApplicationsMaxFields = {
  amount_cash_in_daca?: Maybe<Scalars["numeric"]>;
  amount_custom?: Maybe<Scalars["numeric"]>;
  amount_custom_note?: Maybe<Scalars["String"]>;
  application_date?: Maybe<Scalars["date"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  bank_note?: Maybe<Scalars["String"]>;
  calculated_borrowing_base?: Maybe<Scalars["numeric"]>;
  category?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expires_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  monthly_accounts_receivable?: Maybe<Scalars["numeric"]>;
  monthly_cash?: Maybe<Scalars["numeric"]>;
  monthly_inventory?: Maybe<Scalars["numeric"]>;
  rejected_at?: Maybe<Scalars["timestamp"]>;
  rejected_by_user_id?: Maybe<Scalars["uuid"]>;
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "ebba_applications" */
export type EbbaApplicationsMaxOrderBy = {
  amount_cash_in_daca?: Maybe<OrderBy>;
  amount_custom?: Maybe<OrderBy>;
  amount_custom_note?: Maybe<OrderBy>;
  application_date?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by_user_id?: Maybe<OrderBy>;
  bank_note?: Maybe<OrderBy>;
  calculated_borrowing_base?: Maybe<OrderBy>;
  category?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  expires_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  monthly_accounts_receivable?: Maybe<OrderBy>;
  monthly_cash?: Maybe<OrderBy>;
  monthly_inventory?: Maybe<OrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejected_by_user_id?: Maybe<OrderBy>;
  rejection_note?: Maybe<OrderBy>;
  requested_at?: Maybe<OrderBy>;
  submitted_by_user_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type EbbaApplicationsMinFields = {
  amount_cash_in_daca?: Maybe<Scalars["numeric"]>;
  amount_custom?: Maybe<Scalars["numeric"]>;
  amount_custom_note?: Maybe<Scalars["String"]>;
  application_date?: Maybe<Scalars["date"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  bank_note?: Maybe<Scalars["String"]>;
  calculated_borrowing_base?: Maybe<Scalars["numeric"]>;
  category?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expires_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  monthly_accounts_receivable?: Maybe<Scalars["numeric"]>;
  monthly_cash?: Maybe<Scalars["numeric"]>;
  monthly_inventory?: Maybe<Scalars["numeric"]>;
  rejected_at?: Maybe<Scalars["timestamp"]>;
  rejected_by_user_id?: Maybe<Scalars["uuid"]>;
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "ebba_applications" */
export type EbbaApplicationsMinOrderBy = {
  amount_cash_in_daca?: Maybe<OrderBy>;
  amount_custom?: Maybe<OrderBy>;
  amount_custom_note?: Maybe<OrderBy>;
  application_date?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by_user_id?: Maybe<OrderBy>;
  bank_note?: Maybe<OrderBy>;
  calculated_borrowing_base?: Maybe<OrderBy>;
  category?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  expires_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  monthly_accounts_receivable?: Maybe<OrderBy>;
  monthly_cash?: Maybe<OrderBy>;
  monthly_inventory?: Maybe<OrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejected_by_user_id?: Maybe<OrderBy>;
  rejection_note?: Maybe<OrderBy>;
  requested_at?: Maybe<OrderBy>;
  submitted_by_user_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "ebba_applications" */
export type EbbaApplicationsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<EbbaApplications>;
};

/** input type for inserting object relation for remote table "ebba_applications" */
export type EbbaApplicationsObjRelInsertInput = {
  data: EbbaApplicationsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<EbbaApplicationsOnConflict>;
};

/** on_conflict condition type for table "ebba_applications" */
export type EbbaApplicationsOnConflict = {
  constraint: EbbaApplicationsConstraint;
  update_columns?: Array<EbbaApplicationsUpdateColumn>;
  where?: Maybe<EbbaApplicationsBoolExp>;
};

/** Ordering options when selecting data from "ebba_applications". */
export type EbbaApplicationsOrderBy = {
  amount_cash_in_daca?: Maybe<OrderBy>;
  amount_custom?: Maybe<OrderBy>;
  amount_custom_note?: Maybe<OrderBy>;
  application_date?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by_user?: Maybe<UsersOrderBy>;
  approved_by_user_id?: Maybe<OrderBy>;
  bank_note?: Maybe<OrderBy>;
  calculated_borrowing_base?: Maybe<OrderBy>;
  category?: Maybe<OrderBy>;
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  ebba_application_files_aggregate?: Maybe<EbbaApplicationFilesAggregateOrderBy>;
  expires_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  monthly_accounts_receivable?: Maybe<OrderBy>;
  monthly_cash?: Maybe<OrderBy>;
  monthly_inventory?: Maybe<OrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejected_by_user?: Maybe<UsersOrderBy>;
  rejected_by_user_id?: Maybe<OrderBy>;
  rejection_note?: Maybe<OrderBy>;
  requested_at?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  submitted_by_user?: Maybe<UsersOrderBy>;
  submitted_by_user_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: ebba_applications */
export type EbbaApplicationsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "ebba_applications" */
export enum EbbaApplicationsSelectColumn {
  /** column name */
  AmountCashInDaca = "amount_cash_in_daca",
  /** column name */
  AmountCustom = "amount_custom",
  /** column name */
  AmountCustomNote = "amount_custom_note",
  /** column name */
  ApplicationDate = "application_date",
  /** column name */
  ApprovedAt = "approved_at",
  /** column name */
  ApprovedByUserId = "approved_by_user_id",
  /** column name */
  BankNote = "bank_note",
  /** column name */
  CalculatedBorrowingBase = "calculated_borrowing_base",
  /** column name */
  Category = "category",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExpiresDate = "expires_date",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  MonthlyAccountsReceivable = "monthly_accounts_receivable",
  /** column name */
  MonthlyCash = "monthly_cash",
  /** column name */
  MonthlyInventory = "monthly_inventory",
  /** column name */
  RejectedAt = "rejected_at",
  /** column name */
  RejectedByUserId = "rejected_by_user_id",
  /** column name */
  RejectionNote = "rejection_note",
  /** column name */
  RequestedAt = "requested_at",
  /** column name */
  Status = "status",
  /** column name */
  SubmittedByUserId = "submitted_by_user_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "ebba_applications" */
export type EbbaApplicationsSetInput = {
  amount_cash_in_daca?: Maybe<Scalars["numeric"]>;
  amount_custom?: Maybe<Scalars["numeric"]>;
  amount_custom_note?: Maybe<Scalars["String"]>;
  application_date?: Maybe<Scalars["date"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  bank_note?: Maybe<Scalars["String"]>;
  calculated_borrowing_base?: Maybe<Scalars["numeric"]>;
  category?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expires_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  monthly_accounts_receivable?: Maybe<Scalars["numeric"]>;
  monthly_cash?: Maybe<Scalars["numeric"]>;
  monthly_inventory?: Maybe<Scalars["numeric"]>;
  rejected_at?: Maybe<Scalars["timestamp"]>;
  rejected_by_user_id?: Maybe<Scalars["uuid"]>;
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<RequestStatusEnum>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type EbbaApplicationsStddevFields = {
  amount_cash_in_daca?: Maybe<Scalars["Float"]>;
  amount_custom?: Maybe<Scalars["Float"]>;
  calculated_borrowing_base?: Maybe<Scalars["Float"]>;
  monthly_accounts_receivable?: Maybe<Scalars["Float"]>;
  monthly_cash?: Maybe<Scalars["Float"]>;
  monthly_inventory?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ebba_applications" */
export type EbbaApplicationsStddevOrderBy = {
  amount_cash_in_daca?: Maybe<OrderBy>;
  amount_custom?: Maybe<OrderBy>;
  calculated_borrowing_base?: Maybe<OrderBy>;
  monthly_accounts_receivable?: Maybe<OrderBy>;
  monthly_cash?: Maybe<OrderBy>;
  monthly_inventory?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EbbaApplicationsStddevPopFields = {
  amount_cash_in_daca?: Maybe<Scalars["Float"]>;
  amount_custom?: Maybe<Scalars["Float"]>;
  calculated_borrowing_base?: Maybe<Scalars["Float"]>;
  monthly_accounts_receivable?: Maybe<Scalars["Float"]>;
  monthly_cash?: Maybe<Scalars["Float"]>;
  monthly_inventory?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ebba_applications" */
export type EbbaApplicationsStddevPopOrderBy = {
  amount_cash_in_daca?: Maybe<OrderBy>;
  amount_custom?: Maybe<OrderBy>;
  calculated_borrowing_base?: Maybe<OrderBy>;
  monthly_accounts_receivable?: Maybe<OrderBy>;
  monthly_cash?: Maybe<OrderBy>;
  monthly_inventory?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EbbaApplicationsStddevSampFields = {
  amount_cash_in_daca?: Maybe<Scalars["Float"]>;
  amount_custom?: Maybe<Scalars["Float"]>;
  calculated_borrowing_base?: Maybe<Scalars["Float"]>;
  monthly_accounts_receivable?: Maybe<Scalars["Float"]>;
  monthly_cash?: Maybe<Scalars["Float"]>;
  monthly_inventory?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ebba_applications" */
export type EbbaApplicationsStddevSampOrderBy = {
  amount_cash_in_daca?: Maybe<OrderBy>;
  amount_custom?: Maybe<OrderBy>;
  calculated_borrowing_base?: Maybe<OrderBy>;
  monthly_accounts_receivable?: Maybe<OrderBy>;
  monthly_cash?: Maybe<OrderBy>;
  monthly_inventory?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type EbbaApplicationsSumFields = {
  amount_cash_in_daca?: Maybe<Scalars["numeric"]>;
  amount_custom?: Maybe<Scalars["numeric"]>;
  calculated_borrowing_base?: Maybe<Scalars["numeric"]>;
  monthly_accounts_receivable?: Maybe<Scalars["numeric"]>;
  monthly_cash?: Maybe<Scalars["numeric"]>;
  monthly_inventory?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "ebba_applications" */
export type EbbaApplicationsSumOrderBy = {
  amount_cash_in_daca?: Maybe<OrderBy>;
  amount_custom?: Maybe<OrderBy>;
  calculated_borrowing_base?: Maybe<OrderBy>;
  monthly_accounts_receivable?: Maybe<OrderBy>;
  monthly_cash?: Maybe<OrderBy>;
  monthly_inventory?: Maybe<OrderBy>;
};

/** update columns of table "ebba_applications" */
export enum EbbaApplicationsUpdateColumn {
  /** column name */
  AmountCashInDaca = "amount_cash_in_daca",
  /** column name */
  AmountCustom = "amount_custom",
  /** column name */
  AmountCustomNote = "amount_custom_note",
  /** column name */
  ApplicationDate = "application_date",
  /** column name */
  ApprovedAt = "approved_at",
  /** column name */
  ApprovedByUserId = "approved_by_user_id",
  /** column name */
  BankNote = "bank_note",
  /** column name */
  CalculatedBorrowingBase = "calculated_borrowing_base",
  /** column name */
  Category = "category",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExpiresDate = "expires_date",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  MonthlyAccountsReceivable = "monthly_accounts_receivable",
  /** column name */
  MonthlyCash = "monthly_cash",
  /** column name */
  MonthlyInventory = "monthly_inventory",
  /** column name */
  RejectedAt = "rejected_at",
  /** column name */
  RejectedByUserId = "rejected_by_user_id",
  /** column name */
  RejectionNote = "rejection_note",
  /** column name */
  RequestedAt = "requested_at",
  /** column name */
  Status = "status",
  /** column name */
  SubmittedByUserId = "submitted_by_user_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type EbbaApplicationsVarPopFields = {
  amount_cash_in_daca?: Maybe<Scalars["Float"]>;
  amount_custom?: Maybe<Scalars["Float"]>;
  calculated_borrowing_base?: Maybe<Scalars["Float"]>;
  monthly_accounts_receivable?: Maybe<Scalars["Float"]>;
  monthly_cash?: Maybe<Scalars["Float"]>;
  monthly_inventory?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ebba_applications" */
export type EbbaApplicationsVarPopOrderBy = {
  amount_cash_in_daca?: Maybe<OrderBy>;
  amount_custom?: Maybe<OrderBy>;
  calculated_borrowing_base?: Maybe<OrderBy>;
  monthly_accounts_receivable?: Maybe<OrderBy>;
  monthly_cash?: Maybe<OrderBy>;
  monthly_inventory?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EbbaApplicationsVarSampFields = {
  amount_cash_in_daca?: Maybe<Scalars["Float"]>;
  amount_custom?: Maybe<Scalars["Float"]>;
  calculated_borrowing_base?: Maybe<Scalars["Float"]>;
  monthly_accounts_receivable?: Maybe<Scalars["Float"]>;
  monthly_cash?: Maybe<Scalars["Float"]>;
  monthly_inventory?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ebba_applications" */
export type EbbaApplicationsVarSampOrderBy = {
  amount_cash_in_daca?: Maybe<OrderBy>;
  amount_custom?: Maybe<OrderBy>;
  calculated_borrowing_base?: Maybe<OrderBy>;
  monthly_accounts_receivable?: Maybe<OrderBy>;
  monthly_cash?: Maybe<OrderBy>;
  monthly_inventory?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type EbbaApplicationsVarianceFields = {
  amount_cash_in_daca?: Maybe<Scalars["Float"]>;
  amount_custom?: Maybe<Scalars["Float"]>;
  calculated_borrowing_base?: Maybe<Scalars["Float"]>;
  monthly_accounts_receivable?: Maybe<Scalars["Float"]>;
  monthly_cash?: Maybe<Scalars["Float"]>;
  monthly_inventory?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ebba_applications" */
export type EbbaApplicationsVarianceOrderBy = {
  amount_cash_in_daca?: Maybe<OrderBy>;
  amount_custom?: Maybe<OrderBy>;
  calculated_borrowing_base?: Maybe<OrderBy>;
  monthly_accounts_receivable?: Maybe<OrderBy>;
  monthly_cash?: Maybe<OrderBy>;
  monthly_inventory?: Maybe<OrderBy>;
};

/** Table to keep track of files stored on the platform */
export type Files = {
  /** An object relationship */
  company: Companies;
  company_id: Scalars["uuid"];
  /** An array relationship */
  company_licenses: Array<CompanyLicenses>;
  /** An aggregate relationship */
  company_licenses_aggregate: CompanyLicensesAggregate;
  created_at: Scalars["timestamptz"];
  /** An object relationship */
  created_by?: Maybe<Users>;
  created_by_user_id?: Maybe<Scalars["uuid"]>;
  extension: Scalars["String"];
  id: Scalars["uuid"];
  /** An array relationship */
  invoice_files: Array<InvoiceFiles>;
  /** An aggregate relationship */
  invoice_files_aggregate: InvoiceFilesAggregate;
  mime_type?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  path: Scalars["String"];
  /** An array relationship */
  purchase_order_files: Array<PurchaseOrderFiles>;
  /** An aggregate relationship */
  purchase_order_files_aggregate: PurchaseOrderFilesAggregate;
  sequential_id?: Maybe<Scalars["Int"]>;
  size: Scalars["bigint"];
  updated_at: Scalars["timestamptz"];
};

/** Table to keep track of files stored on the platform */
export type FilesCompanyLicensesArgs = {
  distinct_on?: Maybe<Array<CompanyLicensesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyLicensesOrderBy>>;
  where?: Maybe<CompanyLicensesBoolExp>;
};

/** Table to keep track of files stored on the platform */
export type FilesCompanyLicensesAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyLicensesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyLicensesOrderBy>>;
  where?: Maybe<CompanyLicensesBoolExp>;
};

/** Table to keep track of files stored on the platform */
export type FilesInvoiceFilesArgs = {
  distinct_on?: Maybe<Array<InvoiceFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoiceFilesOrderBy>>;
  where?: Maybe<InvoiceFilesBoolExp>;
};

/** Table to keep track of files stored on the platform */
export type FilesInvoiceFilesAggregateArgs = {
  distinct_on?: Maybe<Array<InvoiceFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoiceFilesOrderBy>>;
  where?: Maybe<InvoiceFilesBoolExp>;
};

/** Table to keep track of files stored on the platform */
export type FilesPurchaseOrderFilesArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderFilesOrderBy>>;
  where?: Maybe<PurchaseOrderFilesBoolExp>;
};

/** Table to keep track of files stored on the platform */
export type FilesPurchaseOrderFilesAggregateArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderFilesOrderBy>>;
  where?: Maybe<PurchaseOrderFilesBoolExp>;
};

/** aggregated selection of "files" */
export type FilesAggregate = {
  aggregate?: Maybe<FilesAggregateFields>;
  nodes: Array<Files>;
};

/** aggregate fields of "files" */
export type FilesAggregateFields = {
  avg?: Maybe<FilesAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<FilesMaxFields>;
  min?: Maybe<FilesMinFields>;
  stddev?: Maybe<FilesStddevFields>;
  stddev_pop?: Maybe<FilesStddevPopFields>;
  stddev_samp?: Maybe<FilesStddevSampFields>;
  sum?: Maybe<FilesSumFields>;
  var_pop?: Maybe<FilesVarPopFields>;
  var_samp?: Maybe<FilesVarSampFields>;
  variance?: Maybe<FilesVarianceFields>;
};

/** aggregate fields of "files" */
export type FilesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<FilesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type FilesAvgFields = {
  sequential_id?: Maybe<Scalars["Float"]>;
  size?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "files". All fields are combined with a logical 'AND'. */
export type FilesBoolExp = {
  _and?: Maybe<Array<FilesBoolExp>>;
  _not?: Maybe<FilesBoolExp>;
  _or?: Maybe<Array<FilesBoolExp>>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  company_licenses?: Maybe<CompanyLicensesBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UsersBoolExp>;
  created_by_user_id?: Maybe<UuidComparisonExp>;
  extension?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  invoice_files?: Maybe<InvoiceFilesBoolExp>;
  mime_type?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  path?: Maybe<StringComparisonExp>;
  purchase_order_files?: Maybe<PurchaseOrderFilesBoolExp>;
  sequential_id?: Maybe<IntComparisonExp>;
  size?: Maybe<BigintComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "files" */
export enum FilesConstraint {
  /** unique or primary key constraint */
  FilesPkey = "files_pkey",
}

/** input type for incrementing numeric columns in table "files" */
export type FilesIncInput = {
  sequential_id?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "files" */
export type FilesInsertInput = {
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  company_licenses?: Maybe<CompanyLicensesArrRelInsertInput>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<UsersObjRelInsertInput>;
  created_by_user_id?: Maybe<Scalars["uuid"]>;
  extension?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  invoice_files?: Maybe<InvoiceFilesArrRelInsertInput>;
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  path?: Maybe<Scalars["String"]>;
  purchase_order_files?: Maybe<PurchaseOrderFilesArrRelInsertInput>;
  sequential_id?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["bigint"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type FilesMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_user_id?: Maybe<Scalars["uuid"]>;
  extension?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  path?: Maybe<Scalars["String"]>;
  sequential_id?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["bigint"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type FilesMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_user_id?: Maybe<Scalars["uuid"]>;
  extension?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  path?: Maybe<Scalars["String"]>;
  sequential_id?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["bigint"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "files" */
export type FilesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "files" */
export type FilesObjRelInsertInput = {
  data: FilesInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<FilesOnConflict>;
};

/** on_conflict condition type for table "files" */
export type FilesOnConflict = {
  constraint: FilesConstraint;
  update_columns?: Array<FilesUpdateColumn>;
  where?: Maybe<FilesBoolExp>;
};

/** Ordering options when selecting data from "files". */
export type FilesOrderBy = {
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  company_licenses_aggregate?: Maybe<CompanyLicensesAggregateOrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<UsersOrderBy>;
  created_by_user_id?: Maybe<OrderBy>;
  extension?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  invoice_files_aggregate?: Maybe<InvoiceFilesAggregateOrderBy>;
  mime_type?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  path?: Maybe<OrderBy>;
  purchase_order_files_aggregate?: Maybe<PurchaseOrderFilesAggregateOrderBy>;
  sequential_id?: Maybe<OrderBy>;
  size?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: files */
export type FilesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "files" */
export enum FilesSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedByUserId = "created_by_user_id",
  /** column name */
  Extension = "extension",
  /** column name */
  Id = "id",
  /** column name */
  MimeType = "mime_type",
  /** column name */
  Name = "name",
  /** column name */
  Path = "path",
  /** column name */
  SequentialId = "sequential_id",
  /** column name */
  Size = "size",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "files" */
export type FilesSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_user_id?: Maybe<Scalars["uuid"]>;
  extension?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  path?: Maybe<Scalars["String"]>;
  sequential_id?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["bigint"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type FilesStddevFields = {
  sequential_id?: Maybe<Scalars["Float"]>;
  size?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type FilesStddevPopFields = {
  sequential_id?: Maybe<Scalars["Float"]>;
  size?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type FilesStddevSampFields = {
  sequential_id?: Maybe<Scalars["Float"]>;
  size?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type FilesSumFields = {
  sequential_id?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["bigint"]>;
};

/** update columns of table "files" */
export enum FilesUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedByUserId = "created_by_user_id",
  /** column name */
  Extension = "extension",
  /** column name */
  Id = "id",
  /** column name */
  MimeType = "mime_type",
  /** column name */
  Name = "name",
  /** column name */
  Path = "path",
  /** column name */
  SequentialId = "sequential_id",
  /** column name */
  Size = "size",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type FilesVarPopFields = {
  sequential_id?: Maybe<Scalars["Float"]>;
  size?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type FilesVarSampFields = {
  sequential_id?: Maybe<Scalars["Float"]>;
  size?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type FilesVarianceFields = {
  sequential_id?: Maybe<Scalars["Float"]>;
  size?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "financial_summaries" */
export type FinancialSummaries = {
  account_level_balance_payload?: Maybe<Scalars["jsonb"]>;
  accounting_interest_accrued_today?: Maybe<Scalars["numeric"]>;
  accounting_late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_late_fees?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  adjusted_total_limit?: Maybe<Scalars["numeric"]>;
  available_limit: Scalars["numeric"];
  /** An object relationship */
  company: Companies;
  company_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  daily_interest_rate?: Maybe<Scalars["numeric"]>;
  date: Scalars["date"];
  /** This is the day the customer met their volume discount threshold for their contract term */
  day_volume_threshold_met?: Maybe<Scalars["date"]>;
  days_to_compute_back?: Maybe<Scalars["Int"]>;
  id: Scalars["uuid"];
  interest_accrued_today?: Maybe<Scalars["numeric"]>;
  late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  loans_info?: Maybe<Scalars["json"]>;
  minimum_interest_amount?: Maybe<Scalars["numeric"]>;
  minimum_interest_duration?: Maybe<Scalars["String"]>;
  minimum_interest_remaining?: Maybe<Scalars["numeric"]>;
  minimum_monthly_payload?: Maybe<Scalars["jsonb"]>;
  most_overdue_loan_days?: Maybe<Scalars["Int"]>;
  needs_recompute: Scalars["Boolean"];
  product_type?: Maybe<Scalars["String"]>;
  total_amount_to_pay_interest_on?: Maybe<Scalars["numeric"]>;
  total_fees_paid_adjustment_today?: Maybe<Scalars["numeric"]>;
  total_interest_paid_adjustment_today?: Maybe<Scalars["numeric"]>;
  total_limit: Scalars["numeric"];
  total_outstanding_fees: Scalars["numeric"];
  total_outstanding_interest: Scalars["numeric"];
  total_outstanding_principal: Scalars["numeric"];
  total_outstanding_principal_for_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["numeric"]>;
  total_principal_in_requested_state: Scalars["numeric"];
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** columns and relationships of "financial_summaries" */
export type FinancialSummariesAccountLevelBalancePayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "financial_summaries" */
export type FinancialSummariesLoansInfoArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "financial_summaries" */
export type FinancialSummariesMinimumMonthlyPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "financial_summaries" */
export type FinancialSummariesAggregate = {
  aggregate?: Maybe<FinancialSummariesAggregateFields>;
  nodes: Array<FinancialSummaries>;
};

/** aggregate fields of "financial_summaries" */
export type FinancialSummariesAggregateFields = {
  avg?: Maybe<FinancialSummariesAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<FinancialSummariesMaxFields>;
  min?: Maybe<FinancialSummariesMinFields>;
  stddev?: Maybe<FinancialSummariesStddevFields>;
  stddev_pop?: Maybe<FinancialSummariesStddevPopFields>;
  stddev_samp?: Maybe<FinancialSummariesStddevSampFields>;
  sum?: Maybe<FinancialSummariesSumFields>;
  var_pop?: Maybe<FinancialSummariesVarPopFields>;
  var_samp?: Maybe<FinancialSummariesVarSampFields>;
  variance?: Maybe<FinancialSummariesVarianceFields>;
};

/** aggregate fields of "financial_summaries" */
export type FinancialSummariesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<FinancialSummariesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "financial_summaries" */
export type FinancialSummariesAggregateOrderBy = {
  avg?: Maybe<FinancialSummariesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<FinancialSummariesMaxOrderBy>;
  min?: Maybe<FinancialSummariesMinOrderBy>;
  stddev?: Maybe<FinancialSummariesStddevOrderBy>;
  stddev_pop?: Maybe<FinancialSummariesStddevPopOrderBy>;
  stddev_samp?: Maybe<FinancialSummariesStddevSampOrderBy>;
  sum?: Maybe<FinancialSummariesSumOrderBy>;
  var_pop?: Maybe<FinancialSummariesVarPopOrderBy>;
  var_samp?: Maybe<FinancialSummariesVarSampOrderBy>;
  variance?: Maybe<FinancialSummariesVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type FinancialSummariesAppendInput = {
  account_level_balance_payload?: Maybe<Scalars["jsonb"]>;
  minimum_monthly_payload?: Maybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "financial_summaries" */
export type FinancialSummariesArrRelInsertInput = {
  data: Array<FinancialSummariesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<FinancialSummariesOnConflict>;
};

/** aggregate avg on columns */
export type FinancialSummariesAvgFields = {
  accounting_interest_accrued_today?: Maybe<Scalars["Float"]>;
  accounting_late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_interest?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_late_fees?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_principal?: Maybe<Scalars["Float"]>;
  adjusted_total_limit?: Maybe<Scalars["Float"]>;
  available_limit?: Maybe<Scalars["Float"]>;
  daily_interest_rate?: Maybe<Scalars["Float"]>;
  days_to_compute_back?: Maybe<Scalars["Float"]>;
  interest_accrued_today?: Maybe<Scalars["Float"]>;
  late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  minimum_interest_amount?: Maybe<Scalars["Float"]>;
  minimum_interest_remaining?: Maybe<Scalars["Float"]>;
  most_overdue_loan_days?: Maybe<Scalars["Float"]>;
  total_amount_to_pay_interest_on?: Maybe<Scalars["Float"]>;
  total_fees_paid_adjustment_today?: Maybe<Scalars["Float"]>;
  total_interest_paid_adjustment_today?: Maybe<Scalars["Float"]>;
  total_limit?: Maybe<Scalars["Float"]>;
  total_outstanding_fees?: Maybe<Scalars["Float"]>;
  total_outstanding_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["Float"]>;
  total_principal_in_requested_state?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "financial_summaries" */
export type FinancialSummariesAvgOrderBy = {
  accounting_interest_accrued_today?: Maybe<OrderBy>;
  accounting_late_fees_accrued_today?: Maybe<OrderBy>;
  accounting_total_outstanding_interest?: Maybe<OrderBy>;
  accounting_total_outstanding_late_fees?: Maybe<OrderBy>;
  accounting_total_outstanding_principal?: Maybe<OrderBy>;
  adjusted_total_limit?: Maybe<OrderBy>;
  available_limit?: Maybe<OrderBy>;
  daily_interest_rate?: Maybe<OrderBy>;
  days_to_compute_back?: Maybe<OrderBy>;
  interest_accrued_today?: Maybe<OrderBy>;
  late_fees_accrued_today?: Maybe<OrderBy>;
  minimum_interest_amount?: Maybe<OrderBy>;
  minimum_interest_remaining?: Maybe<OrderBy>;
  most_overdue_loan_days?: Maybe<OrderBy>;
  total_amount_to_pay_interest_on?: Maybe<OrderBy>;
  total_fees_paid_adjustment_today?: Maybe<OrderBy>;
  total_interest_paid_adjustment_today?: Maybe<OrderBy>;
  total_limit?: Maybe<OrderBy>;
  total_outstanding_fees?: Maybe<OrderBy>;
  total_outstanding_interest?: Maybe<OrderBy>;
  total_outstanding_principal?: Maybe<OrderBy>;
  total_outstanding_principal_for_interest?: Maybe<OrderBy>;
  total_outstanding_principal_past_due?: Maybe<OrderBy>;
  total_principal_in_requested_state?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "financial_summaries". All fields are combined with a logical 'AND'. */
export type FinancialSummariesBoolExp = {
  _and?: Maybe<Array<FinancialSummariesBoolExp>>;
  _not?: Maybe<FinancialSummariesBoolExp>;
  _or?: Maybe<Array<FinancialSummariesBoolExp>>;
  account_level_balance_payload?: Maybe<JsonbComparisonExp>;
  accounting_interest_accrued_today?: Maybe<NumericComparisonExp>;
  accounting_late_fees_accrued_today?: Maybe<NumericComparisonExp>;
  accounting_total_outstanding_interest?: Maybe<NumericComparisonExp>;
  accounting_total_outstanding_late_fees?: Maybe<NumericComparisonExp>;
  accounting_total_outstanding_principal?: Maybe<NumericComparisonExp>;
  adjusted_total_limit?: Maybe<NumericComparisonExp>;
  available_limit?: Maybe<NumericComparisonExp>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  daily_interest_rate?: Maybe<NumericComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  day_volume_threshold_met?: Maybe<DateComparisonExp>;
  days_to_compute_back?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  interest_accrued_today?: Maybe<NumericComparisonExp>;
  late_fees_accrued_today?: Maybe<NumericComparisonExp>;
  loans_info?: Maybe<JsonComparisonExp>;
  minimum_interest_amount?: Maybe<NumericComparisonExp>;
  minimum_interest_duration?: Maybe<StringComparisonExp>;
  minimum_interest_remaining?: Maybe<NumericComparisonExp>;
  minimum_monthly_payload?: Maybe<JsonbComparisonExp>;
  most_overdue_loan_days?: Maybe<IntComparisonExp>;
  needs_recompute?: Maybe<BooleanComparisonExp>;
  product_type?: Maybe<StringComparisonExp>;
  total_amount_to_pay_interest_on?: Maybe<NumericComparisonExp>;
  total_fees_paid_adjustment_today?: Maybe<NumericComparisonExp>;
  total_interest_paid_adjustment_today?: Maybe<NumericComparisonExp>;
  total_limit?: Maybe<NumericComparisonExp>;
  total_outstanding_fees?: Maybe<NumericComparisonExp>;
  total_outstanding_interest?: Maybe<NumericComparisonExp>;
  total_outstanding_principal?: Maybe<NumericComparisonExp>;
  total_outstanding_principal_for_interest?: Maybe<NumericComparisonExp>;
  total_outstanding_principal_past_due?: Maybe<NumericComparisonExp>;
  total_principal_in_requested_state?: Maybe<NumericComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "financial_summaries" */
export enum FinancialSummariesConstraint {
  /** unique or primary key constraint */
  FinancialSummariesCompanyIdDateKey = "financial_summaries_company_id_date_key",
  /** unique or primary key constraint */
  FinancialSummariesPkey = "financial_summaries_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type FinancialSummariesDeleteAtPathInput = {
  account_level_balance_payload?: Maybe<Array<Scalars["String"]>>;
  minimum_monthly_payload?: Maybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type FinancialSummariesDeleteElemInput = {
  account_level_balance_payload?: Maybe<Scalars["Int"]>;
  minimum_monthly_payload?: Maybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type FinancialSummariesDeleteKeyInput = {
  account_level_balance_payload?: Maybe<Scalars["String"]>;
  minimum_monthly_payload?: Maybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "financial_summaries" */
export type FinancialSummariesIncInput = {
  accounting_interest_accrued_today?: Maybe<Scalars["numeric"]>;
  accounting_late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_late_fees?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  adjusted_total_limit?: Maybe<Scalars["numeric"]>;
  available_limit?: Maybe<Scalars["numeric"]>;
  daily_interest_rate?: Maybe<Scalars["numeric"]>;
  days_to_compute_back?: Maybe<Scalars["Int"]>;
  interest_accrued_today?: Maybe<Scalars["numeric"]>;
  late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  minimum_interest_amount?: Maybe<Scalars["numeric"]>;
  minimum_interest_remaining?: Maybe<Scalars["numeric"]>;
  most_overdue_loan_days?: Maybe<Scalars["Int"]>;
  total_amount_to_pay_interest_on?: Maybe<Scalars["numeric"]>;
  total_fees_paid_adjustment_today?: Maybe<Scalars["numeric"]>;
  total_interest_paid_adjustment_today?: Maybe<Scalars["numeric"]>;
  total_limit?: Maybe<Scalars["numeric"]>;
  total_outstanding_fees?: Maybe<Scalars["numeric"]>;
  total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["numeric"]>;
  total_principal_in_requested_state?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "financial_summaries" */
export type FinancialSummariesInsertInput = {
  account_level_balance_payload?: Maybe<Scalars["jsonb"]>;
  accounting_interest_accrued_today?: Maybe<Scalars["numeric"]>;
  accounting_late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_late_fees?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  adjusted_total_limit?: Maybe<Scalars["numeric"]>;
  available_limit?: Maybe<Scalars["numeric"]>;
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  daily_interest_rate?: Maybe<Scalars["numeric"]>;
  date?: Maybe<Scalars["date"]>;
  /** This is the day the customer met their volume discount threshold for their contract term */
  day_volume_threshold_met?: Maybe<Scalars["date"]>;
  days_to_compute_back?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  interest_accrued_today?: Maybe<Scalars["numeric"]>;
  late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  loans_info?: Maybe<Scalars["json"]>;
  minimum_interest_amount?: Maybe<Scalars["numeric"]>;
  minimum_interest_duration?: Maybe<Scalars["String"]>;
  minimum_interest_remaining?: Maybe<Scalars["numeric"]>;
  minimum_monthly_payload?: Maybe<Scalars["jsonb"]>;
  most_overdue_loan_days?: Maybe<Scalars["Int"]>;
  needs_recompute?: Maybe<Scalars["Boolean"]>;
  product_type?: Maybe<Scalars["String"]>;
  total_amount_to_pay_interest_on?: Maybe<Scalars["numeric"]>;
  total_fees_paid_adjustment_today?: Maybe<Scalars["numeric"]>;
  total_interest_paid_adjustment_today?: Maybe<Scalars["numeric"]>;
  total_limit?: Maybe<Scalars["numeric"]>;
  total_outstanding_fees?: Maybe<Scalars["numeric"]>;
  total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["numeric"]>;
  total_principal_in_requested_state?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type FinancialSummariesMaxFields = {
  accounting_interest_accrued_today?: Maybe<Scalars["numeric"]>;
  accounting_late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_late_fees?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  adjusted_total_limit?: Maybe<Scalars["numeric"]>;
  available_limit?: Maybe<Scalars["numeric"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  daily_interest_rate?: Maybe<Scalars["numeric"]>;
  date?: Maybe<Scalars["date"]>;
  /** This is the day the customer met their volume discount threshold for their contract term */
  day_volume_threshold_met?: Maybe<Scalars["date"]>;
  days_to_compute_back?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  interest_accrued_today?: Maybe<Scalars["numeric"]>;
  late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  minimum_interest_amount?: Maybe<Scalars["numeric"]>;
  minimum_interest_duration?: Maybe<Scalars["String"]>;
  minimum_interest_remaining?: Maybe<Scalars["numeric"]>;
  most_overdue_loan_days?: Maybe<Scalars["Int"]>;
  product_type?: Maybe<Scalars["String"]>;
  total_amount_to_pay_interest_on?: Maybe<Scalars["numeric"]>;
  total_fees_paid_adjustment_today?: Maybe<Scalars["numeric"]>;
  total_interest_paid_adjustment_today?: Maybe<Scalars["numeric"]>;
  total_limit?: Maybe<Scalars["numeric"]>;
  total_outstanding_fees?: Maybe<Scalars["numeric"]>;
  total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["numeric"]>;
  total_principal_in_requested_state?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "financial_summaries" */
export type FinancialSummariesMaxOrderBy = {
  accounting_interest_accrued_today?: Maybe<OrderBy>;
  accounting_late_fees_accrued_today?: Maybe<OrderBy>;
  accounting_total_outstanding_interest?: Maybe<OrderBy>;
  accounting_total_outstanding_late_fees?: Maybe<OrderBy>;
  accounting_total_outstanding_principal?: Maybe<OrderBy>;
  adjusted_total_limit?: Maybe<OrderBy>;
  available_limit?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  daily_interest_rate?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  /** This is the day the customer met their volume discount threshold for their contract term */
  day_volume_threshold_met?: Maybe<OrderBy>;
  days_to_compute_back?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  interest_accrued_today?: Maybe<OrderBy>;
  late_fees_accrued_today?: Maybe<OrderBy>;
  minimum_interest_amount?: Maybe<OrderBy>;
  minimum_interest_duration?: Maybe<OrderBy>;
  minimum_interest_remaining?: Maybe<OrderBy>;
  most_overdue_loan_days?: Maybe<OrderBy>;
  product_type?: Maybe<OrderBy>;
  total_amount_to_pay_interest_on?: Maybe<OrderBy>;
  total_fees_paid_adjustment_today?: Maybe<OrderBy>;
  total_interest_paid_adjustment_today?: Maybe<OrderBy>;
  total_limit?: Maybe<OrderBy>;
  total_outstanding_fees?: Maybe<OrderBy>;
  total_outstanding_interest?: Maybe<OrderBy>;
  total_outstanding_principal?: Maybe<OrderBy>;
  total_outstanding_principal_for_interest?: Maybe<OrderBy>;
  total_outstanding_principal_past_due?: Maybe<OrderBy>;
  total_principal_in_requested_state?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type FinancialSummariesMinFields = {
  accounting_interest_accrued_today?: Maybe<Scalars["numeric"]>;
  accounting_late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_late_fees?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  adjusted_total_limit?: Maybe<Scalars["numeric"]>;
  available_limit?: Maybe<Scalars["numeric"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  daily_interest_rate?: Maybe<Scalars["numeric"]>;
  date?: Maybe<Scalars["date"]>;
  /** This is the day the customer met their volume discount threshold for their contract term */
  day_volume_threshold_met?: Maybe<Scalars["date"]>;
  days_to_compute_back?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  interest_accrued_today?: Maybe<Scalars["numeric"]>;
  late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  minimum_interest_amount?: Maybe<Scalars["numeric"]>;
  minimum_interest_duration?: Maybe<Scalars["String"]>;
  minimum_interest_remaining?: Maybe<Scalars["numeric"]>;
  most_overdue_loan_days?: Maybe<Scalars["Int"]>;
  product_type?: Maybe<Scalars["String"]>;
  total_amount_to_pay_interest_on?: Maybe<Scalars["numeric"]>;
  total_fees_paid_adjustment_today?: Maybe<Scalars["numeric"]>;
  total_interest_paid_adjustment_today?: Maybe<Scalars["numeric"]>;
  total_limit?: Maybe<Scalars["numeric"]>;
  total_outstanding_fees?: Maybe<Scalars["numeric"]>;
  total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["numeric"]>;
  total_principal_in_requested_state?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "financial_summaries" */
export type FinancialSummariesMinOrderBy = {
  accounting_interest_accrued_today?: Maybe<OrderBy>;
  accounting_late_fees_accrued_today?: Maybe<OrderBy>;
  accounting_total_outstanding_interest?: Maybe<OrderBy>;
  accounting_total_outstanding_late_fees?: Maybe<OrderBy>;
  accounting_total_outstanding_principal?: Maybe<OrderBy>;
  adjusted_total_limit?: Maybe<OrderBy>;
  available_limit?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  daily_interest_rate?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  /** This is the day the customer met their volume discount threshold for their contract term */
  day_volume_threshold_met?: Maybe<OrderBy>;
  days_to_compute_back?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  interest_accrued_today?: Maybe<OrderBy>;
  late_fees_accrued_today?: Maybe<OrderBy>;
  minimum_interest_amount?: Maybe<OrderBy>;
  minimum_interest_duration?: Maybe<OrderBy>;
  minimum_interest_remaining?: Maybe<OrderBy>;
  most_overdue_loan_days?: Maybe<OrderBy>;
  product_type?: Maybe<OrderBy>;
  total_amount_to_pay_interest_on?: Maybe<OrderBy>;
  total_fees_paid_adjustment_today?: Maybe<OrderBy>;
  total_interest_paid_adjustment_today?: Maybe<OrderBy>;
  total_limit?: Maybe<OrderBy>;
  total_outstanding_fees?: Maybe<OrderBy>;
  total_outstanding_interest?: Maybe<OrderBy>;
  total_outstanding_principal?: Maybe<OrderBy>;
  total_outstanding_principal_for_interest?: Maybe<OrderBy>;
  total_outstanding_principal_past_due?: Maybe<OrderBy>;
  total_principal_in_requested_state?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "financial_summaries" */
export type FinancialSummariesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<FinancialSummaries>;
};

/** on_conflict condition type for table "financial_summaries" */
export type FinancialSummariesOnConflict = {
  constraint: FinancialSummariesConstraint;
  update_columns?: Array<FinancialSummariesUpdateColumn>;
  where?: Maybe<FinancialSummariesBoolExp>;
};

/** Ordering options when selecting data from "financial_summaries". */
export type FinancialSummariesOrderBy = {
  account_level_balance_payload?: Maybe<OrderBy>;
  accounting_interest_accrued_today?: Maybe<OrderBy>;
  accounting_late_fees_accrued_today?: Maybe<OrderBy>;
  accounting_total_outstanding_interest?: Maybe<OrderBy>;
  accounting_total_outstanding_late_fees?: Maybe<OrderBy>;
  accounting_total_outstanding_principal?: Maybe<OrderBy>;
  adjusted_total_limit?: Maybe<OrderBy>;
  available_limit?: Maybe<OrderBy>;
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  daily_interest_rate?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  day_volume_threshold_met?: Maybe<OrderBy>;
  days_to_compute_back?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  interest_accrued_today?: Maybe<OrderBy>;
  late_fees_accrued_today?: Maybe<OrderBy>;
  loans_info?: Maybe<OrderBy>;
  minimum_interest_amount?: Maybe<OrderBy>;
  minimum_interest_duration?: Maybe<OrderBy>;
  minimum_interest_remaining?: Maybe<OrderBy>;
  minimum_monthly_payload?: Maybe<OrderBy>;
  most_overdue_loan_days?: Maybe<OrderBy>;
  needs_recompute?: Maybe<OrderBy>;
  product_type?: Maybe<OrderBy>;
  total_amount_to_pay_interest_on?: Maybe<OrderBy>;
  total_fees_paid_adjustment_today?: Maybe<OrderBy>;
  total_interest_paid_adjustment_today?: Maybe<OrderBy>;
  total_limit?: Maybe<OrderBy>;
  total_outstanding_fees?: Maybe<OrderBy>;
  total_outstanding_interest?: Maybe<OrderBy>;
  total_outstanding_principal?: Maybe<OrderBy>;
  total_outstanding_principal_for_interest?: Maybe<OrderBy>;
  total_outstanding_principal_past_due?: Maybe<OrderBy>;
  total_principal_in_requested_state?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: financial_summaries */
export type FinancialSummariesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type FinancialSummariesPrependInput = {
  account_level_balance_payload?: Maybe<Scalars["jsonb"]>;
  minimum_monthly_payload?: Maybe<Scalars["jsonb"]>;
};

/** select columns of table "financial_summaries" */
export enum FinancialSummariesSelectColumn {
  /** column name */
  AccountLevelBalancePayload = "account_level_balance_payload",
  /** column name */
  AccountingInterestAccruedToday = "accounting_interest_accrued_today",
  /** column name */
  AccountingLateFeesAccruedToday = "accounting_late_fees_accrued_today",
  /** column name */
  AccountingTotalOutstandingInterest = "accounting_total_outstanding_interest",
  /** column name */
  AccountingTotalOutstandingLateFees = "accounting_total_outstanding_late_fees",
  /** column name */
  AccountingTotalOutstandingPrincipal = "accounting_total_outstanding_principal",
  /** column name */
  AdjustedTotalLimit = "adjusted_total_limit",
  /** column name */
  AvailableLimit = "available_limit",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DailyInterestRate = "daily_interest_rate",
  /** column name */
  Date = "date",
  /** column name */
  DayVolumeThresholdMet = "day_volume_threshold_met",
  /** column name */
  DaysToComputeBack = "days_to_compute_back",
  /** column name */
  Id = "id",
  /** column name */
  InterestAccruedToday = "interest_accrued_today",
  /** column name */
  LateFeesAccruedToday = "late_fees_accrued_today",
  /** column name */
  LoansInfo = "loans_info",
  /** column name */
  MinimumInterestAmount = "minimum_interest_amount",
  /** column name */
  MinimumInterestDuration = "minimum_interest_duration",
  /** column name */
  MinimumInterestRemaining = "minimum_interest_remaining",
  /** column name */
  MinimumMonthlyPayload = "minimum_monthly_payload",
  /** column name */
  MostOverdueLoanDays = "most_overdue_loan_days",
  /** column name */
  NeedsRecompute = "needs_recompute",
  /** column name */
  ProductType = "product_type",
  /** column name */
  TotalAmountToPayInterestOn = "total_amount_to_pay_interest_on",
  /** column name */
  TotalFeesPaidAdjustmentToday = "total_fees_paid_adjustment_today",
  /** column name */
  TotalInterestPaidAdjustmentToday = "total_interest_paid_adjustment_today",
  /** column name */
  TotalLimit = "total_limit",
  /** column name */
  TotalOutstandingFees = "total_outstanding_fees",
  /** column name */
  TotalOutstandingInterest = "total_outstanding_interest",
  /** column name */
  TotalOutstandingPrincipal = "total_outstanding_principal",
  /** column name */
  TotalOutstandingPrincipalForInterest = "total_outstanding_principal_for_interest",
  /** column name */
  TotalOutstandingPrincipalPastDue = "total_outstanding_principal_past_due",
  /** column name */
  TotalPrincipalInRequestedState = "total_principal_in_requested_state",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "financial_summaries" */
export type FinancialSummariesSetInput = {
  account_level_balance_payload?: Maybe<Scalars["jsonb"]>;
  accounting_interest_accrued_today?: Maybe<Scalars["numeric"]>;
  accounting_late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_late_fees?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  adjusted_total_limit?: Maybe<Scalars["numeric"]>;
  available_limit?: Maybe<Scalars["numeric"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  daily_interest_rate?: Maybe<Scalars["numeric"]>;
  date?: Maybe<Scalars["date"]>;
  /** This is the day the customer met their volume discount threshold for their contract term */
  day_volume_threshold_met?: Maybe<Scalars["date"]>;
  days_to_compute_back?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  interest_accrued_today?: Maybe<Scalars["numeric"]>;
  late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  loans_info?: Maybe<Scalars["json"]>;
  minimum_interest_amount?: Maybe<Scalars["numeric"]>;
  minimum_interest_duration?: Maybe<Scalars["String"]>;
  minimum_interest_remaining?: Maybe<Scalars["numeric"]>;
  minimum_monthly_payload?: Maybe<Scalars["jsonb"]>;
  most_overdue_loan_days?: Maybe<Scalars["Int"]>;
  needs_recompute?: Maybe<Scalars["Boolean"]>;
  product_type?: Maybe<Scalars["String"]>;
  total_amount_to_pay_interest_on?: Maybe<Scalars["numeric"]>;
  total_fees_paid_adjustment_today?: Maybe<Scalars["numeric"]>;
  total_interest_paid_adjustment_today?: Maybe<Scalars["numeric"]>;
  total_limit?: Maybe<Scalars["numeric"]>;
  total_outstanding_fees?: Maybe<Scalars["numeric"]>;
  total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["numeric"]>;
  total_principal_in_requested_state?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type FinancialSummariesStddevFields = {
  accounting_interest_accrued_today?: Maybe<Scalars["Float"]>;
  accounting_late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_interest?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_late_fees?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_principal?: Maybe<Scalars["Float"]>;
  adjusted_total_limit?: Maybe<Scalars["Float"]>;
  available_limit?: Maybe<Scalars["Float"]>;
  daily_interest_rate?: Maybe<Scalars["Float"]>;
  days_to_compute_back?: Maybe<Scalars["Float"]>;
  interest_accrued_today?: Maybe<Scalars["Float"]>;
  late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  minimum_interest_amount?: Maybe<Scalars["Float"]>;
  minimum_interest_remaining?: Maybe<Scalars["Float"]>;
  most_overdue_loan_days?: Maybe<Scalars["Float"]>;
  total_amount_to_pay_interest_on?: Maybe<Scalars["Float"]>;
  total_fees_paid_adjustment_today?: Maybe<Scalars["Float"]>;
  total_interest_paid_adjustment_today?: Maybe<Scalars["Float"]>;
  total_limit?: Maybe<Scalars["Float"]>;
  total_outstanding_fees?: Maybe<Scalars["Float"]>;
  total_outstanding_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["Float"]>;
  total_principal_in_requested_state?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "financial_summaries" */
export type FinancialSummariesStddevOrderBy = {
  accounting_interest_accrued_today?: Maybe<OrderBy>;
  accounting_late_fees_accrued_today?: Maybe<OrderBy>;
  accounting_total_outstanding_interest?: Maybe<OrderBy>;
  accounting_total_outstanding_late_fees?: Maybe<OrderBy>;
  accounting_total_outstanding_principal?: Maybe<OrderBy>;
  adjusted_total_limit?: Maybe<OrderBy>;
  available_limit?: Maybe<OrderBy>;
  daily_interest_rate?: Maybe<OrderBy>;
  days_to_compute_back?: Maybe<OrderBy>;
  interest_accrued_today?: Maybe<OrderBy>;
  late_fees_accrued_today?: Maybe<OrderBy>;
  minimum_interest_amount?: Maybe<OrderBy>;
  minimum_interest_remaining?: Maybe<OrderBy>;
  most_overdue_loan_days?: Maybe<OrderBy>;
  total_amount_to_pay_interest_on?: Maybe<OrderBy>;
  total_fees_paid_adjustment_today?: Maybe<OrderBy>;
  total_interest_paid_adjustment_today?: Maybe<OrderBy>;
  total_limit?: Maybe<OrderBy>;
  total_outstanding_fees?: Maybe<OrderBy>;
  total_outstanding_interest?: Maybe<OrderBy>;
  total_outstanding_principal?: Maybe<OrderBy>;
  total_outstanding_principal_for_interest?: Maybe<OrderBy>;
  total_outstanding_principal_past_due?: Maybe<OrderBy>;
  total_principal_in_requested_state?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type FinancialSummariesStddevPopFields = {
  accounting_interest_accrued_today?: Maybe<Scalars["Float"]>;
  accounting_late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_interest?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_late_fees?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_principal?: Maybe<Scalars["Float"]>;
  adjusted_total_limit?: Maybe<Scalars["Float"]>;
  available_limit?: Maybe<Scalars["Float"]>;
  daily_interest_rate?: Maybe<Scalars["Float"]>;
  days_to_compute_back?: Maybe<Scalars["Float"]>;
  interest_accrued_today?: Maybe<Scalars["Float"]>;
  late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  minimum_interest_amount?: Maybe<Scalars["Float"]>;
  minimum_interest_remaining?: Maybe<Scalars["Float"]>;
  most_overdue_loan_days?: Maybe<Scalars["Float"]>;
  total_amount_to_pay_interest_on?: Maybe<Scalars["Float"]>;
  total_fees_paid_adjustment_today?: Maybe<Scalars["Float"]>;
  total_interest_paid_adjustment_today?: Maybe<Scalars["Float"]>;
  total_limit?: Maybe<Scalars["Float"]>;
  total_outstanding_fees?: Maybe<Scalars["Float"]>;
  total_outstanding_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["Float"]>;
  total_principal_in_requested_state?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "financial_summaries" */
export type FinancialSummariesStddevPopOrderBy = {
  accounting_interest_accrued_today?: Maybe<OrderBy>;
  accounting_late_fees_accrued_today?: Maybe<OrderBy>;
  accounting_total_outstanding_interest?: Maybe<OrderBy>;
  accounting_total_outstanding_late_fees?: Maybe<OrderBy>;
  accounting_total_outstanding_principal?: Maybe<OrderBy>;
  adjusted_total_limit?: Maybe<OrderBy>;
  available_limit?: Maybe<OrderBy>;
  daily_interest_rate?: Maybe<OrderBy>;
  days_to_compute_back?: Maybe<OrderBy>;
  interest_accrued_today?: Maybe<OrderBy>;
  late_fees_accrued_today?: Maybe<OrderBy>;
  minimum_interest_amount?: Maybe<OrderBy>;
  minimum_interest_remaining?: Maybe<OrderBy>;
  most_overdue_loan_days?: Maybe<OrderBy>;
  total_amount_to_pay_interest_on?: Maybe<OrderBy>;
  total_fees_paid_adjustment_today?: Maybe<OrderBy>;
  total_interest_paid_adjustment_today?: Maybe<OrderBy>;
  total_limit?: Maybe<OrderBy>;
  total_outstanding_fees?: Maybe<OrderBy>;
  total_outstanding_interest?: Maybe<OrderBy>;
  total_outstanding_principal?: Maybe<OrderBy>;
  total_outstanding_principal_for_interest?: Maybe<OrderBy>;
  total_outstanding_principal_past_due?: Maybe<OrderBy>;
  total_principal_in_requested_state?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type FinancialSummariesStddevSampFields = {
  accounting_interest_accrued_today?: Maybe<Scalars["Float"]>;
  accounting_late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_interest?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_late_fees?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_principal?: Maybe<Scalars["Float"]>;
  adjusted_total_limit?: Maybe<Scalars["Float"]>;
  available_limit?: Maybe<Scalars["Float"]>;
  daily_interest_rate?: Maybe<Scalars["Float"]>;
  days_to_compute_back?: Maybe<Scalars["Float"]>;
  interest_accrued_today?: Maybe<Scalars["Float"]>;
  late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  minimum_interest_amount?: Maybe<Scalars["Float"]>;
  minimum_interest_remaining?: Maybe<Scalars["Float"]>;
  most_overdue_loan_days?: Maybe<Scalars["Float"]>;
  total_amount_to_pay_interest_on?: Maybe<Scalars["Float"]>;
  total_fees_paid_adjustment_today?: Maybe<Scalars["Float"]>;
  total_interest_paid_adjustment_today?: Maybe<Scalars["Float"]>;
  total_limit?: Maybe<Scalars["Float"]>;
  total_outstanding_fees?: Maybe<Scalars["Float"]>;
  total_outstanding_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["Float"]>;
  total_principal_in_requested_state?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "financial_summaries" */
export type FinancialSummariesStddevSampOrderBy = {
  accounting_interest_accrued_today?: Maybe<OrderBy>;
  accounting_late_fees_accrued_today?: Maybe<OrderBy>;
  accounting_total_outstanding_interest?: Maybe<OrderBy>;
  accounting_total_outstanding_late_fees?: Maybe<OrderBy>;
  accounting_total_outstanding_principal?: Maybe<OrderBy>;
  adjusted_total_limit?: Maybe<OrderBy>;
  available_limit?: Maybe<OrderBy>;
  daily_interest_rate?: Maybe<OrderBy>;
  days_to_compute_back?: Maybe<OrderBy>;
  interest_accrued_today?: Maybe<OrderBy>;
  late_fees_accrued_today?: Maybe<OrderBy>;
  minimum_interest_amount?: Maybe<OrderBy>;
  minimum_interest_remaining?: Maybe<OrderBy>;
  most_overdue_loan_days?: Maybe<OrderBy>;
  total_amount_to_pay_interest_on?: Maybe<OrderBy>;
  total_fees_paid_adjustment_today?: Maybe<OrderBy>;
  total_interest_paid_adjustment_today?: Maybe<OrderBy>;
  total_limit?: Maybe<OrderBy>;
  total_outstanding_fees?: Maybe<OrderBy>;
  total_outstanding_interest?: Maybe<OrderBy>;
  total_outstanding_principal?: Maybe<OrderBy>;
  total_outstanding_principal_for_interest?: Maybe<OrderBy>;
  total_outstanding_principal_past_due?: Maybe<OrderBy>;
  total_principal_in_requested_state?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type FinancialSummariesSumFields = {
  accounting_interest_accrued_today?: Maybe<Scalars["numeric"]>;
  accounting_late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_late_fees?: Maybe<Scalars["numeric"]>;
  accounting_total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  adjusted_total_limit?: Maybe<Scalars["numeric"]>;
  available_limit?: Maybe<Scalars["numeric"]>;
  daily_interest_rate?: Maybe<Scalars["numeric"]>;
  days_to_compute_back?: Maybe<Scalars["Int"]>;
  interest_accrued_today?: Maybe<Scalars["numeric"]>;
  late_fees_accrued_today?: Maybe<Scalars["numeric"]>;
  minimum_interest_amount?: Maybe<Scalars["numeric"]>;
  minimum_interest_remaining?: Maybe<Scalars["numeric"]>;
  most_overdue_loan_days?: Maybe<Scalars["Int"]>;
  total_amount_to_pay_interest_on?: Maybe<Scalars["numeric"]>;
  total_fees_paid_adjustment_today?: Maybe<Scalars["numeric"]>;
  total_interest_paid_adjustment_today?: Maybe<Scalars["numeric"]>;
  total_limit?: Maybe<Scalars["numeric"]>;
  total_outstanding_fees?: Maybe<Scalars["numeric"]>;
  total_outstanding_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["numeric"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["numeric"]>;
  total_principal_in_requested_state?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "financial_summaries" */
export type FinancialSummariesSumOrderBy = {
  accounting_interest_accrued_today?: Maybe<OrderBy>;
  accounting_late_fees_accrued_today?: Maybe<OrderBy>;
  accounting_total_outstanding_interest?: Maybe<OrderBy>;
  accounting_total_outstanding_late_fees?: Maybe<OrderBy>;
  accounting_total_outstanding_principal?: Maybe<OrderBy>;
  adjusted_total_limit?: Maybe<OrderBy>;
  available_limit?: Maybe<OrderBy>;
  daily_interest_rate?: Maybe<OrderBy>;
  days_to_compute_back?: Maybe<OrderBy>;
  interest_accrued_today?: Maybe<OrderBy>;
  late_fees_accrued_today?: Maybe<OrderBy>;
  minimum_interest_amount?: Maybe<OrderBy>;
  minimum_interest_remaining?: Maybe<OrderBy>;
  most_overdue_loan_days?: Maybe<OrderBy>;
  total_amount_to_pay_interest_on?: Maybe<OrderBy>;
  total_fees_paid_adjustment_today?: Maybe<OrderBy>;
  total_interest_paid_adjustment_today?: Maybe<OrderBy>;
  total_limit?: Maybe<OrderBy>;
  total_outstanding_fees?: Maybe<OrderBy>;
  total_outstanding_interest?: Maybe<OrderBy>;
  total_outstanding_principal?: Maybe<OrderBy>;
  total_outstanding_principal_for_interest?: Maybe<OrderBy>;
  total_outstanding_principal_past_due?: Maybe<OrderBy>;
  total_principal_in_requested_state?: Maybe<OrderBy>;
};

/** update columns of table "financial_summaries" */
export enum FinancialSummariesUpdateColumn {
  /** column name */
  AccountLevelBalancePayload = "account_level_balance_payload",
  /** column name */
  AccountingInterestAccruedToday = "accounting_interest_accrued_today",
  /** column name */
  AccountingLateFeesAccruedToday = "accounting_late_fees_accrued_today",
  /** column name */
  AccountingTotalOutstandingInterest = "accounting_total_outstanding_interest",
  /** column name */
  AccountingTotalOutstandingLateFees = "accounting_total_outstanding_late_fees",
  /** column name */
  AccountingTotalOutstandingPrincipal = "accounting_total_outstanding_principal",
  /** column name */
  AdjustedTotalLimit = "adjusted_total_limit",
  /** column name */
  AvailableLimit = "available_limit",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DailyInterestRate = "daily_interest_rate",
  /** column name */
  Date = "date",
  /** column name */
  DayVolumeThresholdMet = "day_volume_threshold_met",
  /** column name */
  DaysToComputeBack = "days_to_compute_back",
  /** column name */
  Id = "id",
  /** column name */
  InterestAccruedToday = "interest_accrued_today",
  /** column name */
  LateFeesAccruedToday = "late_fees_accrued_today",
  /** column name */
  LoansInfo = "loans_info",
  /** column name */
  MinimumInterestAmount = "minimum_interest_amount",
  /** column name */
  MinimumInterestDuration = "minimum_interest_duration",
  /** column name */
  MinimumInterestRemaining = "minimum_interest_remaining",
  /** column name */
  MinimumMonthlyPayload = "minimum_monthly_payload",
  /** column name */
  MostOverdueLoanDays = "most_overdue_loan_days",
  /** column name */
  NeedsRecompute = "needs_recompute",
  /** column name */
  ProductType = "product_type",
  /** column name */
  TotalAmountToPayInterestOn = "total_amount_to_pay_interest_on",
  /** column name */
  TotalFeesPaidAdjustmentToday = "total_fees_paid_adjustment_today",
  /** column name */
  TotalInterestPaidAdjustmentToday = "total_interest_paid_adjustment_today",
  /** column name */
  TotalLimit = "total_limit",
  /** column name */
  TotalOutstandingFees = "total_outstanding_fees",
  /** column name */
  TotalOutstandingInterest = "total_outstanding_interest",
  /** column name */
  TotalOutstandingPrincipal = "total_outstanding_principal",
  /** column name */
  TotalOutstandingPrincipalForInterest = "total_outstanding_principal_for_interest",
  /** column name */
  TotalOutstandingPrincipalPastDue = "total_outstanding_principal_past_due",
  /** column name */
  TotalPrincipalInRequestedState = "total_principal_in_requested_state",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type FinancialSummariesVarPopFields = {
  accounting_interest_accrued_today?: Maybe<Scalars["Float"]>;
  accounting_late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_interest?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_late_fees?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_principal?: Maybe<Scalars["Float"]>;
  adjusted_total_limit?: Maybe<Scalars["Float"]>;
  available_limit?: Maybe<Scalars["Float"]>;
  daily_interest_rate?: Maybe<Scalars["Float"]>;
  days_to_compute_back?: Maybe<Scalars["Float"]>;
  interest_accrued_today?: Maybe<Scalars["Float"]>;
  late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  minimum_interest_amount?: Maybe<Scalars["Float"]>;
  minimum_interest_remaining?: Maybe<Scalars["Float"]>;
  most_overdue_loan_days?: Maybe<Scalars["Float"]>;
  total_amount_to_pay_interest_on?: Maybe<Scalars["Float"]>;
  total_fees_paid_adjustment_today?: Maybe<Scalars["Float"]>;
  total_interest_paid_adjustment_today?: Maybe<Scalars["Float"]>;
  total_limit?: Maybe<Scalars["Float"]>;
  total_outstanding_fees?: Maybe<Scalars["Float"]>;
  total_outstanding_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["Float"]>;
  total_principal_in_requested_state?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "financial_summaries" */
export type FinancialSummariesVarPopOrderBy = {
  accounting_interest_accrued_today?: Maybe<OrderBy>;
  accounting_late_fees_accrued_today?: Maybe<OrderBy>;
  accounting_total_outstanding_interest?: Maybe<OrderBy>;
  accounting_total_outstanding_late_fees?: Maybe<OrderBy>;
  accounting_total_outstanding_principal?: Maybe<OrderBy>;
  adjusted_total_limit?: Maybe<OrderBy>;
  available_limit?: Maybe<OrderBy>;
  daily_interest_rate?: Maybe<OrderBy>;
  days_to_compute_back?: Maybe<OrderBy>;
  interest_accrued_today?: Maybe<OrderBy>;
  late_fees_accrued_today?: Maybe<OrderBy>;
  minimum_interest_amount?: Maybe<OrderBy>;
  minimum_interest_remaining?: Maybe<OrderBy>;
  most_overdue_loan_days?: Maybe<OrderBy>;
  total_amount_to_pay_interest_on?: Maybe<OrderBy>;
  total_fees_paid_adjustment_today?: Maybe<OrderBy>;
  total_interest_paid_adjustment_today?: Maybe<OrderBy>;
  total_limit?: Maybe<OrderBy>;
  total_outstanding_fees?: Maybe<OrderBy>;
  total_outstanding_interest?: Maybe<OrderBy>;
  total_outstanding_principal?: Maybe<OrderBy>;
  total_outstanding_principal_for_interest?: Maybe<OrderBy>;
  total_outstanding_principal_past_due?: Maybe<OrderBy>;
  total_principal_in_requested_state?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type FinancialSummariesVarSampFields = {
  accounting_interest_accrued_today?: Maybe<Scalars["Float"]>;
  accounting_late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_interest?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_late_fees?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_principal?: Maybe<Scalars["Float"]>;
  adjusted_total_limit?: Maybe<Scalars["Float"]>;
  available_limit?: Maybe<Scalars["Float"]>;
  daily_interest_rate?: Maybe<Scalars["Float"]>;
  days_to_compute_back?: Maybe<Scalars["Float"]>;
  interest_accrued_today?: Maybe<Scalars["Float"]>;
  late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  minimum_interest_amount?: Maybe<Scalars["Float"]>;
  minimum_interest_remaining?: Maybe<Scalars["Float"]>;
  most_overdue_loan_days?: Maybe<Scalars["Float"]>;
  total_amount_to_pay_interest_on?: Maybe<Scalars["Float"]>;
  total_fees_paid_adjustment_today?: Maybe<Scalars["Float"]>;
  total_interest_paid_adjustment_today?: Maybe<Scalars["Float"]>;
  total_limit?: Maybe<Scalars["Float"]>;
  total_outstanding_fees?: Maybe<Scalars["Float"]>;
  total_outstanding_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["Float"]>;
  total_principal_in_requested_state?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "financial_summaries" */
export type FinancialSummariesVarSampOrderBy = {
  accounting_interest_accrued_today?: Maybe<OrderBy>;
  accounting_late_fees_accrued_today?: Maybe<OrderBy>;
  accounting_total_outstanding_interest?: Maybe<OrderBy>;
  accounting_total_outstanding_late_fees?: Maybe<OrderBy>;
  accounting_total_outstanding_principal?: Maybe<OrderBy>;
  adjusted_total_limit?: Maybe<OrderBy>;
  available_limit?: Maybe<OrderBy>;
  daily_interest_rate?: Maybe<OrderBy>;
  days_to_compute_back?: Maybe<OrderBy>;
  interest_accrued_today?: Maybe<OrderBy>;
  late_fees_accrued_today?: Maybe<OrderBy>;
  minimum_interest_amount?: Maybe<OrderBy>;
  minimum_interest_remaining?: Maybe<OrderBy>;
  most_overdue_loan_days?: Maybe<OrderBy>;
  total_amount_to_pay_interest_on?: Maybe<OrderBy>;
  total_fees_paid_adjustment_today?: Maybe<OrderBy>;
  total_interest_paid_adjustment_today?: Maybe<OrderBy>;
  total_limit?: Maybe<OrderBy>;
  total_outstanding_fees?: Maybe<OrderBy>;
  total_outstanding_interest?: Maybe<OrderBy>;
  total_outstanding_principal?: Maybe<OrderBy>;
  total_outstanding_principal_for_interest?: Maybe<OrderBy>;
  total_outstanding_principal_past_due?: Maybe<OrderBy>;
  total_principal_in_requested_state?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type FinancialSummariesVarianceFields = {
  accounting_interest_accrued_today?: Maybe<Scalars["Float"]>;
  accounting_late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_interest?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_late_fees?: Maybe<Scalars["Float"]>;
  accounting_total_outstanding_principal?: Maybe<Scalars["Float"]>;
  adjusted_total_limit?: Maybe<Scalars["Float"]>;
  available_limit?: Maybe<Scalars["Float"]>;
  daily_interest_rate?: Maybe<Scalars["Float"]>;
  days_to_compute_back?: Maybe<Scalars["Float"]>;
  interest_accrued_today?: Maybe<Scalars["Float"]>;
  late_fees_accrued_today?: Maybe<Scalars["Float"]>;
  minimum_interest_amount?: Maybe<Scalars["Float"]>;
  minimum_interest_remaining?: Maybe<Scalars["Float"]>;
  most_overdue_loan_days?: Maybe<Scalars["Float"]>;
  total_amount_to_pay_interest_on?: Maybe<Scalars["Float"]>;
  total_fees_paid_adjustment_today?: Maybe<Scalars["Float"]>;
  total_interest_paid_adjustment_today?: Maybe<Scalars["Float"]>;
  total_limit?: Maybe<Scalars["Float"]>;
  total_outstanding_fees?: Maybe<Scalars["Float"]>;
  total_outstanding_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_for_interest?: Maybe<Scalars["Float"]>;
  total_outstanding_principal_past_due?: Maybe<Scalars["Float"]>;
  total_principal_in_requested_state?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "financial_summaries" */
export type FinancialSummariesVarianceOrderBy = {
  accounting_interest_accrued_today?: Maybe<OrderBy>;
  accounting_late_fees_accrued_today?: Maybe<OrderBy>;
  accounting_total_outstanding_interest?: Maybe<OrderBy>;
  accounting_total_outstanding_late_fees?: Maybe<OrderBy>;
  accounting_total_outstanding_principal?: Maybe<OrderBy>;
  adjusted_total_limit?: Maybe<OrderBy>;
  available_limit?: Maybe<OrderBy>;
  daily_interest_rate?: Maybe<OrderBy>;
  days_to_compute_back?: Maybe<OrderBy>;
  interest_accrued_today?: Maybe<OrderBy>;
  late_fees_accrued_today?: Maybe<OrderBy>;
  minimum_interest_amount?: Maybe<OrderBy>;
  minimum_interest_remaining?: Maybe<OrderBy>;
  most_overdue_loan_days?: Maybe<OrderBy>;
  total_amount_to_pay_interest_on?: Maybe<OrderBy>;
  total_fees_paid_adjustment_today?: Maybe<OrderBy>;
  total_interest_paid_adjustment_today?: Maybe<OrderBy>;
  total_limit?: Maybe<OrderBy>;
  total_outstanding_fees?: Maybe<OrderBy>;
  total_outstanding_interest?: Maybe<OrderBy>;
  total_outstanding_principal?: Maybe<OrderBy>;
  total_outstanding_principal_for_interest?: Maybe<OrderBy>;
  total_outstanding_principal_past_due?: Maybe<OrderBy>;
  total_principal_in_requested_state?: Maybe<OrderBy>;
};

/** columns and relationships of "invoice_file_type" */
export type InvoiceFileType = {
  display_name: Scalars["String"];
  value: Scalars["String"];
};

/** aggregated selection of "invoice_file_type" */
export type InvoiceFileTypeAggregate = {
  aggregate?: Maybe<InvoiceFileTypeAggregateFields>;
  nodes: Array<InvoiceFileType>;
};

/** aggregate fields of "invoice_file_type" */
export type InvoiceFileTypeAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<InvoiceFileTypeMaxFields>;
  min?: Maybe<InvoiceFileTypeMinFields>;
};

/** aggregate fields of "invoice_file_type" */
export type InvoiceFileTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<InvoiceFileTypeSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "invoice_file_type". All fields are combined with a logical 'AND'. */
export type InvoiceFileTypeBoolExp = {
  _and?: Maybe<Array<InvoiceFileTypeBoolExp>>;
  _not?: Maybe<InvoiceFileTypeBoolExp>;
  _or?: Maybe<Array<InvoiceFileTypeBoolExp>>;
  display_name?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "invoice_file_type" */
export enum InvoiceFileTypeConstraint {
  /** unique or primary key constraint */
  InvoiceFileTypePkey = "invoice_file_type_pkey",
}

export enum InvoiceFileTypeEnum {
  /** Cannabis */
  Cannabis = "cannabis",
  /** Invoice */
  Invoice = "invoice",
}

/** Boolean expression to compare columns of type "invoice_file_type_enum". All fields are combined with logical 'AND'. */
export type InvoiceFileTypeEnumComparisonExp = {
  _eq?: Maybe<InvoiceFileTypeEnum>;
  _in?: Maybe<Array<InvoiceFileTypeEnum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<InvoiceFileTypeEnum>;
  _nin?: Maybe<Array<InvoiceFileTypeEnum>>;
};

/** input type for inserting data into table "invoice_file_type" */
export type InvoiceFileTypeInsertInput = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type InvoiceFileTypeMaxFields = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type InvoiceFileTypeMinFields = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "invoice_file_type" */
export type InvoiceFileTypeMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<InvoiceFileType>;
};

/** on_conflict condition type for table "invoice_file_type" */
export type InvoiceFileTypeOnConflict = {
  constraint: InvoiceFileTypeConstraint;
  update_columns?: Array<InvoiceFileTypeUpdateColumn>;
  where?: Maybe<InvoiceFileTypeBoolExp>;
};

/** Ordering options when selecting data from "invoice_file_type". */
export type InvoiceFileTypeOrderBy = {
  display_name?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: invoice_file_type */
export type InvoiceFileTypePkColumnsInput = {
  value: Scalars["String"];
};

/** select columns of table "invoice_file_type" */
export enum InvoiceFileTypeSelectColumn {
  /** column name */
  DisplayName = "display_name",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "invoice_file_type" */
export type InvoiceFileTypeSetInput = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** update columns of table "invoice_file_type" */
export enum InvoiceFileTypeUpdateColumn {
  /** column name */
  DisplayName = "display_name",
  /** column name */
  Value = "value",
}

/** columns and relationships of "invoice_files" */
export type InvoiceFiles = {
  /** An object relationship */
  file: Files;
  file_id: Scalars["uuid"];
  file_type: InvoiceFileTypeEnum;
  /** An object relationship */
  invoice: Invoices;
  invoice_id: Scalars["uuid"];
};

/** aggregated selection of "invoice_files" */
export type InvoiceFilesAggregate = {
  aggregate?: Maybe<InvoiceFilesAggregateFields>;
  nodes: Array<InvoiceFiles>;
};

/** aggregate fields of "invoice_files" */
export type InvoiceFilesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<InvoiceFilesMaxFields>;
  min?: Maybe<InvoiceFilesMinFields>;
};

/** aggregate fields of "invoice_files" */
export type InvoiceFilesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<InvoiceFilesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "invoice_files" */
export type InvoiceFilesAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<InvoiceFilesMaxOrderBy>;
  min?: Maybe<InvoiceFilesMinOrderBy>;
};

/** input type for inserting array relation for remote table "invoice_files" */
export type InvoiceFilesArrRelInsertInput = {
  data: Array<InvoiceFilesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<InvoiceFilesOnConflict>;
};

/** Boolean expression to filter rows from the table "invoice_files". All fields are combined with a logical 'AND'. */
export type InvoiceFilesBoolExp = {
  _and?: Maybe<Array<InvoiceFilesBoolExp>>;
  _not?: Maybe<InvoiceFilesBoolExp>;
  _or?: Maybe<Array<InvoiceFilesBoolExp>>;
  file?: Maybe<FilesBoolExp>;
  file_id?: Maybe<UuidComparisonExp>;
  file_type?: Maybe<InvoiceFileTypeEnumComparisonExp>;
  invoice?: Maybe<InvoicesBoolExp>;
  invoice_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "invoice_files" */
export enum InvoiceFilesConstraint {
  /** unique or primary key constraint */
  InvoiceFilesPkey = "invoice_files_pkey",
}

/** input type for inserting data into table "invoice_files" */
export type InvoiceFilesInsertInput = {
  file?: Maybe<FilesObjRelInsertInput>;
  file_id?: Maybe<Scalars["uuid"]>;
  file_type?: Maybe<InvoiceFileTypeEnum>;
  invoice?: Maybe<InvoicesObjRelInsertInput>;
  invoice_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type InvoiceFilesMaxFields = {
  file_id?: Maybe<Scalars["uuid"]>;
  invoice_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "invoice_files" */
export type InvoiceFilesMaxOrderBy = {
  file_id?: Maybe<OrderBy>;
  invoice_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type InvoiceFilesMinFields = {
  file_id?: Maybe<Scalars["uuid"]>;
  invoice_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "invoice_files" */
export type InvoiceFilesMinOrderBy = {
  file_id?: Maybe<OrderBy>;
  invoice_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "invoice_files" */
export type InvoiceFilesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<InvoiceFiles>;
};

/** on_conflict condition type for table "invoice_files" */
export type InvoiceFilesOnConflict = {
  constraint: InvoiceFilesConstraint;
  update_columns?: Array<InvoiceFilesUpdateColumn>;
  where?: Maybe<InvoiceFilesBoolExp>;
};

/** Ordering options when selecting data from "invoice_files". */
export type InvoiceFilesOrderBy = {
  file?: Maybe<FilesOrderBy>;
  file_id?: Maybe<OrderBy>;
  file_type?: Maybe<OrderBy>;
  invoice?: Maybe<InvoicesOrderBy>;
  invoice_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: invoice_files */
export type InvoiceFilesPkColumnsInput = {
  file_id: Scalars["uuid"];
  invoice_id: Scalars["uuid"];
};

/** select columns of table "invoice_files" */
export enum InvoiceFilesSelectColumn {
  /** column name */
  FileId = "file_id",
  /** column name */
  FileType = "file_type",
  /** column name */
  InvoiceId = "invoice_id",
}

/** input type for updating data in table "invoice_files" */
export type InvoiceFilesSetInput = {
  file_id?: Maybe<Scalars["uuid"]>;
  file_type?: Maybe<InvoiceFileTypeEnum>;
  invoice_id?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "invoice_files" */
export enum InvoiceFilesUpdateColumn {
  /** column name */
  FileId = "file_id",
  /** column name */
  FileType = "file_type",
  /** column name */
  InvoiceId = "invoice_id",
}

/** Maintains the collection of company invoices used for both Invoice Financing and PMF  */
export type Invoices = {
  advance_date?: Maybe<Scalars["date"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  company: Companies;
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  funded_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  invoice_date?: Maybe<Scalars["date"]>;
  invoice_due_date?: Maybe<Scalars["date"]>;
  /** An array relationship */
  invoice_files: Array<InvoiceFiles>;
  /** An aggregate relationship */
  invoice_files_aggregate: InvoiceFilesAggregate;
  invoice_number: Scalars["String"];
  /** This field is used for the Purchase Money Financing product type but NOT for the Invoice Financing product type */
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  /** An array relationship */
  loans: Array<Loans>;
  /** An aggregate relationship */
  loans_aggregate: LoansAggregate;
  /** An object relationship */
  payment?: Maybe<Payments>;
  payment_confirmed_at?: Maybe<Scalars["timestamptz"]>;
  payment_id?: Maybe<Scalars["uuid"]>;
  payment_rejected_at?: Maybe<Scalars["timestamptz"]>;
  payment_rejection_note?: Maybe<Scalars["String"]>;
  payment_requested_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  payor?: Maybe<Payors>;
  payor_id: Scalars["uuid"];
  rejected_at?: Maybe<Scalars["timestamptz"]>;
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  status: RequestStatusEnum;
  subtotal_amount: Scalars["numeric"];
  taxes_amount: Scalars["numeric"];
  total_amount: Scalars["numeric"];
  updated_at: Scalars["timestamptz"];
};

/** Maintains the collection of company invoices used for both Invoice Financing and PMF  */
export type InvoicesInvoiceFilesArgs = {
  distinct_on?: Maybe<Array<InvoiceFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoiceFilesOrderBy>>;
  where?: Maybe<InvoiceFilesBoolExp>;
};

/** Maintains the collection of company invoices used for both Invoice Financing and PMF  */
export type InvoicesInvoiceFilesAggregateArgs = {
  distinct_on?: Maybe<Array<InvoiceFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoiceFilesOrderBy>>;
  where?: Maybe<InvoiceFilesBoolExp>;
};

/** Maintains the collection of company invoices used for both Invoice Financing and PMF  */
export type InvoicesLoansArgs = {
  distinct_on?: Maybe<Array<LoansSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoansOrderBy>>;
  where?: Maybe<LoansBoolExp>;
};

/** Maintains the collection of company invoices used for both Invoice Financing and PMF  */
export type InvoicesLoansAggregateArgs = {
  distinct_on?: Maybe<Array<LoansSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoansOrderBy>>;
  where?: Maybe<LoansBoolExp>;
};

/** aggregated selection of "invoices" */
export type InvoicesAggregate = {
  aggregate?: Maybe<InvoicesAggregateFields>;
  nodes: Array<Invoices>;
};

/** aggregate fields of "invoices" */
export type InvoicesAggregateFields = {
  avg?: Maybe<InvoicesAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<InvoicesMaxFields>;
  min?: Maybe<InvoicesMinFields>;
  stddev?: Maybe<InvoicesStddevFields>;
  stddev_pop?: Maybe<InvoicesStddevPopFields>;
  stddev_samp?: Maybe<InvoicesStddevSampFields>;
  sum?: Maybe<InvoicesSumFields>;
  var_pop?: Maybe<InvoicesVarPopFields>;
  var_samp?: Maybe<InvoicesVarSampFields>;
  variance?: Maybe<InvoicesVarianceFields>;
};

/** aggregate fields of "invoices" */
export type InvoicesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<InvoicesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "invoices" */
export type InvoicesAggregateOrderBy = {
  avg?: Maybe<InvoicesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<InvoicesMaxOrderBy>;
  min?: Maybe<InvoicesMinOrderBy>;
  stddev?: Maybe<InvoicesStddevOrderBy>;
  stddev_pop?: Maybe<InvoicesStddevPopOrderBy>;
  stddev_samp?: Maybe<InvoicesStddevSampOrderBy>;
  sum?: Maybe<InvoicesSumOrderBy>;
  var_pop?: Maybe<InvoicesVarPopOrderBy>;
  var_samp?: Maybe<InvoicesVarSampOrderBy>;
  variance?: Maybe<InvoicesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "invoices" */
export type InvoicesArrRelInsertInput = {
  data: Array<InvoicesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<InvoicesOnConflict>;
};

/** aggregate avg on columns */
export type InvoicesAvgFields = {
  subtotal_amount?: Maybe<Scalars["Float"]>;
  taxes_amount?: Maybe<Scalars["Float"]>;
  total_amount?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "invoices" */
export type InvoicesAvgOrderBy = {
  subtotal_amount?: Maybe<OrderBy>;
  taxes_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "invoices". All fields are combined with a logical 'AND'. */
export type InvoicesBoolExp = {
  _and?: Maybe<Array<InvoicesBoolExp>>;
  _not?: Maybe<InvoicesBoolExp>;
  _or?: Maybe<Array<InvoicesBoolExp>>;
  advance_date?: Maybe<DateComparisonExp>;
  approved_at?: Maybe<TimestamptzComparisonExp>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  funded_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  invoice_date?: Maybe<DateComparisonExp>;
  invoice_due_date?: Maybe<DateComparisonExp>;
  invoice_files?: Maybe<InvoiceFilesBoolExp>;
  invoice_number?: Maybe<StringComparisonExp>;
  is_cannabis?: Maybe<BooleanComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  loans?: Maybe<LoansBoolExp>;
  payment?: Maybe<PaymentsBoolExp>;
  payment_confirmed_at?: Maybe<TimestamptzComparisonExp>;
  payment_id?: Maybe<UuidComparisonExp>;
  payment_rejected_at?: Maybe<TimestamptzComparisonExp>;
  payment_rejection_note?: Maybe<StringComparisonExp>;
  payment_requested_at?: Maybe<TimestamptzComparisonExp>;
  payor?: Maybe<PayorsBoolExp>;
  payor_id?: Maybe<UuidComparisonExp>;
  rejected_at?: Maybe<TimestamptzComparisonExp>;
  rejection_note?: Maybe<StringComparisonExp>;
  requested_at?: Maybe<TimestamptzComparisonExp>;
  status?: Maybe<RequestStatusEnumComparisonExp>;
  subtotal_amount?: Maybe<NumericComparisonExp>;
  taxes_amount?: Maybe<NumericComparisonExp>;
  total_amount?: Maybe<NumericComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "invoices" */
export enum InvoicesConstraint {
  /** unique or primary key constraint */
  InvoicesPaymentIdKey = "invoices_payment_id_key",
  /** unique or primary key constraint */
  InvoicesPkey = "invoices_pkey",
}

/** input type for incrementing numeric columns in table "invoices" */
export type InvoicesIncInput = {
  subtotal_amount?: Maybe<Scalars["numeric"]>;
  taxes_amount?: Maybe<Scalars["numeric"]>;
  total_amount?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "invoices" */
export type InvoicesInsertInput = {
  advance_date?: Maybe<Scalars["date"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  funded_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  invoice_date?: Maybe<Scalars["date"]>;
  invoice_due_date?: Maybe<Scalars["date"]>;
  invoice_files?: Maybe<InvoiceFilesArrRelInsertInput>;
  invoice_number?: Maybe<Scalars["String"]>;
  /** This field is used for the Purchase Money Financing product type but NOT for the Invoice Financing product type */
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  loans?: Maybe<LoansArrRelInsertInput>;
  payment?: Maybe<PaymentsObjRelInsertInput>;
  payment_confirmed_at?: Maybe<Scalars["timestamptz"]>;
  payment_id?: Maybe<Scalars["uuid"]>;
  payment_rejected_at?: Maybe<Scalars["timestamptz"]>;
  payment_rejection_note?: Maybe<Scalars["String"]>;
  payment_requested_at?: Maybe<Scalars["timestamptz"]>;
  payor?: Maybe<PayorsObjRelInsertInput>;
  payor_id?: Maybe<Scalars["uuid"]>;
  rejected_at?: Maybe<Scalars["timestamptz"]>;
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<RequestStatusEnum>;
  subtotal_amount?: Maybe<Scalars["numeric"]>;
  taxes_amount?: Maybe<Scalars["numeric"]>;
  total_amount?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type InvoicesMaxFields = {
  advance_date?: Maybe<Scalars["date"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  funded_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  invoice_date?: Maybe<Scalars["date"]>;
  invoice_due_date?: Maybe<Scalars["date"]>;
  invoice_number?: Maybe<Scalars["String"]>;
  payment_confirmed_at?: Maybe<Scalars["timestamptz"]>;
  payment_id?: Maybe<Scalars["uuid"]>;
  payment_rejected_at?: Maybe<Scalars["timestamptz"]>;
  payment_rejection_note?: Maybe<Scalars["String"]>;
  payment_requested_at?: Maybe<Scalars["timestamptz"]>;
  payor_id?: Maybe<Scalars["uuid"]>;
  rejected_at?: Maybe<Scalars["timestamptz"]>;
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  subtotal_amount?: Maybe<Scalars["numeric"]>;
  taxes_amount?: Maybe<Scalars["numeric"]>;
  total_amount?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "invoices" */
export type InvoicesMaxOrderBy = {
  advance_date?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  funded_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  invoice_date?: Maybe<OrderBy>;
  invoice_due_date?: Maybe<OrderBy>;
  invoice_number?: Maybe<OrderBy>;
  payment_confirmed_at?: Maybe<OrderBy>;
  payment_id?: Maybe<OrderBy>;
  payment_rejected_at?: Maybe<OrderBy>;
  payment_rejection_note?: Maybe<OrderBy>;
  payment_requested_at?: Maybe<OrderBy>;
  payor_id?: Maybe<OrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejection_note?: Maybe<OrderBy>;
  requested_at?: Maybe<OrderBy>;
  subtotal_amount?: Maybe<OrderBy>;
  taxes_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type InvoicesMinFields = {
  advance_date?: Maybe<Scalars["date"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  funded_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  invoice_date?: Maybe<Scalars["date"]>;
  invoice_due_date?: Maybe<Scalars["date"]>;
  invoice_number?: Maybe<Scalars["String"]>;
  payment_confirmed_at?: Maybe<Scalars["timestamptz"]>;
  payment_id?: Maybe<Scalars["uuid"]>;
  payment_rejected_at?: Maybe<Scalars["timestamptz"]>;
  payment_rejection_note?: Maybe<Scalars["String"]>;
  payment_requested_at?: Maybe<Scalars["timestamptz"]>;
  payor_id?: Maybe<Scalars["uuid"]>;
  rejected_at?: Maybe<Scalars["timestamptz"]>;
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  subtotal_amount?: Maybe<Scalars["numeric"]>;
  taxes_amount?: Maybe<Scalars["numeric"]>;
  total_amount?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "invoices" */
export type InvoicesMinOrderBy = {
  advance_date?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  funded_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  invoice_date?: Maybe<OrderBy>;
  invoice_due_date?: Maybe<OrderBy>;
  invoice_number?: Maybe<OrderBy>;
  payment_confirmed_at?: Maybe<OrderBy>;
  payment_id?: Maybe<OrderBy>;
  payment_rejected_at?: Maybe<OrderBy>;
  payment_rejection_note?: Maybe<OrderBy>;
  payment_requested_at?: Maybe<OrderBy>;
  payor_id?: Maybe<OrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejection_note?: Maybe<OrderBy>;
  requested_at?: Maybe<OrderBy>;
  subtotal_amount?: Maybe<OrderBy>;
  taxes_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "invoices" */
export type InvoicesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Invoices>;
};

/** input type for inserting object relation for remote table "invoices" */
export type InvoicesObjRelInsertInput = {
  data: InvoicesInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<InvoicesOnConflict>;
};

/** on_conflict condition type for table "invoices" */
export type InvoicesOnConflict = {
  constraint: InvoicesConstraint;
  update_columns?: Array<InvoicesUpdateColumn>;
  where?: Maybe<InvoicesBoolExp>;
};

/** Ordering options when selecting data from "invoices". */
export type InvoicesOrderBy = {
  advance_date?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  funded_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  invoice_date?: Maybe<OrderBy>;
  invoice_due_date?: Maybe<OrderBy>;
  invoice_files_aggregate?: Maybe<InvoiceFilesAggregateOrderBy>;
  invoice_number?: Maybe<OrderBy>;
  is_cannabis?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  loans_aggregate?: Maybe<LoansAggregateOrderBy>;
  payment?: Maybe<PaymentsOrderBy>;
  payment_confirmed_at?: Maybe<OrderBy>;
  payment_id?: Maybe<OrderBy>;
  payment_rejected_at?: Maybe<OrderBy>;
  payment_rejection_note?: Maybe<OrderBy>;
  payment_requested_at?: Maybe<OrderBy>;
  payor?: Maybe<PayorsOrderBy>;
  payor_id?: Maybe<OrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejection_note?: Maybe<OrderBy>;
  requested_at?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  subtotal_amount?: Maybe<OrderBy>;
  taxes_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: invoices */
export type InvoicesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "invoices" */
export enum InvoicesSelectColumn {
  /** column name */
  AdvanceDate = "advance_date",
  /** column name */
  ApprovedAt = "approved_at",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FundedAt = "funded_at",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceDate = "invoice_date",
  /** column name */
  InvoiceDueDate = "invoice_due_date",
  /** column name */
  InvoiceNumber = "invoice_number",
  /** column name */
  IsCannabis = "is_cannabis",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  PaymentConfirmedAt = "payment_confirmed_at",
  /** column name */
  PaymentId = "payment_id",
  /** column name */
  PaymentRejectedAt = "payment_rejected_at",
  /** column name */
  PaymentRejectionNote = "payment_rejection_note",
  /** column name */
  PaymentRequestedAt = "payment_requested_at",
  /** column name */
  PayorId = "payor_id",
  /** column name */
  RejectedAt = "rejected_at",
  /** column name */
  RejectionNote = "rejection_note",
  /** column name */
  RequestedAt = "requested_at",
  /** column name */
  Status = "status",
  /** column name */
  SubtotalAmount = "subtotal_amount",
  /** column name */
  TaxesAmount = "taxes_amount",
  /** column name */
  TotalAmount = "total_amount",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "invoices" */
export type InvoicesSetInput = {
  advance_date?: Maybe<Scalars["date"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  funded_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  invoice_date?: Maybe<Scalars["date"]>;
  invoice_due_date?: Maybe<Scalars["date"]>;
  invoice_number?: Maybe<Scalars["String"]>;
  /** This field is used for the Purchase Money Financing product type but NOT for the Invoice Financing product type */
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  payment_confirmed_at?: Maybe<Scalars["timestamptz"]>;
  payment_id?: Maybe<Scalars["uuid"]>;
  payment_rejected_at?: Maybe<Scalars["timestamptz"]>;
  payment_rejection_note?: Maybe<Scalars["String"]>;
  payment_requested_at?: Maybe<Scalars["timestamptz"]>;
  payor_id?: Maybe<Scalars["uuid"]>;
  rejected_at?: Maybe<Scalars["timestamptz"]>;
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<RequestStatusEnum>;
  subtotal_amount?: Maybe<Scalars["numeric"]>;
  taxes_amount?: Maybe<Scalars["numeric"]>;
  total_amount?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type InvoicesStddevFields = {
  subtotal_amount?: Maybe<Scalars["Float"]>;
  taxes_amount?: Maybe<Scalars["Float"]>;
  total_amount?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "invoices" */
export type InvoicesStddevOrderBy = {
  subtotal_amount?: Maybe<OrderBy>;
  taxes_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type InvoicesStddevPopFields = {
  subtotal_amount?: Maybe<Scalars["Float"]>;
  taxes_amount?: Maybe<Scalars["Float"]>;
  total_amount?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "invoices" */
export type InvoicesStddevPopOrderBy = {
  subtotal_amount?: Maybe<OrderBy>;
  taxes_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type InvoicesStddevSampFields = {
  subtotal_amount?: Maybe<Scalars["Float"]>;
  taxes_amount?: Maybe<Scalars["Float"]>;
  total_amount?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "invoices" */
export type InvoicesStddevSampOrderBy = {
  subtotal_amount?: Maybe<OrderBy>;
  taxes_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type InvoicesSumFields = {
  subtotal_amount?: Maybe<Scalars["numeric"]>;
  taxes_amount?: Maybe<Scalars["numeric"]>;
  total_amount?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "invoices" */
export type InvoicesSumOrderBy = {
  subtotal_amount?: Maybe<OrderBy>;
  taxes_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
};

/** update columns of table "invoices" */
export enum InvoicesUpdateColumn {
  /** column name */
  AdvanceDate = "advance_date",
  /** column name */
  ApprovedAt = "approved_at",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FundedAt = "funded_at",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceDate = "invoice_date",
  /** column name */
  InvoiceDueDate = "invoice_due_date",
  /** column name */
  InvoiceNumber = "invoice_number",
  /** column name */
  IsCannabis = "is_cannabis",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  PaymentConfirmedAt = "payment_confirmed_at",
  /** column name */
  PaymentId = "payment_id",
  /** column name */
  PaymentRejectedAt = "payment_rejected_at",
  /** column name */
  PaymentRejectionNote = "payment_rejection_note",
  /** column name */
  PaymentRequestedAt = "payment_requested_at",
  /** column name */
  PayorId = "payor_id",
  /** column name */
  RejectedAt = "rejected_at",
  /** column name */
  RejectionNote = "rejection_note",
  /** column name */
  RequestedAt = "requested_at",
  /** column name */
  Status = "status",
  /** column name */
  SubtotalAmount = "subtotal_amount",
  /** column name */
  TaxesAmount = "taxes_amount",
  /** column name */
  TotalAmount = "total_amount",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type InvoicesVarPopFields = {
  subtotal_amount?: Maybe<Scalars["Float"]>;
  taxes_amount?: Maybe<Scalars["Float"]>;
  total_amount?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "invoices" */
export type InvoicesVarPopOrderBy = {
  subtotal_amount?: Maybe<OrderBy>;
  taxes_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type InvoicesVarSampFields = {
  subtotal_amount?: Maybe<Scalars["Float"]>;
  taxes_amount?: Maybe<Scalars["Float"]>;
  total_amount?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "invoices" */
export type InvoicesVarSampOrderBy = {
  subtotal_amount?: Maybe<OrderBy>;
  taxes_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type InvoicesVarianceFields = {
  subtotal_amount?: Maybe<Scalars["Float"]>;
  taxes_amount?: Maybe<Scalars["Float"]>;
  total_amount?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "invoices" */
export type InvoicesVarianceOrderBy = {
  subtotal_amount?: Maybe<OrderBy>;
  taxes_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type JsonComparisonExp = {
  _eq?: Maybe<Scalars["json"]>;
  _gt?: Maybe<Scalars["json"]>;
  _gte?: Maybe<Scalars["json"]>;
  _in?: Maybe<Array<Scalars["json"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["json"]>;
  _lte?: Maybe<Scalars["json"]>;
  _neq?: Maybe<Scalars["json"]>;
  _nin?: Maybe<Array<Scalars["json"]>>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars["jsonb"]>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars["jsonb"]>;
  _eq?: Maybe<Scalars["jsonb"]>;
  _gt?: Maybe<Scalars["jsonb"]>;
  _gte?: Maybe<Scalars["jsonb"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars["String"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars["String"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars["String"]>>;
  _in?: Maybe<Array<Scalars["jsonb"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["jsonb"]>;
  _lte?: Maybe<Scalars["jsonb"]>;
  _neq?: Maybe<Scalars["jsonb"]>;
  _nin?: Maybe<Array<Scalars["jsonb"]>>;
};

/** columns and relationships of "line_of_credits" */
export type LineOfCredits = {
  /** An object relationship */
  company: Companies;
  company_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** Comment left on line of credit by customer user for bank user */
  customer_note?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  is_credit_for_vendor: Scalars["Boolean"];
  /** An object relationship */
  recipient_vendor?: Maybe<Companies>;
  recipient_vendor_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregated selection of "line_of_credits" */
export type LineOfCreditsAggregate = {
  aggregate?: Maybe<LineOfCreditsAggregateFields>;
  nodes: Array<LineOfCredits>;
};

/** aggregate fields of "line_of_credits" */
export type LineOfCreditsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<LineOfCreditsMaxFields>;
  min?: Maybe<LineOfCreditsMinFields>;
};

/** aggregate fields of "line_of_credits" */
export type LineOfCreditsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LineOfCreditsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "line_of_credits". All fields are combined with a logical 'AND'. */
export type LineOfCreditsBoolExp = {
  _and?: Maybe<Array<LineOfCreditsBoolExp>>;
  _not?: Maybe<LineOfCreditsBoolExp>;
  _or?: Maybe<Array<LineOfCreditsBoolExp>>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  customer_note?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_credit_for_vendor?: Maybe<BooleanComparisonExp>;
  recipient_vendor?: Maybe<CompaniesBoolExp>;
  recipient_vendor_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "line_of_credits" */
export enum LineOfCreditsConstraint {
  /** unique or primary key constraint */
  LineOfCreditsPkey = "line_of_credits_pkey",
}

/** input type for inserting data into table "line_of_credits" */
export type LineOfCreditsInsertInput = {
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** Comment left on line of credit by customer user for bank user */
  customer_note?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_credit_for_vendor?: Maybe<Scalars["Boolean"]>;
  recipient_vendor?: Maybe<CompaniesObjRelInsertInput>;
  recipient_vendor_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type LineOfCreditsMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** Comment left on line of credit by customer user for bank user */
  customer_note?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  recipient_vendor_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type LineOfCreditsMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** Comment left on line of credit by customer user for bank user */
  customer_note?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  recipient_vendor_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "line_of_credits" */
export type LineOfCreditsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<LineOfCredits>;
};

/** input type for inserting object relation for remote table "line_of_credits" */
export type LineOfCreditsObjRelInsertInput = {
  data: LineOfCreditsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<LineOfCreditsOnConflict>;
};

/** on_conflict condition type for table "line_of_credits" */
export type LineOfCreditsOnConflict = {
  constraint: LineOfCreditsConstraint;
  update_columns?: Array<LineOfCreditsUpdateColumn>;
  where?: Maybe<LineOfCreditsBoolExp>;
};

/** Ordering options when selecting data from "line_of_credits". */
export type LineOfCreditsOrderBy = {
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  customer_note?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_credit_for_vendor?: Maybe<OrderBy>;
  recipient_vendor?: Maybe<CompaniesOrderBy>;
  recipient_vendor_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: line_of_credits */
export type LineOfCreditsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "line_of_credits" */
export enum LineOfCreditsSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CustomerNote = "customer_note",
  /** column name */
  Id = "id",
  /** column name */
  IsCreditForVendor = "is_credit_for_vendor",
  /** column name */
  RecipientVendorId = "recipient_vendor_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "line_of_credits" */
export type LineOfCreditsSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** Comment left on line of credit by customer user for bank user */
  customer_note?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_credit_for_vendor?: Maybe<Scalars["Boolean"]>;
  recipient_vendor_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "line_of_credits" */
export enum LineOfCreditsUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CustomerNote = "customer_note",
  /** column name */
  Id = "id",
  /** column name */
  IsCreditForVendor = "is_credit_for_vendor",
  /** column name */
  RecipientVendorId = "recipient_vendor_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** columns and relationships of "loan_reports" */
export type LoanReports = {
  created_at: Scalars["timestamptz"];
  /** An object relationship */
  debt_facility?: Maybe<DebtFacilities>;
  debt_facility_added_date?: Maybe<Scalars["date"]>;
  debt_facility_id?: Maybe<Scalars["uuid"]>;
  debt_facility_status?: Maybe<Scalars["String"]>;
  debt_facility_waiver_date?: Maybe<Scalars["date"]>;
  debt_facility_waiver_expiration_date?: Maybe<Scalars["date"]>;
  financing_day_limit?: Maybe<Scalars["Int"]>;
  financing_period?: Maybe<Scalars["Int"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  loan?: Maybe<Loans>;
  repayment_date?: Maybe<Scalars["date"]>;
  total_fees_paid?: Maybe<Scalars["numeric"]>;
  total_interest_paid?: Maybe<Scalars["numeric"]>;
  total_principal_paid?: Maybe<Scalars["numeric"]>;
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "loan_reports" */
export type LoanReportsAggregate = {
  aggregate?: Maybe<LoanReportsAggregateFields>;
  nodes: Array<LoanReports>;
};

/** aggregate fields of "loan_reports" */
export type LoanReportsAggregateFields = {
  avg?: Maybe<LoanReportsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<LoanReportsMaxFields>;
  min?: Maybe<LoanReportsMinFields>;
  stddev?: Maybe<LoanReportsStddevFields>;
  stddev_pop?: Maybe<LoanReportsStddevPopFields>;
  stddev_samp?: Maybe<LoanReportsStddevSampFields>;
  sum?: Maybe<LoanReportsSumFields>;
  var_pop?: Maybe<LoanReportsVarPopFields>;
  var_samp?: Maybe<LoanReportsVarSampFields>;
  variance?: Maybe<LoanReportsVarianceFields>;
};

/** aggregate fields of "loan_reports" */
export type LoanReportsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanReportsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type LoanReportsAvgFields = {
  financing_day_limit?: Maybe<Scalars["Float"]>;
  financing_period?: Maybe<Scalars["Float"]>;
  total_fees_paid?: Maybe<Scalars["Float"]>;
  total_interest_paid?: Maybe<Scalars["Float"]>;
  total_principal_paid?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "loan_reports". All fields are combined with a logical 'AND'. */
export type LoanReportsBoolExp = {
  _and?: Maybe<Array<LoanReportsBoolExp>>;
  _not?: Maybe<LoanReportsBoolExp>;
  _or?: Maybe<Array<LoanReportsBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  debt_facility?: Maybe<DebtFacilitiesBoolExp>;
  debt_facility_added_date?: Maybe<DateComparisonExp>;
  debt_facility_id?: Maybe<UuidComparisonExp>;
  debt_facility_status?: Maybe<StringComparisonExp>;
  debt_facility_waiver_date?: Maybe<DateComparisonExp>;
  debt_facility_waiver_expiration_date?: Maybe<DateComparisonExp>;
  financing_day_limit?: Maybe<IntComparisonExp>;
  financing_period?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  loan?: Maybe<LoansBoolExp>;
  repayment_date?: Maybe<DateComparisonExp>;
  total_fees_paid?: Maybe<NumericComparisonExp>;
  total_interest_paid?: Maybe<NumericComparisonExp>;
  total_principal_paid?: Maybe<NumericComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "loan_reports" */
export enum LoanReportsConstraint {
  /** unique or primary key constraint */
  LoanReportsPkey = "loan_reports_pkey",
}

/** input type for incrementing numeric columns in table "loan_reports" */
export type LoanReportsIncInput = {
  financing_day_limit?: Maybe<Scalars["Int"]>;
  financing_period?: Maybe<Scalars["Int"]>;
  total_fees_paid?: Maybe<Scalars["numeric"]>;
  total_interest_paid?: Maybe<Scalars["numeric"]>;
  total_principal_paid?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "loan_reports" */
export type LoanReportsInsertInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  debt_facility?: Maybe<DebtFacilitiesObjRelInsertInput>;
  debt_facility_added_date?: Maybe<Scalars["date"]>;
  debt_facility_id?: Maybe<Scalars["uuid"]>;
  debt_facility_status?: Maybe<Scalars["String"]>;
  debt_facility_waiver_date?: Maybe<Scalars["date"]>;
  debt_facility_waiver_expiration_date?: Maybe<Scalars["date"]>;
  financing_day_limit?: Maybe<Scalars["Int"]>;
  financing_period?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  loan?: Maybe<LoansObjRelInsertInput>;
  repayment_date?: Maybe<Scalars["date"]>;
  total_fees_paid?: Maybe<Scalars["numeric"]>;
  total_interest_paid?: Maybe<Scalars["numeric"]>;
  total_principal_paid?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type LoanReportsMaxFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  debt_facility_added_date?: Maybe<Scalars["date"]>;
  debt_facility_id?: Maybe<Scalars["uuid"]>;
  debt_facility_status?: Maybe<Scalars["String"]>;
  debt_facility_waiver_date?: Maybe<Scalars["date"]>;
  debt_facility_waiver_expiration_date?: Maybe<Scalars["date"]>;
  financing_day_limit?: Maybe<Scalars["Int"]>;
  financing_period?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  repayment_date?: Maybe<Scalars["date"]>;
  total_fees_paid?: Maybe<Scalars["numeric"]>;
  total_interest_paid?: Maybe<Scalars["numeric"]>;
  total_principal_paid?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type LoanReportsMinFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  debt_facility_added_date?: Maybe<Scalars["date"]>;
  debt_facility_id?: Maybe<Scalars["uuid"]>;
  debt_facility_status?: Maybe<Scalars["String"]>;
  debt_facility_waiver_date?: Maybe<Scalars["date"]>;
  debt_facility_waiver_expiration_date?: Maybe<Scalars["date"]>;
  financing_day_limit?: Maybe<Scalars["Int"]>;
  financing_period?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  repayment_date?: Maybe<Scalars["date"]>;
  total_fees_paid?: Maybe<Scalars["numeric"]>;
  total_interest_paid?: Maybe<Scalars["numeric"]>;
  total_principal_paid?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "loan_reports" */
export type LoanReportsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<LoanReports>;
};

/** input type for inserting object relation for remote table "loan_reports" */
export type LoanReportsObjRelInsertInput = {
  data: LoanReportsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<LoanReportsOnConflict>;
};

/** on_conflict condition type for table "loan_reports" */
export type LoanReportsOnConflict = {
  constraint: LoanReportsConstraint;
  update_columns?: Array<LoanReportsUpdateColumn>;
  where?: Maybe<LoanReportsBoolExp>;
};

/** Ordering options when selecting data from "loan_reports". */
export type LoanReportsOrderBy = {
  created_at?: Maybe<OrderBy>;
  debt_facility?: Maybe<DebtFacilitiesOrderBy>;
  debt_facility_added_date?: Maybe<OrderBy>;
  debt_facility_id?: Maybe<OrderBy>;
  debt_facility_status?: Maybe<OrderBy>;
  debt_facility_waiver_date?: Maybe<OrderBy>;
  debt_facility_waiver_expiration_date?: Maybe<OrderBy>;
  financing_day_limit?: Maybe<OrderBy>;
  financing_period?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  loan?: Maybe<LoansOrderBy>;
  repayment_date?: Maybe<OrderBy>;
  total_fees_paid?: Maybe<OrderBy>;
  total_interest_paid?: Maybe<OrderBy>;
  total_principal_paid?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_reports */
export type LoanReportsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "loan_reports" */
export enum LoanReportsSelectColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DebtFacilityAddedDate = "debt_facility_added_date",
  /** column name */
  DebtFacilityId = "debt_facility_id",
  /** column name */
  DebtFacilityStatus = "debt_facility_status",
  /** column name */
  DebtFacilityWaiverDate = "debt_facility_waiver_date",
  /** column name */
  DebtFacilityWaiverExpirationDate = "debt_facility_waiver_expiration_date",
  /** column name */
  FinancingDayLimit = "financing_day_limit",
  /** column name */
  FinancingPeriod = "financing_period",
  /** column name */
  Id = "id",
  /** column name */
  RepaymentDate = "repayment_date",
  /** column name */
  TotalFeesPaid = "total_fees_paid",
  /** column name */
  TotalInterestPaid = "total_interest_paid",
  /** column name */
  TotalPrincipalPaid = "total_principal_paid",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "loan_reports" */
export type LoanReportsSetInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  debt_facility_added_date?: Maybe<Scalars["date"]>;
  debt_facility_id?: Maybe<Scalars["uuid"]>;
  debt_facility_status?: Maybe<Scalars["String"]>;
  debt_facility_waiver_date?: Maybe<Scalars["date"]>;
  debt_facility_waiver_expiration_date?: Maybe<Scalars["date"]>;
  financing_day_limit?: Maybe<Scalars["Int"]>;
  financing_period?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  repayment_date?: Maybe<Scalars["date"]>;
  total_fees_paid?: Maybe<Scalars["numeric"]>;
  total_interest_paid?: Maybe<Scalars["numeric"]>;
  total_principal_paid?: Maybe<Scalars["numeric"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type LoanReportsStddevFields = {
  financing_day_limit?: Maybe<Scalars["Float"]>;
  financing_period?: Maybe<Scalars["Float"]>;
  total_fees_paid?: Maybe<Scalars["Float"]>;
  total_interest_paid?: Maybe<Scalars["Float"]>;
  total_principal_paid?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type LoanReportsStddevPopFields = {
  financing_day_limit?: Maybe<Scalars["Float"]>;
  financing_period?: Maybe<Scalars["Float"]>;
  total_fees_paid?: Maybe<Scalars["Float"]>;
  total_interest_paid?: Maybe<Scalars["Float"]>;
  total_principal_paid?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type LoanReportsStddevSampFields = {
  financing_day_limit?: Maybe<Scalars["Float"]>;
  financing_period?: Maybe<Scalars["Float"]>;
  total_fees_paid?: Maybe<Scalars["Float"]>;
  total_interest_paid?: Maybe<Scalars["Float"]>;
  total_principal_paid?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type LoanReportsSumFields = {
  financing_day_limit?: Maybe<Scalars["Int"]>;
  financing_period?: Maybe<Scalars["Int"]>;
  total_fees_paid?: Maybe<Scalars["numeric"]>;
  total_interest_paid?: Maybe<Scalars["numeric"]>;
  total_principal_paid?: Maybe<Scalars["numeric"]>;
};

/** update columns of table "loan_reports" */
export enum LoanReportsUpdateColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DebtFacilityAddedDate = "debt_facility_added_date",
  /** column name */
  DebtFacilityId = "debt_facility_id",
  /** column name */
  DebtFacilityStatus = "debt_facility_status",
  /** column name */
  DebtFacilityWaiverDate = "debt_facility_waiver_date",
  /** column name */
  DebtFacilityWaiverExpirationDate = "debt_facility_waiver_expiration_date",
  /** column name */
  FinancingDayLimit = "financing_day_limit",
  /** column name */
  FinancingPeriod = "financing_period",
  /** column name */
  Id = "id",
  /** column name */
  RepaymentDate = "repayment_date",
  /** column name */
  TotalFeesPaid = "total_fees_paid",
  /** column name */
  TotalInterestPaid = "total_interest_paid",
  /** column name */
  TotalPrincipalPaid = "total_principal_paid",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type LoanReportsVarPopFields = {
  financing_day_limit?: Maybe<Scalars["Float"]>;
  financing_period?: Maybe<Scalars["Float"]>;
  total_fees_paid?: Maybe<Scalars["Float"]>;
  total_interest_paid?: Maybe<Scalars["Float"]>;
  total_principal_paid?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type LoanReportsVarSampFields = {
  financing_day_limit?: Maybe<Scalars["Float"]>;
  financing_period?: Maybe<Scalars["Float"]>;
  total_fees_paid?: Maybe<Scalars["Float"]>;
  total_interest_paid?: Maybe<Scalars["Float"]>;
  total_principal_paid?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type LoanReportsVarianceFields = {
  financing_day_limit?: Maybe<Scalars["Float"]>;
  financing_period?: Maybe<Scalars["Float"]>;
  total_fees_paid?: Maybe<Scalars["Float"]>;
  total_interest_paid?: Maybe<Scalars["Float"]>;
  total_principal_paid?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "loan_type" */
export type LoanType = {
  display_name: Scalars["String"];
  value: Scalars["String"];
};

/** aggregated selection of "loan_type" */
export type LoanTypeAggregate = {
  aggregate?: Maybe<LoanTypeAggregateFields>;
  nodes: Array<LoanType>;
};

/** aggregate fields of "loan_type" */
export type LoanTypeAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<LoanTypeMaxFields>;
  min?: Maybe<LoanTypeMinFields>;
};

/** aggregate fields of "loan_type" */
export type LoanTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanTypeSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "loan_type". All fields are combined with a logical 'AND'. */
export type LoanTypeBoolExp = {
  _and?: Maybe<Array<LoanTypeBoolExp>>;
  _not?: Maybe<LoanTypeBoolExp>;
  _or?: Maybe<Array<LoanTypeBoolExp>>;
  display_name?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan_type" */
export enum LoanTypeConstraint {
  /** unique or primary key constraint */
  LoanTypePkey = "loan_type_pkey",
}

export enum LoanTypeEnum {
  /** Invoice */
  Invoice = "invoice",
  /** Line of Credit */
  LineOfCredit = "line_of_credit",
  /** Purchase Order */
  PurchaseOrder = "purchase_order",
}

/** Boolean expression to compare columns of type "loan_type_enum". All fields are combined with logical 'AND'. */
export type LoanTypeEnumComparisonExp = {
  _eq?: Maybe<LoanTypeEnum>;
  _in?: Maybe<Array<LoanTypeEnum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<LoanTypeEnum>;
  _nin?: Maybe<Array<LoanTypeEnum>>;
};

/** input type for inserting data into table "loan_type" */
export type LoanTypeInsertInput = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type LoanTypeMaxFields = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type LoanTypeMinFields = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "loan_type" */
export type LoanTypeMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<LoanType>;
};

/** on_conflict condition type for table "loan_type" */
export type LoanTypeOnConflict = {
  constraint: LoanTypeConstraint;
  update_columns?: Array<LoanTypeUpdateColumn>;
  where?: Maybe<LoanTypeBoolExp>;
};

/** Ordering options when selecting data from "loan_type". */
export type LoanTypeOrderBy = {
  display_name?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_type */
export type LoanTypePkColumnsInput = {
  value: Scalars["String"];
};

/** select columns of table "loan_type" */
export enum LoanTypeSelectColumn {
  /** column name */
  DisplayName = "display_name",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "loan_type" */
export type LoanTypeSetInput = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** update columns of table "loan_type" */
export enum LoanTypeUpdateColumn {
  /** column name */
  DisplayName = "display_name",
  /** column name */
  Value = "value",
}

/** All common fields amongst loans go here, and fields specific to that loan type are joined in by the artifact_id */
export type Loans = {
  adjusted_maturity_date?: Maybe<Scalars["date"]>;
  amount: Scalars["numeric"];
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  artifact_id?: Maybe<Scalars["uuid"]>;
  closed_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  company: Companies;
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  customer_notes?: Maybe<Scalars["String"]>;
  disbursement_identifier?: Maybe<Scalars["String"]>;
  funded_at?: Maybe<Scalars["timestamptz"]>;
  funded_by_user_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["uuid"];
  identifier: Scalars["String"];
  /** An object relationship */
  invoice?: Maybe<Invoices>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  /** If frozen, loan financials are never updated; this is for loans imported from Bespoke Financial's legacy system in which loan financials were calculated based on rules no longer supported */
  is_frozen?: Maybe<Scalars["Boolean"]>;
  /** An object relationship */
  line_of_credit?: Maybe<LineOfCredits>;
  /** An object relationship */
  loan_report?: Maybe<LoanReports>;
  loan_report_id?: Maybe<Scalars["uuid"]>;
  loan_type?: Maybe<LoanTypeEnum>;
  maturity_date?: Maybe<Scalars["date"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by_user_id?: Maybe<Scalars["uuid"]>;
  notes?: Maybe<Scalars["String"]>;
  /** This is the settlement date of the advance that is used to pay out this loan */
  origination_date?: Maybe<Scalars["date"]>;
  outstanding_fees?: Maybe<Scalars["numeric"]>;
  outstanding_interest?: Maybe<Scalars["numeric"]>;
  outstanding_principal_balance?: Maybe<Scalars["numeric"]>;
  /** The state of a payment which may be relevant to this loan */
  payment_status?: Maybe<Scalars["String"]>;
  /** An object relationship */
  purchase_order?: Maybe<PurchaseOrders>;
  rejected_at?: Maybe<Scalars["timestamptz"]>;
  rejected_by_user_id?: Maybe<Scalars["uuid"]>;
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  requested_by_user?: Maybe<Users>;
  requested_by_user_id?: Maybe<Scalars["uuid"]>;
  /** The date the customer requests the loan to arrive to the recipient bank account (a better name for this column is requested_deposit_date) */
  requested_payment_date?: Maybe<Scalars["date"]>;
  /** This is the loan request status, e.g., drafted, approved, more_details_required, rejected */
  status: Scalars["String"];
  /** An array relationship */
  transactions: Array<Transactions>;
  /** An aggregate relationship */
  transactions_aggregate: TransactionsAggregate;
  updated_at: Scalars["timestamptz"];
};

/** All common fields amongst loans go here, and fields specific to that loan type are joined in by the artifact_id */
export type LoansTransactionsArgs = {
  distinct_on?: Maybe<Array<TransactionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TransactionsOrderBy>>;
  where?: Maybe<TransactionsBoolExp>;
};

/** All common fields amongst loans go here, and fields specific to that loan type are joined in by the artifact_id */
export type LoansTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<TransactionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TransactionsOrderBy>>;
  where?: Maybe<TransactionsBoolExp>;
};

/** aggregated selection of "loans" */
export type LoansAggregate = {
  aggregate?: Maybe<LoansAggregateFields>;
  nodes: Array<Loans>;
};

/** aggregate fields of "loans" */
export type LoansAggregateFields = {
  avg?: Maybe<LoansAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<LoansMaxFields>;
  min?: Maybe<LoansMinFields>;
  stddev?: Maybe<LoansStddevFields>;
  stddev_pop?: Maybe<LoansStddevPopFields>;
  stddev_samp?: Maybe<LoansStddevSampFields>;
  sum?: Maybe<LoansSumFields>;
  var_pop?: Maybe<LoansVarPopFields>;
  var_samp?: Maybe<LoansVarSampFields>;
  variance?: Maybe<LoansVarianceFields>;
};

/** aggregate fields of "loans" */
export type LoansAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoansSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "loans" */
export type LoansAggregateOrderBy = {
  avg?: Maybe<LoansAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoansMaxOrderBy>;
  min?: Maybe<LoansMinOrderBy>;
  stddev?: Maybe<LoansStddevOrderBy>;
  stddev_pop?: Maybe<LoansStddevPopOrderBy>;
  stddev_samp?: Maybe<LoansStddevSampOrderBy>;
  sum?: Maybe<LoansSumOrderBy>;
  var_pop?: Maybe<LoansVarPopOrderBy>;
  var_samp?: Maybe<LoansVarSampOrderBy>;
  variance?: Maybe<LoansVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loans" */
export type LoansArrRelInsertInput = {
  data: Array<LoansInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<LoansOnConflict>;
};

/** aggregate avg on columns */
export type LoansAvgFields = {
  amount?: Maybe<Scalars["Float"]>;
  outstanding_fees?: Maybe<Scalars["Float"]>;
  outstanding_interest?: Maybe<Scalars["Float"]>;
  outstanding_principal_balance?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "loans" */
export type LoansAvgOrderBy = {
  amount?: Maybe<OrderBy>;
  outstanding_fees?: Maybe<OrderBy>;
  outstanding_interest?: Maybe<OrderBy>;
  outstanding_principal_balance?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loans". All fields are combined with a logical 'AND'. */
export type LoansBoolExp = {
  _and?: Maybe<Array<LoansBoolExp>>;
  _not?: Maybe<LoansBoolExp>;
  _or?: Maybe<Array<LoansBoolExp>>;
  adjusted_maturity_date?: Maybe<DateComparisonExp>;
  amount?: Maybe<NumericComparisonExp>;
  approved_at?: Maybe<TimestamptzComparisonExp>;
  approved_by_user_id?: Maybe<UuidComparisonExp>;
  artifact_id?: Maybe<UuidComparisonExp>;
  closed_at?: Maybe<TimestamptzComparisonExp>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  customer_notes?: Maybe<StringComparisonExp>;
  disbursement_identifier?: Maybe<StringComparisonExp>;
  funded_at?: Maybe<TimestamptzComparisonExp>;
  funded_by_user_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  identifier?: Maybe<StringComparisonExp>;
  invoice?: Maybe<InvoicesBoolExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  is_frozen?: Maybe<BooleanComparisonExp>;
  line_of_credit?: Maybe<LineOfCreditsBoolExp>;
  loan_report?: Maybe<LoanReportsBoolExp>;
  loan_report_id?: Maybe<UuidComparisonExp>;
  loan_type?: Maybe<LoanTypeEnumComparisonExp>;
  maturity_date?: Maybe<DateComparisonExp>;
  modified_at?: Maybe<TimestamptzComparisonExp>;
  modified_by_user_id?: Maybe<UuidComparisonExp>;
  notes?: Maybe<StringComparisonExp>;
  origination_date?: Maybe<DateComparisonExp>;
  outstanding_fees?: Maybe<NumericComparisonExp>;
  outstanding_interest?: Maybe<NumericComparisonExp>;
  outstanding_principal_balance?: Maybe<NumericComparisonExp>;
  payment_status?: Maybe<StringComparisonExp>;
  purchase_order?: Maybe<PurchaseOrdersBoolExp>;
  rejected_at?: Maybe<TimestamptzComparisonExp>;
  rejected_by_user_id?: Maybe<UuidComparisonExp>;
  rejection_note?: Maybe<StringComparisonExp>;
  requested_at?: Maybe<TimestamptzComparisonExp>;
  requested_by_user?: Maybe<UsersBoolExp>;
  requested_by_user_id?: Maybe<UuidComparisonExp>;
  requested_payment_date?: Maybe<DateComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  transactions?: Maybe<TransactionsBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "loans" */
export enum LoansConstraint {
  /** unique or primary key constraint */
  LoansCompanyIdIdentifierKey = "loans_company_id_identifier_key",
  /** unique or primary key constraint */
  LoansLoanReportIdKey = "loans_loan_report_id_key",
  /** unique or primary key constraint */
  LoansPkey = "loans_pkey",
}

/** input type for incrementing numeric columns in table "loans" */
export type LoansIncInput = {
  amount?: Maybe<Scalars["numeric"]>;
  outstanding_fees?: Maybe<Scalars["numeric"]>;
  outstanding_interest?: Maybe<Scalars["numeric"]>;
  outstanding_principal_balance?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "loans" */
export type LoansInsertInput = {
  adjusted_maturity_date?: Maybe<Scalars["date"]>;
  amount?: Maybe<Scalars["numeric"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  artifact_id?: Maybe<Scalars["uuid"]>;
  closed_at?: Maybe<Scalars["timestamptz"]>;
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  customer_notes?: Maybe<Scalars["String"]>;
  disbursement_identifier?: Maybe<Scalars["String"]>;
  funded_at?: Maybe<Scalars["timestamptz"]>;
  funded_by_user_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  invoice?: Maybe<InvoicesObjRelInsertInput>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  /** If frozen, loan financials are never updated; this is for loans imported from Bespoke Financial's legacy system in which loan financials were calculated based on rules no longer supported */
  is_frozen?: Maybe<Scalars["Boolean"]>;
  line_of_credit?: Maybe<LineOfCreditsObjRelInsertInput>;
  loan_report?: Maybe<LoanReportsObjRelInsertInput>;
  loan_report_id?: Maybe<Scalars["uuid"]>;
  loan_type?: Maybe<LoanTypeEnum>;
  maturity_date?: Maybe<Scalars["date"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by_user_id?: Maybe<Scalars["uuid"]>;
  notes?: Maybe<Scalars["String"]>;
  /** This is the settlement date of the advance that is used to pay out this loan */
  origination_date?: Maybe<Scalars["date"]>;
  outstanding_fees?: Maybe<Scalars["numeric"]>;
  outstanding_interest?: Maybe<Scalars["numeric"]>;
  outstanding_principal_balance?: Maybe<Scalars["numeric"]>;
  /** The state of a payment which may be relevant to this loan */
  payment_status?: Maybe<Scalars["String"]>;
  purchase_order?: Maybe<PurchaseOrdersObjRelInsertInput>;
  rejected_at?: Maybe<Scalars["timestamptz"]>;
  rejected_by_user_id?: Maybe<Scalars["uuid"]>;
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  requested_by_user?: Maybe<UsersObjRelInsertInput>;
  requested_by_user_id?: Maybe<Scalars["uuid"]>;
  /** The date the customer requests the loan to arrive to the recipient bank account (a better name for this column is requested_deposit_date) */
  requested_payment_date?: Maybe<Scalars["date"]>;
  /** This is the loan request status, e.g., drafted, approved, more_details_required, rejected */
  status?: Maybe<Scalars["String"]>;
  transactions?: Maybe<TransactionsArrRelInsertInput>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type LoansMaxFields = {
  adjusted_maturity_date?: Maybe<Scalars["date"]>;
  amount?: Maybe<Scalars["numeric"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  artifact_id?: Maybe<Scalars["uuid"]>;
  closed_at?: Maybe<Scalars["timestamptz"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  customer_notes?: Maybe<Scalars["String"]>;
  disbursement_identifier?: Maybe<Scalars["String"]>;
  funded_at?: Maybe<Scalars["timestamptz"]>;
  funded_by_user_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  loan_report_id?: Maybe<Scalars["uuid"]>;
  maturity_date?: Maybe<Scalars["date"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by_user_id?: Maybe<Scalars["uuid"]>;
  notes?: Maybe<Scalars["String"]>;
  /** This is the settlement date of the advance that is used to pay out this loan */
  origination_date?: Maybe<Scalars["date"]>;
  outstanding_fees?: Maybe<Scalars["numeric"]>;
  outstanding_interest?: Maybe<Scalars["numeric"]>;
  outstanding_principal_balance?: Maybe<Scalars["numeric"]>;
  /** The state of a payment which may be relevant to this loan */
  payment_status?: Maybe<Scalars["String"]>;
  rejected_at?: Maybe<Scalars["timestamptz"]>;
  rejected_by_user_id?: Maybe<Scalars["uuid"]>;
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  requested_by_user_id?: Maybe<Scalars["uuid"]>;
  /** The date the customer requests the loan to arrive to the recipient bank account (a better name for this column is requested_deposit_date) */
  requested_payment_date?: Maybe<Scalars["date"]>;
  /** This is the loan request status, e.g., drafted, approved, more_details_required, rejected */
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "loans" */
export type LoansMaxOrderBy = {
  adjusted_maturity_date?: Maybe<OrderBy>;
  amount?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by_user_id?: Maybe<OrderBy>;
  artifact_id?: Maybe<OrderBy>;
  closed_at?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  customer_notes?: Maybe<OrderBy>;
  disbursement_identifier?: Maybe<OrderBy>;
  funded_at?: Maybe<OrderBy>;
  funded_by_user_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  identifier?: Maybe<OrderBy>;
  loan_report_id?: Maybe<OrderBy>;
  maturity_date?: Maybe<OrderBy>;
  modified_at?: Maybe<OrderBy>;
  modified_by_user_id?: Maybe<OrderBy>;
  notes?: Maybe<OrderBy>;
  /** This is the settlement date of the advance that is used to pay out this loan */
  origination_date?: Maybe<OrderBy>;
  outstanding_fees?: Maybe<OrderBy>;
  outstanding_interest?: Maybe<OrderBy>;
  outstanding_principal_balance?: Maybe<OrderBy>;
  /** The state of a payment which may be relevant to this loan */
  payment_status?: Maybe<OrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejected_by_user_id?: Maybe<OrderBy>;
  rejection_note?: Maybe<OrderBy>;
  requested_at?: Maybe<OrderBy>;
  requested_by_user_id?: Maybe<OrderBy>;
  /** The date the customer requests the loan to arrive to the recipient bank account (a better name for this column is requested_deposit_date) */
  requested_payment_date?: Maybe<OrderBy>;
  /** This is the loan request status, e.g., drafted, approved, more_details_required, rejected */
  status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoansMinFields = {
  adjusted_maturity_date?: Maybe<Scalars["date"]>;
  amount?: Maybe<Scalars["numeric"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  artifact_id?: Maybe<Scalars["uuid"]>;
  closed_at?: Maybe<Scalars["timestamptz"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  customer_notes?: Maybe<Scalars["String"]>;
  disbursement_identifier?: Maybe<Scalars["String"]>;
  funded_at?: Maybe<Scalars["timestamptz"]>;
  funded_by_user_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  loan_report_id?: Maybe<Scalars["uuid"]>;
  maturity_date?: Maybe<Scalars["date"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by_user_id?: Maybe<Scalars["uuid"]>;
  notes?: Maybe<Scalars["String"]>;
  /** This is the settlement date of the advance that is used to pay out this loan */
  origination_date?: Maybe<Scalars["date"]>;
  outstanding_fees?: Maybe<Scalars["numeric"]>;
  outstanding_interest?: Maybe<Scalars["numeric"]>;
  outstanding_principal_balance?: Maybe<Scalars["numeric"]>;
  /** The state of a payment which may be relevant to this loan */
  payment_status?: Maybe<Scalars["String"]>;
  rejected_at?: Maybe<Scalars["timestamptz"]>;
  rejected_by_user_id?: Maybe<Scalars["uuid"]>;
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  requested_by_user_id?: Maybe<Scalars["uuid"]>;
  /** The date the customer requests the loan to arrive to the recipient bank account (a better name for this column is requested_deposit_date) */
  requested_payment_date?: Maybe<Scalars["date"]>;
  /** This is the loan request status, e.g., drafted, approved, more_details_required, rejected */
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "loans" */
export type LoansMinOrderBy = {
  adjusted_maturity_date?: Maybe<OrderBy>;
  amount?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by_user_id?: Maybe<OrderBy>;
  artifact_id?: Maybe<OrderBy>;
  closed_at?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  customer_notes?: Maybe<OrderBy>;
  disbursement_identifier?: Maybe<OrderBy>;
  funded_at?: Maybe<OrderBy>;
  funded_by_user_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  identifier?: Maybe<OrderBy>;
  loan_report_id?: Maybe<OrderBy>;
  maturity_date?: Maybe<OrderBy>;
  modified_at?: Maybe<OrderBy>;
  modified_by_user_id?: Maybe<OrderBy>;
  notes?: Maybe<OrderBy>;
  /** This is the settlement date of the advance that is used to pay out this loan */
  origination_date?: Maybe<OrderBy>;
  outstanding_fees?: Maybe<OrderBy>;
  outstanding_interest?: Maybe<OrderBy>;
  outstanding_principal_balance?: Maybe<OrderBy>;
  /** The state of a payment which may be relevant to this loan */
  payment_status?: Maybe<OrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejected_by_user_id?: Maybe<OrderBy>;
  rejection_note?: Maybe<OrderBy>;
  requested_at?: Maybe<OrderBy>;
  requested_by_user_id?: Maybe<OrderBy>;
  /** The date the customer requests the loan to arrive to the recipient bank account (a better name for this column is requested_deposit_date) */
  requested_payment_date?: Maybe<OrderBy>;
  /** This is the loan request status, e.g., drafted, approved, more_details_required, rejected */
  status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loans" */
export type LoansMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Loans>;
};

/** input type for inserting object relation for remote table "loans" */
export type LoansObjRelInsertInput = {
  data: LoansInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<LoansOnConflict>;
};

/** on_conflict condition type for table "loans" */
export type LoansOnConflict = {
  constraint: LoansConstraint;
  update_columns?: Array<LoansUpdateColumn>;
  where?: Maybe<LoansBoolExp>;
};

/** Ordering options when selecting data from "loans". */
export type LoansOrderBy = {
  adjusted_maturity_date?: Maybe<OrderBy>;
  amount?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by_user_id?: Maybe<OrderBy>;
  artifact_id?: Maybe<OrderBy>;
  closed_at?: Maybe<OrderBy>;
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  customer_notes?: Maybe<OrderBy>;
  disbursement_identifier?: Maybe<OrderBy>;
  funded_at?: Maybe<OrderBy>;
  funded_by_user_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  identifier?: Maybe<OrderBy>;
  invoice?: Maybe<InvoicesOrderBy>;
  is_deleted?: Maybe<OrderBy>;
  is_frozen?: Maybe<OrderBy>;
  line_of_credit?: Maybe<LineOfCreditsOrderBy>;
  loan_report?: Maybe<LoanReportsOrderBy>;
  loan_report_id?: Maybe<OrderBy>;
  loan_type?: Maybe<OrderBy>;
  maturity_date?: Maybe<OrderBy>;
  modified_at?: Maybe<OrderBy>;
  modified_by_user_id?: Maybe<OrderBy>;
  notes?: Maybe<OrderBy>;
  origination_date?: Maybe<OrderBy>;
  outstanding_fees?: Maybe<OrderBy>;
  outstanding_interest?: Maybe<OrderBy>;
  outstanding_principal_balance?: Maybe<OrderBy>;
  payment_status?: Maybe<OrderBy>;
  purchase_order?: Maybe<PurchaseOrdersOrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejected_by_user_id?: Maybe<OrderBy>;
  rejection_note?: Maybe<OrderBy>;
  requested_at?: Maybe<OrderBy>;
  requested_by_user?: Maybe<UsersOrderBy>;
  requested_by_user_id?: Maybe<OrderBy>;
  requested_payment_date?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  transactions_aggregate?: Maybe<TransactionsAggregateOrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: loans */
export type LoansPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "loans" */
export enum LoansSelectColumn {
  /** column name */
  AdjustedMaturityDate = "adjusted_maturity_date",
  /** column name */
  Amount = "amount",
  /** column name */
  ApprovedAt = "approved_at",
  /** column name */
  ApprovedByUserId = "approved_by_user_id",
  /** column name */
  ArtifactId = "artifact_id",
  /** column name */
  ClosedAt = "closed_at",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CustomerNotes = "customer_notes",
  /** column name */
  DisbursementIdentifier = "disbursement_identifier",
  /** column name */
  FundedAt = "funded_at",
  /** column name */
  FundedByUserId = "funded_by_user_id",
  /** column name */
  Id = "id",
  /** column name */
  Identifier = "identifier",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  IsFrozen = "is_frozen",
  /** column name */
  LoanReportId = "loan_report_id",
  /** column name */
  LoanType = "loan_type",
  /** column name */
  MaturityDate = "maturity_date",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  ModifiedByUserId = "modified_by_user_id",
  /** column name */
  Notes = "notes",
  /** column name */
  OriginationDate = "origination_date",
  /** column name */
  OutstandingFees = "outstanding_fees",
  /** column name */
  OutstandingInterest = "outstanding_interest",
  /** column name */
  OutstandingPrincipalBalance = "outstanding_principal_balance",
  /** column name */
  PaymentStatus = "payment_status",
  /** column name */
  RejectedAt = "rejected_at",
  /** column name */
  RejectedByUserId = "rejected_by_user_id",
  /** column name */
  RejectionNote = "rejection_note",
  /** column name */
  RequestedAt = "requested_at",
  /** column name */
  RequestedByUserId = "requested_by_user_id",
  /** column name */
  RequestedPaymentDate = "requested_payment_date",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "loans" */
export type LoansSetInput = {
  adjusted_maturity_date?: Maybe<Scalars["date"]>;
  amount?: Maybe<Scalars["numeric"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  artifact_id?: Maybe<Scalars["uuid"]>;
  closed_at?: Maybe<Scalars["timestamptz"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  customer_notes?: Maybe<Scalars["String"]>;
  disbursement_identifier?: Maybe<Scalars["String"]>;
  funded_at?: Maybe<Scalars["timestamptz"]>;
  funded_by_user_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  /** If frozen, loan financials are never updated; this is for loans imported from Bespoke Financial's legacy system in which loan financials were calculated based on rules no longer supported */
  is_frozen?: Maybe<Scalars["Boolean"]>;
  loan_report_id?: Maybe<Scalars["uuid"]>;
  loan_type?: Maybe<LoanTypeEnum>;
  maturity_date?: Maybe<Scalars["date"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by_user_id?: Maybe<Scalars["uuid"]>;
  notes?: Maybe<Scalars["String"]>;
  /** This is the settlement date of the advance that is used to pay out this loan */
  origination_date?: Maybe<Scalars["date"]>;
  outstanding_fees?: Maybe<Scalars["numeric"]>;
  outstanding_interest?: Maybe<Scalars["numeric"]>;
  outstanding_principal_balance?: Maybe<Scalars["numeric"]>;
  /** The state of a payment which may be relevant to this loan */
  payment_status?: Maybe<Scalars["String"]>;
  rejected_at?: Maybe<Scalars["timestamptz"]>;
  rejected_by_user_id?: Maybe<Scalars["uuid"]>;
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  requested_by_user_id?: Maybe<Scalars["uuid"]>;
  /** The date the customer requests the loan to arrive to the recipient bank account (a better name for this column is requested_deposit_date) */
  requested_payment_date?: Maybe<Scalars["date"]>;
  /** This is the loan request status, e.g., drafted, approved, more_details_required, rejected */
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type LoansStddevFields = {
  amount?: Maybe<Scalars["Float"]>;
  outstanding_fees?: Maybe<Scalars["Float"]>;
  outstanding_interest?: Maybe<Scalars["Float"]>;
  outstanding_principal_balance?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "loans" */
export type LoansStddevOrderBy = {
  amount?: Maybe<OrderBy>;
  outstanding_fees?: Maybe<OrderBy>;
  outstanding_interest?: Maybe<OrderBy>;
  outstanding_principal_balance?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoansStddevPopFields = {
  amount?: Maybe<Scalars["Float"]>;
  outstanding_fees?: Maybe<Scalars["Float"]>;
  outstanding_interest?: Maybe<Scalars["Float"]>;
  outstanding_principal_balance?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "loans" */
export type LoansStddevPopOrderBy = {
  amount?: Maybe<OrderBy>;
  outstanding_fees?: Maybe<OrderBy>;
  outstanding_interest?: Maybe<OrderBy>;
  outstanding_principal_balance?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoansStddevSampFields = {
  amount?: Maybe<Scalars["Float"]>;
  outstanding_fees?: Maybe<Scalars["Float"]>;
  outstanding_interest?: Maybe<Scalars["Float"]>;
  outstanding_principal_balance?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "loans" */
export type LoansStddevSampOrderBy = {
  amount?: Maybe<OrderBy>;
  outstanding_fees?: Maybe<OrderBy>;
  outstanding_interest?: Maybe<OrderBy>;
  outstanding_principal_balance?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoansSumFields = {
  amount?: Maybe<Scalars["numeric"]>;
  outstanding_fees?: Maybe<Scalars["numeric"]>;
  outstanding_interest?: Maybe<Scalars["numeric"]>;
  outstanding_principal_balance?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "loans" */
export type LoansSumOrderBy = {
  amount?: Maybe<OrderBy>;
  outstanding_fees?: Maybe<OrderBy>;
  outstanding_interest?: Maybe<OrderBy>;
  outstanding_principal_balance?: Maybe<OrderBy>;
};

/** update columns of table "loans" */
export enum LoansUpdateColumn {
  /** column name */
  AdjustedMaturityDate = "adjusted_maturity_date",
  /** column name */
  Amount = "amount",
  /** column name */
  ApprovedAt = "approved_at",
  /** column name */
  ApprovedByUserId = "approved_by_user_id",
  /** column name */
  ArtifactId = "artifact_id",
  /** column name */
  ClosedAt = "closed_at",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CustomerNotes = "customer_notes",
  /** column name */
  DisbursementIdentifier = "disbursement_identifier",
  /** column name */
  FundedAt = "funded_at",
  /** column name */
  FundedByUserId = "funded_by_user_id",
  /** column name */
  Id = "id",
  /** column name */
  Identifier = "identifier",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  IsFrozen = "is_frozen",
  /** column name */
  LoanReportId = "loan_report_id",
  /** column name */
  LoanType = "loan_type",
  /** column name */
  MaturityDate = "maturity_date",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  ModifiedByUserId = "modified_by_user_id",
  /** column name */
  Notes = "notes",
  /** column name */
  OriginationDate = "origination_date",
  /** column name */
  OutstandingFees = "outstanding_fees",
  /** column name */
  OutstandingInterest = "outstanding_interest",
  /** column name */
  OutstandingPrincipalBalance = "outstanding_principal_balance",
  /** column name */
  PaymentStatus = "payment_status",
  /** column name */
  RejectedAt = "rejected_at",
  /** column name */
  RejectedByUserId = "rejected_by_user_id",
  /** column name */
  RejectionNote = "rejection_note",
  /** column name */
  RequestedAt = "requested_at",
  /** column name */
  RequestedByUserId = "requested_by_user_id",
  /** column name */
  RequestedPaymentDate = "requested_payment_date",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type LoansVarPopFields = {
  amount?: Maybe<Scalars["Float"]>;
  outstanding_fees?: Maybe<Scalars["Float"]>;
  outstanding_interest?: Maybe<Scalars["Float"]>;
  outstanding_principal_balance?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "loans" */
export type LoansVarPopOrderBy = {
  amount?: Maybe<OrderBy>;
  outstanding_fees?: Maybe<OrderBy>;
  outstanding_interest?: Maybe<OrderBy>;
  outstanding_principal_balance?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoansVarSampFields = {
  amount?: Maybe<Scalars["Float"]>;
  outstanding_fees?: Maybe<Scalars["Float"]>;
  outstanding_interest?: Maybe<Scalars["Float"]>;
  outstanding_principal_balance?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "loans" */
export type LoansVarSampOrderBy = {
  amount?: Maybe<OrderBy>;
  outstanding_fees?: Maybe<OrderBy>;
  outstanding_interest?: Maybe<OrderBy>;
  outstanding_principal_balance?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoansVarianceFields = {
  amount?: Maybe<Scalars["Float"]>;
  outstanding_fees?: Maybe<Scalars["Float"]>;
  outstanding_interest?: Maybe<Scalars["Float"]>;
  outstanding_principal_balance?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "loans" */
export type LoansVarianceOrderBy = {
  amount?: Maybe<OrderBy>;
  outstanding_fees?: Maybe<OrderBy>;
  outstanding_interest?: Maybe<OrderBy>;
  outstanding_principal_balance?: Maybe<OrderBy>;
};

/** columns and relationships of "metrc_analysis_summaries" */
export type MetrcAnalysisSummaries = {
  cogs_revenue_payload: Scalars["json"];
  /** An object relationship */
  company: Companies;
  /** An object relationship */
  company_facility?: Maybe<CompanyFacilities>;
  company_id: Scalars["uuid"];
  counts_payload: Scalars["json"];
  date: Scalars["date"];
  default_methodology: Scalars["String"];
  facility_row_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["uuid"];
  inventory_accuracy_payload: Scalars["json"];
  inventory_payload: Scalars["json"];
  methodology: Scalars["String"];
  stale_info_payload: Scalars["json"];
};

/** columns and relationships of "metrc_analysis_summaries" */
export type MetrcAnalysisSummariesCogsRevenuePayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "metrc_analysis_summaries" */
export type MetrcAnalysisSummariesCountsPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "metrc_analysis_summaries" */
export type MetrcAnalysisSummariesInventoryAccuracyPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "metrc_analysis_summaries" */
export type MetrcAnalysisSummariesInventoryPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "metrc_analysis_summaries" */
export type MetrcAnalysisSummariesStaleInfoPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "metrc_analysis_summaries" */
export type MetrcAnalysisSummariesAggregate = {
  aggregate?: Maybe<MetrcAnalysisSummariesAggregateFields>;
  nodes: Array<MetrcAnalysisSummaries>;
};

/** aggregate fields of "metrc_analysis_summaries" */
export type MetrcAnalysisSummariesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<MetrcAnalysisSummariesMaxFields>;
  min?: Maybe<MetrcAnalysisSummariesMinFields>;
};

/** aggregate fields of "metrc_analysis_summaries" */
export type MetrcAnalysisSummariesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MetrcAnalysisSummariesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "metrc_analysis_summaries". All fields are combined with a logical 'AND'. */
export type MetrcAnalysisSummariesBoolExp = {
  _and?: Maybe<Array<MetrcAnalysisSummariesBoolExp>>;
  _not?: Maybe<MetrcAnalysisSummariesBoolExp>;
  _or?: Maybe<Array<MetrcAnalysisSummariesBoolExp>>;
  cogs_revenue_payload?: Maybe<JsonComparisonExp>;
  company?: Maybe<CompaniesBoolExp>;
  company_facility?: Maybe<CompanyFacilitiesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  counts_payload?: Maybe<JsonComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  default_methodology?: Maybe<StringComparisonExp>;
  facility_row_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  inventory_accuracy_payload?: Maybe<JsonComparisonExp>;
  inventory_payload?: Maybe<JsonComparisonExp>;
  methodology?: Maybe<StringComparisonExp>;
  stale_info_payload?: Maybe<JsonComparisonExp>;
};

/** unique or primary key constraints on table "metrc_analysis_summaries" */
export enum MetrcAnalysisSummariesConstraint {
  /** unique or primary key constraint */
  CompanyInventorySummariesCompanyIdDateMethodologyKey = "company_inventory_summaries_company_id_date_methodology_key",
  /** unique or primary key constraint */
  CompanyInventorySummariesPkey = "company_inventory_summaries_pkey",
}

/** input type for inserting data into table "metrc_analysis_summaries" */
export type MetrcAnalysisSummariesInsertInput = {
  cogs_revenue_payload?: Maybe<Scalars["json"]>;
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_facility?: Maybe<CompanyFacilitiesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  counts_payload?: Maybe<Scalars["json"]>;
  date?: Maybe<Scalars["date"]>;
  default_methodology?: Maybe<Scalars["String"]>;
  facility_row_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  inventory_accuracy_payload?: Maybe<Scalars["json"]>;
  inventory_payload?: Maybe<Scalars["json"]>;
  methodology?: Maybe<Scalars["String"]>;
  stale_info_payload?: Maybe<Scalars["json"]>;
};

/** aggregate max on columns */
export type MetrcAnalysisSummariesMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  date?: Maybe<Scalars["date"]>;
  default_methodology?: Maybe<Scalars["String"]>;
  facility_row_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  methodology?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type MetrcAnalysisSummariesMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  date?: Maybe<Scalars["date"]>;
  default_methodology?: Maybe<Scalars["String"]>;
  facility_row_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  methodology?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "metrc_analysis_summaries" */
export type MetrcAnalysisSummariesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetrcAnalysisSummaries>;
};

/** on_conflict condition type for table "metrc_analysis_summaries" */
export type MetrcAnalysisSummariesOnConflict = {
  constraint: MetrcAnalysisSummariesConstraint;
  update_columns?: Array<MetrcAnalysisSummariesUpdateColumn>;
  where?: Maybe<MetrcAnalysisSummariesBoolExp>;
};

/** Ordering options when selecting data from "metrc_analysis_summaries". */
export type MetrcAnalysisSummariesOrderBy = {
  cogs_revenue_payload?: Maybe<OrderBy>;
  company?: Maybe<CompaniesOrderBy>;
  company_facility?: Maybe<CompanyFacilitiesOrderBy>;
  company_id?: Maybe<OrderBy>;
  counts_payload?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  default_methodology?: Maybe<OrderBy>;
  facility_row_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  inventory_accuracy_payload?: Maybe<OrderBy>;
  inventory_payload?: Maybe<OrderBy>;
  methodology?: Maybe<OrderBy>;
  stale_info_payload?: Maybe<OrderBy>;
};

/** primary key columns input for table: metrc_analysis_summaries */
export type MetrcAnalysisSummariesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "metrc_analysis_summaries" */
export enum MetrcAnalysisSummariesSelectColumn {
  /** column name */
  CogsRevenuePayload = "cogs_revenue_payload",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CountsPayload = "counts_payload",
  /** column name */
  Date = "date",
  /** column name */
  DefaultMethodology = "default_methodology",
  /** column name */
  FacilityRowId = "facility_row_id",
  /** column name */
  Id = "id",
  /** column name */
  InventoryAccuracyPayload = "inventory_accuracy_payload",
  /** column name */
  InventoryPayload = "inventory_payload",
  /** column name */
  Methodology = "methodology",
  /** column name */
  StaleInfoPayload = "stale_info_payload",
}

/** input type for updating data in table "metrc_analysis_summaries" */
export type MetrcAnalysisSummariesSetInput = {
  cogs_revenue_payload?: Maybe<Scalars["json"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  counts_payload?: Maybe<Scalars["json"]>;
  date?: Maybe<Scalars["date"]>;
  default_methodology?: Maybe<Scalars["String"]>;
  facility_row_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  inventory_accuracy_payload?: Maybe<Scalars["json"]>;
  inventory_payload?: Maybe<Scalars["json"]>;
  methodology?: Maybe<Scalars["String"]>;
  stale_info_payload?: Maybe<Scalars["json"]>;
};

/** update columns of table "metrc_analysis_summaries" */
export enum MetrcAnalysisSummariesUpdateColumn {
  /** column name */
  CogsRevenuePayload = "cogs_revenue_payload",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CountsPayload = "counts_payload",
  /** column name */
  Date = "date",
  /** column name */
  DefaultMethodology = "default_methodology",
  /** column name */
  FacilityRowId = "facility_row_id",
  /** column name */
  Id = "id",
  /** column name */
  InventoryAccuracyPayload = "inventory_accuracy_payload",
  /** column name */
  InventoryPayload = "inventory_payload",
  /** column name */
  Methodology = "methodology",
  /** column name */
  StaleInfoPayload = "stale_info_payload",
}

/** List of API keys we use to connect to Metrc */
export type MetrcApiKeys = {
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamp"];
  encrypted_api_key: Scalars["String"];
  facilities_payload?: Maybe<Scalars["json"]>;
  hashed_key?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  is_deleted: Scalars["Boolean"];
  is_functioning?: Maybe<Scalars["Boolean"]>;
  last_used_at?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  metrc_download_summaries: Array<MetrcDownloadSummaries>;
  /** An aggregate relationship */
  metrc_download_summaries_aggregate: MetrcDownloadSummariesAggregate;
  permissions_payload?: Maybe<Scalars["json"]>;
  permissions_refreshed_at?: Maybe<Scalars["timestamptz"]>;
  status_codes_payload?: Maybe<Scalars["json"]>;
  updated_at: Scalars["timestamptz"];
  us_state?: Maybe<Scalars["String"]>;
  use_saved_licenses_only: Scalars["Boolean"];
};

/** List of API keys we use to connect to Metrc */
export type MetrcApiKeysFacilitiesPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** List of API keys we use to connect to Metrc */
export type MetrcApiKeysMetrcDownloadSummariesArgs = {
  distinct_on?: Maybe<Array<MetrcDownloadSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcDownloadSummariesOrderBy>>;
  where?: Maybe<MetrcDownloadSummariesBoolExp>;
};

/** List of API keys we use to connect to Metrc */
export type MetrcApiKeysMetrcDownloadSummariesAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcDownloadSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcDownloadSummariesOrderBy>>;
  where?: Maybe<MetrcDownloadSummariesBoolExp>;
};

/** List of API keys we use to connect to Metrc */
export type MetrcApiKeysPermissionsPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** List of API keys we use to connect to Metrc */
export type MetrcApiKeysStatusCodesPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "metrc_api_keys" */
export type MetrcApiKeysAggregate = {
  aggregate?: Maybe<MetrcApiKeysAggregateFields>;
  nodes: Array<MetrcApiKeys>;
};

/** aggregate fields of "metrc_api_keys" */
export type MetrcApiKeysAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<MetrcApiKeysMaxFields>;
  min?: Maybe<MetrcApiKeysMinFields>;
};

/** aggregate fields of "metrc_api_keys" */
export type MetrcApiKeysAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MetrcApiKeysSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "metrc_api_keys" */
export type MetrcApiKeysAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<MetrcApiKeysMaxOrderBy>;
  min?: Maybe<MetrcApiKeysMinOrderBy>;
};

/** input type for inserting array relation for remote table "metrc_api_keys" */
export type MetrcApiKeysArrRelInsertInput = {
  data: Array<MetrcApiKeysInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<MetrcApiKeysOnConflict>;
};

/** Boolean expression to filter rows from the table "metrc_api_keys". All fields are combined with a logical 'AND'. */
export type MetrcApiKeysBoolExp = {
  _and?: Maybe<Array<MetrcApiKeysBoolExp>>;
  _not?: Maybe<MetrcApiKeysBoolExp>;
  _or?: Maybe<Array<MetrcApiKeysBoolExp>>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestampComparisonExp>;
  encrypted_api_key?: Maybe<StringComparisonExp>;
  facilities_payload?: Maybe<JsonComparisonExp>;
  hashed_key?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  is_functioning?: Maybe<BooleanComparisonExp>;
  last_used_at?: Maybe<TimestamptzComparisonExp>;
  metrc_download_summaries?: Maybe<MetrcDownloadSummariesBoolExp>;
  permissions_payload?: Maybe<JsonComparisonExp>;
  permissions_refreshed_at?: Maybe<TimestamptzComparisonExp>;
  status_codes_payload?: Maybe<JsonComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  us_state?: Maybe<StringComparisonExp>;
  use_saved_licenses_only?: Maybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "metrc_api_keys" */
export enum MetrcApiKeysConstraint {
  /** unique or primary key constraint */
  MetrcApiKeysPkey = "metrc_api_keys_pkey",
}

/** input type for inserting data into table "metrc_api_keys" */
export type MetrcApiKeysInsertInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamp"]>;
  encrypted_api_key?: Maybe<Scalars["String"]>;
  facilities_payload?: Maybe<Scalars["json"]>;
  hashed_key?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  is_functioning?: Maybe<Scalars["Boolean"]>;
  last_used_at?: Maybe<Scalars["timestamptz"]>;
  metrc_download_summaries?: Maybe<MetrcDownloadSummariesArrRelInsertInput>;
  permissions_payload?: Maybe<Scalars["json"]>;
  permissions_refreshed_at?: Maybe<Scalars["timestamptz"]>;
  status_codes_payload?: Maybe<Scalars["json"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
  use_saved_licenses_only?: Maybe<Scalars["Boolean"]>;
};

/** aggregate max on columns */
export type MetrcApiKeysMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamp"]>;
  encrypted_api_key?: Maybe<Scalars["String"]>;
  hashed_key?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_used_at?: Maybe<Scalars["timestamptz"]>;
  permissions_refreshed_at?: Maybe<Scalars["timestamptz"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "metrc_api_keys" */
export type MetrcApiKeysMaxOrderBy = {
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  encrypted_api_key?: Maybe<OrderBy>;
  hashed_key?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_used_at?: Maybe<OrderBy>;
  permissions_refreshed_at?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type MetrcApiKeysMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamp"]>;
  encrypted_api_key?: Maybe<Scalars["String"]>;
  hashed_key?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_used_at?: Maybe<Scalars["timestamptz"]>;
  permissions_refreshed_at?: Maybe<Scalars["timestamptz"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "metrc_api_keys" */
export type MetrcApiKeysMinOrderBy = {
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  encrypted_api_key?: Maybe<OrderBy>;
  hashed_key?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_used_at?: Maybe<OrderBy>;
  permissions_refreshed_at?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** response of any mutation on the table "metrc_api_keys" */
export type MetrcApiKeysMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetrcApiKeys>;
};

/** input type for inserting object relation for remote table "metrc_api_keys" */
export type MetrcApiKeysObjRelInsertInput = {
  data: MetrcApiKeysInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<MetrcApiKeysOnConflict>;
};

/** on_conflict condition type for table "metrc_api_keys" */
export type MetrcApiKeysOnConflict = {
  constraint: MetrcApiKeysConstraint;
  update_columns?: Array<MetrcApiKeysUpdateColumn>;
  where?: Maybe<MetrcApiKeysBoolExp>;
};

/** Ordering options when selecting data from "metrc_api_keys". */
export type MetrcApiKeysOrderBy = {
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  encrypted_api_key?: Maybe<OrderBy>;
  facilities_payload?: Maybe<OrderBy>;
  hashed_key?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  is_functioning?: Maybe<OrderBy>;
  last_used_at?: Maybe<OrderBy>;
  metrc_download_summaries_aggregate?: Maybe<MetrcDownloadSummariesAggregateOrderBy>;
  permissions_payload?: Maybe<OrderBy>;
  permissions_refreshed_at?: Maybe<OrderBy>;
  status_codes_payload?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
  use_saved_licenses_only?: Maybe<OrderBy>;
};

/** primary key columns input for table: metrc_api_keys */
export type MetrcApiKeysPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "metrc_api_keys" */
export enum MetrcApiKeysSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EncryptedApiKey = "encrypted_api_key",
  /** column name */
  FacilitiesPayload = "facilities_payload",
  /** column name */
  HashedKey = "hashed_key",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  IsFunctioning = "is_functioning",
  /** column name */
  LastUsedAt = "last_used_at",
  /** column name */
  PermissionsPayload = "permissions_payload",
  /** column name */
  PermissionsRefreshedAt = "permissions_refreshed_at",
  /** column name */
  StatusCodesPayload = "status_codes_payload",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
  /** column name */
  UseSavedLicensesOnly = "use_saved_licenses_only",
}

/** input type for updating data in table "metrc_api_keys" */
export type MetrcApiKeysSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamp"]>;
  encrypted_api_key?: Maybe<Scalars["String"]>;
  facilities_payload?: Maybe<Scalars["json"]>;
  hashed_key?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  is_functioning?: Maybe<Scalars["Boolean"]>;
  last_used_at?: Maybe<Scalars["timestamptz"]>;
  permissions_payload?: Maybe<Scalars["json"]>;
  permissions_refreshed_at?: Maybe<Scalars["timestamptz"]>;
  status_codes_payload?: Maybe<Scalars["json"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
  use_saved_licenses_only?: Maybe<Scalars["Boolean"]>;
};

/** update columns of table "metrc_api_keys" */
export enum MetrcApiKeysUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EncryptedApiKey = "encrypted_api_key",
  /** column name */
  FacilitiesPayload = "facilities_payload",
  /** column name */
  HashedKey = "hashed_key",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  IsFunctioning = "is_functioning",
  /** column name */
  LastUsedAt = "last_used_at",
  /** column name */
  PermissionsPayload = "permissions_payload",
  /** column name */
  PermissionsRefreshedAt = "permissions_refreshed_at",
  /** column name */
  StatusCodesPayload = "status_codes_payload",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
  /** column name */
  UseSavedLicensesOnly = "use_saved_licenses_only",
}

/** columns and relationships of "metrc_deliveries" */
export type MetrcDeliveries = {
  /** An array relationship */
  company_deliveries: Array<CompanyDeliveries>;
  /** An aggregate relationship */
  company_deliveries_aggregate: CompanyDeliveriesAggregate;
  created_at: Scalars["timestamptz"];
  /** From Metrc */
  delivery_id: Scalars["String"];
  delivery_payload: Scalars["json"];
  delivery_type?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  metrc_transfer: MetrcTransfers;
  /** An array relationship */
  metrc_transfer_packages: Array<MetrcTransferPackages>;
  /** An aggregate relationship */
  metrc_transfer_packages_aggregate: MetrcTransferPackagesAggregate;
  received_datetime?: Maybe<Scalars["timestamptz"]>;
  recipient_facility_license_number: Scalars["String"];
  recipient_facility_name: Scalars["String"];
  shipment_transaction_type: Scalars["String"];
  shipment_type_name: Scalars["String"];
  transfer_row_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  us_state: Scalars["String"];
};

/** columns and relationships of "metrc_deliveries" */
export type MetrcDeliveriesCompanyDeliveriesArgs = {
  distinct_on?: Maybe<Array<CompanyDeliveriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyDeliveriesOrderBy>>;
  where?: Maybe<CompanyDeliveriesBoolExp>;
};

/** columns and relationships of "metrc_deliveries" */
export type MetrcDeliveriesCompanyDeliveriesAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyDeliveriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyDeliveriesOrderBy>>;
  where?: Maybe<CompanyDeliveriesBoolExp>;
};

/** columns and relationships of "metrc_deliveries" */
export type MetrcDeliveriesDeliveryPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "metrc_deliveries" */
export type MetrcDeliveriesMetrcTransferPackagesArgs = {
  distinct_on?: Maybe<Array<MetrcTransferPackagesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcTransferPackagesOrderBy>>;
  where?: Maybe<MetrcTransferPackagesBoolExp>;
};

/** columns and relationships of "metrc_deliveries" */
export type MetrcDeliveriesMetrcTransferPackagesAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcTransferPackagesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcTransferPackagesOrderBy>>;
  where?: Maybe<MetrcTransferPackagesBoolExp>;
};

/** aggregated selection of "metrc_deliveries" */
export type MetrcDeliveriesAggregate = {
  aggregate?: Maybe<MetrcDeliveriesAggregateFields>;
  nodes: Array<MetrcDeliveries>;
};

/** aggregate fields of "metrc_deliveries" */
export type MetrcDeliveriesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<MetrcDeliveriesMaxFields>;
  min?: Maybe<MetrcDeliveriesMinFields>;
};

/** aggregate fields of "metrc_deliveries" */
export type MetrcDeliveriesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MetrcDeliveriesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "metrc_deliveries" */
export type MetrcDeliveriesAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<MetrcDeliveriesMaxOrderBy>;
  min?: Maybe<MetrcDeliveriesMinOrderBy>;
};

/** input type for inserting array relation for remote table "metrc_deliveries" */
export type MetrcDeliveriesArrRelInsertInput = {
  data: Array<MetrcDeliveriesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<MetrcDeliveriesOnConflict>;
};

/** Boolean expression to filter rows from the table "metrc_deliveries". All fields are combined with a logical 'AND'. */
export type MetrcDeliveriesBoolExp = {
  _and?: Maybe<Array<MetrcDeliveriesBoolExp>>;
  _not?: Maybe<MetrcDeliveriesBoolExp>;
  _or?: Maybe<Array<MetrcDeliveriesBoolExp>>;
  company_deliveries?: Maybe<CompanyDeliveriesBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  delivery_id?: Maybe<StringComparisonExp>;
  delivery_payload?: Maybe<JsonComparisonExp>;
  delivery_type?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  metrc_transfer?: Maybe<MetrcTransfersBoolExp>;
  metrc_transfer_packages?: Maybe<MetrcTransferPackagesBoolExp>;
  received_datetime?: Maybe<TimestamptzComparisonExp>;
  recipient_facility_license_number?: Maybe<StringComparisonExp>;
  recipient_facility_name?: Maybe<StringComparisonExp>;
  shipment_transaction_type?: Maybe<StringComparisonExp>;
  shipment_type_name?: Maybe<StringComparisonExp>;
  transfer_row_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  us_state?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "metrc_deliveries" */
export enum MetrcDeliveriesConstraint {
  /** unique or primary key constraint */
  MetrcDeliveriesPkey = "metrc_deliveries_pkey",
  /** unique or primary key constraint */
  MetrcDeliveriesUsStateTransferRowIdDeliveryIdKey = "metrc_deliveries_us_state_transfer_row_id_delivery_id_key",
}

/** input type for inserting data into table "metrc_deliveries" */
export type MetrcDeliveriesInsertInput = {
  company_deliveries?: Maybe<CompanyDeliveriesArrRelInsertInput>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** From Metrc */
  delivery_id?: Maybe<Scalars["String"]>;
  delivery_payload?: Maybe<Scalars["json"]>;
  delivery_type?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  metrc_transfer?: Maybe<MetrcTransfersObjRelInsertInput>;
  metrc_transfer_packages?: Maybe<MetrcTransferPackagesArrRelInsertInput>;
  received_datetime?: Maybe<Scalars["timestamptz"]>;
  recipient_facility_license_number?: Maybe<Scalars["String"]>;
  recipient_facility_name?: Maybe<Scalars["String"]>;
  shipment_transaction_type?: Maybe<Scalars["String"]>;
  shipment_type_name?: Maybe<Scalars["String"]>;
  transfer_row_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type MetrcDeliveriesMaxFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** From Metrc */
  delivery_id?: Maybe<Scalars["String"]>;
  delivery_type?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  received_datetime?: Maybe<Scalars["timestamptz"]>;
  recipient_facility_license_number?: Maybe<Scalars["String"]>;
  recipient_facility_name?: Maybe<Scalars["String"]>;
  shipment_transaction_type?: Maybe<Scalars["String"]>;
  shipment_type_name?: Maybe<Scalars["String"]>;
  transfer_row_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "metrc_deliveries" */
export type MetrcDeliveriesMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  /** From Metrc */
  delivery_id?: Maybe<OrderBy>;
  delivery_type?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  received_datetime?: Maybe<OrderBy>;
  recipient_facility_license_number?: Maybe<OrderBy>;
  recipient_facility_name?: Maybe<OrderBy>;
  shipment_transaction_type?: Maybe<OrderBy>;
  shipment_type_name?: Maybe<OrderBy>;
  transfer_row_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type MetrcDeliveriesMinFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** From Metrc */
  delivery_id?: Maybe<Scalars["String"]>;
  delivery_type?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  received_datetime?: Maybe<Scalars["timestamptz"]>;
  recipient_facility_license_number?: Maybe<Scalars["String"]>;
  recipient_facility_name?: Maybe<Scalars["String"]>;
  shipment_transaction_type?: Maybe<Scalars["String"]>;
  shipment_type_name?: Maybe<Scalars["String"]>;
  transfer_row_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "metrc_deliveries" */
export type MetrcDeliveriesMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  /** From Metrc */
  delivery_id?: Maybe<OrderBy>;
  delivery_type?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  received_datetime?: Maybe<OrderBy>;
  recipient_facility_license_number?: Maybe<OrderBy>;
  recipient_facility_name?: Maybe<OrderBy>;
  shipment_transaction_type?: Maybe<OrderBy>;
  shipment_type_name?: Maybe<OrderBy>;
  transfer_row_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** response of any mutation on the table "metrc_deliveries" */
export type MetrcDeliveriesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetrcDeliveries>;
};

/** input type for inserting object relation for remote table "metrc_deliveries" */
export type MetrcDeliveriesObjRelInsertInput = {
  data: MetrcDeliveriesInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<MetrcDeliveriesOnConflict>;
};

/** on_conflict condition type for table "metrc_deliveries" */
export type MetrcDeliveriesOnConflict = {
  constraint: MetrcDeliveriesConstraint;
  update_columns?: Array<MetrcDeliveriesUpdateColumn>;
  where?: Maybe<MetrcDeliveriesBoolExp>;
};

/** Ordering options when selecting data from "metrc_deliveries". */
export type MetrcDeliveriesOrderBy = {
  company_deliveries_aggregate?: Maybe<CompanyDeliveriesAggregateOrderBy>;
  created_at?: Maybe<OrderBy>;
  delivery_id?: Maybe<OrderBy>;
  delivery_payload?: Maybe<OrderBy>;
  delivery_type?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  metrc_transfer?: Maybe<MetrcTransfersOrderBy>;
  metrc_transfer_packages_aggregate?: Maybe<MetrcTransferPackagesAggregateOrderBy>;
  received_datetime?: Maybe<OrderBy>;
  recipient_facility_license_number?: Maybe<OrderBy>;
  recipient_facility_name?: Maybe<OrderBy>;
  shipment_transaction_type?: Maybe<OrderBy>;
  shipment_type_name?: Maybe<OrderBy>;
  transfer_row_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** primary key columns input for table: metrc_deliveries */
export type MetrcDeliveriesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "metrc_deliveries" */
export enum MetrcDeliveriesSelectColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeliveryId = "delivery_id",
  /** column name */
  DeliveryPayload = "delivery_payload",
  /** column name */
  DeliveryType = "delivery_type",
  /** column name */
  Id = "id",
  /** column name */
  ReceivedDatetime = "received_datetime",
  /** column name */
  RecipientFacilityLicenseNumber = "recipient_facility_license_number",
  /** column name */
  RecipientFacilityName = "recipient_facility_name",
  /** column name */
  ShipmentTransactionType = "shipment_transaction_type",
  /** column name */
  ShipmentTypeName = "shipment_type_name",
  /** column name */
  TransferRowId = "transfer_row_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** input type for updating data in table "metrc_deliveries" */
export type MetrcDeliveriesSetInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** From Metrc */
  delivery_id?: Maybe<Scalars["String"]>;
  delivery_payload?: Maybe<Scalars["json"]>;
  delivery_type?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  received_datetime?: Maybe<Scalars["timestamptz"]>;
  recipient_facility_license_number?: Maybe<Scalars["String"]>;
  recipient_facility_name?: Maybe<Scalars["String"]>;
  shipment_transaction_type?: Maybe<Scalars["String"]>;
  shipment_type_name?: Maybe<Scalars["String"]>;
  transfer_row_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** update columns of table "metrc_deliveries" */
export enum MetrcDeliveriesUpdateColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeliveryId = "delivery_id",
  /** column name */
  DeliveryPayload = "delivery_payload",
  /** column name */
  DeliveryType = "delivery_type",
  /** column name */
  Id = "id",
  /** column name */
  ReceivedDatetime = "received_datetime",
  /** column name */
  RecipientFacilityLicenseNumber = "recipient_facility_license_number",
  /** column name */
  RecipientFacilityName = "recipient_facility_name",
  /** column name */
  ShipmentTransactionType = "shipment_transaction_type",
  /** column name */
  ShipmentTypeName = "shipment_type_name",
  /** column name */
  TransferRowId = "transfer_row_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** Structured results of how a download  */
export type MetrcDownloadSummaries = {
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  date: Scalars["date"];
  err_details: Scalars["json"];
  harvests_status: Scalars["String"];
  id: Scalars["uuid"];
  license_number: Scalars["String"];
  /** An object relationship */
  metrc_api_key: MetrcApiKeys;
  metrc_api_key_id: Scalars["uuid"];
  num_retries: Scalars["Int"];
  packages_status: Scalars["String"];
  plant_batches_status: Scalars["String"];
  plants_status: Scalars["String"];
  retry_payload: Scalars["json"];
  sales_status: Scalars["String"];
  status: Scalars["String"];
  transfers_status: Scalars["String"];
  updated_at: Scalars["timestamptz"];
};

/** Structured results of how a download  */
export type MetrcDownloadSummariesErrDetailsArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** Structured results of how a download  */
export type MetrcDownloadSummariesRetryPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "metrc_download_summaries" */
export type MetrcDownloadSummariesAggregate = {
  aggregate?: Maybe<MetrcDownloadSummariesAggregateFields>;
  nodes: Array<MetrcDownloadSummaries>;
};

/** aggregate fields of "metrc_download_summaries" */
export type MetrcDownloadSummariesAggregateFields = {
  avg?: Maybe<MetrcDownloadSummariesAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<MetrcDownloadSummariesMaxFields>;
  min?: Maybe<MetrcDownloadSummariesMinFields>;
  stddev?: Maybe<MetrcDownloadSummariesStddevFields>;
  stddev_pop?: Maybe<MetrcDownloadSummariesStddevPopFields>;
  stddev_samp?: Maybe<MetrcDownloadSummariesStddevSampFields>;
  sum?: Maybe<MetrcDownloadSummariesSumFields>;
  var_pop?: Maybe<MetrcDownloadSummariesVarPopFields>;
  var_samp?: Maybe<MetrcDownloadSummariesVarSampFields>;
  variance?: Maybe<MetrcDownloadSummariesVarianceFields>;
};

/** aggregate fields of "metrc_download_summaries" */
export type MetrcDownloadSummariesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MetrcDownloadSummariesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "metrc_download_summaries" */
export type MetrcDownloadSummariesAggregateOrderBy = {
  avg?: Maybe<MetrcDownloadSummariesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<MetrcDownloadSummariesMaxOrderBy>;
  min?: Maybe<MetrcDownloadSummariesMinOrderBy>;
  stddev?: Maybe<MetrcDownloadSummariesStddevOrderBy>;
  stddev_pop?: Maybe<MetrcDownloadSummariesStddevPopOrderBy>;
  stddev_samp?: Maybe<MetrcDownloadSummariesStddevSampOrderBy>;
  sum?: Maybe<MetrcDownloadSummariesSumOrderBy>;
  var_pop?: Maybe<MetrcDownloadSummariesVarPopOrderBy>;
  var_samp?: Maybe<MetrcDownloadSummariesVarSampOrderBy>;
  variance?: Maybe<MetrcDownloadSummariesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "metrc_download_summaries" */
export type MetrcDownloadSummariesArrRelInsertInput = {
  data: Array<MetrcDownloadSummariesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<MetrcDownloadSummariesOnConflict>;
};

/** aggregate avg on columns */
export type MetrcDownloadSummariesAvgFields = {
  num_retries?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "metrc_download_summaries" */
export type MetrcDownloadSummariesAvgOrderBy = {
  num_retries?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "metrc_download_summaries". All fields are combined with a logical 'AND'. */
export type MetrcDownloadSummariesBoolExp = {
  _and?: Maybe<Array<MetrcDownloadSummariesBoolExp>>;
  _not?: Maybe<MetrcDownloadSummariesBoolExp>;
  _or?: Maybe<Array<MetrcDownloadSummariesBoolExp>>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  err_details?: Maybe<JsonComparisonExp>;
  harvests_status?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  license_number?: Maybe<StringComparisonExp>;
  metrc_api_key?: Maybe<MetrcApiKeysBoolExp>;
  metrc_api_key_id?: Maybe<UuidComparisonExp>;
  num_retries?: Maybe<IntComparisonExp>;
  packages_status?: Maybe<StringComparisonExp>;
  plant_batches_status?: Maybe<StringComparisonExp>;
  plants_status?: Maybe<StringComparisonExp>;
  retry_payload?: Maybe<JsonComparisonExp>;
  sales_status?: Maybe<StringComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  transfers_status?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "metrc_download_summaries" */
export enum MetrcDownloadSummariesConstraint {
  /** unique or primary key constraint */
  MetrcDownloadSummariesLicenseNumberDateKey = "metrc_download_summaries_license_number_date_key",
  /** unique or primary key constraint */
  MetrcDownloadSummariesPkey = "metrc_download_summaries_pkey",
}

/** input type for incrementing numeric columns in table "metrc_download_summaries" */
export type MetrcDownloadSummariesIncInput = {
  num_retries?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "metrc_download_summaries" */
export type MetrcDownloadSummariesInsertInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  date?: Maybe<Scalars["date"]>;
  err_details?: Maybe<Scalars["json"]>;
  harvests_status?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  license_number?: Maybe<Scalars["String"]>;
  metrc_api_key?: Maybe<MetrcApiKeysObjRelInsertInput>;
  metrc_api_key_id?: Maybe<Scalars["uuid"]>;
  num_retries?: Maybe<Scalars["Int"]>;
  packages_status?: Maybe<Scalars["String"]>;
  plant_batches_status?: Maybe<Scalars["String"]>;
  plants_status?: Maybe<Scalars["String"]>;
  retry_payload?: Maybe<Scalars["json"]>;
  sales_status?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  transfers_status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type MetrcDownloadSummariesMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  date?: Maybe<Scalars["date"]>;
  harvests_status?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  license_number?: Maybe<Scalars["String"]>;
  metrc_api_key_id?: Maybe<Scalars["uuid"]>;
  num_retries?: Maybe<Scalars["Int"]>;
  packages_status?: Maybe<Scalars["String"]>;
  plant_batches_status?: Maybe<Scalars["String"]>;
  plants_status?: Maybe<Scalars["String"]>;
  sales_status?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  transfers_status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "metrc_download_summaries" */
export type MetrcDownloadSummariesMaxOrderBy = {
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  harvests_status?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  metrc_api_key_id?: Maybe<OrderBy>;
  num_retries?: Maybe<OrderBy>;
  packages_status?: Maybe<OrderBy>;
  plant_batches_status?: Maybe<OrderBy>;
  plants_status?: Maybe<OrderBy>;
  sales_status?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  transfers_status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type MetrcDownloadSummariesMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  date?: Maybe<Scalars["date"]>;
  harvests_status?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  license_number?: Maybe<Scalars["String"]>;
  metrc_api_key_id?: Maybe<Scalars["uuid"]>;
  num_retries?: Maybe<Scalars["Int"]>;
  packages_status?: Maybe<Scalars["String"]>;
  plant_batches_status?: Maybe<Scalars["String"]>;
  plants_status?: Maybe<Scalars["String"]>;
  sales_status?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  transfers_status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "metrc_download_summaries" */
export type MetrcDownloadSummariesMinOrderBy = {
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  harvests_status?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  metrc_api_key_id?: Maybe<OrderBy>;
  num_retries?: Maybe<OrderBy>;
  packages_status?: Maybe<OrderBy>;
  plant_batches_status?: Maybe<OrderBy>;
  plants_status?: Maybe<OrderBy>;
  sales_status?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  transfers_status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "metrc_download_summaries" */
export type MetrcDownloadSummariesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetrcDownloadSummaries>;
};

/** on_conflict condition type for table "metrc_download_summaries" */
export type MetrcDownloadSummariesOnConflict = {
  constraint: MetrcDownloadSummariesConstraint;
  update_columns?: Array<MetrcDownloadSummariesUpdateColumn>;
  where?: Maybe<MetrcDownloadSummariesBoolExp>;
};

/** Ordering options when selecting data from "metrc_download_summaries". */
export type MetrcDownloadSummariesOrderBy = {
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  err_details?: Maybe<OrderBy>;
  harvests_status?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  metrc_api_key?: Maybe<MetrcApiKeysOrderBy>;
  metrc_api_key_id?: Maybe<OrderBy>;
  num_retries?: Maybe<OrderBy>;
  packages_status?: Maybe<OrderBy>;
  plant_batches_status?: Maybe<OrderBy>;
  plants_status?: Maybe<OrderBy>;
  retry_payload?: Maybe<OrderBy>;
  sales_status?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  transfers_status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: metrc_download_summaries */
export type MetrcDownloadSummariesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "metrc_download_summaries" */
export enum MetrcDownloadSummariesSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Date = "date",
  /** column name */
  ErrDetails = "err_details",
  /** column name */
  HarvestsStatus = "harvests_status",
  /** column name */
  Id = "id",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  MetrcApiKeyId = "metrc_api_key_id",
  /** column name */
  NumRetries = "num_retries",
  /** column name */
  PackagesStatus = "packages_status",
  /** column name */
  PlantBatchesStatus = "plant_batches_status",
  /** column name */
  PlantsStatus = "plants_status",
  /** column name */
  RetryPayload = "retry_payload",
  /** column name */
  SalesStatus = "sales_status",
  /** column name */
  Status = "status",
  /** column name */
  TransfersStatus = "transfers_status",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "metrc_download_summaries" */
export type MetrcDownloadSummariesSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  date?: Maybe<Scalars["date"]>;
  err_details?: Maybe<Scalars["json"]>;
  harvests_status?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  license_number?: Maybe<Scalars["String"]>;
  metrc_api_key_id?: Maybe<Scalars["uuid"]>;
  num_retries?: Maybe<Scalars["Int"]>;
  packages_status?: Maybe<Scalars["String"]>;
  plant_batches_status?: Maybe<Scalars["String"]>;
  plants_status?: Maybe<Scalars["String"]>;
  retry_payload?: Maybe<Scalars["json"]>;
  sales_status?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  transfers_status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type MetrcDownloadSummariesStddevFields = {
  num_retries?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "metrc_download_summaries" */
export type MetrcDownloadSummariesStddevOrderBy = {
  num_retries?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type MetrcDownloadSummariesStddevPopFields = {
  num_retries?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "metrc_download_summaries" */
export type MetrcDownloadSummariesStddevPopOrderBy = {
  num_retries?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type MetrcDownloadSummariesStddevSampFields = {
  num_retries?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "metrc_download_summaries" */
export type MetrcDownloadSummariesStddevSampOrderBy = {
  num_retries?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type MetrcDownloadSummariesSumFields = {
  num_retries?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "metrc_download_summaries" */
export type MetrcDownloadSummariesSumOrderBy = {
  num_retries?: Maybe<OrderBy>;
};

/** update columns of table "metrc_download_summaries" */
export enum MetrcDownloadSummariesUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Date = "date",
  /** column name */
  ErrDetails = "err_details",
  /** column name */
  HarvestsStatus = "harvests_status",
  /** column name */
  Id = "id",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  MetrcApiKeyId = "metrc_api_key_id",
  /** column name */
  NumRetries = "num_retries",
  /** column name */
  PackagesStatus = "packages_status",
  /** column name */
  PlantBatchesStatus = "plant_batches_status",
  /** column name */
  PlantsStatus = "plants_status",
  /** column name */
  RetryPayload = "retry_payload",
  /** column name */
  SalesStatus = "sales_status",
  /** column name */
  Status = "status",
  /** column name */
  TransfersStatus = "transfers_status",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type MetrcDownloadSummariesVarPopFields = {
  num_retries?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "metrc_download_summaries" */
export type MetrcDownloadSummariesVarPopOrderBy = {
  num_retries?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type MetrcDownloadSummariesVarSampFields = {
  num_retries?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "metrc_download_summaries" */
export type MetrcDownloadSummariesVarSampOrderBy = {
  num_retries?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type MetrcDownloadSummariesVarianceFields = {
  num_retries?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "metrc_download_summaries" */
export type MetrcDownloadSummariesVarianceOrderBy = {
  num_retries?: Maybe<OrderBy>;
};

/** columns and relationships of "metrc_harvests" */
export type MetrcHarvests = {
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  harvest_id: Scalars["String"];
  harvest_start_date: Scalars["date"];
  id: Scalars["uuid"];
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  payload: Scalars["json"];
  type: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  us_state: Scalars["String"];
};

/** columns and relationships of "metrc_harvests" */
export type MetrcHarvestsPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "metrc_harvests" */
export type MetrcHarvestsAggregate = {
  aggregate?: Maybe<MetrcHarvestsAggregateFields>;
  nodes: Array<MetrcHarvests>;
};

/** aggregate fields of "metrc_harvests" */
export type MetrcHarvestsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<MetrcHarvestsMaxFields>;
  min?: Maybe<MetrcHarvestsMinFields>;
};

/** aggregate fields of "metrc_harvests" */
export type MetrcHarvestsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MetrcHarvestsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "metrc_harvests". All fields are combined with a logical 'AND'. */
export type MetrcHarvestsBoolExp = {
  _and?: Maybe<Array<MetrcHarvestsBoolExp>>;
  _not?: Maybe<MetrcHarvestsBoolExp>;
  _or?: Maybe<Array<MetrcHarvestsBoolExp>>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  harvest_id?: Maybe<StringComparisonExp>;
  harvest_start_date?: Maybe<DateComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  last_modified_at?: Maybe<TimestamptzComparisonExp>;
  license_number?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  payload?: Maybe<JsonComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  us_state?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "metrc_harvests" */
export enum MetrcHarvestsConstraint {
  /** unique or primary key constraint */
  MetrcHarvestsHarvestIdKey = "metrc_harvests_harvest_id_key",
  /** unique or primary key constraint */
  MetrcHarvestsPkey = "metrc_harvests_pkey",
}

/** input type for inserting data into table "metrc_harvests" */
export type MetrcHarvestsInsertInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  harvest_id?: Maybe<Scalars["String"]>;
  harvest_start_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  payload?: Maybe<Scalars["json"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type MetrcHarvestsMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  harvest_id?: Maybe<Scalars["String"]>;
  harvest_start_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type MetrcHarvestsMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  harvest_id?: Maybe<Scalars["String"]>;
  harvest_start_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "metrc_harvests" */
export type MetrcHarvestsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetrcHarvests>;
};

/** on_conflict condition type for table "metrc_harvests" */
export type MetrcHarvestsOnConflict = {
  constraint: MetrcHarvestsConstraint;
  update_columns?: Array<MetrcHarvestsUpdateColumn>;
  where?: Maybe<MetrcHarvestsBoolExp>;
};

/** Ordering options when selecting data from "metrc_harvests". */
export type MetrcHarvestsOrderBy = {
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  harvest_id?: Maybe<OrderBy>;
  harvest_start_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_modified_at?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  payload?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** primary key columns input for table: metrc_harvests */
export type MetrcHarvestsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "metrc_harvests" */
export enum MetrcHarvestsSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  HarvestId = "harvest_id",
  /** column name */
  HarvestStartDate = "harvest_start_date",
  /** column name */
  Id = "id",
  /** column name */
  LastModifiedAt = "last_modified_at",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  Name = "name",
  /** column name */
  Payload = "payload",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** input type for updating data in table "metrc_harvests" */
export type MetrcHarvestsSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  harvest_id?: Maybe<Scalars["String"]>;
  harvest_start_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  payload?: Maybe<Scalars["json"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** update columns of table "metrc_harvests" */
export enum MetrcHarvestsUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  HarvestId = "harvest_id",
  /** column name */
  HarvestStartDate = "harvest_start_date",
  /** column name */
  Id = "id",
  /** column name */
  LastModifiedAt = "last_modified_at",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  Name = "name",
  /** column name */
  Payload = "payload",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** columns and relationships of "metrc_packages" */
export type MetrcPackages = {
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  last_modified_at: Scalars["timestamptz"];
  license_number?: Maybe<Scalars["String"]>;
  package_id: Scalars["String"];
  package_label: Scalars["String"];
  package_payload: Scalars["json"];
  package_type: Scalars["String"];
  packaged_date: Scalars["date"];
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["numeric"]>;
  type: Scalars["String"];
  unit_of_measure?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
  us_state: Scalars["String"];
};

/** columns and relationships of "metrc_packages" */
export type MetrcPackagesPackagePayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "metrc_packages" */
export type MetrcPackagesAggregate = {
  aggregate?: Maybe<MetrcPackagesAggregateFields>;
  nodes: Array<MetrcPackages>;
};

/** aggregate fields of "metrc_packages" */
export type MetrcPackagesAggregateFields = {
  avg?: Maybe<MetrcPackagesAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<MetrcPackagesMaxFields>;
  min?: Maybe<MetrcPackagesMinFields>;
  stddev?: Maybe<MetrcPackagesStddevFields>;
  stddev_pop?: Maybe<MetrcPackagesStddevPopFields>;
  stddev_samp?: Maybe<MetrcPackagesStddevSampFields>;
  sum?: Maybe<MetrcPackagesSumFields>;
  var_pop?: Maybe<MetrcPackagesVarPopFields>;
  var_samp?: Maybe<MetrcPackagesVarSampFields>;
  variance?: Maybe<MetrcPackagesVarianceFields>;
};

/** aggregate fields of "metrc_packages" */
export type MetrcPackagesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MetrcPackagesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type MetrcPackagesAvgFields = {
  quantity?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "metrc_packages". All fields are combined with a logical 'AND'. */
export type MetrcPackagesBoolExp = {
  _and?: Maybe<Array<MetrcPackagesBoolExp>>;
  _not?: Maybe<MetrcPackagesBoolExp>;
  _or?: Maybe<Array<MetrcPackagesBoolExp>>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  last_modified_at?: Maybe<TimestamptzComparisonExp>;
  license_number?: Maybe<StringComparisonExp>;
  package_id?: Maybe<StringComparisonExp>;
  package_label?: Maybe<StringComparisonExp>;
  package_payload?: Maybe<JsonComparisonExp>;
  package_type?: Maybe<StringComparisonExp>;
  packaged_date?: Maybe<DateComparisonExp>;
  product_category_name?: Maybe<StringComparisonExp>;
  product_name?: Maybe<StringComparisonExp>;
  quantity?: Maybe<NumericComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  unit_of_measure?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  us_state?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "metrc_packages" */
export enum MetrcPackagesConstraint {
  /** unique or primary key constraint */
  MetrcPackagesPkey = "metrc_packages_pkey",
  /** unique or primary key constraint */
  MetrcPackagesUsStateLicenseNumberPackageIdKey = "metrc_packages_us_state_license_number_package_id_key",
  /** unique or primary key constraint */
  MetrcPackagesUsStatePackageIdKey = "metrc_packages_us_state_package_id_key",
}

/** input type for incrementing numeric columns in table "metrc_packages" */
export type MetrcPackagesIncInput = {
  quantity?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "metrc_packages" */
export type MetrcPackagesInsertInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  package_id?: Maybe<Scalars["String"]>;
  package_label?: Maybe<Scalars["String"]>;
  package_payload?: Maybe<Scalars["json"]>;
  package_type?: Maybe<Scalars["String"]>;
  packaged_date?: Maybe<Scalars["date"]>;
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["numeric"]>;
  type?: Maybe<Scalars["String"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type MetrcPackagesMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  package_id?: Maybe<Scalars["String"]>;
  package_label?: Maybe<Scalars["String"]>;
  package_type?: Maybe<Scalars["String"]>;
  packaged_date?: Maybe<Scalars["date"]>;
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["numeric"]>;
  type?: Maybe<Scalars["String"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type MetrcPackagesMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  package_id?: Maybe<Scalars["String"]>;
  package_label?: Maybe<Scalars["String"]>;
  package_type?: Maybe<Scalars["String"]>;
  packaged_date?: Maybe<Scalars["date"]>;
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["numeric"]>;
  type?: Maybe<Scalars["String"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "metrc_packages" */
export type MetrcPackagesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetrcPackages>;
};

/** on_conflict condition type for table "metrc_packages" */
export type MetrcPackagesOnConflict = {
  constraint: MetrcPackagesConstraint;
  update_columns?: Array<MetrcPackagesUpdateColumn>;
  where?: Maybe<MetrcPackagesBoolExp>;
};

/** Ordering options when selecting data from "metrc_packages". */
export type MetrcPackagesOrderBy = {
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_modified_at?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  package_id?: Maybe<OrderBy>;
  package_label?: Maybe<OrderBy>;
  package_payload?: Maybe<OrderBy>;
  package_type?: Maybe<OrderBy>;
  packaged_date?: Maybe<OrderBy>;
  product_category_name?: Maybe<OrderBy>;
  product_name?: Maybe<OrderBy>;
  quantity?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  unit_of_measure?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** primary key columns input for table: metrc_packages */
export type MetrcPackagesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "metrc_packages" */
export enum MetrcPackagesSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastModifiedAt = "last_modified_at",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  PackageId = "package_id",
  /** column name */
  PackageLabel = "package_label",
  /** column name */
  PackagePayload = "package_payload",
  /** column name */
  PackageType = "package_type",
  /** column name */
  PackagedDate = "packaged_date",
  /** column name */
  ProductCategoryName = "product_category_name",
  /** column name */
  ProductName = "product_name",
  /** column name */
  Quantity = "quantity",
  /** column name */
  Type = "type",
  /** column name */
  UnitOfMeasure = "unit_of_measure",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** input type for updating data in table "metrc_packages" */
export type MetrcPackagesSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  package_id?: Maybe<Scalars["String"]>;
  package_label?: Maybe<Scalars["String"]>;
  package_payload?: Maybe<Scalars["json"]>;
  package_type?: Maybe<Scalars["String"]>;
  packaged_date?: Maybe<Scalars["date"]>;
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["numeric"]>;
  type?: Maybe<Scalars["String"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type MetrcPackagesStddevFields = {
  quantity?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type MetrcPackagesStddevPopFields = {
  quantity?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type MetrcPackagesStddevSampFields = {
  quantity?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type MetrcPackagesSumFields = {
  quantity?: Maybe<Scalars["numeric"]>;
};

/** update columns of table "metrc_packages" */
export enum MetrcPackagesUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastModifiedAt = "last_modified_at",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  PackageId = "package_id",
  /** column name */
  PackageLabel = "package_label",
  /** column name */
  PackagePayload = "package_payload",
  /** column name */
  PackageType = "package_type",
  /** column name */
  PackagedDate = "packaged_date",
  /** column name */
  ProductCategoryName = "product_category_name",
  /** column name */
  ProductName = "product_name",
  /** column name */
  Quantity = "quantity",
  /** column name */
  Type = "type",
  /** column name */
  UnitOfMeasure = "unit_of_measure",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** aggregate var_pop on columns */
export type MetrcPackagesVarPopFields = {
  quantity?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type MetrcPackagesVarSampFields = {
  quantity?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type MetrcPackagesVarianceFields = {
  quantity?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "metrc_plant_batches" */
export type MetrcPlantBatches = {
  /** An object relationship */
  company: Companies;
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  payload: Scalars["json"];
  plant_batch_id: Scalars["String"];
  planted_date: Scalars["date"];
  type: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  us_state: Scalars["String"];
};

/** columns and relationships of "metrc_plant_batches" */
export type MetrcPlantBatchesPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "metrc_plant_batches" */
export type MetrcPlantBatchesAggregate = {
  aggregate?: Maybe<MetrcPlantBatchesAggregateFields>;
  nodes: Array<MetrcPlantBatches>;
};

/** aggregate fields of "metrc_plant_batches" */
export type MetrcPlantBatchesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<MetrcPlantBatchesMaxFields>;
  min?: Maybe<MetrcPlantBatchesMinFields>;
};

/** aggregate fields of "metrc_plant_batches" */
export type MetrcPlantBatchesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MetrcPlantBatchesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "metrc_plant_batches". All fields are combined with a logical 'AND'. */
export type MetrcPlantBatchesBoolExp = {
  _and?: Maybe<Array<MetrcPlantBatchesBoolExp>>;
  _not?: Maybe<MetrcPlantBatchesBoolExp>;
  _or?: Maybe<Array<MetrcPlantBatchesBoolExp>>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  last_modified_at?: Maybe<TimestamptzComparisonExp>;
  license_number?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  payload?: Maybe<JsonComparisonExp>;
  plant_batch_id?: Maybe<StringComparisonExp>;
  planted_date?: Maybe<DateComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  us_state?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "metrc_plant_batches" */
export enum MetrcPlantBatchesConstraint {
  /** unique or primary key constraint */
  MetrcPlantBatchesPkey = "metrc_plant_batches_pkey",
  /** unique or primary key constraint */
  MetrcPlantBatchesPlantBatchIdKey = "metrc_plant_batches_plant_batch_id_key",
}

/** input type for inserting data into table "metrc_plant_batches" */
export type MetrcPlantBatchesInsertInput = {
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  payload?: Maybe<Scalars["json"]>;
  plant_batch_id?: Maybe<Scalars["String"]>;
  planted_date?: Maybe<Scalars["date"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type MetrcPlantBatchesMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  plant_batch_id?: Maybe<Scalars["String"]>;
  planted_date?: Maybe<Scalars["date"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type MetrcPlantBatchesMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  plant_batch_id?: Maybe<Scalars["String"]>;
  planted_date?: Maybe<Scalars["date"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "metrc_plant_batches" */
export type MetrcPlantBatchesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetrcPlantBatches>;
};

/** on_conflict condition type for table "metrc_plant_batches" */
export type MetrcPlantBatchesOnConflict = {
  constraint: MetrcPlantBatchesConstraint;
  update_columns?: Array<MetrcPlantBatchesUpdateColumn>;
  where?: Maybe<MetrcPlantBatchesBoolExp>;
};

/** Ordering options when selecting data from "metrc_plant_batches". */
export type MetrcPlantBatchesOrderBy = {
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_modified_at?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  payload?: Maybe<OrderBy>;
  plant_batch_id?: Maybe<OrderBy>;
  planted_date?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** primary key columns input for table: metrc_plant_batches */
export type MetrcPlantBatchesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "metrc_plant_batches" */
export enum MetrcPlantBatchesSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastModifiedAt = "last_modified_at",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  Name = "name",
  /** column name */
  Payload = "payload",
  /** column name */
  PlantBatchId = "plant_batch_id",
  /** column name */
  PlantedDate = "planted_date",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** input type for updating data in table "metrc_plant_batches" */
export type MetrcPlantBatchesSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  payload?: Maybe<Scalars["json"]>;
  plant_batch_id?: Maybe<Scalars["String"]>;
  planted_date?: Maybe<Scalars["date"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** update columns of table "metrc_plant_batches" */
export enum MetrcPlantBatchesUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastModifiedAt = "last_modified_at",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  Name = "name",
  /** column name */
  Payload = "payload",
  /** column name */
  PlantBatchId = "plant_batch_id",
  /** column name */
  PlantedDate = "planted_date",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** columns and relationships of "metrc_plants" */
export type MetrcPlants = {
  /** An object relationship */
  company: Companies;
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  label?: Maybe<Scalars["String"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  payload: Scalars["json"];
  plant_id: Scalars["String"];
  planted_date: Scalars["date"];
  type: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  us_state?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "metrc_plants" */
export type MetrcPlantsPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "metrc_plants" */
export type MetrcPlantsAggregate = {
  aggregate?: Maybe<MetrcPlantsAggregateFields>;
  nodes: Array<MetrcPlants>;
};

/** aggregate fields of "metrc_plants" */
export type MetrcPlantsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<MetrcPlantsMaxFields>;
  min?: Maybe<MetrcPlantsMinFields>;
};

/** aggregate fields of "metrc_plants" */
export type MetrcPlantsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MetrcPlantsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "metrc_plants". All fields are combined with a logical 'AND'. */
export type MetrcPlantsBoolExp = {
  _and?: Maybe<Array<MetrcPlantsBoolExp>>;
  _not?: Maybe<MetrcPlantsBoolExp>;
  _or?: Maybe<Array<MetrcPlantsBoolExp>>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  label?: Maybe<StringComparisonExp>;
  last_modified_at?: Maybe<TimestamptzComparisonExp>;
  license_number?: Maybe<StringComparisonExp>;
  payload?: Maybe<JsonComparisonExp>;
  plant_id?: Maybe<StringComparisonExp>;
  planted_date?: Maybe<DateComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  us_state?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "metrc_plants" */
export enum MetrcPlantsConstraint {
  /** unique or primary key constraint */
  MetrcPlantsPkey = "metrc_plants_pkey",
  /** unique or primary key constraint */
  MetrcPlantsUsStateLicenseNumberPlantIdKey = "metrc_plants_us_state_license_number_plant_id_key",
  /** unique or primary key constraint */
  MetrcPlantsUsStatePlantIdKey = "metrc_plants_us_state_plant_id_key",
}

/** input type for inserting data into table "metrc_plants" */
export type MetrcPlantsInsertInput = {
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  label?: Maybe<Scalars["String"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  payload?: Maybe<Scalars["json"]>;
  plant_id?: Maybe<Scalars["String"]>;
  planted_date?: Maybe<Scalars["date"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type MetrcPlantsMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  label?: Maybe<Scalars["String"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  plant_id?: Maybe<Scalars["String"]>;
  planted_date?: Maybe<Scalars["date"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type MetrcPlantsMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  label?: Maybe<Scalars["String"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  plant_id?: Maybe<Scalars["String"]>;
  planted_date?: Maybe<Scalars["date"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "metrc_plants" */
export type MetrcPlantsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetrcPlants>;
};

/** on_conflict condition type for table "metrc_plants" */
export type MetrcPlantsOnConflict = {
  constraint: MetrcPlantsConstraint;
  update_columns?: Array<MetrcPlantsUpdateColumn>;
  where?: Maybe<MetrcPlantsBoolExp>;
};

/** Ordering options when selecting data from "metrc_plants". */
export type MetrcPlantsOrderBy = {
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  label?: Maybe<OrderBy>;
  last_modified_at?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  payload?: Maybe<OrderBy>;
  plant_id?: Maybe<OrderBy>;
  planted_date?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** primary key columns input for table: metrc_plants */
export type MetrcPlantsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "metrc_plants" */
export enum MetrcPlantsSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
  /** column name */
  LastModifiedAt = "last_modified_at",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  Payload = "payload",
  /** column name */
  PlantId = "plant_id",
  /** column name */
  PlantedDate = "planted_date",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** input type for updating data in table "metrc_plants" */
export type MetrcPlantsSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  label?: Maybe<Scalars["String"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  payload?: Maybe<Scalars["json"]>;
  plant_id?: Maybe<Scalars["String"]>;
  planted_date?: Maybe<Scalars["date"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** update columns of table "metrc_plants" */
export enum MetrcPlantsUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
  /** column name */
  LastModifiedAt = "last_modified_at",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  Payload = "payload",
  /** column name */
  PlantId = "plant_id",
  /** column name */
  PlantedDate = "planted_date",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** columns and relationships of "metrc_sales_receipts" */
export type MetrcSalesReceipts = {
  /** An object relationship */
  company?: Maybe<Companies>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_final?: Maybe<Scalars["Boolean"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  /** fetch data from the table: "metrc_sales_transactions" */
  metrc_sales_transactions: Array<MetrcSalesTransactions>;
  /** An aggregate relationship */
  metrc_sales_transactions_aggregate: MetrcSalesTransactionsAggregate;
  payload: Scalars["json"];
  receipt_id?: Maybe<Scalars["String"]>;
  receipt_number: Scalars["String"];
  sales_customer_type: Scalars["String"];
  sales_datetime: Scalars["timestamptz"];
  total_packages: Scalars["Int"];
  total_price: Scalars["numeric"];
  type: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  us_state: Scalars["String"];
};

/** columns and relationships of "metrc_sales_receipts" */
export type MetrcSalesReceiptsMetrcSalesTransactionsArgs = {
  distinct_on?: Maybe<Array<MetrcSalesTransactionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcSalesTransactionsOrderBy>>;
  where?: Maybe<MetrcSalesTransactionsBoolExp>;
};

/** columns and relationships of "metrc_sales_receipts" */
export type MetrcSalesReceiptsMetrcSalesTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcSalesTransactionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcSalesTransactionsOrderBy>>;
  where?: Maybe<MetrcSalesTransactionsBoolExp>;
};

/** columns and relationships of "metrc_sales_receipts" */
export type MetrcSalesReceiptsPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "metrc_sales_receipts" */
export type MetrcSalesReceiptsAggregate = {
  aggregate?: Maybe<MetrcSalesReceiptsAggregateFields>;
  nodes: Array<MetrcSalesReceipts>;
};

/** aggregate fields of "metrc_sales_receipts" */
export type MetrcSalesReceiptsAggregateFields = {
  avg?: Maybe<MetrcSalesReceiptsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<MetrcSalesReceiptsMaxFields>;
  min?: Maybe<MetrcSalesReceiptsMinFields>;
  stddev?: Maybe<MetrcSalesReceiptsStddevFields>;
  stddev_pop?: Maybe<MetrcSalesReceiptsStddevPopFields>;
  stddev_samp?: Maybe<MetrcSalesReceiptsStddevSampFields>;
  sum?: Maybe<MetrcSalesReceiptsSumFields>;
  var_pop?: Maybe<MetrcSalesReceiptsVarPopFields>;
  var_samp?: Maybe<MetrcSalesReceiptsVarSampFields>;
  variance?: Maybe<MetrcSalesReceiptsVarianceFields>;
};

/** aggregate fields of "metrc_sales_receipts" */
export type MetrcSalesReceiptsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MetrcSalesReceiptsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type MetrcSalesReceiptsAvgFields = {
  total_packages?: Maybe<Scalars["Float"]>;
  total_price?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "metrc_sales_receipts". All fields are combined with a logical 'AND'. */
export type MetrcSalesReceiptsBoolExp = {
  _and?: Maybe<Array<MetrcSalesReceiptsBoolExp>>;
  _not?: Maybe<MetrcSalesReceiptsBoolExp>;
  _or?: Maybe<Array<MetrcSalesReceiptsBoolExp>>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_final?: Maybe<BooleanComparisonExp>;
  last_modified_at?: Maybe<TimestamptzComparisonExp>;
  license_number?: Maybe<StringComparisonExp>;
  metrc_sales_transactions?: Maybe<MetrcSalesTransactionsBoolExp>;
  payload?: Maybe<JsonComparisonExp>;
  receipt_id?: Maybe<StringComparisonExp>;
  receipt_number?: Maybe<StringComparisonExp>;
  sales_customer_type?: Maybe<StringComparisonExp>;
  sales_datetime?: Maybe<TimestamptzComparisonExp>;
  total_packages?: Maybe<IntComparisonExp>;
  total_price?: Maybe<NumericComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  us_state?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "metrc_sales_receipts" */
export enum MetrcSalesReceiptsConstraint {
  /** unique or primary key constraint */
  MetrcSalesReceiptsPkey = "metrc_sales_receipts_pkey",
  /** unique or primary key constraint */
  MetrcSalesReceiptsUsStateLicenseNumberReceiptIdKey = "metrc_sales_receipts_us_state_license_number_receipt_id_key",
  /** unique or primary key constraint */
  MetrcSalesReceiptsUsStateReceiptIdKey = "metrc_sales_receipts_us_state_receipt_id_key",
}

/** input type for incrementing numeric columns in table "metrc_sales_receipts" */
export type MetrcSalesReceiptsIncInput = {
  total_packages?: Maybe<Scalars["Int"]>;
  total_price?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "metrc_sales_receipts" */
export type MetrcSalesReceiptsInsertInput = {
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_final?: Maybe<Scalars["Boolean"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  metrc_sales_transactions?: Maybe<MetrcSalesTransactionsArrRelInsertInput>;
  payload?: Maybe<Scalars["json"]>;
  receipt_id?: Maybe<Scalars["String"]>;
  receipt_number?: Maybe<Scalars["String"]>;
  sales_customer_type?: Maybe<Scalars["String"]>;
  sales_datetime?: Maybe<Scalars["timestamptz"]>;
  total_packages?: Maybe<Scalars["Int"]>;
  total_price?: Maybe<Scalars["numeric"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type MetrcSalesReceiptsMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  receipt_id?: Maybe<Scalars["String"]>;
  receipt_number?: Maybe<Scalars["String"]>;
  sales_customer_type?: Maybe<Scalars["String"]>;
  sales_datetime?: Maybe<Scalars["timestamptz"]>;
  total_packages?: Maybe<Scalars["Int"]>;
  total_price?: Maybe<Scalars["numeric"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type MetrcSalesReceiptsMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  receipt_id?: Maybe<Scalars["String"]>;
  receipt_number?: Maybe<Scalars["String"]>;
  sales_customer_type?: Maybe<Scalars["String"]>;
  sales_datetime?: Maybe<Scalars["timestamptz"]>;
  total_packages?: Maybe<Scalars["Int"]>;
  total_price?: Maybe<Scalars["numeric"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "metrc_sales_receipts" */
export type MetrcSalesReceiptsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetrcSalesReceipts>;
};

/** input type for inserting object relation for remote table "metrc_sales_receipts" */
export type MetrcSalesReceiptsObjRelInsertInput = {
  data: MetrcSalesReceiptsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<MetrcSalesReceiptsOnConflict>;
};

/** on_conflict condition type for table "metrc_sales_receipts" */
export type MetrcSalesReceiptsOnConflict = {
  constraint: MetrcSalesReceiptsConstraint;
  update_columns?: Array<MetrcSalesReceiptsUpdateColumn>;
  where?: Maybe<MetrcSalesReceiptsBoolExp>;
};

/** Ordering options when selecting data from "metrc_sales_receipts". */
export type MetrcSalesReceiptsOrderBy = {
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_final?: Maybe<OrderBy>;
  last_modified_at?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  metrc_sales_transactions_aggregate?: Maybe<MetrcSalesTransactionsAggregateOrderBy>;
  payload?: Maybe<OrderBy>;
  receipt_id?: Maybe<OrderBy>;
  receipt_number?: Maybe<OrderBy>;
  sales_customer_type?: Maybe<OrderBy>;
  sales_datetime?: Maybe<OrderBy>;
  total_packages?: Maybe<OrderBy>;
  total_price?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** primary key columns input for table: metrc_sales_receipts */
export type MetrcSalesReceiptsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "metrc_sales_receipts" */
export enum MetrcSalesReceiptsSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsFinal = "is_final",
  /** column name */
  LastModifiedAt = "last_modified_at",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  Payload = "payload",
  /** column name */
  ReceiptId = "receipt_id",
  /** column name */
  ReceiptNumber = "receipt_number",
  /** column name */
  SalesCustomerType = "sales_customer_type",
  /** column name */
  SalesDatetime = "sales_datetime",
  /** column name */
  TotalPackages = "total_packages",
  /** column name */
  TotalPrice = "total_price",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** input type for updating data in table "metrc_sales_receipts" */
export type MetrcSalesReceiptsSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_final?: Maybe<Scalars["Boolean"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  payload?: Maybe<Scalars["json"]>;
  receipt_id?: Maybe<Scalars["String"]>;
  receipt_number?: Maybe<Scalars["String"]>;
  sales_customer_type?: Maybe<Scalars["String"]>;
  sales_datetime?: Maybe<Scalars["timestamptz"]>;
  total_packages?: Maybe<Scalars["Int"]>;
  total_price?: Maybe<Scalars["numeric"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type MetrcSalesReceiptsStddevFields = {
  total_packages?: Maybe<Scalars["Float"]>;
  total_price?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type MetrcSalesReceiptsStddevPopFields = {
  total_packages?: Maybe<Scalars["Float"]>;
  total_price?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type MetrcSalesReceiptsStddevSampFields = {
  total_packages?: Maybe<Scalars["Float"]>;
  total_price?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type MetrcSalesReceiptsSumFields = {
  total_packages?: Maybe<Scalars["Int"]>;
  total_price?: Maybe<Scalars["numeric"]>;
};

/** update columns of table "metrc_sales_receipts" */
export enum MetrcSalesReceiptsUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsFinal = "is_final",
  /** column name */
  LastModifiedAt = "last_modified_at",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  Payload = "payload",
  /** column name */
  ReceiptId = "receipt_id",
  /** column name */
  ReceiptNumber = "receipt_number",
  /** column name */
  SalesCustomerType = "sales_customer_type",
  /** column name */
  SalesDatetime = "sales_datetime",
  /** column name */
  TotalPackages = "total_packages",
  /** column name */
  TotalPrice = "total_price",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** aggregate var_pop on columns */
export type MetrcSalesReceiptsVarPopFields = {
  total_packages?: Maybe<Scalars["Float"]>;
  total_price?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type MetrcSalesReceiptsVarSampFields = {
  total_packages?: Maybe<Scalars["Float"]>;
  total_price?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type MetrcSalesReceiptsVarianceFields = {
  total_packages?: Maybe<Scalars["Float"]>;
  total_price?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "metrc_sales_transactions" */
export type MetrcSalesTransactions = {
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_deleted?: Maybe<Scalars["Boolean"]>;
  last_modified_at: Scalars["timestamptz"];
  license_number?: Maybe<Scalars["String"]>;
  /** An object relationship */
  metrc_sales_receipt?: Maybe<MetrcSalesReceipts>;
  package_id: Scalars["String"];
  package_label: Scalars["String"];
  payload: Scalars["json"];
  product_category_name: Scalars["String"];
  product_name: Scalars["String"];
  quantity_sold: Scalars["numeric"];
  receipt_id?: Maybe<Scalars["String"]>;
  receipt_row_id?: Maybe<Scalars["uuid"]>;
  recorded_datetime: Scalars["timestamptz"];
  total_price: Scalars["numeric"];
  type: Scalars["String"];
  unit_of_measure: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  us_state: Scalars["String"];
};

/** columns and relationships of "metrc_sales_transactions" */
export type MetrcSalesTransactionsPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "metrc_sales_transactions" */
export type MetrcSalesTransactionsAggregate = {
  aggregate?: Maybe<MetrcSalesTransactionsAggregateFields>;
  nodes: Array<MetrcSalesTransactions>;
};

/** aggregate fields of "metrc_sales_transactions" */
export type MetrcSalesTransactionsAggregateFields = {
  avg?: Maybe<MetrcSalesTransactionsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<MetrcSalesTransactionsMaxFields>;
  min?: Maybe<MetrcSalesTransactionsMinFields>;
  stddev?: Maybe<MetrcSalesTransactionsStddevFields>;
  stddev_pop?: Maybe<MetrcSalesTransactionsStddevPopFields>;
  stddev_samp?: Maybe<MetrcSalesTransactionsStddevSampFields>;
  sum?: Maybe<MetrcSalesTransactionsSumFields>;
  var_pop?: Maybe<MetrcSalesTransactionsVarPopFields>;
  var_samp?: Maybe<MetrcSalesTransactionsVarSampFields>;
  variance?: Maybe<MetrcSalesTransactionsVarianceFields>;
};

/** aggregate fields of "metrc_sales_transactions" */
export type MetrcSalesTransactionsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MetrcSalesTransactionsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "metrc_sales_transactions" */
export type MetrcSalesTransactionsAggregateOrderBy = {
  avg?: Maybe<MetrcSalesTransactionsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<MetrcSalesTransactionsMaxOrderBy>;
  min?: Maybe<MetrcSalesTransactionsMinOrderBy>;
  stddev?: Maybe<MetrcSalesTransactionsStddevOrderBy>;
  stddev_pop?: Maybe<MetrcSalesTransactionsStddevPopOrderBy>;
  stddev_samp?: Maybe<MetrcSalesTransactionsStddevSampOrderBy>;
  sum?: Maybe<MetrcSalesTransactionsSumOrderBy>;
  var_pop?: Maybe<MetrcSalesTransactionsVarPopOrderBy>;
  var_samp?: Maybe<MetrcSalesTransactionsVarSampOrderBy>;
  variance?: Maybe<MetrcSalesTransactionsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "metrc_sales_transactions" */
export type MetrcSalesTransactionsArrRelInsertInput = {
  data: Array<MetrcSalesTransactionsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<MetrcSalesTransactionsOnConflict>;
};

/** aggregate avg on columns */
export type MetrcSalesTransactionsAvgFields = {
  quantity_sold?: Maybe<Scalars["Float"]>;
  total_price?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "metrc_sales_transactions" */
export type MetrcSalesTransactionsAvgOrderBy = {
  quantity_sold?: Maybe<OrderBy>;
  total_price?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "metrc_sales_transactions". All fields are combined with a logical 'AND'. */
export type MetrcSalesTransactionsBoolExp = {
  _and?: Maybe<Array<MetrcSalesTransactionsBoolExp>>;
  _not?: Maybe<MetrcSalesTransactionsBoolExp>;
  _or?: Maybe<Array<MetrcSalesTransactionsBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  last_modified_at?: Maybe<TimestamptzComparisonExp>;
  license_number?: Maybe<StringComparisonExp>;
  metrc_sales_receipt?: Maybe<MetrcSalesReceiptsBoolExp>;
  package_id?: Maybe<StringComparisonExp>;
  package_label?: Maybe<StringComparisonExp>;
  payload?: Maybe<JsonComparisonExp>;
  product_category_name?: Maybe<StringComparisonExp>;
  product_name?: Maybe<StringComparisonExp>;
  quantity_sold?: Maybe<NumericComparisonExp>;
  receipt_id?: Maybe<StringComparisonExp>;
  receipt_row_id?: Maybe<UuidComparisonExp>;
  recorded_datetime?: Maybe<TimestamptzComparisonExp>;
  total_price?: Maybe<NumericComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  unit_of_measure?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  us_state?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "metrc_sales_transactions" */
export enum MetrcSalesTransactionsConstraint {
  /** unique or primary key constraint */
  MetrcSalesTransactionsPkey = "metrc_sales_transactions_pkey",
}

/** input type for incrementing numeric columns in table "metrc_sales_transactions" */
export type MetrcSalesTransactionsIncInput = {
  quantity_sold?: Maybe<Scalars["numeric"]>;
  total_price?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "metrc_sales_transactions" */
export type MetrcSalesTransactionsInsertInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  metrc_sales_receipt?: Maybe<MetrcSalesReceiptsObjRelInsertInput>;
  package_id?: Maybe<Scalars["String"]>;
  package_label?: Maybe<Scalars["String"]>;
  payload?: Maybe<Scalars["json"]>;
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  quantity_sold?: Maybe<Scalars["numeric"]>;
  receipt_id?: Maybe<Scalars["String"]>;
  receipt_row_id?: Maybe<Scalars["uuid"]>;
  recorded_datetime?: Maybe<Scalars["timestamptz"]>;
  total_price?: Maybe<Scalars["numeric"]>;
  type?: Maybe<Scalars["String"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type MetrcSalesTransactionsMaxFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  package_id?: Maybe<Scalars["String"]>;
  package_label?: Maybe<Scalars["String"]>;
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  quantity_sold?: Maybe<Scalars["numeric"]>;
  receipt_id?: Maybe<Scalars["String"]>;
  receipt_row_id?: Maybe<Scalars["uuid"]>;
  recorded_datetime?: Maybe<Scalars["timestamptz"]>;
  total_price?: Maybe<Scalars["numeric"]>;
  type?: Maybe<Scalars["String"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "metrc_sales_transactions" */
export type MetrcSalesTransactionsMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_modified_at?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  package_id?: Maybe<OrderBy>;
  package_label?: Maybe<OrderBy>;
  product_category_name?: Maybe<OrderBy>;
  product_name?: Maybe<OrderBy>;
  quantity_sold?: Maybe<OrderBy>;
  receipt_id?: Maybe<OrderBy>;
  receipt_row_id?: Maybe<OrderBy>;
  recorded_datetime?: Maybe<OrderBy>;
  total_price?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  unit_of_measure?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type MetrcSalesTransactionsMinFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  package_id?: Maybe<Scalars["String"]>;
  package_label?: Maybe<Scalars["String"]>;
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  quantity_sold?: Maybe<Scalars["numeric"]>;
  receipt_id?: Maybe<Scalars["String"]>;
  receipt_row_id?: Maybe<Scalars["uuid"]>;
  recorded_datetime?: Maybe<Scalars["timestamptz"]>;
  total_price?: Maybe<Scalars["numeric"]>;
  type?: Maybe<Scalars["String"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "metrc_sales_transactions" */
export type MetrcSalesTransactionsMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_modified_at?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  package_id?: Maybe<OrderBy>;
  package_label?: Maybe<OrderBy>;
  product_category_name?: Maybe<OrderBy>;
  product_name?: Maybe<OrderBy>;
  quantity_sold?: Maybe<OrderBy>;
  receipt_id?: Maybe<OrderBy>;
  receipt_row_id?: Maybe<OrderBy>;
  recorded_datetime?: Maybe<OrderBy>;
  total_price?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  unit_of_measure?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** response of any mutation on the table "metrc_sales_transactions" */
export type MetrcSalesTransactionsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetrcSalesTransactions>;
};

/** on_conflict condition type for table "metrc_sales_transactions" */
export type MetrcSalesTransactionsOnConflict = {
  constraint: MetrcSalesTransactionsConstraint;
  update_columns?: Array<MetrcSalesTransactionsUpdateColumn>;
  where?: Maybe<MetrcSalesTransactionsBoolExp>;
};

/** Ordering options when selecting data from "metrc_sales_transactions". */
export type MetrcSalesTransactionsOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  last_modified_at?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  metrc_sales_receipt?: Maybe<MetrcSalesReceiptsOrderBy>;
  package_id?: Maybe<OrderBy>;
  package_label?: Maybe<OrderBy>;
  payload?: Maybe<OrderBy>;
  product_category_name?: Maybe<OrderBy>;
  product_name?: Maybe<OrderBy>;
  quantity_sold?: Maybe<OrderBy>;
  receipt_id?: Maybe<OrderBy>;
  receipt_row_id?: Maybe<OrderBy>;
  recorded_datetime?: Maybe<OrderBy>;
  total_price?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  unit_of_measure?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** primary key columns input for table: metrc_sales_transactions */
export type MetrcSalesTransactionsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "metrc_sales_transactions" */
export enum MetrcSalesTransactionsSelectColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  LastModifiedAt = "last_modified_at",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  PackageId = "package_id",
  /** column name */
  PackageLabel = "package_label",
  /** column name */
  Payload = "payload",
  /** column name */
  ProductCategoryName = "product_category_name",
  /** column name */
  ProductName = "product_name",
  /** column name */
  QuantitySold = "quantity_sold",
  /** column name */
  ReceiptId = "receipt_id",
  /** column name */
  ReceiptRowId = "receipt_row_id",
  /** column name */
  RecordedDatetime = "recorded_datetime",
  /** column name */
  TotalPrice = "total_price",
  /** column name */
  Type = "type",
  /** column name */
  UnitOfMeasure = "unit_of_measure",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** input type for updating data in table "metrc_sales_transactions" */
export type MetrcSalesTransactionsSetInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  package_id?: Maybe<Scalars["String"]>;
  package_label?: Maybe<Scalars["String"]>;
  payload?: Maybe<Scalars["json"]>;
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  quantity_sold?: Maybe<Scalars["numeric"]>;
  receipt_id?: Maybe<Scalars["String"]>;
  receipt_row_id?: Maybe<Scalars["uuid"]>;
  recorded_datetime?: Maybe<Scalars["timestamptz"]>;
  total_price?: Maybe<Scalars["numeric"]>;
  type?: Maybe<Scalars["String"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type MetrcSalesTransactionsStddevFields = {
  quantity_sold?: Maybe<Scalars["Float"]>;
  total_price?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "metrc_sales_transactions" */
export type MetrcSalesTransactionsStddevOrderBy = {
  quantity_sold?: Maybe<OrderBy>;
  total_price?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type MetrcSalesTransactionsStddevPopFields = {
  quantity_sold?: Maybe<Scalars["Float"]>;
  total_price?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "metrc_sales_transactions" */
export type MetrcSalesTransactionsStddevPopOrderBy = {
  quantity_sold?: Maybe<OrderBy>;
  total_price?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type MetrcSalesTransactionsStddevSampFields = {
  quantity_sold?: Maybe<Scalars["Float"]>;
  total_price?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "metrc_sales_transactions" */
export type MetrcSalesTransactionsStddevSampOrderBy = {
  quantity_sold?: Maybe<OrderBy>;
  total_price?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type MetrcSalesTransactionsSumFields = {
  quantity_sold?: Maybe<Scalars["numeric"]>;
  total_price?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "metrc_sales_transactions" */
export type MetrcSalesTransactionsSumOrderBy = {
  quantity_sold?: Maybe<OrderBy>;
  total_price?: Maybe<OrderBy>;
};

/** update columns of table "metrc_sales_transactions" */
export enum MetrcSalesTransactionsUpdateColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  LastModifiedAt = "last_modified_at",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  PackageId = "package_id",
  /** column name */
  PackageLabel = "package_label",
  /** column name */
  Payload = "payload",
  /** column name */
  ProductCategoryName = "product_category_name",
  /** column name */
  ProductName = "product_name",
  /** column name */
  QuantitySold = "quantity_sold",
  /** column name */
  ReceiptId = "receipt_id",
  /** column name */
  ReceiptRowId = "receipt_row_id",
  /** column name */
  RecordedDatetime = "recorded_datetime",
  /** column name */
  TotalPrice = "total_price",
  /** column name */
  Type = "type",
  /** column name */
  UnitOfMeasure = "unit_of_measure",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** aggregate var_pop on columns */
export type MetrcSalesTransactionsVarPopFields = {
  quantity_sold?: Maybe<Scalars["Float"]>;
  total_price?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "metrc_sales_transactions" */
export type MetrcSalesTransactionsVarPopOrderBy = {
  quantity_sold?: Maybe<OrderBy>;
  total_price?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type MetrcSalesTransactionsVarSampFields = {
  quantity_sold?: Maybe<Scalars["Float"]>;
  total_price?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "metrc_sales_transactions" */
export type MetrcSalesTransactionsVarSampOrderBy = {
  quantity_sold?: Maybe<OrderBy>;
  total_price?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type MetrcSalesTransactionsVarianceFields = {
  quantity_sold?: Maybe<Scalars["Float"]>;
  total_price?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "metrc_sales_transactions" */
export type MetrcSalesTransactionsVarianceOrderBy = {
  quantity_sold?: Maybe<OrderBy>;
  total_price?: Maybe<OrderBy>;
};

/** columns and relationships of "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkus = {
  /** An object relationship */
  bespoke_catalog_sku?: Maybe<BespokeCatalogSkus>;
  bespoke_catalog_sku_id?: Maybe<Scalars["uuid"]>;
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  is_deleted: Scalars["Boolean"];
  is_sample: Scalars["Boolean"];
  last_edited_by_user_id?: Maybe<Scalars["uuid"]>;
  product_category_name: Scalars["String"];
  product_name: Scalars["String"];
  sku_confidence: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  user?: Maybe<Users>;
  wholesale_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregated selection of "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusAggregate = {
  aggregate?: Maybe<MetrcToBespokeCatalogSkusAggregateFields>;
  nodes: Array<MetrcToBespokeCatalogSkus>;
};

/** aggregate fields of "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusAggregateFields = {
  avg?: Maybe<MetrcToBespokeCatalogSkusAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<MetrcToBespokeCatalogSkusMaxFields>;
  min?: Maybe<MetrcToBespokeCatalogSkusMinFields>;
  stddev?: Maybe<MetrcToBespokeCatalogSkusStddevFields>;
  stddev_pop?: Maybe<MetrcToBespokeCatalogSkusStddevPopFields>;
  stddev_samp?: Maybe<MetrcToBespokeCatalogSkusStddevSampFields>;
  sum?: Maybe<MetrcToBespokeCatalogSkusSumFields>;
  var_pop?: Maybe<MetrcToBespokeCatalogSkusVarPopFields>;
  var_samp?: Maybe<MetrcToBespokeCatalogSkusVarSampFields>;
  variance?: Maybe<MetrcToBespokeCatalogSkusVarianceFields>;
};

/** aggregate fields of "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MetrcToBespokeCatalogSkusSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusAggregateOrderBy = {
  avg?: Maybe<MetrcToBespokeCatalogSkusAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<MetrcToBespokeCatalogSkusMaxOrderBy>;
  min?: Maybe<MetrcToBespokeCatalogSkusMinOrderBy>;
  stddev?: Maybe<MetrcToBespokeCatalogSkusStddevOrderBy>;
  stddev_pop?: Maybe<MetrcToBespokeCatalogSkusStddevPopOrderBy>;
  stddev_samp?: Maybe<MetrcToBespokeCatalogSkusStddevSampOrderBy>;
  sum?: Maybe<MetrcToBespokeCatalogSkusSumOrderBy>;
  var_pop?: Maybe<MetrcToBespokeCatalogSkusVarPopOrderBy>;
  var_samp?: Maybe<MetrcToBespokeCatalogSkusVarSampOrderBy>;
  variance?: Maybe<MetrcToBespokeCatalogSkusVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusArrRelInsertInput = {
  data: Array<MetrcToBespokeCatalogSkusInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<MetrcToBespokeCatalogSkusOnConflict>;
};

/** aggregate avg on columns */
export type MetrcToBespokeCatalogSkusAvgFields = {
  wholesale_quantity?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusAvgOrderBy = {
  wholesale_quantity?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "metrc_to_bespoke_catalog_skus". All fields are combined with a logical 'AND'. */
export type MetrcToBespokeCatalogSkusBoolExp = {
  _and?: Maybe<Array<MetrcToBespokeCatalogSkusBoolExp>>;
  _not?: Maybe<MetrcToBespokeCatalogSkusBoolExp>;
  _or?: Maybe<Array<MetrcToBespokeCatalogSkusBoolExp>>;
  bespoke_catalog_sku?: Maybe<BespokeCatalogSkusBoolExp>;
  bespoke_catalog_sku_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  is_sample?: Maybe<BooleanComparisonExp>;
  last_edited_by_user_id?: Maybe<UuidComparisonExp>;
  product_category_name?: Maybe<StringComparisonExp>;
  product_name?: Maybe<StringComparisonExp>;
  sku_confidence?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<UsersBoolExp>;
  wholesale_quantity?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "metrc_to_bespoke_catalog_skus" */
export enum MetrcToBespokeCatalogSkusConstraint {
  /** unique or primary key constraint */
  MetrcToBespokeCatalogSkusPkey = "metrc_to_bespoke_catalog_skus_pkey",
  /** unique or primary key constraint */
  MetrcToBespokeCatalogSkusProductCategoryNameProductNam = "metrc_to_bespoke_catalog_skus_product_category_name_product_nam",
}

/** input type for incrementing numeric columns in table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusIncInput = {
  wholesale_quantity?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusInsertInput = {
  bespoke_catalog_sku?: Maybe<BespokeCatalogSkusObjRelInsertInput>;
  bespoke_catalog_sku_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  is_sample?: Maybe<Scalars["Boolean"]>;
  last_edited_by_user_id?: Maybe<Scalars["uuid"]>;
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  sku_confidence?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user?: Maybe<UsersObjRelInsertInput>;
  wholesale_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type MetrcToBespokeCatalogSkusMaxFields = {
  bespoke_catalog_sku_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_edited_by_user_id?: Maybe<Scalars["uuid"]>;
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  sku_confidence?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  wholesale_quantity?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusMaxOrderBy = {
  bespoke_catalog_sku_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_edited_by_user_id?: Maybe<OrderBy>;
  product_category_name?: Maybe<OrderBy>;
  product_name?: Maybe<OrderBy>;
  sku_confidence?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  wholesale_quantity?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type MetrcToBespokeCatalogSkusMinFields = {
  bespoke_catalog_sku_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_edited_by_user_id?: Maybe<Scalars["uuid"]>;
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  sku_confidence?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  wholesale_quantity?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusMinOrderBy = {
  bespoke_catalog_sku_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_edited_by_user_id?: Maybe<OrderBy>;
  product_category_name?: Maybe<OrderBy>;
  product_name?: Maybe<OrderBy>;
  sku_confidence?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  wholesale_quantity?: Maybe<OrderBy>;
};

/** response of any mutation on the table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetrcToBespokeCatalogSkus>;
};

/** on_conflict condition type for table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusOnConflict = {
  constraint: MetrcToBespokeCatalogSkusConstraint;
  update_columns?: Array<MetrcToBespokeCatalogSkusUpdateColumn>;
  where?: Maybe<MetrcToBespokeCatalogSkusBoolExp>;
};

/** Ordering options when selecting data from "metrc_to_bespoke_catalog_skus". */
export type MetrcToBespokeCatalogSkusOrderBy = {
  bespoke_catalog_sku?: Maybe<BespokeCatalogSkusOrderBy>;
  bespoke_catalog_sku_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  is_sample?: Maybe<OrderBy>;
  last_edited_by_user_id?: Maybe<OrderBy>;
  product_category_name?: Maybe<OrderBy>;
  product_name?: Maybe<OrderBy>;
  sku_confidence?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
  wholesale_quantity?: Maybe<OrderBy>;
};

/** primary key columns input for table: metrc_to_bespoke_catalog_skus */
export type MetrcToBespokeCatalogSkusPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "metrc_to_bespoke_catalog_skus" */
export enum MetrcToBespokeCatalogSkusSelectColumn {
  /** column name */
  BespokeCatalogSkuId = "bespoke_catalog_sku_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  IsSample = "is_sample",
  /** column name */
  LastEditedByUserId = "last_edited_by_user_id",
  /** column name */
  ProductCategoryName = "product_category_name",
  /** column name */
  ProductName = "product_name",
  /** column name */
  SkuConfidence = "sku_confidence",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WholesaleQuantity = "wholesale_quantity",
}

/** input type for updating data in table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusSetInput = {
  bespoke_catalog_sku_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  is_sample?: Maybe<Scalars["Boolean"]>;
  last_edited_by_user_id?: Maybe<Scalars["uuid"]>;
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  sku_confidence?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  wholesale_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type MetrcToBespokeCatalogSkusStddevFields = {
  wholesale_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusStddevOrderBy = {
  wholesale_quantity?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type MetrcToBespokeCatalogSkusStddevPopFields = {
  wholesale_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusStddevPopOrderBy = {
  wholesale_quantity?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type MetrcToBespokeCatalogSkusStddevSampFields = {
  wholesale_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusStddevSampOrderBy = {
  wholesale_quantity?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type MetrcToBespokeCatalogSkusSumFields = {
  wholesale_quantity?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusSumOrderBy = {
  wholesale_quantity?: Maybe<OrderBy>;
};

/** update columns of table "metrc_to_bespoke_catalog_skus" */
export enum MetrcToBespokeCatalogSkusUpdateColumn {
  /** column name */
  BespokeCatalogSkuId = "bespoke_catalog_sku_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  IsSample = "is_sample",
  /** column name */
  LastEditedByUserId = "last_edited_by_user_id",
  /** column name */
  ProductCategoryName = "product_category_name",
  /** column name */
  ProductName = "product_name",
  /** column name */
  SkuConfidence = "sku_confidence",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WholesaleQuantity = "wholesale_quantity",
}

/** aggregate var_pop on columns */
export type MetrcToBespokeCatalogSkusVarPopFields = {
  wholesale_quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusVarPopOrderBy = {
  wholesale_quantity?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type MetrcToBespokeCatalogSkusVarSampFields = {
  wholesale_quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusVarSampOrderBy = {
  wholesale_quantity?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type MetrcToBespokeCatalogSkusVarianceFields = {
  wholesale_quantity?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "metrc_to_bespoke_catalog_skus" */
export type MetrcToBespokeCatalogSkusVarianceOrderBy = {
  wholesale_quantity?: Maybe<OrderBy>;
};

/** columns and relationships of "metrc_transfer_packages" */
export type MetrcTransferPackages = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at: Scalars["timestamptz"];
  created_date?: Maybe<Scalars["date"]>;
  /** From Metrc */
  delivery_id?: Maybe<Scalars["String"]>;
  delivery_row_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["uuid"];
  lab_results_status: Scalars["String"];
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  /** An object relationship */
  metrc_delivery?: Maybe<MetrcDeliveries>;
  /** An object relationship */
  metrc_transfer: MetrcTransfers;
  /** From Metrc */
  package_id: Scalars["String"];
  package_label: Scalars["String"];
  package_payload: Scalars["json"];
  package_type: Scalars["String"];
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  received_quantity?: Maybe<Scalars["numeric"]>;
  received_unit_of_measure?: Maybe<Scalars["String"]>;
  shipment_package_state?: Maybe<Scalars["String"]>;
  shipped_quantity?: Maybe<Scalars["numeric"]>;
  shipped_unit_of_measure?: Maybe<Scalars["String"]>;
  shipper_wholesale_price?: Maybe<Scalars["numeric"]>;
  transfer_row_id: Scalars["uuid"];
  type: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  us_state: Scalars["String"];
};

/** columns and relationships of "metrc_transfer_packages" */
export type MetrcTransferPackagesPackagePayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "metrc_transfer_packages" */
export type MetrcTransferPackagesAggregate = {
  aggregate?: Maybe<MetrcTransferPackagesAggregateFields>;
  nodes: Array<MetrcTransferPackages>;
};

/** aggregate fields of "metrc_transfer_packages" */
export type MetrcTransferPackagesAggregateFields = {
  avg?: Maybe<MetrcTransferPackagesAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<MetrcTransferPackagesMaxFields>;
  min?: Maybe<MetrcTransferPackagesMinFields>;
  stddev?: Maybe<MetrcTransferPackagesStddevFields>;
  stddev_pop?: Maybe<MetrcTransferPackagesStddevPopFields>;
  stddev_samp?: Maybe<MetrcTransferPackagesStddevSampFields>;
  sum?: Maybe<MetrcTransferPackagesSumFields>;
  var_pop?: Maybe<MetrcTransferPackagesVarPopFields>;
  var_samp?: Maybe<MetrcTransferPackagesVarSampFields>;
  variance?: Maybe<MetrcTransferPackagesVarianceFields>;
};

/** aggregate fields of "metrc_transfer_packages" */
export type MetrcTransferPackagesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MetrcTransferPackagesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "metrc_transfer_packages" */
export type MetrcTransferPackagesAggregateOrderBy = {
  avg?: Maybe<MetrcTransferPackagesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<MetrcTransferPackagesMaxOrderBy>;
  min?: Maybe<MetrcTransferPackagesMinOrderBy>;
  stddev?: Maybe<MetrcTransferPackagesStddevOrderBy>;
  stddev_pop?: Maybe<MetrcTransferPackagesStddevPopOrderBy>;
  stddev_samp?: Maybe<MetrcTransferPackagesStddevSampOrderBy>;
  sum?: Maybe<MetrcTransferPackagesSumOrderBy>;
  var_pop?: Maybe<MetrcTransferPackagesVarPopOrderBy>;
  var_samp?: Maybe<MetrcTransferPackagesVarSampOrderBy>;
  variance?: Maybe<MetrcTransferPackagesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "metrc_transfer_packages" */
export type MetrcTransferPackagesArrRelInsertInput = {
  data: Array<MetrcTransferPackagesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<MetrcTransferPackagesOnConflict>;
};

/** aggregate avg on columns */
export type MetrcTransferPackagesAvgFields = {
  received_quantity?: Maybe<Scalars["Float"]>;
  shipped_quantity?: Maybe<Scalars["Float"]>;
  shipper_wholesale_price?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "metrc_transfer_packages" */
export type MetrcTransferPackagesAvgOrderBy = {
  received_quantity?: Maybe<OrderBy>;
  shipped_quantity?: Maybe<OrderBy>;
  shipper_wholesale_price?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "metrc_transfer_packages". All fields are combined with a logical 'AND'. */
export type MetrcTransferPackagesBoolExp = {
  _and?: Maybe<Array<MetrcTransferPackagesBoolExp>>;
  _not?: Maybe<MetrcTransferPackagesBoolExp>;
  _or?: Maybe<Array<MetrcTransferPackagesBoolExp>>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_date?: Maybe<DateComparisonExp>;
  delivery_id?: Maybe<StringComparisonExp>;
  delivery_row_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  lab_results_status?: Maybe<StringComparisonExp>;
  last_modified_at?: Maybe<TimestamptzComparisonExp>;
  license_number?: Maybe<StringComparisonExp>;
  metrc_delivery?: Maybe<MetrcDeliveriesBoolExp>;
  metrc_transfer?: Maybe<MetrcTransfersBoolExp>;
  package_id?: Maybe<StringComparisonExp>;
  package_label?: Maybe<StringComparisonExp>;
  package_payload?: Maybe<JsonComparisonExp>;
  package_type?: Maybe<StringComparisonExp>;
  product_category_name?: Maybe<StringComparisonExp>;
  product_name?: Maybe<StringComparisonExp>;
  received_quantity?: Maybe<NumericComparisonExp>;
  received_unit_of_measure?: Maybe<StringComparisonExp>;
  shipment_package_state?: Maybe<StringComparisonExp>;
  shipped_quantity?: Maybe<NumericComparisonExp>;
  shipped_unit_of_measure?: Maybe<StringComparisonExp>;
  shipper_wholesale_price?: Maybe<NumericComparisonExp>;
  transfer_row_id?: Maybe<UuidComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  us_state?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "metrc_transfer_packages" */
export enum MetrcTransferPackagesConstraint {
  /** unique or primary key constraint */
  MetrcTransferPackagesDeliveryIdPackageIdKey = "metrc_transfer_packages_delivery_id_package_id_key",
  /** unique or primary key constraint */
  MetrcTransferPackagesPkey = "metrc_transfer_packages_pkey",
}

/** input type for incrementing numeric columns in table "metrc_transfer_packages" */
export type MetrcTransferPackagesIncInput = {
  received_quantity?: Maybe<Scalars["numeric"]>;
  shipped_quantity?: Maybe<Scalars["numeric"]>;
  shipper_wholesale_price?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "metrc_transfer_packages" */
export type MetrcTransferPackagesInsertInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_date?: Maybe<Scalars["date"]>;
  /** From Metrc */
  delivery_id?: Maybe<Scalars["String"]>;
  delivery_row_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  lab_results_status?: Maybe<Scalars["String"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  metrc_delivery?: Maybe<MetrcDeliveriesObjRelInsertInput>;
  metrc_transfer?: Maybe<MetrcTransfersObjRelInsertInput>;
  /** From Metrc */
  package_id?: Maybe<Scalars["String"]>;
  package_label?: Maybe<Scalars["String"]>;
  package_payload?: Maybe<Scalars["json"]>;
  package_type?: Maybe<Scalars["String"]>;
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  received_quantity?: Maybe<Scalars["numeric"]>;
  received_unit_of_measure?: Maybe<Scalars["String"]>;
  shipment_package_state?: Maybe<Scalars["String"]>;
  shipped_quantity?: Maybe<Scalars["numeric"]>;
  shipped_unit_of_measure?: Maybe<Scalars["String"]>;
  shipper_wholesale_price?: Maybe<Scalars["numeric"]>;
  transfer_row_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type MetrcTransferPackagesMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_date?: Maybe<Scalars["date"]>;
  /** From Metrc */
  delivery_id?: Maybe<Scalars["String"]>;
  delivery_row_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  lab_results_status?: Maybe<Scalars["String"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  /** From Metrc */
  package_id?: Maybe<Scalars["String"]>;
  package_label?: Maybe<Scalars["String"]>;
  package_type?: Maybe<Scalars["String"]>;
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  received_quantity?: Maybe<Scalars["numeric"]>;
  received_unit_of_measure?: Maybe<Scalars["String"]>;
  shipment_package_state?: Maybe<Scalars["String"]>;
  shipped_quantity?: Maybe<Scalars["numeric"]>;
  shipped_unit_of_measure?: Maybe<Scalars["String"]>;
  shipper_wholesale_price?: Maybe<Scalars["numeric"]>;
  transfer_row_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "metrc_transfer_packages" */
export type MetrcTransferPackagesMaxOrderBy = {
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_date?: Maybe<OrderBy>;
  /** From Metrc */
  delivery_id?: Maybe<OrderBy>;
  delivery_row_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lab_results_status?: Maybe<OrderBy>;
  last_modified_at?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  /** From Metrc */
  package_id?: Maybe<OrderBy>;
  package_label?: Maybe<OrderBy>;
  package_type?: Maybe<OrderBy>;
  product_category_name?: Maybe<OrderBy>;
  product_name?: Maybe<OrderBy>;
  received_quantity?: Maybe<OrderBy>;
  received_unit_of_measure?: Maybe<OrderBy>;
  shipment_package_state?: Maybe<OrderBy>;
  shipped_quantity?: Maybe<OrderBy>;
  shipped_unit_of_measure?: Maybe<OrderBy>;
  shipper_wholesale_price?: Maybe<OrderBy>;
  transfer_row_id?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type MetrcTransferPackagesMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_date?: Maybe<Scalars["date"]>;
  /** From Metrc */
  delivery_id?: Maybe<Scalars["String"]>;
  delivery_row_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  lab_results_status?: Maybe<Scalars["String"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  /** From Metrc */
  package_id?: Maybe<Scalars["String"]>;
  package_label?: Maybe<Scalars["String"]>;
  package_type?: Maybe<Scalars["String"]>;
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  received_quantity?: Maybe<Scalars["numeric"]>;
  received_unit_of_measure?: Maybe<Scalars["String"]>;
  shipment_package_state?: Maybe<Scalars["String"]>;
  shipped_quantity?: Maybe<Scalars["numeric"]>;
  shipped_unit_of_measure?: Maybe<Scalars["String"]>;
  shipper_wholesale_price?: Maybe<Scalars["numeric"]>;
  transfer_row_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "metrc_transfer_packages" */
export type MetrcTransferPackagesMinOrderBy = {
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_date?: Maybe<OrderBy>;
  /** From Metrc */
  delivery_id?: Maybe<OrderBy>;
  delivery_row_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lab_results_status?: Maybe<OrderBy>;
  last_modified_at?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  /** From Metrc */
  package_id?: Maybe<OrderBy>;
  package_label?: Maybe<OrderBy>;
  package_type?: Maybe<OrderBy>;
  product_category_name?: Maybe<OrderBy>;
  product_name?: Maybe<OrderBy>;
  received_quantity?: Maybe<OrderBy>;
  received_unit_of_measure?: Maybe<OrderBy>;
  shipment_package_state?: Maybe<OrderBy>;
  shipped_quantity?: Maybe<OrderBy>;
  shipped_unit_of_measure?: Maybe<OrderBy>;
  shipper_wholesale_price?: Maybe<OrderBy>;
  transfer_row_id?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** response of any mutation on the table "metrc_transfer_packages" */
export type MetrcTransferPackagesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetrcTransferPackages>;
};

/** on_conflict condition type for table "metrc_transfer_packages" */
export type MetrcTransferPackagesOnConflict = {
  constraint: MetrcTransferPackagesConstraint;
  update_columns?: Array<MetrcTransferPackagesUpdateColumn>;
  where?: Maybe<MetrcTransferPackagesBoolExp>;
};

/** Ordering options when selecting data from "metrc_transfer_packages". */
export type MetrcTransferPackagesOrderBy = {
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_date?: Maybe<OrderBy>;
  delivery_id?: Maybe<OrderBy>;
  delivery_row_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lab_results_status?: Maybe<OrderBy>;
  last_modified_at?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  metrc_delivery?: Maybe<MetrcDeliveriesOrderBy>;
  metrc_transfer?: Maybe<MetrcTransfersOrderBy>;
  package_id?: Maybe<OrderBy>;
  package_label?: Maybe<OrderBy>;
  package_payload?: Maybe<OrderBy>;
  package_type?: Maybe<OrderBy>;
  product_category_name?: Maybe<OrderBy>;
  product_name?: Maybe<OrderBy>;
  received_quantity?: Maybe<OrderBy>;
  received_unit_of_measure?: Maybe<OrderBy>;
  shipment_package_state?: Maybe<OrderBy>;
  shipped_quantity?: Maybe<OrderBy>;
  shipped_unit_of_measure?: Maybe<OrderBy>;
  shipper_wholesale_price?: Maybe<OrderBy>;
  transfer_row_id?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** primary key columns input for table: metrc_transfer_packages */
export type MetrcTransferPackagesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "metrc_transfer_packages" */
export enum MetrcTransferPackagesSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  DeliveryId = "delivery_id",
  /** column name */
  DeliveryRowId = "delivery_row_id",
  /** column name */
  Id = "id",
  /** column name */
  LabResultsStatus = "lab_results_status",
  /** column name */
  LastModifiedAt = "last_modified_at",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  PackageId = "package_id",
  /** column name */
  PackageLabel = "package_label",
  /** column name */
  PackagePayload = "package_payload",
  /** column name */
  PackageType = "package_type",
  /** column name */
  ProductCategoryName = "product_category_name",
  /** column name */
  ProductName = "product_name",
  /** column name */
  ReceivedQuantity = "received_quantity",
  /** column name */
  ReceivedUnitOfMeasure = "received_unit_of_measure",
  /** column name */
  ShipmentPackageState = "shipment_package_state",
  /** column name */
  ShippedQuantity = "shipped_quantity",
  /** column name */
  ShippedUnitOfMeasure = "shipped_unit_of_measure",
  /** column name */
  ShipperWholesalePrice = "shipper_wholesale_price",
  /** column name */
  TransferRowId = "transfer_row_id",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** input type for updating data in table "metrc_transfer_packages" */
export type MetrcTransferPackagesSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_date?: Maybe<Scalars["date"]>;
  /** From Metrc */
  delivery_id?: Maybe<Scalars["String"]>;
  delivery_row_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  lab_results_status?: Maybe<Scalars["String"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  /** From Metrc */
  package_id?: Maybe<Scalars["String"]>;
  package_label?: Maybe<Scalars["String"]>;
  package_payload?: Maybe<Scalars["json"]>;
  package_type?: Maybe<Scalars["String"]>;
  product_category_name?: Maybe<Scalars["String"]>;
  product_name?: Maybe<Scalars["String"]>;
  received_quantity?: Maybe<Scalars["numeric"]>;
  received_unit_of_measure?: Maybe<Scalars["String"]>;
  shipment_package_state?: Maybe<Scalars["String"]>;
  shipped_quantity?: Maybe<Scalars["numeric"]>;
  shipped_unit_of_measure?: Maybe<Scalars["String"]>;
  shipper_wholesale_price?: Maybe<Scalars["numeric"]>;
  transfer_row_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type MetrcTransferPackagesStddevFields = {
  received_quantity?: Maybe<Scalars["Float"]>;
  shipped_quantity?: Maybe<Scalars["Float"]>;
  shipper_wholesale_price?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "metrc_transfer_packages" */
export type MetrcTransferPackagesStddevOrderBy = {
  received_quantity?: Maybe<OrderBy>;
  shipped_quantity?: Maybe<OrderBy>;
  shipper_wholesale_price?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type MetrcTransferPackagesStddevPopFields = {
  received_quantity?: Maybe<Scalars["Float"]>;
  shipped_quantity?: Maybe<Scalars["Float"]>;
  shipper_wholesale_price?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "metrc_transfer_packages" */
export type MetrcTransferPackagesStddevPopOrderBy = {
  received_quantity?: Maybe<OrderBy>;
  shipped_quantity?: Maybe<OrderBy>;
  shipper_wholesale_price?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type MetrcTransferPackagesStddevSampFields = {
  received_quantity?: Maybe<Scalars["Float"]>;
  shipped_quantity?: Maybe<Scalars["Float"]>;
  shipper_wholesale_price?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "metrc_transfer_packages" */
export type MetrcTransferPackagesStddevSampOrderBy = {
  received_quantity?: Maybe<OrderBy>;
  shipped_quantity?: Maybe<OrderBy>;
  shipper_wholesale_price?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type MetrcTransferPackagesSumFields = {
  received_quantity?: Maybe<Scalars["numeric"]>;
  shipped_quantity?: Maybe<Scalars["numeric"]>;
  shipper_wholesale_price?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "metrc_transfer_packages" */
export type MetrcTransferPackagesSumOrderBy = {
  received_quantity?: Maybe<OrderBy>;
  shipped_quantity?: Maybe<OrderBy>;
  shipper_wholesale_price?: Maybe<OrderBy>;
};

/** update columns of table "metrc_transfer_packages" */
export enum MetrcTransferPackagesUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  DeliveryId = "delivery_id",
  /** column name */
  DeliveryRowId = "delivery_row_id",
  /** column name */
  Id = "id",
  /** column name */
  LabResultsStatus = "lab_results_status",
  /** column name */
  LastModifiedAt = "last_modified_at",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  PackageId = "package_id",
  /** column name */
  PackageLabel = "package_label",
  /** column name */
  PackagePayload = "package_payload",
  /** column name */
  PackageType = "package_type",
  /** column name */
  ProductCategoryName = "product_category_name",
  /** column name */
  ProductName = "product_name",
  /** column name */
  ReceivedQuantity = "received_quantity",
  /** column name */
  ReceivedUnitOfMeasure = "received_unit_of_measure",
  /** column name */
  ShipmentPackageState = "shipment_package_state",
  /** column name */
  ShippedQuantity = "shipped_quantity",
  /** column name */
  ShippedUnitOfMeasure = "shipped_unit_of_measure",
  /** column name */
  ShipperWholesalePrice = "shipper_wholesale_price",
  /** column name */
  TransferRowId = "transfer_row_id",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** aggregate var_pop on columns */
export type MetrcTransferPackagesVarPopFields = {
  received_quantity?: Maybe<Scalars["Float"]>;
  shipped_quantity?: Maybe<Scalars["Float"]>;
  shipper_wholesale_price?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "metrc_transfer_packages" */
export type MetrcTransferPackagesVarPopOrderBy = {
  received_quantity?: Maybe<OrderBy>;
  shipped_quantity?: Maybe<OrderBy>;
  shipper_wholesale_price?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type MetrcTransferPackagesVarSampFields = {
  received_quantity?: Maybe<Scalars["Float"]>;
  shipped_quantity?: Maybe<Scalars["Float"]>;
  shipper_wholesale_price?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "metrc_transfer_packages" */
export type MetrcTransferPackagesVarSampOrderBy = {
  received_quantity?: Maybe<OrderBy>;
  shipped_quantity?: Maybe<OrderBy>;
  shipper_wholesale_price?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type MetrcTransferPackagesVarianceFields = {
  received_quantity?: Maybe<Scalars["Float"]>;
  shipped_quantity?: Maybe<Scalars["Float"]>;
  shipper_wholesale_price?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "metrc_transfer_packages" */
export type MetrcTransferPackagesVarianceOrderBy = {
  received_quantity?: Maybe<OrderBy>;
  shipped_quantity?: Maybe<OrderBy>;
  shipper_wholesale_price?: Maybe<OrderBy>;
};

/** List of transfers from Metrc */
export type MetrcTransfers = {
  /** An array relationship */
  company_deliveries: Array<CompanyDeliveries>;
  /** An aggregate relationship */
  company_deliveries_aggregate: CompanyDeliveriesAggregate;
  created_at: Scalars["timestamptz"];
  created_date: Scalars["date"];
  id: Scalars["uuid"];
  lab_results_status?: Maybe<Scalars["String"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  manifest_number: Scalars["String"];
  /** An array relationship */
  metrc_deliveries: Array<MetrcDeliveries>;
  /** An aggregate relationship */
  metrc_deliveries_aggregate: MetrcDeliveriesAggregate;
  /** An array relationship */
  metrc_transfer_packages: Array<MetrcTransferPackages>;
  /** An aggregate relationship */
  metrc_transfer_packages_aggregate: MetrcTransferPackagesAggregate;
  shipment_transaction_type?: Maybe<Scalars["String"]>;
  shipment_type_name?: Maybe<Scalars["String"]>;
  shipper_facility_license_number?: Maybe<Scalars["String"]>;
  shipper_facility_name?: Maybe<Scalars["String"]>;
  /** From Metrc */
  transfer_id?: Maybe<Scalars["String"]>;
  transfer_payload: Scalars["json"];
  transfer_type: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
  us_state: Scalars["String"];
};

/** List of transfers from Metrc */
export type MetrcTransfersCompanyDeliveriesArgs = {
  distinct_on?: Maybe<Array<CompanyDeliveriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyDeliveriesOrderBy>>;
  where?: Maybe<CompanyDeliveriesBoolExp>;
};

/** List of transfers from Metrc */
export type MetrcTransfersCompanyDeliveriesAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyDeliveriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyDeliveriesOrderBy>>;
  where?: Maybe<CompanyDeliveriesBoolExp>;
};

/** List of transfers from Metrc */
export type MetrcTransfersMetrcDeliveriesArgs = {
  distinct_on?: Maybe<Array<MetrcDeliveriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcDeliveriesOrderBy>>;
  where?: Maybe<MetrcDeliveriesBoolExp>;
};

/** List of transfers from Metrc */
export type MetrcTransfersMetrcDeliveriesAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcDeliveriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcDeliveriesOrderBy>>;
  where?: Maybe<MetrcDeliveriesBoolExp>;
};

/** List of transfers from Metrc */
export type MetrcTransfersMetrcTransferPackagesArgs = {
  distinct_on?: Maybe<Array<MetrcTransferPackagesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcTransferPackagesOrderBy>>;
  where?: Maybe<MetrcTransferPackagesBoolExp>;
};

/** List of transfers from Metrc */
export type MetrcTransfersMetrcTransferPackagesAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcTransferPackagesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcTransferPackagesOrderBy>>;
  where?: Maybe<MetrcTransferPackagesBoolExp>;
};

/** List of transfers from Metrc */
export type MetrcTransfersTransferPayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "metrc_transfers" */
export type MetrcTransfersAggregate = {
  aggregate?: Maybe<MetrcTransfersAggregateFields>;
  nodes: Array<MetrcTransfers>;
};

/** aggregate fields of "metrc_transfers" */
export type MetrcTransfersAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<MetrcTransfersMaxFields>;
  min?: Maybe<MetrcTransfersMinFields>;
};

/** aggregate fields of "metrc_transfers" */
export type MetrcTransfersAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MetrcTransfersSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "metrc_transfers". All fields are combined with a logical 'AND'. */
export type MetrcTransfersBoolExp = {
  _and?: Maybe<Array<MetrcTransfersBoolExp>>;
  _not?: Maybe<MetrcTransfersBoolExp>;
  _or?: Maybe<Array<MetrcTransfersBoolExp>>;
  company_deliveries?: Maybe<CompanyDeliveriesBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_date?: Maybe<DateComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  lab_results_status?: Maybe<StringComparisonExp>;
  last_modified_at?: Maybe<TimestamptzComparisonExp>;
  license_number?: Maybe<StringComparisonExp>;
  manifest_number?: Maybe<StringComparisonExp>;
  metrc_deliveries?: Maybe<MetrcDeliveriesBoolExp>;
  metrc_transfer_packages?: Maybe<MetrcTransferPackagesBoolExp>;
  shipment_transaction_type?: Maybe<StringComparisonExp>;
  shipment_type_name?: Maybe<StringComparisonExp>;
  shipper_facility_license_number?: Maybe<StringComparisonExp>;
  shipper_facility_name?: Maybe<StringComparisonExp>;
  transfer_id?: Maybe<StringComparisonExp>;
  transfer_payload?: Maybe<JsonComparisonExp>;
  transfer_type?: Maybe<StringComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  us_state?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "metrc_transfers" */
export enum MetrcTransfersConstraint {
  /** unique or primary key constraint */
  MetrcTransfersPkey = "metrc_transfers_pkey",
  /** unique or primary key constraint */
  MetrcTransfersUsStateTransferIdKey = "metrc_transfers_us_state_transfer_id_key",
}

/** input type for inserting data into table "metrc_transfers" */
export type MetrcTransfersInsertInput = {
  company_deliveries?: Maybe<CompanyDeliveriesArrRelInsertInput>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  lab_results_status?: Maybe<Scalars["String"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  manifest_number?: Maybe<Scalars["String"]>;
  metrc_deliveries?: Maybe<MetrcDeliveriesArrRelInsertInput>;
  metrc_transfer_packages?: Maybe<MetrcTransferPackagesArrRelInsertInput>;
  shipment_transaction_type?: Maybe<Scalars["String"]>;
  shipment_type_name?: Maybe<Scalars["String"]>;
  shipper_facility_license_number?: Maybe<Scalars["String"]>;
  shipper_facility_name?: Maybe<Scalars["String"]>;
  /** From Metrc */
  transfer_id?: Maybe<Scalars["String"]>;
  transfer_payload?: Maybe<Scalars["json"]>;
  transfer_type?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type MetrcTransfersMaxFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  lab_results_status?: Maybe<Scalars["String"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  manifest_number?: Maybe<Scalars["String"]>;
  shipment_transaction_type?: Maybe<Scalars["String"]>;
  shipment_type_name?: Maybe<Scalars["String"]>;
  shipper_facility_license_number?: Maybe<Scalars["String"]>;
  shipper_facility_name?: Maybe<Scalars["String"]>;
  /** From Metrc */
  transfer_id?: Maybe<Scalars["String"]>;
  transfer_type?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type MetrcTransfersMinFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  lab_results_status?: Maybe<Scalars["String"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  manifest_number?: Maybe<Scalars["String"]>;
  shipment_transaction_type?: Maybe<Scalars["String"]>;
  shipment_type_name?: Maybe<Scalars["String"]>;
  shipper_facility_license_number?: Maybe<Scalars["String"]>;
  shipper_facility_name?: Maybe<Scalars["String"]>;
  /** From Metrc */
  transfer_id?: Maybe<Scalars["String"]>;
  transfer_type?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "metrc_transfers" */
export type MetrcTransfersMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetrcTransfers>;
};

/** input type for inserting object relation for remote table "metrc_transfers" */
export type MetrcTransfersObjRelInsertInput = {
  data: MetrcTransfersInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<MetrcTransfersOnConflict>;
};

/** on_conflict condition type for table "metrc_transfers" */
export type MetrcTransfersOnConflict = {
  constraint: MetrcTransfersConstraint;
  update_columns?: Array<MetrcTransfersUpdateColumn>;
  where?: Maybe<MetrcTransfersBoolExp>;
};

/** Ordering options when selecting data from "metrc_transfers". */
export type MetrcTransfersOrderBy = {
  company_deliveries_aggregate?: Maybe<CompanyDeliveriesAggregateOrderBy>;
  created_at?: Maybe<OrderBy>;
  created_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lab_results_status?: Maybe<OrderBy>;
  last_modified_at?: Maybe<OrderBy>;
  license_number?: Maybe<OrderBy>;
  manifest_number?: Maybe<OrderBy>;
  metrc_deliveries_aggregate?: Maybe<MetrcDeliveriesAggregateOrderBy>;
  metrc_transfer_packages_aggregate?: Maybe<MetrcTransferPackagesAggregateOrderBy>;
  shipment_transaction_type?: Maybe<OrderBy>;
  shipment_type_name?: Maybe<OrderBy>;
  shipper_facility_license_number?: Maybe<OrderBy>;
  shipper_facility_name?: Maybe<OrderBy>;
  transfer_id?: Maybe<OrderBy>;
  transfer_payload?: Maybe<OrderBy>;
  transfer_type?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  us_state?: Maybe<OrderBy>;
};

/** primary key columns input for table: metrc_transfers */
export type MetrcTransfersPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "metrc_transfers" */
export enum MetrcTransfersSelectColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  Id = "id",
  /** column name */
  LabResultsStatus = "lab_results_status",
  /** column name */
  LastModifiedAt = "last_modified_at",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  ManifestNumber = "manifest_number",
  /** column name */
  ShipmentTransactionType = "shipment_transaction_type",
  /** column name */
  ShipmentTypeName = "shipment_type_name",
  /** column name */
  ShipperFacilityLicenseNumber = "shipper_facility_license_number",
  /** column name */
  ShipperFacilityName = "shipper_facility_name",
  /** column name */
  TransferId = "transfer_id",
  /** column name */
  TransferPayload = "transfer_payload",
  /** column name */
  TransferType = "transfer_type",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** input type for updating data in table "metrc_transfers" */
export type MetrcTransfersSetInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  lab_results_status?: Maybe<Scalars["String"]>;
  last_modified_at?: Maybe<Scalars["timestamptz"]>;
  license_number?: Maybe<Scalars["String"]>;
  manifest_number?: Maybe<Scalars["String"]>;
  shipment_transaction_type?: Maybe<Scalars["String"]>;
  shipment_type_name?: Maybe<Scalars["String"]>;
  shipper_facility_license_number?: Maybe<Scalars["String"]>;
  shipper_facility_name?: Maybe<Scalars["String"]>;
  /** From Metrc */
  transfer_id?: Maybe<Scalars["String"]>;
  transfer_payload?: Maybe<Scalars["json"]>;
  transfer_type?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  us_state?: Maybe<Scalars["String"]>;
};

/** update columns of table "metrc_transfers" */
export enum MetrcTransfersUpdateColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  Id = "id",
  /** column name */
  LabResultsStatus = "lab_results_status",
  /** column name */
  LastModifiedAt = "last_modified_at",
  /** column name */
  LicenseNumber = "license_number",
  /** column name */
  ManifestNumber = "manifest_number",
  /** column name */
  ShipmentTransactionType = "shipment_transaction_type",
  /** column name */
  ShipmentTypeName = "shipment_type_name",
  /** column name */
  ShipperFacilityLicenseNumber = "shipper_facility_license_number",
  /** column name */
  ShipperFacilityName = "shipper_facility_name",
  /** column name */
  TransferId = "transfer_id",
  /** column name */
  TransferPayload = "transfer_payload",
  /** column name */
  TransferType = "transfer_type",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UsState = "us_state",
}

/** columns and relationships of "monthly_summary_calculations" */
export type MonthlySummaryCalculations = {
  /** An object relationship */
  company: Companies;
  company_id: Scalars["uuid"];
  id: Scalars["uuid"];
  minimum_payment?: Maybe<Scalars["numeric"]>;
  report_month: Scalars["date"];
};

/** aggregated selection of "monthly_summary_calculations" */
export type MonthlySummaryCalculationsAggregate = {
  aggregate?: Maybe<MonthlySummaryCalculationsAggregateFields>;
  nodes: Array<MonthlySummaryCalculations>;
};

/** aggregate fields of "monthly_summary_calculations" */
export type MonthlySummaryCalculationsAggregateFields = {
  avg?: Maybe<MonthlySummaryCalculationsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<MonthlySummaryCalculationsMaxFields>;
  min?: Maybe<MonthlySummaryCalculationsMinFields>;
  stddev?: Maybe<MonthlySummaryCalculationsStddevFields>;
  stddev_pop?: Maybe<MonthlySummaryCalculationsStddevPopFields>;
  stddev_samp?: Maybe<MonthlySummaryCalculationsStddevSampFields>;
  sum?: Maybe<MonthlySummaryCalculationsSumFields>;
  var_pop?: Maybe<MonthlySummaryCalculationsVarPopFields>;
  var_samp?: Maybe<MonthlySummaryCalculationsVarSampFields>;
  variance?: Maybe<MonthlySummaryCalculationsVarianceFields>;
};

/** aggregate fields of "monthly_summary_calculations" */
export type MonthlySummaryCalculationsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MonthlySummaryCalculationsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsAggregateOrderBy = {
  avg?: Maybe<MonthlySummaryCalculationsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<MonthlySummaryCalculationsMaxOrderBy>;
  min?: Maybe<MonthlySummaryCalculationsMinOrderBy>;
  stddev?: Maybe<MonthlySummaryCalculationsStddevOrderBy>;
  stddev_pop?: Maybe<MonthlySummaryCalculationsStddevPopOrderBy>;
  stddev_samp?: Maybe<MonthlySummaryCalculationsStddevSampOrderBy>;
  sum?: Maybe<MonthlySummaryCalculationsSumOrderBy>;
  var_pop?: Maybe<MonthlySummaryCalculationsVarPopOrderBy>;
  var_samp?: Maybe<MonthlySummaryCalculationsVarSampOrderBy>;
  variance?: Maybe<MonthlySummaryCalculationsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsArrRelInsertInput = {
  data: Array<MonthlySummaryCalculationsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<MonthlySummaryCalculationsOnConflict>;
};

/** aggregate avg on columns */
export type MonthlySummaryCalculationsAvgFields = {
  minimum_payment?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsAvgOrderBy = {
  minimum_payment?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "monthly_summary_calculations". All fields are combined with a logical 'AND'. */
export type MonthlySummaryCalculationsBoolExp = {
  _and?: Maybe<Array<MonthlySummaryCalculationsBoolExp>>;
  _not?: Maybe<MonthlySummaryCalculationsBoolExp>;
  _or?: Maybe<Array<MonthlySummaryCalculationsBoolExp>>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  minimum_payment?: Maybe<NumericComparisonExp>;
  report_month?: Maybe<DateComparisonExp>;
};

/** unique or primary key constraints on table "monthly_summary_calculations" */
export enum MonthlySummaryCalculationsConstraint {
  /** unique or primary key constraint */
  MonthlySummaryCalculationsPkey = "monthly_summary_calculations_pkey",
}

/** input type for incrementing numeric columns in table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsIncInput = {
  minimum_payment?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsInsertInput = {
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  minimum_payment?: Maybe<Scalars["numeric"]>;
  report_month?: Maybe<Scalars["date"]>;
};

/** aggregate max on columns */
export type MonthlySummaryCalculationsMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  minimum_payment?: Maybe<Scalars["numeric"]>;
  report_month?: Maybe<Scalars["date"]>;
};

/** order by max() on columns of table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsMaxOrderBy = {
  company_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  minimum_payment?: Maybe<OrderBy>;
  report_month?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type MonthlySummaryCalculationsMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  minimum_payment?: Maybe<Scalars["numeric"]>;
  report_month?: Maybe<Scalars["date"]>;
};

/** order by min() on columns of table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsMinOrderBy = {
  company_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  minimum_payment?: Maybe<OrderBy>;
  report_month?: Maybe<OrderBy>;
};

/** response of any mutation on the table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MonthlySummaryCalculations>;
};

/** on_conflict condition type for table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsOnConflict = {
  constraint: MonthlySummaryCalculationsConstraint;
  update_columns?: Array<MonthlySummaryCalculationsUpdateColumn>;
  where?: Maybe<MonthlySummaryCalculationsBoolExp>;
};

/** Ordering options when selecting data from "monthly_summary_calculations". */
export type MonthlySummaryCalculationsOrderBy = {
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  minimum_payment?: Maybe<OrderBy>;
  report_month?: Maybe<OrderBy>;
};

/** primary key columns input for table: monthly_summary_calculations */
export type MonthlySummaryCalculationsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "monthly_summary_calculations" */
export enum MonthlySummaryCalculationsSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  Id = "id",
  /** column name */
  MinimumPayment = "minimum_payment",
  /** column name */
  ReportMonth = "report_month",
}

/** input type for updating data in table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  minimum_payment?: Maybe<Scalars["numeric"]>;
  report_month?: Maybe<Scalars["date"]>;
};

/** aggregate stddev on columns */
export type MonthlySummaryCalculationsStddevFields = {
  minimum_payment?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsStddevOrderBy = {
  minimum_payment?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type MonthlySummaryCalculationsStddevPopFields = {
  minimum_payment?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsStddevPopOrderBy = {
  minimum_payment?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type MonthlySummaryCalculationsStddevSampFields = {
  minimum_payment?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsStddevSampOrderBy = {
  minimum_payment?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type MonthlySummaryCalculationsSumFields = {
  minimum_payment?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsSumOrderBy = {
  minimum_payment?: Maybe<OrderBy>;
};

/** update columns of table "monthly_summary_calculations" */
export enum MonthlySummaryCalculationsUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  Id = "id",
  /** column name */
  MinimumPayment = "minimum_payment",
  /** column name */
  ReportMonth = "report_month",
}

/** aggregate var_pop on columns */
export type MonthlySummaryCalculationsVarPopFields = {
  minimum_payment?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsVarPopOrderBy = {
  minimum_payment?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type MonthlySummaryCalculationsVarSampFields = {
  minimum_payment?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsVarSampOrderBy = {
  minimum_payment?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type MonthlySummaryCalculationsVarianceFields = {
  minimum_payment?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "monthly_summary_calculations" */
export type MonthlySummaryCalculationsVarianceOrderBy = {
  minimum_payment?: Maybe<OrderBy>;
};

/** mutation root */
export type MutationRoot = {
  /** delete data from the table: "async_job_summaries" */
  delete_async_job_summaries?: Maybe<AsyncJobSummariesMutationResponse>;
  /** delete single row from the table: "async_job_summaries" */
  delete_async_job_summaries_by_pk?: Maybe<AsyncJobSummaries>;
  /** delete data from the table: "async_jobs" */
  delete_async_jobs?: Maybe<AsyncJobsMutationResponse>;
  /** delete single row from the table: "async_jobs" */
  delete_async_jobs_by_pk?: Maybe<AsyncJobs>;
  /** delete data from the table: "async_pipelines" */
  delete_async_pipelines?: Maybe<AsyncPipelinesMutationResponse>;
  /** delete single row from the table: "async_pipelines" */
  delete_async_pipelines_by_pk?: Maybe<AsyncPipelines>;
  /** delete data from the table: "audit_events" */
  delete_audit_events?: Maybe<AuditEventsMutationResponse>;
  /** delete single row from the table: "audit_events" */
  delete_audit_events_by_pk?: Maybe<AuditEvents>;
  /** delete data from the table: "bank_accounts" */
  delete_bank_accounts?: Maybe<BankAccountsMutationResponse>;
  /** delete single row from the table: "bank_accounts" */
  delete_bank_accounts_by_pk?: Maybe<BankAccounts>;
  /** delete data from the table: "bank_financial_summaries" */
  delete_bank_financial_summaries?: Maybe<BankFinancialSummariesMutationResponse>;
  /** delete single row from the table: "bank_financial_summaries" */
  delete_bank_financial_summaries_by_pk?: Maybe<BankFinancialSummaries>;
  /** delete data from the table: "bespoke_catalog_brands" */
  delete_bespoke_catalog_brands?: Maybe<BespokeCatalogBrandsMutationResponse>;
  /** delete single row from the table: "bespoke_catalog_brands" */
  delete_bespoke_catalog_brands_by_pk?: Maybe<BespokeCatalogBrands>;
  /** delete data from the table: "bespoke_catalog_sku_groups" */
  delete_bespoke_catalog_sku_groups?: Maybe<BespokeCatalogSkuGroupsMutationResponse>;
  /** delete single row from the table: "bespoke_catalog_sku_groups" */
  delete_bespoke_catalog_sku_groups_by_pk?: Maybe<BespokeCatalogSkuGroups>;
  /** delete data from the table: "bespoke_catalog_skus" */
  delete_bespoke_catalog_skus?: Maybe<BespokeCatalogSkusMutationResponse>;
  /** delete single row from the table: "bespoke_catalog_skus" */
  delete_bespoke_catalog_skus_by_pk?: Maybe<BespokeCatalogSkus>;
  /** delete data from the table: "blaze_preapprovals" */
  delete_blaze_preapprovals?: Maybe<BlazePreapprovalsMutationResponse>;
  /** delete single row from the table: "blaze_preapprovals" */
  delete_blaze_preapprovals_by_pk?: Maybe<BlazePreapprovals>;
  /** delete data from the table: "blaze_shop_entries" */
  delete_blaze_shop_entries?: Maybe<BlazeShopEntriesMutationResponse>;
  /** delete single row from the table: "blaze_shop_entries" */
  delete_blaze_shop_entries_by_pk?: Maybe<BlazeShopEntries>;
  /** delete data from the table: "blaze_users" */
  delete_blaze_users?: Maybe<BlazeUsersMutationResponse>;
  /** delete single row from the table: "blaze_users" */
  delete_blaze_users_by_pk?: Maybe<BlazeUsers>;
  /** delete data from the table: "companies" */
  delete_companies?: Maybe<CompaniesMutationResponse>;
  /** delete single row from the table: "companies" */
  delete_companies_by_pk?: Maybe<Companies>;
  /** delete data from the table: "company_agreements" */
  delete_company_agreements?: Maybe<CompanyAgreementsMutationResponse>;
  /** delete single row from the table: "company_agreements" */
  delete_company_agreements_by_pk?: Maybe<CompanyAgreements>;
  /** delete data from the table: "company_deliveries" */
  delete_company_deliveries?: Maybe<CompanyDeliveriesMutationResponse>;
  /** delete single row from the table: "company_deliveries" */
  delete_company_deliveries_by_pk?: Maybe<CompanyDeliveries>;
  /** delete data from the table: "company_facilities" */
  delete_company_facilities?: Maybe<CompanyFacilitiesMutationResponse>;
  /** delete single row from the table: "company_facilities" */
  delete_company_facilities_by_pk?: Maybe<CompanyFacilities>;
  /** delete data from the table: "company_licenses" */
  delete_company_licenses?: Maybe<CompanyLicensesMutationResponse>;
  /** delete single row from the table: "company_licenses" */
  delete_company_licenses_by_pk?: Maybe<CompanyLicenses>;
  /** delete data from the table: "company_partnership_invitations" */
  delete_company_partnership_invitations?: Maybe<CompanyPartnershipInvitationsMutationResponse>;
  /** delete single row from the table: "company_partnership_invitations" */
  delete_company_partnership_invitations_by_pk?: Maybe<CompanyPartnershipInvitations>;
  /** delete data from the table: "company_partnership_requests" */
  delete_company_partnership_requests?: Maybe<CompanyPartnershipRequestsMutationResponse>;
  /** delete single row from the table: "company_partnership_requests" */
  delete_company_partnership_requests_by_pk?: Maybe<CompanyPartnershipRequests>;
  /** delete data from the table: "company_payor_contacts" */
  delete_company_payor_contacts?: Maybe<CompanyPayorContactsMutationResponse>;
  /** delete single row from the table: "company_payor_contacts" */
  delete_company_payor_contacts_by_pk?: Maybe<CompanyPayorContacts>;
  /** delete data from the table: "company_payor_partnerships" */
  delete_company_payor_partnerships?: Maybe<CompanyPayorPartnershipsMutationResponse>;
  /** delete single row from the table: "company_payor_partnerships" */
  delete_company_payor_partnerships_by_pk?: Maybe<CompanyPayorPartnerships>;
  /** delete data from the table: "company_settings" */
  delete_company_settings?: Maybe<CompanySettingsMutationResponse>;
  /** delete single row from the table: "company_settings" */
  delete_company_settings_by_pk?: Maybe<CompanySettings>;
  /** delete data from the table: "company_type" */
  delete_company_type?: Maybe<CompanyTypeMutationResponse>;
  /** delete single row from the table: "company_type" */
  delete_company_type_by_pk?: Maybe<CompanyType>;
  /** delete data from the table: "company_vendor_contacts" */
  delete_company_vendor_contacts?: Maybe<CompanyVendorContactsMutationResponse>;
  /** delete single row from the table: "company_vendor_contacts" */
  delete_company_vendor_contacts_by_pk?: Maybe<CompanyVendorContacts>;
  /** delete data from the table: "company_vendor_partnerships" */
  delete_company_vendor_partnerships?: Maybe<CompanyVendorPartnershipsMutationResponse>;
  /** delete single row from the table: "company_vendor_partnerships" */
  delete_company_vendor_partnerships_by_pk?: Maybe<CompanyVendorPartnerships>;
  /** delete data from the table: "contracts" */
  delete_contracts?: Maybe<ContractsMutationResponse>;
  /** delete single row from the table: "contracts" */
  delete_contracts_by_pk?: Maybe<Contracts>;
  /** delete data from the table: "customer_surveillance_results" */
  delete_customer_surveillance_results?: Maybe<CustomerSurveillanceResultsMutationResponse>;
  /** delete single row from the table: "customer_surveillance_results" */
  delete_customer_surveillance_results_by_pk?: Maybe<CustomerSurveillanceResults>;
  /** delete data from the table: "debt_facilities" */
  delete_debt_facilities?: Maybe<DebtFacilitiesMutationResponse>;
  /** delete single row from the table: "debt_facilities" */
  delete_debt_facilities_by_pk?: Maybe<DebtFacilities>;
  /** delete data from the table: "debt_facility_capacities" */
  delete_debt_facility_capacities?: Maybe<DebtFacilityCapacitiesMutationResponse>;
  /** delete single row from the table: "debt_facility_capacities" */
  delete_debt_facility_capacities_by_pk?: Maybe<DebtFacilityCapacities>;
  /** delete data from the table: "debt_facility_events" */
  delete_debt_facility_events?: Maybe<DebtFacilityEventsMutationResponse>;
  /** delete single row from the table: "debt_facility_events" */
  delete_debt_facility_events_by_pk?: Maybe<DebtFacilityEvents>;
  /** delete data from the table: "ebba_application_files" */
  delete_ebba_application_files?: Maybe<EbbaApplicationFilesMutationResponse>;
  /** delete single row from the table: "ebba_application_files" */
  delete_ebba_application_files_by_pk?: Maybe<EbbaApplicationFiles>;
  /** delete data from the table: "ebba_applications" */
  delete_ebba_applications?: Maybe<EbbaApplicationsMutationResponse>;
  /** delete single row from the table: "ebba_applications" */
  delete_ebba_applications_by_pk?: Maybe<EbbaApplications>;
  /** delete data from the table: "files" */
  delete_files?: Maybe<FilesMutationResponse>;
  /** delete single row from the table: "files" */
  delete_files_by_pk?: Maybe<Files>;
  /** delete data from the table: "financial_summaries" */
  delete_financial_summaries?: Maybe<FinancialSummariesMutationResponse>;
  /** delete single row from the table: "financial_summaries" */
  delete_financial_summaries_by_pk?: Maybe<FinancialSummaries>;
  /** delete data from the table: "invoice_file_type" */
  delete_invoice_file_type?: Maybe<InvoiceFileTypeMutationResponse>;
  /** delete single row from the table: "invoice_file_type" */
  delete_invoice_file_type_by_pk?: Maybe<InvoiceFileType>;
  /** delete data from the table: "invoice_files" */
  delete_invoice_files?: Maybe<InvoiceFilesMutationResponse>;
  /** delete single row from the table: "invoice_files" */
  delete_invoice_files_by_pk?: Maybe<InvoiceFiles>;
  /** delete data from the table: "invoices" */
  delete_invoices?: Maybe<InvoicesMutationResponse>;
  /** delete single row from the table: "invoices" */
  delete_invoices_by_pk?: Maybe<Invoices>;
  /** delete data from the table: "line_of_credits" */
  delete_line_of_credits?: Maybe<LineOfCreditsMutationResponse>;
  /** delete single row from the table: "line_of_credits" */
  delete_line_of_credits_by_pk?: Maybe<LineOfCredits>;
  /** delete data from the table: "loan_reports" */
  delete_loan_reports?: Maybe<LoanReportsMutationResponse>;
  /** delete single row from the table: "loan_reports" */
  delete_loan_reports_by_pk?: Maybe<LoanReports>;
  /** delete data from the table: "loan_type" */
  delete_loan_type?: Maybe<LoanTypeMutationResponse>;
  /** delete single row from the table: "loan_type" */
  delete_loan_type_by_pk?: Maybe<LoanType>;
  /** delete data from the table: "loans" */
  delete_loans?: Maybe<LoansMutationResponse>;
  /** delete single row from the table: "loans" */
  delete_loans_by_pk?: Maybe<Loans>;
  /** delete data from the table: "metrc_analysis_summaries" */
  delete_metrc_analysis_summaries?: Maybe<MetrcAnalysisSummariesMutationResponse>;
  /** delete single row from the table: "metrc_analysis_summaries" */
  delete_metrc_analysis_summaries_by_pk?: Maybe<MetrcAnalysisSummaries>;
  /** delete data from the table: "metrc_api_keys" */
  delete_metrc_api_keys?: Maybe<MetrcApiKeysMutationResponse>;
  /** delete single row from the table: "metrc_api_keys" */
  delete_metrc_api_keys_by_pk?: Maybe<MetrcApiKeys>;
  /** delete data from the table: "metrc_deliveries" */
  delete_metrc_deliveries?: Maybe<MetrcDeliveriesMutationResponse>;
  /** delete single row from the table: "metrc_deliveries" */
  delete_metrc_deliveries_by_pk?: Maybe<MetrcDeliveries>;
  /** delete data from the table: "metrc_download_summaries" */
  delete_metrc_download_summaries?: Maybe<MetrcDownloadSummariesMutationResponse>;
  /** delete single row from the table: "metrc_download_summaries" */
  delete_metrc_download_summaries_by_pk?: Maybe<MetrcDownloadSummaries>;
  /** delete data from the table: "metrc_harvests" */
  delete_metrc_harvests?: Maybe<MetrcHarvestsMutationResponse>;
  /** delete single row from the table: "metrc_harvests" */
  delete_metrc_harvests_by_pk?: Maybe<MetrcHarvests>;
  /** delete data from the table: "metrc_packages" */
  delete_metrc_packages?: Maybe<MetrcPackagesMutationResponse>;
  /** delete single row from the table: "metrc_packages" */
  delete_metrc_packages_by_pk?: Maybe<MetrcPackages>;
  /** delete data from the table: "metrc_plant_batches" */
  delete_metrc_plant_batches?: Maybe<MetrcPlantBatchesMutationResponse>;
  /** delete single row from the table: "metrc_plant_batches" */
  delete_metrc_plant_batches_by_pk?: Maybe<MetrcPlantBatches>;
  /** delete data from the table: "metrc_plants" */
  delete_metrc_plants?: Maybe<MetrcPlantsMutationResponse>;
  /** delete single row from the table: "metrc_plants" */
  delete_metrc_plants_by_pk?: Maybe<MetrcPlants>;
  /** delete data from the table: "metrc_sales_receipts" */
  delete_metrc_sales_receipts?: Maybe<MetrcSalesReceiptsMutationResponse>;
  /** delete single row from the table: "metrc_sales_receipts" */
  delete_metrc_sales_receipts_by_pk?: Maybe<MetrcSalesReceipts>;
  /** delete data from the table: "metrc_sales_transactions" */
  delete_metrc_sales_transactions?: Maybe<MetrcSalesTransactionsMutationResponse>;
  /** delete single row from the table: "metrc_sales_transactions" */
  delete_metrc_sales_transactions_by_pk?: Maybe<MetrcSalesTransactions>;
  /** delete data from the table: "metrc_to_bespoke_catalog_skus" */
  delete_metrc_to_bespoke_catalog_skus?: Maybe<MetrcToBespokeCatalogSkusMutationResponse>;
  /** delete single row from the table: "metrc_to_bespoke_catalog_skus" */
  delete_metrc_to_bespoke_catalog_skus_by_pk?: Maybe<MetrcToBespokeCatalogSkus>;
  /** delete data from the table: "metrc_transfer_packages" */
  delete_metrc_transfer_packages?: Maybe<MetrcTransferPackagesMutationResponse>;
  /** delete single row from the table: "metrc_transfer_packages" */
  delete_metrc_transfer_packages_by_pk?: Maybe<MetrcTransferPackages>;
  /** delete data from the table: "metrc_transfers" */
  delete_metrc_transfers?: Maybe<MetrcTransfersMutationResponse>;
  /** delete single row from the table: "metrc_transfers" */
  delete_metrc_transfers_by_pk?: Maybe<MetrcTransfers>;
  /** delete data from the table: "monthly_summary_calculations" */
  delete_monthly_summary_calculations?: Maybe<MonthlySummaryCalculationsMutationResponse>;
  /** delete single row from the table: "monthly_summary_calculations" */
  delete_monthly_summary_calculations_by_pk?: Maybe<MonthlySummaryCalculations>;
  /** delete data from the table: "parent_companies" */
  delete_parent_companies?: Maybe<ParentCompaniesMutationResponse>;
  /** delete single row from the table: "parent_companies" */
  delete_parent_companies_by_pk?: Maybe<ParentCompanies>;
  /** delete data from the table: "payments" */
  delete_payments?: Maybe<PaymentsMutationResponse>;
  /** delete single row from the table: "payments" */
  delete_payments_by_pk?: Maybe<Payments>;
  /** delete data from the table: "payors" */
  delete_payors?: Maybe<PayorsMutationResponse>;
  /** delete data from the table: "purchase_order_file_type" */
  delete_purchase_order_file_type?: Maybe<PurchaseOrderFileTypeMutationResponse>;
  /** delete single row from the table: "purchase_order_file_type" */
  delete_purchase_order_file_type_by_pk?: Maybe<PurchaseOrderFileType>;
  /** delete data from the table: "purchase_order_files" */
  delete_purchase_order_files?: Maybe<PurchaseOrderFilesMutationResponse>;
  /** delete single row from the table: "purchase_order_files" */
  delete_purchase_order_files_by_pk?: Maybe<PurchaseOrderFiles>;
  /** delete data from the table: "purchase_order_metrc_transfers" */
  delete_purchase_order_metrc_transfers?: Maybe<PurchaseOrderMetrcTransfersMutationResponse>;
  /** delete single row from the table: "purchase_order_metrc_transfers" */
  delete_purchase_order_metrc_transfers_by_pk?: Maybe<PurchaseOrderMetrcTransfers>;
  /** delete data from the table: "purchase_orders" */
  delete_purchase_orders?: Maybe<PurchaseOrdersMutationResponse>;
  /** delete single row from the table: "purchase_orders" */
  delete_purchase_orders_by_pk?: Maybe<PurchaseOrders>;
  /** delete data from the table: "request_status" */
  delete_request_status?: Maybe<RequestStatusMutationResponse>;
  /** delete single row from the table: "request_status" */
  delete_request_status_by_pk?: Maybe<RequestStatus>;
  /** delete data from the table: "revoked_tokens" */
  delete_revoked_tokens?: Maybe<RevokedTokensMutationResponse>;
  /** delete single row from the table: "revoked_tokens" */
  delete_revoked_tokens_by_pk?: Maybe<RevokedTokens>;
  /** delete data from the table: "sync_pipelines" */
  delete_sync_pipelines?: Maybe<SyncPipelinesMutationResponse>;
  /** delete single row from the table: "sync_pipelines" */
  delete_sync_pipelines_by_pk?: Maybe<SyncPipelines>;
  /** delete data from the table: "transactions" */
  delete_transactions?: Maybe<TransactionsMutationResponse>;
  /** delete single row from the table: "transactions" */
  delete_transactions_by_pk?: Maybe<Transactions>;
  /** delete data from the table: "two_factor_links" */
  delete_two_factor_links?: Maybe<TwoFactorLinksMutationResponse>;
  /** delete single row from the table: "two_factor_links" */
  delete_two_factor_links_by_pk?: Maybe<TwoFactorLinks>;
  /** delete data from the table: "user_roles" */
  delete_user_roles?: Maybe<UserRolesMutationResponse>;
  /** delete single row from the table: "user_roles" */
  delete_user_roles_by_pk?: Maybe<UserRoles>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<UsersMutationResponse>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "vendor_change_requests" */
  delete_vendor_change_requests?: Maybe<VendorChangeRequestsMutationResponse>;
  /** delete single row from the table: "vendor_change_requests" */
  delete_vendor_change_requests_by_pk?: Maybe<VendorChangeRequests>;
  /** delete data from the table: "vendors" */
  delete_vendors?: Maybe<VendorsMutationResponse>;
  /** insert data into the table: "async_job_summaries" */
  insert_async_job_summaries?: Maybe<AsyncJobSummariesMutationResponse>;
  /** insert a single row into the table: "async_job_summaries" */
  insert_async_job_summaries_one?: Maybe<AsyncJobSummaries>;
  /** insert data into the table: "async_jobs" */
  insert_async_jobs?: Maybe<AsyncJobsMutationResponse>;
  /** insert a single row into the table: "async_jobs" */
  insert_async_jobs_one?: Maybe<AsyncJobs>;
  /** insert data into the table: "async_pipelines" */
  insert_async_pipelines?: Maybe<AsyncPipelinesMutationResponse>;
  /** insert a single row into the table: "async_pipelines" */
  insert_async_pipelines_one?: Maybe<AsyncPipelines>;
  /** insert data into the table: "audit_events" */
  insert_audit_events?: Maybe<AuditEventsMutationResponse>;
  /** insert a single row into the table: "audit_events" */
  insert_audit_events_one?: Maybe<AuditEvents>;
  /** insert data into the table: "bank_accounts" */
  insert_bank_accounts?: Maybe<BankAccountsMutationResponse>;
  /** insert a single row into the table: "bank_accounts" */
  insert_bank_accounts_one?: Maybe<BankAccounts>;
  /** insert data into the table: "bank_financial_summaries" */
  insert_bank_financial_summaries?: Maybe<BankFinancialSummariesMutationResponse>;
  /** insert a single row into the table: "bank_financial_summaries" */
  insert_bank_financial_summaries_one?: Maybe<BankFinancialSummaries>;
  /** insert data into the table: "bespoke_catalog_brands" */
  insert_bespoke_catalog_brands?: Maybe<BespokeCatalogBrandsMutationResponse>;
  /** insert a single row into the table: "bespoke_catalog_brands" */
  insert_bespoke_catalog_brands_one?: Maybe<BespokeCatalogBrands>;
  /** insert data into the table: "bespoke_catalog_sku_groups" */
  insert_bespoke_catalog_sku_groups?: Maybe<BespokeCatalogSkuGroupsMutationResponse>;
  /** insert a single row into the table: "bespoke_catalog_sku_groups" */
  insert_bespoke_catalog_sku_groups_one?: Maybe<BespokeCatalogSkuGroups>;
  /** insert data into the table: "bespoke_catalog_skus" */
  insert_bespoke_catalog_skus?: Maybe<BespokeCatalogSkusMutationResponse>;
  /** insert a single row into the table: "bespoke_catalog_skus" */
  insert_bespoke_catalog_skus_one?: Maybe<BespokeCatalogSkus>;
  /** insert data into the table: "blaze_preapprovals" */
  insert_blaze_preapprovals?: Maybe<BlazePreapprovalsMutationResponse>;
  /** insert a single row into the table: "blaze_preapprovals" */
  insert_blaze_preapprovals_one?: Maybe<BlazePreapprovals>;
  /** insert data into the table: "blaze_shop_entries" */
  insert_blaze_shop_entries?: Maybe<BlazeShopEntriesMutationResponse>;
  /** insert a single row into the table: "blaze_shop_entries" */
  insert_blaze_shop_entries_one?: Maybe<BlazeShopEntries>;
  /** insert data into the table: "blaze_users" */
  insert_blaze_users?: Maybe<BlazeUsersMutationResponse>;
  /** insert a single row into the table: "blaze_users" */
  insert_blaze_users_one?: Maybe<BlazeUsers>;
  /** insert data into the table: "companies" */
  insert_companies?: Maybe<CompaniesMutationResponse>;
  /** insert a single row into the table: "companies" */
  insert_companies_one?: Maybe<Companies>;
  /** insert data into the table: "company_agreements" */
  insert_company_agreements?: Maybe<CompanyAgreementsMutationResponse>;
  /** insert a single row into the table: "company_agreements" */
  insert_company_agreements_one?: Maybe<CompanyAgreements>;
  /** insert data into the table: "company_deliveries" */
  insert_company_deliveries?: Maybe<CompanyDeliveriesMutationResponse>;
  /** insert a single row into the table: "company_deliveries" */
  insert_company_deliveries_one?: Maybe<CompanyDeliveries>;
  /** insert data into the table: "company_facilities" */
  insert_company_facilities?: Maybe<CompanyFacilitiesMutationResponse>;
  /** insert a single row into the table: "company_facilities" */
  insert_company_facilities_one?: Maybe<CompanyFacilities>;
  /** insert data into the table: "company_licenses" */
  insert_company_licenses?: Maybe<CompanyLicensesMutationResponse>;
  /** insert a single row into the table: "company_licenses" */
  insert_company_licenses_one?: Maybe<CompanyLicenses>;
  /** insert data into the table: "company_partnership_invitations" */
  insert_company_partnership_invitations?: Maybe<CompanyPartnershipInvitationsMutationResponse>;
  /** insert a single row into the table: "company_partnership_invitations" */
  insert_company_partnership_invitations_one?: Maybe<CompanyPartnershipInvitations>;
  /** insert data into the table: "company_partnership_requests" */
  insert_company_partnership_requests?: Maybe<CompanyPartnershipRequestsMutationResponse>;
  /** insert a single row into the table: "company_partnership_requests" */
  insert_company_partnership_requests_one?: Maybe<CompanyPartnershipRequests>;
  /** insert data into the table: "company_payor_contacts" */
  insert_company_payor_contacts?: Maybe<CompanyPayorContactsMutationResponse>;
  /** insert a single row into the table: "company_payor_contacts" */
  insert_company_payor_contacts_one?: Maybe<CompanyPayorContacts>;
  /** insert data into the table: "company_payor_partnerships" */
  insert_company_payor_partnerships?: Maybe<CompanyPayorPartnershipsMutationResponse>;
  /** insert a single row into the table: "company_payor_partnerships" */
  insert_company_payor_partnerships_one?: Maybe<CompanyPayorPartnerships>;
  /** insert data into the table: "company_settings" */
  insert_company_settings?: Maybe<CompanySettingsMutationResponse>;
  /** insert a single row into the table: "company_settings" */
  insert_company_settings_one?: Maybe<CompanySettings>;
  /** insert data into the table: "company_type" */
  insert_company_type?: Maybe<CompanyTypeMutationResponse>;
  /** insert a single row into the table: "company_type" */
  insert_company_type_one?: Maybe<CompanyType>;
  /** insert data into the table: "company_vendor_contacts" */
  insert_company_vendor_contacts?: Maybe<CompanyVendorContactsMutationResponse>;
  /** insert a single row into the table: "company_vendor_contacts" */
  insert_company_vendor_contacts_one?: Maybe<CompanyVendorContacts>;
  /** insert data into the table: "company_vendor_partnerships" */
  insert_company_vendor_partnerships?: Maybe<CompanyVendorPartnershipsMutationResponse>;
  /** insert a single row into the table: "company_vendor_partnerships" */
  insert_company_vendor_partnerships_one?: Maybe<CompanyVendorPartnerships>;
  /** insert data into the table: "contracts" */
  insert_contracts?: Maybe<ContractsMutationResponse>;
  /** insert a single row into the table: "contracts" */
  insert_contracts_one?: Maybe<Contracts>;
  /** insert data into the table: "customer_surveillance_results" */
  insert_customer_surveillance_results?: Maybe<CustomerSurveillanceResultsMutationResponse>;
  /** insert a single row into the table: "customer_surveillance_results" */
  insert_customer_surveillance_results_one?: Maybe<CustomerSurveillanceResults>;
  /** insert data into the table: "debt_facilities" */
  insert_debt_facilities?: Maybe<DebtFacilitiesMutationResponse>;
  /** insert a single row into the table: "debt_facilities" */
  insert_debt_facilities_one?: Maybe<DebtFacilities>;
  /** insert data into the table: "debt_facility_capacities" */
  insert_debt_facility_capacities?: Maybe<DebtFacilityCapacitiesMutationResponse>;
  /** insert a single row into the table: "debt_facility_capacities" */
  insert_debt_facility_capacities_one?: Maybe<DebtFacilityCapacities>;
  /** insert data into the table: "debt_facility_events" */
  insert_debt_facility_events?: Maybe<DebtFacilityEventsMutationResponse>;
  /** insert a single row into the table: "debt_facility_events" */
  insert_debt_facility_events_one?: Maybe<DebtFacilityEvents>;
  /** insert data into the table: "ebba_application_files" */
  insert_ebba_application_files?: Maybe<EbbaApplicationFilesMutationResponse>;
  /** insert a single row into the table: "ebba_application_files" */
  insert_ebba_application_files_one?: Maybe<EbbaApplicationFiles>;
  /** insert data into the table: "ebba_applications" */
  insert_ebba_applications?: Maybe<EbbaApplicationsMutationResponse>;
  /** insert a single row into the table: "ebba_applications" */
  insert_ebba_applications_one?: Maybe<EbbaApplications>;
  /** insert data into the table: "files" */
  insert_files?: Maybe<FilesMutationResponse>;
  /** insert a single row into the table: "files" */
  insert_files_one?: Maybe<Files>;
  /** insert data into the table: "financial_summaries" */
  insert_financial_summaries?: Maybe<FinancialSummariesMutationResponse>;
  /** insert a single row into the table: "financial_summaries" */
  insert_financial_summaries_one?: Maybe<FinancialSummaries>;
  /** insert data into the table: "invoice_file_type" */
  insert_invoice_file_type?: Maybe<InvoiceFileTypeMutationResponse>;
  /** insert a single row into the table: "invoice_file_type" */
  insert_invoice_file_type_one?: Maybe<InvoiceFileType>;
  /** insert data into the table: "invoice_files" */
  insert_invoice_files?: Maybe<InvoiceFilesMutationResponse>;
  /** insert a single row into the table: "invoice_files" */
  insert_invoice_files_one?: Maybe<InvoiceFiles>;
  /** insert data into the table: "invoices" */
  insert_invoices?: Maybe<InvoicesMutationResponse>;
  /** insert a single row into the table: "invoices" */
  insert_invoices_one?: Maybe<Invoices>;
  /** insert data into the table: "line_of_credits" */
  insert_line_of_credits?: Maybe<LineOfCreditsMutationResponse>;
  /** insert a single row into the table: "line_of_credits" */
  insert_line_of_credits_one?: Maybe<LineOfCredits>;
  /** insert data into the table: "loan_reports" */
  insert_loan_reports?: Maybe<LoanReportsMutationResponse>;
  /** insert a single row into the table: "loan_reports" */
  insert_loan_reports_one?: Maybe<LoanReports>;
  /** insert data into the table: "loan_type" */
  insert_loan_type?: Maybe<LoanTypeMutationResponse>;
  /** insert a single row into the table: "loan_type" */
  insert_loan_type_one?: Maybe<LoanType>;
  /** insert data into the table: "loans" */
  insert_loans?: Maybe<LoansMutationResponse>;
  /** insert a single row into the table: "loans" */
  insert_loans_one?: Maybe<Loans>;
  /** insert data into the table: "metrc_analysis_summaries" */
  insert_metrc_analysis_summaries?: Maybe<MetrcAnalysisSummariesMutationResponse>;
  /** insert a single row into the table: "metrc_analysis_summaries" */
  insert_metrc_analysis_summaries_one?: Maybe<MetrcAnalysisSummaries>;
  /** insert data into the table: "metrc_api_keys" */
  insert_metrc_api_keys?: Maybe<MetrcApiKeysMutationResponse>;
  /** insert a single row into the table: "metrc_api_keys" */
  insert_metrc_api_keys_one?: Maybe<MetrcApiKeys>;
  /** insert data into the table: "metrc_deliveries" */
  insert_metrc_deliveries?: Maybe<MetrcDeliveriesMutationResponse>;
  /** insert a single row into the table: "metrc_deliveries" */
  insert_metrc_deliveries_one?: Maybe<MetrcDeliveries>;
  /** insert data into the table: "metrc_download_summaries" */
  insert_metrc_download_summaries?: Maybe<MetrcDownloadSummariesMutationResponse>;
  /** insert a single row into the table: "metrc_download_summaries" */
  insert_metrc_download_summaries_one?: Maybe<MetrcDownloadSummaries>;
  /** insert data into the table: "metrc_harvests" */
  insert_metrc_harvests?: Maybe<MetrcHarvestsMutationResponse>;
  /** insert a single row into the table: "metrc_harvests" */
  insert_metrc_harvests_one?: Maybe<MetrcHarvests>;
  /** insert data into the table: "metrc_packages" */
  insert_metrc_packages?: Maybe<MetrcPackagesMutationResponse>;
  /** insert a single row into the table: "metrc_packages" */
  insert_metrc_packages_one?: Maybe<MetrcPackages>;
  /** insert data into the table: "metrc_plant_batches" */
  insert_metrc_plant_batches?: Maybe<MetrcPlantBatchesMutationResponse>;
  /** insert a single row into the table: "metrc_plant_batches" */
  insert_metrc_plant_batches_one?: Maybe<MetrcPlantBatches>;
  /** insert data into the table: "metrc_plants" */
  insert_metrc_plants?: Maybe<MetrcPlantsMutationResponse>;
  /** insert a single row into the table: "metrc_plants" */
  insert_metrc_plants_one?: Maybe<MetrcPlants>;
  /** insert data into the table: "metrc_sales_receipts" */
  insert_metrc_sales_receipts?: Maybe<MetrcSalesReceiptsMutationResponse>;
  /** insert a single row into the table: "metrc_sales_receipts" */
  insert_metrc_sales_receipts_one?: Maybe<MetrcSalesReceipts>;
  /** insert data into the table: "metrc_sales_transactions" */
  insert_metrc_sales_transactions?: Maybe<MetrcSalesTransactionsMutationResponse>;
  /** insert a single row into the table: "metrc_sales_transactions" */
  insert_metrc_sales_transactions_one?: Maybe<MetrcSalesTransactions>;
  /** insert data into the table: "metrc_to_bespoke_catalog_skus" */
  insert_metrc_to_bespoke_catalog_skus?: Maybe<MetrcToBespokeCatalogSkusMutationResponse>;
  /** insert a single row into the table: "metrc_to_bespoke_catalog_skus" */
  insert_metrc_to_bespoke_catalog_skus_one?: Maybe<MetrcToBespokeCatalogSkus>;
  /** insert data into the table: "metrc_transfer_packages" */
  insert_metrc_transfer_packages?: Maybe<MetrcTransferPackagesMutationResponse>;
  /** insert a single row into the table: "metrc_transfer_packages" */
  insert_metrc_transfer_packages_one?: Maybe<MetrcTransferPackages>;
  /** insert data into the table: "metrc_transfers" */
  insert_metrc_transfers?: Maybe<MetrcTransfersMutationResponse>;
  /** insert a single row into the table: "metrc_transfers" */
  insert_metrc_transfers_one?: Maybe<MetrcTransfers>;
  /** insert data into the table: "monthly_summary_calculations" */
  insert_monthly_summary_calculations?: Maybe<MonthlySummaryCalculationsMutationResponse>;
  /** insert a single row into the table: "monthly_summary_calculations" */
  insert_monthly_summary_calculations_one?: Maybe<MonthlySummaryCalculations>;
  /** insert data into the table: "parent_companies" */
  insert_parent_companies?: Maybe<ParentCompaniesMutationResponse>;
  /** insert a single row into the table: "parent_companies" */
  insert_parent_companies_one?: Maybe<ParentCompanies>;
  /** insert data into the table: "payments" */
  insert_payments?: Maybe<PaymentsMutationResponse>;
  /** insert a single row into the table: "payments" */
  insert_payments_one?: Maybe<Payments>;
  /** insert data into the table: "payors" */
  insert_payors?: Maybe<PayorsMutationResponse>;
  /** insert a single row into the table: "payors" */
  insert_payors_one?: Maybe<Payors>;
  /** insert data into the table: "purchase_order_file_type" */
  insert_purchase_order_file_type?: Maybe<PurchaseOrderFileTypeMutationResponse>;
  /** insert a single row into the table: "purchase_order_file_type" */
  insert_purchase_order_file_type_one?: Maybe<PurchaseOrderFileType>;
  /** insert data into the table: "purchase_order_files" */
  insert_purchase_order_files?: Maybe<PurchaseOrderFilesMutationResponse>;
  /** insert a single row into the table: "purchase_order_files" */
  insert_purchase_order_files_one?: Maybe<PurchaseOrderFiles>;
  /** insert data into the table: "purchase_order_metrc_transfers" */
  insert_purchase_order_metrc_transfers?: Maybe<PurchaseOrderMetrcTransfersMutationResponse>;
  /** insert a single row into the table: "purchase_order_metrc_transfers" */
  insert_purchase_order_metrc_transfers_one?: Maybe<PurchaseOrderMetrcTransfers>;
  /** insert data into the table: "purchase_orders" */
  insert_purchase_orders?: Maybe<PurchaseOrdersMutationResponse>;
  /** insert a single row into the table: "purchase_orders" */
  insert_purchase_orders_one?: Maybe<PurchaseOrders>;
  /** insert data into the table: "request_status" */
  insert_request_status?: Maybe<RequestStatusMutationResponse>;
  /** insert a single row into the table: "request_status" */
  insert_request_status_one?: Maybe<RequestStatus>;
  /** insert data into the table: "revoked_tokens" */
  insert_revoked_tokens?: Maybe<RevokedTokensMutationResponse>;
  /** insert a single row into the table: "revoked_tokens" */
  insert_revoked_tokens_one?: Maybe<RevokedTokens>;
  /** insert data into the table: "sync_pipelines" */
  insert_sync_pipelines?: Maybe<SyncPipelinesMutationResponse>;
  /** insert a single row into the table: "sync_pipelines" */
  insert_sync_pipelines_one?: Maybe<SyncPipelines>;
  /** insert data into the table: "transactions" */
  insert_transactions?: Maybe<TransactionsMutationResponse>;
  /** insert a single row into the table: "transactions" */
  insert_transactions_one?: Maybe<Transactions>;
  /** insert data into the table: "two_factor_links" */
  insert_two_factor_links?: Maybe<TwoFactorLinksMutationResponse>;
  /** insert a single row into the table: "two_factor_links" */
  insert_two_factor_links_one?: Maybe<TwoFactorLinks>;
  /** insert data into the table: "user_roles" */
  insert_user_roles?: Maybe<UserRolesMutationResponse>;
  /** insert a single row into the table: "user_roles" */
  insert_user_roles_one?: Maybe<UserRoles>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<UsersMutationResponse>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "vendor_change_requests" */
  insert_vendor_change_requests?: Maybe<VendorChangeRequestsMutationResponse>;
  /** insert a single row into the table: "vendor_change_requests" */
  insert_vendor_change_requests_one?: Maybe<VendorChangeRequests>;
  /** insert data into the table: "vendors" */
  insert_vendors?: Maybe<VendorsMutationResponse>;
  /** insert a single row into the table: "vendors" */
  insert_vendors_one?: Maybe<Vendors>;
  /** update data of the table: "async_job_summaries" */
  update_async_job_summaries?: Maybe<AsyncJobSummariesMutationResponse>;
  /** update single row of the table: "async_job_summaries" */
  update_async_job_summaries_by_pk?: Maybe<AsyncJobSummaries>;
  /** update data of the table: "async_jobs" */
  update_async_jobs?: Maybe<AsyncJobsMutationResponse>;
  /** update single row of the table: "async_jobs" */
  update_async_jobs_by_pk?: Maybe<AsyncJobs>;
  /** update data of the table: "async_pipelines" */
  update_async_pipelines?: Maybe<AsyncPipelinesMutationResponse>;
  /** update single row of the table: "async_pipelines" */
  update_async_pipelines_by_pk?: Maybe<AsyncPipelines>;
  /** update data of the table: "audit_events" */
  update_audit_events?: Maybe<AuditEventsMutationResponse>;
  /** update single row of the table: "audit_events" */
  update_audit_events_by_pk?: Maybe<AuditEvents>;
  /** update data of the table: "bank_accounts" */
  update_bank_accounts?: Maybe<BankAccountsMutationResponse>;
  /** update single row of the table: "bank_accounts" */
  update_bank_accounts_by_pk?: Maybe<BankAccounts>;
  /** update data of the table: "bank_financial_summaries" */
  update_bank_financial_summaries?: Maybe<BankFinancialSummariesMutationResponse>;
  /** update single row of the table: "bank_financial_summaries" */
  update_bank_financial_summaries_by_pk?: Maybe<BankFinancialSummaries>;
  /** update data of the table: "bespoke_catalog_brands" */
  update_bespoke_catalog_brands?: Maybe<BespokeCatalogBrandsMutationResponse>;
  /** update single row of the table: "bespoke_catalog_brands" */
  update_bespoke_catalog_brands_by_pk?: Maybe<BespokeCatalogBrands>;
  /** update data of the table: "bespoke_catalog_sku_groups" */
  update_bespoke_catalog_sku_groups?: Maybe<BespokeCatalogSkuGroupsMutationResponse>;
  /** update single row of the table: "bespoke_catalog_sku_groups" */
  update_bespoke_catalog_sku_groups_by_pk?: Maybe<BespokeCatalogSkuGroups>;
  /** update data of the table: "bespoke_catalog_skus" */
  update_bespoke_catalog_skus?: Maybe<BespokeCatalogSkusMutationResponse>;
  /** update single row of the table: "bespoke_catalog_skus" */
  update_bespoke_catalog_skus_by_pk?: Maybe<BespokeCatalogSkus>;
  /** update data of the table: "blaze_preapprovals" */
  update_blaze_preapprovals?: Maybe<BlazePreapprovalsMutationResponse>;
  /** update single row of the table: "blaze_preapprovals" */
  update_blaze_preapprovals_by_pk?: Maybe<BlazePreapprovals>;
  /** update data of the table: "blaze_shop_entries" */
  update_blaze_shop_entries?: Maybe<BlazeShopEntriesMutationResponse>;
  /** update single row of the table: "blaze_shop_entries" */
  update_blaze_shop_entries_by_pk?: Maybe<BlazeShopEntries>;
  /** update data of the table: "blaze_users" */
  update_blaze_users?: Maybe<BlazeUsersMutationResponse>;
  /** update single row of the table: "blaze_users" */
  update_blaze_users_by_pk?: Maybe<BlazeUsers>;
  /** update data of the table: "companies" */
  update_companies?: Maybe<CompaniesMutationResponse>;
  /** update single row of the table: "companies" */
  update_companies_by_pk?: Maybe<Companies>;
  /** update data of the table: "company_agreements" */
  update_company_agreements?: Maybe<CompanyAgreementsMutationResponse>;
  /** update single row of the table: "company_agreements" */
  update_company_agreements_by_pk?: Maybe<CompanyAgreements>;
  /** update data of the table: "company_deliveries" */
  update_company_deliveries?: Maybe<CompanyDeliveriesMutationResponse>;
  /** update single row of the table: "company_deliveries" */
  update_company_deliveries_by_pk?: Maybe<CompanyDeliveries>;
  /** update data of the table: "company_facilities" */
  update_company_facilities?: Maybe<CompanyFacilitiesMutationResponse>;
  /** update single row of the table: "company_facilities" */
  update_company_facilities_by_pk?: Maybe<CompanyFacilities>;
  /** update data of the table: "company_licenses" */
  update_company_licenses?: Maybe<CompanyLicensesMutationResponse>;
  /** update single row of the table: "company_licenses" */
  update_company_licenses_by_pk?: Maybe<CompanyLicenses>;
  /** update data of the table: "company_partnership_invitations" */
  update_company_partnership_invitations?: Maybe<CompanyPartnershipInvitationsMutationResponse>;
  /** update single row of the table: "company_partnership_invitations" */
  update_company_partnership_invitations_by_pk?: Maybe<CompanyPartnershipInvitations>;
  /** update data of the table: "company_partnership_requests" */
  update_company_partnership_requests?: Maybe<CompanyPartnershipRequestsMutationResponse>;
  /** update single row of the table: "company_partnership_requests" */
  update_company_partnership_requests_by_pk?: Maybe<CompanyPartnershipRequests>;
  /** update data of the table: "company_payor_contacts" */
  update_company_payor_contacts?: Maybe<CompanyPayorContactsMutationResponse>;
  /** update single row of the table: "company_payor_contacts" */
  update_company_payor_contacts_by_pk?: Maybe<CompanyPayorContacts>;
  /** update data of the table: "company_payor_partnerships" */
  update_company_payor_partnerships?: Maybe<CompanyPayorPartnershipsMutationResponse>;
  /** update single row of the table: "company_payor_partnerships" */
  update_company_payor_partnerships_by_pk?: Maybe<CompanyPayorPartnerships>;
  /** update data of the table: "company_settings" */
  update_company_settings?: Maybe<CompanySettingsMutationResponse>;
  /** update single row of the table: "company_settings" */
  update_company_settings_by_pk?: Maybe<CompanySettings>;
  /** update data of the table: "company_type" */
  update_company_type?: Maybe<CompanyTypeMutationResponse>;
  /** update single row of the table: "company_type" */
  update_company_type_by_pk?: Maybe<CompanyType>;
  /** update data of the table: "company_vendor_contacts" */
  update_company_vendor_contacts?: Maybe<CompanyVendorContactsMutationResponse>;
  /** update single row of the table: "company_vendor_contacts" */
  update_company_vendor_contacts_by_pk?: Maybe<CompanyVendorContacts>;
  /** update data of the table: "company_vendor_partnerships" */
  update_company_vendor_partnerships?: Maybe<CompanyVendorPartnershipsMutationResponse>;
  /** update single row of the table: "company_vendor_partnerships" */
  update_company_vendor_partnerships_by_pk?: Maybe<CompanyVendorPartnerships>;
  /** update data of the table: "contracts" */
  update_contracts?: Maybe<ContractsMutationResponse>;
  /** update single row of the table: "contracts" */
  update_contracts_by_pk?: Maybe<Contracts>;
  /** update data of the table: "customer_surveillance_results" */
  update_customer_surveillance_results?: Maybe<CustomerSurveillanceResultsMutationResponse>;
  /** update single row of the table: "customer_surveillance_results" */
  update_customer_surveillance_results_by_pk?: Maybe<CustomerSurveillanceResults>;
  /** update data of the table: "debt_facilities" */
  update_debt_facilities?: Maybe<DebtFacilitiesMutationResponse>;
  /** update single row of the table: "debt_facilities" */
  update_debt_facilities_by_pk?: Maybe<DebtFacilities>;
  /** update data of the table: "debt_facility_capacities" */
  update_debt_facility_capacities?: Maybe<DebtFacilityCapacitiesMutationResponse>;
  /** update single row of the table: "debt_facility_capacities" */
  update_debt_facility_capacities_by_pk?: Maybe<DebtFacilityCapacities>;
  /** update data of the table: "debt_facility_events" */
  update_debt_facility_events?: Maybe<DebtFacilityEventsMutationResponse>;
  /** update single row of the table: "debt_facility_events" */
  update_debt_facility_events_by_pk?: Maybe<DebtFacilityEvents>;
  /** update data of the table: "ebba_application_files" */
  update_ebba_application_files?: Maybe<EbbaApplicationFilesMutationResponse>;
  /** update single row of the table: "ebba_application_files" */
  update_ebba_application_files_by_pk?: Maybe<EbbaApplicationFiles>;
  /** update data of the table: "ebba_applications" */
  update_ebba_applications?: Maybe<EbbaApplicationsMutationResponse>;
  /** update single row of the table: "ebba_applications" */
  update_ebba_applications_by_pk?: Maybe<EbbaApplications>;
  /** update data of the table: "files" */
  update_files?: Maybe<FilesMutationResponse>;
  /** update single row of the table: "files" */
  update_files_by_pk?: Maybe<Files>;
  /** update data of the table: "financial_summaries" */
  update_financial_summaries?: Maybe<FinancialSummariesMutationResponse>;
  /** update single row of the table: "financial_summaries" */
  update_financial_summaries_by_pk?: Maybe<FinancialSummaries>;
  /** update data of the table: "invoice_file_type" */
  update_invoice_file_type?: Maybe<InvoiceFileTypeMutationResponse>;
  /** update single row of the table: "invoice_file_type" */
  update_invoice_file_type_by_pk?: Maybe<InvoiceFileType>;
  /** update data of the table: "invoice_files" */
  update_invoice_files?: Maybe<InvoiceFilesMutationResponse>;
  /** update single row of the table: "invoice_files" */
  update_invoice_files_by_pk?: Maybe<InvoiceFiles>;
  /** update data of the table: "invoices" */
  update_invoices?: Maybe<InvoicesMutationResponse>;
  /** update single row of the table: "invoices" */
  update_invoices_by_pk?: Maybe<Invoices>;
  /** update data of the table: "line_of_credits" */
  update_line_of_credits?: Maybe<LineOfCreditsMutationResponse>;
  /** update single row of the table: "line_of_credits" */
  update_line_of_credits_by_pk?: Maybe<LineOfCredits>;
  /** update data of the table: "loan_reports" */
  update_loan_reports?: Maybe<LoanReportsMutationResponse>;
  /** update single row of the table: "loan_reports" */
  update_loan_reports_by_pk?: Maybe<LoanReports>;
  /** update data of the table: "loan_type" */
  update_loan_type?: Maybe<LoanTypeMutationResponse>;
  /** update single row of the table: "loan_type" */
  update_loan_type_by_pk?: Maybe<LoanType>;
  /** update data of the table: "loans" */
  update_loans?: Maybe<LoansMutationResponse>;
  /** update single row of the table: "loans" */
  update_loans_by_pk?: Maybe<Loans>;
  /** update data of the table: "metrc_analysis_summaries" */
  update_metrc_analysis_summaries?: Maybe<MetrcAnalysisSummariesMutationResponse>;
  /** update single row of the table: "metrc_analysis_summaries" */
  update_metrc_analysis_summaries_by_pk?: Maybe<MetrcAnalysisSummaries>;
  /** update data of the table: "metrc_api_keys" */
  update_metrc_api_keys?: Maybe<MetrcApiKeysMutationResponse>;
  /** update single row of the table: "metrc_api_keys" */
  update_metrc_api_keys_by_pk?: Maybe<MetrcApiKeys>;
  /** update data of the table: "metrc_deliveries" */
  update_metrc_deliveries?: Maybe<MetrcDeliveriesMutationResponse>;
  /** update single row of the table: "metrc_deliveries" */
  update_metrc_deliveries_by_pk?: Maybe<MetrcDeliveries>;
  /** update data of the table: "metrc_download_summaries" */
  update_metrc_download_summaries?: Maybe<MetrcDownloadSummariesMutationResponse>;
  /** update single row of the table: "metrc_download_summaries" */
  update_metrc_download_summaries_by_pk?: Maybe<MetrcDownloadSummaries>;
  /** update data of the table: "metrc_harvests" */
  update_metrc_harvests?: Maybe<MetrcHarvestsMutationResponse>;
  /** update single row of the table: "metrc_harvests" */
  update_metrc_harvests_by_pk?: Maybe<MetrcHarvests>;
  /** update data of the table: "metrc_packages" */
  update_metrc_packages?: Maybe<MetrcPackagesMutationResponse>;
  /** update single row of the table: "metrc_packages" */
  update_metrc_packages_by_pk?: Maybe<MetrcPackages>;
  /** update data of the table: "metrc_plant_batches" */
  update_metrc_plant_batches?: Maybe<MetrcPlantBatchesMutationResponse>;
  /** update single row of the table: "metrc_plant_batches" */
  update_metrc_plant_batches_by_pk?: Maybe<MetrcPlantBatches>;
  /** update data of the table: "metrc_plants" */
  update_metrc_plants?: Maybe<MetrcPlantsMutationResponse>;
  /** update single row of the table: "metrc_plants" */
  update_metrc_plants_by_pk?: Maybe<MetrcPlants>;
  /** update data of the table: "metrc_sales_receipts" */
  update_metrc_sales_receipts?: Maybe<MetrcSalesReceiptsMutationResponse>;
  /** update single row of the table: "metrc_sales_receipts" */
  update_metrc_sales_receipts_by_pk?: Maybe<MetrcSalesReceipts>;
  /** update data of the table: "metrc_sales_transactions" */
  update_metrc_sales_transactions?: Maybe<MetrcSalesTransactionsMutationResponse>;
  /** update single row of the table: "metrc_sales_transactions" */
  update_metrc_sales_transactions_by_pk?: Maybe<MetrcSalesTransactions>;
  /** update data of the table: "metrc_to_bespoke_catalog_skus" */
  update_metrc_to_bespoke_catalog_skus?: Maybe<MetrcToBespokeCatalogSkusMutationResponse>;
  /** update single row of the table: "metrc_to_bespoke_catalog_skus" */
  update_metrc_to_bespoke_catalog_skus_by_pk?: Maybe<MetrcToBespokeCatalogSkus>;
  /** update data of the table: "metrc_transfer_packages" */
  update_metrc_transfer_packages?: Maybe<MetrcTransferPackagesMutationResponse>;
  /** update single row of the table: "metrc_transfer_packages" */
  update_metrc_transfer_packages_by_pk?: Maybe<MetrcTransferPackages>;
  /** update data of the table: "metrc_transfers" */
  update_metrc_transfers?: Maybe<MetrcTransfersMutationResponse>;
  /** update single row of the table: "metrc_transfers" */
  update_metrc_transfers_by_pk?: Maybe<MetrcTransfers>;
  /** update data of the table: "monthly_summary_calculations" */
  update_monthly_summary_calculations?: Maybe<MonthlySummaryCalculationsMutationResponse>;
  /** update single row of the table: "monthly_summary_calculations" */
  update_monthly_summary_calculations_by_pk?: Maybe<MonthlySummaryCalculations>;
  /** update data of the table: "parent_companies" */
  update_parent_companies?: Maybe<ParentCompaniesMutationResponse>;
  /** update single row of the table: "parent_companies" */
  update_parent_companies_by_pk?: Maybe<ParentCompanies>;
  /** update data of the table: "payments" */
  update_payments?: Maybe<PaymentsMutationResponse>;
  /** update single row of the table: "payments" */
  update_payments_by_pk?: Maybe<Payments>;
  /** update data of the table: "payors" */
  update_payors?: Maybe<PayorsMutationResponse>;
  /** update data of the table: "purchase_order_file_type" */
  update_purchase_order_file_type?: Maybe<PurchaseOrderFileTypeMutationResponse>;
  /** update single row of the table: "purchase_order_file_type" */
  update_purchase_order_file_type_by_pk?: Maybe<PurchaseOrderFileType>;
  /** update data of the table: "purchase_order_files" */
  update_purchase_order_files?: Maybe<PurchaseOrderFilesMutationResponse>;
  /** update single row of the table: "purchase_order_files" */
  update_purchase_order_files_by_pk?: Maybe<PurchaseOrderFiles>;
  /** update data of the table: "purchase_order_metrc_transfers" */
  update_purchase_order_metrc_transfers?: Maybe<PurchaseOrderMetrcTransfersMutationResponse>;
  /** update single row of the table: "purchase_order_metrc_transfers" */
  update_purchase_order_metrc_transfers_by_pk?: Maybe<PurchaseOrderMetrcTransfers>;
  /** update data of the table: "purchase_orders" */
  update_purchase_orders?: Maybe<PurchaseOrdersMutationResponse>;
  /** update single row of the table: "purchase_orders" */
  update_purchase_orders_by_pk?: Maybe<PurchaseOrders>;
  /** update data of the table: "request_status" */
  update_request_status?: Maybe<RequestStatusMutationResponse>;
  /** update single row of the table: "request_status" */
  update_request_status_by_pk?: Maybe<RequestStatus>;
  /** update data of the table: "revoked_tokens" */
  update_revoked_tokens?: Maybe<RevokedTokensMutationResponse>;
  /** update single row of the table: "revoked_tokens" */
  update_revoked_tokens_by_pk?: Maybe<RevokedTokens>;
  /** update data of the table: "sync_pipelines" */
  update_sync_pipelines?: Maybe<SyncPipelinesMutationResponse>;
  /** update single row of the table: "sync_pipelines" */
  update_sync_pipelines_by_pk?: Maybe<SyncPipelines>;
  /** update data of the table: "transactions" */
  update_transactions?: Maybe<TransactionsMutationResponse>;
  /** update single row of the table: "transactions" */
  update_transactions_by_pk?: Maybe<Transactions>;
  /** update data of the table: "two_factor_links" */
  update_two_factor_links?: Maybe<TwoFactorLinksMutationResponse>;
  /** update single row of the table: "two_factor_links" */
  update_two_factor_links_by_pk?: Maybe<TwoFactorLinks>;
  /** update data of the table: "user_roles" */
  update_user_roles?: Maybe<UserRolesMutationResponse>;
  /** update single row of the table: "user_roles" */
  update_user_roles_by_pk?: Maybe<UserRoles>;
  /** update data of the table: "users" */
  update_users?: Maybe<UsersMutationResponse>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "vendor_change_requests" */
  update_vendor_change_requests?: Maybe<VendorChangeRequestsMutationResponse>;
  /** update single row of the table: "vendor_change_requests" */
  update_vendor_change_requests_by_pk?: Maybe<VendorChangeRequests>;
  /** update data of the table: "vendors" */
  update_vendors?: Maybe<VendorsMutationResponse>;
};

/** mutation root */
export type MutationRootDeleteAsyncJobSummariesArgs = {
  where: AsyncJobSummariesBoolExp;
};

/** mutation root */
export type MutationRootDeleteAsyncJobSummariesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteAsyncJobsArgs = {
  where: AsyncJobsBoolExp;
};

/** mutation root */
export type MutationRootDeleteAsyncJobsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteAsyncPipelinesArgs = {
  where: AsyncPipelinesBoolExp;
};

/** mutation root */
export type MutationRootDeleteAsyncPipelinesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteAuditEventsArgs = {
  where: AuditEventsBoolExp;
};

/** mutation root */
export type MutationRootDeleteAuditEventsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteBankAccountsArgs = {
  where: BankAccountsBoolExp;
};

/** mutation root */
export type MutationRootDeleteBankAccountsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteBankFinancialSummariesArgs = {
  where: BankFinancialSummariesBoolExp;
};

/** mutation root */
export type MutationRootDeleteBankFinancialSummariesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteBespokeCatalogBrandsArgs = {
  where: BespokeCatalogBrandsBoolExp;
};

/** mutation root */
export type MutationRootDeleteBespokeCatalogBrandsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteBespokeCatalogSkuGroupsArgs = {
  where: BespokeCatalogSkuGroupsBoolExp;
};

/** mutation root */
export type MutationRootDeleteBespokeCatalogSkuGroupsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteBespokeCatalogSkusArgs = {
  where: BespokeCatalogSkusBoolExp;
};

/** mutation root */
export type MutationRootDeleteBespokeCatalogSkusByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteBlazePreapprovalsArgs = {
  where: BlazePreapprovalsBoolExp;
};

/** mutation root */
export type MutationRootDeleteBlazePreapprovalsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteBlazeShopEntriesArgs = {
  where: BlazeShopEntriesBoolExp;
};

/** mutation root */
export type MutationRootDeleteBlazeShopEntriesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteBlazeUsersArgs = {
  where: BlazeUsersBoolExp;
};

/** mutation root */
export type MutationRootDeleteBlazeUsersByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteCompaniesArgs = {
  where: CompaniesBoolExp;
};

/** mutation root */
export type MutationRootDeleteCompaniesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteCompanyAgreementsArgs = {
  where: CompanyAgreementsBoolExp;
};

/** mutation root */
export type MutationRootDeleteCompanyAgreementsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteCompanyDeliveriesArgs = {
  where: CompanyDeliveriesBoolExp;
};

/** mutation root */
export type MutationRootDeleteCompanyDeliveriesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteCompanyFacilitiesArgs = {
  where: CompanyFacilitiesBoolExp;
};

/** mutation root */
export type MutationRootDeleteCompanyFacilitiesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteCompanyLicensesArgs = {
  where: CompanyLicensesBoolExp;
};

/** mutation root */
export type MutationRootDeleteCompanyLicensesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteCompanyPartnershipInvitationsArgs = {
  where: CompanyPartnershipInvitationsBoolExp;
};

/** mutation root */
export type MutationRootDeleteCompanyPartnershipInvitationsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteCompanyPartnershipRequestsArgs = {
  where: CompanyPartnershipRequestsBoolExp;
};

/** mutation root */
export type MutationRootDeleteCompanyPartnershipRequestsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteCompanyPayorContactsArgs = {
  where: CompanyPayorContactsBoolExp;
};

/** mutation root */
export type MutationRootDeleteCompanyPayorContactsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteCompanyPayorPartnershipsArgs = {
  where: CompanyPayorPartnershipsBoolExp;
};

/** mutation root */
export type MutationRootDeleteCompanyPayorPartnershipsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteCompanySettingsArgs = {
  where: CompanySettingsBoolExp;
};

/** mutation root */
export type MutationRootDeleteCompanySettingsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteCompanyTypeArgs = {
  where: CompanyTypeBoolExp;
};

/** mutation root */
export type MutationRootDeleteCompanyTypeByPkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type MutationRootDeleteCompanyVendorContactsArgs = {
  where: CompanyVendorContactsBoolExp;
};

/** mutation root */
export type MutationRootDeleteCompanyVendorContactsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteCompanyVendorPartnershipsArgs = {
  where: CompanyVendorPartnershipsBoolExp;
};

/** mutation root */
export type MutationRootDeleteCompanyVendorPartnershipsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteContractsArgs = {
  where: ContractsBoolExp;
};

/** mutation root */
export type MutationRootDeleteContractsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteCustomerSurveillanceResultsArgs = {
  where: CustomerSurveillanceResultsBoolExp;
};

/** mutation root */
export type MutationRootDeleteCustomerSurveillanceResultsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteDebtFacilitiesArgs = {
  where: DebtFacilitiesBoolExp;
};

/** mutation root */
export type MutationRootDeleteDebtFacilitiesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteDebtFacilityCapacitiesArgs = {
  where: DebtFacilityCapacitiesBoolExp;
};

/** mutation root */
export type MutationRootDeleteDebtFacilityCapacitiesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteDebtFacilityEventsArgs = {
  where: DebtFacilityEventsBoolExp;
};

/** mutation root */
export type MutationRootDeleteDebtFacilityEventsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteEbbaApplicationFilesArgs = {
  where: EbbaApplicationFilesBoolExp;
};

/** mutation root */
export type MutationRootDeleteEbbaApplicationFilesByPkArgs = {
  ebba_application_id: Scalars["uuid"];
  file_id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteEbbaApplicationsArgs = {
  where: EbbaApplicationsBoolExp;
};

/** mutation root */
export type MutationRootDeleteEbbaApplicationsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteFilesArgs = {
  where: FilesBoolExp;
};

/** mutation root */
export type MutationRootDeleteFilesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteFinancialSummariesArgs = {
  where: FinancialSummariesBoolExp;
};

/** mutation root */
export type MutationRootDeleteFinancialSummariesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteInvoiceFileTypeArgs = {
  where: InvoiceFileTypeBoolExp;
};

/** mutation root */
export type MutationRootDeleteInvoiceFileTypeByPkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type MutationRootDeleteInvoiceFilesArgs = {
  where: InvoiceFilesBoolExp;
};

/** mutation root */
export type MutationRootDeleteInvoiceFilesByPkArgs = {
  file_id: Scalars["uuid"];
  invoice_id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteInvoicesArgs = {
  where: InvoicesBoolExp;
};

/** mutation root */
export type MutationRootDeleteInvoicesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteLineOfCreditsArgs = {
  where: LineOfCreditsBoolExp;
};

/** mutation root */
export type MutationRootDeleteLineOfCreditsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteLoanReportsArgs = {
  where: LoanReportsBoolExp;
};

/** mutation root */
export type MutationRootDeleteLoanReportsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteLoanTypeArgs = {
  where: LoanTypeBoolExp;
};

/** mutation root */
export type MutationRootDeleteLoanTypeByPkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type MutationRootDeleteLoansArgs = {
  where: LoansBoolExp;
};

/** mutation root */
export type MutationRootDeleteLoansByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteMetrcAnalysisSummariesArgs = {
  where: MetrcAnalysisSummariesBoolExp;
};

/** mutation root */
export type MutationRootDeleteMetrcAnalysisSummariesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteMetrcApiKeysArgs = {
  where: MetrcApiKeysBoolExp;
};

/** mutation root */
export type MutationRootDeleteMetrcApiKeysByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteMetrcDeliveriesArgs = {
  where: MetrcDeliveriesBoolExp;
};

/** mutation root */
export type MutationRootDeleteMetrcDeliveriesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteMetrcDownloadSummariesArgs = {
  where: MetrcDownloadSummariesBoolExp;
};

/** mutation root */
export type MutationRootDeleteMetrcDownloadSummariesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteMetrcHarvestsArgs = {
  where: MetrcHarvestsBoolExp;
};

/** mutation root */
export type MutationRootDeleteMetrcHarvestsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteMetrcPackagesArgs = {
  where: MetrcPackagesBoolExp;
};

/** mutation root */
export type MutationRootDeleteMetrcPackagesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteMetrcPlantBatchesArgs = {
  where: MetrcPlantBatchesBoolExp;
};

/** mutation root */
export type MutationRootDeleteMetrcPlantBatchesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteMetrcPlantsArgs = {
  where: MetrcPlantsBoolExp;
};

/** mutation root */
export type MutationRootDeleteMetrcPlantsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteMetrcSalesReceiptsArgs = {
  where: MetrcSalesReceiptsBoolExp;
};

/** mutation root */
export type MutationRootDeleteMetrcSalesReceiptsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteMetrcSalesTransactionsArgs = {
  where: MetrcSalesTransactionsBoolExp;
};

/** mutation root */
export type MutationRootDeleteMetrcSalesTransactionsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteMetrcToBespokeCatalogSkusArgs = {
  where: MetrcToBespokeCatalogSkusBoolExp;
};

/** mutation root */
export type MutationRootDeleteMetrcToBespokeCatalogSkusByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteMetrcTransferPackagesArgs = {
  where: MetrcTransferPackagesBoolExp;
};

/** mutation root */
export type MutationRootDeleteMetrcTransferPackagesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteMetrcTransfersArgs = {
  where: MetrcTransfersBoolExp;
};

/** mutation root */
export type MutationRootDeleteMetrcTransfersByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteMonthlySummaryCalculationsArgs = {
  where: MonthlySummaryCalculationsBoolExp;
};

/** mutation root */
export type MutationRootDeleteMonthlySummaryCalculationsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteParentCompaniesArgs = {
  where: ParentCompaniesBoolExp;
};

/** mutation root */
export type MutationRootDeleteParentCompaniesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeletePaymentsArgs = {
  where: PaymentsBoolExp;
};

/** mutation root */
export type MutationRootDeletePaymentsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeletePayorsArgs = {
  where: PayorsBoolExp;
};

/** mutation root */
export type MutationRootDeletePurchaseOrderFileTypeArgs = {
  where: PurchaseOrderFileTypeBoolExp;
};

/** mutation root */
export type MutationRootDeletePurchaseOrderFileTypeByPkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type MutationRootDeletePurchaseOrderFilesArgs = {
  where: PurchaseOrderFilesBoolExp;
};

/** mutation root */
export type MutationRootDeletePurchaseOrderFilesByPkArgs = {
  file_id: Scalars["uuid"];
  purchase_order_id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeletePurchaseOrderMetrcTransfersArgs = {
  where: PurchaseOrderMetrcTransfersBoolExp;
};

/** mutation root */
export type MutationRootDeletePurchaseOrderMetrcTransfersByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeletePurchaseOrdersArgs = {
  where: PurchaseOrdersBoolExp;
};

/** mutation root */
export type MutationRootDeletePurchaseOrdersByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteRequestStatusArgs = {
  where: RequestStatusBoolExp;
};

/** mutation root */
export type MutationRootDeleteRequestStatusByPkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type MutationRootDeleteRevokedTokensArgs = {
  where: RevokedTokensBoolExp;
};

/** mutation root */
export type MutationRootDeleteRevokedTokensByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteSyncPipelinesArgs = {
  where: SyncPipelinesBoolExp;
};

/** mutation root */
export type MutationRootDeleteSyncPipelinesByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteTransactionsArgs = {
  where: TransactionsBoolExp;
};

/** mutation root */
export type MutationRootDeleteTransactionsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteTwoFactorLinksArgs = {
  where: TwoFactorLinksBoolExp;
};

/** mutation root */
export type MutationRootDeleteTwoFactorLinksByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteUserRolesArgs = {
  where: UserRolesBoolExp;
};

/** mutation root */
export type MutationRootDeleteUserRolesByPkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type MutationRootDeleteUsersArgs = {
  where: UsersBoolExp;
};

/** mutation root */
export type MutationRootDeleteUsersByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteVendorChangeRequestsArgs = {
  where: VendorChangeRequestsBoolExp;
};

/** mutation root */
export type MutationRootDeleteVendorChangeRequestsByPkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationRootDeleteVendorsArgs = {
  where: VendorsBoolExp;
};

/** mutation root */
export type MutationRootInsertAsyncJobSummariesArgs = {
  objects: Array<AsyncJobSummariesInsertInput>;
  on_conflict?: Maybe<AsyncJobSummariesOnConflict>;
};

/** mutation root */
export type MutationRootInsertAsyncJobSummariesOneArgs = {
  object: AsyncJobSummariesInsertInput;
  on_conflict?: Maybe<AsyncJobSummariesOnConflict>;
};

/** mutation root */
export type MutationRootInsertAsyncJobsArgs = {
  objects: Array<AsyncJobsInsertInput>;
  on_conflict?: Maybe<AsyncJobsOnConflict>;
};

/** mutation root */
export type MutationRootInsertAsyncJobsOneArgs = {
  object: AsyncJobsInsertInput;
  on_conflict?: Maybe<AsyncJobsOnConflict>;
};

/** mutation root */
export type MutationRootInsertAsyncPipelinesArgs = {
  objects: Array<AsyncPipelinesInsertInput>;
  on_conflict?: Maybe<AsyncPipelinesOnConflict>;
};

/** mutation root */
export type MutationRootInsertAsyncPipelinesOneArgs = {
  object: AsyncPipelinesInsertInput;
  on_conflict?: Maybe<AsyncPipelinesOnConflict>;
};

/** mutation root */
export type MutationRootInsertAuditEventsArgs = {
  objects: Array<AuditEventsInsertInput>;
  on_conflict?: Maybe<AuditEventsOnConflict>;
};

/** mutation root */
export type MutationRootInsertAuditEventsOneArgs = {
  object: AuditEventsInsertInput;
  on_conflict?: Maybe<AuditEventsOnConflict>;
};

/** mutation root */
export type MutationRootInsertBankAccountsArgs = {
  objects: Array<BankAccountsInsertInput>;
  on_conflict?: Maybe<BankAccountsOnConflict>;
};

/** mutation root */
export type MutationRootInsertBankAccountsOneArgs = {
  object: BankAccountsInsertInput;
  on_conflict?: Maybe<BankAccountsOnConflict>;
};

/** mutation root */
export type MutationRootInsertBankFinancialSummariesArgs = {
  objects: Array<BankFinancialSummariesInsertInput>;
  on_conflict?: Maybe<BankFinancialSummariesOnConflict>;
};

/** mutation root */
export type MutationRootInsertBankFinancialSummariesOneArgs = {
  object: BankFinancialSummariesInsertInput;
  on_conflict?: Maybe<BankFinancialSummariesOnConflict>;
};

/** mutation root */
export type MutationRootInsertBespokeCatalogBrandsArgs = {
  objects: Array<BespokeCatalogBrandsInsertInput>;
  on_conflict?: Maybe<BespokeCatalogBrandsOnConflict>;
};

/** mutation root */
export type MutationRootInsertBespokeCatalogBrandsOneArgs = {
  object: BespokeCatalogBrandsInsertInput;
  on_conflict?: Maybe<BespokeCatalogBrandsOnConflict>;
};

/** mutation root */
export type MutationRootInsertBespokeCatalogSkuGroupsArgs = {
  objects: Array<BespokeCatalogSkuGroupsInsertInput>;
  on_conflict?: Maybe<BespokeCatalogSkuGroupsOnConflict>;
};

/** mutation root */
export type MutationRootInsertBespokeCatalogSkuGroupsOneArgs = {
  object: BespokeCatalogSkuGroupsInsertInput;
  on_conflict?: Maybe<BespokeCatalogSkuGroupsOnConflict>;
};

/** mutation root */
export type MutationRootInsertBespokeCatalogSkusArgs = {
  objects: Array<BespokeCatalogSkusInsertInput>;
  on_conflict?: Maybe<BespokeCatalogSkusOnConflict>;
};

/** mutation root */
export type MutationRootInsertBespokeCatalogSkusOneArgs = {
  object: BespokeCatalogSkusInsertInput;
  on_conflict?: Maybe<BespokeCatalogSkusOnConflict>;
};

/** mutation root */
export type MutationRootInsertBlazePreapprovalsArgs = {
  objects: Array<BlazePreapprovalsInsertInput>;
  on_conflict?: Maybe<BlazePreapprovalsOnConflict>;
};

/** mutation root */
export type MutationRootInsertBlazePreapprovalsOneArgs = {
  object: BlazePreapprovalsInsertInput;
  on_conflict?: Maybe<BlazePreapprovalsOnConflict>;
};

/** mutation root */
export type MutationRootInsertBlazeShopEntriesArgs = {
  objects: Array<BlazeShopEntriesInsertInput>;
  on_conflict?: Maybe<BlazeShopEntriesOnConflict>;
};

/** mutation root */
export type MutationRootInsertBlazeShopEntriesOneArgs = {
  object: BlazeShopEntriesInsertInput;
  on_conflict?: Maybe<BlazeShopEntriesOnConflict>;
};

/** mutation root */
export type MutationRootInsertBlazeUsersArgs = {
  objects: Array<BlazeUsersInsertInput>;
  on_conflict?: Maybe<BlazeUsersOnConflict>;
};

/** mutation root */
export type MutationRootInsertBlazeUsersOneArgs = {
  object: BlazeUsersInsertInput;
  on_conflict?: Maybe<BlazeUsersOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompaniesArgs = {
  objects: Array<CompaniesInsertInput>;
  on_conflict?: Maybe<CompaniesOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompaniesOneArgs = {
  object: CompaniesInsertInput;
  on_conflict?: Maybe<CompaniesOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyAgreementsArgs = {
  objects: Array<CompanyAgreementsInsertInput>;
  on_conflict?: Maybe<CompanyAgreementsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyAgreementsOneArgs = {
  object: CompanyAgreementsInsertInput;
  on_conflict?: Maybe<CompanyAgreementsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyDeliveriesArgs = {
  objects: Array<CompanyDeliveriesInsertInput>;
  on_conflict?: Maybe<CompanyDeliveriesOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyDeliveriesOneArgs = {
  object: CompanyDeliveriesInsertInput;
  on_conflict?: Maybe<CompanyDeliveriesOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyFacilitiesArgs = {
  objects: Array<CompanyFacilitiesInsertInput>;
  on_conflict?: Maybe<CompanyFacilitiesOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyFacilitiesOneArgs = {
  object: CompanyFacilitiesInsertInput;
  on_conflict?: Maybe<CompanyFacilitiesOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyLicensesArgs = {
  objects: Array<CompanyLicensesInsertInput>;
  on_conflict?: Maybe<CompanyLicensesOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyLicensesOneArgs = {
  object: CompanyLicensesInsertInput;
  on_conflict?: Maybe<CompanyLicensesOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyPartnershipInvitationsArgs = {
  objects: Array<CompanyPartnershipInvitationsInsertInput>;
  on_conflict?: Maybe<CompanyPartnershipInvitationsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyPartnershipInvitationsOneArgs = {
  object: CompanyPartnershipInvitationsInsertInput;
  on_conflict?: Maybe<CompanyPartnershipInvitationsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyPartnershipRequestsArgs = {
  objects: Array<CompanyPartnershipRequestsInsertInput>;
  on_conflict?: Maybe<CompanyPartnershipRequestsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyPartnershipRequestsOneArgs = {
  object: CompanyPartnershipRequestsInsertInput;
  on_conflict?: Maybe<CompanyPartnershipRequestsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyPayorContactsArgs = {
  objects: Array<CompanyPayorContactsInsertInput>;
  on_conflict?: Maybe<CompanyPayorContactsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyPayorContactsOneArgs = {
  object: CompanyPayorContactsInsertInput;
  on_conflict?: Maybe<CompanyPayorContactsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyPayorPartnershipsArgs = {
  objects: Array<CompanyPayorPartnershipsInsertInput>;
  on_conflict?: Maybe<CompanyPayorPartnershipsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyPayorPartnershipsOneArgs = {
  object: CompanyPayorPartnershipsInsertInput;
  on_conflict?: Maybe<CompanyPayorPartnershipsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanySettingsArgs = {
  objects: Array<CompanySettingsInsertInput>;
  on_conflict?: Maybe<CompanySettingsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanySettingsOneArgs = {
  object: CompanySettingsInsertInput;
  on_conflict?: Maybe<CompanySettingsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyTypeArgs = {
  objects: Array<CompanyTypeInsertInput>;
  on_conflict?: Maybe<CompanyTypeOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyTypeOneArgs = {
  object: CompanyTypeInsertInput;
  on_conflict?: Maybe<CompanyTypeOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyVendorContactsArgs = {
  objects: Array<CompanyVendorContactsInsertInput>;
  on_conflict?: Maybe<CompanyVendorContactsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyVendorContactsOneArgs = {
  object: CompanyVendorContactsInsertInput;
  on_conflict?: Maybe<CompanyVendorContactsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyVendorPartnershipsArgs = {
  objects: Array<CompanyVendorPartnershipsInsertInput>;
  on_conflict?: Maybe<CompanyVendorPartnershipsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCompanyVendorPartnershipsOneArgs = {
  object: CompanyVendorPartnershipsInsertInput;
  on_conflict?: Maybe<CompanyVendorPartnershipsOnConflict>;
};

/** mutation root */
export type MutationRootInsertContractsArgs = {
  objects: Array<ContractsInsertInput>;
  on_conflict?: Maybe<ContractsOnConflict>;
};

/** mutation root */
export type MutationRootInsertContractsOneArgs = {
  object: ContractsInsertInput;
  on_conflict?: Maybe<ContractsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCustomerSurveillanceResultsArgs = {
  objects: Array<CustomerSurveillanceResultsInsertInput>;
  on_conflict?: Maybe<CustomerSurveillanceResultsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCustomerSurveillanceResultsOneArgs = {
  object: CustomerSurveillanceResultsInsertInput;
  on_conflict?: Maybe<CustomerSurveillanceResultsOnConflict>;
};

/** mutation root */
export type MutationRootInsertDebtFacilitiesArgs = {
  objects: Array<DebtFacilitiesInsertInput>;
  on_conflict?: Maybe<DebtFacilitiesOnConflict>;
};

/** mutation root */
export type MutationRootInsertDebtFacilitiesOneArgs = {
  object: DebtFacilitiesInsertInput;
  on_conflict?: Maybe<DebtFacilitiesOnConflict>;
};

/** mutation root */
export type MutationRootInsertDebtFacilityCapacitiesArgs = {
  objects: Array<DebtFacilityCapacitiesInsertInput>;
  on_conflict?: Maybe<DebtFacilityCapacitiesOnConflict>;
};

/** mutation root */
export type MutationRootInsertDebtFacilityCapacitiesOneArgs = {
  object: DebtFacilityCapacitiesInsertInput;
  on_conflict?: Maybe<DebtFacilityCapacitiesOnConflict>;
};

/** mutation root */
export type MutationRootInsertDebtFacilityEventsArgs = {
  objects: Array<DebtFacilityEventsInsertInput>;
  on_conflict?: Maybe<DebtFacilityEventsOnConflict>;
};

/** mutation root */
export type MutationRootInsertDebtFacilityEventsOneArgs = {
  object: DebtFacilityEventsInsertInput;
  on_conflict?: Maybe<DebtFacilityEventsOnConflict>;
};

/** mutation root */
export type MutationRootInsertEbbaApplicationFilesArgs = {
  objects: Array<EbbaApplicationFilesInsertInput>;
  on_conflict?: Maybe<EbbaApplicationFilesOnConflict>;
};

/** mutation root */
export type MutationRootInsertEbbaApplicationFilesOneArgs = {
  object: EbbaApplicationFilesInsertInput;
  on_conflict?: Maybe<EbbaApplicationFilesOnConflict>;
};

/** mutation root */
export type MutationRootInsertEbbaApplicationsArgs = {
  objects: Array<EbbaApplicationsInsertInput>;
  on_conflict?: Maybe<EbbaApplicationsOnConflict>;
};

/** mutation root */
export type MutationRootInsertEbbaApplicationsOneArgs = {
  object: EbbaApplicationsInsertInput;
  on_conflict?: Maybe<EbbaApplicationsOnConflict>;
};

/** mutation root */
export type MutationRootInsertFilesArgs = {
  objects: Array<FilesInsertInput>;
  on_conflict?: Maybe<FilesOnConflict>;
};

/** mutation root */
export type MutationRootInsertFilesOneArgs = {
  object: FilesInsertInput;
  on_conflict?: Maybe<FilesOnConflict>;
};

/** mutation root */
export type MutationRootInsertFinancialSummariesArgs = {
  objects: Array<FinancialSummariesInsertInput>;
  on_conflict?: Maybe<FinancialSummariesOnConflict>;
};

/** mutation root */
export type MutationRootInsertFinancialSummariesOneArgs = {
  object: FinancialSummariesInsertInput;
  on_conflict?: Maybe<FinancialSummariesOnConflict>;
};

/** mutation root */
export type MutationRootInsertInvoiceFileTypeArgs = {
  objects: Array<InvoiceFileTypeInsertInput>;
  on_conflict?: Maybe<InvoiceFileTypeOnConflict>;
};

/** mutation root */
export type MutationRootInsertInvoiceFileTypeOneArgs = {
  object: InvoiceFileTypeInsertInput;
  on_conflict?: Maybe<InvoiceFileTypeOnConflict>;
};

/** mutation root */
export type MutationRootInsertInvoiceFilesArgs = {
  objects: Array<InvoiceFilesInsertInput>;
  on_conflict?: Maybe<InvoiceFilesOnConflict>;
};

/** mutation root */
export type MutationRootInsertInvoiceFilesOneArgs = {
  object: InvoiceFilesInsertInput;
  on_conflict?: Maybe<InvoiceFilesOnConflict>;
};

/** mutation root */
export type MutationRootInsertInvoicesArgs = {
  objects: Array<InvoicesInsertInput>;
  on_conflict?: Maybe<InvoicesOnConflict>;
};

/** mutation root */
export type MutationRootInsertInvoicesOneArgs = {
  object: InvoicesInsertInput;
  on_conflict?: Maybe<InvoicesOnConflict>;
};

/** mutation root */
export type MutationRootInsertLineOfCreditsArgs = {
  objects: Array<LineOfCreditsInsertInput>;
  on_conflict?: Maybe<LineOfCreditsOnConflict>;
};

/** mutation root */
export type MutationRootInsertLineOfCreditsOneArgs = {
  object: LineOfCreditsInsertInput;
  on_conflict?: Maybe<LineOfCreditsOnConflict>;
};

/** mutation root */
export type MutationRootInsertLoanReportsArgs = {
  objects: Array<LoanReportsInsertInput>;
  on_conflict?: Maybe<LoanReportsOnConflict>;
};

/** mutation root */
export type MutationRootInsertLoanReportsOneArgs = {
  object: LoanReportsInsertInput;
  on_conflict?: Maybe<LoanReportsOnConflict>;
};

/** mutation root */
export type MutationRootInsertLoanTypeArgs = {
  objects: Array<LoanTypeInsertInput>;
  on_conflict?: Maybe<LoanTypeOnConflict>;
};

/** mutation root */
export type MutationRootInsertLoanTypeOneArgs = {
  object: LoanTypeInsertInput;
  on_conflict?: Maybe<LoanTypeOnConflict>;
};

/** mutation root */
export type MutationRootInsertLoansArgs = {
  objects: Array<LoansInsertInput>;
  on_conflict?: Maybe<LoansOnConflict>;
};

/** mutation root */
export type MutationRootInsertLoansOneArgs = {
  object: LoansInsertInput;
  on_conflict?: Maybe<LoansOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcAnalysisSummariesArgs = {
  objects: Array<MetrcAnalysisSummariesInsertInput>;
  on_conflict?: Maybe<MetrcAnalysisSummariesOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcAnalysisSummariesOneArgs = {
  object: MetrcAnalysisSummariesInsertInput;
  on_conflict?: Maybe<MetrcAnalysisSummariesOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcApiKeysArgs = {
  objects: Array<MetrcApiKeysInsertInput>;
  on_conflict?: Maybe<MetrcApiKeysOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcApiKeysOneArgs = {
  object: MetrcApiKeysInsertInput;
  on_conflict?: Maybe<MetrcApiKeysOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcDeliveriesArgs = {
  objects: Array<MetrcDeliveriesInsertInput>;
  on_conflict?: Maybe<MetrcDeliveriesOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcDeliveriesOneArgs = {
  object: MetrcDeliveriesInsertInput;
  on_conflict?: Maybe<MetrcDeliveriesOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcDownloadSummariesArgs = {
  objects: Array<MetrcDownloadSummariesInsertInput>;
  on_conflict?: Maybe<MetrcDownloadSummariesOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcDownloadSummariesOneArgs = {
  object: MetrcDownloadSummariesInsertInput;
  on_conflict?: Maybe<MetrcDownloadSummariesOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcHarvestsArgs = {
  objects: Array<MetrcHarvestsInsertInput>;
  on_conflict?: Maybe<MetrcHarvestsOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcHarvestsOneArgs = {
  object: MetrcHarvestsInsertInput;
  on_conflict?: Maybe<MetrcHarvestsOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcPackagesArgs = {
  objects: Array<MetrcPackagesInsertInput>;
  on_conflict?: Maybe<MetrcPackagesOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcPackagesOneArgs = {
  object: MetrcPackagesInsertInput;
  on_conflict?: Maybe<MetrcPackagesOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcPlantBatchesArgs = {
  objects: Array<MetrcPlantBatchesInsertInput>;
  on_conflict?: Maybe<MetrcPlantBatchesOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcPlantBatchesOneArgs = {
  object: MetrcPlantBatchesInsertInput;
  on_conflict?: Maybe<MetrcPlantBatchesOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcPlantsArgs = {
  objects: Array<MetrcPlantsInsertInput>;
  on_conflict?: Maybe<MetrcPlantsOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcPlantsOneArgs = {
  object: MetrcPlantsInsertInput;
  on_conflict?: Maybe<MetrcPlantsOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcSalesReceiptsArgs = {
  objects: Array<MetrcSalesReceiptsInsertInput>;
  on_conflict?: Maybe<MetrcSalesReceiptsOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcSalesReceiptsOneArgs = {
  object: MetrcSalesReceiptsInsertInput;
  on_conflict?: Maybe<MetrcSalesReceiptsOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcSalesTransactionsArgs = {
  objects: Array<MetrcSalesTransactionsInsertInput>;
  on_conflict?: Maybe<MetrcSalesTransactionsOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcSalesTransactionsOneArgs = {
  object: MetrcSalesTransactionsInsertInput;
  on_conflict?: Maybe<MetrcSalesTransactionsOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcToBespokeCatalogSkusArgs = {
  objects: Array<MetrcToBespokeCatalogSkusInsertInput>;
  on_conflict?: Maybe<MetrcToBespokeCatalogSkusOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcToBespokeCatalogSkusOneArgs = {
  object: MetrcToBespokeCatalogSkusInsertInput;
  on_conflict?: Maybe<MetrcToBespokeCatalogSkusOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcTransferPackagesArgs = {
  objects: Array<MetrcTransferPackagesInsertInput>;
  on_conflict?: Maybe<MetrcTransferPackagesOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcTransferPackagesOneArgs = {
  object: MetrcTransferPackagesInsertInput;
  on_conflict?: Maybe<MetrcTransferPackagesOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcTransfersArgs = {
  objects: Array<MetrcTransfersInsertInput>;
  on_conflict?: Maybe<MetrcTransfersOnConflict>;
};

/** mutation root */
export type MutationRootInsertMetrcTransfersOneArgs = {
  object: MetrcTransfersInsertInput;
  on_conflict?: Maybe<MetrcTransfersOnConflict>;
};

/** mutation root */
export type MutationRootInsertMonthlySummaryCalculationsArgs = {
  objects: Array<MonthlySummaryCalculationsInsertInput>;
  on_conflict?: Maybe<MonthlySummaryCalculationsOnConflict>;
};

/** mutation root */
export type MutationRootInsertMonthlySummaryCalculationsOneArgs = {
  object: MonthlySummaryCalculationsInsertInput;
  on_conflict?: Maybe<MonthlySummaryCalculationsOnConflict>;
};

/** mutation root */
export type MutationRootInsertParentCompaniesArgs = {
  objects: Array<ParentCompaniesInsertInput>;
  on_conflict?: Maybe<ParentCompaniesOnConflict>;
};

/** mutation root */
export type MutationRootInsertParentCompaniesOneArgs = {
  object: ParentCompaniesInsertInput;
  on_conflict?: Maybe<ParentCompaniesOnConflict>;
};

/** mutation root */
export type MutationRootInsertPaymentsArgs = {
  objects: Array<PaymentsInsertInput>;
  on_conflict?: Maybe<PaymentsOnConflict>;
};

/** mutation root */
export type MutationRootInsertPaymentsOneArgs = {
  object: PaymentsInsertInput;
  on_conflict?: Maybe<PaymentsOnConflict>;
};

/** mutation root */
export type MutationRootInsertPayorsArgs = {
  objects: Array<PayorsInsertInput>;
};

/** mutation root */
export type MutationRootInsertPayorsOneArgs = {
  object: PayorsInsertInput;
};

/** mutation root */
export type MutationRootInsertPurchaseOrderFileTypeArgs = {
  objects: Array<PurchaseOrderFileTypeInsertInput>;
  on_conflict?: Maybe<PurchaseOrderFileTypeOnConflict>;
};

/** mutation root */
export type MutationRootInsertPurchaseOrderFileTypeOneArgs = {
  object: PurchaseOrderFileTypeInsertInput;
  on_conflict?: Maybe<PurchaseOrderFileTypeOnConflict>;
};

/** mutation root */
export type MutationRootInsertPurchaseOrderFilesArgs = {
  objects: Array<PurchaseOrderFilesInsertInput>;
  on_conflict?: Maybe<PurchaseOrderFilesOnConflict>;
};

/** mutation root */
export type MutationRootInsertPurchaseOrderFilesOneArgs = {
  object: PurchaseOrderFilesInsertInput;
  on_conflict?: Maybe<PurchaseOrderFilesOnConflict>;
};

/** mutation root */
export type MutationRootInsertPurchaseOrderMetrcTransfersArgs = {
  objects: Array<PurchaseOrderMetrcTransfersInsertInput>;
  on_conflict?: Maybe<PurchaseOrderMetrcTransfersOnConflict>;
};

/** mutation root */
export type MutationRootInsertPurchaseOrderMetrcTransfersOneArgs = {
  object: PurchaseOrderMetrcTransfersInsertInput;
  on_conflict?: Maybe<PurchaseOrderMetrcTransfersOnConflict>;
};

/** mutation root */
export type MutationRootInsertPurchaseOrdersArgs = {
  objects: Array<PurchaseOrdersInsertInput>;
  on_conflict?: Maybe<PurchaseOrdersOnConflict>;
};

/** mutation root */
export type MutationRootInsertPurchaseOrdersOneArgs = {
  object: PurchaseOrdersInsertInput;
  on_conflict?: Maybe<PurchaseOrdersOnConflict>;
};

/** mutation root */
export type MutationRootInsertRequestStatusArgs = {
  objects: Array<RequestStatusInsertInput>;
  on_conflict?: Maybe<RequestStatusOnConflict>;
};

/** mutation root */
export type MutationRootInsertRequestStatusOneArgs = {
  object: RequestStatusInsertInput;
  on_conflict?: Maybe<RequestStatusOnConflict>;
};

/** mutation root */
export type MutationRootInsertRevokedTokensArgs = {
  objects: Array<RevokedTokensInsertInput>;
  on_conflict?: Maybe<RevokedTokensOnConflict>;
};

/** mutation root */
export type MutationRootInsertRevokedTokensOneArgs = {
  object: RevokedTokensInsertInput;
  on_conflict?: Maybe<RevokedTokensOnConflict>;
};

/** mutation root */
export type MutationRootInsertSyncPipelinesArgs = {
  objects: Array<SyncPipelinesInsertInput>;
  on_conflict?: Maybe<SyncPipelinesOnConflict>;
};

/** mutation root */
export type MutationRootInsertSyncPipelinesOneArgs = {
  object: SyncPipelinesInsertInput;
  on_conflict?: Maybe<SyncPipelinesOnConflict>;
};

/** mutation root */
export type MutationRootInsertTransactionsArgs = {
  objects: Array<TransactionsInsertInput>;
  on_conflict?: Maybe<TransactionsOnConflict>;
};

/** mutation root */
export type MutationRootInsertTransactionsOneArgs = {
  object: TransactionsInsertInput;
  on_conflict?: Maybe<TransactionsOnConflict>;
};

/** mutation root */
export type MutationRootInsertTwoFactorLinksArgs = {
  objects: Array<TwoFactorLinksInsertInput>;
  on_conflict?: Maybe<TwoFactorLinksOnConflict>;
};

/** mutation root */
export type MutationRootInsertTwoFactorLinksOneArgs = {
  object: TwoFactorLinksInsertInput;
  on_conflict?: Maybe<TwoFactorLinksOnConflict>;
};

/** mutation root */
export type MutationRootInsertUserRolesArgs = {
  objects: Array<UserRolesInsertInput>;
  on_conflict?: Maybe<UserRolesOnConflict>;
};

/** mutation root */
export type MutationRootInsertUserRolesOneArgs = {
  object: UserRolesInsertInput;
  on_conflict?: Maybe<UserRolesOnConflict>;
};

/** mutation root */
export type MutationRootInsertUsersArgs = {
  objects: Array<UsersInsertInput>;
  on_conflict?: Maybe<UsersOnConflict>;
};

/** mutation root */
export type MutationRootInsertUsersOneArgs = {
  object: UsersInsertInput;
  on_conflict?: Maybe<UsersOnConflict>;
};

/** mutation root */
export type MutationRootInsertVendorChangeRequestsArgs = {
  objects: Array<VendorChangeRequestsInsertInput>;
  on_conflict?: Maybe<VendorChangeRequestsOnConflict>;
};

/** mutation root */
export type MutationRootInsertVendorChangeRequestsOneArgs = {
  object: VendorChangeRequestsInsertInput;
  on_conflict?: Maybe<VendorChangeRequestsOnConflict>;
};

/** mutation root */
export type MutationRootInsertVendorsArgs = {
  objects: Array<VendorsInsertInput>;
};

/** mutation root */
export type MutationRootInsertVendorsOneArgs = {
  object: VendorsInsertInput;
};

/** mutation root */
export type MutationRootUpdateAsyncJobSummariesArgs = {
  _set?: Maybe<AsyncJobSummariesSetInput>;
  where: AsyncJobSummariesBoolExp;
};

/** mutation root */
export type MutationRootUpdateAsyncJobSummariesByPkArgs = {
  _set?: Maybe<AsyncJobSummariesSetInput>;
  pk_columns: AsyncJobSummariesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateAsyncJobsArgs = {
  _inc?: Maybe<AsyncJobsIncInput>;
  _set?: Maybe<AsyncJobsSetInput>;
  where: AsyncJobsBoolExp;
};

/** mutation root */
export type MutationRootUpdateAsyncJobsByPkArgs = {
  _inc?: Maybe<AsyncJobsIncInput>;
  _set?: Maybe<AsyncJobsSetInput>;
  pk_columns: AsyncJobsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateAsyncPipelinesArgs = {
  _set?: Maybe<AsyncPipelinesSetInput>;
  where: AsyncPipelinesBoolExp;
};

/** mutation root */
export type MutationRootUpdateAsyncPipelinesByPkArgs = {
  _set?: Maybe<AsyncPipelinesSetInput>;
  pk_columns: AsyncPipelinesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateAuditEventsArgs = {
  _append?: Maybe<AuditEventsAppendInput>;
  _delete_at_path?: Maybe<AuditEventsDeleteAtPathInput>;
  _delete_elem?: Maybe<AuditEventsDeleteElemInput>;
  _delete_key?: Maybe<AuditEventsDeleteKeyInput>;
  _prepend?: Maybe<AuditEventsPrependInput>;
  _set?: Maybe<AuditEventsSetInput>;
  where: AuditEventsBoolExp;
};

/** mutation root */
export type MutationRootUpdateAuditEventsByPkArgs = {
  _append?: Maybe<AuditEventsAppendInput>;
  _delete_at_path?: Maybe<AuditEventsDeleteAtPathInput>;
  _delete_elem?: Maybe<AuditEventsDeleteElemInput>;
  _delete_key?: Maybe<AuditEventsDeleteKeyInput>;
  _prepend?: Maybe<AuditEventsPrependInput>;
  _set?: Maybe<AuditEventsSetInput>;
  pk_columns: AuditEventsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateBankAccountsArgs = {
  _set?: Maybe<BankAccountsSetInput>;
  where: BankAccountsBoolExp;
};

/** mutation root */
export type MutationRootUpdateBankAccountsByPkArgs = {
  _set?: Maybe<BankAccountsSetInput>;
  pk_columns: BankAccountsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateBankFinancialSummariesArgs = {
  _inc?: Maybe<BankFinancialSummariesIncInput>;
  _set?: Maybe<BankFinancialSummariesSetInput>;
  where: BankFinancialSummariesBoolExp;
};

/** mutation root */
export type MutationRootUpdateBankFinancialSummariesByPkArgs = {
  _inc?: Maybe<BankFinancialSummariesIncInput>;
  _set?: Maybe<BankFinancialSummariesSetInput>;
  pk_columns: BankFinancialSummariesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateBespokeCatalogBrandsArgs = {
  _set?: Maybe<BespokeCatalogBrandsSetInput>;
  where: BespokeCatalogBrandsBoolExp;
};

/** mutation root */
export type MutationRootUpdateBespokeCatalogBrandsByPkArgs = {
  _set?: Maybe<BespokeCatalogBrandsSetInput>;
  pk_columns: BespokeCatalogBrandsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateBespokeCatalogSkuGroupsArgs = {
  _inc?: Maybe<BespokeCatalogSkuGroupsIncInput>;
  _set?: Maybe<BespokeCatalogSkuGroupsSetInput>;
  where: BespokeCatalogSkuGroupsBoolExp;
};

/** mutation root */
export type MutationRootUpdateBespokeCatalogSkuGroupsByPkArgs = {
  _inc?: Maybe<BespokeCatalogSkuGroupsIncInput>;
  _set?: Maybe<BespokeCatalogSkuGroupsSetInput>;
  pk_columns: BespokeCatalogSkuGroupsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateBespokeCatalogSkusArgs = {
  _set?: Maybe<BespokeCatalogSkusSetInput>;
  where: BespokeCatalogSkusBoolExp;
};

/** mutation root */
export type MutationRootUpdateBespokeCatalogSkusByPkArgs = {
  _set?: Maybe<BespokeCatalogSkusSetInput>;
  pk_columns: BespokeCatalogSkusPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateBlazePreapprovalsArgs = {
  _inc?: Maybe<BlazePreapprovalsIncInput>;
  _set?: Maybe<BlazePreapprovalsSetInput>;
  where: BlazePreapprovalsBoolExp;
};

/** mutation root */
export type MutationRootUpdateBlazePreapprovalsByPkArgs = {
  _inc?: Maybe<BlazePreapprovalsIncInput>;
  _set?: Maybe<BlazePreapprovalsSetInput>;
  pk_columns: BlazePreapprovalsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateBlazeShopEntriesArgs = {
  _set?: Maybe<BlazeShopEntriesSetInput>;
  where: BlazeShopEntriesBoolExp;
};

/** mutation root */
export type MutationRootUpdateBlazeShopEntriesByPkArgs = {
  _set?: Maybe<BlazeShopEntriesSetInput>;
  pk_columns: BlazeShopEntriesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateBlazeUsersArgs = {
  _set?: Maybe<BlazeUsersSetInput>;
  where: BlazeUsersBoolExp;
};

/** mutation root */
export type MutationRootUpdateBlazeUsersByPkArgs = {
  _set?: Maybe<BlazeUsersSetInput>;
  pk_columns: BlazeUsersPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateCompaniesArgs = {
  _inc?: Maybe<CompaniesIncInput>;
  _set?: Maybe<CompaniesSetInput>;
  where: CompaniesBoolExp;
};

/** mutation root */
export type MutationRootUpdateCompaniesByPkArgs = {
  _inc?: Maybe<CompaniesIncInput>;
  _set?: Maybe<CompaniesSetInput>;
  pk_columns: CompaniesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateCompanyAgreementsArgs = {
  _set?: Maybe<CompanyAgreementsSetInput>;
  where: CompanyAgreementsBoolExp;
};

/** mutation root */
export type MutationRootUpdateCompanyAgreementsByPkArgs = {
  _set?: Maybe<CompanyAgreementsSetInput>;
  pk_columns: CompanyAgreementsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateCompanyDeliveriesArgs = {
  _set?: Maybe<CompanyDeliveriesSetInput>;
  where: CompanyDeliveriesBoolExp;
};

/** mutation root */
export type MutationRootUpdateCompanyDeliveriesByPkArgs = {
  _set?: Maybe<CompanyDeliveriesSetInput>;
  pk_columns: CompanyDeliveriesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateCompanyFacilitiesArgs = {
  _set?: Maybe<CompanyFacilitiesSetInput>;
  where: CompanyFacilitiesBoolExp;
};

/** mutation root */
export type MutationRootUpdateCompanyFacilitiesByPkArgs = {
  _set?: Maybe<CompanyFacilitiesSetInput>;
  pk_columns: CompanyFacilitiesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateCompanyLicensesArgs = {
  _inc?: Maybe<CompanyLicensesIncInput>;
  _set?: Maybe<CompanyLicensesSetInput>;
  where: CompanyLicensesBoolExp;
};

/** mutation root */
export type MutationRootUpdateCompanyLicensesByPkArgs = {
  _inc?: Maybe<CompanyLicensesIncInput>;
  _set?: Maybe<CompanyLicensesSetInput>;
  pk_columns: CompanyLicensesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateCompanyPartnershipInvitationsArgs = {
  _set?: Maybe<CompanyPartnershipInvitationsSetInput>;
  where: CompanyPartnershipInvitationsBoolExp;
};

/** mutation root */
export type MutationRootUpdateCompanyPartnershipInvitationsByPkArgs = {
  _set?: Maybe<CompanyPartnershipInvitationsSetInput>;
  pk_columns: CompanyPartnershipInvitationsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateCompanyPartnershipRequestsArgs = {
  _set?: Maybe<CompanyPartnershipRequestsSetInput>;
  where: CompanyPartnershipRequestsBoolExp;
};

/** mutation root */
export type MutationRootUpdateCompanyPartnershipRequestsByPkArgs = {
  _set?: Maybe<CompanyPartnershipRequestsSetInput>;
  pk_columns: CompanyPartnershipRequestsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateCompanyPayorContactsArgs = {
  _set?: Maybe<CompanyPayorContactsSetInput>;
  where: CompanyPayorContactsBoolExp;
};

/** mutation root */
export type MutationRootUpdateCompanyPayorContactsByPkArgs = {
  _set?: Maybe<CompanyPayorContactsSetInput>;
  pk_columns: CompanyPayorContactsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateCompanyPayorPartnershipsArgs = {
  _set?: Maybe<CompanyPayorPartnershipsSetInput>;
  where: CompanyPayorPartnershipsBoolExp;
};

/** mutation root */
export type MutationRootUpdateCompanyPayorPartnershipsByPkArgs = {
  _set?: Maybe<CompanyPayorPartnershipsSetInput>;
  pk_columns: CompanyPayorPartnershipsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateCompanySettingsArgs = {
  _append?: Maybe<CompanySettingsAppendInput>;
  _delete_at_path?: Maybe<CompanySettingsDeleteAtPathInput>;
  _delete_elem?: Maybe<CompanySettingsDeleteElemInput>;
  _delete_key?: Maybe<CompanySettingsDeleteKeyInput>;
  _prepend?: Maybe<CompanySettingsPrependInput>;
  _set?: Maybe<CompanySettingsSetInput>;
  where: CompanySettingsBoolExp;
};

/** mutation root */
export type MutationRootUpdateCompanySettingsByPkArgs = {
  _append?: Maybe<CompanySettingsAppendInput>;
  _delete_at_path?: Maybe<CompanySettingsDeleteAtPathInput>;
  _delete_elem?: Maybe<CompanySettingsDeleteElemInput>;
  _delete_key?: Maybe<CompanySettingsDeleteKeyInput>;
  _prepend?: Maybe<CompanySettingsPrependInput>;
  _set?: Maybe<CompanySettingsSetInput>;
  pk_columns: CompanySettingsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateCompanyTypeArgs = {
  _set?: Maybe<CompanyTypeSetInput>;
  where: CompanyTypeBoolExp;
};

/** mutation root */
export type MutationRootUpdateCompanyTypeByPkArgs = {
  _set?: Maybe<CompanyTypeSetInput>;
  pk_columns: CompanyTypePkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateCompanyVendorContactsArgs = {
  _set?: Maybe<CompanyVendorContactsSetInput>;
  where: CompanyVendorContactsBoolExp;
};

/** mutation root */
export type MutationRootUpdateCompanyVendorContactsByPkArgs = {
  _set?: Maybe<CompanyVendorContactsSetInput>;
  pk_columns: CompanyVendorContactsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateCompanyVendorPartnershipsArgs = {
  _set?: Maybe<CompanyVendorPartnershipsSetInput>;
  where: CompanyVendorPartnershipsBoolExp;
};

/** mutation root */
export type MutationRootUpdateCompanyVendorPartnershipsByPkArgs = {
  _set?: Maybe<CompanyVendorPartnershipsSetInput>;
  pk_columns: CompanyVendorPartnershipsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateContractsArgs = {
  _append?: Maybe<ContractsAppendInput>;
  _delete_at_path?: Maybe<ContractsDeleteAtPathInput>;
  _delete_elem?: Maybe<ContractsDeleteElemInput>;
  _delete_key?: Maybe<ContractsDeleteKeyInput>;
  _prepend?: Maybe<ContractsPrependInput>;
  _set?: Maybe<ContractsSetInput>;
  where: ContractsBoolExp;
};

/** mutation root */
export type MutationRootUpdateContractsByPkArgs = {
  _append?: Maybe<ContractsAppendInput>;
  _delete_at_path?: Maybe<ContractsDeleteAtPathInput>;
  _delete_elem?: Maybe<ContractsDeleteElemInput>;
  _delete_key?: Maybe<ContractsDeleteKeyInput>;
  _prepend?: Maybe<ContractsPrependInput>;
  _set?: Maybe<ContractsSetInput>;
  pk_columns: ContractsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateCustomerSurveillanceResultsArgs = {
  _set?: Maybe<CustomerSurveillanceResultsSetInput>;
  where: CustomerSurveillanceResultsBoolExp;
};

/** mutation root */
export type MutationRootUpdateCustomerSurveillanceResultsByPkArgs = {
  _set?: Maybe<CustomerSurveillanceResultsSetInput>;
  pk_columns: CustomerSurveillanceResultsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateDebtFacilitiesArgs = {
  _set?: Maybe<DebtFacilitiesSetInput>;
  where: DebtFacilitiesBoolExp;
};

/** mutation root */
export type MutationRootUpdateDebtFacilitiesByPkArgs = {
  _set?: Maybe<DebtFacilitiesSetInput>;
  pk_columns: DebtFacilitiesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateDebtFacilityCapacitiesArgs = {
  _inc?: Maybe<DebtFacilityCapacitiesIncInput>;
  _set?: Maybe<DebtFacilityCapacitiesSetInput>;
  where: DebtFacilityCapacitiesBoolExp;
};

/** mutation root */
export type MutationRootUpdateDebtFacilityCapacitiesByPkArgs = {
  _inc?: Maybe<DebtFacilityCapacitiesIncInput>;
  _set?: Maybe<DebtFacilityCapacitiesSetInput>;
  pk_columns: DebtFacilityCapacitiesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateDebtFacilityEventsArgs = {
  _append?: Maybe<DebtFacilityEventsAppendInput>;
  _delete_at_path?: Maybe<DebtFacilityEventsDeleteAtPathInput>;
  _delete_elem?: Maybe<DebtFacilityEventsDeleteElemInput>;
  _delete_key?: Maybe<DebtFacilityEventsDeleteKeyInput>;
  _inc?: Maybe<DebtFacilityEventsIncInput>;
  _prepend?: Maybe<DebtFacilityEventsPrependInput>;
  _set?: Maybe<DebtFacilityEventsSetInput>;
  where: DebtFacilityEventsBoolExp;
};

/** mutation root */
export type MutationRootUpdateDebtFacilityEventsByPkArgs = {
  _append?: Maybe<DebtFacilityEventsAppendInput>;
  _delete_at_path?: Maybe<DebtFacilityEventsDeleteAtPathInput>;
  _delete_elem?: Maybe<DebtFacilityEventsDeleteElemInput>;
  _delete_key?: Maybe<DebtFacilityEventsDeleteKeyInput>;
  _inc?: Maybe<DebtFacilityEventsIncInput>;
  _prepend?: Maybe<DebtFacilityEventsPrependInput>;
  _set?: Maybe<DebtFacilityEventsSetInput>;
  pk_columns: DebtFacilityEventsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateEbbaApplicationFilesArgs = {
  _set?: Maybe<EbbaApplicationFilesSetInput>;
  where: EbbaApplicationFilesBoolExp;
};

/** mutation root */
export type MutationRootUpdateEbbaApplicationFilesByPkArgs = {
  _set?: Maybe<EbbaApplicationFilesSetInput>;
  pk_columns: EbbaApplicationFilesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateEbbaApplicationsArgs = {
  _inc?: Maybe<EbbaApplicationsIncInput>;
  _set?: Maybe<EbbaApplicationsSetInput>;
  where: EbbaApplicationsBoolExp;
};

/** mutation root */
export type MutationRootUpdateEbbaApplicationsByPkArgs = {
  _inc?: Maybe<EbbaApplicationsIncInput>;
  _set?: Maybe<EbbaApplicationsSetInput>;
  pk_columns: EbbaApplicationsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateFilesArgs = {
  _inc?: Maybe<FilesIncInput>;
  _set?: Maybe<FilesSetInput>;
  where: FilesBoolExp;
};

/** mutation root */
export type MutationRootUpdateFilesByPkArgs = {
  _inc?: Maybe<FilesIncInput>;
  _set?: Maybe<FilesSetInput>;
  pk_columns: FilesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateFinancialSummariesArgs = {
  _append?: Maybe<FinancialSummariesAppendInput>;
  _delete_at_path?: Maybe<FinancialSummariesDeleteAtPathInput>;
  _delete_elem?: Maybe<FinancialSummariesDeleteElemInput>;
  _delete_key?: Maybe<FinancialSummariesDeleteKeyInput>;
  _inc?: Maybe<FinancialSummariesIncInput>;
  _prepend?: Maybe<FinancialSummariesPrependInput>;
  _set?: Maybe<FinancialSummariesSetInput>;
  where: FinancialSummariesBoolExp;
};

/** mutation root */
export type MutationRootUpdateFinancialSummariesByPkArgs = {
  _append?: Maybe<FinancialSummariesAppendInput>;
  _delete_at_path?: Maybe<FinancialSummariesDeleteAtPathInput>;
  _delete_elem?: Maybe<FinancialSummariesDeleteElemInput>;
  _delete_key?: Maybe<FinancialSummariesDeleteKeyInput>;
  _inc?: Maybe<FinancialSummariesIncInput>;
  _prepend?: Maybe<FinancialSummariesPrependInput>;
  _set?: Maybe<FinancialSummariesSetInput>;
  pk_columns: FinancialSummariesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateInvoiceFileTypeArgs = {
  _set?: Maybe<InvoiceFileTypeSetInput>;
  where: InvoiceFileTypeBoolExp;
};

/** mutation root */
export type MutationRootUpdateInvoiceFileTypeByPkArgs = {
  _set?: Maybe<InvoiceFileTypeSetInput>;
  pk_columns: InvoiceFileTypePkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateInvoiceFilesArgs = {
  _set?: Maybe<InvoiceFilesSetInput>;
  where: InvoiceFilesBoolExp;
};

/** mutation root */
export type MutationRootUpdateInvoiceFilesByPkArgs = {
  _set?: Maybe<InvoiceFilesSetInput>;
  pk_columns: InvoiceFilesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateInvoicesArgs = {
  _inc?: Maybe<InvoicesIncInput>;
  _set?: Maybe<InvoicesSetInput>;
  where: InvoicesBoolExp;
};

/** mutation root */
export type MutationRootUpdateInvoicesByPkArgs = {
  _inc?: Maybe<InvoicesIncInput>;
  _set?: Maybe<InvoicesSetInput>;
  pk_columns: InvoicesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateLineOfCreditsArgs = {
  _set?: Maybe<LineOfCreditsSetInput>;
  where: LineOfCreditsBoolExp;
};

/** mutation root */
export type MutationRootUpdateLineOfCreditsByPkArgs = {
  _set?: Maybe<LineOfCreditsSetInput>;
  pk_columns: LineOfCreditsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateLoanReportsArgs = {
  _inc?: Maybe<LoanReportsIncInput>;
  _set?: Maybe<LoanReportsSetInput>;
  where: LoanReportsBoolExp;
};

/** mutation root */
export type MutationRootUpdateLoanReportsByPkArgs = {
  _inc?: Maybe<LoanReportsIncInput>;
  _set?: Maybe<LoanReportsSetInput>;
  pk_columns: LoanReportsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateLoanTypeArgs = {
  _set?: Maybe<LoanTypeSetInput>;
  where: LoanTypeBoolExp;
};

/** mutation root */
export type MutationRootUpdateLoanTypeByPkArgs = {
  _set?: Maybe<LoanTypeSetInput>;
  pk_columns: LoanTypePkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateLoansArgs = {
  _inc?: Maybe<LoansIncInput>;
  _set?: Maybe<LoansSetInput>;
  where: LoansBoolExp;
};

/** mutation root */
export type MutationRootUpdateLoansByPkArgs = {
  _inc?: Maybe<LoansIncInput>;
  _set?: Maybe<LoansSetInput>;
  pk_columns: LoansPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateMetrcAnalysisSummariesArgs = {
  _set?: Maybe<MetrcAnalysisSummariesSetInput>;
  where: MetrcAnalysisSummariesBoolExp;
};

/** mutation root */
export type MutationRootUpdateMetrcAnalysisSummariesByPkArgs = {
  _set?: Maybe<MetrcAnalysisSummariesSetInput>;
  pk_columns: MetrcAnalysisSummariesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateMetrcApiKeysArgs = {
  _set?: Maybe<MetrcApiKeysSetInput>;
  where: MetrcApiKeysBoolExp;
};

/** mutation root */
export type MutationRootUpdateMetrcApiKeysByPkArgs = {
  _set?: Maybe<MetrcApiKeysSetInput>;
  pk_columns: MetrcApiKeysPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateMetrcDeliveriesArgs = {
  _set?: Maybe<MetrcDeliveriesSetInput>;
  where: MetrcDeliveriesBoolExp;
};

/** mutation root */
export type MutationRootUpdateMetrcDeliveriesByPkArgs = {
  _set?: Maybe<MetrcDeliveriesSetInput>;
  pk_columns: MetrcDeliveriesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateMetrcDownloadSummariesArgs = {
  _inc?: Maybe<MetrcDownloadSummariesIncInput>;
  _set?: Maybe<MetrcDownloadSummariesSetInput>;
  where: MetrcDownloadSummariesBoolExp;
};

/** mutation root */
export type MutationRootUpdateMetrcDownloadSummariesByPkArgs = {
  _inc?: Maybe<MetrcDownloadSummariesIncInput>;
  _set?: Maybe<MetrcDownloadSummariesSetInput>;
  pk_columns: MetrcDownloadSummariesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateMetrcHarvestsArgs = {
  _set?: Maybe<MetrcHarvestsSetInput>;
  where: MetrcHarvestsBoolExp;
};

/** mutation root */
export type MutationRootUpdateMetrcHarvestsByPkArgs = {
  _set?: Maybe<MetrcHarvestsSetInput>;
  pk_columns: MetrcHarvestsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateMetrcPackagesArgs = {
  _inc?: Maybe<MetrcPackagesIncInput>;
  _set?: Maybe<MetrcPackagesSetInput>;
  where: MetrcPackagesBoolExp;
};

/** mutation root */
export type MutationRootUpdateMetrcPackagesByPkArgs = {
  _inc?: Maybe<MetrcPackagesIncInput>;
  _set?: Maybe<MetrcPackagesSetInput>;
  pk_columns: MetrcPackagesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateMetrcPlantBatchesArgs = {
  _set?: Maybe<MetrcPlantBatchesSetInput>;
  where: MetrcPlantBatchesBoolExp;
};

/** mutation root */
export type MutationRootUpdateMetrcPlantBatchesByPkArgs = {
  _set?: Maybe<MetrcPlantBatchesSetInput>;
  pk_columns: MetrcPlantBatchesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateMetrcPlantsArgs = {
  _set?: Maybe<MetrcPlantsSetInput>;
  where: MetrcPlantsBoolExp;
};

/** mutation root */
export type MutationRootUpdateMetrcPlantsByPkArgs = {
  _set?: Maybe<MetrcPlantsSetInput>;
  pk_columns: MetrcPlantsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateMetrcSalesReceiptsArgs = {
  _inc?: Maybe<MetrcSalesReceiptsIncInput>;
  _set?: Maybe<MetrcSalesReceiptsSetInput>;
  where: MetrcSalesReceiptsBoolExp;
};

/** mutation root */
export type MutationRootUpdateMetrcSalesReceiptsByPkArgs = {
  _inc?: Maybe<MetrcSalesReceiptsIncInput>;
  _set?: Maybe<MetrcSalesReceiptsSetInput>;
  pk_columns: MetrcSalesReceiptsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateMetrcSalesTransactionsArgs = {
  _inc?: Maybe<MetrcSalesTransactionsIncInput>;
  _set?: Maybe<MetrcSalesTransactionsSetInput>;
  where: MetrcSalesTransactionsBoolExp;
};

/** mutation root */
export type MutationRootUpdateMetrcSalesTransactionsByPkArgs = {
  _inc?: Maybe<MetrcSalesTransactionsIncInput>;
  _set?: Maybe<MetrcSalesTransactionsSetInput>;
  pk_columns: MetrcSalesTransactionsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateMetrcToBespokeCatalogSkusArgs = {
  _inc?: Maybe<MetrcToBespokeCatalogSkusIncInput>;
  _set?: Maybe<MetrcToBespokeCatalogSkusSetInput>;
  where: MetrcToBespokeCatalogSkusBoolExp;
};

/** mutation root */
export type MutationRootUpdateMetrcToBespokeCatalogSkusByPkArgs = {
  _inc?: Maybe<MetrcToBespokeCatalogSkusIncInput>;
  _set?: Maybe<MetrcToBespokeCatalogSkusSetInput>;
  pk_columns: MetrcToBespokeCatalogSkusPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateMetrcTransferPackagesArgs = {
  _inc?: Maybe<MetrcTransferPackagesIncInput>;
  _set?: Maybe<MetrcTransferPackagesSetInput>;
  where: MetrcTransferPackagesBoolExp;
};

/** mutation root */
export type MutationRootUpdateMetrcTransferPackagesByPkArgs = {
  _inc?: Maybe<MetrcTransferPackagesIncInput>;
  _set?: Maybe<MetrcTransferPackagesSetInput>;
  pk_columns: MetrcTransferPackagesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateMetrcTransfersArgs = {
  _set?: Maybe<MetrcTransfersSetInput>;
  where: MetrcTransfersBoolExp;
};

/** mutation root */
export type MutationRootUpdateMetrcTransfersByPkArgs = {
  _set?: Maybe<MetrcTransfersSetInput>;
  pk_columns: MetrcTransfersPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateMonthlySummaryCalculationsArgs = {
  _inc?: Maybe<MonthlySummaryCalculationsIncInput>;
  _set?: Maybe<MonthlySummaryCalculationsSetInput>;
  where: MonthlySummaryCalculationsBoolExp;
};

/** mutation root */
export type MutationRootUpdateMonthlySummaryCalculationsByPkArgs = {
  _inc?: Maybe<MonthlySummaryCalculationsIncInput>;
  _set?: Maybe<MonthlySummaryCalculationsSetInput>;
  pk_columns: MonthlySummaryCalculationsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateParentCompaniesArgs = {
  _append?: Maybe<ParentCompaniesAppendInput>;
  _delete_at_path?: Maybe<ParentCompaniesDeleteAtPathInput>;
  _delete_elem?: Maybe<ParentCompaniesDeleteElemInput>;
  _delete_key?: Maybe<ParentCompaniesDeleteKeyInput>;
  _prepend?: Maybe<ParentCompaniesPrependInput>;
  _set?: Maybe<ParentCompaniesSetInput>;
  where: ParentCompaniesBoolExp;
};

/** mutation root */
export type MutationRootUpdateParentCompaniesByPkArgs = {
  _append?: Maybe<ParentCompaniesAppendInput>;
  _delete_at_path?: Maybe<ParentCompaniesDeleteAtPathInput>;
  _delete_elem?: Maybe<ParentCompaniesDeleteElemInput>;
  _delete_key?: Maybe<ParentCompaniesDeleteKeyInput>;
  _prepend?: Maybe<ParentCompaniesPrependInput>;
  _set?: Maybe<ParentCompaniesSetInput>;
  pk_columns: ParentCompaniesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdatePaymentsArgs = {
  _append?: Maybe<PaymentsAppendInput>;
  _delete_at_path?: Maybe<PaymentsDeleteAtPathInput>;
  _delete_elem?: Maybe<PaymentsDeleteElemInput>;
  _delete_key?: Maybe<PaymentsDeleteKeyInput>;
  _inc?: Maybe<PaymentsIncInput>;
  _prepend?: Maybe<PaymentsPrependInput>;
  _set?: Maybe<PaymentsSetInput>;
  where: PaymentsBoolExp;
};

/** mutation root */
export type MutationRootUpdatePaymentsByPkArgs = {
  _append?: Maybe<PaymentsAppendInput>;
  _delete_at_path?: Maybe<PaymentsDeleteAtPathInput>;
  _delete_elem?: Maybe<PaymentsDeleteElemInput>;
  _delete_key?: Maybe<PaymentsDeleteKeyInput>;
  _inc?: Maybe<PaymentsIncInput>;
  _prepend?: Maybe<PaymentsPrependInput>;
  _set?: Maybe<PaymentsSetInput>;
  pk_columns: PaymentsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdatePayorsArgs = {
  _inc?: Maybe<PayorsIncInput>;
  _set?: Maybe<PayorsSetInput>;
  where: PayorsBoolExp;
};

/** mutation root */
export type MutationRootUpdatePurchaseOrderFileTypeArgs = {
  _set?: Maybe<PurchaseOrderFileTypeSetInput>;
  where: PurchaseOrderFileTypeBoolExp;
};

/** mutation root */
export type MutationRootUpdatePurchaseOrderFileTypeByPkArgs = {
  _set?: Maybe<PurchaseOrderFileTypeSetInput>;
  pk_columns: PurchaseOrderFileTypePkColumnsInput;
};

/** mutation root */
export type MutationRootUpdatePurchaseOrderFilesArgs = {
  _set?: Maybe<PurchaseOrderFilesSetInput>;
  where: PurchaseOrderFilesBoolExp;
};

/** mutation root */
export type MutationRootUpdatePurchaseOrderFilesByPkArgs = {
  _set?: Maybe<PurchaseOrderFilesSetInput>;
  pk_columns: PurchaseOrderFilesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdatePurchaseOrderMetrcTransfersArgs = {
  _set?: Maybe<PurchaseOrderMetrcTransfersSetInput>;
  where: PurchaseOrderMetrcTransfersBoolExp;
};

/** mutation root */
export type MutationRootUpdatePurchaseOrderMetrcTransfersByPkArgs = {
  _set?: Maybe<PurchaseOrderMetrcTransfersSetInput>;
  pk_columns: PurchaseOrderMetrcTransfersPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdatePurchaseOrdersArgs = {
  _append?: Maybe<PurchaseOrdersAppendInput>;
  _delete_at_path?: Maybe<PurchaseOrdersDeleteAtPathInput>;
  _delete_elem?: Maybe<PurchaseOrdersDeleteElemInput>;
  _delete_key?: Maybe<PurchaseOrdersDeleteKeyInput>;
  _inc?: Maybe<PurchaseOrdersIncInput>;
  _prepend?: Maybe<PurchaseOrdersPrependInput>;
  _set?: Maybe<PurchaseOrdersSetInput>;
  where: PurchaseOrdersBoolExp;
};

/** mutation root */
export type MutationRootUpdatePurchaseOrdersByPkArgs = {
  _append?: Maybe<PurchaseOrdersAppendInput>;
  _delete_at_path?: Maybe<PurchaseOrdersDeleteAtPathInput>;
  _delete_elem?: Maybe<PurchaseOrdersDeleteElemInput>;
  _delete_key?: Maybe<PurchaseOrdersDeleteKeyInput>;
  _inc?: Maybe<PurchaseOrdersIncInput>;
  _prepend?: Maybe<PurchaseOrdersPrependInput>;
  _set?: Maybe<PurchaseOrdersSetInput>;
  pk_columns: PurchaseOrdersPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateRequestStatusArgs = {
  _set?: Maybe<RequestStatusSetInput>;
  where: RequestStatusBoolExp;
};

/** mutation root */
export type MutationRootUpdateRequestStatusByPkArgs = {
  _set?: Maybe<RequestStatusSetInput>;
  pk_columns: RequestStatusPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateRevokedTokensArgs = {
  _set?: Maybe<RevokedTokensSetInput>;
  where: RevokedTokensBoolExp;
};

/** mutation root */
export type MutationRootUpdateRevokedTokensByPkArgs = {
  _set?: Maybe<RevokedTokensSetInput>;
  pk_columns: RevokedTokensPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateSyncPipelinesArgs = {
  _set?: Maybe<SyncPipelinesSetInput>;
  where: SyncPipelinesBoolExp;
};

/** mutation root */
export type MutationRootUpdateSyncPipelinesByPkArgs = {
  _set?: Maybe<SyncPipelinesSetInput>;
  pk_columns: SyncPipelinesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateTransactionsArgs = {
  _inc?: Maybe<TransactionsIncInput>;
  _set?: Maybe<TransactionsSetInput>;
  where: TransactionsBoolExp;
};

/** mutation root */
export type MutationRootUpdateTransactionsByPkArgs = {
  _inc?: Maybe<TransactionsIncInput>;
  _set?: Maybe<TransactionsSetInput>;
  pk_columns: TransactionsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateTwoFactorLinksArgs = {
  _set?: Maybe<TwoFactorLinksSetInput>;
  where: TwoFactorLinksBoolExp;
};

/** mutation root */
export type MutationRootUpdateTwoFactorLinksByPkArgs = {
  _set?: Maybe<TwoFactorLinksSetInput>;
  pk_columns: TwoFactorLinksPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateUserRolesArgs = {
  _set?: Maybe<UserRolesSetInput>;
  where: UserRolesBoolExp;
};

/** mutation root */
export type MutationRootUpdateUserRolesByPkArgs = {
  _set?: Maybe<UserRolesSetInput>;
  pk_columns: UserRolesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateUsersArgs = {
  _set?: Maybe<UsersSetInput>;
  where: UsersBoolExp;
};

/** mutation root */
export type MutationRootUpdateUsersByPkArgs = {
  _set?: Maybe<UsersSetInput>;
  pk_columns: UsersPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateVendorChangeRequestsArgs = {
  _set?: Maybe<VendorChangeRequestsSetInput>;
  where: VendorChangeRequestsBoolExp;
};

/** mutation root */
export type MutationRootUpdateVendorChangeRequestsByPkArgs = {
  _set?: Maybe<VendorChangeRequestsSetInput>;
  pk_columns: VendorChangeRequestsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateVendorsArgs = {
  _inc?: Maybe<VendorsIncInput>;
  _set?: Maybe<VendorsSetInput>;
  where: VendorsBoolExp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: Maybe<Scalars["numeric"]>;
  _gt?: Maybe<Scalars["numeric"]>;
  _gte?: Maybe<Scalars["numeric"]>;
  _in?: Maybe<Array<Scalars["numeric"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["numeric"]>;
  _lte?: Maybe<Scalars["numeric"]>;
  _neq?: Maybe<Scalars["numeric"]>;
  _nin?: Maybe<Array<Scalars["numeric"]>>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = "asc",
  /** in ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in descending order, nulls first */
  Desc = "desc",
  /** in descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

/** columns and relationships of "parent_companies" */
export type ParentCompanies = {
  /** An array relationship */
  companies: Array<Companies>;
  /** An aggregate relationship */
  companies_aggregate: CompaniesAggregate;
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  name: Scalars["String"];
  settings: Scalars["jsonb"];
  updated_at: Scalars["timestamptz"];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: UsersAggregate;
};

/** columns and relationships of "parent_companies" */
export type ParentCompaniesCompaniesArgs = {
  distinct_on?: Maybe<Array<CompaniesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompaniesOrderBy>>;
  where?: Maybe<CompaniesBoolExp>;
};

/** columns and relationships of "parent_companies" */
export type ParentCompaniesCompaniesAggregateArgs = {
  distinct_on?: Maybe<Array<CompaniesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompaniesOrderBy>>;
  where?: Maybe<CompaniesBoolExp>;
};

/** columns and relationships of "parent_companies" */
export type ParentCompaniesSettingsArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "parent_companies" */
export type ParentCompaniesUsersArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

/** columns and relationships of "parent_companies" */
export type ParentCompaniesUsersAggregateArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

/** aggregated selection of "parent_companies" */
export type ParentCompaniesAggregate = {
  aggregate?: Maybe<ParentCompaniesAggregateFields>;
  nodes: Array<ParentCompanies>;
};

/** aggregate fields of "parent_companies" */
export type ParentCompaniesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<ParentCompaniesMaxFields>;
  min?: Maybe<ParentCompaniesMinFields>;
};

/** aggregate fields of "parent_companies" */
export type ParentCompaniesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ParentCompaniesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ParentCompaniesAppendInput = {
  settings?: Maybe<Scalars["jsonb"]>;
};

/** Boolean expression to filter rows from the table "parent_companies". All fields are combined with a logical 'AND'. */
export type ParentCompaniesBoolExp = {
  _and?: Maybe<Array<ParentCompaniesBoolExp>>;
  _not?: Maybe<ParentCompaniesBoolExp>;
  _or?: Maybe<Array<ParentCompaniesBoolExp>>;
  companies?: Maybe<CompaniesBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  settings?: Maybe<JsonbComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  users?: Maybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "parent_companies" */
export enum ParentCompaniesConstraint {
  /** unique or primary key constraint */
  ParentCompaniesNameKey = "parent_companies_name_key",
  /** unique or primary key constraint */
  ParentCompaniesPkey = "parent_companies_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ParentCompaniesDeleteAtPathInput = {
  settings?: Maybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ParentCompaniesDeleteElemInput = {
  settings?: Maybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ParentCompaniesDeleteKeyInput = {
  settings?: Maybe<Scalars["String"]>;
};

/** input type for inserting data into table "parent_companies" */
export type ParentCompaniesInsertInput = {
  companies?: Maybe<CompaniesArrRelInsertInput>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  settings?: Maybe<Scalars["jsonb"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  users?: Maybe<UsersArrRelInsertInput>;
};

/** aggregate max on columns */
export type ParentCompaniesMaxFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type ParentCompaniesMinFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "parent_companies" */
export type ParentCompaniesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<ParentCompanies>;
};

/** input type for inserting object relation for remote table "parent_companies" */
export type ParentCompaniesObjRelInsertInput = {
  data: ParentCompaniesInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<ParentCompaniesOnConflict>;
};

/** on_conflict condition type for table "parent_companies" */
export type ParentCompaniesOnConflict = {
  constraint: ParentCompaniesConstraint;
  update_columns?: Array<ParentCompaniesUpdateColumn>;
  where?: Maybe<ParentCompaniesBoolExp>;
};

/** Ordering options when selecting data from "parent_companies". */
export type ParentCompaniesOrderBy = {
  companies_aggregate?: Maybe<CompaniesAggregateOrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  settings?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  users_aggregate?: Maybe<UsersAggregateOrderBy>;
};

/** primary key columns input for table: parent_companies */
export type ParentCompaniesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ParentCompaniesPrependInput = {
  settings?: Maybe<Scalars["jsonb"]>;
};

/** select columns of table "parent_companies" */
export enum ParentCompaniesSelectColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Settings = "settings",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "parent_companies" */
export type ParentCompaniesSetInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  settings?: Maybe<Scalars["jsonb"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "parent_companies" */
export enum ParentCompaniesUpdateColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Settings = "settings",
  /** column name */
  UpdatedAt = "updated_at",
}

/** Payments are dollar amounts transferred to and from the bank */
export type Payments = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<Scalars["numeric"]>;
  bank_note?: Maybe<Scalars["String"]>;
  /** An object relationship */
  company: Companies;
  /** An object relationship */
  company_bank_account?: Maybe<BankAccounts>;
  company_bank_account_id?: Maybe<Scalars["uuid"]>;
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  customer_note?: Maybe<Scalars["String"]>;
  /** The date when payment is credited to destination account */
  deposit_date?: Maybe<Scalars["date"]>;
  id: Scalars["uuid"];
  /** Unique identifier for payments scoped to (company_id, identifier) */
  identifier?: Maybe<Scalars["String"]>;
  /** An object relationship */
  invoice?: Maybe<Invoices>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  /** JSON blob which records information about this payment: which loans this payment is intended for, how much of this payment is intended to go to principal vs interest, etc */
  items_covered: Scalars["jsonb"];
  method: Scalars["String"];
  /** Mostly used for credits and fees, if this payment for a fee or credit was due to an underlying advance or repayment */
  originating_payment_id?: Maybe<Scalars["uuid"]>;
  /** The date when payment is debited from source account */
  payment_date?: Maybe<Scalars["date"]>;
  /** An object relationship */
  recipient_bank_account?: Maybe<BankAccounts>;
  recipient_bank_account_id?: Maybe<Scalars["uuid"]>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<Scalars["numeric"]>;
  /** When a customer requests or notifies us a payment should take place, their user id is captured here */
  requested_by_user_id?: Maybe<Scalars["uuid"]>;
  /** The date the customer requests the payment to arrive to the recipient bank account (a better name for this column is requested_deposit_date) */
  requested_payment_date?: Maybe<Scalars["date"]>;
  /** When a payment was reversed: reversed payments do not have any transactions associated with them, but we retain them for record-keeping sake */
  reversed_at?: Maybe<Scalars["timestamptz"]>;
  /** When this payment has been settled and applied to loans. This can only be done once. */
  settled_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  settled_by_user?: Maybe<Users>;
  settled_by_user_id?: Maybe<Scalars["uuid"]>;
  /** The date when payment is settled and is effective for financial calculations */
  settlement_date?: Maybe<Scalars["date"]>;
  /** Unique identifier for settled payments scoped to (company_id, type, settlement_identifier) */
  settlement_identifier?: Maybe<Scalars["String"]>;
  /** When this payment record was originally added to the Postgres DB */
  submitted_at: Scalars["timestamptz"];
  /** An object relationship */
  submitted_by_user?: Maybe<Users>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  transactions: Array<Transactions>;
  /** An aggregate relationship */
  transactions_aggregate: TransactionsAggregate;
  type: Scalars["String"];
  updated_at: Scalars["timestamptz"];
};

/** Payments are dollar amounts transferred to and from the bank */
export type PaymentsItemsCoveredArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** Payments are dollar amounts transferred to and from the bank */
export type PaymentsTransactionsArgs = {
  distinct_on?: Maybe<Array<TransactionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TransactionsOrderBy>>;
  where?: Maybe<TransactionsBoolExp>;
};

/** Payments are dollar amounts transferred to and from the bank */
export type PaymentsTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<TransactionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TransactionsOrderBy>>;
  where?: Maybe<TransactionsBoolExp>;
};

/** aggregated selection of "payments" */
export type PaymentsAggregate = {
  aggregate?: Maybe<PaymentsAggregateFields>;
  nodes: Array<Payments>;
};

/** aggregate fields of "payments" */
export type PaymentsAggregateFields = {
  avg?: Maybe<PaymentsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<PaymentsMaxFields>;
  min?: Maybe<PaymentsMinFields>;
  stddev?: Maybe<PaymentsStddevFields>;
  stddev_pop?: Maybe<PaymentsStddevPopFields>;
  stddev_samp?: Maybe<PaymentsStddevSampFields>;
  sum?: Maybe<PaymentsSumFields>;
  var_pop?: Maybe<PaymentsVarPopFields>;
  var_samp?: Maybe<PaymentsVarSampFields>;
  variance?: Maybe<PaymentsVarianceFields>;
};

/** aggregate fields of "payments" */
export type PaymentsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<PaymentsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "payments" */
export type PaymentsAggregateOrderBy = {
  avg?: Maybe<PaymentsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<PaymentsMaxOrderBy>;
  min?: Maybe<PaymentsMinOrderBy>;
  stddev?: Maybe<PaymentsStddevOrderBy>;
  stddev_pop?: Maybe<PaymentsStddevPopOrderBy>;
  stddev_samp?: Maybe<PaymentsStddevSampOrderBy>;
  sum?: Maybe<PaymentsSumOrderBy>;
  var_pop?: Maybe<PaymentsVarPopOrderBy>;
  var_samp?: Maybe<PaymentsVarSampOrderBy>;
  variance?: Maybe<PaymentsVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type PaymentsAppendInput = {
  /** JSON blob which records information about this payment: which loans this payment is intended for, how much of this payment is intended to go to principal vs interest, etc */
  items_covered?: Maybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "payments" */
export type PaymentsArrRelInsertInput = {
  data: Array<PaymentsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<PaymentsOnConflict>;
};

/** aggregate avg on columns */
export type PaymentsAvgFields = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<Scalars["Float"]>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "payments" */
export type PaymentsAvgOrderBy = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<OrderBy>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "payments". All fields are combined with a logical 'AND'. */
export type PaymentsBoolExp = {
  _and?: Maybe<Array<PaymentsBoolExp>>;
  _not?: Maybe<PaymentsBoolExp>;
  _or?: Maybe<Array<PaymentsBoolExp>>;
  amount?: Maybe<NumericComparisonExp>;
  bank_note?: Maybe<StringComparisonExp>;
  company?: Maybe<CompaniesBoolExp>;
  company_bank_account?: Maybe<BankAccountsBoolExp>;
  company_bank_account_id?: Maybe<UuidComparisonExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  customer_note?: Maybe<StringComparisonExp>;
  deposit_date?: Maybe<DateComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  identifier?: Maybe<StringComparisonExp>;
  invoice?: Maybe<InvoicesBoolExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  items_covered?: Maybe<JsonbComparisonExp>;
  method?: Maybe<StringComparisonExp>;
  originating_payment_id?: Maybe<UuidComparisonExp>;
  payment_date?: Maybe<DateComparisonExp>;
  recipient_bank_account?: Maybe<BankAccountsBoolExp>;
  recipient_bank_account_id?: Maybe<UuidComparisonExp>;
  requested_amount?: Maybe<NumericComparisonExp>;
  requested_by_user_id?: Maybe<UuidComparisonExp>;
  requested_payment_date?: Maybe<DateComparisonExp>;
  reversed_at?: Maybe<TimestamptzComparisonExp>;
  settled_at?: Maybe<TimestamptzComparisonExp>;
  settled_by_user?: Maybe<UsersBoolExp>;
  settled_by_user_id?: Maybe<UuidComparisonExp>;
  settlement_date?: Maybe<DateComparisonExp>;
  settlement_identifier?: Maybe<StringComparisonExp>;
  submitted_at?: Maybe<TimestamptzComparisonExp>;
  submitted_by_user?: Maybe<UsersBoolExp>;
  submitted_by_user_id?: Maybe<UuidComparisonExp>;
  transactions?: Maybe<TransactionsBoolExp>;
  type?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "payments" */
export enum PaymentsConstraint {
  /** unique or primary key constraint */
  PaymentsPkey = "payments_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type PaymentsDeleteAtPathInput = {
  /** JSON blob which records information about this payment: which loans this payment is intended for, how much of this payment is intended to go to principal vs interest, etc */
  items_covered?: Maybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type PaymentsDeleteElemInput = {
  /** JSON blob which records information about this payment: which loans this payment is intended for, how much of this payment is intended to go to principal vs interest, etc */
  items_covered?: Maybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type PaymentsDeleteKeyInput = {
  /** JSON blob which records information about this payment: which loans this payment is intended for, how much of this payment is intended to go to principal vs interest, etc */
  items_covered?: Maybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "payments" */
export type PaymentsIncInput = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<Scalars["numeric"]>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "payments" */
export type PaymentsInsertInput = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<Scalars["numeric"]>;
  bank_note?: Maybe<Scalars["String"]>;
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_bank_account?: Maybe<BankAccountsObjRelInsertInput>;
  company_bank_account_id?: Maybe<Scalars["uuid"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  customer_note?: Maybe<Scalars["String"]>;
  /** The date when payment is credited to destination account */
  deposit_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  /** Unique identifier for payments scoped to (company_id, identifier) */
  identifier?: Maybe<Scalars["String"]>;
  invoice?: Maybe<InvoicesObjRelInsertInput>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  /** JSON blob which records information about this payment: which loans this payment is intended for, how much of this payment is intended to go to principal vs interest, etc */
  items_covered?: Maybe<Scalars["jsonb"]>;
  method?: Maybe<Scalars["String"]>;
  /** Mostly used for credits and fees, if this payment for a fee or credit was due to an underlying advance or repayment */
  originating_payment_id?: Maybe<Scalars["uuid"]>;
  /** The date when payment is debited from source account */
  payment_date?: Maybe<Scalars["date"]>;
  recipient_bank_account?: Maybe<BankAccountsObjRelInsertInput>;
  recipient_bank_account_id?: Maybe<Scalars["uuid"]>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<Scalars["numeric"]>;
  /** When a customer requests or notifies us a payment should take place, their user id is captured here */
  requested_by_user_id?: Maybe<Scalars["uuid"]>;
  /** The date the customer requests the payment to arrive to the recipient bank account (a better name for this column is requested_deposit_date) */
  requested_payment_date?: Maybe<Scalars["date"]>;
  /** When a payment was reversed: reversed payments do not have any transactions associated with them, but we retain them for record-keeping sake */
  reversed_at?: Maybe<Scalars["timestamptz"]>;
  /** When this payment has been settled and applied to loans. This can only be done once. */
  settled_at?: Maybe<Scalars["timestamptz"]>;
  settled_by_user?: Maybe<UsersObjRelInsertInput>;
  settled_by_user_id?: Maybe<Scalars["uuid"]>;
  /** The date when payment is settled and is effective for financial calculations */
  settlement_date?: Maybe<Scalars["date"]>;
  /** Unique identifier for settled payments scoped to (company_id, type, settlement_identifier) */
  settlement_identifier?: Maybe<Scalars["String"]>;
  /** When this payment record was originally added to the Postgres DB */
  submitted_at?: Maybe<Scalars["timestamptz"]>;
  submitted_by_user?: Maybe<UsersObjRelInsertInput>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  transactions?: Maybe<TransactionsArrRelInsertInput>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type PaymentsMaxFields = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<Scalars["numeric"]>;
  bank_note?: Maybe<Scalars["String"]>;
  company_bank_account_id?: Maybe<Scalars["uuid"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  customer_note?: Maybe<Scalars["String"]>;
  /** The date when payment is credited to destination account */
  deposit_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  /** Unique identifier for payments scoped to (company_id, identifier) */
  identifier?: Maybe<Scalars["String"]>;
  method?: Maybe<Scalars["String"]>;
  /** Mostly used for credits and fees, if this payment for a fee or credit was due to an underlying advance or repayment */
  originating_payment_id?: Maybe<Scalars["uuid"]>;
  /** The date when payment is debited from source account */
  payment_date?: Maybe<Scalars["date"]>;
  recipient_bank_account_id?: Maybe<Scalars["uuid"]>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<Scalars["numeric"]>;
  /** When a customer requests or notifies us a payment should take place, their user id is captured here */
  requested_by_user_id?: Maybe<Scalars["uuid"]>;
  /** The date the customer requests the payment to arrive to the recipient bank account (a better name for this column is requested_deposit_date) */
  requested_payment_date?: Maybe<Scalars["date"]>;
  /** When a payment was reversed: reversed payments do not have any transactions associated with them, but we retain them for record-keeping sake */
  reversed_at?: Maybe<Scalars["timestamptz"]>;
  /** When this payment has been settled and applied to loans. This can only be done once. */
  settled_at?: Maybe<Scalars["timestamptz"]>;
  settled_by_user_id?: Maybe<Scalars["uuid"]>;
  /** The date when payment is settled and is effective for financial calculations */
  settlement_date?: Maybe<Scalars["date"]>;
  /** Unique identifier for settled payments scoped to (company_id, type, settlement_identifier) */
  settlement_identifier?: Maybe<Scalars["String"]>;
  /** When this payment record was originally added to the Postgres DB */
  submitted_at?: Maybe<Scalars["timestamptz"]>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "payments" */
export type PaymentsMaxOrderBy = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<OrderBy>;
  bank_note?: Maybe<OrderBy>;
  company_bank_account_id?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  customer_note?: Maybe<OrderBy>;
  /** The date when payment is credited to destination account */
  deposit_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  /** Unique identifier for payments scoped to (company_id, identifier) */
  identifier?: Maybe<OrderBy>;
  method?: Maybe<OrderBy>;
  /** Mostly used for credits and fees, if this payment for a fee or credit was due to an underlying advance or repayment */
  originating_payment_id?: Maybe<OrderBy>;
  /** The date when payment is debited from source account */
  payment_date?: Maybe<OrderBy>;
  recipient_bank_account_id?: Maybe<OrderBy>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<OrderBy>;
  /** When a customer requests or notifies us a payment should take place, their user id is captured here */
  requested_by_user_id?: Maybe<OrderBy>;
  /** The date the customer requests the payment to arrive to the recipient bank account (a better name for this column is requested_deposit_date) */
  requested_payment_date?: Maybe<OrderBy>;
  /** When a payment was reversed: reversed payments do not have any transactions associated with them, but we retain them for record-keeping sake */
  reversed_at?: Maybe<OrderBy>;
  /** When this payment has been settled and applied to loans. This can only be done once. */
  settled_at?: Maybe<OrderBy>;
  settled_by_user_id?: Maybe<OrderBy>;
  /** The date when payment is settled and is effective for financial calculations */
  settlement_date?: Maybe<OrderBy>;
  /** Unique identifier for settled payments scoped to (company_id, type, settlement_identifier) */
  settlement_identifier?: Maybe<OrderBy>;
  /** When this payment record was originally added to the Postgres DB */
  submitted_at?: Maybe<OrderBy>;
  submitted_by_user_id?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type PaymentsMinFields = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<Scalars["numeric"]>;
  bank_note?: Maybe<Scalars["String"]>;
  company_bank_account_id?: Maybe<Scalars["uuid"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  customer_note?: Maybe<Scalars["String"]>;
  /** The date when payment is credited to destination account */
  deposit_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  /** Unique identifier for payments scoped to (company_id, identifier) */
  identifier?: Maybe<Scalars["String"]>;
  method?: Maybe<Scalars["String"]>;
  /** Mostly used for credits and fees, if this payment for a fee or credit was due to an underlying advance or repayment */
  originating_payment_id?: Maybe<Scalars["uuid"]>;
  /** The date when payment is debited from source account */
  payment_date?: Maybe<Scalars["date"]>;
  recipient_bank_account_id?: Maybe<Scalars["uuid"]>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<Scalars["numeric"]>;
  /** When a customer requests or notifies us a payment should take place, their user id is captured here */
  requested_by_user_id?: Maybe<Scalars["uuid"]>;
  /** The date the customer requests the payment to arrive to the recipient bank account (a better name for this column is requested_deposit_date) */
  requested_payment_date?: Maybe<Scalars["date"]>;
  /** When a payment was reversed: reversed payments do not have any transactions associated with them, but we retain them for record-keeping sake */
  reversed_at?: Maybe<Scalars["timestamptz"]>;
  /** When this payment has been settled and applied to loans. This can only be done once. */
  settled_at?: Maybe<Scalars["timestamptz"]>;
  settled_by_user_id?: Maybe<Scalars["uuid"]>;
  /** The date when payment is settled and is effective for financial calculations */
  settlement_date?: Maybe<Scalars["date"]>;
  /** Unique identifier for settled payments scoped to (company_id, type, settlement_identifier) */
  settlement_identifier?: Maybe<Scalars["String"]>;
  /** When this payment record was originally added to the Postgres DB */
  submitted_at?: Maybe<Scalars["timestamptz"]>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "payments" */
export type PaymentsMinOrderBy = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<OrderBy>;
  bank_note?: Maybe<OrderBy>;
  company_bank_account_id?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  customer_note?: Maybe<OrderBy>;
  /** The date when payment is credited to destination account */
  deposit_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  /** Unique identifier for payments scoped to (company_id, identifier) */
  identifier?: Maybe<OrderBy>;
  method?: Maybe<OrderBy>;
  /** Mostly used for credits and fees, if this payment for a fee or credit was due to an underlying advance or repayment */
  originating_payment_id?: Maybe<OrderBy>;
  /** The date when payment is debited from source account */
  payment_date?: Maybe<OrderBy>;
  recipient_bank_account_id?: Maybe<OrderBy>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<OrderBy>;
  /** When a customer requests or notifies us a payment should take place, their user id is captured here */
  requested_by_user_id?: Maybe<OrderBy>;
  /** The date the customer requests the payment to arrive to the recipient bank account (a better name for this column is requested_deposit_date) */
  requested_payment_date?: Maybe<OrderBy>;
  /** When a payment was reversed: reversed payments do not have any transactions associated with them, but we retain them for record-keeping sake */
  reversed_at?: Maybe<OrderBy>;
  /** When this payment has been settled and applied to loans. This can only be done once. */
  settled_at?: Maybe<OrderBy>;
  settled_by_user_id?: Maybe<OrderBy>;
  /** The date when payment is settled and is effective for financial calculations */
  settlement_date?: Maybe<OrderBy>;
  /** Unique identifier for settled payments scoped to (company_id, type, settlement_identifier) */
  settlement_identifier?: Maybe<OrderBy>;
  /** When this payment record was originally added to the Postgres DB */
  submitted_at?: Maybe<OrderBy>;
  submitted_by_user_id?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "payments" */
export type PaymentsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Payments>;
};

/** input type for inserting object relation for remote table "payments" */
export type PaymentsObjRelInsertInput = {
  data: PaymentsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<PaymentsOnConflict>;
};

/** on_conflict condition type for table "payments" */
export type PaymentsOnConflict = {
  constraint: PaymentsConstraint;
  update_columns?: Array<PaymentsUpdateColumn>;
  where?: Maybe<PaymentsBoolExp>;
};

/** Ordering options when selecting data from "payments". */
export type PaymentsOrderBy = {
  amount?: Maybe<OrderBy>;
  bank_note?: Maybe<OrderBy>;
  company?: Maybe<CompaniesOrderBy>;
  company_bank_account?: Maybe<BankAccountsOrderBy>;
  company_bank_account_id?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  customer_note?: Maybe<OrderBy>;
  deposit_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  identifier?: Maybe<OrderBy>;
  invoice?: Maybe<InvoicesOrderBy>;
  is_deleted?: Maybe<OrderBy>;
  items_covered?: Maybe<OrderBy>;
  method?: Maybe<OrderBy>;
  originating_payment_id?: Maybe<OrderBy>;
  payment_date?: Maybe<OrderBy>;
  recipient_bank_account?: Maybe<BankAccountsOrderBy>;
  recipient_bank_account_id?: Maybe<OrderBy>;
  requested_amount?: Maybe<OrderBy>;
  requested_by_user_id?: Maybe<OrderBy>;
  requested_payment_date?: Maybe<OrderBy>;
  reversed_at?: Maybe<OrderBy>;
  settled_at?: Maybe<OrderBy>;
  settled_by_user?: Maybe<UsersOrderBy>;
  settled_by_user_id?: Maybe<OrderBy>;
  settlement_date?: Maybe<OrderBy>;
  settlement_identifier?: Maybe<OrderBy>;
  submitted_at?: Maybe<OrderBy>;
  submitted_by_user?: Maybe<UsersOrderBy>;
  submitted_by_user_id?: Maybe<OrderBy>;
  transactions_aggregate?: Maybe<TransactionsAggregateOrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: payments */
export type PaymentsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type PaymentsPrependInput = {
  /** JSON blob which records information about this payment: which loans this payment is intended for, how much of this payment is intended to go to principal vs interest, etc */
  items_covered?: Maybe<Scalars["jsonb"]>;
};

/** select columns of table "payments" */
export enum PaymentsSelectColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  BankNote = "bank_note",
  /** column name */
  CompanyBankAccountId = "company_bank_account_id",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CustomerNote = "customer_note",
  /** column name */
  DepositDate = "deposit_date",
  /** column name */
  Id = "id",
  /** column name */
  Identifier = "identifier",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  ItemsCovered = "items_covered",
  /** column name */
  Method = "method",
  /** column name */
  OriginatingPaymentId = "originating_payment_id",
  /** column name */
  PaymentDate = "payment_date",
  /** column name */
  RecipientBankAccountId = "recipient_bank_account_id",
  /** column name */
  RequestedAmount = "requested_amount",
  /** column name */
  RequestedByUserId = "requested_by_user_id",
  /** column name */
  RequestedPaymentDate = "requested_payment_date",
  /** column name */
  ReversedAt = "reversed_at",
  /** column name */
  SettledAt = "settled_at",
  /** column name */
  SettledByUserId = "settled_by_user_id",
  /** column name */
  SettlementDate = "settlement_date",
  /** column name */
  SettlementIdentifier = "settlement_identifier",
  /** column name */
  SubmittedAt = "submitted_at",
  /** column name */
  SubmittedByUserId = "submitted_by_user_id",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "payments" */
export type PaymentsSetInput = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<Scalars["numeric"]>;
  bank_note?: Maybe<Scalars["String"]>;
  company_bank_account_id?: Maybe<Scalars["uuid"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  customer_note?: Maybe<Scalars["String"]>;
  /** The date when payment is credited to destination account */
  deposit_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  /** Unique identifier for payments scoped to (company_id, identifier) */
  identifier?: Maybe<Scalars["String"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  /** JSON blob which records information about this payment: which loans this payment is intended for, how much of this payment is intended to go to principal vs interest, etc */
  items_covered?: Maybe<Scalars["jsonb"]>;
  method?: Maybe<Scalars["String"]>;
  /** Mostly used for credits and fees, if this payment for a fee or credit was due to an underlying advance or repayment */
  originating_payment_id?: Maybe<Scalars["uuid"]>;
  /** The date when payment is debited from source account */
  payment_date?: Maybe<Scalars["date"]>;
  recipient_bank_account_id?: Maybe<Scalars["uuid"]>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<Scalars["numeric"]>;
  /** When a customer requests or notifies us a payment should take place, their user id is captured here */
  requested_by_user_id?: Maybe<Scalars["uuid"]>;
  /** The date the customer requests the payment to arrive to the recipient bank account (a better name for this column is requested_deposit_date) */
  requested_payment_date?: Maybe<Scalars["date"]>;
  /** When a payment was reversed: reversed payments do not have any transactions associated with them, but we retain them for record-keeping sake */
  reversed_at?: Maybe<Scalars["timestamptz"]>;
  /** When this payment has been settled and applied to loans. This can only be done once. */
  settled_at?: Maybe<Scalars["timestamptz"]>;
  settled_by_user_id?: Maybe<Scalars["uuid"]>;
  /** The date when payment is settled and is effective for financial calculations */
  settlement_date?: Maybe<Scalars["date"]>;
  /** Unique identifier for settled payments scoped to (company_id, type, settlement_identifier) */
  settlement_identifier?: Maybe<Scalars["String"]>;
  /** When this payment record was originally added to the Postgres DB */
  submitted_at?: Maybe<Scalars["timestamptz"]>;
  submitted_by_user_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type PaymentsStddevFields = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<Scalars["Float"]>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "payments" */
export type PaymentsStddevOrderBy = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<OrderBy>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type PaymentsStddevPopFields = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<Scalars["Float"]>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "payments" */
export type PaymentsStddevPopOrderBy = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<OrderBy>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type PaymentsStddevSampFields = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<Scalars["Float"]>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "payments" */
export type PaymentsStddevSampOrderBy = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<OrderBy>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type PaymentsSumFields = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<Scalars["numeric"]>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "payments" */
export type PaymentsSumOrderBy = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<OrderBy>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<OrderBy>;
};

/** update columns of table "payments" */
export enum PaymentsUpdateColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  BankNote = "bank_note",
  /** column name */
  CompanyBankAccountId = "company_bank_account_id",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CustomerNote = "customer_note",
  /** column name */
  DepositDate = "deposit_date",
  /** column name */
  Id = "id",
  /** column name */
  Identifier = "identifier",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  ItemsCovered = "items_covered",
  /** column name */
  Method = "method",
  /** column name */
  OriginatingPaymentId = "originating_payment_id",
  /** column name */
  PaymentDate = "payment_date",
  /** column name */
  RecipientBankAccountId = "recipient_bank_account_id",
  /** column name */
  RequestedAmount = "requested_amount",
  /** column name */
  RequestedByUserId = "requested_by_user_id",
  /** column name */
  RequestedPaymentDate = "requested_payment_date",
  /** column name */
  ReversedAt = "reversed_at",
  /** column name */
  SettledAt = "settled_at",
  /** column name */
  SettledByUserId = "settled_by_user_id",
  /** column name */
  SettlementDate = "settlement_date",
  /** column name */
  SettlementIdentifier = "settlement_identifier",
  /** column name */
  SubmittedAt = "submitted_at",
  /** column name */
  SubmittedByUserId = "submitted_by_user_id",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type PaymentsVarPopFields = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<Scalars["Float"]>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "payments" */
export type PaymentsVarPopOrderBy = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<OrderBy>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type PaymentsVarSampFields = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<Scalars["Float"]>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "payments" */
export type PaymentsVarSampOrderBy = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<OrderBy>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type PaymentsVarianceFields = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<Scalars["Float"]>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "payments" */
export type PaymentsVarianceOrderBy = {
  /** The amount this payment actually is, as opposed to the requested amount */
  amount?: Maybe<OrderBy>;
  /** The amount the customer requests this payment to be */
  requested_amount?: Maybe<OrderBy>;
};

/** columns and relationships of "payors" */
export type Payors = {
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  /** An array relationship */
  company_payor_partnerships: Array<CompanyPayorPartnerships>;
  /** An aggregate relationship */
  company_payor_partnerships_aggregate: CompanyPayorPartnershipsAggregate;
  company_settings_id?: Maybe<Scalars["uuid"]>;
  contract_id?: Maybe<Scalars["uuid"]>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  employer_identification_number?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
  /** An array relationship */
  licenses: Array<CompanyLicenses>;
  /** An aggregate relationship */
  licenses_aggregate: CompanyLicensesAggregate;
  name?: Maybe<Scalars["String"]>;
  needs_balance_recomputed?: Maybe<Scalars["Boolean"]>;
  /** An object relationship */
  parent_company?: Maybe<ParentCompanies>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  phone_number?: Maybe<Scalars["String"]>;
  /** An object relationship */
  settings?: Maybe<CompanySettings>;
  state?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: UsersAggregate;
  zip_code?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "payors" */
export type PayorsCompanyPayorPartnershipsArgs = {
  distinct_on?: Maybe<Array<CompanyPayorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorPartnershipsOrderBy>>;
  where?: Maybe<CompanyPayorPartnershipsBoolExp>;
};

/** columns and relationships of "payors" */
export type PayorsCompanyPayorPartnershipsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyPayorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorPartnershipsOrderBy>>;
  where?: Maybe<CompanyPayorPartnershipsBoolExp>;
};

/** columns and relationships of "payors" */
export type PayorsLicensesArgs = {
  distinct_on?: Maybe<Array<CompanyLicensesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyLicensesOrderBy>>;
  where?: Maybe<CompanyLicensesBoolExp>;
};

/** columns and relationships of "payors" */
export type PayorsLicensesAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyLicensesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyLicensesOrderBy>>;
  where?: Maybe<CompanyLicensesBoolExp>;
};

/** columns and relationships of "payors" */
export type PayorsUsersArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

/** columns and relationships of "payors" */
export type PayorsUsersAggregateArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

/** aggregated selection of "payors" */
export type PayorsAggregate = {
  aggregate?: Maybe<PayorsAggregateFields>;
  nodes: Array<Payors>;
};

/** aggregate fields of "payors" */
export type PayorsAggregateFields = {
  avg?: Maybe<PayorsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<PayorsMaxFields>;
  min?: Maybe<PayorsMinFields>;
  stddev?: Maybe<PayorsStddevFields>;
  stddev_pop?: Maybe<PayorsStddevPopFields>;
  stddev_samp?: Maybe<PayorsStddevSampFields>;
  sum?: Maybe<PayorsSumFields>;
  var_pop?: Maybe<PayorsVarPopFields>;
  var_samp?: Maybe<PayorsVarSampFields>;
  variance?: Maybe<PayorsVarianceFields>;
};

/** aggregate fields of "payors" */
export type PayorsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<PayorsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type PayorsAvgFields = {
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "payors". All fields are combined with a logical 'AND'. */
export type PayorsBoolExp = {
  _and?: Maybe<Array<PayorsBoolExp>>;
  _not?: Maybe<PayorsBoolExp>;
  _or?: Maybe<Array<PayorsBoolExp>>;
  address?: Maybe<StringComparisonExp>;
  city?: Maybe<StringComparisonExp>;
  company_payor_partnerships?: Maybe<CompanyPayorPartnershipsBoolExp>;
  company_settings_id?: Maybe<UuidComparisonExp>;
  contract_id?: Maybe<UuidComparisonExp>;
  country?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  dba_name?: Maybe<StringComparisonExp>;
  employer_identification_number?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  identifier?: Maybe<StringComparisonExp>;
  is_cannabis?: Maybe<BooleanComparisonExp>;
  latest_loan_identifier?: Maybe<IntComparisonExp>;
  licenses?: Maybe<CompanyLicensesBoolExp>;
  name?: Maybe<StringComparisonExp>;
  needs_balance_recomputed?: Maybe<BooleanComparisonExp>;
  parent_company?: Maybe<ParentCompaniesBoolExp>;
  parent_company_id?: Maybe<UuidComparisonExp>;
  phone_number?: Maybe<StringComparisonExp>;
  settings?: Maybe<CompanySettingsBoolExp>;
  state?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  users?: Maybe<UsersBoolExp>;
  zip_code?: Maybe<StringComparisonExp>;
};

/** input type for incrementing numeric columns in table "payors" */
export type PayorsIncInput = {
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "payors" */
export type PayorsInsertInput = {
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company_payor_partnerships?: Maybe<CompanyPayorPartnershipsArrRelInsertInput>;
  company_settings_id?: Maybe<Scalars["uuid"]>;
  contract_id?: Maybe<Scalars["uuid"]>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  employer_identification_number?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
  licenses?: Maybe<CompanyLicensesArrRelInsertInput>;
  name?: Maybe<Scalars["String"]>;
  needs_balance_recomputed?: Maybe<Scalars["Boolean"]>;
  parent_company?: Maybe<ParentCompaniesObjRelInsertInput>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  phone_number?: Maybe<Scalars["String"]>;
  settings?: Maybe<CompanySettingsObjRelInsertInput>;
  state?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  users?: Maybe<UsersArrRelInsertInput>;
  zip_code?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type PayorsMaxFields = {
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company_settings_id?: Maybe<Scalars["uuid"]>;
  contract_id?: Maybe<Scalars["uuid"]>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  employer_identification_number?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  phone_number?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  zip_code?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type PayorsMinFields = {
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company_settings_id?: Maybe<Scalars["uuid"]>;
  contract_id?: Maybe<Scalars["uuid"]>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  employer_identification_number?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  phone_number?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  zip_code?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "payors" */
export type PayorsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Payors>;
};

/** input type for inserting object relation for remote table "payors" */
export type PayorsObjRelInsertInput = {
  data: PayorsInsertInput;
};

/** Ordering options when selecting data from "payors". */
export type PayorsOrderBy = {
  address?: Maybe<OrderBy>;
  city?: Maybe<OrderBy>;
  company_payor_partnerships_aggregate?: Maybe<CompanyPayorPartnershipsAggregateOrderBy>;
  company_settings_id?: Maybe<OrderBy>;
  contract_id?: Maybe<OrderBy>;
  country?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  dba_name?: Maybe<OrderBy>;
  employer_identification_number?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  identifier?: Maybe<OrderBy>;
  is_cannabis?: Maybe<OrderBy>;
  latest_loan_identifier?: Maybe<OrderBy>;
  licenses_aggregate?: Maybe<CompanyLicensesAggregateOrderBy>;
  name?: Maybe<OrderBy>;
  needs_balance_recomputed?: Maybe<OrderBy>;
  parent_company?: Maybe<ParentCompaniesOrderBy>;
  parent_company_id?: Maybe<OrderBy>;
  phone_number?: Maybe<OrderBy>;
  settings?: Maybe<CompanySettingsOrderBy>;
  state?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  users_aggregate?: Maybe<UsersAggregateOrderBy>;
  zip_code?: Maybe<OrderBy>;
};

/** select columns of table "payors" */
export enum PayorsSelectColumn {
  /** column name */
  Address = "address",
  /** column name */
  City = "city",
  /** column name */
  CompanySettingsId = "company_settings_id",
  /** column name */
  ContractId = "contract_id",
  /** column name */
  Country = "country",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DbaName = "dba_name",
  /** column name */
  EmployerIdentificationNumber = "employer_identification_number",
  /** column name */
  Id = "id",
  /** column name */
  Identifier = "identifier",
  /** column name */
  IsCannabis = "is_cannabis",
  /** column name */
  LatestLoanIdentifier = "latest_loan_identifier",
  /** column name */
  Name = "name",
  /** column name */
  NeedsBalanceRecomputed = "needs_balance_recomputed",
  /** column name */
  ParentCompanyId = "parent_company_id",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  State = "state",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  ZipCode = "zip_code",
}

/** input type for updating data in table "payors" */
export type PayorsSetInput = {
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company_settings_id?: Maybe<Scalars["uuid"]>;
  contract_id?: Maybe<Scalars["uuid"]>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  employer_identification_number?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  needs_balance_recomputed?: Maybe<Scalars["Boolean"]>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  phone_number?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  zip_code?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type PayorsStddevFields = {
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type PayorsStddevPopFields = {
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type PayorsStddevSampFields = {
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type PayorsSumFields = {
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
};

/** aggregate var_pop on columns */
export type PayorsVarPopFields = {
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type PayorsVarSampFields = {
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type PayorsVarianceFields = {
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
};

/** Enum for PurchaseOrderFile types */
export type PurchaseOrderFileType = {
  display_name: Scalars["String"];
  value: Scalars["String"];
};

/** aggregated selection of "purchase_order_file_type" */
export type PurchaseOrderFileTypeAggregate = {
  aggregate?: Maybe<PurchaseOrderFileTypeAggregateFields>;
  nodes: Array<PurchaseOrderFileType>;
};

/** aggregate fields of "purchase_order_file_type" */
export type PurchaseOrderFileTypeAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<PurchaseOrderFileTypeMaxFields>;
  min?: Maybe<PurchaseOrderFileTypeMinFields>;
};

/** aggregate fields of "purchase_order_file_type" */
export type PurchaseOrderFileTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<PurchaseOrderFileTypeSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "purchase_order_file_type". All fields are combined with a logical 'AND'. */
export type PurchaseOrderFileTypeBoolExp = {
  _and?: Maybe<Array<PurchaseOrderFileTypeBoolExp>>;
  _not?: Maybe<PurchaseOrderFileTypeBoolExp>;
  _or?: Maybe<Array<PurchaseOrderFileTypeBoolExp>>;
  display_name?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "purchase_order_file_type" */
export enum PurchaseOrderFileTypeConstraint {
  /** unique or primary key constraint */
  PurchaseOrderFileTypePkey = "purchase_order_file_type_pkey",
}

export enum PurchaseOrderFileTypeEnum {
  /** Cannabis */
  Cannabis = "cannabis",
  /** Purchase Order */
  PurchaseOrder = "purchase_order",
}

/** Boolean expression to compare columns of type "purchase_order_file_type_enum". All fields are combined with logical 'AND'. */
export type PurchaseOrderFileTypeEnumComparisonExp = {
  _eq?: Maybe<PurchaseOrderFileTypeEnum>;
  _in?: Maybe<Array<PurchaseOrderFileTypeEnum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<PurchaseOrderFileTypeEnum>;
  _nin?: Maybe<Array<PurchaseOrderFileTypeEnum>>;
};

/** input type for inserting data into table "purchase_order_file_type" */
export type PurchaseOrderFileTypeInsertInput = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type PurchaseOrderFileTypeMaxFields = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type PurchaseOrderFileTypeMinFields = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "purchase_order_file_type" */
export type PurchaseOrderFileTypeMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PurchaseOrderFileType>;
};

/** on_conflict condition type for table "purchase_order_file_type" */
export type PurchaseOrderFileTypeOnConflict = {
  constraint: PurchaseOrderFileTypeConstraint;
  update_columns?: Array<PurchaseOrderFileTypeUpdateColumn>;
  where?: Maybe<PurchaseOrderFileTypeBoolExp>;
};

/** Ordering options when selecting data from "purchase_order_file_type". */
export type PurchaseOrderFileTypeOrderBy = {
  display_name?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: purchase_order_file_type */
export type PurchaseOrderFileTypePkColumnsInput = {
  value: Scalars["String"];
};

/** select columns of table "purchase_order_file_type" */
export enum PurchaseOrderFileTypeSelectColumn {
  /** column name */
  DisplayName = "display_name",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "purchase_order_file_type" */
export type PurchaseOrderFileTypeSetInput = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** update columns of table "purchase_order_file_type" */
export enum PurchaseOrderFileTypeUpdateColumn {
  /** column name */
  DisplayName = "display_name",
  /** column name */
  Value = "value",
}

/** Files attached to purchase orders */
export type PurchaseOrderFiles = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  file: Files;
  file_id: Scalars["uuid"];
  file_type: PurchaseOrderFileTypeEnum;
  /** An object relationship */
  purchase_order: PurchaseOrders;
  purchase_order_id: Scalars["uuid"];
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregated selection of "purchase_order_files" */
export type PurchaseOrderFilesAggregate = {
  aggregate?: Maybe<PurchaseOrderFilesAggregateFields>;
  nodes: Array<PurchaseOrderFiles>;
};

/** aggregate fields of "purchase_order_files" */
export type PurchaseOrderFilesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<PurchaseOrderFilesMaxFields>;
  min?: Maybe<PurchaseOrderFilesMinFields>;
};

/** aggregate fields of "purchase_order_files" */
export type PurchaseOrderFilesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<PurchaseOrderFilesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "purchase_order_files" */
export type PurchaseOrderFilesAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<PurchaseOrderFilesMaxOrderBy>;
  min?: Maybe<PurchaseOrderFilesMinOrderBy>;
};

/** input type for inserting array relation for remote table "purchase_order_files" */
export type PurchaseOrderFilesArrRelInsertInput = {
  data: Array<PurchaseOrderFilesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<PurchaseOrderFilesOnConflict>;
};

/** Boolean expression to filter rows from the table "purchase_order_files". All fields are combined with a logical 'AND'. */
export type PurchaseOrderFilesBoolExp = {
  _and?: Maybe<Array<PurchaseOrderFilesBoolExp>>;
  _not?: Maybe<PurchaseOrderFilesBoolExp>;
  _or?: Maybe<Array<PurchaseOrderFilesBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  file?: Maybe<FilesBoolExp>;
  file_id?: Maybe<UuidComparisonExp>;
  file_type?: Maybe<PurchaseOrderFileTypeEnumComparisonExp>;
  purchase_order?: Maybe<PurchaseOrdersBoolExp>;
  purchase_order_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "purchase_order_files" */
export enum PurchaseOrderFilesConstraint {
  /** unique or primary key constraint */
  PurchaseOrderFilesPkey = "purchase_order_files_pkey",
}

/** input type for inserting data into table "purchase_order_files" */
export type PurchaseOrderFilesInsertInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  file?: Maybe<FilesObjRelInsertInput>;
  file_id?: Maybe<Scalars["uuid"]>;
  file_type?: Maybe<PurchaseOrderFileTypeEnum>;
  purchase_order?: Maybe<PurchaseOrdersObjRelInsertInput>;
  purchase_order_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type PurchaseOrderFilesMaxFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  file_id?: Maybe<Scalars["uuid"]>;
  purchase_order_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "purchase_order_files" */
export type PurchaseOrderFilesMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  file_id?: Maybe<OrderBy>;
  purchase_order_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type PurchaseOrderFilesMinFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  file_id?: Maybe<Scalars["uuid"]>;
  purchase_order_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "purchase_order_files" */
export type PurchaseOrderFilesMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  file_id?: Maybe<OrderBy>;
  purchase_order_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "purchase_order_files" */
export type PurchaseOrderFilesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PurchaseOrderFiles>;
};

/** on_conflict condition type for table "purchase_order_files" */
export type PurchaseOrderFilesOnConflict = {
  constraint: PurchaseOrderFilesConstraint;
  update_columns?: Array<PurchaseOrderFilesUpdateColumn>;
  where?: Maybe<PurchaseOrderFilesBoolExp>;
};

/** Ordering options when selecting data from "purchase_order_files". */
export type PurchaseOrderFilesOrderBy = {
  created_at?: Maybe<OrderBy>;
  file?: Maybe<FilesOrderBy>;
  file_id?: Maybe<OrderBy>;
  file_type?: Maybe<OrderBy>;
  purchase_order?: Maybe<PurchaseOrdersOrderBy>;
  purchase_order_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: purchase_order_files */
export type PurchaseOrderFilesPkColumnsInput = {
  file_id: Scalars["uuid"];
  purchase_order_id: Scalars["uuid"];
};

/** select columns of table "purchase_order_files" */
export enum PurchaseOrderFilesSelectColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FileId = "file_id",
  /** column name */
  FileType = "file_type",
  /** column name */
  PurchaseOrderId = "purchase_order_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "purchase_order_files" */
export type PurchaseOrderFilesSetInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  file_id?: Maybe<Scalars["uuid"]>;
  file_type?: Maybe<PurchaseOrderFileTypeEnum>;
  purchase_order_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "purchase_order_files" */
export enum PurchaseOrderFilesUpdateColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FileId = "file_id",
  /** column name */
  FileType = "file_type",
  /** column name */
  PurchaseOrderId = "purchase_order_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** columns and relationships of "purchase_order_metrc_transfers" */
export type PurchaseOrderMetrcTransfers = {
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  /** An object relationship */
  metrc_transfer: MetrcTransfers;
  metrc_transfer_id: Scalars["uuid"];
  /** An object relationship */
  purchase_order: PurchaseOrders;
  purchase_order_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "purchase_order_metrc_transfers" */
export type PurchaseOrderMetrcTransfersAggregate = {
  aggregate?: Maybe<PurchaseOrderMetrcTransfersAggregateFields>;
  nodes: Array<PurchaseOrderMetrcTransfers>;
};

/** aggregate fields of "purchase_order_metrc_transfers" */
export type PurchaseOrderMetrcTransfersAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<PurchaseOrderMetrcTransfersMaxFields>;
  min?: Maybe<PurchaseOrderMetrcTransfersMinFields>;
};

/** aggregate fields of "purchase_order_metrc_transfers" */
export type PurchaseOrderMetrcTransfersAggregateFieldsCountArgs = {
  columns?: Maybe<Array<PurchaseOrderMetrcTransfersSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "purchase_order_metrc_transfers" */
export type PurchaseOrderMetrcTransfersAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<PurchaseOrderMetrcTransfersMaxOrderBy>;
  min?: Maybe<PurchaseOrderMetrcTransfersMinOrderBy>;
};

/** input type for inserting array relation for remote table "purchase_order_metrc_transfers" */
export type PurchaseOrderMetrcTransfersArrRelInsertInput = {
  data: Array<PurchaseOrderMetrcTransfersInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<PurchaseOrderMetrcTransfersOnConflict>;
};

/** Boolean expression to filter rows from the table "purchase_order_metrc_transfers". All fields are combined with a logical 'AND'. */
export type PurchaseOrderMetrcTransfersBoolExp = {
  _and?: Maybe<Array<PurchaseOrderMetrcTransfersBoolExp>>;
  _not?: Maybe<PurchaseOrderMetrcTransfersBoolExp>;
  _or?: Maybe<Array<PurchaseOrderMetrcTransfersBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  metrc_transfer?: Maybe<MetrcTransfersBoolExp>;
  metrc_transfer_id?: Maybe<UuidComparisonExp>;
  purchase_order?: Maybe<PurchaseOrdersBoolExp>;
  purchase_order_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "purchase_order_metrc_transfers" */
export enum PurchaseOrderMetrcTransfersConstraint {
  /** unique or primary key constraint */
  PurchaseOrderMetrcTransferPurchaseOrderIdMetrcTransKey = "purchase_order_metrc_transfer_purchase_order_id_metrc_trans_key",
  /** unique or primary key constraint */
  PurchaseOrderMetrcTransfersPkey = "purchase_order_metrc_transfers_pkey",
}

/** input type for inserting data into table "purchase_order_metrc_transfers" */
export type PurchaseOrderMetrcTransfersInsertInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  metrc_transfer?: Maybe<MetrcTransfersObjRelInsertInput>;
  metrc_transfer_id?: Maybe<Scalars["uuid"]>;
  purchase_order?: Maybe<PurchaseOrdersObjRelInsertInput>;
  purchase_order_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type PurchaseOrderMetrcTransfersMaxFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  metrc_transfer_id?: Maybe<Scalars["uuid"]>;
  purchase_order_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "purchase_order_metrc_transfers" */
export type PurchaseOrderMetrcTransfersMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  metrc_transfer_id?: Maybe<OrderBy>;
  purchase_order_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type PurchaseOrderMetrcTransfersMinFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  metrc_transfer_id?: Maybe<Scalars["uuid"]>;
  purchase_order_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "purchase_order_metrc_transfers" */
export type PurchaseOrderMetrcTransfersMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  metrc_transfer_id?: Maybe<OrderBy>;
  purchase_order_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "purchase_order_metrc_transfers" */
export type PurchaseOrderMetrcTransfersMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PurchaseOrderMetrcTransfers>;
};

/** on_conflict condition type for table "purchase_order_metrc_transfers" */
export type PurchaseOrderMetrcTransfersOnConflict = {
  constraint: PurchaseOrderMetrcTransfersConstraint;
  update_columns?: Array<PurchaseOrderMetrcTransfersUpdateColumn>;
  where?: Maybe<PurchaseOrderMetrcTransfersBoolExp>;
};

/** Ordering options when selecting data from "purchase_order_metrc_transfers". */
export type PurchaseOrderMetrcTransfersOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  metrc_transfer?: Maybe<MetrcTransfersOrderBy>;
  metrc_transfer_id?: Maybe<OrderBy>;
  purchase_order?: Maybe<PurchaseOrdersOrderBy>;
  purchase_order_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: purchase_order_metrc_transfers */
export type PurchaseOrderMetrcTransfersPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "purchase_order_metrc_transfers" */
export enum PurchaseOrderMetrcTransfersSelectColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  MetrcTransferId = "metrc_transfer_id",
  /** column name */
  PurchaseOrderId = "purchase_order_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "purchase_order_metrc_transfers" */
export type PurchaseOrderMetrcTransfersSetInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  metrc_transfer_id?: Maybe<Scalars["uuid"]>;
  purchase_order_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "purchase_order_metrc_transfers" */
export enum PurchaseOrderMetrcTransfersUpdateColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  MetrcTransferId = "metrc_transfer_id",
  /** column name */
  PurchaseOrderId = "purchase_order_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** columns and relationships of "purchase_orders" */
export type PurchaseOrders = {
  all_bank_notes: Scalars["json"];
  all_customer_notes: Scalars["json"];
  amount?: Maybe<Scalars["numeric"]>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<Scalars["numeric"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  approving_user_id?: Maybe<Users>;
  bank_incomplete_note?: Maybe<Scalars["String"]>;
  /** Comment left on purchase order by bank user for bank user */
  bank_note?: Maybe<Scalars["String"]>;
  /** When bank rejects purchase order, this mandatory note explains the rejection */
  bank_rejection_note?: Maybe<Scalars["String"]>;
  closed_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  company: Companies;
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  /** Comment left on purchase order by customer user for bank user */
  customer_note?: Maybe<Scalars["String"]>;
  delivery_date?: Maybe<Scalars["date"]>;
  funded_at?: Maybe<Scalars["timestamptz"]>;
  history?: Maybe<Scalars["jsonb"]>;
  id: Scalars["uuid"];
  incompleted_at?: Maybe<Scalars["timestamptz"]>;
  /** Whether this purchase order includes "cannabis or derivatives"; NULL means unknown (neither true nor false) */
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  /** Whether this purchase order is based on Metrc manifests; this may be true even if purchase order does not have associated manifests purchase order is a draft */
  is_metrc_based?: Maybe<Scalars["Boolean"]>;
  /** An array relationship */
  loans: Array<Loans>;
  /** An aggregate relationship */
  loans_aggregate: LoansAggregate;
  net_terms?: Maybe<Scalars["Int"]>;
  new_purchase_order_status?: Maybe<Scalars["String"]>;
  order_date?: Maybe<Scalars["date"]>;
  order_number: Scalars["String"];
  /** An array relationship */
  purchase_order_files: Array<PurchaseOrderFiles>;
  /** An aggregate relationship */
  purchase_order_files_aggregate: PurchaseOrderFilesAggregate;
  /** An array relationship */
  purchase_order_metrc_transfers: Array<PurchaseOrderMetrcTransfers>;
  /** An aggregate relationship */
  purchase_order_metrc_transfers_aggregate: PurchaseOrderMetrcTransfersAggregate;
  rejected_at?: Maybe<Scalars["timestamptz"]>;
  rejected_by_user_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  rejecting_user_id?: Maybe<Users>;
  /** When vendor rejects purchase order, this mandatory note explains the rejection */
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  status: RequestStatusEnum;
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  vendor?: Maybe<Vendors>;
  vendor_id: Scalars["uuid"];
};

/** columns and relationships of "purchase_orders" */
export type PurchaseOrdersAllBankNotesArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "purchase_orders" */
export type PurchaseOrdersAllCustomerNotesArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "purchase_orders" */
export type PurchaseOrdersHistoryArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "purchase_orders" */
export type PurchaseOrdersLoansArgs = {
  distinct_on?: Maybe<Array<LoansSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoansOrderBy>>;
  where?: Maybe<LoansBoolExp>;
};

/** columns and relationships of "purchase_orders" */
export type PurchaseOrdersLoansAggregateArgs = {
  distinct_on?: Maybe<Array<LoansSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoansOrderBy>>;
  where?: Maybe<LoansBoolExp>;
};

/** columns and relationships of "purchase_orders" */
export type PurchaseOrdersPurchaseOrderFilesArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderFilesOrderBy>>;
  where?: Maybe<PurchaseOrderFilesBoolExp>;
};

/** columns and relationships of "purchase_orders" */
export type PurchaseOrdersPurchaseOrderFilesAggregateArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderFilesOrderBy>>;
  where?: Maybe<PurchaseOrderFilesBoolExp>;
};

/** columns and relationships of "purchase_orders" */
export type PurchaseOrdersPurchaseOrderMetrcTransfersArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderMetrcTransfersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderMetrcTransfersOrderBy>>;
  where?: Maybe<PurchaseOrderMetrcTransfersBoolExp>;
};

/** columns and relationships of "purchase_orders" */
export type PurchaseOrdersPurchaseOrderMetrcTransfersAggregateArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderMetrcTransfersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderMetrcTransfersOrderBy>>;
  where?: Maybe<PurchaseOrderMetrcTransfersBoolExp>;
};

/** aggregated selection of "purchase_orders" */
export type PurchaseOrdersAggregate = {
  aggregate?: Maybe<PurchaseOrdersAggregateFields>;
  nodes: Array<PurchaseOrders>;
};

/** aggregate fields of "purchase_orders" */
export type PurchaseOrdersAggregateFields = {
  avg?: Maybe<PurchaseOrdersAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<PurchaseOrdersMaxFields>;
  min?: Maybe<PurchaseOrdersMinFields>;
  stddev?: Maybe<PurchaseOrdersStddevFields>;
  stddev_pop?: Maybe<PurchaseOrdersStddevPopFields>;
  stddev_samp?: Maybe<PurchaseOrdersStddevSampFields>;
  sum?: Maybe<PurchaseOrdersSumFields>;
  var_pop?: Maybe<PurchaseOrdersVarPopFields>;
  var_samp?: Maybe<PurchaseOrdersVarSampFields>;
  variance?: Maybe<PurchaseOrdersVarianceFields>;
};

/** aggregate fields of "purchase_orders" */
export type PurchaseOrdersAggregateFieldsCountArgs = {
  columns?: Maybe<Array<PurchaseOrdersSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "purchase_orders" */
export type PurchaseOrdersAggregateOrderBy = {
  avg?: Maybe<PurchaseOrdersAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<PurchaseOrdersMaxOrderBy>;
  min?: Maybe<PurchaseOrdersMinOrderBy>;
  stddev?: Maybe<PurchaseOrdersStddevOrderBy>;
  stddev_pop?: Maybe<PurchaseOrdersStddevPopOrderBy>;
  stddev_samp?: Maybe<PurchaseOrdersStddevSampOrderBy>;
  sum?: Maybe<PurchaseOrdersSumOrderBy>;
  var_pop?: Maybe<PurchaseOrdersVarPopOrderBy>;
  var_samp?: Maybe<PurchaseOrdersVarSampOrderBy>;
  variance?: Maybe<PurchaseOrdersVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type PurchaseOrdersAppendInput = {
  history?: Maybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "purchase_orders" */
export type PurchaseOrdersArrRelInsertInput = {
  data: Array<PurchaseOrdersInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<PurchaseOrdersOnConflict>;
};

/** aggregate avg on columns */
export type PurchaseOrdersAvgFields = {
  amount?: Maybe<Scalars["Float"]>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<Scalars["Float"]>;
  net_terms?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "purchase_orders" */
export type PurchaseOrdersAvgOrderBy = {
  amount?: Maybe<OrderBy>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<OrderBy>;
  net_terms?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "purchase_orders". All fields are combined with a logical 'AND'. */
export type PurchaseOrdersBoolExp = {
  _and?: Maybe<Array<PurchaseOrdersBoolExp>>;
  _not?: Maybe<PurchaseOrdersBoolExp>;
  _or?: Maybe<Array<PurchaseOrdersBoolExp>>;
  all_bank_notes?: Maybe<JsonComparisonExp>;
  all_customer_notes?: Maybe<JsonComparisonExp>;
  amount?: Maybe<NumericComparisonExp>;
  amount_funded?: Maybe<NumericComparisonExp>;
  approved_at?: Maybe<TimestamptzComparisonExp>;
  approved_by_user_id?: Maybe<UuidComparisonExp>;
  approving_user_id?: Maybe<UsersBoolExp>;
  bank_incomplete_note?: Maybe<StringComparisonExp>;
  bank_note?: Maybe<StringComparisonExp>;
  bank_rejection_note?: Maybe<StringComparisonExp>;
  closed_at?: Maybe<TimestamptzComparisonExp>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  customer_note?: Maybe<StringComparisonExp>;
  delivery_date?: Maybe<DateComparisonExp>;
  funded_at?: Maybe<TimestamptzComparisonExp>;
  history?: Maybe<JsonbComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  incompleted_at?: Maybe<TimestamptzComparisonExp>;
  is_cannabis?: Maybe<BooleanComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  is_metrc_based?: Maybe<BooleanComparisonExp>;
  loans?: Maybe<LoansBoolExp>;
  net_terms?: Maybe<IntComparisonExp>;
  new_purchase_order_status?: Maybe<StringComparisonExp>;
  order_date?: Maybe<DateComparisonExp>;
  order_number?: Maybe<StringComparisonExp>;
  purchase_order_files?: Maybe<PurchaseOrderFilesBoolExp>;
  purchase_order_metrc_transfers?: Maybe<PurchaseOrderMetrcTransfersBoolExp>;
  rejected_at?: Maybe<TimestamptzComparisonExp>;
  rejected_by_user_id?: Maybe<UuidComparisonExp>;
  rejecting_user_id?: Maybe<UsersBoolExp>;
  rejection_note?: Maybe<StringComparisonExp>;
  requested_at?: Maybe<TimestamptzComparisonExp>;
  status?: Maybe<RequestStatusEnumComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  vendor?: Maybe<VendorsBoolExp>;
  vendor_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "purchase_orders" */
export enum PurchaseOrdersConstraint {
  /** unique or primary key constraint */
  PurchaseOrdersPkey = "purchase_orders_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type PurchaseOrdersDeleteAtPathInput = {
  history?: Maybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type PurchaseOrdersDeleteElemInput = {
  history?: Maybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type PurchaseOrdersDeleteKeyInput = {
  history?: Maybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "purchase_orders" */
export type PurchaseOrdersIncInput = {
  amount?: Maybe<Scalars["numeric"]>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<Scalars["numeric"]>;
  net_terms?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "purchase_orders" */
export type PurchaseOrdersInsertInput = {
  all_bank_notes?: Maybe<Scalars["json"]>;
  all_customer_notes?: Maybe<Scalars["json"]>;
  amount?: Maybe<Scalars["numeric"]>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<Scalars["numeric"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  approving_user_id?: Maybe<UsersObjRelInsertInput>;
  bank_incomplete_note?: Maybe<Scalars["String"]>;
  /** Comment left on purchase order by bank user for bank user */
  bank_note?: Maybe<Scalars["String"]>;
  /** When bank rejects purchase order, this mandatory note explains the rejection */
  bank_rejection_note?: Maybe<Scalars["String"]>;
  closed_at?: Maybe<Scalars["timestamptz"]>;
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** Comment left on purchase order by customer user for bank user */
  customer_note?: Maybe<Scalars["String"]>;
  delivery_date?: Maybe<Scalars["date"]>;
  funded_at?: Maybe<Scalars["timestamptz"]>;
  history?: Maybe<Scalars["jsonb"]>;
  id?: Maybe<Scalars["uuid"]>;
  incompleted_at?: Maybe<Scalars["timestamptz"]>;
  /** Whether this purchase order includes "cannabis or derivatives"; NULL means unknown (neither true nor false) */
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  /** Whether this purchase order is based on Metrc manifests; this may be true even if purchase order does not have associated manifests purchase order is a draft */
  is_metrc_based?: Maybe<Scalars["Boolean"]>;
  loans?: Maybe<LoansArrRelInsertInput>;
  net_terms?: Maybe<Scalars["Int"]>;
  new_purchase_order_status?: Maybe<Scalars["String"]>;
  order_date?: Maybe<Scalars["date"]>;
  order_number?: Maybe<Scalars["String"]>;
  purchase_order_files?: Maybe<PurchaseOrderFilesArrRelInsertInput>;
  purchase_order_metrc_transfers?: Maybe<PurchaseOrderMetrcTransfersArrRelInsertInput>;
  rejected_at?: Maybe<Scalars["timestamptz"]>;
  rejected_by_user_id?: Maybe<Scalars["uuid"]>;
  rejecting_user_id?: Maybe<UsersObjRelInsertInput>;
  /** When vendor rejects purchase order, this mandatory note explains the rejection */
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<RequestStatusEnum>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vendor?: Maybe<VendorsObjRelInsertInput>;
  vendor_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type PurchaseOrdersMaxFields = {
  amount?: Maybe<Scalars["numeric"]>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<Scalars["numeric"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  bank_incomplete_note?: Maybe<Scalars["String"]>;
  /** Comment left on purchase order by bank user for bank user */
  bank_note?: Maybe<Scalars["String"]>;
  /** When bank rejects purchase order, this mandatory note explains the rejection */
  bank_rejection_note?: Maybe<Scalars["String"]>;
  closed_at?: Maybe<Scalars["timestamptz"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** Comment left on purchase order by customer user for bank user */
  customer_note?: Maybe<Scalars["String"]>;
  delivery_date?: Maybe<Scalars["date"]>;
  funded_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  incompleted_at?: Maybe<Scalars["timestamptz"]>;
  net_terms?: Maybe<Scalars["Int"]>;
  new_purchase_order_status?: Maybe<Scalars["String"]>;
  order_date?: Maybe<Scalars["date"]>;
  order_number?: Maybe<Scalars["String"]>;
  rejected_at?: Maybe<Scalars["timestamptz"]>;
  rejected_by_user_id?: Maybe<Scalars["uuid"]>;
  /** When vendor rejects purchase order, this mandatory note explains the rejection */
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vendor_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "purchase_orders" */
export type PurchaseOrdersMaxOrderBy = {
  amount?: Maybe<OrderBy>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by_user_id?: Maybe<OrderBy>;
  bank_incomplete_note?: Maybe<OrderBy>;
  /** Comment left on purchase order by bank user for bank user */
  bank_note?: Maybe<OrderBy>;
  /** When bank rejects purchase order, this mandatory note explains the rejection */
  bank_rejection_note?: Maybe<OrderBy>;
  closed_at?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  /** Comment left on purchase order by customer user for bank user */
  customer_note?: Maybe<OrderBy>;
  delivery_date?: Maybe<OrderBy>;
  funded_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  incompleted_at?: Maybe<OrderBy>;
  net_terms?: Maybe<OrderBy>;
  new_purchase_order_status?: Maybe<OrderBy>;
  order_date?: Maybe<OrderBy>;
  order_number?: Maybe<OrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejected_by_user_id?: Maybe<OrderBy>;
  /** When vendor rejects purchase order, this mandatory note explains the rejection */
  rejection_note?: Maybe<OrderBy>;
  requested_at?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type PurchaseOrdersMinFields = {
  amount?: Maybe<Scalars["numeric"]>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<Scalars["numeric"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  bank_incomplete_note?: Maybe<Scalars["String"]>;
  /** Comment left on purchase order by bank user for bank user */
  bank_note?: Maybe<Scalars["String"]>;
  /** When bank rejects purchase order, this mandatory note explains the rejection */
  bank_rejection_note?: Maybe<Scalars["String"]>;
  closed_at?: Maybe<Scalars["timestamptz"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** Comment left on purchase order by customer user for bank user */
  customer_note?: Maybe<Scalars["String"]>;
  delivery_date?: Maybe<Scalars["date"]>;
  funded_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  incompleted_at?: Maybe<Scalars["timestamptz"]>;
  net_terms?: Maybe<Scalars["Int"]>;
  new_purchase_order_status?: Maybe<Scalars["String"]>;
  order_date?: Maybe<Scalars["date"]>;
  order_number?: Maybe<Scalars["String"]>;
  rejected_at?: Maybe<Scalars["timestamptz"]>;
  rejected_by_user_id?: Maybe<Scalars["uuid"]>;
  /** When vendor rejects purchase order, this mandatory note explains the rejection */
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vendor_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "purchase_orders" */
export type PurchaseOrdersMinOrderBy = {
  amount?: Maybe<OrderBy>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by_user_id?: Maybe<OrderBy>;
  bank_incomplete_note?: Maybe<OrderBy>;
  /** Comment left on purchase order by bank user for bank user */
  bank_note?: Maybe<OrderBy>;
  /** When bank rejects purchase order, this mandatory note explains the rejection */
  bank_rejection_note?: Maybe<OrderBy>;
  closed_at?: Maybe<OrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  /** Comment left on purchase order by customer user for bank user */
  customer_note?: Maybe<OrderBy>;
  delivery_date?: Maybe<OrderBy>;
  funded_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  incompleted_at?: Maybe<OrderBy>;
  net_terms?: Maybe<OrderBy>;
  new_purchase_order_status?: Maybe<OrderBy>;
  order_date?: Maybe<OrderBy>;
  order_number?: Maybe<OrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejected_by_user_id?: Maybe<OrderBy>;
  /** When vendor rejects purchase order, this mandatory note explains the rejection */
  rejection_note?: Maybe<OrderBy>;
  requested_at?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "purchase_orders" */
export type PurchaseOrdersMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PurchaseOrders>;
};

/** input type for inserting object relation for remote table "purchase_orders" */
export type PurchaseOrdersObjRelInsertInput = {
  data: PurchaseOrdersInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<PurchaseOrdersOnConflict>;
};

/** on_conflict condition type for table "purchase_orders" */
export type PurchaseOrdersOnConflict = {
  constraint: PurchaseOrdersConstraint;
  update_columns?: Array<PurchaseOrdersUpdateColumn>;
  where?: Maybe<PurchaseOrdersBoolExp>;
};

/** Ordering options when selecting data from "purchase_orders". */
export type PurchaseOrdersOrderBy = {
  all_bank_notes?: Maybe<OrderBy>;
  all_customer_notes?: Maybe<OrderBy>;
  amount?: Maybe<OrderBy>;
  amount_funded?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by_user_id?: Maybe<OrderBy>;
  approving_user_id?: Maybe<UsersOrderBy>;
  bank_incomplete_note?: Maybe<OrderBy>;
  bank_note?: Maybe<OrderBy>;
  bank_rejection_note?: Maybe<OrderBy>;
  closed_at?: Maybe<OrderBy>;
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  customer_note?: Maybe<OrderBy>;
  delivery_date?: Maybe<OrderBy>;
  funded_at?: Maybe<OrderBy>;
  history?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  incompleted_at?: Maybe<OrderBy>;
  is_cannabis?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  is_metrc_based?: Maybe<OrderBy>;
  loans_aggregate?: Maybe<LoansAggregateOrderBy>;
  net_terms?: Maybe<OrderBy>;
  new_purchase_order_status?: Maybe<OrderBy>;
  order_date?: Maybe<OrderBy>;
  order_number?: Maybe<OrderBy>;
  purchase_order_files_aggregate?: Maybe<PurchaseOrderFilesAggregateOrderBy>;
  purchase_order_metrc_transfers_aggregate?: Maybe<PurchaseOrderMetrcTransfersAggregateOrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejected_by_user_id?: Maybe<OrderBy>;
  rejecting_user_id?: Maybe<UsersOrderBy>;
  rejection_note?: Maybe<OrderBy>;
  requested_at?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  vendor?: Maybe<VendorsOrderBy>;
  vendor_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: purchase_orders */
export type PurchaseOrdersPkColumnsInput = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type PurchaseOrdersPrependInput = {
  history?: Maybe<Scalars["jsonb"]>;
};

/** select columns of table "purchase_orders" */
export enum PurchaseOrdersSelectColumn {
  /** column name */
  AllBankNotes = "all_bank_notes",
  /** column name */
  AllCustomerNotes = "all_customer_notes",
  /** column name */
  Amount = "amount",
  /** column name */
  AmountFunded = "amount_funded",
  /** column name */
  ApprovedAt = "approved_at",
  /** column name */
  ApprovedByUserId = "approved_by_user_id",
  /** column name */
  BankIncompleteNote = "bank_incomplete_note",
  /** column name */
  BankNote = "bank_note",
  /** column name */
  BankRejectionNote = "bank_rejection_note",
  /** column name */
  ClosedAt = "closed_at",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CustomerNote = "customer_note",
  /** column name */
  DeliveryDate = "delivery_date",
  /** column name */
  FundedAt = "funded_at",
  /** column name */
  History = "history",
  /** column name */
  Id = "id",
  /** column name */
  IncompletedAt = "incompleted_at",
  /** column name */
  IsCannabis = "is_cannabis",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  IsMetrcBased = "is_metrc_based",
  /** column name */
  NetTerms = "net_terms",
  /** column name */
  NewPurchaseOrderStatus = "new_purchase_order_status",
  /** column name */
  OrderDate = "order_date",
  /** column name */
  OrderNumber = "order_number",
  /** column name */
  RejectedAt = "rejected_at",
  /** column name */
  RejectedByUserId = "rejected_by_user_id",
  /** column name */
  RejectionNote = "rejection_note",
  /** column name */
  RequestedAt = "requested_at",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VendorId = "vendor_id",
}

/** input type for updating data in table "purchase_orders" */
export type PurchaseOrdersSetInput = {
  all_bank_notes?: Maybe<Scalars["json"]>;
  all_customer_notes?: Maybe<Scalars["json"]>;
  amount?: Maybe<Scalars["numeric"]>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<Scalars["numeric"]>;
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  bank_incomplete_note?: Maybe<Scalars["String"]>;
  /** Comment left on purchase order by bank user for bank user */
  bank_note?: Maybe<Scalars["String"]>;
  /** When bank rejects purchase order, this mandatory note explains the rejection */
  bank_rejection_note?: Maybe<Scalars["String"]>;
  closed_at?: Maybe<Scalars["timestamptz"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** Comment left on purchase order by customer user for bank user */
  customer_note?: Maybe<Scalars["String"]>;
  delivery_date?: Maybe<Scalars["date"]>;
  funded_at?: Maybe<Scalars["timestamptz"]>;
  history?: Maybe<Scalars["jsonb"]>;
  id?: Maybe<Scalars["uuid"]>;
  incompleted_at?: Maybe<Scalars["timestamptz"]>;
  /** Whether this purchase order includes "cannabis or derivatives"; NULL means unknown (neither true nor false) */
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  /** Whether this purchase order is based on Metrc manifests; this may be true even if purchase order does not have associated manifests purchase order is a draft */
  is_metrc_based?: Maybe<Scalars["Boolean"]>;
  net_terms?: Maybe<Scalars["Int"]>;
  new_purchase_order_status?: Maybe<Scalars["String"]>;
  order_date?: Maybe<Scalars["date"]>;
  order_number?: Maybe<Scalars["String"]>;
  rejected_at?: Maybe<Scalars["timestamptz"]>;
  rejected_by_user_id?: Maybe<Scalars["uuid"]>;
  /** When vendor rejects purchase order, this mandatory note explains the rejection */
  rejection_note?: Maybe<Scalars["String"]>;
  requested_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<RequestStatusEnum>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vendor_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type PurchaseOrdersStddevFields = {
  amount?: Maybe<Scalars["Float"]>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<Scalars["Float"]>;
  net_terms?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "purchase_orders" */
export type PurchaseOrdersStddevOrderBy = {
  amount?: Maybe<OrderBy>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<OrderBy>;
  net_terms?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type PurchaseOrdersStddevPopFields = {
  amount?: Maybe<Scalars["Float"]>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<Scalars["Float"]>;
  net_terms?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "purchase_orders" */
export type PurchaseOrdersStddevPopOrderBy = {
  amount?: Maybe<OrderBy>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<OrderBy>;
  net_terms?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type PurchaseOrdersStddevSampFields = {
  amount?: Maybe<Scalars["Float"]>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<Scalars["Float"]>;
  net_terms?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "purchase_orders" */
export type PurchaseOrdersStddevSampOrderBy = {
  amount?: Maybe<OrderBy>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<OrderBy>;
  net_terms?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type PurchaseOrdersSumFields = {
  amount?: Maybe<Scalars["numeric"]>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<Scalars["numeric"]>;
  net_terms?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "purchase_orders" */
export type PurchaseOrdersSumOrderBy = {
  amount?: Maybe<OrderBy>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<OrderBy>;
  net_terms?: Maybe<OrderBy>;
};

/** update columns of table "purchase_orders" */
export enum PurchaseOrdersUpdateColumn {
  /** column name */
  AllBankNotes = "all_bank_notes",
  /** column name */
  AllCustomerNotes = "all_customer_notes",
  /** column name */
  Amount = "amount",
  /** column name */
  AmountFunded = "amount_funded",
  /** column name */
  ApprovedAt = "approved_at",
  /** column name */
  ApprovedByUserId = "approved_by_user_id",
  /** column name */
  BankIncompleteNote = "bank_incomplete_note",
  /** column name */
  BankNote = "bank_note",
  /** column name */
  BankRejectionNote = "bank_rejection_note",
  /** column name */
  ClosedAt = "closed_at",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CustomerNote = "customer_note",
  /** column name */
  DeliveryDate = "delivery_date",
  /** column name */
  FundedAt = "funded_at",
  /** column name */
  History = "history",
  /** column name */
  Id = "id",
  /** column name */
  IncompletedAt = "incompleted_at",
  /** column name */
  IsCannabis = "is_cannabis",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  IsMetrcBased = "is_metrc_based",
  /** column name */
  NetTerms = "net_terms",
  /** column name */
  NewPurchaseOrderStatus = "new_purchase_order_status",
  /** column name */
  OrderDate = "order_date",
  /** column name */
  OrderNumber = "order_number",
  /** column name */
  RejectedAt = "rejected_at",
  /** column name */
  RejectedByUserId = "rejected_by_user_id",
  /** column name */
  RejectionNote = "rejection_note",
  /** column name */
  RequestedAt = "requested_at",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VendorId = "vendor_id",
}

/** aggregate var_pop on columns */
export type PurchaseOrdersVarPopFields = {
  amount?: Maybe<Scalars["Float"]>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<Scalars["Float"]>;
  net_terms?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "purchase_orders" */
export type PurchaseOrdersVarPopOrderBy = {
  amount?: Maybe<OrderBy>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<OrderBy>;
  net_terms?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type PurchaseOrdersVarSampFields = {
  amount?: Maybe<Scalars["Float"]>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<Scalars["Float"]>;
  net_terms?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "purchase_orders" */
export type PurchaseOrdersVarSampOrderBy = {
  amount?: Maybe<OrderBy>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<OrderBy>;
  net_terms?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type PurchaseOrdersVarianceFields = {
  amount?: Maybe<Scalars["Float"]>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<Scalars["Float"]>;
  net_terms?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "purchase_orders" */
export type PurchaseOrdersVarianceOrderBy = {
  amount?: Maybe<OrderBy>;
  /** How much in dollars that this Purchase Order has been funded */
  amount_funded?: Maybe<OrderBy>;
  net_terms?: Maybe<OrderBy>;
};

export type QueryRoot = {
  /** fetch data from the table: "async_job_summaries" */
  async_job_summaries: Array<AsyncJobSummaries>;
  /** fetch aggregated fields from the table: "async_job_summaries" */
  async_job_summaries_aggregate: AsyncJobSummariesAggregate;
  /** fetch data from the table: "async_job_summaries" using primary key columns */
  async_job_summaries_by_pk?: Maybe<AsyncJobSummaries>;
  /** fetch data from the table: "async_jobs" */
  async_jobs: Array<AsyncJobs>;
  /** fetch aggregated fields from the table: "async_jobs" */
  async_jobs_aggregate: AsyncJobsAggregate;
  /** fetch data from the table: "async_jobs" using primary key columns */
  async_jobs_by_pk?: Maybe<AsyncJobs>;
  /** fetch data from the table: "async_pipelines" */
  async_pipelines: Array<AsyncPipelines>;
  /** fetch aggregated fields from the table: "async_pipelines" */
  async_pipelines_aggregate: AsyncPipelinesAggregate;
  /** fetch data from the table: "async_pipelines" using primary key columns */
  async_pipelines_by_pk?: Maybe<AsyncPipelines>;
  /** fetch data from the table: "audit_events" */
  audit_events: Array<AuditEvents>;
  /** fetch aggregated fields from the table: "audit_events" */
  audit_events_aggregate: AuditEventsAggregate;
  /** fetch data from the table: "audit_events" using primary key columns */
  audit_events_by_pk?: Maybe<AuditEvents>;
  /** An array relationship */
  bank_accounts: Array<BankAccounts>;
  /** An aggregate relationship */
  bank_accounts_aggregate: BankAccountsAggregate;
  /** fetch data from the table: "bank_accounts" using primary key columns */
  bank_accounts_by_pk?: Maybe<BankAccounts>;
  /** fetch data from the table: "bank_financial_summaries" */
  bank_financial_summaries: Array<BankFinancialSummaries>;
  /** fetch aggregated fields from the table: "bank_financial_summaries" */
  bank_financial_summaries_aggregate: BankFinancialSummariesAggregate;
  /** fetch data from the table: "bank_financial_summaries" using primary key columns */
  bank_financial_summaries_by_pk?: Maybe<BankFinancialSummaries>;
  /** fetch data from the table: "bespoke_catalog_brands" */
  bespoke_catalog_brands: Array<BespokeCatalogBrands>;
  /** fetch aggregated fields from the table: "bespoke_catalog_brands" */
  bespoke_catalog_brands_aggregate: BespokeCatalogBrandsAggregate;
  /** fetch data from the table: "bespoke_catalog_brands" using primary key columns */
  bespoke_catalog_brands_by_pk?: Maybe<BespokeCatalogBrands>;
  /** An array relationship */
  bespoke_catalog_sku_groups: Array<BespokeCatalogSkuGroups>;
  /** An aggregate relationship */
  bespoke_catalog_sku_groups_aggregate: BespokeCatalogSkuGroupsAggregate;
  /** fetch data from the table: "bespoke_catalog_sku_groups" using primary key columns */
  bespoke_catalog_sku_groups_by_pk?: Maybe<BespokeCatalogSkuGroups>;
  /** An array relationship */
  bespoke_catalog_skus: Array<BespokeCatalogSkus>;
  /** An aggregate relationship */
  bespoke_catalog_skus_aggregate: BespokeCatalogSkusAggregate;
  /** fetch data from the table: "bespoke_catalog_skus" using primary key columns */
  bespoke_catalog_skus_by_pk?: Maybe<BespokeCatalogSkus>;
  /** fetch data from the table: "blaze_preapprovals" */
  blaze_preapprovals: Array<BlazePreapprovals>;
  /** fetch aggregated fields from the table: "blaze_preapprovals" */
  blaze_preapprovals_aggregate: BlazePreapprovalsAggregate;
  /** fetch data from the table: "blaze_preapprovals" using primary key columns */
  blaze_preapprovals_by_pk?: Maybe<BlazePreapprovals>;
  /** fetch data from the table: "blaze_shop_entries" */
  blaze_shop_entries: Array<BlazeShopEntries>;
  /** fetch aggregated fields from the table: "blaze_shop_entries" */
  blaze_shop_entries_aggregate: BlazeShopEntriesAggregate;
  /** fetch data from the table: "blaze_shop_entries" using primary key columns */
  blaze_shop_entries_by_pk?: Maybe<BlazeShopEntries>;
  /** fetch data from the table: "blaze_users" */
  blaze_users: Array<BlazeUsers>;
  /** fetch aggregated fields from the table: "blaze_users" */
  blaze_users_aggregate: BlazeUsersAggregate;
  /** fetch data from the table: "blaze_users" using primary key columns */
  blaze_users_by_pk?: Maybe<BlazeUsers>;
  /** An array relationship */
  companies: Array<Companies>;
  /** An aggregate relationship */
  companies_aggregate: CompaniesAggregate;
  /** fetch data from the table: "companies" using primary key columns */
  companies_by_pk?: Maybe<Companies>;
  /** fetch data from the table: "company_agreements" */
  company_agreements: Array<CompanyAgreements>;
  /** fetch aggregated fields from the table: "company_agreements" */
  company_agreements_aggregate: CompanyAgreementsAggregate;
  /** fetch data from the table: "company_agreements" using primary key columns */
  company_agreements_by_pk?: Maybe<CompanyAgreements>;
  /** An array relationship */
  company_deliveries: Array<CompanyDeliveries>;
  /** An aggregate relationship */
  company_deliveries_aggregate: CompanyDeliveriesAggregate;
  /** fetch data from the table: "company_deliveries" using primary key columns */
  company_deliveries_by_pk?: Maybe<CompanyDeliveries>;
  /** An array relationship */
  company_facilities: Array<CompanyFacilities>;
  /** An aggregate relationship */
  company_facilities_aggregate: CompanyFacilitiesAggregate;
  /** fetch data from the table: "company_facilities" using primary key columns */
  company_facilities_by_pk?: Maybe<CompanyFacilities>;
  /** An array relationship */
  company_licenses: Array<CompanyLicenses>;
  /** An aggregate relationship */
  company_licenses_aggregate: CompanyLicensesAggregate;
  /** fetch data from the table: "company_licenses" using primary key columns */
  company_licenses_by_pk?: Maybe<CompanyLicenses>;
  /** fetch data from the table: "company_partnership_invitations" */
  company_partnership_invitations: Array<CompanyPartnershipInvitations>;
  /** fetch aggregated fields from the table: "company_partnership_invitations" */
  company_partnership_invitations_aggregate: CompanyPartnershipInvitationsAggregate;
  /** fetch data from the table: "company_partnership_invitations" using primary key columns */
  company_partnership_invitations_by_pk?: Maybe<CompanyPartnershipInvitations>;
  /** fetch data from the table: "company_partnership_requests" */
  company_partnership_requests: Array<CompanyPartnershipRequests>;
  /** fetch aggregated fields from the table: "company_partnership_requests" */
  company_partnership_requests_aggregate: CompanyPartnershipRequestsAggregate;
  /** fetch data from the table: "company_partnership_requests" using primary key columns */
  company_partnership_requests_by_pk?: Maybe<CompanyPartnershipRequests>;
  /** fetch data from the table: "company_payor_contacts" */
  company_payor_contacts: Array<CompanyPayorContacts>;
  /** fetch aggregated fields from the table: "company_payor_contacts" */
  company_payor_contacts_aggregate: CompanyPayorContactsAggregate;
  /** fetch data from the table: "company_payor_contacts" using primary key columns */
  company_payor_contacts_by_pk?: Maybe<CompanyPayorContacts>;
  /** An array relationship */
  company_payor_partnerships: Array<CompanyPayorPartnerships>;
  /** An aggregate relationship */
  company_payor_partnerships_aggregate: CompanyPayorPartnershipsAggregate;
  /** fetch data from the table: "company_payor_partnerships" using primary key columns */
  company_payor_partnerships_by_pk?: Maybe<CompanyPayorPartnerships>;
  /** fetch data from the table: "company_settings" */
  company_settings: Array<CompanySettings>;
  /** fetch aggregated fields from the table: "company_settings" */
  company_settings_aggregate: CompanySettingsAggregate;
  /** fetch data from the table: "company_settings" using primary key columns */
  company_settings_by_pk?: Maybe<CompanySettings>;
  /** fetch data from the table: "company_type" */
  company_type: Array<CompanyType>;
  /** fetch aggregated fields from the table: "company_type" */
  company_type_aggregate: CompanyTypeAggregate;
  /** fetch data from the table: "company_type" using primary key columns */
  company_type_by_pk?: Maybe<CompanyType>;
  /** fetch data from the table: "company_vendor_contacts" */
  company_vendor_contacts: Array<CompanyVendorContacts>;
  /** fetch aggregated fields from the table: "company_vendor_contacts" */
  company_vendor_contacts_aggregate: CompanyVendorContactsAggregate;
  /** fetch data from the table: "company_vendor_contacts" using primary key columns */
  company_vendor_contacts_by_pk?: Maybe<CompanyVendorContacts>;
  /** An array relationship */
  company_vendor_partnerships: Array<CompanyVendorPartnerships>;
  /** An aggregate relationship */
  company_vendor_partnerships_aggregate: CompanyVendorPartnershipsAggregate;
  /** fetch data from the table: "company_vendor_partnerships" using primary key columns */
  company_vendor_partnerships_by_pk?: Maybe<CompanyVendorPartnerships>;
  /** An array relationship */
  contracts: Array<Contracts>;
  /** An aggregate relationship */
  contracts_aggregate: ContractsAggregate;
  /** fetch data from the table: "contracts" using primary key columns */
  contracts_by_pk?: Maybe<Contracts>;
  /** An array relationship */
  customer_surveillance_results: Array<CustomerSurveillanceResults>;
  /** An aggregate relationship */
  customer_surveillance_results_aggregate: CustomerSurveillanceResultsAggregate;
  /** fetch data from the table: "customer_surveillance_results" using primary key columns */
  customer_surveillance_results_by_pk?: Maybe<CustomerSurveillanceResults>;
  /** fetch data from the table: "debt_facilities" */
  debt_facilities: Array<DebtFacilities>;
  /** fetch aggregated fields from the table: "debt_facilities" */
  debt_facilities_aggregate: DebtFacilitiesAggregate;
  /** fetch data from the table: "debt_facilities" using primary key columns */
  debt_facilities_by_pk?: Maybe<DebtFacilities>;
  /** An array relationship */
  debt_facility_capacities: Array<DebtFacilityCapacities>;
  /** An aggregate relationship */
  debt_facility_capacities_aggregate: DebtFacilityCapacitiesAggregate;
  /** fetch data from the table: "debt_facility_capacities" using primary key columns */
  debt_facility_capacities_by_pk?: Maybe<DebtFacilityCapacities>;
  /** fetch data from the table: "debt_facility_events" */
  debt_facility_events: Array<DebtFacilityEvents>;
  /** fetch aggregated fields from the table: "debt_facility_events" */
  debt_facility_events_aggregate: DebtFacilityEventsAggregate;
  /** fetch data from the table: "debt_facility_events" using primary key columns */
  debt_facility_events_by_pk?: Maybe<DebtFacilityEvents>;
  /** An array relationship */
  ebba_application_files: Array<EbbaApplicationFiles>;
  /** An aggregate relationship */
  ebba_application_files_aggregate: EbbaApplicationFilesAggregate;
  /** fetch data from the table: "ebba_application_files" using primary key columns */
  ebba_application_files_by_pk?: Maybe<EbbaApplicationFiles>;
  /** An array relationship */
  ebba_applications: Array<EbbaApplications>;
  /** An aggregate relationship */
  ebba_applications_aggregate: EbbaApplicationsAggregate;
  /** fetch data from the table: "ebba_applications" using primary key columns */
  ebba_applications_by_pk?: Maybe<EbbaApplications>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: FilesAggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** An array relationship */
  financial_summaries: Array<FinancialSummaries>;
  /** An aggregate relationship */
  financial_summaries_aggregate: FinancialSummariesAggregate;
  /** fetch data from the table: "financial_summaries" using primary key columns */
  financial_summaries_by_pk?: Maybe<FinancialSummaries>;
  /** fetch data from the table: "invoice_file_type" */
  invoice_file_type: Array<InvoiceFileType>;
  /** fetch aggregated fields from the table: "invoice_file_type" */
  invoice_file_type_aggregate: InvoiceFileTypeAggregate;
  /** fetch data from the table: "invoice_file_type" using primary key columns */
  invoice_file_type_by_pk?: Maybe<InvoiceFileType>;
  /** An array relationship */
  invoice_files: Array<InvoiceFiles>;
  /** An aggregate relationship */
  invoice_files_aggregate: InvoiceFilesAggregate;
  /** fetch data from the table: "invoice_files" using primary key columns */
  invoice_files_by_pk?: Maybe<InvoiceFiles>;
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: InvoicesAggregate;
  /** fetch data from the table: "invoices" using primary key columns */
  invoices_by_pk?: Maybe<Invoices>;
  /** fetch data from the table: "line_of_credits" */
  line_of_credits: Array<LineOfCredits>;
  /** fetch aggregated fields from the table: "line_of_credits" */
  line_of_credits_aggregate: LineOfCreditsAggregate;
  /** fetch data from the table: "line_of_credits" using primary key columns */
  line_of_credits_by_pk?: Maybe<LineOfCredits>;
  /** fetch data from the table: "loan_reports" */
  loan_reports: Array<LoanReports>;
  /** fetch aggregated fields from the table: "loan_reports" */
  loan_reports_aggregate: LoanReportsAggregate;
  /** fetch data from the table: "loan_reports" using primary key columns */
  loan_reports_by_pk?: Maybe<LoanReports>;
  /** fetch data from the table: "loan_type" */
  loan_type: Array<LoanType>;
  /** fetch aggregated fields from the table: "loan_type" */
  loan_type_aggregate: LoanTypeAggregate;
  /** fetch data from the table: "loan_type" using primary key columns */
  loan_type_by_pk?: Maybe<LoanType>;
  /** An array relationship */
  loans: Array<Loans>;
  /** An aggregate relationship */
  loans_aggregate: LoansAggregate;
  /** fetch data from the table: "loans" using primary key columns */
  loans_by_pk?: Maybe<Loans>;
  /** fetch data from the table: "metrc_analysis_summaries" */
  metrc_analysis_summaries: Array<MetrcAnalysisSummaries>;
  /** fetch aggregated fields from the table: "metrc_analysis_summaries" */
  metrc_analysis_summaries_aggregate: MetrcAnalysisSummariesAggregate;
  /** fetch data from the table: "metrc_analysis_summaries" using primary key columns */
  metrc_analysis_summaries_by_pk?: Maybe<MetrcAnalysisSummaries>;
  /** An array relationship */
  metrc_api_keys: Array<MetrcApiKeys>;
  /** An aggregate relationship */
  metrc_api_keys_aggregate: MetrcApiKeysAggregate;
  /** fetch data from the table: "metrc_api_keys" using primary key columns */
  metrc_api_keys_by_pk?: Maybe<MetrcApiKeys>;
  /** An array relationship */
  metrc_deliveries: Array<MetrcDeliveries>;
  /** An aggregate relationship */
  metrc_deliveries_aggregate: MetrcDeliveriesAggregate;
  /** fetch data from the table: "metrc_deliveries" using primary key columns */
  metrc_deliveries_by_pk?: Maybe<MetrcDeliveries>;
  /** An array relationship */
  metrc_download_summaries: Array<MetrcDownloadSummaries>;
  /** An aggregate relationship */
  metrc_download_summaries_aggregate: MetrcDownloadSummariesAggregate;
  /** fetch data from the table: "metrc_download_summaries" using primary key columns */
  metrc_download_summaries_by_pk?: Maybe<MetrcDownloadSummaries>;
  /** fetch data from the table: "metrc_harvests" */
  metrc_harvests: Array<MetrcHarvests>;
  /** fetch aggregated fields from the table: "metrc_harvests" */
  metrc_harvests_aggregate: MetrcHarvestsAggregate;
  /** fetch data from the table: "metrc_harvests" using primary key columns */
  metrc_harvests_by_pk?: Maybe<MetrcHarvests>;
  /** fetch data from the table: "metrc_packages" */
  metrc_packages: Array<MetrcPackages>;
  /** fetch aggregated fields from the table: "metrc_packages" */
  metrc_packages_aggregate: MetrcPackagesAggregate;
  /** fetch data from the table: "metrc_packages" using primary key columns */
  metrc_packages_by_pk?: Maybe<MetrcPackages>;
  /** fetch data from the table: "metrc_plant_batches" */
  metrc_plant_batches: Array<MetrcPlantBatches>;
  /** fetch aggregated fields from the table: "metrc_plant_batches" */
  metrc_plant_batches_aggregate: MetrcPlantBatchesAggregate;
  /** fetch data from the table: "metrc_plant_batches" using primary key columns */
  metrc_plant_batches_by_pk?: Maybe<MetrcPlantBatches>;
  /** fetch data from the table: "metrc_plants" */
  metrc_plants: Array<MetrcPlants>;
  /** fetch aggregated fields from the table: "metrc_plants" */
  metrc_plants_aggregate: MetrcPlantsAggregate;
  /** fetch data from the table: "metrc_plants" using primary key columns */
  metrc_plants_by_pk?: Maybe<MetrcPlants>;
  /** fetch data from the table: "metrc_sales_receipts" */
  metrc_sales_receipts: Array<MetrcSalesReceipts>;
  /** fetch aggregated fields from the table: "metrc_sales_receipts" */
  metrc_sales_receipts_aggregate: MetrcSalesReceiptsAggregate;
  /** fetch data from the table: "metrc_sales_receipts" using primary key columns */
  metrc_sales_receipts_by_pk?: Maybe<MetrcSalesReceipts>;
  /** fetch data from the table: "metrc_sales_transactions" */
  metrc_sales_transactions: Array<MetrcSalesTransactions>;
  /** An aggregate relationship */
  metrc_sales_transactions_aggregate: MetrcSalesTransactionsAggregate;
  /** fetch data from the table: "metrc_sales_transactions" using primary key columns */
  metrc_sales_transactions_by_pk?: Maybe<MetrcSalesTransactions>;
  /** An array relationship */
  metrc_to_bespoke_catalog_skus: Array<MetrcToBespokeCatalogSkus>;
  /** An aggregate relationship */
  metrc_to_bespoke_catalog_skus_aggregate: MetrcToBespokeCatalogSkusAggregate;
  /** fetch data from the table: "metrc_to_bespoke_catalog_skus" using primary key columns */
  metrc_to_bespoke_catalog_skus_by_pk?: Maybe<MetrcToBespokeCatalogSkus>;
  /** An array relationship */
  metrc_transfer_packages: Array<MetrcTransferPackages>;
  /** An aggregate relationship */
  metrc_transfer_packages_aggregate: MetrcTransferPackagesAggregate;
  /** fetch data from the table: "metrc_transfer_packages" using primary key columns */
  metrc_transfer_packages_by_pk?: Maybe<MetrcTransferPackages>;
  /** fetch data from the table: "metrc_transfers" */
  metrc_transfers: Array<MetrcTransfers>;
  /** fetch aggregated fields from the table: "metrc_transfers" */
  metrc_transfers_aggregate: MetrcTransfersAggregate;
  /** fetch data from the table: "metrc_transfers" using primary key columns */
  metrc_transfers_by_pk?: Maybe<MetrcTransfers>;
  /** An array relationship */
  monthly_summary_calculations: Array<MonthlySummaryCalculations>;
  /** An aggregate relationship */
  monthly_summary_calculations_aggregate: MonthlySummaryCalculationsAggregate;
  /** fetch data from the table: "monthly_summary_calculations" using primary key columns */
  monthly_summary_calculations_by_pk?: Maybe<MonthlySummaryCalculations>;
  /** fetch data from the table: "parent_companies" */
  parent_companies: Array<ParentCompanies>;
  /** fetch aggregated fields from the table: "parent_companies" */
  parent_companies_aggregate: ParentCompaniesAggregate;
  /** fetch data from the table: "parent_companies" using primary key columns */
  parent_companies_by_pk?: Maybe<ParentCompanies>;
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregate relationship */
  payments_aggregate: PaymentsAggregate;
  /** fetch data from the table: "payments" using primary key columns */
  payments_by_pk?: Maybe<Payments>;
  /** fetch data from the table: "payors" */
  payors: Array<Payors>;
  /** fetch aggregated fields from the table: "payors" */
  payors_aggregate: PayorsAggregate;
  /** fetch data from the table: "purchase_order_file_type" */
  purchase_order_file_type: Array<PurchaseOrderFileType>;
  /** fetch aggregated fields from the table: "purchase_order_file_type" */
  purchase_order_file_type_aggregate: PurchaseOrderFileTypeAggregate;
  /** fetch data from the table: "purchase_order_file_type" using primary key columns */
  purchase_order_file_type_by_pk?: Maybe<PurchaseOrderFileType>;
  /** An array relationship */
  purchase_order_files: Array<PurchaseOrderFiles>;
  /** An aggregate relationship */
  purchase_order_files_aggregate: PurchaseOrderFilesAggregate;
  /** fetch data from the table: "purchase_order_files" using primary key columns */
  purchase_order_files_by_pk?: Maybe<PurchaseOrderFiles>;
  /** An array relationship */
  purchase_order_metrc_transfers: Array<PurchaseOrderMetrcTransfers>;
  /** An aggregate relationship */
  purchase_order_metrc_transfers_aggregate: PurchaseOrderMetrcTransfersAggregate;
  /** fetch data from the table: "purchase_order_metrc_transfers" using primary key columns */
  purchase_order_metrc_transfers_by_pk?: Maybe<PurchaseOrderMetrcTransfers>;
  /** An array relationship */
  purchase_orders: Array<PurchaseOrders>;
  /** An aggregate relationship */
  purchase_orders_aggregate: PurchaseOrdersAggregate;
  /** fetch data from the table: "purchase_orders" using primary key columns */
  purchase_orders_by_pk?: Maybe<PurchaseOrders>;
  /** fetch data from the table: "request_status" */
  request_status: Array<RequestStatus>;
  /** fetch aggregated fields from the table: "request_status" */
  request_status_aggregate: RequestStatusAggregate;
  /** fetch data from the table: "request_status" using primary key columns */
  request_status_by_pk?: Maybe<RequestStatus>;
  /** fetch data from the table: "revoked_tokens" */
  revoked_tokens: Array<RevokedTokens>;
  /** fetch aggregated fields from the table: "revoked_tokens" */
  revoked_tokens_aggregate: RevokedTokensAggregate;
  /** fetch data from the table: "revoked_tokens" using primary key columns */
  revoked_tokens_by_pk?: Maybe<RevokedTokens>;
  /** fetch data from the table: "sync_pipelines" */
  sync_pipelines: Array<SyncPipelines>;
  /** fetch aggregated fields from the table: "sync_pipelines" */
  sync_pipelines_aggregate: SyncPipelinesAggregate;
  /** fetch data from the table: "sync_pipelines" using primary key columns */
  sync_pipelines_by_pk?: Maybe<SyncPipelines>;
  /** An array relationship */
  transactions: Array<Transactions>;
  /** An aggregate relationship */
  transactions_aggregate: TransactionsAggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  /** fetch data from the table: "two_factor_links" */
  two_factor_links: Array<TwoFactorLinks>;
  /** fetch aggregated fields from the table: "two_factor_links" */
  two_factor_links_aggregate: TwoFactorLinksAggregate;
  /** fetch data from the table: "two_factor_links" using primary key columns */
  two_factor_links_by_pk?: Maybe<TwoFactorLinks>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<UserRoles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: UserRolesAggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<UserRoles>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "vendor_change_requests" */
  vendor_change_requests: Array<VendorChangeRequests>;
  /** fetch aggregated fields from the table: "vendor_change_requests" */
  vendor_change_requests_aggregate: VendorChangeRequestsAggregate;
  /** fetch data from the table: "vendor_change_requests" using primary key columns */
  vendor_change_requests_by_pk?: Maybe<VendorChangeRequests>;
  /** fetch data from the table: "vendors" */
  vendors: Array<Vendors>;
  /** fetch aggregated fields from the table: "vendors" */
  vendors_aggregate: VendorsAggregate;
};

export type QueryRootAsyncJobSummariesArgs = {
  distinct_on?: Maybe<Array<AsyncJobSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AsyncJobSummariesOrderBy>>;
  where?: Maybe<AsyncJobSummariesBoolExp>;
};

export type QueryRootAsyncJobSummariesAggregateArgs = {
  distinct_on?: Maybe<Array<AsyncJobSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AsyncJobSummariesOrderBy>>;
  where?: Maybe<AsyncJobSummariesBoolExp>;
};

export type QueryRootAsyncJobSummariesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootAsyncJobsArgs = {
  distinct_on?: Maybe<Array<AsyncJobsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AsyncJobsOrderBy>>;
  where?: Maybe<AsyncJobsBoolExp>;
};

export type QueryRootAsyncJobsAggregateArgs = {
  distinct_on?: Maybe<Array<AsyncJobsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AsyncJobsOrderBy>>;
  where?: Maybe<AsyncJobsBoolExp>;
};

export type QueryRootAsyncJobsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootAsyncPipelinesArgs = {
  distinct_on?: Maybe<Array<AsyncPipelinesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AsyncPipelinesOrderBy>>;
  where?: Maybe<AsyncPipelinesBoolExp>;
};

export type QueryRootAsyncPipelinesAggregateArgs = {
  distinct_on?: Maybe<Array<AsyncPipelinesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AsyncPipelinesOrderBy>>;
  where?: Maybe<AsyncPipelinesBoolExp>;
};

export type QueryRootAsyncPipelinesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootAuditEventsArgs = {
  distinct_on?: Maybe<Array<AuditEventsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AuditEventsOrderBy>>;
  where?: Maybe<AuditEventsBoolExp>;
};

export type QueryRootAuditEventsAggregateArgs = {
  distinct_on?: Maybe<Array<AuditEventsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AuditEventsOrderBy>>;
  where?: Maybe<AuditEventsBoolExp>;
};

export type QueryRootAuditEventsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootBankAccountsArgs = {
  distinct_on?: Maybe<Array<BankAccountsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BankAccountsOrderBy>>;
  where?: Maybe<BankAccountsBoolExp>;
};

export type QueryRootBankAccountsAggregateArgs = {
  distinct_on?: Maybe<Array<BankAccountsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BankAccountsOrderBy>>;
  where?: Maybe<BankAccountsBoolExp>;
};

export type QueryRootBankAccountsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootBankFinancialSummariesArgs = {
  distinct_on?: Maybe<Array<BankFinancialSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BankFinancialSummariesOrderBy>>;
  where?: Maybe<BankFinancialSummariesBoolExp>;
};

export type QueryRootBankFinancialSummariesAggregateArgs = {
  distinct_on?: Maybe<Array<BankFinancialSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BankFinancialSummariesOrderBy>>;
  where?: Maybe<BankFinancialSummariesBoolExp>;
};

export type QueryRootBankFinancialSummariesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootBespokeCatalogBrandsArgs = {
  distinct_on?: Maybe<Array<BespokeCatalogBrandsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BespokeCatalogBrandsOrderBy>>;
  where?: Maybe<BespokeCatalogBrandsBoolExp>;
};

export type QueryRootBespokeCatalogBrandsAggregateArgs = {
  distinct_on?: Maybe<Array<BespokeCatalogBrandsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BespokeCatalogBrandsOrderBy>>;
  where?: Maybe<BespokeCatalogBrandsBoolExp>;
};

export type QueryRootBespokeCatalogBrandsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootBespokeCatalogSkuGroupsArgs = {
  distinct_on?: Maybe<Array<BespokeCatalogSkuGroupsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BespokeCatalogSkuGroupsOrderBy>>;
  where?: Maybe<BespokeCatalogSkuGroupsBoolExp>;
};

export type QueryRootBespokeCatalogSkuGroupsAggregateArgs = {
  distinct_on?: Maybe<Array<BespokeCatalogSkuGroupsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BespokeCatalogSkuGroupsOrderBy>>;
  where?: Maybe<BespokeCatalogSkuGroupsBoolExp>;
};

export type QueryRootBespokeCatalogSkuGroupsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootBespokeCatalogSkusArgs = {
  distinct_on?: Maybe<Array<BespokeCatalogSkusSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BespokeCatalogSkusOrderBy>>;
  where?: Maybe<BespokeCatalogSkusBoolExp>;
};

export type QueryRootBespokeCatalogSkusAggregateArgs = {
  distinct_on?: Maybe<Array<BespokeCatalogSkusSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BespokeCatalogSkusOrderBy>>;
  where?: Maybe<BespokeCatalogSkusBoolExp>;
};

export type QueryRootBespokeCatalogSkusByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootBlazePreapprovalsArgs = {
  distinct_on?: Maybe<Array<BlazePreapprovalsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BlazePreapprovalsOrderBy>>;
  where?: Maybe<BlazePreapprovalsBoolExp>;
};

export type QueryRootBlazePreapprovalsAggregateArgs = {
  distinct_on?: Maybe<Array<BlazePreapprovalsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BlazePreapprovalsOrderBy>>;
  where?: Maybe<BlazePreapprovalsBoolExp>;
};

export type QueryRootBlazePreapprovalsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootBlazeShopEntriesArgs = {
  distinct_on?: Maybe<Array<BlazeShopEntriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BlazeShopEntriesOrderBy>>;
  where?: Maybe<BlazeShopEntriesBoolExp>;
};

export type QueryRootBlazeShopEntriesAggregateArgs = {
  distinct_on?: Maybe<Array<BlazeShopEntriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BlazeShopEntriesOrderBy>>;
  where?: Maybe<BlazeShopEntriesBoolExp>;
};

export type QueryRootBlazeShopEntriesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootBlazeUsersArgs = {
  distinct_on?: Maybe<Array<BlazeUsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BlazeUsersOrderBy>>;
  where?: Maybe<BlazeUsersBoolExp>;
};

export type QueryRootBlazeUsersAggregateArgs = {
  distinct_on?: Maybe<Array<BlazeUsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BlazeUsersOrderBy>>;
  where?: Maybe<BlazeUsersBoolExp>;
};

export type QueryRootBlazeUsersByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootCompaniesArgs = {
  distinct_on?: Maybe<Array<CompaniesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompaniesOrderBy>>;
  where?: Maybe<CompaniesBoolExp>;
};

export type QueryRootCompaniesAggregateArgs = {
  distinct_on?: Maybe<Array<CompaniesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompaniesOrderBy>>;
  where?: Maybe<CompaniesBoolExp>;
};

export type QueryRootCompaniesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootCompanyAgreementsArgs = {
  distinct_on?: Maybe<Array<CompanyAgreementsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyAgreementsOrderBy>>;
  where?: Maybe<CompanyAgreementsBoolExp>;
};

export type QueryRootCompanyAgreementsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyAgreementsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyAgreementsOrderBy>>;
  where?: Maybe<CompanyAgreementsBoolExp>;
};

export type QueryRootCompanyAgreementsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootCompanyDeliveriesArgs = {
  distinct_on?: Maybe<Array<CompanyDeliveriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyDeliveriesOrderBy>>;
  where?: Maybe<CompanyDeliveriesBoolExp>;
};

export type QueryRootCompanyDeliveriesAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyDeliveriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyDeliveriesOrderBy>>;
  where?: Maybe<CompanyDeliveriesBoolExp>;
};

export type QueryRootCompanyDeliveriesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootCompanyFacilitiesArgs = {
  distinct_on?: Maybe<Array<CompanyFacilitiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyFacilitiesOrderBy>>;
  where?: Maybe<CompanyFacilitiesBoolExp>;
};

export type QueryRootCompanyFacilitiesAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyFacilitiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyFacilitiesOrderBy>>;
  where?: Maybe<CompanyFacilitiesBoolExp>;
};

export type QueryRootCompanyFacilitiesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootCompanyLicensesArgs = {
  distinct_on?: Maybe<Array<CompanyLicensesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyLicensesOrderBy>>;
  where?: Maybe<CompanyLicensesBoolExp>;
};

export type QueryRootCompanyLicensesAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyLicensesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyLicensesOrderBy>>;
  where?: Maybe<CompanyLicensesBoolExp>;
};

export type QueryRootCompanyLicensesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootCompanyPartnershipInvitationsArgs = {
  distinct_on?: Maybe<Array<CompanyPartnershipInvitationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPartnershipInvitationsOrderBy>>;
  where?: Maybe<CompanyPartnershipInvitationsBoolExp>;
};

export type QueryRootCompanyPartnershipInvitationsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyPartnershipInvitationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPartnershipInvitationsOrderBy>>;
  where?: Maybe<CompanyPartnershipInvitationsBoolExp>;
};

export type QueryRootCompanyPartnershipInvitationsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootCompanyPartnershipRequestsArgs = {
  distinct_on?: Maybe<Array<CompanyPartnershipRequestsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPartnershipRequestsOrderBy>>;
  where?: Maybe<CompanyPartnershipRequestsBoolExp>;
};

export type QueryRootCompanyPartnershipRequestsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyPartnershipRequestsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPartnershipRequestsOrderBy>>;
  where?: Maybe<CompanyPartnershipRequestsBoolExp>;
};

export type QueryRootCompanyPartnershipRequestsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootCompanyPayorContactsArgs = {
  distinct_on?: Maybe<Array<CompanyPayorContactsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorContactsOrderBy>>;
  where?: Maybe<CompanyPayorContactsBoolExp>;
};

export type QueryRootCompanyPayorContactsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyPayorContactsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorContactsOrderBy>>;
  where?: Maybe<CompanyPayorContactsBoolExp>;
};

export type QueryRootCompanyPayorContactsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootCompanyPayorPartnershipsArgs = {
  distinct_on?: Maybe<Array<CompanyPayorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorPartnershipsOrderBy>>;
  where?: Maybe<CompanyPayorPartnershipsBoolExp>;
};

export type QueryRootCompanyPayorPartnershipsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyPayorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorPartnershipsOrderBy>>;
  where?: Maybe<CompanyPayorPartnershipsBoolExp>;
};

export type QueryRootCompanyPayorPartnershipsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootCompanySettingsArgs = {
  distinct_on?: Maybe<Array<CompanySettingsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanySettingsOrderBy>>;
  where?: Maybe<CompanySettingsBoolExp>;
};

export type QueryRootCompanySettingsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanySettingsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanySettingsOrderBy>>;
  where?: Maybe<CompanySettingsBoolExp>;
};

export type QueryRootCompanySettingsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootCompanyTypeArgs = {
  distinct_on?: Maybe<Array<CompanyTypeSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyTypeOrderBy>>;
  where?: Maybe<CompanyTypeBoolExp>;
};

export type QueryRootCompanyTypeAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyTypeSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyTypeOrderBy>>;
  where?: Maybe<CompanyTypeBoolExp>;
};

export type QueryRootCompanyTypeByPkArgs = {
  value: Scalars["String"];
};

export type QueryRootCompanyVendorContactsArgs = {
  distinct_on?: Maybe<Array<CompanyVendorContactsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorContactsOrderBy>>;
  where?: Maybe<CompanyVendorContactsBoolExp>;
};

export type QueryRootCompanyVendorContactsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyVendorContactsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorContactsOrderBy>>;
  where?: Maybe<CompanyVendorContactsBoolExp>;
};

export type QueryRootCompanyVendorContactsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootCompanyVendorPartnershipsArgs = {
  distinct_on?: Maybe<Array<CompanyVendorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorPartnershipsOrderBy>>;
  where?: Maybe<CompanyVendorPartnershipsBoolExp>;
};

export type QueryRootCompanyVendorPartnershipsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyVendorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorPartnershipsOrderBy>>;
  where?: Maybe<CompanyVendorPartnershipsBoolExp>;
};

export type QueryRootCompanyVendorPartnershipsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootContractsArgs = {
  distinct_on?: Maybe<Array<ContractsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ContractsOrderBy>>;
  where?: Maybe<ContractsBoolExp>;
};

export type QueryRootContractsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ContractsOrderBy>>;
  where?: Maybe<ContractsBoolExp>;
};

export type QueryRootContractsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootCustomerSurveillanceResultsArgs = {
  distinct_on?: Maybe<Array<CustomerSurveillanceResultsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CustomerSurveillanceResultsOrderBy>>;
  where?: Maybe<CustomerSurveillanceResultsBoolExp>;
};

export type QueryRootCustomerSurveillanceResultsAggregateArgs = {
  distinct_on?: Maybe<Array<CustomerSurveillanceResultsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CustomerSurveillanceResultsOrderBy>>;
  where?: Maybe<CustomerSurveillanceResultsBoolExp>;
};

export type QueryRootCustomerSurveillanceResultsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootDebtFacilitiesArgs = {
  distinct_on?: Maybe<Array<DebtFacilitiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<DebtFacilitiesOrderBy>>;
  where?: Maybe<DebtFacilitiesBoolExp>;
};

export type QueryRootDebtFacilitiesAggregateArgs = {
  distinct_on?: Maybe<Array<DebtFacilitiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<DebtFacilitiesOrderBy>>;
  where?: Maybe<DebtFacilitiesBoolExp>;
};

export type QueryRootDebtFacilitiesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootDebtFacilityCapacitiesArgs = {
  distinct_on?: Maybe<Array<DebtFacilityCapacitiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<DebtFacilityCapacitiesOrderBy>>;
  where?: Maybe<DebtFacilityCapacitiesBoolExp>;
};

export type QueryRootDebtFacilityCapacitiesAggregateArgs = {
  distinct_on?: Maybe<Array<DebtFacilityCapacitiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<DebtFacilityCapacitiesOrderBy>>;
  where?: Maybe<DebtFacilityCapacitiesBoolExp>;
};

export type QueryRootDebtFacilityCapacitiesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootDebtFacilityEventsArgs = {
  distinct_on?: Maybe<Array<DebtFacilityEventsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<DebtFacilityEventsOrderBy>>;
  where?: Maybe<DebtFacilityEventsBoolExp>;
};

export type QueryRootDebtFacilityEventsAggregateArgs = {
  distinct_on?: Maybe<Array<DebtFacilityEventsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<DebtFacilityEventsOrderBy>>;
  where?: Maybe<DebtFacilityEventsBoolExp>;
};

export type QueryRootDebtFacilityEventsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootEbbaApplicationFilesArgs = {
  distinct_on?: Maybe<Array<EbbaApplicationFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EbbaApplicationFilesOrderBy>>;
  where?: Maybe<EbbaApplicationFilesBoolExp>;
};

export type QueryRootEbbaApplicationFilesAggregateArgs = {
  distinct_on?: Maybe<Array<EbbaApplicationFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EbbaApplicationFilesOrderBy>>;
  where?: Maybe<EbbaApplicationFilesBoolExp>;
};

export type QueryRootEbbaApplicationFilesByPkArgs = {
  ebba_application_id: Scalars["uuid"];
  file_id: Scalars["uuid"];
};

export type QueryRootEbbaApplicationsArgs = {
  distinct_on?: Maybe<Array<EbbaApplicationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EbbaApplicationsOrderBy>>;
  where?: Maybe<EbbaApplicationsBoolExp>;
};

export type QueryRootEbbaApplicationsAggregateArgs = {
  distinct_on?: Maybe<Array<EbbaApplicationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EbbaApplicationsOrderBy>>;
  where?: Maybe<EbbaApplicationsBoolExp>;
};

export type QueryRootEbbaApplicationsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootFilesArgs = {
  distinct_on?: Maybe<Array<FilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FilesOrderBy>>;
  where?: Maybe<FilesBoolExp>;
};

export type QueryRootFilesAggregateArgs = {
  distinct_on?: Maybe<Array<FilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FilesOrderBy>>;
  where?: Maybe<FilesBoolExp>;
};

export type QueryRootFilesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootFinancialSummariesArgs = {
  distinct_on?: Maybe<Array<FinancialSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FinancialSummariesOrderBy>>;
  where?: Maybe<FinancialSummariesBoolExp>;
};

export type QueryRootFinancialSummariesAggregateArgs = {
  distinct_on?: Maybe<Array<FinancialSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FinancialSummariesOrderBy>>;
  where?: Maybe<FinancialSummariesBoolExp>;
};

export type QueryRootFinancialSummariesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootInvoiceFileTypeArgs = {
  distinct_on?: Maybe<Array<InvoiceFileTypeSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoiceFileTypeOrderBy>>;
  where?: Maybe<InvoiceFileTypeBoolExp>;
};

export type QueryRootInvoiceFileTypeAggregateArgs = {
  distinct_on?: Maybe<Array<InvoiceFileTypeSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoiceFileTypeOrderBy>>;
  where?: Maybe<InvoiceFileTypeBoolExp>;
};

export type QueryRootInvoiceFileTypeByPkArgs = {
  value: Scalars["String"];
};

export type QueryRootInvoiceFilesArgs = {
  distinct_on?: Maybe<Array<InvoiceFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoiceFilesOrderBy>>;
  where?: Maybe<InvoiceFilesBoolExp>;
};

export type QueryRootInvoiceFilesAggregateArgs = {
  distinct_on?: Maybe<Array<InvoiceFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoiceFilesOrderBy>>;
  where?: Maybe<InvoiceFilesBoolExp>;
};

export type QueryRootInvoiceFilesByPkArgs = {
  file_id: Scalars["uuid"];
  invoice_id: Scalars["uuid"];
};

export type QueryRootInvoicesArgs = {
  distinct_on?: Maybe<Array<InvoicesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoicesOrderBy>>;
  where?: Maybe<InvoicesBoolExp>;
};

export type QueryRootInvoicesAggregateArgs = {
  distinct_on?: Maybe<Array<InvoicesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoicesOrderBy>>;
  where?: Maybe<InvoicesBoolExp>;
};

export type QueryRootInvoicesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootLineOfCreditsArgs = {
  distinct_on?: Maybe<Array<LineOfCreditsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LineOfCreditsOrderBy>>;
  where?: Maybe<LineOfCreditsBoolExp>;
};

export type QueryRootLineOfCreditsAggregateArgs = {
  distinct_on?: Maybe<Array<LineOfCreditsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LineOfCreditsOrderBy>>;
  where?: Maybe<LineOfCreditsBoolExp>;
};

export type QueryRootLineOfCreditsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootLoanReportsArgs = {
  distinct_on?: Maybe<Array<LoanReportsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoanReportsOrderBy>>;
  where?: Maybe<LoanReportsBoolExp>;
};

export type QueryRootLoanReportsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanReportsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoanReportsOrderBy>>;
  where?: Maybe<LoanReportsBoolExp>;
};

export type QueryRootLoanReportsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootLoanTypeArgs = {
  distinct_on?: Maybe<Array<LoanTypeSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoanTypeOrderBy>>;
  where?: Maybe<LoanTypeBoolExp>;
};

export type QueryRootLoanTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanTypeSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoanTypeOrderBy>>;
  where?: Maybe<LoanTypeBoolExp>;
};

export type QueryRootLoanTypeByPkArgs = {
  value: Scalars["String"];
};

export type QueryRootLoansArgs = {
  distinct_on?: Maybe<Array<LoansSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoansOrderBy>>;
  where?: Maybe<LoansBoolExp>;
};

export type QueryRootLoansAggregateArgs = {
  distinct_on?: Maybe<Array<LoansSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoansOrderBy>>;
  where?: Maybe<LoansBoolExp>;
};

export type QueryRootLoansByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootMetrcAnalysisSummariesArgs = {
  distinct_on?: Maybe<Array<MetrcAnalysisSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcAnalysisSummariesOrderBy>>;
  where?: Maybe<MetrcAnalysisSummariesBoolExp>;
};

export type QueryRootMetrcAnalysisSummariesAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcAnalysisSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcAnalysisSummariesOrderBy>>;
  where?: Maybe<MetrcAnalysisSummariesBoolExp>;
};

export type QueryRootMetrcAnalysisSummariesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootMetrcApiKeysArgs = {
  distinct_on?: Maybe<Array<MetrcApiKeysSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcApiKeysOrderBy>>;
  where?: Maybe<MetrcApiKeysBoolExp>;
};

export type QueryRootMetrcApiKeysAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcApiKeysSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcApiKeysOrderBy>>;
  where?: Maybe<MetrcApiKeysBoolExp>;
};

export type QueryRootMetrcApiKeysByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootMetrcDeliveriesArgs = {
  distinct_on?: Maybe<Array<MetrcDeliveriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcDeliveriesOrderBy>>;
  where?: Maybe<MetrcDeliveriesBoolExp>;
};

export type QueryRootMetrcDeliveriesAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcDeliveriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcDeliveriesOrderBy>>;
  where?: Maybe<MetrcDeliveriesBoolExp>;
};

export type QueryRootMetrcDeliveriesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootMetrcDownloadSummariesArgs = {
  distinct_on?: Maybe<Array<MetrcDownloadSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcDownloadSummariesOrderBy>>;
  where?: Maybe<MetrcDownloadSummariesBoolExp>;
};

export type QueryRootMetrcDownloadSummariesAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcDownloadSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcDownloadSummariesOrderBy>>;
  where?: Maybe<MetrcDownloadSummariesBoolExp>;
};

export type QueryRootMetrcDownloadSummariesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootMetrcHarvestsArgs = {
  distinct_on?: Maybe<Array<MetrcHarvestsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcHarvestsOrderBy>>;
  where?: Maybe<MetrcHarvestsBoolExp>;
};

export type QueryRootMetrcHarvestsAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcHarvestsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcHarvestsOrderBy>>;
  where?: Maybe<MetrcHarvestsBoolExp>;
};

export type QueryRootMetrcHarvestsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootMetrcPackagesArgs = {
  distinct_on?: Maybe<Array<MetrcPackagesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcPackagesOrderBy>>;
  where?: Maybe<MetrcPackagesBoolExp>;
};

export type QueryRootMetrcPackagesAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcPackagesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcPackagesOrderBy>>;
  where?: Maybe<MetrcPackagesBoolExp>;
};

export type QueryRootMetrcPackagesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootMetrcPlantBatchesArgs = {
  distinct_on?: Maybe<Array<MetrcPlantBatchesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcPlantBatchesOrderBy>>;
  where?: Maybe<MetrcPlantBatchesBoolExp>;
};

export type QueryRootMetrcPlantBatchesAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcPlantBatchesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcPlantBatchesOrderBy>>;
  where?: Maybe<MetrcPlantBatchesBoolExp>;
};

export type QueryRootMetrcPlantBatchesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootMetrcPlantsArgs = {
  distinct_on?: Maybe<Array<MetrcPlantsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcPlantsOrderBy>>;
  where?: Maybe<MetrcPlantsBoolExp>;
};

export type QueryRootMetrcPlantsAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcPlantsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcPlantsOrderBy>>;
  where?: Maybe<MetrcPlantsBoolExp>;
};

export type QueryRootMetrcPlantsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootMetrcSalesReceiptsArgs = {
  distinct_on?: Maybe<Array<MetrcSalesReceiptsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcSalesReceiptsOrderBy>>;
  where?: Maybe<MetrcSalesReceiptsBoolExp>;
};

export type QueryRootMetrcSalesReceiptsAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcSalesReceiptsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcSalesReceiptsOrderBy>>;
  where?: Maybe<MetrcSalesReceiptsBoolExp>;
};

export type QueryRootMetrcSalesReceiptsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootMetrcSalesTransactionsArgs = {
  distinct_on?: Maybe<Array<MetrcSalesTransactionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcSalesTransactionsOrderBy>>;
  where?: Maybe<MetrcSalesTransactionsBoolExp>;
};

export type QueryRootMetrcSalesTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcSalesTransactionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcSalesTransactionsOrderBy>>;
  where?: Maybe<MetrcSalesTransactionsBoolExp>;
};

export type QueryRootMetrcSalesTransactionsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootMetrcToBespokeCatalogSkusArgs = {
  distinct_on?: Maybe<Array<MetrcToBespokeCatalogSkusSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcToBespokeCatalogSkusOrderBy>>;
  where?: Maybe<MetrcToBespokeCatalogSkusBoolExp>;
};

export type QueryRootMetrcToBespokeCatalogSkusAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcToBespokeCatalogSkusSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcToBespokeCatalogSkusOrderBy>>;
  where?: Maybe<MetrcToBespokeCatalogSkusBoolExp>;
};

export type QueryRootMetrcToBespokeCatalogSkusByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootMetrcTransferPackagesArgs = {
  distinct_on?: Maybe<Array<MetrcTransferPackagesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcTransferPackagesOrderBy>>;
  where?: Maybe<MetrcTransferPackagesBoolExp>;
};

export type QueryRootMetrcTransferPackagesAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcTransferPackagesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcTransferPackagesOrderBy>>;
  where?: Maybe<MetrcTransferPackagesBoolExp>;
};

export type QueryRootMetrcTransferPackagesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootMetrcTransfersArgs = {
  distinct_on?: Maybe<Array<MetrcTransfersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcTransfersOrderBy>>;
  where?: Maybe<MetrcTransfersBoolExp>;
};

export type QueryRootMetrcTransfersAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcTransfersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcTransfersOrderBy>>;
  where?: Maybe<MetrcTransfersBoolExp>;
};

export type QueryRootMetrcTransfersByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootMonthlySummaryCalculationsArgs = {
  distinct_on?: Maybe<Array<MonthlySummaryCalculationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MonthlySummaryCalculationsOrderBy>>;
  where?: Maybe<MonthlySummaryCalculationsBoolExp>;
};

export type QueryRootMonthlySummaryCalculationsAggregateArgs = {
  distinct_on?: Maybe<Array<MonthlySummaryCalculationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MonthlySummaryCalculationsOrderBy>>;
  where?: Maybe<MonthlySummaryCalculationsBoolExp>;
};

export type QueryRootMonthlySummaryCalculationsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootParentCompaniesArgs = {
  distinct_on?: Maybe<Array<ParentCompaniesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ParentCompaniesOrderBy>>;
  where?: Maybe<ParentCompaniesBoolExp>;
};

export type QueryRootParentCompaniesAggregateArgs = {
  distinct_on?: Maybe<Array<ParentCompaniesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ParentCompaniesOrderBy>>;
  where?: Maybe<ParentCompaniesBoolExp>;
};

export type QueryRootParentCompaniesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootPaymentsArgs = {
  distinct_on?: Maybe<Array<PaymentsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PaymentsOrderBy>>;
  where?: Maybe<PaymentsBoolExp>;
};

export type QueryRootPaymentsAggregateArgs = {
  distinct_on?: Maybe<Array<PaymentsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PaymentsOrderBy>>;
  where?: Maybe<PaymentsBoolExp>;
};

export type QueryRootPaymentsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootPayorsArgs = {
  distinct_on?: Maybe<Array<PayorsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PayorsOrderBy>>;
  where?: Maybe<PayorsBoolExp>;
};

export type QueryRootPayorsAggregateArgs = {
  distinct_on?: Maybe<Array<PayorsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PayorsOrderBy>>;
  where?: Maybe<PayorsBoolExp>;
};

export type QueryRootPurchaseOrderFileTypeArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderFileTypeSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderFileTypeOrderBy>>;
  where?: Maybe<PurchaseOrderFileTypeBoolExp>;
};

export type QueryRootPurchaseOrderFileTypeAggregateArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderFileTypeSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderFileTypeOrderBy>>;
  where?: Maybe<PurchaseOrderFileTypeBoolExp>;
};

export type QueryRootPurchaseOrderFileTypeByPkArgs = {
  value: Scalars["String"];
};

export type QueryRootPurchaseOrderFilesArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderFilesOrderBy>>;
  where?: Maybe<PurchaseOrderFilesBoolExp>;
};

export type QueryRootPurchaseOrderFilesAggregateArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderFilesOrderBy>>;
  where?: Maybe<PurchaseOrderFilesBoolExp>;
};

export type QueryRootPurchaseOrderFilesByPkArgs = {
  file_id: Scalars["uuid"];
  purchase_order_id: Scalars["uuid"];
};

export type QueryRootPurchaseOrderMetrcTransfersArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderMetrcTransfersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderMetrcTransfersOrderBy>>;
  where?: Maybe<PurchaseOrderMetrcTransfersBoolExp>;
};

export type QueryRootPurchaseOrderMetrcTransfersAggregateArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderMetrcTransfersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderMetrcTransfersOrderBy>>;
  where?: Maybe<PurchaseOrderMetrcTransfersBoolExp>;
};

export type QueryRootPurchaseOrderMetrcTransfersByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootPurchaseOrdersArgs = {
  distinct_on?: Maybe<Array<PurchaseOrdersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrdersOrderBy>>;
  where?: Maybe<PurchaseOrdersBoolExp>;
};

export type QueryRootPurchaseOrdersAggregateArgs = {
  distinct_on?: Maybe<Array<PurchaseOrdersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrdersOrderBy>>;
  where?: Maybe<PurchaseOrdersBoolExp>;
};

export type QueryRootPurchaseOrdersByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootRequestStatusArgs = {
  distinct_on?: Maybe<Array<RequestStatusSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<RequestStatusOrderBy>>;
  where?: Maybe<RequestStatusBoolExp>;
};

export type QueryRootRequestStatusAggregateArgs = {
  distinct_on?: Maybe<Array<RequestStatusSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<RequestStatusOrderBy>>;
  where?: Maybe<RequestStatusBoolExp>;
};

export type QueryRootRequestStatusByPkArgs = {
  value: Scalars["String"];
};

export type QueryRootRevokedTokensArgs = {
  distinct_on?: Maybe<Array<RevokedTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<RevokedTokensOrderBy>>;
  where?: Maybe<RevokedTokensBoolExp>;
};

export type QueryRootRevokedTokensAggregateArgs = {
  distinct_on?: Maybe<Array<RevokedTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<RevokedTokensOrderBy>>;
  where?: Maybe<RevokedTokensBoolExp>;
};

export type QueryRootRevokedTokensByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootSyncPipelinesArgs = {
  distinct_on?: Maybe<Array<SyncPipelinesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SyncPipelinesOrderBy>>;
  where?: Maybe<SyncPipelinesBoolExp>;
};

export type QueryRootSyncPipelinesAggregateArgs = {
  distinct_on?: Maybe<Array<SyncPipelinesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SyncPipelinesOrderBy>>;
  where?: Maybe<SyncPipelinesBoolExp>;
};

export type QueryRootSyncPipelinesByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootTransactionsArgs = {
  distinct_on?: Maybe<Array<TransactionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TransactionsOrderBy>>;
  where?: Maybe<TransactionsBoolExp>;
};

export type QueryRootTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<TransactionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TransactionsOrderBy>>;
  where?: Maybe<TransactionsBoolExp>;
};

export type QueryRootTransactionsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootTwoFactorLinksArgs = {
  distinct_on?: Maybe<Array<TwoFactorLinksSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TwoFactorLinksOrderBy>>;
  where?: Maybe<TwoFactorLinksBoolExp>;
};

export type QueryRootTwoFactorLinksAggregateArgs = {
  distinct_on?: Maybe<Array<TwoFactorLinksSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TwoFactorLinksOrderBy>>;
  where?: Maybe<TwoFactorLinksBoolExp>;
};

export type QueryRootTwoFactorLinksByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootUserRolesArgs = {
  distinct_on?: Maybe<Array<UserRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserRolesOrderBy>>;
  where?: Maybe<UserRolesBoolExp>;
};

export type QueryRootUserRolesAggregateArgs = {
  distinct_on?: Maybe<Array<UserRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserRolesOrderBy>>;
  where?: Maybe<UserRolesBoolExp>;
};

export type QueryRootUserRolesByPkArgs = {
  value: Scalars["String"];
};

export type QueryRootUsersArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

export type QueryRootUsersAggregateArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

export type QueryRootUsersByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootVendorChangeRequestsArgs = {
  distinct_on?: Maybe<Array<VendorChangeRequestsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<VendorChangeRequestsOrderBy>>;
  where?: Maybe<VendorChangeRequestsBoolExp>;
};

export type QueryRootVendorChangeRequestsAggregateArgs = {
  distinct_on?: Maybe<Array<VendorChangeRequestsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<VendorChangeRequestsOrderBy>>;
  where?: Maybe<VendorChangeRequestsBoolExp>;
};

export type QueryRootVendorChangeRequestsByPkArgs = {
  id: Scalars["uuid"];
};

export type QueryRootVendorsArgs = {
  distinct_on?: Maybe<Array<VendorsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<VendorsOrderBy>>;
  where?: Maybe<VendorsBoolExp>;
};

export type QueryRootVendorsAggregateArgs = {
  distinct_on?: Maybe<Array<VendorsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<VendorsOrderBy>>;
  where?: Maybe<VendorsBoolExp>;
};

/** columns and relationships of "request_status" */
export type RequestStatus = {
  display_name: Scalars["String"];
  value: Scalars["String"];
};

/** aggregated selection of "request_status" */
export type RequestStatusAggregate = {
  aggregate?: Maybe<RequestStatusAggregateFields>;
  nodes: Array<RequestStatus>;
};

/** aggregate fields of "request_status" */
export type RequestStatusAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<RequestStatusMaxFields>;
  min?: Maybe<RequestStatusMinFields>;
};

/** aggregate fields of "request_status" */
export type RequestStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<RequestStatusSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "request_status". All fields are combined with a logical 'AND'. */
export type RequestStatusBoolExp = {
  _and?: Maybe<Array<RequestStatusBoolExp>>;
  _not?: Maybe<RequestStatusBoolExp>;
  _or?: Maybe<Array<RequestStatusBoolExp>>;
  display_name?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "request_status" */
export enum RequestStatusConstraint {
  /** unique or primary key constraint */
  VendorRequestStatusPkey = "vendor_request_status_pkey",
}

export enum RequestStatusEnum {
  /** Pending */
  ApprovalRequested = "approval_requested",
  /** Approved */
  Approved = "approved",
  /** Draft */
  Drafted = "drafted",
  /** Incomplete */
  Incomplete = "incomplete",
  /** Rejected */
  Rejected = "rejected",
}

/** Boolean expression to compare columns of type "request_status_enum". All fields are combined with logical 'AND'. */
export type RequestStatusEnumComparisonExp = {
  _eq?: Maybe<RequestStatusEnum>;
  _in?: Maybe<Array<RequestStatusEnum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<RequestStatusEnum>;
  _nin?: Maybe<Array<RequestStatusEnum>>;
};

/** input type for inserting data into table "request_status" */
export type RequestStatusInsertInput = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type RequestStatusMaxFields = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type RequestStatusMinFields = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "request_status" */
export type RequestStatusMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<RequestStatus>;
};

/** on_conflict condition type for table "request_status" */
export type RequestStatusOnConflict = {
  constraint: RequestStatusConstraint;
  update_columns?: Array<RequestStatusUpdateColumn>;
  where?: Maybe<RequestStatusBoolExp>;
};

/** Ordering options when selecting data from "request_status". */
export type RequestStatusOrderBy = {
  display_name?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: request_status */
export type RequestStatusPkColumnsInput = {
  value: Scalars["String"];
};

/** select columns of table "request_status" */
export enum RequestStatusSelectColumn {
  /** column name */
  DisplayName = "display_name",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "request_status" */
export type RequestStatusSetInput = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** update columns of table "request_status" */
export enum RequestStatusUpdateColumn {
  /** column name */
  DisplayName = "display_name",
  /** column name */
  Value = "value",
}

/** columns and relationships of "revoked_tokens" */
export type RevokedTokens = {
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  jti: Scalars["String"];
  user_id: Scalars["uuid"];
};

/** aggregated selection of "revoked_tokens" */
export type RevokedTokensAggregate = {
  aggregate?: Maybe<RevokedTokensAggregateFields>;
  nodes: Array<RevokedTokens>;
};

/** aggregate fields of "revoked_tokens" */
export type RevokedTokensAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<RevokedTokensMaxFields>;
  min?: Maybe<RevokedTokensMinFields>;
};

/** aggregate fields of "revoked_tokens" */
export type RevokedTokensAggregateFieldsCountArgs = {
  columns?: Maybe<Array<RevokedTokensSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "revoked_tokens". All fields are combined with a logical 'AND'. */
export type RevokedTokensBoolExp = {
  _and?: Maybe<Array<RevokedTokensBoolExp>>;
  _not?: Maybe<RevokedTokensBoolExp>;
  _or?: Maybe<Array<RevokedTokensBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  jti?: Maybe<StringComparisonExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "revoked_tokens" */
export enum RevokedTokensConstraint {
  /** unique or primary key constraint */
  RevokedTokensPkey = "revoked_tokens_pkey",
}

/** input type for inserting data into table "revoked_tokens" */
export type RevokedTokensInsertInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  jti?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type RevokedTokensMaxFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  jti?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type RevokedTokensMinFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  jti?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "revoked_tokens" */
export type RevokedTokensMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<RevokedTokens>;
};

/** on_conflict condition type for table "revoked_tokens" */
export type RevokedTokensOnConflict = {
  constraint: RevokedTokensConstraint;
  update_columns?: Array<RevokedTokensUpdateColumn>;
  where?: Maybe<RevokedTokensBoolExp>;
};

/** Ordering options when selecting data from "revoked_tokens". */
export type RevokedTokensOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  jti?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: revoked_tokens */
export type RevokedTokensPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "revoked_tokens" */
export enum RevokedTokensSelectColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Jti = "jti",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "revoked_tokens" */
export type RevokedTokensSetInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  jti?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "revoked_tokens" */
export enum RevokedTokensUpdateColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Jti = "jti",
  /** column name */
  UserId = "user_id",
}

export type SubscriptionRoot = {
  /** fetch data from the table: "async_job_summaries" */
  async_job_summaries: Array<AsyncJobSummaries>;
  /** fetch aggregated fields from the table: "async_job_summaries" */
  async_job_summaries_aggregate: AsyncJobSummariesAggregate;
  /** fetch data from the table: "async_job_summaries" using primary key columns */
  async_job_summaries_by_pk?: Maybe<AsyncJobSummaries>;
  /** fetch data from the table: "async_jobs" */
  async_jobs: Array<AsyncJobs>;
  /** fetch aggregated fields from the table: "async_jobs" */
  async_jobs_aggregate: AsyncJobsAggregate;
  /** fetch data from the table: "async_jobs" using primary key columns */
  async_jobs_by_pk?: Maybe<AsyncJobs>;
  /** fetch data from the table: "async_pipelines" */
  async_pipelines: Array<AsyncPipelines>;
  /** fetch aggregated fields from the table: "async_pipelines" */
  async_pipelines_aggregate: AsyncPipelinesAggregate;
  /** fetch data from the table: "async_pipelines" using primary key columns */
  async_pipelines_by_pk?: Maybe<AsyncPipelines>;
  /** fetch data from the table: "audit_events" */
  audit_events: Array<AuditEvents>;
  /** fetch aggregated fields from the table: "audit_events" */
  audit_events_aggregate: AuditEventsAggregate;
  /** fetch data from the table: "audit_events" using primary key columns */
  audit_events_by_pk?: Maybe<AuditEvents>;
  /** An array relationship */
  bank_accounts: Array<BankAccounts>;
  /** An aggregate relationship */
  bank_accounts_aggregate: BankAccountsAggregate;
  /** fetch data from the table: "bank_accounts" using primary key columns */
  bank_accounts_by_pk?: Maybe<BankAccounts>;
  /** fetch data from the table: "bank_financial_summaries" */
  bank_financial_summaries: Array<BankFinancialSummaries>;
  /** fetch aggregated fields from the table: "bank_financial_summaries" */
  bank_financial_summaries_aggregate: BankFinancialSummariesAggregate;
  /** fetch data from the table: "bank_financial_summaries" using primary key columns */
  bank_financial_summaries_by_pk?: Maybe<BankFinancialSummaries>;
  /** fetch data from the table: "bespoke_catalog_brands" */
  bespoke_catalog_brands: Array<BespokeCatalogBrands>;
  /** fetch aggregated fields from the table: "bespoke_catalog_brands" */
  bespoke_catalog_brands_aggregate: BespokeCatalogBrandsAggregate;
  /** fetch data from the table: "bespoke_catalog_brands" using primary key columns */
  bespoke_catalog_brands_by_pk?: Maybe<BespokeCatalogBrands>;
  /** An array relationship */
  bespoke_catalog_sku_groups: Array<BespokeCatalogSkuGroups>;
  /** An aggregate relationship */
  bespoke_catalog_sku_groups_aggregate: BespokeCatalogSkuGroupsAggregate;
  /** fetch data from the table: "bespoke_catalog_sku_groups" using primary key columns */
  bespoke_catalog_sku_groups_by_pk?: Maybe<BespokeCatalogSkuGroups>;
  /** An array relationship */
  bespoke_catalog_skus: Array<BespokeCatalogSkus>;
  /** An aggregate relationship */
  bespoke_catalog_skus_aggregate: BespokeCatalogSkusAggregate;
  /** fetch data from the table: "bespoke_catalog_skus" using primary key columns */
  bespoke_catalog_skus_by_pk?: Maybe<BespokeCatalogSkus>;
  /** fetch data from the table: "blaze_preapprovals" */
  blaze_preapprovals: Array<BlazePreapprovals>;
  /** fetch aggregated fields from the table: "blaze_preapprovals" */
  blaze_preapprovals_aggregate: BlazePreapprovalsAggregate;
  /** fetch data from the table: "blaze_preapprovals" using primary key columns */
  blaze_preapprovals_by_pk?: Maybe<BlazePreapprovals>;
  /** fetch data from the table: "blaze_shop_entries" */
  blaze_shop_entries: Array<BlazeShopEntries>;
  /** fetch aggregated fields from the table: "blaze_shop_entries" */
  blaze_shop_entries_aggregate: BlazeShopEntriesAggregate;
  /** fetch data from the table: "blaze_shop_entries" using primary key columns */
  blaze_shop_entries_by_pk?: Maybe<BlazeShopEntries>;
  /** fetch data from the table: "blaze_users" */
  blaze_users: Array<BlazeUsers>;
  /** fetch aggregated fields from the table: "blaze_users" */
  blaze_users_aggregate: BlazeUsersAggregate;
  /** fetch data from the table: "blaze_users" using primary key columns */
  blaze_users_by_pk?: Maybe<BlazeUsers>;
  /** An array relationship */
  companies: Array<Companies>;
  /** An aggregate relationship */
  companies_aggregate: CompaniesAggregate;
  /** fetch data from the table: "companies" using primary key columns */
  companies_by_pk?: Maybe<Companies>;
  /** fetch data from the table: "company_agreements" */
  company_agreements: Array<CompanyAgreements>;
  /** fetch aggregated fields from the table: "company_agreements" */
  company_agreements_aggregate: CompanyAgreementsAggregate;
  /** fetch data from the table: "company_agreements" using primary key columns */
  company_agreements_by_pk?: Maybe<CompanyAgreements>;
  /** An array relationship */
  company_deliveries: Array<CompanyDeliveries>;
  /** An aggregate relationship */
  company_deliveries_aggregate: CompanyDeliveriesAggregate;
  /** fetch data from the table: "company_deliveries" using primary key columns */
  company_deliveries_by_pk?: Maybe<CompanyDeliveries>;
  /** An array relationship */
  company_facilities: Array<CompanyFacilities>;
  /** An aggregate relationship */
  company_facilities_aggregate: CompanyFacilitiesAggregate;
  /** fetch data from the table: "company_facilities" using primary key columns */
  company_facilities_by_pk?: Maybe<CompanyFacilities>;
  /** An array relationship */
  company_licenses: Array<CompanyLicenses>;
  /** An aggregate relationship */
  company_licenses_aggregate: CompanyLicensesAggregate;
  /** fetch data from the table: "company_licenses" using primary key columns */
  company_licenses_by_pk?: Maybe<CompanyLicenses>;
  /** fetch data from the table: "company_partnership_invitations" */
  company_partnership_invitations: Array<CompanyPartnershipInvitations>;
  /** fetch aggregated fields from the table: "company_partnership_invitations" */
  company_partnership_invitations_aggregate: CompanyPartnershipInvitationsAggregate;
  /** fetch data from the table: "company_partnership_invitations" using primary key columns */
  company_partnership_invitations_by_pk?: Maybe<CompanyPartnershipInvitations>;
  /** fetch data from the table: "company_partnership_requests" */
  company_partnership_requests: Array<CompanyPartnershipRequests>;
  /** fetch aggregated fields from the table: "company_partnership_requests" */
  company_partnership_requests_aggregate: CompanyPartnershipRequestsAggregate;
  /** fetch data from the table: "company_partnership_requests" using primary key columns */
  company_partnership_requests_by_pk?: Maybe<CompanyPartnershipRequests>;
  /** fetch data from the table: "company_payor_contacts" */
  company_payor_contacts: Array<CompanyPayorContacts>;
  /** fetch aggregated fields from the table: "company_payor_contacts" */
  company_payor_contacts_aggregate: CompanyPayorContactsAggregate;
  /** fetch data from the table: "company_payor_contacts" using primary key columns */
  company_payor_contacts_by_pk?: Maybe<CompanyPayorContacts>;
  /** An array relationship */
  company_payor_partnerships: Array<CompanyPayorPartnerships>;
  /** An aggregate relationship */
  company_payor_partnerships_aggregate: CompanyPayorPartnershipsAggregate;
  /** fetch data from the table: "company_payor_partnerships" using primary key columns */
  company_payor_partnerships_by_pk?: Maybe<CompanyPayorPartnerships>;
  /** fetch data from the table: "company_settings" */
  company_settings: Array<CompanySettings>;
  /** fetch aggregated fields from the table: "company_settings" */
  company_settings_aggregate: CompanySettingsAggregate;
  /** fetch data from the table: "company_settings" using primary key columns */
  company_settings_by_pk?: Maybe<CompanySettings>;
  /** fetch data from the table: "company_type" */
  company_type: Array<CompanyType>;
  /** fetch aggregated fields from the table: "company_type" */
  company_type_aggregate: CompanyTypeAggregate;
  /** fetch data from the table: "company_type" using primary key columns */
  company_type_by_pk?: Maybe<CompanyType>;
  /** fetch data from the table: "company_vendor_contacts" */
  company_vendor_contacts: Array<CompanyVendorContacts>;
  /** fetch aggregated fields from the table: "company_vendor_contacts" */
  company_vendor_contacts_aggregate: CompanyVendorContactsAggregate;
  /** fetch data from the table: "company_vendor_contacts" using primary key columns */
  company_vendor_contacts_by_pk?: Maybe<CompanyVendorContacts>;
  /** An array relationship */
  company_vendor_partnerships: Array<CompanyVendorPartnerships>;
  /** An aggregate relationship */
  company_vendor_partnerships_aggregate: CompanyVendorPartnershipsAggregate;
  /** fetch data from the table: "company_vendor_partnerships" using primary key columns */
  company_vendor_partnerships_by_pk?: Maybe<CompanyVendorPartnerships>;
  /** An array relationship */
  contracts: Array<Contracts>;
  /** An aggregate relationship */
  contracts_aggregate: ContractsAggregate;
  /** fetch data from the table: "contracts" using primary key columns */
  contracts_by_pk?: Maybe<Contracts>;
  /** An array relationship */
  customer_surveillance_results: Array<CustomerSurveillanceResults>;
  /** An aggregate relationship */
  customer_surveillance_results_aggregate: CustomerSurveillanceResultsAggregate;
  /** fetch data from the table: "customer_surveillance_results" using primary key columns */
  customer_surveillance_results_by_pk?: Maybe<CustomerSurveillanceResults>;
  /** fetch data from the table: "debt_facilities" */
  debt_facilities: Array<DebtFacilities>;
  /** fetch aggregated fields from the table: "debt_facilities" */
  debt_facilities_aggregate: DebtFacilitiesAggregate;
  /** fetch data from the table: "debt_facilities" using primary key columns */
  debt_facilities_by_pk?: Maybe<DebtFacilities>;
  /** An array relationship */
  debt_facility_capacities: Array<DebtFacilityCapacities>;
  /** An aggregate relationship */
  debt_facility_capacities_aggregate: DebtFacilityCapacitiesAggregate;
  /** fetch data from the table: "debt_facility_capacities" using primary key columns */
  debt_facility_capacities_by_pk?: Maybe<DebtFacilityCapacities>;
  /** fetch data from the table: "debt_facility_events" */
  debt_facility_events: Array<DebtFacilityEvents>;
  /** fetch aggregated fields from the table: "debt_facility_events" */
  debt_facility_events_aggregate: DebtFacilityEventsAggregate;
  /** fetch data from the table: "debt_facility_events" using primary key columns */
  debt_facility_events_by_pk?: Maybe<DebtFacilityEvents>;
  /** An array relationship */
  ebba_application_files: Array<EbbaApplicationFiles>;
  /** An aggregate relationship */
  ebba_application_files_aggregate: EbbaApplicationFilesAggregate;
  /** fetch data from the table: "ebba_application_files" using primary key columns */
  ebba_application_files_by_pk?: Maybe<EbbaApplicationFiles>;
  /** An array relationship */
  ebba_applications: Array<EbbaApplications>;
  /** An aggregate relationship */
  ebba_applications_aggregate: EbbaApplicationsAggregate;
  /** fetch data from the table: "ebba_applications" using primary key columns */
  ebba_applications_by_pk?: Maybe<EbbaApplications>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: FilesAggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** An array relationship */
  financial_summaries: Array<FinancialSummaries>;
  /** An aggregate relationship */
  financial_summaries_aggregate: FinancialSummariesAggregate;
  /** fetch data from the table: "financial_summaries" using primary key columns */
  financial_summaries_by_pk?: Maybe<FinancialSummaries>;
  /** fetch data from the table: "invoice_file_type" */
  invoice_file_type: Array<InvoiceFileType>;
  /** fetch aggregated fields from the table: "invoice_file_type" */
  invoice_file_type_aggregate: InvoiceFileTypeAggregate;
  /** fetch data from the table: "invoice_file_type" using primary key columns */
  invoice_file_type_by_pk?: Maybe<InvoiceFileType>;
  /** An array relationship */
  invoice_files: Array<InvoiceFiles>;
  /** An aggregate relationship */
  invoice_files_aggregate: InvoiceFilesAggregate;
  /** fetch data from the table: "invoice_files" using primary key columns */
  invoice_files_by_pk?: Maybe<InvoiceFiles>;
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: InvoicesAggregate;
  /** fetch data from the table: "invoices" using primary key columns */
  invoices_by_pk?: Maybe<Invoices>;
  /** fetch data from the table: "line_of_credits" */
  line_of_credits: Array<LineOfCredits>;
  /** fetch aggregated fields from the table: "line_of_credits" */
  line_of_credits_aggregate: LineOfCreditsAggregate;
  /** fetch data from the table: "line_of_credits" using primary key columns */
  line_of_credits_by_pk?: Maybe<LineOfCredits>;
  /** fetch data from the table: "loan_reports" */
  loan_reports: Array<LoanReports>;
  /** fetch aggregated fields from the table: "loan_reports" */
  loan_reports_aggregate: LoanReportsAggregate;
  /** fetch data from the table: "loan_reports" using primary key columns */
  loan_reports_by_pk?: Maybe<LoanReports>;
  /** fetch data from the table: "loan_type" */
  loan_type: Array<LoanType>;
  /** fetch aggregated fields from the table: "loan_type" */
  loan_type_aggregate: LoanTypeAggregate;
  /** fetch data from the table: "loan_type" using primary key columns */
  loan_type_by_pk?: Maybe<LoanType>;
  /** An array relationship */
  loans: Array<Loans>;
  /** An aggregate relationship */
  loans_aggregate: LoansAggregate;
  /** fetch data from the table: "loans" using primary key columns */
  loans_by_pk?: Maybe<Loans>;
  /** fetch data from the table: "metrc_analysis_summaries" */
  metrc_analysis_summaries: Array<MetrcAnalysisSummaries>;
  /** fetch aggregated fields from the table: "metrc_analysis_summaries" */
  metrc_analysis_summaries_aggregate: MetrcAnalysisSummariesAggregate;
  /** fetch data from the table: "metrc_analysis_summaries" using primary key columns */
  metrc_analysis_summaries_by_pk?: Maybe<MetrcAnalysisSummaries>;
  /** An array relationship */
  metrc_api_keys: Array<MetrcApiKeys>;
  /** An aggregate relationship */
  metrc_api_keys_aggregate: MetrcApiKeysAggregate;
  /** fetch data from the table: "metrc_api_keys" using primary key columns */
  metrc_api_keys_by_pk?: Maybe<MetrcApiKeys>;
  /** An array relationship */
  metrc_deliveries: Array<MetrcDeliveries>;
  /** An aggregate relationship */
  metrc_deliveries_aggregate: MetrcDeliveriesAggregate;
  /** fetch data from the table: "metrc_deliveries" using primary key columns */
  metrc_deliveries_by_pk?: Maybe<MetrcDeliveries>;
  /** An array relationship */
  metrc_download_summaries: Array<MetrcDownloadSummaries>;
  /** An aggregate relationship */
  metrc_download_summaries_aggregate: MetrcDownloadSummariesAggregate;
  /** fetch data from the table: "metrc_download_summaries" using primary key columns */
  metrc_download_summaries_by_pk?: Maybe<MetrcDownloadSummaries>;
  /** fetch data from the table: "metrc_harvests" */
  metrc_harvests: Array<MetrcHarvests>;
  /** fetch aggregated fields from the table: "metrc_harvests" */
  metrc_harvests_aggregate: MetrcHarvestsAggregate;
  /** fetch data from the table: "metrc_harvests" using primary key columns */
  metrc_harvests_by_pk?: Maybe<MetrcHarvests>;
  /** fetch data from the table: "metrc_packages" */
  metrc_packages: Array<MetrcPackages>;
  /** fetch aggregated fields from the table: "metrc_packages" */
  metrc_packages_aggregate: MetrcPackagesAggregate;
  /** fetch data from the table: "metrc_packages" using primary key columns */
  metrc_packages_by_pk?: Maybe<MetrcPackages>;
  /** fetch data from the table: "metrc_plant_batches" */
  metrc_plant_batches: Array<MetrcPlantBatches>;
  /** fetch aggregated fields from the table: "metrc_plant_batches" */
  metrc_plant_batches_aggregate: MetrcPlantBatchesAggregate;
  /** fetch data from the table: "metrc_plant_batches" using primary key columns */
  metrc_plant_batches_by_pk?: Maybe<MetrcPlantBatches>;
  /** fetch data from the table: "metrc_plants" */
  metrc_plants: Array<MetrcPlants>;
  /** fetch aggregated fields from the table: "metrc_plants" */
  metrc_plants_aggregate: MetrcPlantsAggregate;
  /** fetch data from the table: "metrc_plants" using primary key columns */
  metrc_plants_by_pk?: Maybe<MetrcPlants>;
  /** fetch data from the table: "metrc_sales_receipts" */
  metrc_sales_receipts: Array<MetrcSalesReceipts>;
  /** fetch aggregated fields from the table: "metrc_sales_receipts" */
  metrc_sales_receipts_aggregate: MetrcSalesReceiptsAggregate;
  /** fetch data from the table: "metrc_sales_receipts" using primary key columns */
  metrc_sales_receipts_by_pk?: Maybe<MetrcSalesReceipts>;
  /** fetch data from the table: "metrc_sales_transactions" */
  metrc_sales_transactions: Array<MetrcSalesTransactions>;
  /** An aggregate relationship */
  metrc_sales_transactions_aggregate: MetrcSalesTransactionsAggregate;
  /** fetch data from the table: "metrc_sales_transactions" using primary key columns */
  metrc_sales_transactions_by_pk?: Maybe<MetrcSalesTransactions>;
  /** An array relationship */
  metrc_to_bespoke_catalog_skus: Array<MetrcToBespokeCatalogSkus>;
  /** An aggregate relationship */
  metrc_to_bespoke_catalog_skus_aggregate: MetrcToBespokeCatalogSkusAggregate;
  /** fetch data from the table: "metrc_to_bespoke_catalog_skus" using primary key columns */
  metrc_to_bespoke_catalog_skus_by_pk?: Maybe<MetrcToBespokeCatalogSkus>;
  /** An array relationship */
  metrc_transfer_packages: Array<MetrcTransferPackages>;
  /** An aggregate relationship */
  metrc_transfer_packages_aggregate: MetrcTransferPackagesAggregate;
  /** fetch data from the table: "metrc_transfer_packages" using primary key columns */
  metrc_transfer_packages_by_pk?: Maybe<MetrcTransferPackages>;
  /** fetch data from the table: "metrc_transfers" */
  metrc_transfers: Array<MetrcTransfers>;
  /** fetch aggregated fields from the table: "metrc_transfers" */
  metrc_transfers_aggregate: MetrcTransfersAggregate;
  /** fetch data from the table: "metrc_transfers" using primary key columns */
  metrc_transfers_by_pk?: Maybe<MetrcTransfers>;
  /** An array relationship */
  monthly_summary_calculations: Array<MonthlySummaryCalculations>;
  /** An aggregate relationship */
  monthly_summary_calculations_aggregate: MonthlySummaryCalculationsAggregate;
  /** fetch data from the table: "monthly_summary_calculations" using primary key columns */
  monthly_summary_calculations_by_pk?: Maybe<MonthlySummaryCalculations>;
  /** fetch data from the table: "parent_companies" */
  parent_companies: Array<ParentCompanies>;
  /** fetch aggregated fields from the table: "parent_companies" */
  parent_companies_aggregate: ParentCompaniesAggregate;
  /** fetch data from the table: "parent_companies" using primary key columns */
  parent_companies_by_pk?: Maybe<ParentCompanies>;
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregate relationship */
  payments_aggregate: PaymentsAggregate;
  /** fetch data from the table: "payments" using primary key columns */
  payments_by_pk?: Maybe<Payments>;
  /** fetch data from the table: "payors" */
  payors: Array<Payors>;
  /** fetch aggregated fields from the table: "payors" */
  payors_aggregate: PayorsAggregate;
  /** fetch data from the table: "purchase_order_file_type" */
  purchase_order_file_type: Array<PurchaseOrderFileType>;
  /** fetch aggregated fields from the table: "purchase_order_file_type" */
  purchase_order_file_type_aggregate: PurchaseOrderFileTypeAggregate;
  /** fetch data from the table: "purchase_order_file_type" using primary key columns */
  purchase_order_file_type_by_pk?: Maybe<PurchaseOrderFileType>;
  /** An array relationship */
  purchase_order_files: Array<PurchaseOrderFiles>;
  /** An aggregate relationship */
  purchase_order_files_aggregate: PurchaseOrderFilesAggregate;
  /** fetch data from the table: "purchase_order_files" using primary key columns */
  purchase_order_files_by_pk?: Maybe<PurchaseOrderFiles>;
  /** An array relationship */
  purchase_order_metrc_transfers: Array<PurchaseOrderMetrcTransfers>;
  /** An aggregate relationship */
  purchase_order_metrc_transfers_aggregate: PurchaseOrderMetrcTransfersAggregate;
  /** fetch data from the table: "purchase_order_metrc_transfers" using primary key columns */
  purchase_order_metrc_transfers_by_pk?: Maybe<PurchaseOrderMetrcTransfers>;
  /** An array relationship */
  purchase_orders: Array<PurchaseOrders>;
  /** An aggregate relationship */
  purchase_orders_aggregate: PurchaseOrdersAggregate;
  /** fetch data from the table: "purchase_orders" using primary key columns */
  purchase_orders_by_pk?: Maybe<PurchaseOrders>;
  /** fetch data from the table: "request_status" */
  request_status: Array<RequestStatus>;
  /** fetch aggregated fields from the table: "request_status" */
  request_status_aggregate: RequestStatusAggregate;
  /** fetch data from the table: "request_status" using primary key columns */
  request_status_by_pk?: Maybe<RequestStatus>;
  /** fetch data from the table: "revoked_tokens" */
  revoked_tokens: Array<RevokedTokens>;
  /** fetch aggregated fields from the table: "revoked_tokens" */
  revoked_tokens_aggregate: RevokedTokensAggregate;
  /** fetch data from the table: "revoked_tokens" using primary key columns */
  revoked_tokens_by_pk?: Maybe<RevokedTokens>;
  /** fetch data from the table: "sync_pipelines" */
  sync_pipelines: Array<SyncPipelines>;
  /** fetch aggregated fields from the table: "sync_pipelines" */
  sync_pipelines_aggregate: SyncPipelinesAggregate;
  /** fetch data from the table: "sync_pipelines" using primary key columns */
  sync_pipelines_by_pk?: Maybe<SyncPipelines>;
  /** An array relationship */
  transactions: Array<Transactions>;
  /** An aggregate relationship */
  transactions_aggregate: TransactionsAggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  /** fetch data from the table: "two_factor_links" */
  two_factor_links: Array<TwoFactorLinks>;
  /** fetch aggregated fields from the table: "two_factor_links" */
  two_factor_links_aggregate: TwoFactorLinksAggregate;
  /** fetch data from the table: "two_factor_links" using primary key columns */
  two_factor_links_by_pk?: Maybe<TwoFactorLinks>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<UserRoles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: UserRolesAggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<UserRoles>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "vendor_change_requests" */
  vendor_change_requests: Array<VendorChangeRequests>;
  /** fetch aggregated fields from the table: "vendor_change_requests" */
  vendor_change_requests_aggregate: VendorChangeRequestsAggregate;
  /** fetch data from the table: "vendor_change_requests" using primary key columns */
  vendor_change_requests_by_pk?: Maybe<VendorChangeRequests>;
  /** fetch data from the table: "vendors" */
  vendors: Array<Vendors>;
  /** fetch aggregated fields from the table: "vendors" */
  vendors_aggregate: VendorsAggregate;
};

export type SubscriptionRootAsyncJobSummariesArgs = {
  distinct_on?: Maybe<Array<AsyncJobSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AsyncJobSummariesOrderBy>>;
  where?: Maybe<AsyncJobSummariesBoolExp>;
};

export type SubscriptionRootAsyncJobSummariesAggregateArgs = {
  distinct_on?: Maybe<Array<AsyncJobSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AsyncJobSummariesOrderBy>>;
  where?: Maybe<AsyncJobSummariesBoolExp>;
};

export type SubscriptionRootAsyncJobSummariesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootAsyncJobsArgs = {
  distinct_on?: Maybe<Array<AsyncJobsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AsyncJobsOrderBy>>;
  where?: Maybe<AsyncJobsBoolExp>;
};

export type SubscriptionRootAsyncJobsAggregateArgs = {
  distinct_on?: Maybe<Array<AsyncJobsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AsyncJobsOrderBy>>;
  where?: Maybe<AsyncJobsBoolExp>;
};

export type SubscriptionRootAsyncJobsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootAsyncPipelinesArgs = {
  distinct_on?: Maybe<Array<AsyncPipelinesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AsyncPipelinesOrderBy>>;
  where?: Maybe<AsyncPipelinesBoolExp>;
};

export type SubscriptionRootAsyncPipelinesAggregateArgs = {
  distinct_on?: Maybe<Array<AsyncPipelinesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AsyncPipelinesOrderBy>>;
  where?: Maybe<AsyncPipelinesBoolExp>;
};

export type SubscriptionRootAsyncPipelinesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootAuditEventsArgs = {
  distinct_on?: Maybe<Array<AuditEventsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AuditEventsOrderBy>>;
  where?: Maybe<AuditEventsBoolExp>;
};

export type SubscriptionRootAuditEventsAggregateArgs = {
  distinct_on?: Maybe<Array<AuditEventsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AuditEventsOrderBy>>;
  where?: Maybe<AuditEventsBoolExp>;
};

export type SubscriptionRootAuditEventsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootBankAccountsArgs = {
  distinct_on?: Maybe<Array<BankAccountsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BankAccountsOrderBy>>;
  where?: Maybe<BankAccountsBoolExp>;
};

export type SubscriptionRootBankAccountsAggregateArgs = {
  distinct_on?: Maybe<Array<BankAccountsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BankAccountsOrderBy>>;
  where?: Maybe<BankAccountsBoolExp>;
};

export type SubscriptionRootBankAccountsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootBankFinancialSummariesArgs = {
  distinct_on?: Maybe<Array<BankFinancialSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BankFinancialSummariesOrderBy>>;
  where?: Maybe<BankFinancialSummariesBoolExp>;
};

export type SubscriptionRootBankFinancialSummariesAggregateArgs = {
  distinct_on?: Maybe<Array<BankFinancialSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BankFinancialSummariesOrderBy>>;
  where?: Maybe<BankFinancialSummariesBoolExp>;
};

export type SubscriptionRootBankFinancialSummariesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootBespokeCatalogBrandsArgs = {
  distinct_on?: Maybe<Array<BespokeCatalogBrandsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BespokeCatalogBrandsOrderBy>>;
  where?: Maybe<BespokeCatalogBrandsBoolExp>;
};

export type SubscriptionRootBespokeCatalogBrandsAggregateArgs = {
  distinct_on?: Maybe<Array<BespokeCatalogBrandsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BespokeCatalogBrandsOrderBy>>;
  where?: Maybe<BespokeCatalogBrandsBoolExp>;
};

export type SubscriptionRootBespokeCatalogBrandsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootBespokeCatalogSkuGroupsArgs = {
  distinct_on?: Maybe<Array<BespokeCatalogSkuGroupsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BespokeCatalogSkuGroupsOrderBy>>;
  where?: Maybe<BespokeCatalogSkuGroupsBoolExp>;
};

export type SubscriptionRootBespokeCatalogSkuGroupsAggregateArgs = {
  distinct_on?: Maybe<Array<BespokeCatalogSkuGroupsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BespokeCatalogSkuGroupsOrderBy>>;
  where?: Maybe<BespokeCatalogSkuGroupsBoolExp>;
};

export type SubscriptionRootBespokeCatalogSkuGroupsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootBespokeCatalogSkusArgs = {
  distinct_on?: Maybe<Array<BespokeCatalogSkusSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BespokeCatalogSkusOrderBy>>;
  where?: Maybe<BespokeCatalogSkusBoolExp>;
};

export type SubscriptionRootBespokeCatalogSkusAggregateArgs = {
  distinct_on?: Maybe<Array<BespokeCatalogSkusSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BespokeCatalogSkusOrderBy>>;
  where?: Maybe<BespokeCatalogSkusBoolExp>;
};

export type SubscriptionRootBespokeCatalogSkusByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootBlazePreapprovalsArgs = {
  distinct_on?: Maybe<Array<BlazePreapprovalsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BlazePreapprovalsOrderBy>>;
  where?: Maybe<BlazePreapprovalsBoolExp>;
};

export type SubscriptionRootBlazePreapprovalsAggregateArgs = {
  distinct_on?: Maybe<Array<BlazePreapprovalsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BlazePreapprovalsOrderBy>>;
  where?: Maybe<BlazePreapprovalsBoolExp>;
};

export type SubscriptionRootBlazePreapprovalsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootBlazeShopEntriesArgs = {
  distinct_on?: Maybe<Array<BlazeShopEntriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BlazeShopEntriesOrderBy>>;
  where?: Maybe<BlazeShopEntriesBoolExp>;
};

export type SubscriptionRootBlazeShopEntriesAggregateArgs = {
  distinct_on?: Maybe<Array<BlazeShopEntriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BlazeShopEntriesOrderBy>>;
  where?: Maybe<BlazeShopEntriesBoolExp>;
};

export type SubscriptionRootBlazeShopEntriesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootBlazeUsersArgs = {
  distinct_on?: Maybe<Array<BlazeUsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BlazeUsersOrderBy>>;
  where?: Maybe<BlazeUsersBoolExp>;
};

export type SubscriptionRootBlazeUsersAggregateArgs = {
  distinct_on?: Maybe<Array<BlazeUsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BlazeUsersOrderBy>>;
  where?: Maybe<BlazeUsersBoolExp>;
};

export type SubscriptionRootBlazeUsersByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootCompaniesArgs = {
  distinct_on?: Maybe<Array<CompaniesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompaniesOrderBy>>;
  where?: Maybe<CompaniesBoolExp>;
};

export type SubscriptionRootCompaniesAggregateArgs = {
  distinct_on?: Maybe<Array<CompaniesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompaniesOrderBy>>;
  where?: Maybe<CompaniesBoolExp>;
};

export type SubscriptionRootCompaniesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootCompanyAgreementsArgs = {
  distinct_on?: Maybe<Array<CompanyAgreementsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyAgreementsOrderBy>>;
  where?: Maybe<CompanyAgreementsBoolExp>;
};

export type SubscriptionRootCompanyAgreementsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyAgreementsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyAgreementsOrderBy>>;
  where?: Maybe<CompanyAgreementsBoolExp>;
};

export type SubscriptionRootCompanyAgreementsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootCompanyDeliveriesArgs = {
  distinct_on?: Maybe<Array<CompanyDeliveriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyDeliveriesOrderBy>>;
  where?: Maybe<CompanyDeliveriesBoolExp>;
};

export type SubscriptionRootCompanyDeliveriesAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyDeliveriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyDeliveriesOrderBy>>;
  where?: Maybe<CompanyDeliveriesBoolExp>;
};

export type SubscriptionRootCompanyDeliveriesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootCompanyFacilitiesArgs = {
  distinct_on?: Maybe<Array<CompanyFacilitiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyFacilitiesOrderBy>>;
  where?: Maybe<CompanyFacilitiesBoolExp>;
};

export type SubscriptionRootCompanyFacilitiesAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyFacilitiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyFacilitiesOrderBy>>;
  where?: Maybe<CompanyFacilitiesBoolExp>;
};

export type SubscriptionRootCompanyFacilitiesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootCompanyLicensesArgs = {
  distinct_on?: Maybe<Array<CompanyLicensesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyLicensesOrderBy>>;
  where?: Maybe<CompanyLicensesBoolExp>;
};

export type SubscriptionRootCompanyLicensesAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyLicensesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyLicensesOrderBy>>;
  where?: Maybe<CompanyLicensesBoolExp>;
};

export type SubscriptionRootCompanyLicensesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootCompanyPartnershipInvitationsArgs = {
  distinct_on?: Maybe<Array<CompanyPartnershipInvitationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPartnershipInvitationsOrderBy>>;
  where?: Maybe<CompanyPartnershipInvitationsBoolExp>;
};

export type SubscriptionRootCompanyPartnershipInvitationsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyPartnershipInvitationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPartnershipInvitationsOrderBy>>;
  where?: Maybe<CompanyPartnershipInvitationsBoolExp>;
};

export type SubscriptionRootCompanyPartnershipInvitationsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootCompanyPartnershipRequestsArgs = {
  distinct_on?: Maybe<Array<CompanyPartnershipRequestsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPartnershipRequestsOrderBy>>;
  where?: Maybe<CompanyPartnershipRequestsBoolExp>;
};

export type SubscriptionRootCompanyPartnershipRequestsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyPartnershipRequestsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPartnershipRequestsOrderBy>>;
  where?: Maybe<CompanyPartnershipRequestsBoolExp>;
};

export type SubscriptionRootCompanyPartnershipRequestsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootCompanyPayorContactsArgs = {
  distinct_on?: Maybe<Array<CompanyPayorContactsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorContactsOrderBy>>;
  where?: Maybe<CompanyPayorContactsBoolExp>;
};

export type SubscriptionRootCompanyPayorContactsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyPayorContactsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorContactsOrderBy>>;
  where?: Maybe<CompanyPayorContactsBoolExp>;
};

export type SubscriptionRootCompanyPayorContactsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootCompanyPayorPartnershipsArgs = {
  distinct_on?: Maybe<Array<CompanyPayorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorPartnershipsOrderBy>>;
  where?: Maybe<CompanyPayorPartnershipsBoolExp>;
};

export type SubscriptionRootCompanyPayorPartnershipsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyPayorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyPayorPartnershipsOrderBy>>;
  where?: Maybe<CompanyPayorPartnershipsBoolExp>;
};

export type SubscriptionRootCompanyPayorPartnershipsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootCompanySettingsArgs = {
  distinct_on?: Maybe<Array<CompanySettingsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanySettingsOrderBy>>;
  where?: Maybe<CompanySettingsBoolExp>;
};

export type SubscriptionRootCompanySettingsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanySettingsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanySettingsOrderBy>>;
  where?: Maybe<CompanySettingsBoolExp>;
};

export type SubscriptionRootCompanySettingsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootCompanyTypeArgs = {
  distinct_on?: Maybe<Array<CompanyTypeSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyTypeOrderBy>>;
  where?: Maybe<CompanyTypeBoolExp>;
};

export type SubscriptionRootCompanyTypeAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyTypeSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyTypeOrderBy>>;
  where?: Maybe<CompanyTypeBoolExp>;
};

export type SubscriptionRootCompanyTypeByPkArgs = {
  value: Scalars["String"];
};

export type SubscriptionRootCompanyVendorContactsArgs = {
  distinct_on?: Maybe<Array<CompanyVendorContactsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorContactsOrderBy>>;
  where?: Maybe<CompanyVendorContactsBoolExp>;
};

export type SubscriptionRootCompanyVendorContactsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyVendorContactsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorContactsOrderBy>>;
  where?: Maybe<CompanyVendorContactsBoolExp>;
};

export type SubscriptionRootCompanyVendorContactsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootCompanyVendorPartnershipsArgs = {
  distinct_on?: Maybe<Array<CompanyVendorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorPartnershipsOrderBy>>;
  where?: Maybe<CompanyVendorPartnershipsBoolExp>;
};

export type SubscriptionRootCompanyVendorPartnershipsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyVendorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorPartnershipsOrderBy>>;
  where?: Maybe<CompanyVendorPartnershipsBoolExp>;
};

export type SubscriptionRootCompanyVendorPartnershipsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootContractsArgs = {
  distinct_on?: Maybe<Array<ContractsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ContractsOrderBy>>;
  where?: Maybe<ContractsBoolExp>;
};

export type SubscriptionRootContractsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ContractsOrderBy>>;
  where?: Maybe<ContractsBoolExp>;
};

export type SubscriptionRootContractsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootCustomerSurveillanceResultsArgs = {
  distinct_on?: Maybe<Array<CustomerSurveillanceResultsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CustomerSurveillanceResultsOrderBy>>;
  where?: Maybe<CustomerSurveillanceResultsBoolExp>;
};

export type SubscriptionRootCustomerSurveillanceResultsAggregateArgs = {
  distinct_on?: Maybe<Array<CustomerSurveillanceResultsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CustomerSurveillanceResultsOrderBy>>;
  where?: Maybe<CustomerSurveillanceResultsBoolExp>;
};

export type SubscriptionRootCustomerSurveillanceResultsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootDebtFacilitiesArgs = {
  distinct_on?: Maybe<Array<DebtFacilitiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<DebtFacilitiesOrderBy>>;
  where?: Maybe<DebtFacilitiesBoolExp>;
};

export type SubscriptionRootDebtFacilitiesAggregateArgs = {
  distinct_on?: Maybe<Array<DebtFacilitiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<DebtFacilitiesOrderBy>>;
  where?: Maybe<DebtFacilitiesBoolExp>;
};

export type SubscriptionRootDebtFacilitiesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootDebtFacilityCapacitiesArgs = {
  distinct_on?: Maybe<Array<DebtFacilityCapacitiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<DebtFacilityCapacitiesOrderBy>>;
  where?: Maybe<DebtFacilityCapacitiesBoolExp>;
};

export type SubscriptionRootDebtFacilityCapacitiesAggregateArgs = {
  distinct_on?: Maybe<Array<DebtFacilityCapacitiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<DebtFacilityCapacitiesOrderBy>>;
  where?: Maybe<DebtFacilityCapacitiesBoolExp>;
};

export type SubscriptionRootDebtFacilityCapacitiesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootDebtFacilityEventsArgs = {
  distinct_on?: Maybe<Array<DebtFacilityEventsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<DebtFacilityEventsOrderBy>>;
  where?: Maybe<DebtFacilityEventsBoolExp>;
};

export type SubscriptionRootDebtFacilityEventsAggregateArgs = {
  distinct_on?: Maybe<Array<DebtFacilityEventsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<DebtFacilityEventsOrderBy>>;
  where?: Maybe<DebtFacilityEventsBoolExp>;
};

export type SubscriptionRootDebtFacilityEventsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootEbbaApplicationFilesArgs = {
  distinct_on?: Maybe<Array<EbbaApplicationFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EbbaApplicationFilesOrderBy>>;
  where?: Maybe<EbbaApplicationFilesBoolExp>;
};

export type SubscriptionRootEbbaApplicationFilesAggregateArgs = {
  distinct_on?: Maybe<Array<EbbaApplicationFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EbbaApplicationFilesOrderBy>>;
  where?: Maybe<EbbaApplicationFilesBoolExp>;
};

export type SubscriptionRootEbbaApplicationFilesByPkArgs = {
  ebba_application_id: Scalars["uuid"];
  file_id: Scalars["uuid"];
};

export type SubscriptionRootEbbaApplicationsArgs = {
  distinct_on?: Maybe<Array<EbbaApplicationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EbbaApplicationsOrderBy>>;
  where?: Maybe<EbbaApplicationsBoolExp>;
};

export type SubscriptionRootEbbaApplicationsAggregateArgs = {
  distinct_on?: Maybe<Array<EbbaApplicationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EbbaApplicationsOrderBy>>;
  where?: Maybe<EbbaApplicationsBoolExp>;
};

export type SubscriptionRootEbbaApplicationsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootFilesArgs = {
  distinct_on?: Maybe<Array<FilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FilesOrderBy>>;
  where?: Maybe<FilesBoolExp>;
};

export type SubscriptionRootFilesAggregateArgs = {
  distinct_on?: Maybe<Array<FilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FilesOrderBy>>;
  where?: Maybe<FilesBoolExp>;
};

export type SubscriptionRootFilesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootFinancialSummariesArgs = {
  distinct_on?: Maybe<Array<FinancialSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FinancialSummariesOrderBy>>;
  where?: Maybe<FinancialSummariesBoolExp>;
};

export type SubscriptionRootFinancialSummariesAggregateArgs = {
  distinct_on?: Maybe<Array<FinancialSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FinancialSummariesOrderBy>>;
  where?: Maybe<FinancialSummariesBoolExp>;
};

export type SubscriptionRootFinancialSummariesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootInvoiceFileTypeArgs = {
  distinct_on?: Maybe<Array<InvoiceFileTypeSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoiceFileTypeOrderBy>>;
  where?: Maybe<InvoiceFileTypeBoolExp>;
};

export type SubscriptionRootInvoiceFileTypeAggregateArgs = {
  distinct_on?: Maybe<Array<InvoiceFileTypeSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoiceFileTypeOrderBy>>;
  where?: Maybe<InvoiceFileTypeBoolExp>;
};

export type SubscriptionRootInvoiceFileTypeByPkArgs = {
  value: Scalars["String"];
};

export type SubscriptionRootInvoiceFilesArgs = {
  distinct_on?: Maybe<Array<InvoiceFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoiceFilesOrderBy>>;
  where?: Maybe<InvoiceFilesBoolExp>;
};

export type SubscriptionRootInvoiceFilesAggregateArgs = {
  distinct_on?: Maybe<Array<InvoiceFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoiceFilesOrderBy>>;
  where?: Maybe<InvoiceFilesBoolExp>;
};

export type SubscriptionRootInvoiceFilesByPkArgs = {
  file_id: Scalars["uuid"];
  invoice_id: Scalars["uuid"];
};

export type SubscriptionRootInvoicesArgs = {
  distinct_on?: Maybe<Array<InvoicesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoicesOrderBy>>;
  where?: Maybe<InvoicesBoolExp>;
};

export type SubscriptionRootInvoicesAggregateArgs = {
  distinct_on?: Maybe<Array<InvoicesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InvoicesOrderBy>>;
  where?: Maybe<InvoicesBoolExp>;
};

export type SubscriptionRootInvoicesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootLineOfCreditsArgs = {
  distinct_on?: Maybe<Array<LineOfCreditsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LineOfCreditsOrderBy>>;
  where?: Maybe<LineOfCreditsBoolExp>;
};

export type SubscriptionRootLineOfCreditsAggregateArgs = {
  distinct_on?: Maybe<Array<LineOfCreditsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LineOfCreditsOrderBy>>;
  where?: Maybe<LineOfCreditsBoolExp>;
};

export type SubscriptionRootLineOfCreditsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootLoanReportsArgs = {
  distinct_on?: Maybe<Array<LoanReportsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoanReportsOrderBy>>;
  where?: Maybe<LoanReportsBoolExp>;
};

export type SubscriptionRootLoanReportsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanReportsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoanReportsOrderBy>>;
  where?: Maybe<LoanReportsBoolExp>;
};

export type SubscriptionRootLoanReportsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootLoanTypeArgs = {
  distinct_on?: Maybe<Array<LoanTypeSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoanTypeOrderBy>>;
  where?: Maybe<LoanTypeBoolExp>;
};

export type SubscriptionRootLoanTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanTypeSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoanTypeOrderBy>>;
  where?: Maybe<LoanTypeBoolExp>;
};

export type SubscriptionRootLoanTypeByPkArgs = {
  value: Scalars["String"];
};

export type SubscriptionRootLoansArgs = {
  distinct_on?: Maybe<Array<LoansSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoansOrderBy>>;
  where?: Maybe<LoansBoolExp>;
};

export type SubscriptionRootLoansAggregateArgs = {
  distinct_on?: Maybe<Array<LoansSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<LoansOrderBy>>;
  where?: Maybe<LoansBoolExp>;
};

export type SubscriptionRootLoansByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootMetrcAnalysisSummariesArgs = {
  distinct_on?: Maybe<Array<MetrcAnalysisSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcAnalysisSummariesOrderBy>>;
  where?: Maybe<MetrcAnalysisSummariesBoolExp>;
};

export type SubscriptionRootMetrcAnalysisSummariesAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcAnalysisSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcAnalysisSummariesOrderBy>>;
  where?: Maybe<MetrcAnalysisSummariesBoolExp>;
};

export type SubscriptionRootMetrcAnalysisSummariesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootMetrcApiKeysArgs = {
  distinct_on?: Maybe<Array<MetrcApiKeysSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcApiKeysOrderBy>>;
  where?: Maybe<MetrcApiKeysBoolExp>;
};

export type SubscriptionRootMetrcApiKeysAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcApiKeysSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcApiKeysOrderBy>>;
  where?: Maybe<MetrcApiKeysBoolExp>;
};

export type SubscriptionRootMetrcApiKeysByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootMetrcDeliveriesArgs = {
  distinct_on?: Maybe<Array<MetrcDeliveriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcDeliveriesOrderBy>>;
  where?: Maybe<MetrcDeliveriesBoolExp>;
};

export type SubscriptionRootMetrcDeliveriesAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcDeliveriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcDeliveriesOrderBy>>;
  where?: Maybe<MetrcDeliveriesBoolExp>;
};

export type SubscriptionRootMetrcDeliveriesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootMetrcDownloadSummariesArgs = {
  distinct_on?: Maybe<Array<MetrcDownloadSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcDownloadSummariesOrderBy>>;
  where?: Maybe<MetrcDownloadSummariesBoolExp>;
};

export type SubscriptionRootMetrcDownloadSummariesAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcDownloadSummariesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcDownloadSummariesOrderBy>>;
  where?: Maybe<MetrcDownloadSummariesBoolExp>;
};

export type SubscriptionRootMetrcDownloadSummariesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootMetrcHarvestsArgs = {
  distinct_on?: Maybe<Array<MetrcHarvestsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcHarvestsOrderBy>>;
  where?: Maybe<MetrcHarvestsBoolExp>;
};

export type SubscriptionRootMetrcHarvestsAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcHarvestsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcHarvestsOrderBy>>;
  where?: Maybe<MetrcHarvestsBoolExp>;
};

export type SubscriptionRootMetrcHarvestsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootMetrcPackagesArgs = {
  distinct_on?: Maybe<Array<MetrcPackagesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcPackagesOrderBy>>;
  where?: Maybe<MetrcPackagesBoolExp>;
};

export type SubscriptionRootMetrcPackagesAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcPackagesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcPackagesOrderBy>>;
  where?: Maybe<MetrcPackagesBoolExp>;
};

export type SubscriptionRootMetrcPackagesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootMetrcPlantBatchesArgs = {
  distinct_on?: Maybe<Array<MetrcPlantBatchesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcPlantBatchesOrderBy>>;
  where?: Maybe<MetrcPlantBatchesBoolExp>;
};

export type SubscriptionRootMetrcPlantBatchesAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcPlantBatchesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcPlantBatchesOrderBy>>;
  where?: Maybe<MetrcPlantBatchesBoolExp>;
};

export type SubscriptionRootMetrcPlantBatchesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootMetrcPlantsArgs = {
  distinct_on?: Maybe<Array<MetrcPlantsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcPlantsOrderBy>>;
  where?: Maybe<MetrcPlantsBoolExp>;
};

export type SubscriptionRootMetrcPlantsAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcPlantsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcPlantsOrderBy>>;
  where?: Maybe<MetrcPlantsBoolExp>;
};

export type SubscriptionRootMetrcPlantsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootMetrcSalesReceiptsArgs = {
  distinct_on?: Maybe<Array<MetrcSalesReceiptsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcSalesReceiptsOrderBy>>;
  where?: Maybe<MetrcSalesReceiptsBoolExp>;
};

export type SubscriptionRootMetrcSalesReceiptsAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcSalesReceiptsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcSalesReceiptsOrderBy>>;
  where?: Maybe<MetrcSalesReceiptsBoolExp>;
};

export type SubscriptionRootMetrcSalesReceiptsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootMetrcSalesTransactionsArgs = {
  distinct_on?: Maybe<Array<MetrcSalesTransactionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcSalesTransactionsOrderBy>>;
  where?: Maybe<MetrcSalesTransactionsBoolExp>;
};

export type SubscriptionRootMetrcSalesTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcSalesTransactionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcSalesTransactionsOrderBy>>;
  where?: Maybe<MetrcSalesTransactionsBoolExp>;
};

export type SubscriptionRootMetrcSalesTransactionsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootMetrcToBespokeCatalogSkusArgs = {
  distinct_on?: Maybe<Array<MetrcToBespokeCatalogSkusSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcToBespokeCatalogSkusOrderBy>>;
  where?: Maybe<MetrcToBespokeCatalogSkusBoolExp>;
};

export type SubscriptionRootMetrcToBespokeCatalogSkusAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcToBespokeCatalogSkusSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcToBespokeCatalogSkusOrderBy>>;
  where?: Maybe<MetrcToBespokeCatalogSkusBoolExp>;
};

export type SubscriptionRootMetrcToBespokeCatalogSkusByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootMetrcTransferPackagesArgs = {
  distinct_on?: Maybe<Array<MetrcTransferPackagesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcTransferPackagesOrderBy>>;
  where?: Maybe<MetrcTransferPackagesBoolExp>;
};

export type SubscriptionRootMetrcTransferPackagesAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcTransferPackagesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcTransferPackagesOrderBy>>;
  where?: Maybe<MetrcTransferPackagesBoolExp>;
};

export type SubscriptionRootMetrcTransferPackagesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootMetrcTransfersArgs = {
  distinct_on?: Maybe<Array<MetrcTransfersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcTransfersOrderBy>>;
  where?: Maybe<MetrcTransfersBoolExp>;
};

export type SubscriptionRootMetrcTransfersAggregateArgs = {
  distinct_on?: Maybe<Array<MetrcTransfersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetrcTransfersOrderBy>>;
  where?: Maybe<MetrcTransfersBoolExp>;
};

export type SubscriptionRootMetrcTransfersByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootMonthlySummaryCalculationsArgs = {
  distinct_on?: Maybe<Array<MonthlySummaryCalculationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MonthlySummaryCalculationsOrderBy>>;
  where?: Maybe<MonthlySummaryCalculationsBoolExp>;
};

export type SubscriptionRootMonthlySummaryCalculationsAggregateArgs = {
  distinct_on?: Maybe<Array<MonthlySummaryCalculationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MonthlySummaryCalculationsOrderBy>>;
  where?: Maybe<MonthlySummaryCalculationsBoolExp>;
};

export type SubscriptionRootMonthlySummaryCalculationsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootParentCompaniesArgs = {
  distinct_on?: Maybe<Array<ParentCompaniesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ParentCompaniesOrderBy>>;
  where?: Maybe<ParentCompaniesBoolExp>;
};

export type SubscriptionRootParentCompaniesAggregateArgs = {
  distinct_on?: Maybe<Array<ParentCompaniesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ParentCompaniesOrderBy>>;
  where?: Maybe<ParentCompaniesBoolExp>;
};

export type SubscriptionRootParentCompaniesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootPaymentsArgs = {
  distinct_on?: Maybe<Array<PaymentsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PaymentsOrderBy>>;
  where?: Maybe<PaymentsBoolExp>;
};

export type SubscriptionRootPaymentsAggregateArgs = {
  distinct_on?: Maybe<Array<PaymentsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PaymentsOrderBy>>;
  where?: Maybe<PaymentsBoolExp>;
};

export type SubscriptionRootPaymentsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootPayorsArgs = {
  distinct_on?: Maybe<Array<PayorsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PayorsOrderBy>>;
  where?: Maybe<PayorsBoolExp>;
};

export type SubscriptionRootPayorsAggregateArgs = {
  distinct_on?: Maybe<Array<PayorsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PayorsOrderBy>>;
  where?: Maybe<PayorsBoolExp>;
};

export type SubscriptionRootPurchaseOrderFileTypeArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderFileTypeSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderFileTypeOrderBy>>;
  where?: Maybe<PurchaseOrderFileTypeBoolExp>;
};

export type SubscriptionRootPurchaseOrderFileTypeAggregateArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderFileTypeSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderFileTypeOrderBy>>;
  where?: Maybe<PurchaseOrderFileTypeBoolExp>;
};

export type SubscriptionRootPurchaseOrderFileTypeByPkArgs = {
  value: Scalars["String"];
};

export type SubscriptionRootPurchaseOrderFilesArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderFilesOrderBy>>;
  where?: Maybe<PurchaseOrderFilesBoolExp>;
};

export type SubscriptionRootPurchaseOrderFilesAggregateArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderFilesOrderBy>>;
  where?: Maybe<PurchaseOrderFilesBoolExp>;
};

export type SubscriptionRootPurchaseOrderFilesByPkArgs = {
  file_id: Scalars["uuid"];
  purchase_order_id: Scalars["uuid"];
};

export type SubscriptionRootPurchaseOrderMetrcTransfersArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderMetrcTransfersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderMetrcTransfersOrderBy>>;
  where?: Maybe<PurchaseOrderMetrcTransfersBoolExp>;
};

export type SubscriptionRootPurchaseOrderMetrcTransfersAggregateArgs = {
  distinct_on?: Maybe<Array<PurchaseOrderMetrcTransfersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrderMetrcTransfersOrderBy>>;
  where?: Maybe<PurchaseOrderMetrcTransfersBoolExp>;
};

export type SubscriptionRootPurchaseOrderMetrcTransfersByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootPurchaseOrdersArgs = {
  distinct_on?: Maybe<Array<PurchaseOrdersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrdersOrderBy>>;
  where?: Maybe<PurchaseOrdersBoolExp>;
};

export type SubscriptionRootPurchaseOrdersAggregateArgs = {
  distinct_on?: Maybe<Array<PurchaseOrdersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PurchaseOrdersOrderBy>>;
  where?: Maybe<PurchaseOrdersBoolExp>;
};

export type SubscriptionRootPurchaseOrdersByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootRequestStatusArgs = {
  distinct_on?: Maybe<Array<RequestStatusSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<RequestStatusOrderBy>>;
  where?: Maybe<RequestStatusBoolExp>;
};

export type SubscriptionRootRequestStatusAggregateArgs = {
  distinct_on?: Maybe<Array<RequestStatusSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<RequestStatusOrderBy>>;
  where?: Maybe<RequestStatusBoolExp>;
};

export type SubscriptionRootRequestStatusByPkArgs = {
  value: Scalars["String"];
};

export type SubscriptionRootRevokedTokensArgs = {
  distinct_on?: Maybe<Array<RevokedTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<RevokedTokensOrderBy>>;
  where?: Maybe<RevokedTokensBoolExp>;
};

export type SubscriptionRootRevokedTokensAggregateArgs = {
  distinct_on?: Maybe<Array<RevokedTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<RevokedTokensOrderBy>>;
  where?: Maybe<RevokedTokensBoolExp>;
};

export type SubscriptionRootRevokedTokensByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootSyncPipelinesArgs = {
  distinct_on?: Maybe<Array<SyncPipelinesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SyncPipelinesOrderBy>>;
  where?: Maybe<SyncPipelinesBoolExp>;
};

export type SubscriptionRootSyncPipelinesAggregateArgs = {
  distinct_on?: Maybe<Array<SyncPipelinesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SyncPipelinesOrderBy>>;
  where?: Maybe<SyncPipelinesBoolExp>;
};

export type SubscriptionRootSyncPipelinesByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootTransactionsArgs = {
  distinct_on?: Maybe<Array<TransactionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TransactionsOrderBy>>;
  where?: Maybe<TransactionsBoolExp>;
};

export type SubscriptionRootTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<TransactionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TransactionsOrderBy>>;
  where?: Maybe<TransactionsBoolExp>;
};

export type SubscriptionRootTransactionsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootTwoFactorLinksArgs = {
  distinct_on?: Maybe<Array<TwoFactorLinksSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TwoFactorLinksOrderBy>>;
  where?: Maybe<TwoFactorLinksBoolExp>;
};

export type SubscriptionRootTwoFactorLinksAggregateArgs = {
  distinct_on?: Maybe<Array<TwoFactorLinksSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TwoFactorLinksOrderBy>>;
  where?: Maybe<TwoFactorLinksBoolExp>;
};

export type SubscriptionRootTwoFactorLinksByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootUserRolesArgs = {
  distinct_on?: Maybe<Array<UserRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserRolesOrderBy>>;
  where?: Maybe<UserRolesBoolExp>;
};

export type SubscriptionRootUserRolesAggregateArgs = {
  distinct_on?: Maybe<Array<UserRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserRolesOrderBy>>;
  where?: Maybe<UserRolesBoolExp>;
};

export type SubscriptionRootUserRolesByPkArgs = {
  value: Scalars["String"];
};

export type SubscriptionRootUsersArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

export type SubscriptionRootUsersAggregateArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

export type SubscriptionRootUsersByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootVendorChangeRequestsArgs = {
  distinct_on?: Maybe<Array<VendorChangeRequestsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<VendorChangeRequestsOrderBy>>;
  where?: Maybe<VendorChangeRequestsBoolExp>;
};

export type SubscriptionRootVendorChangeRequestsAggregateArgs = {
  distinct_on?: Maybe<Array<VendorChangeRequestsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<VendorChangeRequestsOrderBy>>;
  where?: Maybe<VendorChangeRequestsBoolExp>;
};

export type SubscriptionRootVendorChangeRequestsByPkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionRootVendorsArgs = {
  distinct_on?: Maybe<Array<VendorsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<VendorsOrderBy>>;
  where?: Maybe<VendorsBoolExp>;
};

export type SubscriptionRootVendorsAggregateArgs = {
  distinct_on?: Maybe<Array<VendorsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<VendorsOrderBy>>;
  where?: Maybe<VendorsBoolExp>;
};

/** columns and relationships of "sync_pipelines" */
export type SyncPipelines = {
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  internal_state: Scalars["json"];
  name: Scalars["String"];
  params: Scalars["json"];
  status: Scalars["String"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "sync_pipelines" */
export type SyncPipelinesInternalStateArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "sync_pipelines" */
export type SyncPipelinesParamsArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "sync_pipelines" */
export type SyncPipelinesAggregate = {
  aggregate?: Maybe<SyncPipelinesAggregateFields>;
  nodes: Array<SyncPipelines>;
};

/** aggregate fields of "sync_pipelines" */
export type SyncPipelinesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<SyncPipelinesMaxFields>;
  min?: Maybe<SyncPipelinesMinFields>;
};

/** aggregate fields of "sync_pipelines" */
export type SyncPipelinesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<SyncPipelinesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "sync_pipelines". All fields are combined with a logical 'AND'. */
export type SyncPipelinesBoolExp = {
  _and?: Maybe<Array<SyncPipelinesBoolExp>>;
  _not?: Maybe<SyncPipelinesBoolExp>;
  _or?: Maybe<Array<SyncPipelinesBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  internal_state?: Maybe<JsonComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  params?: Maybe<JsonComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "sync_pipelines" */
export enum SyncPipelinesConstraint {
  /** unique or primary key constraint */
  SyncPipelinesPkey = "sync_pipelines_pkey",
}

/** input type for inserting data into table "sync_pipelines" */
export type SyncPipelinesInsertInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  internal_state?: Maybe<Scalars["json"]>;
  name?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["json"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type SyncPipelinesMaxFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type SyncPipelinesMinFields = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "sync_pipelines" */
export type SyncPipelinesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<SyncPipelines>;
};

/** on_conflict condition type for table "sync_pipelines" */
export type SyncPipelinesOnConflict = {
  constraint: SyncPipelinesConstraint;
  update_columns?: Array<SyncPipelinesUpdateColumn>;
  where?: Maybe<SyncPipelinesBoolExp>;
};

/** Ordering options when selecting data from "sync_pipelines". */
export type SyncPipelinesOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  internal_state?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  params?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: sync_pipelines */
export type SyncPipelinesPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "sync_pipelines" */
export enum SyncPipelinesSelectColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InternalState = "internal_state",
  /** column name */
  Name = "name",
  /** column name */
  Params = "params",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "sync_pipelines" */
export type SyncPipelinesSetInput = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  internal_state?: Maybe<Scalars["json"]>;
  name?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["json"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "sync_pipelines" */
export enum SyncPipelinesUpdateColumn {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InternalState = "internal_state",
  /** column name */
  Name = "name",
  /** column name */
  Params = "params",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type TimestampComparisonExp = {
  _eq?: Maybe<Scalars["timestamp"]>;
  _gt?: Maybe<Scalars["timestamp"]>;
  _gte?: Maybe<Scalars["timestamp"]>;
  _in?: Maybe<Array<Scalars["timestamp"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamp"]>;
  _lte?: Maybe<Scalars["timestamp"]>;
  _neq?: Maybe<Scalars["timestamp"]>;
  _nin?: Maybe<Array<Scalars["timestamp"]>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: Maybe<Scalars["timestamptz"]>;
  _gt?: Maybe<Scalars["timestamptz"]>;
  _gte?: Maybe<Scalars["timestamptz"]>;
  _in?: Maybe<Array<Scalars["timestamptz"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamptz"]>;
  _lte?: Maybe<Scalars["timestamptz"]>;
  _neq?: Maybe<Scalars["timestamptz"]>;
  _nin?: Maybe<Array<Scalars["timestamptz"]>>;
};

/** Transactions correspond to debits and credits that take place on a loan */
export type Transactions = {
  amount: Scalars["numeric"];
  created_at: Scalars["timestamptz"];
  created_by_user_id?: Maybe<Scalars["uuid"]>;
  /** For financial purposes, this is the date this transaction is considered in effect. */
  effective_date: Scalars["date"];
  id: Scalars["uuid"];
  is_deleted?: Maybe<Scalars["Boolean"]>;
  /** An object relationship */
  loan?: Maybe<Loans>;
  loan_id?: Maybe<Scalars["uuid"]>;
  modified_at: Scalars["timestamptz"];
  modified_by_user_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  payment: Payments;
  payment_id: Scalars["uuid"];
  /** For information purposes, to understand why a fee may have been generated, for example */
  subtype?: Maybe<Scalars["String"]>;
  to_fees: Scalars["numeric"];
  to_interest: Scalars["numeric"];
  to_principal: Scalars["numeric"];
  type: Scalars["String"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "transactions" */
export type TransactionsAggregate = {
  aggregate?: Maybe<TransactionsAggregateFields>;
  nodes: Array<Transactions>;
};

/** aggregate fields of "transactions" */
export type TransactionsAggregateFields = {
  avg?: Maybe<TransactionsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<TransactionsMaxFields>;
  min?: Maybe<TransactionsMinFields>;
  stddev?: Maybe<TransactionsStddevFields>;
  stddev_pop?: Maybe<TransactionsStddevPopFields>;
  stddev_samp?: Maybe<TransactionsStddevSampFields>;
  sum?: Maybe<TransactionsSumFields>;
  var_pop?: Maybe<TransactionsVarPopFields>;
  var_samp?: Maybe<TransactionsVarSampFields>;
  variance?: Maybe<TransactionsVarianceFields>;
};

/** aggregate fields of "transactions" */
export type TransactionsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<TransactionsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "transactions" */
export type TransactionsAggregateOrderBy = {
  avg?: Maybe<TransactionsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<TransactionsMaxOrderBy>;
  min?: Maybe<TransactionsMinOrderBy>;
  stddev?: Maybe<TransactionsStddevOrderBy>;
  stddev_pop?: Maybe<TransactionsStddevPopOrderBy>;
  stddev_samp?: Maybe<TransactionsStddevSampOrderBy>;
  sum?: Maybe<TransactionsSumOrderBy>;
  var_pop?: Maybe<TransactionsVarPopOrderBy>;
  var_samp?: Maybe<TransactionsVarSampOrderBy>;
  variance?: Maybe<TransactionsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "transactions" */
export type TransactionsArrRelInsertInput = {
  data: Array<TransactionsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<TransactionsOnConflict>;
};

/** aggregate avg on columns */
export type TransactionsAvgFields = {
  amount?: Maybe<Scalars["Float"]>;
  to_fees?: Maybe<Scalars["Float"]>;
  to_interest?: Maybe<Scalars["Float"]>;
  to_principal?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "transactions" */
export type TransactionsAvgOrderBy = {
  amount?: Maybe<OrderBy>;
  to_fees?: Maybe<OrderBy>;
  to_interest?: Maybe<OrderBy>;
  to_principal?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "transactions". All fields are combined with a logical 'AND'. */
export type TransactionsBoolExp = {
  _and?: Maybe<Array<TransactionsBoolExp>>;
  _not?: Maybe<TransactionsBoolExp>;
  _or?: Maybe<Array<TransactionsBoolExp>>;
  amount?: Maybe<NumericComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by_user_id?: Maybe<UuidComparisonExp>;
  effective_date?: Maybe<DateComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  loan?: Maybe<LoansBoolExp>;
  loan_id?: Maybe<UuidComparisonExp>;
  modified_at?: Maybe<TimestamptzComparisonExp>;
  modified_by_user_id?: Maybe<UuidComparisonExp>;
  payment?: Maybe<PaymentsBoolExp>;
  payment_id?: Maybe<UuidComparisonExp>;
  subtype?: Maybe<StringComparisonExp>;
  to_fees?: Maybe<NumericComparisonExp>;
  to_interest?: Maybe<NumericComparisonExp>;
  to_principal?: Maybe<NumericComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "transactions" */
export enum TransactionsConstraint {
  /** unique or primary key constraint */
  TransactionsPkey = "transactions_pkey",
}

/** input type for incrementing numeric columns in table "transactions" */
export type TransactionsIncInput = {
  amount?: Maybe<Scalars["numeric"]>;
  to_fees?: Maybe<Scalars["numeric"]>;
  to_interest?: Maybe<Scalars["numeric"]>;
  to_principal?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "transactions" */
export type TransactionsInsertInput = {
  amount?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_user_id?: Maybe<Scalars["uuid"]>;
  /** For financial purposes, this is the date this transaction is considered in effect. */
  effective_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  loan?: Maybe<LoansObjRelInsertInput>;
  loan_id?: Maybe<Scalars["uuid"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by_user_id?: Maybe<Scalars["uuid"]>;
  payment?: Maybe<PaymentsObjRelInsertInput>;
  payment_id?: Maybe<Scalars["uuid"]>;
  /** For information purposes, to understand why a fee may have been generated, for example */
  subtype?: Maybe<Scalars["String"]>;
  to_fees?: Maybe<Scalars["numeric"]>;
  to_interest?: Maybe<Scalars["numeric"]>;
  to_principal?: Maybe<Scalars["numeric"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type TransactionsMaxFields = {
  amount?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_user_id?: Maybe<Scalars["uuid"]>;
  /** For financial purposes, this is the date this transaction is considered in effect. */
  effective_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  loan_id?: Maybe<Scalars["uuid"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by_user_id?: Maybe<Scalars["uuid"]>;
  payment_id?: Maybe<Scalars["uuid"]>;
  /** For information purposes, to understand why a fee may have been generated, for example */
  subtype?: Maybe<Scalars["String"]>;
  to_fees?: Maybe<Scalars["numeric"]>;
  to_interest?: Maybe<Scalars["numeric"]>;
  to_principal?: Maybe<Scalars["numeric"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "transactions" */
export type TransactionsMaxOrderBy = {
  amount?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by_user_id?: Maybe<OrderBy>;
  /** For financial purposes, this is the date this transaction is considered in effect. */
  effective_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  loan_id?: Maybe<OrderBy>;
  modified_at?: Maybe<OrderBy>;
  modified_by_user_id?: Maybe<OrderBy>;
  payment_id?: Maybe<OrderBy>;
  /** For information purposes, to understand why a fee may have been generated, for example */
  subtype?: Maybe<OrderBy>;
  to_fees?: Maybe<OrderBy>;
  to_interest?: Maybe<OrderBy>;
  to_principal?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type TransactionsMinFields = {
  amount?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_user_id?: Maybe<Scalars["uuid"]>;
  /** For financial purposes, this is the date this transaction is considered in effect. */
  effective_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  loan_id?: Maybe<Scalars["uuid"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by_user_id?: Maybe<Scalars["uuid"]>;
  payment_id?: Maybe<Scalars["uuid"]>;
  /** For information purposes, to understand why a fee may have been generated, for example */
  subtype?: Maybe<Scalars["String"]>;
  to_fees?: Maybe<Scalars["numeric"]>;
  to_interest?: Maybe<Scalars["numeric"]>;
  to_principal?: Maybe<Scalars["numeric"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "transactions" */
export type TransactionsMinOrderBy = {
  amount?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by_user_id?: Maybe<OrderBy>;
  /** For financial purposes, this is the date this transaction is considered in effect. */
  effective_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  loan_id?: Maybe<OrderBy>;
  modified_at?: Maybe<OrderBy>;
  modified_by_user_id?: Maybe<OrderBy>;
  payment_id?: Maybe<OrderBy>;
  /** For information purposes, to understand why a fee may have been generated, for example */
  subtype?: Maybe<OrderBy>;
  to_fees?: Maybe<OrderBy>;
  to_interest?: Maybe<OrderBy>;
  to_principal?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "transactions" */
export type TransactionsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Transactions>;
};

/** on_conflict condition type for table "transactions" */
export type TransactionsOnConflict = {
  constraint: TransactionsConstraint;
  update_columns?: Array<TransactionsUpdateColumn>;
  where?: Maybe<TransactionsBoolExp>;
};

/** Ordering options when selecting data from "transactions". */
export type TransactionsOrderBy = {
  amount?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by_user_id?: Maybe<OrderBy>;
  effective_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  loan?: Maybe<LoansOrderBy>;
  loan_id?: Maybe<OrderBy>;
  modified_at?: Maybe<OrderBy>;
  modified_by_user_id?: Maybe<OrderBy>;
  payment?: Maybe<PaymentsOrderBy>;
  payment_id?: Maybe<OrderBy>;
  subtype?: Maybe<OrderBy>;
  to_fees?: Maybe<OrderBy>;
  to_interest?: Maybe<OrderBy>;
  to_principal?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: transactions */
export type TransactionsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "transactions" */
export enum TransactionsSelectColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedByUserId = "created_by_user_id",
  /** column name */
  EffectiveDate = "effective_date",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  LoanId = "loan_id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  ModifiedByUserId = "modified_by_user_id",
  /** column name */
  PaymentId = "payment_id",
  /** column name */
  Subtype = "subtype",
  /** column name */
  ToFees = "to_fees",
  /** column name */
  ToInterest = "to_interest",
  /** column name */
  ToPrincipal = "to_principal",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "transactions" */
export type TransactionsSetInput = {
  amount?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_user_id?: Maybe<Scalars["uuid"]>;
  /** For financial purposes, this is the date this transaction is considered in effect. */
  effective_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  loan_id?: Maybe<Scalars["uuid"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by_user_id?: Maybe<Scalars["uuid"]>;
  payment_id?: Maybe<Scalars["uuid"]>;
  /** For information purposes, to understand why a fee may have been generated, for example */
  subtype?: Maybe<Scalars["String"]>;
  to_fees?: Maybe<Scalars["numeric"]>;
  to_interest?: Maybe<Scalars["numeric"]>;
  to_principal?: Maybe<Scalars["numeric"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type TransactionsStddevFields = {
  amount?: Maybe<Scalars["Float"]>;
  to_fees?: Maybe<Scalars["Float"]>;
  to_interest?: Maybe<Scalars["Float"]>;
  to_principal?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "transactions" */
export type TransactionsStddevOrderBy = {
  amount?: Maybe<OrderBy>;
  to_fees?: Maybe<OrderBy>;
  to_interest?: Maybe<OrderBy>;
  to_principal?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TransactionsStddevPopFields = {
  amount?: Maybe<Scalars["Float"]>;
  to_fees?: Maybe<Scalars["Float"]>;
  to_interest?: Maybe<Scalars["Float"]>;
  to_principal?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "transactions" */
export type TransactionsStddevPopOrderBy = {
  amount?: Maybe<OrderBy>;
  to_fees?: Maybe<OrderBy>;
  to_interest?: Maybe<OrderBy>;
  to_principal?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TransactionsStddevSampFields = {
  amount?: Maybe<Scalars["Float"]>;
  to_fees?: Maybe<Scalars["Float"]>;
  to_interest?: Maybe<Scalars["Float"]>;
  to_principal?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "transactions" */
export type TransactionsStddevSampOrderBy = {
  amount?: Maybe<OrderBy>;
  to_fees?: Maybe<OrderBy>;
  to_interest?: Maybe<OrderBy>;
  to_principal?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type TransactionsSumFields = {
  amount?: Maybe<Scalars["numeric"]>;
  to_fees?: Maybe<Scalars["numeric"]>;
  to_interest?: Maybe<Scalars["numeric"]>;
  to_principal?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "transactions" */
export type TransactionsSumOrderBy = {
  amount?: Maybe<OrderBy>;
  to_fees?: Maybe<OrderBy>;
  to_interest?: Maybe<OrderBy>;
  to_principal?: Maybe<OrderBy>;
};

/** update columns of table "transactions" */
export enum TransactionsUpdateColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedByUserId = "created_by_user_id",
  /** column name */
  EffectiveDate = "effective_date",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  LoanId = "loan_id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  ModifiedByUserId = "modified_by_user_id",
  /** column name */
  PaymentId = "payment_id",
  /** column name */
  Subtype = "subtype",
  /** column name */
  ToFees = "to_fees",
  /** column name */
  ToInterest = "to_interest",
  /** column name */
  ToPrincipal = "to_principal",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type TransactionsVarPopFields = {
  amount?: Maybe<Scalars["Float"]>;
  to_fees?: Maybe<Scalars["Float"]>;
  to_interest?: Maybe<Scalars["Float"]>;
  to_principal?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "transactions" */
export type TransactionsVarPopOrderBy = {
  amount?: Maybe<OrderBy>;
  to_fees?: Maybe<OrderBy>;
  to_interest?: Maybe<OrderBy>;
  to_principal?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TransactionsVarSampFields = {
  amount?: Maybe<Scalars["Float"]>;
  to_fees?: Maybe<Scalars["Float"]>;
  to_interest?: Maybe<Scalars["Float"]>;
  to_principal?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "transactions" */
export type TransactionsVarSampOrderBy = {
  amount?: Maybe<OrderBy>;
  to_fees?: Maybe<OrderBy>;
  to_interest?: Maybe<OrderBy>;
  to_principal?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type TransactionsVarianceFields = {
  amount?: Maybe<Scalars["Float"]>;
  to_fees?: Maybe<Scalars["Float"]>;
  to_interest?: Maybe<Scalars["Float"]>;
  to_principal?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "transactions" */
export type TransactionsVarianceOrderBy = {
  amount?: Maybe<OrderBy>;
  to_fees?: Maybe<OrderBy>;
  to_interest?: Maybe<OrderBy>;
  to_principal?: Maybe<OrderBy>;
};

/** Links that are secured behind two factor auth */
export type TwoFactorLinks = {
  expires_at: Scalars["timestamptz"];
  form_info: Scalars["json"];
  id: Scalars["uuid"];
  /** One link_id may have been sent to many emails. So we want to keep track of each email and what two-factor code they may need to enter separately as a key in this dictionary. */
  token_states?: Maybe<Scalars["json"]>;
};

/** Links that are secured behind two factor auth */
export type TwoFactorLinksFormInfoArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** Links that are secured behind two factor auth */
export type TwoFactorLinksTokenStatesArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "two_factor_links" */
export type TwoFactorLinksAggregate = {
  aggregate?: Maybe<TwoFactorLinksAggregateFields>;
  nodes: Array<TwoFactorLinks>;
};

/** aggregate fields of "two_factor_links" */
export type TwoFactorLinksAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<TwoFactorLinksMaxFields>;
  min?: Maybe<TwoFactorLinksMinFields>;
};

/** aggregate fields of "two_factor_links" */
export type TwoFactorLinksAggregateFieldsCountArgs = {
  columns?: Maybe<Array<TwoFactorLinksSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "two_factor_links". All fields are combined with a logical 'AND'. */
export type TwoFactorLinksBoolExp = {
  _and?: Maybe<Array<TwoFactorLinksBoolExp>>;
  _not?: Maybe<TwoFactorLinksBoolExp>;
  _or?: Maybe<Array<TwoFactorLinksBoolExp>>;
  expires_at?: Maybe<TimestamptzComparisonExp>;
  form_info?: Maybe<JsonComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  token_states?: Maybe<JsonComparisonExp>;
};

/** unique or primary key constraints on table "two_factor_links" */
export enum TwoFactorLinksConstraint {
  /** unique or primary key constraint */
  TwoFactorLinksPkey = "two_factor_links_pkey",
}

/** input type for inserting data into table "two_factor_links" */
export type TwoFactorLinksInsertInput = {
  expires_at?: Maybe<Scalars["timestamptz"]>;
  form_info?: Maybe<Scalars["json"]>;
  id?: Maybe<Scalars["uuid"]>;
  /** One link_id may have been sent to many emails. So we want to keep track of each email and what two-factor code they may need to enter separately as a key in this dictionary. */
  token_states?: Maybe<Scalars["json"]>;
};

/** aggregate max on columns */
export type TwoFactorLinksMaxFields = {
  expires_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type TwoFactorLinksMinFields = {
  expires_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "two_factor_links" */
export type TwoFactorLinksMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TwoFactorLinks>;
};

/** on_conflict condition type for table "two_factor_links" */
export type TwoFactorLinksOnConflict = {
  constraint: TwoFactorLinksConstraint;
  update_columns?: Array<TwoFactorLinksUpdateColumn>;
  where?: Maybe<TwoFactorLinksBoolExp>;
};

/** Ordering options when selecting data from "two_factor_links". */
export type TwoFactorLinksOrderBy = {
  expires_at?: Maybe<OrderBy>;
  form_info?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  token_states?: Maybe<OrderBy>;
};

/** primary key columns input for table: two_factor_links */
export type TwoFactorLinksPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "two_factor_links" */
export enum TwoFactorLinksSelectColumn {
  /** column name */
  ExpiresAt = "expires_at",
  /** column name */
  FormInfo = "form_info",
  /** column name */
  Id = "id",
  /** column name */
  TokenStates = "token_states",
}

/** input type for updating data in table "two_factor_links" */
export type TwoFactorLinksSetInput = {
  expires_at?: Maybe<Scalars["timestamptz"]>;
  form_info?: Maybe<Scalars["json"]>;
  id?: Maybe<Scalars["uuid"]>;
  /** One link_id may have been sent to many emails. So we want to keep track of each email and what two-factor code they may need to enter separately as a key in this dictionary. */
  token_states?: Maybe<Scalars["json"]>;
};

/** update columns of table "two_factor_links" */
export enum TwoFactorLinksUpdateColumn {
  /** column name */
  ExpiresAt = "expires_at",
  /** column name */
  FormInfo = "form_info",
  /** column name */
  Id = "id",
  /** column name */
  TokenStates = "token_states",
}

/** columns and relationships of "user_roles" */
export type UserRoles = {
  display_name: Scalars["String"];
  value: Scalars["String"];
};

/** aggregated selection of "user_roles" */
export type UserRolesAggregate = {
  aggregate?: Maybe<UserRolesAggregateFields>;
  nodes: Array<UserRoles>;
};

/** aggregate fields of "user_roles" */
export type UserRolesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<UserRolesMaxFields>;
  min?: Maybe<UserRolesMinFields>;
};

/** aggregate fields of "user_roles" */
export type UserRolesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<UserRolesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "user_roles". All fields are combined with a logical 'AND'. */
export type UserRolesBoolExp = {
  _and?: Maybe<Array<UserRolesBoolExp>>;
  _not?: Maybe<UserRolesBoolExp>;
  _or?: Maybe<Array<UserRolesBoolExp>>;
  display_name?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "user_roles" */
export enum UserRolesConstraint {
  /** unique or primary key constraint */
  UserRolesPkey = "user_roles_pkey",
}

export enum UserRolesEnum {
  /** Anonymous */
  Anonymous = "anonymous",
  /** Bank Admin */
  BankAdmin = "bank_admin",
  /** Bank Read Only */
  BankReadOnly = "bank_read_only",
  /** Bespoke Catalog Data Entry */
  BespokeCatalogDataEntry = "bespoke_catalog_data_entry",
  /** Bespoke Catalog Data Entry Inherited */
  BespokeCatalogDataEntryInherited = "bespoke_catalog_data_entry_inherited",
  /** Company Admin */
  CompanyAdmin = "company_admin",
  /** Company Admin Vendor Admin Inherited */
  CompanyAdminVendorAdminInherited = "company_admin_vendor_admin_inherited",
  /** Company Contact Only */
  CompanyContactOnly = "company_contact_only",
  /** Company Read Only */
  CompanyReadOnly = "company_read_only",
  /** Vendor Admin */
  VendorAdmin = "vendor_admin",
}

/** Boolean expression to compare columns of type "user_roles_enum". All fields are combined with logical 'AND'. */
export type UserRolesEnumComparisonExp = {
  _eq?: Maybe<UserRolesEnum>;
  _in?: Maybe<Array<UserRolesEnum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<UserRolesEnum>;
  _nin?: Maybe<Array<UserRolesEnum>>;
};

/** input type for inserting data into table "user_roles" */
export type UserRolesInsertInput = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type UserRolesMaxFields = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type UserRolesMinFields = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "user_roles" */
export type UserRolesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<UserRoles>;
};

/** on_conflict condition type for table "user_roles" */
export type UserRolesOnConflict = {
  constraint: UserRolesConstraint;
  update_columns?: Array<UserRolesUpdateColumn>;
  where?: Maybe<UserRolesBoolExp>;
};

/** Ordering options when selecting data from "user_roles". */
export type UserRolesOrderBy = {
  display_name?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: user_roles */
export type UserRolesPkColumnsInput = {
  value: Scalars["String"];
};

/** select columns of table "user_roles" */
export enum UserRolesSelectColumn {
  /** column name */
  DisplayName = "display_name",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "user_roles" */
export type UserRolesSetInput = {
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** update columns of table "user_roles" */
export enum UserRolesUpdateColumn {
  /** column name */
  DisplayName = "display_name",
  /** column name */
  Value = "value",
}

/** columns and relationships of "users" */
export type Users = {
  /** An object relationship */
  company?: Maybe<Companies>;
  company_id?: Maybe<Scalars["uuid"]>;
  company_role?: Maybe<Scalars["String"]>;
  company_role_new?: Maybe<Scalars["json"]>;
  created_at: Scalars["timestamptz"];
  created_by_user_id?: Maybe<Scalars["uuid"]>;
  email: Scalars["String"];
  first_name: Scalars["String"];
  full_name: Scalars["String"];
  id: Scalars["uuid"];
  is_deleted?: Maybe<Scalars["Boolean"]>;
  last_name: Scalars["String"];
  login_method: Scalars["String"];
  /** An object relationship */
  parent_company?: Maybe<ParentCompanies>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  password?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<UserRolesEnum>;
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "users" */
export type UsersCompanyRoleNewArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "users" */
export type UsersAggregate = {
  aggregate?: Maybe<UsersAggregateFields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type UsersAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<UsersMaxFields>;
  min?: Maybe<UsersMinFields>;
};

/** aggregate fields of "users" */
export type UsersAggregateFieldsCountArgs = {
  columns?: Maybe<Array<UsersSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "users" */
export type UsersAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<UsersMaxOrderBy>;
  min?: Maybe<UsersMinOrderBy>;
};

/** input type for inserting array relation for remote table "users" */
export type UsersArrRelInsertInput = {
  data: Array<UsersInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<UsersOnConflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type UsersBoolExp = {
  _and?: Maybe<Array<UsersBoolExp>>;
  _not?: Maybe<UsersBoolExp>;
  _or?: Maybe<Array<UsersBoolExp>>;
  company?: Maybe<CompaniesBoolExp>;
  company_id?: Maybe<UuidComparisonExp>;
  company_role?: Maybe<StringComparisonExp>;
  company_role_new?: Maybe<JsonComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by_user_id?: Maybe<UuidComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  first_name?: Maybe<StringComparisonExp>;
  full_name?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  last_name?: Maybe<StringComparisonExp>;
  login_method?: Maybe<StringComparisonExp>;
  parent_company?: Maybe<ParentCompaniesBoolExp>;
  parent_company_id?: Maybe<UuidComparisonExp>;
  password?: Maybe<StringComparisonExp>;
  phone_number?: Maybe<StringComparisonExp>;
  role?: Maybe<UserRolesEnumComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "users" */
export enum UsersConstraint {
  /** unique or primary key constraint */
  UsersEmailKey = "users_email_key",
  /** unique or primary key constraint */
  UsersPkey = "users_pkey",
}

/** input type for inserting data into table "users" */
export type UsersInsertInput = {
  company?: Maybe<CompaniesObjRelInsertInput>;
  company_id?: Maybe<Scalars["uuid"]>;
  company_role?: Maybe<Scalars["String"]>;
  company_role_new?: Maybe<Scalars["json"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_user_id?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  full_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  last_name?: Maybe<Scalars["String"]>;
  login_method?: Maybe<Scalars["String"]>;
  parent_company?: Maybe<ParentCompaniesObjRelInsertInput>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  password?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<UserRolesEnum>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type UsersMaxFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  company_role?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_user_id?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  full_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_name?: Maybe<Scalars["String"]>;
  login_method?: Maybe<Scalars["String"]>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  password?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "users" */
export type UsersMaxOrderBy = {
  company_id?: Maybe<OrderBy>;
  company_role?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by_user_id?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  first_name?: Maybe<OrderBy>;
  full_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_name?: Maybe<OrderBy>;
  login_method?: Maybe<OrderBy>;
  parent_company_id?: Maybe<OrderBy>;
  password?: Maybe<OrderBy>;
  phone_number?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type UsersMinFields = {
  company_id?: Maybe<Scalars["uuid"]>;
  company_role?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_user_id?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  full_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_name?: Maybe<Scalars["String"]>;
  login_method?: Maybe<Scalars["String"]>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  password?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "users" */
export type UsersMinOrderBy = {
  company_id?: Maybe<OrderBy>;
  company_role?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by_user_id?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  first_name?: Maybe<OrderBy>;
  full_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_name?: Maybe<OrderBy>;
  login_method?: Maybe<OrderBy>;
  parent_company_id?: Maybe<OrderBy>;
  password?: Maybe<OrderBy>;
  phone_number?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "users" */
export type UsersMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type UsersObjRelInsertInput = {
  data: UsersInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<UsersOnConflict>;
};

/** on_conflict condition type for table "users" */
export type UsersOnConflict = {
  constraint: UsersConstraint;
  update_columns?: Array<UsersUpdateColumn>;
  where?: Maybe<UsersBoolExp>;
};

/** Ordering options when selecting data from "users". */
export type UsersOrderBy = {
  company?: Maybe<CompaniesOrderBy>;
  company_id?: Maybe<OrderBy>;
  company_role?: Maybe<OrderBy>;
  company_role_new?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by_user_id?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  first_name?: Maybe<OrderBy>;
  full_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  last_name?: Maybe<OrderBy>;
  login_method?: Maybe<OrderBy>;
  parent_company?: Maybe<ParentCompaniesOrderBy>;
  parent_company_id?: Maybe<OrderBy>;
  password?: Maybe<OrderBy>;
  phone_number?: Maybe<OrderBy>;
  role?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: users */
export type UsersPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "users" */
export enum UsersSelectColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CompanyRole = "company_role",
  /** column name */
  CompanyRoleNew = "company_role_new",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedByUserId = "created_by_user_id",
  /** column name */
  Email = "email",
  /** column name */
  FirstName = "first_name",
  /** column name */
  FullName = "full_name",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  LastName = "last_name",
  /** column name */
  LoginMethod = "login_method",
  /** column name */
  ParentCompanyId = "parent_company_id",
  /** column name */
  Password = "password",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  Role = "role",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "users" */
export type UsersSetInput = {
  company_id?: Maybe<Scalars["uuid"]>;
  company_role?: Maybe<Scalars["String"]>;
  company_role_new?: Maybe<Scalars["json"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_user_id?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  full_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  last_name?: Maybe<Scalars["String"]>;
  login_method?: Maybe<Scalars["String"]>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  password?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<UserRolesEnum>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "users" */
export enum UsersUpdateColumn {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CompanyRole = "company_role",
  /** column name */
  CompanyRoleNew = "company_role_new",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedByUserId = "created_by_user_id",
  /** column name */
  Email = "email",
  /** column name */
  FirstName = "first_name",
  /** column name */
  FullName = "full_name",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  LastName = "last_name",
  /** column name */
  LoginMethod = "login_method",
  /** column name */
  ParentCompanyId = "parent_company_id",
  /** column name */
  Password = "password",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  Role = "role",
  /** column name */
  UpdatedAt = "updated_at",
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: Maybe<Scalars["uuid"]>;
  _gt?: Maybe<Scalars["uuid"]>;
  _gte?: Maybe<Scalars["uuid"]>;
  _in?: Maybe<Array<Scalars["uuid"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["uuid"]>;
  _lte?: Maybe<Scalars["uuid"]>;
  _neq?: Maybe<Scalars["uuid"]>;
  _nin?: Maybe<Array<Scalars["uuid"]>>;
};

/** columns and relationships of "vendor_change_requests" */
export type VendorChangeRequests = {
  approved_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  approved_by_user?: Maybe<Users>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  category: Scalars["String"];
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  is_deleted?: Maybe<Scalars["Boolean"]>;
  request_info: Scalars["json"];
  /** An object relationship */
  requested_vendor: Companies;
  requested_vendor_id: Scalars["uuid"];
  /** An object relationship */
  requesting_company?: Maybe<Companies>;
  requesting_company_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  requesting_user: Users;
  requesting_user_id: Scalars["uuid"];
  /** An object relationship */
  reviewed_by_user?: Maybe<Users>;
  reviewed_by_user_id?: Maybe<Scalars["uuid"]>;
  status: Scalars["String"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "vendor_change_requests" */
export type VendorChangeRequestsRequestInfoArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "vendor_change_requests" */
export type VendorChangeRequestsAggregate = {
  aggregate?: Maybe<VendorChangeRequestsAggregateFields>;
  nodes: Array<VendorChangeRequests>;
};

/** aggregate fields of "vendor_change_requests" */
export type VendorChangeRequestsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<VendorChangeRequestsMaxFields>;
  min?: Maybe<VendorChangeRequestsMinFields>;
};

/** aggregate fields of "vendor_change_requests" */
export type VendorChangeRequestsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<VendorChangeRequestsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "vendor_change_requests". All fields are combined with a logical 'AND'. */
export type VendorChangeRequestsBoolExp = {
  _and?: Maybe<Array<VendorChangeRequestsBoolExp>>;
  _not?: Maybe<VendorChangeRequestsBoolExp>;
  _or?: Maybe<Array<VendorChangeRequestsBoolExp>>;
  approved_at?: Maybe<TimestamptzComparisonExp>;
  approved_by_user?: Maybe<UsersBoolExp>;
  approved_by_user_id?: Maybe<UuidComparisonExp>;
  category?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_deleted?: Maybe<BooleanComparisonExp>;
  request_info?: Maybe<JsonComparisonExp>;
  requested_vendor?: Maybe<CompaniesBoolExp>;
  requested_vendor_id?: Maybe<UuidComparisonExp>;
  requesting_company?: Maybe<CompaniesBoolExp>;
  requesting_company_id?: Maybe<UuidComparisonExp>;
  requesting_user?: Maybe<UsersBoolExp>;
  requesting_user_id?: Maybe<UuidComparisonExp>;
  reviewed_by_user?: Maybe<UsersBoolExp>;
  reviewed_by_user_id?: Maybe<UuidComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "vendor_change_requests" */
export enum VendorChangeRequestsConstraint {
  /** unique or primary key constraint */
  VendorChangeRequestsPkey = "vendor_change_requests_pkey",
}

/** input type for inserting data into table "vendor_change_requests" */
export type VendorChangeRequestsInsertInput = {
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user?: Maybe<UsersObjRelInsertInput>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  category?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  request_info?: Maybe<Scalars["json"]>;
  requested_vendor?: Maybe<CompaniesObjRelInsertInput>;
  requested_vendor_id?: Maybe<Scalars["uuid"]>;
  requesting_company?: Maybe<CompaniesObjRelInsertInput>;
  requesting_company_id?: Maybe<Scalars["uuid"]>;
  requesting_user?: Maybe<UsersObjRelInsertInput>;
  requesting_user_id?: Maybe<Scalars["uuid"]>;
  reviewed_by_user?: Maybe<UsersObjRelInsertInput>;
  reviewed_by_user_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type VendorChangeRequestsMaxFields = {
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  category?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  requested_vendor_id?: Maybe<Scalars["uuid"]>;
  requesting_company_id?: Maybe<Scalars["uuid"]>;
  requesting_user_id?: Maybe<Scalars["uuid"]>;
  reviewed_by_user_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type VendorChangeRequestsMinFields = {
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  category?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  requested_vendor_id?: Maybe<Scalars["uuid"]>;
  requesting_company_id?: Maybe<Scalars["uuid"]>;
  requesting_user_id?: Maybe<Scalars["uuid"]>;
  reviewed_by_user_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "vendor_change_requests" */
export type VendorChangeRequestsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<VendorChangeRequests>;
};

/** on_conflict condition type for table "vendor_change_requests" */
export type VendorChangeRequestsOnConflict = {
  constraint: VendorChangeRequestsConstraint;
  update_columns?: Array<VendorChangeRequestsUpdateColumn>;
  where?: Maybe<VendorChangeRequestsBoolExp>;
};

/** Ordering options when selecting data from "vendor_change_requests". */
export type VendorChangeRequestsOrderBy = {
  approved_at?: Maybe<OrderBy>;
  approved_by_user?: Maybe<UsersOrderBy>;
  approved_by_user_id?: Maybe<OrderBy>;
  category?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_deleted?: Maybe<OrderBy>;
  request_info?: Maybe<OrderBy>;
  requested_vendor?: Maybe<CompaniesOrderBy>;
  requested_vendor_id?: Maybe<OrderBy>;
  requesting_company?: Maybe<CompaniesOrderBy>;
  requesting_company_id?: Maybe<OrderBy>;
  requesting_user?: Maybe<UsersOrderBy>;
  requesting_user_id?: Maybe<OrderBy>;
  reviewed_by_user?: Maybe<UsersOrderBy>;
  reviewed_by_user_id?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: vendor_change_requests */
export type VendorChangeRequestsPkColumnsInput = {
  id: Scalars["uuid"];
};

/** select columns of table "vendor_change_requests" */
export enum VendorChangeRequestsSelectColumn {
  /** column name */
  ApprovedAt = "approved_at",
  /** column name */
  ApprovedByUserId = "approved_by_user_id",
  /** column name */
  Category = "category",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  RequestInfo = "request_info",
  /** column name */
  RequestedVendorId = "requested_vendor_id",
  /** column name */
  RequestingCompanyId = "requesting_company_id",
  /** column name */
  RequestingUserId = "requesting_user_id",
  /** column name */
  ReviewedByUserId = "reviewed_by_user_id",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "vendor_change_requests" */
export type VendorChangeRequestsSetInput = {
  approved_at?: Maybe<Scalars["timestamptz"]>;
  approved_by_user_id?: Maybe<Scalars["uuid"]>;
  category?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  request_info?: Maybe<Scalars["json"]>;
  requested_vendor_id?: Maybe<Scalars["uuid"]>;
  requesting_company_id?: Maybe<Scalars["uuid"]>;
  requesting_user_id?: Maybe<Scalars["uuid"]>;
  reviewed_by_user_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "vendor_change_requests" */
export enum VendorChangeRequestsUpdateColumn {
  /** column name */
  ApprovedAt = "approved_at",
  /** column name */
  ApprovedByUserId = "approved_by_user_id",
  /** column name */
  Category = "category",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  RequestInfo = "request_info",
  /** column name */
  RequestedVendorId = "requested_vendor_id",
  /** column name */
  RequestingCompanyId = "requesting_company_id",
  /** column name */
  RequestingUserId = "requesting_user_id",
  /** column name */
  ReviewedByUserId = "reviewed_by_user_id",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
}

/** columns and relationships of "vendors" */
export type Vendors = {
  address?: Maybe<Scalars["String"]>;
  /** An array relationship */
  bank_accounts: Array<BankAccounts>;
  /** An aggregate relationship */
  bank_accounts_aggregate: BankAccountsAggregate;
  city?: Maybe<Scalars["String"]>;
  /** An array relationship */
  company_deliveries: Array<CompanyDeliveries>;
  /** An aggregate relationship */
  company_deliveries_aggregate: CompanyDeliveriesAggregate;
  company_settings_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  company_vendor_partnerships: Array<CompanyVendorPartnerships>;
  /** An aggregate relationship */
  company_vendor_partnerships_aggregate: CompanyVendorPartnershipsAggregate;
  contract_id?: Maybe<Scalars["uuid"]>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  employer_identification_number?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
  /** An array relationship */
  licenses: Array<CompanyLicenses>;
  /** An aggregate relationship */
  licenses_aggregate: CompanyLicensesAggregate;
  name?: Maybe<Scalars["String"]>;
  needs_balance_recomputed?: Maybe<Scalars["Boolean"]>;
  /** An object relationship */
  parent_company?: Maybe<ParentCompanies>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  phone_number?: Maybe<Scalars["String"]>;
  /** An object relationship */
  settings?: Maybe<CompanySettings>;
  state?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: UsersAggregate;
  zip_code?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "vendors" */
export type VendorsBankAccountsArgs = {
  distinct_on?: Maybe<Array<BankAccountsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BankAccountsOrderBy>>;
  where?: Maybe<BankAccountsBoolExp>;
};

/** columns and relationships of "vendors" */
export type VendorsBankAccountsAggregateArgs = {
  distinct_on?: Maybe<Array<BankAccountsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<BankAccountsOrderBy>>;
  where?: Maybe<BankAccountsBoolExp>;
};

/** columns and relationships of "vendors" */
export type VendorsCompanyDeliveriesArgs = {
  distinct_on?: Maybe<Array<CompanyDeliveriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyDeliveriesOrderBy>>;
  where?: Maybe<CompanyDeliveriesBoolExp>;
};

/** columns and relationships of "vendors" */
export type VendorsCompanyDeliveriesAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyDeliveriesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyDeliveriesOrderBy>>;
  where?: Maybe<CompanyDeliveriesBoolExp>;
};

/** columns and relationships of "vendors" */
export type VendorsCompanyVendorPartnershipsArgs = {
  distinct_on?: Maybe<Array<CompanyVendorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorPartnershipsOrderBy>>;
  where?: Maybe<CompanyVendorPartnershipsBoolExp>;
};

/** columns and relationships of "vendors" */
export type VendorsCompanyVendorPartnershipsAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyVendorPartnershipsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyVendorPartnershipsOrderBy>>;
  where?: Maybe<CompanyVendorPartnershipsBoolExp>;
};

/** columns and relationships of "vendors" */
export type VendorsLicensesArgs = {
  distinct_on?: Maybe<Array<CompanyLicensesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyLicensesOrderBy>>;
  where?: Maybe<CompanyLicensesBoolExp>;
};

/** columns and relationships of "vendors" */
export type VendorsLicensesAggregateArgs = {
  distinct_on?: Maybe<Array<CompanyLicensesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CompanyLicensesOrderBy>>;
  where?: Maybe<CompanyLicensesBoolExp>;
};

/** columns and relationships of "vendors" */
export type VendorsUsersArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

/** columns and relationships of "vendors" */
export type VendorsUsersAggregateArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

/** aggregated selection of "vendors" */
export type VendorsAggregate = {
  aggregate?: Maybe<VendorsAggregateFields>;
  nodes: Array<Vendors>;
};

/** aggregate fields of "vendors" */
export type VendorsAggregateFields = {
  avg?: Maybe<VendorsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<VendorsMaxFields>;
  min?: Maybe<VendorsMinFields>;
  stddev?: Maybe<VendorsStddevFields>;
  stddev_pop?: Maybe<VendorsStddevPopFields>;
  stddev_samp?: Maybe<VendorsStddevSampFields>;
  sum?: Maybe<VendorsSumFields>;
  var_pop?: Maybe<VendorsVarPopFields>;
  var_samp?: Maybe<VendorsVarSampFields>;
  variance?: Maybe<VendorsVarianceFields>;
};

/** aggregate fields of "vendors" */
export type VendorsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<VendorsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type VendorsAvgFields = {
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "vendors". All fields are combined with a logical 'AND'. */
export type VendorsBoolExp = {
  _and?: Maybe<Array<VendorsBoolExp>>;
  _not?: Maybe<VendorsBoolExp>;
  _or?: Maybe<Array<VendorsBoolExp>>;
  address?: Maybe<StringComparisonExp>;
  bank_accounts?: Maybe<BankAccountsBoolExp>;
  city?: Maybe<StringComparisonExp>;
  company_deliveries?: Maybe<CompanyDeliveriesBoolExp>;
  company_settings_id?: Maybe<UuidComparisonExp>;
  company_vendor_partnerships?: Maybe<CompanyVendorPartnershipsBoolExp>;
  contract_id?: Maybe<UuidComparisonExp>;
  country?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  dba_name?: Maybe<StringComparisonExp>;
  employer_identification_number?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  identifier?: Maybe<StringComparisonExp>;
  is_cannabis?: Maybe<BooleanComparisonExp>;
  latest_loan_identifier?: Maybe<IntComparisonExp>;
  licenses?: Maybe<CompanyLicensesBoolExp>;
  name?: Maybe<StringComparisonExp>;
  needs_balance_recomputed?: Maybe<BooleanComparisonExp>;
  parent_company?: Maybe<ParentCompaniesBoolExp>;
  parent_company_id?: Maybe<UuidComparisonExp>;
  phone_number?: Maybe<StringComparisonExp>;
  settings?: Maybe<CompanySettingsBoolExp>;
  state?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  users?: Maybe<UsersBoolExp>;
  zip_code?: Maybe<StringComparisonExp>;
};

/** input type for incrementing numeric columns in table "vendors" */
export type VendorsIncInput = {
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "vendors" */
export type VendorsInsertInput = {
  address?: Maybe<Scalars["String"]>;
  bank_accounts?: Maybe<BankAccountsArrRelInsertInput>;
  city?: Maybe<Scalars["String"]>;
  company_deliveries?: Maybe<CompanyDeliveriesArrRelInsertInput>;
  company_settings_id?: Maybe<Scalars["uuid"]>;
  company_vendor_partnerships?: Maybe<CompanyVendorPartnershipsArrRelInsertInput>;
  contract_id?: Maybe<Scalars["uuid"]>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  employer_identification_number?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
  licenses?: Maybe<CompanyLicensesArrRelInsertInput>;
  name?: Maybe<Scalars["String"]>;
  needs_balance_recomputed?: Maybe<Scalars["Boolean"]>;
  parent_company?: Maybe<ParentCompaniesObjRelInsertInput>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  phone_number?: Maybe<Scalars["String"]>;
  settings?: Maybe<CompanySettingsObjRelInsertInput>;
  state?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  users?: Maybe<UsersArrRelInsertInput>;
  zip_code?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type VendorsMaxFields = {
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company_settings_id?: Maybe<Scalars["uuid"]>;
  contract_id?: Maybe<Scalars["uuid"]>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  employer_identification_number?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  phone_number?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  zip_code?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type VendorsMinFields = {
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company_settings_id?: Maybe<Scalars["uuid"]>;
  contract_id?: Maybe<Scalars["uuid"]>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  employer_identification_number?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  phone_number?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  zip_code?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "vendors" */
export type VendorsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Vendors>;
};

/** input type for inserting object relation for remote table "vendors" */
export type VendorsObjRelInsertInput = {
  data: VendorsInsertInput;
};

/** Ordering options when selecting data from "vendors". */
export type VendorsOrderBy = {
  address?: Maybe<OrderBy>;
  bank_accounts_aggregate?: Maybe<BankAccountsAggregateOrderBy>;
  city?: Maybe<OrderBy>;
  company_deliveries_aggregate?: Maybe<CompanyDeliveriesAggregateOrderBy>;
  company_settings_id?: Maybe<OrderBy>;
  company_vendor_partnerships_aggregate?: Maybe<CompanyVendorPartnershipsAggregateOrderBy>;
  contract_id?: Maybe<OrderBy>;
  country?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  dba_name?: Maybe<OrderBy>;
  employer_identification_number?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  identifier?: Maybe<OrderBy>;
  is_cannabis?: Maybe<OrderBy>;
  latest_loan_identifier?: Maybe<OrderBy>;
  licenses_aggregate?: Maybe<CompanyLicensesAggregateOrderBy>;
  name?: Maybe<OrderBy>;
  needs_balance_recomputed?: Maybe<OrderBy>;
  parent_company?: Maybe<ParentCompaniesOrderBy>;
  parent_company_id?: Maybe<OrderBy>;
  phone_number?: Maybe<OrderBy>;
  settings?: Maybe<CompanySettingsOrderBy>;
  state?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  users_aggregate?: Maybe<UsersAggregateOrderBy>;
  zip_code?: Maybe<OrderBy>;
};

/** select columns of table "vendors" */
export enum VendorsSelectColumn {
  /** column name */
  Address = "address",
  /** column name */
  City = "city",
  /** column name */
  CompanySettingsId = "company_settings_id",
  /** column name */
  ContractId = "contract_id",
  /** column name */
  Country = "country",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DbaName = "dba_name",
  /** column name */
  EmployerIdentificationNumber = "employer_identification_number",
  /** column name */
  Id = "id",
  /** column name */
  Identifier = "identifier",
  /** column name */
  IsCannabis = "is_cannabis",
  /** column name */
  LatestLoanIdentifier = "latest_loan_identifier",
  /** column name */
  Name = "name",
  /** column name */
  NeedsBalanceRecomputed = "needs_balance_recomputed",
  /** column name */
  ParentCompanyId = "parent_company_id",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  State = "state",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  ZipCode = "zip_code",
}

/** input type for updating data in table "vendors" */
export type VendorsSetInput = {
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company_settings_id?: Maybe<Scalars["uuid"]>;
  contract_id?: Maybe<Scalars["uuid"]>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dba_name?: Maybe<Scalars["String"]>;
  employer_identification_number?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["String"]>;
  is_cannabis?: Maybe<Scalars["Boolean"]>;
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  needs_balance_recomputed?: Maybe<Scalars["Boolean"]>;
  parent_company_id?: Maybe<Scalars["uuid"]>;
  phone_number?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  zip_code?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type VendorsStddevFields = {
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type VendorsStddevPopFields = {
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type VendorsStddevSampFields = {
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type VendorsSumFields = {
  latest_loan_identifier?: Maybe<Scalars["Int"]>;
};

/** aggregate var_pop on columns */
export type VendorsVarPopFields = {
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type VendorsVarSampFields = {
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type VendorsVarianceFields = {
  latest_loan_identifier?: Maybe<Scalars["Float"]>;
};

export type GetAdvancesSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GetAdvancesSubscription = {
  payments: Array<
    Pick<Payments, "id"> & {
      company: Pick<Companies, "id" | "name">;
      settled_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
    } & PaymentFragment
  >;
};

export type GetAdvancesByPaymentDateQueryVariables = Exact<{
  date: Scalars["date"];
}>;

export type GetAdvancesByPaymentDateQuery = {
  payments: Array<
    Pick<Payments, "id"> & {
      company: Pick<Companies, "id"> & {
        financial_summaries: Array<
          Pick<FinancialSummaries, "id" | "product_type">
        >;
      };
      settled_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
    } & PaymentFragment
  >;
};

export type GetAdvancesByMethodAndPaymentDateQueryVariables = Exact<{
  method: Scalars["String"];
  date: Scalars["date"];
}>;

export type GetAdvancesByMethodAndPaymentDateQuery = {
  payments: Array<
    Pick<Payments, "id"> & {
      company_bank_account?: Maybe<
        Pick<BankAccounts, "id" | "us_state"> & BankAccountFragment
      >;
      recipient_bank_account?: Maybe<
        Pick<BankAccounts, "id"> & BankAccountFragment
      >;
    } & PaymentFragment
  >;
};

export type GetAsyncPipelinesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAsyncPipelinesQuery = {
  async_pipelines: Array<
    Pick<AsyncPipelines, "id"> & AsyncPipelineLimitedFragment
  >;
};

export type GetAsyncPipelineQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetAsyncPipelineQuery = {
  async_pipelines_by_pk?: Maybe<
    Pick<AsyncPipelines, "id"> & AsyncPipelineFragment
  >;
};

export type GetBespokeBankAccountsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBespokeBankAccountsQuery = {
  bank_accounts: Array<BankAccountFragment>;
};

export type GetBankAccountsByCompanyIdQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type GetBankAccountsByCompanyIdQuery = {
  bank_accounts: Array<BankAccountLimitedFragment>;
};

export type GetAdvancesBankAccountsForCustomerQueryVariables = Exact<{
  customerId: Scalars["uuid"];
  vendorId?: Maybe<Scalars["uuid"]>;
}>;

export type GetAdvancesBankAccountsForCustomerQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id" | "name"> & {
      company_vendor_partnerships: Array<
        Pick<CompanyVendorPartnerships, "id"> & {
          vendor?: Maybe<Pick<Vendors, "id" | "name">>;
          vendor_bank_account?: Maybe<
            Pick<BankAccounts, "id"> & BankAccountFragment
          >;
        }
      >;
      settings?: Maybe<
        Pick<CompanySettings, "id"> & {
          advances_bank_account?: Maybe<
            Pick<BankAccounts, "id"> & BankAccountFragment
          >;
        }
      >;
    }
  >;
};

export type BankAccountsForTransferQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type BankAccountsForTransferQuery = {
  bank_accounts: Array<BankAccountFragment>;
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      settings?: Maybe<
        Pick<CompanySettings, "id"> & {
          collections_bespoke_bank_account?: Maybe<BankAccountFragment>;
        }
      >;
    }
  >;
};

export type GetBespokeCatalogSkusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBespokeCatalogSkusQuery = {
  bespoke_catalog_skus: Array<
    Pick<BespokeCatalogSkus, "id"> & BespokeCatalogSkuFragment
  >;
};

export type GetBespokeCatalogSkuGroupsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBespokeCatalogSkuGroupsQuery = {
  bespoke_catalog_sku_groups: Array<
    Pick<BespokeCatalogSkuGroups, "id"> & BespokeCatalogSkuGroupFragment
  >;
};

export type GetBespokeCatalogSkuGroupsByIdsQueryVariables = Exact<{
  ids: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type GetBespokeCatalogSkuGroupsByIdsQuery = {
  bespoke_catalog_sku_groups: Array<
    Pick<BespokeCatalogSkuGroups, "id"> & BespokeCatalogSkuGroupFragment
  >;
};

export type GetBespokeCatalogBrandsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBespokeCatalogBrandsQuery = {
  bespoke_catalog_brands: Array<
    Pick<BespokeCatalogBrands, "id"> & BespokeCatalogBrandFragment
  >;
};

export type GetMetrcToBespokeCatalogSkusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMetrcToBespokeCatalogSkusQuery = {
  metrc_to_bespoke_catalog_skus: Array<
    Pick<MetrcToBespokeCatalogSkus, "id"> & MetrcToBespokeCatalogSkuFragment
  >;
};

export type GetMetrcToBespokeCatalogSkusByProductNameQueryVariables = Exact<{
  search_query: Scalars["String"];
  limit?: Scalars["Int"];
}>;

export type GetMetrcToBespokeCatalogSkusByProductNameQuery = {
  metrc_to_bespoke_catalog_skus: Array<
    Pick<MetrcToBespokeCatalogSkus, "id"> & MetrcToBespokeCatalogSkuFragment
  >;
};

export type GetBespokeCatalogSkusBySkuNameQueryVariables = Exact<{
  search_prefix: Scalars["String"];
}>;

export type GetBespokeCatalogSkusBySkuNameQuery = {
  bespoke_catalog_skus: Array<
    Pick<BespokeCatalogSkus, "id"> & BespokeCatalogSkuFragment
  >;
};

export type GetBespokeCatalogSkuGroupsBySkuGroupNameQueryVariables = Exact<{
  search_prefix: Scalars["String"];
}>;

export type GetBespokeCatalogSkuGroupsBySkuGroupNameQuery = {
  bespoke_catalog_sku_groups: Array<
    Pick<BespokeCatalogSkuGroups, "id"> & BespokeCatalogSkuGroupFragment
  >;
};

export type GetBespokeCatalogBrandsByBrandNameQueryVariables = Exact<{
  search_prefix: Scalars["String"];
}>;

export type GetBespokeCatalogBrandsByBrandNameQuery = {
  bespoke_catalog_brands: Array<
    Pick<BespokeCatalogBrands, "id"> & BespokeCatalogBrandFragment
  >;
};

export type GetParentCompaniesByNameQueryVariables = Exact<{
  search_prefix: Scalars["String"];
}>;

export type GetParentCompaniesByNameQuery = {
  parent_companies: Array<Pick<ParentCompanies, "id"> & ParentCompanyFragment>;
};

export type BlazePreapprovalFragment = Pick<
  BlazePreapprovals,
  | "id"
  | "external_blaze_company_id"
  | "external_blaze_shop_id"
  | "max_credit_limit"
  | "annual_interest_rate"
  | "expiration_date"
>;

export type GetCompanyFacilityQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetCompanyFacilityQuery = {
  company_facilities_by_pk?: Maybe<
    Pick<CompanyFacilities, "id"> & CompanyFacilityFragment
  >;
};

export type GetCompanyLicenseQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetCompanyLicenseQuery = {
  company_licenses_by_pk?: Maybe<
    Pick<CompanyLicenses, "id"> & CompanyLicenseFragment
  >;
};

export type GetCompanyLicenseRelationsByCompanyIdQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetCompanyLicenseRelationsByCompanyIdQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      company_facilities: Array<
        Pick<CompanyFacilities, "id"> & CompanyFacilityFragment
      >;
    }
  >;
};

export type GetCompanyLicensesByLicenseNumberQueryVariables = Exact<{
  license_number: Scalars["String"];
}>;

export type GetCompanyLicensesByLicenseNumberQuery = {
  company_licenses: Array<
    Pick<CompanyLicenses, "id"> & CompanyLicenseLimitedFragment
  >;
};

export type GetCompanyLicensesByLicenseNumbersQueryVariables = Exact<{
  license_numbers: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GetCompanyLicensesByLicenseNumbersQuery = {
  company_licenses: Array<Pick<CompanyLicenses, "id"> & CompanyLicenseFragment>;
};

export type GetAllCompanyLicensesQueryVariables = Exact<{
  license_number: Scalars["String"];
}>;

export type GetAllCompanyLicensesQuery = {
  company_licenses: Array<CompanyLicenseFragment>;
};

export type GetCompanyLicensesForVendorOnboardingQueryVariables = Exact<{
  license_number_search: Scalars["String"];
}>;

export type GetCompanyLicensesForVendorOnboardingQuery = {
  company_licenses: Array<CompanyLicenseLimitedAnonymousFragment>;
};

export type GetCustomerFinancialSummaryByDateSubscriptionVariables = Exact<{
  company_id: Scalars["uuid"];
  date: Scalars["date"];
}>;

export type GetCustomerFinancialSummaryByDateSubscription = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      financial_summaries: Array<
        Pick<FinancialSummaries, "id"> & FinancialSummaryFragment
      >;
    }
  >;
};

export type GetCustomerOverviewQueryVariables = Exact<{
  company_id: Scalars["uuid"];
  loan_type?: Maybe<LoanTypeEnum>;
}>;

export type GetCustomerOverviewQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id" | "name"> & {
      contract?: Maybe<Pick<Contracts, "id"> & ContractFragment>;
      outstanding_loans: Array<
        Pick<Loans, "id"> & LoanLimitedFragment & LoanArtifactLimitedFragment
      >;
      parent_company?: Maybe<
        Pick<ParentCompanies, "id" | "name"> & {
          companies: Array<Pick<Companies, "id">>;
        }
      >;
      pending_payments: Array<Pick<Payments, "id"> & PaymentLimitedFragment>;
      settings?: Maybe<
        Pick<CompanySettings, "id"> & CompanySettingsLimitedFragment
      >;
      financial_summaries: Array<
        Pick<
          FinancialSummaries,
          "id" | "available_limit" | "date" | "needs_recompute"
        >
      >;
    }
  >;
};

export type GetCustomerAccountQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetCustomerAccountQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      fee_payments: Array<
        Pick<Payments, "id"> & {
          transactions: Array<
            Pick<Transactions, "id"> & {
              loan?: Maybe<Pick<Loans, "id"> & LoanFragment>;
            } & TransactionFragment
          >;
        } & PaymentLimitedFragment
      >;
      pending_payments: Array<Pick<Payments, "id"> & PaymentLimitedFragment>;
    }
  >;
};

export type GetCompanyForBankCompanyPageQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetCompanyForBankCompanyPageQuery = {
  companies_by_pk?: Maybe<
    Pick<
      Companies,
      | "id"
      | "name"
      | "is_customer"
      | "is_payor"
      | "is_vendor"
      | "surveillance_status"
    > & {
      contract?: Maybe<
        Pick<Contracts, "id" | "product_type" | "start_date" | "terminated_at">
      >;
      most_recent_surveillance_result: Array<
        Pick<CustomerSurveillanceResults, "id"> &
          CustomerSurveillanceResultFragment
      >;
    }
  >;
};

export type GetParentCompanyForBankParentCompanyPageQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetParentCompanyForBankParentCompanyPageQuery = {
  parent_companies_by_pk?: Maybe<
    Pick<ParentCompanies, "id" | "name"> & {
      companies: Array<Pick<Companies, "id"> & CompanyFragment>;
      users: Array<Pick<Users, "id"> & UserFragment>;
    }
  >;
};

export type GetCompanyForVendorOnboardingQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetCompanyForVendorOnboardingQuery = {
  companies_by_pk?: Maybe<Pick<Companies, "id" | "name">>;
};

export type GetCustomerOldestContractQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetCustomerOldestContractQuery = {
  oldest_contract: Array<Pick<Contracts, "id"> & ContractFragment>;
};

export type GetFinancialSummariesByCompanyIdQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type GetFinancialSummariesByCompanyIdQuery = {
  financial_summaries: Array<
    Pick<FinancialSummaries, "id"> & {
      company: Pick<Companies, "id" | "name" | "debt_facility_status">;
    } & FinancialSummaryFragment
  >;
};

export type GetMostRecentFinancialSummaryAndContractByCompanyIdQueryVariables =
  Exact<{
    companyId: Scalars["uuid"];
  }>;

export type GetMostRecentFinancialSummaryAndContractByCompanyIdQuery = {
  financial_summaries: Array<
    Pick<FinancialSummaries, "id" | "product_type"> & FinancialSummaryFragment
  >;
  contracts: Array<Pick<Contracts, "id"> & ContractFragment>;
};

export type GetFinancialSummariesByDateQueryVariables = Exact<{
  date: Scalars["date"];
}>;

export type GetFinancialSummariesByDateQuery = {
  financial_summaries: Array<
    Pick<FinancialSummaries, "id"> & {
      company: Pick<Companies, "id" | "name">;
    } & FinancialSummaryFragment
  >;
};

export type GetActiveFinancialSummariesByDateQueryVariables = Exact<{
  date: Scalars["date"];
}>;

export type GetActiveFinancialSummariesByDateQuery = {
  financial_summaries: Array<
    Pick<FinancialSummaries, "id"> & {
      company: Pick<Companies, "id" | "name" | "debt_facility_status">;
    } & FinancialSummaryFragment
  >;
};

export type GetFinancialSummariesAndLoansByCompanyIdQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type GetFinancialSummariesAndLoansByCompanyIdQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id" | "identifier" | "name"> & {
      financial_summaries: Array<
        Pick<FinancialSummaries, "id"> & FinancialSummaryWithLoansInfoFragment
      >;
      loans: Array<Pick<Loans, "id" | "identifier" | "adjusted_maturity_date">>;
      contracts: Array<Pick<Contracts, "id"> & ContractFragment>;
    }
  >;
};

export type GetCustomersSurveillanceSubscriptionVariables = Exact<{
  target_date?: Maybe<Scalars["date"]>;
}>;

export type GetCustomersSurveillanceSubscription = {
  customers: Array<Pick<Companies, "id"> & CustomerSurveillanceFragment>;
};

export type GetCustomersSurveillanceByCompanyIdQueryVariables = Exact<{
  target_date?: Maybe<Scalars["date"]>;
  id: Scalars["uuid"];
}>;

export type GetCustomersSurveillanceByCompanyIdQuery = {
  customer?: Maybe<
    Pick<Companies, "id"> & {
      settings?: Maybe<CompanyDealOwnersFragment>;
    } & CustomerSurveillanceFragment
  >;
};

export type GetSurveillanceResultByIdQueryVariables = Exact<{
  surveillance_result_id: Scalars["uuid"];
}>;

export type GetSurveillanceResultByIdQuery = {
  surveillance_result?: Maybe<
    Pick<CustomerSurveillanceResults, "id"> & {
      submitting_user: Pick<Users, "id" | "first_name" | "last_name">;
    } & CustomerSurveillanceResultFragment
  >;
};

export type GetSurveillanceResultByCompanyIdQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetSurveillanceResultByCompanyIdQuery = {
  customer_surveillance_results: Array<
    Pick<CustomerSurveillanceResults, "id"> & CustomerSurveillanceResultFragment
  >;
};

export type GetOpenLoansByDebtFacilityStatusesQueryVariables = Exact<{
  statuses?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
  target_date: Scalars["date"];
}>;

export type GetOpenLoansByDebtFacilityStatusesQuery = {
  companies: Array<
    Pick<Companies, "id"> & CompanyForOpenLoansByDebtFacilityStatusesFragment
  >;
};

export type GetOpenLoansByDebtFacilityIdQueryVariables = Exact<{
  statuses?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
  target_facility_ids?: Maybe<Array<Scalars["uuid"]> | Scalars["uuid"]>;
  target_date: Scalars["date"];
}>;

export type GetOpenLoansByDebtFacilityIdQuery = {
  companies: Array<Pick<Companies, "id"> & CompanyForByDebtFacilityIdFragment>;
};

export type GetReportLoansByDebtFacilityIdQueryVariables = Exact<{
  debt_facility_statuses?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
  other_statuses?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
  target_facility_ids?: Maybe<Array<Scalars["uuid"]> | Scalars["uuid"]>;
  target_date: Scalars["date"];
}>;

export type GetReportLoansByDebtFacilityIdQuery = {
  companies: Array<
    Pick<Companies, "id"> & CompanyForDebtFacilityReportFragment
  >;
};

export type GetDebtFacilityLoansByIdQueryVariables = Exact<{
  loan_ids?: Maybe<Array<Scalars["uuid"]> | Scalars["uuid"]>;
  target_date: Scalars["date"];
}>;

export type GetDebtFacilityLoansByIdQuery = {
  companies: Array<
    Pick<Companies, "id"> & CompanyForDebtFacilityLoansByIdFragment
  >;
};

export type GetDebtFacilitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetDebtFacilitiesQuery = {
  debt_facilities: Array<Pick<DebtFacilities, "id"> & DebtFacilityFragment>;
};

export type GetDebtFacilityCurrentCapacitySubscriptionVariables = Exact<{
  target_facility_id: Scalars["uuid"];
}>;

export type GetDebtFacilityCurrentCapacitySubscription = {
  debt_facilities: Array<Pick<DebtFacilities, "id"> & DebtFacilityFragment>;
};

export type GetDebtFacilityEventsByLoanReportIdQueryVariables = Exact<{
  loan_report_id: Scalars["uuid"];
}>;

export type GetDebtFacilityEventsByLoanReportIdQuery = {
  debt_facility_events: Array<DebtFacilityEventFragment>;
};

export type GetEbbaApplicationQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetEbbaApplicationQuery = {
  ebba_applications_by_pk?: Maybe<
    Pick<EbbaApplications, "id" | "bank_note"> & {
      approved_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
      rejected_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
      company: Pick<Companies, "id" | "name">;
      ebba_application_files: Array<EbbaApplicationFileFragment>;
    } & EbbaApplicationFragment
  >;
};

export type GetEbbaApplicationsByCompanyIdQueryVariables = Exact<{
  company_id: Scalars["uuid"];
  category: Scalars["String"];
}>;

export type GetEbbaApplicationsByCompanyIdQuery = {
  ebba_applications: Array<
    Pick<EbbaApplications, "id"> & {
      company: Pick<Companies, "id" | "name"> & {
        contract?: Maybe<Pick<Contracts, "id" | "product_type">>;
      };
    } & EbbaApplicationFragment
  >;
};

export type GetOpenEbbaApplicationsCountForBankSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetOpenEbbaApplicationsCountForBankSubscription = {
  ebba_applications: Array<Pick<EbbaApplications, "id" | "category">>;
};

export type GetOpenEbbaApplicationsByCategoryQueryVariables = Exact<{
  category: Scalars["String"];
}>;

export type GetOpenEbbaApplicationsByCategoryQuery = {
  ebba_applications: Array<
    Pick<EbbaApplications, "id" | "bank_note" | "rejected_at"> & {
      rejected_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
      company: Pick<Companies, "id" | "name"> & {
        contract?: Maybe<Pick<Contracts, "id" | "product_type">>;
      };
      ebba_application_files: Array<
        Pick<EbbaApplicationFiles, "ebba_application_id" | "file_id">
      >;
    } & EbbaApplicationFragment
  >;
};

export type GetClosedEbbaApplicationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetClosedEbbaApplicationsQuery = {
  ebba_applications: Array<
    Pick<EbbaApplications, "id" | "bank_note"> & {
      approved_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
      company: Pick<Companies, "id" | "name"> & {
        contract?: Maybe<Pick<Contracts, "id" | "product_type">>;
      };
      ebba_application_files: Array<
        Pick<EbbaApplicationFiles, "ebba_application_id" | "file_id">
      >;
    } & EbbaApplicationFragment
  >;
};

export type GetClosedEbbaApplicationsByCompanyIdQueryVariables = Exact<{
  company_id: Scalars["uuid"];
  financial_report_amount: Scalars["Int"];
}>;

export type GetClosedEbbaApplicationsByCompanyIdQuery = {
  borrowing_base: Array<EbbaApplicationFragment>;
  financial_reports: Array<EbbaApplicationFragment>;
};

export type PayorsByPartnerCompanyQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type PayorsByPartnerCompanyQuery = {
  payors: Array<
    Pick<Payors, "id"> & {
      company_payor_partnerships: Array<
        Pick<CompanyPayorPartnerships, "id" | "approved_at">
      >;
    } & PayorLimitedFragment
  >;
};

export type GetInvoiceByIdQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetInvoiceByIdQuery = {
  invoices_by_pk?: Maybe<
    Pick<Invoices, "id"> & {
      company: Pick<Companies, "id"> & {
        contract?: Maybe<Pick<Contracts, "id" | "product_type">>;
      };
      loans: Array<Pick<Loans, "id"> & LoanLimitedFragment>;
      invoice_files: Array<InvoiceFileFragment>;
    } & InvoiceFragment
  >;
};

export type GetInvoiceForReviewQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetInvoiceForReviewQuery = {
  invoices_by_pk?: Maybe<
    Pick<
      Invoices,
      | "id"
      | "company_id"
      | "payor_id"
      | "invoice_number"
      | "invoice_date"
      | "invoice_due_date"
      | "subtotal_amount"
      | "total_amount"
      | "taxes_amount"
      | "is_cannabis"
      | "status"
      | "created_at"
      | "payment_requested_at"
      | "payment_confirmed_at"
      | "payment_rejected_at"
      | "payment_rejection_note"
    > & {
      invoice_files: Array<
        Pick<InvoiceFiles, "invoice_id" | "file_id"> & InvoiceFileFragment
      >;
      company: Pick<Companies, "id"> & CompanyLimitedFragment;
      payor?: Maybe<
        Pick<Payors, "id"> & {
          settings?: Maybe<
            Pick<CompanySettings, "id"> & {
              collections_bespoke_bank_account?: Maybe<
                Pick<BankAccounts, "id"> & BankAccountLimitedFragment
              >;
            }
          >;
        } & PayorLimitedFragment
      >;
    }
  >;
};

export type GetInvoicesSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GetInvoicesSubscription = {
  invoices: Array<
    Pick<Invoices, "id"> & {
      company: Pick<Companies, "id"> & {
        contract?: Maybe<Pick<Contracts, "id" | "company_id" | "product_type">>;
      };
    } & InvoiceFragment
  >;
};

export type GetAllUnconfirmedInvoicesSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllUnconfirmedInvoicesSubscription = {
  invoices: Array<
    Pick<Invoices, "id"> & {
      company: Pick<Companies, "id"> & {
        contract?: Maybe<Pick<Contracts, "id" | "company_id" | "product_type">>;
      };
    } & InvoiceFragment
  >;
};

export type GetAllConfirmedInvoicesSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllConfirmedInvoicesSubscription = {
  invoices: Array<
    Pick<Invoices, "id"> & {
      company: Pick<Companies, "id"> & {
        contract?: Maybe<Pick<Contracts, "id" | "company_id" | "product_type">>;
      };
    } & InvoiceFragment
  >;
};

export type GetInvoicesByCompanyIdQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetInvoicesByCompanyIdQuery = { invoices: Array<InvoiceFragment> };

export type GetOpenInvoicesByCompanyIdQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetOpenInvoicesByCompanyIdQuery = {
  invoices: Array<InvoiceLimitedFragment>;
};

export type GetClosedInvoicesByCompanyIdQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetClosedInvoicesByCompanyIdQuery = {
  invoices: Array<InvoiceLimitedFragment>;
};

export type GetApprovedInvoicesByCompanyIdQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type GetApprovedInvoicesByCompanyIdQuery = {
  invoices: Array<InvoiceFragment>;
};

export type AddLineOfCreditMutationVariables = Exact<{
  lineOfCredit: LineOfCreditsInsertInput;
}>;

export type AddLineOfCreditMutation = {
  insert_line_of_credits_one?: Maybe<
    Pick<LineOfCredits, "id"> & LineOfCreditFragment
  >;
};

export type UpdateLineOfCreditAndLoanMutationVariables = Exact<{
  lineOfCreditId: Scalars["uuid"];
  lineOfCredit: LineOfCreditsSetInput;
  loanId: Scalars["uuid"];
  loan: LoansSetInput;
}>;

export type UpdateLineOfCreditAndLoanMutation = {
  update_line_of_credits_by_pk?: Maybe<
    Pick<LineOfCredits, "id"> & LineOfCreditFragment
  >;
  update_loans_by_pk?: Maybe<Pick<Loans, "id"> & LoanLimitedFragment>;
};

export type GetPurchaseOrdersForIdsQueryVariables = Exact<{
  purchaseOrderIds?: Maybe<Array<Scalars["uuid"]> | Scalars["uuid"]>;
}>;

export type GetPurchaseOrdersForIdsQuery = {
  purchase_orders: Array<
    Pick<PurchaseOrders, "id"> & {
      loans: Array<Pick<Loans, "id"> & LoanFragment>;
    } & PurchaseOrderFragment
  >;
};

export type GetPurchaseOrdersForIdsLimitedQueryVariables = Exact<{
  purchaseOrderIds?: Maybe<Array<Scalars["uuid"]> | Scalars["uuid"]>;
}>;

export type GetPurchaseOrdersForIdsLimitedQuery = {
  purchase_orders: Array<
    Pick<PurchaseOrders, "id"> & {
      loans: Array<Pick<Loans, "id"> & LoanLimitedFragment>;
    } & PurchaseOrderLimitedNewFragment
  >;
};

export type GetLoanQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetLoanQuery = { loans_by_pk?: Maybe<LoanFragment> };

export type GetLoanForCustomerQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetLoanForCustomerQuery = {
  loans_by_pk?: Maybe<LoanLimitedFragment>;
};

export type GetLoanWithArtifactForCustomerQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetLoanWithArtifactForCustomerQuery = {
  loans_by_pk?: Maybe<LoanLimitedFragment & LoanArtifactLimitedFragment>;
};

export type GetLoanWithArtifactForBankQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetLoanWithArtifactForBankQuery = {
  loans_by_pk?: Maybe<
    {
      loan_report?: Maybe<
        { debt_facility?: Maybe<DebtFacilityFragment> } & LoanReportFragment
      >;
    } & LoanFragment &
      LoanArtifactFragment
  >;
};

export type GetTransactionsForLoanQueryVariables = Exact<{
  loan_id: Scalars["uuid"];
}>;

export type GetTransactionsForLoanQuery = {
  transactions: Array<Pick<Transactions, "id"> & TransactionExtendedFragment>;
};

export type AddLoanMutationVariables = Exact<{
  loan: LoansInsertInput;
}>;

export type AddLoanMutation = { insert_loans_one?: Maybe<LoanLimitedFragment> };

export type UpdateLoanMutationVariables = Exact<{
  id: Scalars["uuid"];
  loan: LoansSetInput;
}>;

export type UpdateLoanMutation = {
  update_loans_by_pk?: Maybe<LoanLimitedFragment>;
};

export type GetLoanWithRelationshipsQueryVariables = Exact<{
  id: Scalars["uuid"];
  is_bank_user: Scalars["Boolean"];
}>;

export type GetLoanWithRelationshipsQuery = {
  loans_by_pk?: Maybe<Pick<Loans, "id"> & LoanWithRelationshipsFragment>;
};

export type GetLoansForBankSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetLoansForBankSubscription = {
  loans: Array<
    Pick<Loans, "id"> & {
      loan_report?: Maybe<LoanReportFragment>;
      company: Pick<Companies, "debt_facility_status">;
    } & LoanFragment &
      LoanArtifactFragment
  >;
};

export type GetFundedLoansForBankIncludingClosedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetFundedLoansForBankIncludingClosedSubscription = {
  loans: Array<
    Pick<Loans, "id"> & {
      loan_report?: Maybe<LoanReportFragment>;
    } & LoanFragment &
      LoanArtifactFragment
  >;
};

export type GetNotFundedLoansForBankSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetNotFundedLoansForBankSubscription = {
  loans: Array<
    Pick<Loans, "id"> & {
      company: {
        most_recent_surveillance_result: Array<
          Pick<CustomerSurveillanceResults, "id"> &
            CustomerSurveillanceResultFragment
        >;
        most_recent_financial_summary: Array<
          Pick<FinancialSummaries, "id" | "product_type">
        >;
      };
    } & LoanFragment &
      LoanArtifactFragment
  >;
};

export type GetFundedLoansForBankSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetFundedLoansForBankSubscription = {
  loans: Array<
    Pick<Loans, "id"> & {
      loan_report?: Maybe<LoanReportFragment>;
    } & LoanFragment &
      LoanArtifactFragment
  >;
};

export type GetClosedLoansForBankSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetClosedLoansForBankSubscription = {
  loans: Array<
    Pick<Loans, "id"> & {
      loan_report?: Maybe<LoanReportFragment>;
    } & LoanFragment &
      LoanArtifactFragment
  >;
};

export type GetActiveLoansForCompanyQueryVariables = Exact<{
  companyId: Scalars["uuid"];
  loanType?: Maybe<LoanTypeEnum>;
}>;

export type GetActiveLoansForCompanyQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      loans: Array<
        Pick<Loans, "id"> & LoanLimitedFragment & LoanArtifactLimitedFragment
      >;
    }
  >;
};

export type GetFinancingRequestsForCompanyQueryVariables = Exact<{
  companyId: Scalars["uuid"];
  loanType?: Maybe<LoanTypeEnum>;
}>;

export type GetFinancingRequestsForCompanyQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id" | "name"> & {
      loans: Array<
        Pick<Loans, "id"> & LoanLimitedFragment & LoanArtifactLimitedFragment
      >;
    }
  >;
};

export type GetClosedLoansForCompanyQueryVariables = Exact<{
  companyId: Scalars["uuid"];
  loanType?: Maybe<LoanTypeEnum>;
}>;

export type GetClosedLoansForCompanyQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      loans: Array<
        Pick<Loans, "id"> & LoanLimitedFragment & LoanArtifactLimitedFragment
      >;
    }
  >;
};

export type GetAllLoansForCompanyQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type GetAllLoansForCompanyQuery = {
  loans: Array<
    Pick<Loans, "id"> & LoanLimitedFragment & LoanArtifactLimitedFragment
  >;
};

export type GetLoansByArtifactIdQueryVariables = Exact<{
  artifact_id: Scalars["uuid"];
}>;

export type GetLoansByArtifactIdQuery = {
  loans: Array<
    Pick<Loans, "id"> & LoanLimitedFragment & LoanArtifactLimitedFragment
  >;
};

export type GetOpenFundedLoansByCompanyAndLoanTypeQueryVariables = Exact<{
  companyId: Scalars["uuid"];
  loanType: LoanTypeEnum;
}>;

export type GetOpenFundedLoansByCompanyAndLoanTypeQuery = {
  loans: Array<
    Pick<Loans, "id"> & LoanLimitedFragment & LoanArtifactLimitedFragment
  >;
};

export type GetLoansByLoanIdsQueryVariables = Exact<{
  loan_ids: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type GetLoansByLoanIdsQuery = {
  loans: Array<
    Pick<Loans, "id"> & {
      company: Pick<Companies, "id"> & {
        contract?: Maybe<Pick<Contracts, "id"> & ContractFragment>;
      } & CompanyFragment;
    } & LoanFragment &
      LoanArtifactFragment
  >;
};

export type GetLimitedLoansByLoanIdsQueryVariables = Exact<{
  loan_ids: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type GetLimitedLoansByLoanIdsQuery = {
  loans: Array<
    Pick<Loans, "id"> & {
      company: Pick<Companies, "id"> & {
        contract?: Maybe<Pick<Contracts, "id"> & ContractFragment>;
      } & CompanyFragment;
    } & LoanLimitedFragment &
      LoanArtifactLimitedFragment
  >;
};

export type GetClosedLimitedLoansByLoanIdsQueryVariables = Exact<{
  loan_ids: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type GetClosedLimitedLoansByLoanIdsQuery = {
  loans: Array<
    Pick<Loans, "id"> & LoanLimitedFragment & LoanArtifactLimitedFragment
  >;
};

export type GetMetrcApiKeysByCompanyIdQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type GetMetrcApiKeysByCompanyIdQuery = {
  metrc_api_keys: Array<Pick<MetrcApiKeys, "id"> & MetrcApiKeyLimitedFragment>;
};

export type GetMetrcMetadataByCompanyIdQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetMetrcMetadataByCompanyIdQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      company_facilities: Array<
        Pick<CompanyFacilities, "id"> & CompanyFacilityFragment
      >;
      licenses: Array<
        Pick<CompanyLicenses, "id"> & {
          company_facility?: Maybe<Pick<CompanyFacilities, "id" | "name">>;
        } & CompanyLicenseFragment
      >;
      metrc_api_keys: Array<
        Pick<MetrcApiKeys, "id"> & MetrcApiKeyLimitedFragment
      >;
    }
  >;
};

export type GetMetrcDownloadSummariesByMetrcApiKeyIdQueryVariables = Exact<{
  metrcApiKeyId: Scalars["uuid"];
}>;

export type GetMetrcDownloadSummariesByMetrcApiKeyIdQuery = {
  metrc_download_summaries: Array<
    Pick<MetrcDownloadSummaries, "id"> & MetrcDownloadSummaryLimitedFragment
  >;
};

export type GetMetrcDownloadSummariesByLicenseNumberQueryVariables = Exact<{
  license_number: Scalars["String"];
}>;

export type GetMetrcDownloadSummariesByLicenseNumberQuery = {
  metrc_download_summaries: Array<
    Pick<MetrcDownloadSummaries, "id"> & MetrcDownloadSummaryLimitedFragment
  >;
};

export type GetBankPayorPartnershipQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetBankPayorPartnershipQuery = {
  company_payor_partnerships_by_pk?: Maybe<
    {
      company: { settings?: Maybe<CompanySettingsFragment> } & CompanyFragment &
        AllCompanyUsersForBankFragment;
      payor?: Maybe<
        Pick<Payors, "id"> & {
          licenses: Array<CompanyLicenseLimitedFragment>;
          settings?: Maybe<
            Pick<CompanySettings, "id"> & {
              collections_bespoke_bank_account?: Maybe<BankAccountFragment>;
            }
          >;
          users: Array<ContactFragment>;
        }
      >;
      payor_agreement?: Maybe<CompanyAgreementFragment>;
      payor_contacts: Array<
        Pick<CompanyPayorContacts, "id"> & CompanyPayorContactFragment
      >;
    } & PayorPartnershipFragment
  >;
};

export type GetPayorPartnershipForContactsQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetPayorPartnershipForContactsQuery = {
  company_payor_partnerships_by_pk?: Maybe<
    {
      payor?: Maybe<
        Pick<Payors, "id"> & {
          users: Array<Pick<Users, "id"> & ContactFragment>;
        }
      >;
      payor_contacts: Array<
        Pick<CompanyPayorContacts, "id"> & CompanyPayorContactFragment
      >;
    } & PayorPartnershipFragment
  >;
};

export type GetPayorPartnershipsForBankQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPayorPartnershipsForBankQuery = {
  company_payor_partnerships: Array<
    {
      company: Pick<Companies, "id"> & CompanyLimitedFragment;
      payor?: Maybe<
        Pick<Payors, "id"> & { users: Array<ContactFragment> } & PayorFragment
      >;
    } & PayorPartnershipFragment
  >;
};

export type GetPayorPartnershipsByPayorIdQueryVariables = Exact<{
  payor_id: Scalars["uuid"];
}>;

export type GetPayorPartnershipsByPayorIdQuery = {
  company_payor_partnerships: Array<
    Pick<CompanyPayorPartnerships, "id"> & PayorPartnershipFragment
  >;
};

export type UpdatePayorAgreementIdMutationVariables = Exact<{
  companyPayorPartnershipId: Scalars["uuid"];
  payorAgreementId?: Maybe<Scalars["uuid"]>;
}>;

export type UpdatePayorAgreementIdMutation = {
  update_company_payor_partnerships_by_pk?: Maybe<
    Pick<CompanyPayorPartnerships, "id"> & {
      payor_agreement?: Maybe<CompanyAgreementFragment>;
    }
  >;
};

export type AddCompanyPayorAgreementMutationVariables = Exact<{
  payorAgreement: CompanyAgreementsInsertInput;
}>;

export type AddCompanyPayorAgreementMutation = {
  insert_company_agreements_one?: Maybe<CompanyAgreementFragment>;
};

export type GetPayorsWithMetadataQueryVariables = Exact<{
  date?: Maybe<Scalars["date"]>;
}>;

export type GetPayorsWithMetadataQuery = {
  payors: Array<
    Pick<Companies, "id"> & {
      licenses: Array<CompanyLicenseLimitedFragment>;
    } & CustomersWithMetadataFragment
  >;
};

export type ListPayorPartnershipsByCompanyIdQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type ListPayorPartnershipsByCompanyIdQuery = {
  company_payor_partnerships: Array<
    Pick<CompanyPayorPartnerships, "id"> & {
      payor_limited?: Maybe<PayorLimitedFragment>;
    } & PayorPartnershipFragment
  >;
};

export type GetCompanyDeliveryQueryVariables = Exact<{
  id: Scalars["uuid"];
  company_id: Scalars["uuid"];
}>;

export type GetCompanyDeliveryQuery = {
  company_deliveries_by_pk?: Maybe<
    Pick<CompanyDeliveries, "id"> & {
      metrc_transfer: Pick<MetrcTransfers, "id"> & {
        metrc_transfer_packages: Array<
          Pick<MetrcTransferPackages, "id"> & MetrcTransferPackageFragment
        >;
      } & MetrcTransferFragment;
      metrc_delivery: Pick<MetrcDeliveries, "id"> &
        MetrcDeliveryLimitedFragment;
      vendor?: Maybe<
        Pick<Vendors, "id" | "name"> & {
          company_vendor_partnerships: Array<
            Pick<CompanyVendorPartnerships, "id" | "approved_at">
          >;
        }
      >;
    } & CompanyDeliveryFragment
  >;
};

export type GetIncomingFromVendorCompanyDeliveriesByCompanyIdQueryVariables =
  Exact<{
    company_id: Scalars["uuid"];
    start_created_date: Scalars["date"];
    transfer_row_ids: Array<Scalars["uuid"]> | Scalars["uuid"];
  }>;

export type GetIncomingFromVendorCompanyDeliveriesByCompanyIdQuery = {
  company_deliveries: Array<
    Pick<CompanyDeliveries, "id"> & {
      metrc_transfer: Pick<MetrcTransfers, "id"> & MetrcTransferFragment;
      metrc_delivery: Pick<MetrcDeliveries, "id"> &
        MetrcDeliveryLimitedFragment;
      vendor?: Maybe<
        Pick<Vendors, "id" | "name"> & {
          company_vendor_partnerships: Array<
            Pick<CompanyVendorPartnerships, "id" | "approved_at">
          >;
        }
      >;
    } & CompanyDeliveryFragment
  >;
};

export type GetPurchaseOrderForBankQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetPurchaseOrderForBankQuery = {
  purchase_orders_by_pk?: Maybe<
    Pick<PurchaseOrders, "id"> & {
      loans: Array<Pick<Loans, "id"> & LoanLimitedFragment>;
      purchase_order_files: Array<PurchaseOrderFileFragment>;
      purchase_order_metrc_transfers: Array<
        Pick<PurchaseOrderMetrcTransfers, "id"> & {
          metrc_transfer: Pick<MetrcTransfers, "id"> & {
            metrc_transfer_packages: Array<
              Pick<MetrcTransferPackages, "id"> & MetrcTransferPackageFragment
            >;
          } & MetrcTransferFragment;
        } & PurchaseOrderMetrcTransferFragment
      >;
    } & PurchaseOrderFragment
  >;
};

export type GetPurchaseOrderForCustomerQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetPurchaseOrderForCustomerQuery = {
  purchase_orders_by_pk?: Maybe<
    Pick<PurchaseOrders, "id"> & {
      loans: Array<Pick<Loans, "id"> & LoanLimitedFragment>;
      purchase_order_files: Array<PurchaseOrderFileFragment>;
      purchase_order_metrc_transfers: Array<
        Pick<PurchaseOrderMetrcTransfers, "id"> & {
          metrc_transfer: Pick<MetrcTransfers, "id"> & MetrcTransferFragment;
        } & PurchaseOrderMetrcTransferFragment
      >;
    } & PurchaseOrderLimitedFragment
  >;
};

export type GetPurchaseOrderForCombinedQueryVariables = Exact<{
  id: Scalars["uuid"];
  is_bank_user: Scalars["Boolean"];
}>;

export type GetPurchaseOrderForCombinedQuery = {
  purchase_orders_by_pk?: Maybe<
    Pick<PurchaseOrders, "id"> & PurchaseOrderWithRelationshipsFragment
  >;
};

export type GetPurchaseOrderForReviewQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetPurchaseOrderForReviewQuery = {
  purchase_orders_by_pk?: Maybe<
    Pick<
      PurchaseOrders,
      | "id"
      | "company_id"
      | "vendor_id"
      | "order_number"
      | "order_date"
      | "net_terms"
      | "amount"
      | "is_cannabis"
      | "is_metrc_based"
      | "is_deleted"
      | "status"
      | "created_at"
      | "all_customer_notes"
    > & {
      purchase_order_files: Array<
        Pick<PurchaseOrderFiles, "purchase_order_id" | "file_id"> &
          PurchaseOrderFileFragment
      >;
      company: Pick<Companies, "id"> & CompanyLimitedFragment;
      vendor?: Maybe<Pick<Vendors, "id"> & VendorLimitedFragment>;
      purchase_order_metrc_transfers: Array<
        Pick<PurchaseOrderMetrcTransfers, "id"> & {
          metrc_transfer: Pick<MetrcTransfers, "id"> & {
            metrc_transfer_packages: Array<
              Pick<MetrcTransferPackages, "id"> & MetrcTransferPackageFragment
            >;
          } & MetrcTransferFragment;
        } & PurchaseOrderMetrcTransferFragment
      >;
    }
  >;
};

export type GetPurchaseOrdersSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetPurchaseOrdersSubscription = {
  purchase_orders: Array<Pick<PurchaseOrders, "id"> & PurchaseOrderFragment>;
};

export type GetPurchaseOrdersByNewStatusSubscriptionVariables = Exact<{
  statuses?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type GetPurchaseOrdersByNewStatusSubscription = {
  purchase_orders: Array<Pick<PurchaseOrders, "id"> & PurchaseOrderFragment>;
};

export type GetConfirmedPurchaseOrdersSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetConfirmedPurchaseOrdersSubscription = {
  purchase_orders: Array<Pick<PurchaseOrders, "id"> & PurchaseOrderFragment>;
};

export type GetConfirmedPurchaseOrdersNewSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetConfirmedPurchaseOrdersNewSubscription = {
  purchase_orders: Array<Pick<PurchaseOrders, "id"> & PurchaseOrderFragment>;
};

export type GetAllPurchaseOrdersByCompanyIdNewQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetAllPurchaseOrdersByCompanyIdNewQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      settings?: Maybe<Pick<CompanySettings, "id" | "has_autofinancing">>;
    }
  >;
  purchase_orders: Array<PurchaseOrderLimitedNewFragment>;
};

export type GetFundablePurchaseOrdersByCompanyIdQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetFundablePurchaseOrdersByCompanyIdQuery = {
  purchase_orders: Array<PurchaseOrderLimitedFragment>;
};

export type GetVendorReviewablePurchaseOrdersQueryVariables = Exact<{
  vendor_id: Scalars["uuid"];
}>;

export type GetVendorReviewablePurchaseOrdersQuery = {
  purchase_orders: Array<PurchaseOrderLimitedNewFragment>;
};

export type GetPurchaseOrdersChangesRequestedCountForCustomerQueryVariables =
  Exact<{
    company_id: Scalars["uuid"];
  }>;

export type GetPurchaseOrdersChangesRequestedCountForCustomerQuery = {
  purchase_orders: Array<Pick<PurchaseOrders, "id">>;
};

export type GetVendorPurchaseOrdersByStatusQueryVariables = Exact<{
  vendor_id: Scalars["uuid"];
  statuses?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type GetVendorPurchaseOrdersByStatusQuery = {
  purchase_orders: Array<PurchaseOrderLimitedNewFragment>;
};

export type GetPaymentQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetPaymentQuery = {
  payments_by_pk?: Maybe<
    Pick<Payments, "id"> & {
      company: Pick<Companies, "id" | "name">;
      settled_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
    } & PaymentLimitedFragment
  >;
};

export type GetPaymentForBankQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetPaymentForBankQuery = {
  payments_by_pk?: Maybe<
    Pick<Payments, "id"> & {
      company: Pick<Companies, "id" | "name">;
      settled_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
    } & PaymentFragment
  >;
};

export type GetPaymentForSettlementQueryVariables = Exact<{
  id: Scalars["uuid"];
  today: Scalars["date"];
  is_bank_user?: Maybe<Scalars["Boolean"]>;
}>;

export type GetPaymentForSettlementQuery = {
  payments_by_pk?: Maybe<
    Pick<Payments, "id"> & {
      company: Pick<Companies, "id" | "name"> & {
        contract?: Maybe<Pick<Contracts, "id"> & ContractFragment>;
        financial_summaries: Array<
          Pick<FinancialSummaries, "id"> & FinancialSummaryFragment
        >;
      };
      company_bank_account?: Maybe<
        Pick<BankAccounts, "id"> & BankAccountFragment
      >;
      invoice?: Maybe<
        Pick<Invoices, "id"> & {
          payor?: Maybe<Pick<Payors, "id"> & PayorCombinedFragment>;
        }
      >;
      settled_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
    } & PaymentCombinedFragment
  >;
};

export type GetRepaymentsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetRepaymentsSubscription = {
  payments: Array<
    Pick<Payments, "id"> & {
      transactions: Array<
        Pick<Transactions, "id"> & {
          loan?: Maybe<
            Pick<Loans, "id"> &
              LoanLimitedFragment &
              LoanArtifactLimitedFragment
          >;
          payment: Pick<Payments, "id"> & PaymentLimitedFragment;
        } & TransactionFragment
      >;
      company: Pick<Companies, "id"> & {
        most_recent_financial_summary: Array<
          Pick<FinancialSummaries, "id" | "product_type">
        >;
      };
      settled_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
    } & PaymentFragment
  >;
};

export type GetRepaymentsByDepositDateRangeQueryVariables = Exact<{
  start_date: Scalars["date"];
  end_date: Scalars["date"];
  start_datetime: Scalars["timestamptz"];
  end_datetime: Scalars["timestamptz"];
}>;

export type GetRepaymentsByDepositDateRangeQuery = {
  payments: Array<Pick<Payments, "id"> & RepaymentForBankDataGridFragment>;
};

export type GetSubmittedPaymentsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetSubmittedPaymentsSubscription = {
  payments: Array<Pick<Payments, "id"> & RepaymentForBankDataGridFragment>;
};

export type GetRepaymentsForCompanyQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetRepaymentsForCompanyQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      payments: Array<
        Pick<Payments, "id"> & {
          transactions: Array<
            Pick<Transactions, "id"> & {
              loan?: Maybe<
                Pick<Loans, "id"> &
                  LoanLimitedFragment &
                  LoanArtifactLimitedFragment
              >;
              payment: Pick<Payments, "id"> & PaymentLimitedFragment;
            } & TransactionFragment
          >;
        } & PaymentLimitedFragment
      >;
    }
  >;
};

export type GetRepaymentsByMethodAndPaymentDateQueryVariables = Exact<{
  method: Scalars["String"];
  date: Scalars["date"];
}>;

export type GetRepaymentsByMethodAndPaymentDateQuery = {
  payments: Array<
    Pick<Payments, "id"> & {
      company_bank_account?: Maybe<
        Pick<BankAccounts, "id"> & BankAccountFragment
      >;
      recipient_bank_account?: Maybe<
        Pick<BankAccounts, "id"> & BankAccountFragment
      >;
    } & PaymentFragment
  >;
};

export type GetMostRecentMonthlyCalculationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMostRecentMonthlyCalculationsQuery = {
  companies: Array<Pick<Companies, "id"> & RecentMonthlyCalculationsFragment>;
};

export type LastMonthlySummaryReportLiveRunQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LastMonthlySummaryReportLiveRunQuery = {
  sync_pipelines: Array<
    Pick<
      SyncPipelines,
      "id" | "name" | "internal_state" | "params" | "created_at"
    >
  >;
};

export type GetOpenAsyncJobsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetOpenAsyncJobsSubscription = {
  async_jobs: Array<Pick<AsyncJobs, "id"> & AsyncJobFragment>;
};

export type GetCompletedAsyncJobsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompletedAsyncJobsQuery = {
  async_jobs: Array<Pick<AsyncJobs, "id"> & AsyncJobFragment>;
};

export type GetAsyncJobByIdQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetAsyncJobByIdQuery = {
  async_jobs_by_pk?: Maybe<Pick<AsyncJobs, "id"> & AsyncJobFragment>;
};

export type GetCompanySettingsQueryVariables = Exact<{
  company_settings_id: Scalars["uuid"];
}>;

export type GetCompanySettingsQuery = {
  company_settings_by_pk?: Maybe<CompanySettingsFragment>;
};

export type GetCompanySettingsByCompanyIdForCustomerQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetCompanySettingsByCompanyIdForCustomerQuery = {
  company_settings: Array<
    Pick<CompanySettings, "id"> & CompanySettingsLimitedFragment
  >;
};

export type UpdateIsDummyAccountMutationVariables = Exact<{
  companySettingsId: Scalars["uuid"];
  isDummyAccount?: Maybe<Scalars["Boolean"]>;
}>;

export type UpdateIsDummyAccountMutation = {
  update_company_settings_by_pk?: Maybe<CompanySettingsFragment>;
};

export type UpdateCompanySettingsMutationVariables = Exact<{
  company_settings_id: Scalars["uuid"];
  company_settings: CompanySettingsSetInput;
}>;

export type UpdateCompanySettingsMutation = {
  update_company_settings_by_pk?: Maybe<CompanySettingsFragment>;
};

export type GetContractQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetContractQuery = {
  contracts_by_pk?: Maybe<
    Pick<Contracts, "id"> & {
      company?: Maybe<Pick<Companies, "id" | "name">>;
    } & ContractFragment
  >;
};

export type GetUserQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetUserQuery = {
  users_by_pk?: Maybe<Pick<Users, "id"> & UserFragment>;
};

export type GetUserByEmailQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type GetUserByEmailQuery = {
  users: Array<
    Pick<Users, "id"> & {
      company?: Maybe<Pick<Companies, "id" | "name">>;
    } & UserFragment
  >;
};

export type GetActiveUsersByRolesQueryVariables = Exact<{
  roles: Array<UserRolesEnum> | UserRolesEnum;
  isBankUser?: Maybe<Scalars["Boolean"]>;
}>;

export type GetActiveUsersByRolesQuery = {
  users: Array<
    Pick<Users, "id"> & {
      company?: Maybe<Pick<Companies, "id" | "name">>;
    } & UserWrapperFragment
  >;
};

export type GetActiveTeamMembersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetActiveTeamMembersQuery = {
  client_success_team_members: Array<Pick<Users, "id" | "full_name">>;
  business_development_team_members: Array<Pick<Users, "id" | "full_name">>;
  underwriting_team_members: Array<Pick<Users, "id" | "full_name">>;
};

export type GetDeactivatedUsersByRolesQueryVariables = Exact<{
  roles: Array<UserRolesEnum> | UserRolesEnum;
}>;

export type GetDeactivatedUsersByRolesQuery = {
  users: Array<Pick<Users, "id"> & UserFragment>;
};

export type GetUsersForCompanyQueryVariables = Exact<{
  parent_company_id: Scalars["uuid"];
  company_id: Scalars["uuid"];
  isBankUser?: Maybe<Scalars["Boolean"]>;
}>;

export type GetUsersForCompanyQuery = { users: Array<UserWrapperFragment> };

export type GetUsersForParentCompanyQueryVariables = Exact<{
  parent_company_id: Scalars["uuid"];
  isBankUser?: Maybe<Scalars["Boolean"]>;
}>;

export type GetUsersForParentCompanyQuery = {
  users: Array<UserWrapperFragment>;
};

export type GetDeactivatedUsersForParentCompanyQueryVariables = Exact<{
  parent_company_id: Scalars["uuid"];
}>;

export type GetDeactivatedUsersForParentCompanyQuery = {
  users: Array<UserFragment>;
};

export type GetDeactivatedUsersForCompanyQueryVariables = Exact<{
  parent_company_id: Scalars["uuid"];
  company_id: Scalars["uuid"];
}>;

export type GetDeactivatedUsersForCompanyQuery = { users: Array<UserFragment> };

export type AssignAdvancesBespokeBankAccountMutationVariables = Exact<{
  companySettingsId: Scalars["uuid"];
  bankAccountId?: Maybe<Scalars["uuid"]>;
}>;

export type AssignAdvancesBespokeBankAccountMutation = {
  update_company_settings_by_pk?: Maybe<
    Pick<CompanySettings, "id"> & {
      advances_bespoke_bank_account?: Maybe<BankAccountFragment>;
    }
  >;
};

export type AssignCollectionsBespokeBankAccountMutationVariables = Exact<{
  companySettingsId: Scalars["uuid"];
  bankAccountId?: Maybe<Scalars["uuid"]>;
}>;

export type AssignCollectionsBespokeBankAccountMutation = {
  update_company_settings_by_pk?: Maybe<
    Pick<CompanySettings, "id"> & {
      collections_bespoke_bank_account?: Maybe<BankAccountFragment>;
    }
  >;
};

export type AssignAdvancesBankAccountMutationVariables = Exact<{
  companySettingsId: Scalars["uuid"];
  bankAccountId?: Maybe<Scalars["uuid"]>;
}>;

export type AssignAdvancesBankAccountMutation = {
  update_company_settings_by_pk?: Maybe<
    Pick<CompanySettings, "id"> & {
      advances_bank_account?: Maybe<BankAccountLimitedFragment>;
    }
  >;
};

export type AssignCollectionsBankAccountMutationVariables = Exact<{
  companySettingsId: Scalars["uuid"];
  bankAccountId?: Maybe<Scalars["uuid"]>;
}>;

export type AssignCollectionsBankAccountMutation = {
  update_company_settings_by_pk?: Maybe<
    Pick<CompanySettings, "id"> & {
      collections_bank_account?: Maybe<BankAccountLimitedFragment>;
    }
  >;
};

export type GetCompaniesWithLicensesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompaniesWithLicensesQuery = {
  companies: Array<
    Pick<Companies, "id"> & {
      licenses: Array<Pick<CompanyLicenses, "id" | "license_number">>;
    } & CompanyLimitedFragment
  >;
};

export type GetParentCompanyWithCustomerCompaniesQueryVariables = Exact<{
  parent_company_id: Scalars["uuid"];
  date: Scalars["date"];
}>;

export type GetParentCompanyWithCustomerCompaniesQuery = {
  parent_companies_by_pk?: Maybe<
    Pick<ParentCompanies, "id"> & {
      customer_companies: Array<
        Pick<Companies, "id" | "name"> & {
          contract?: Maybe<Pick<Contracts, "id"> & ContractFragment>;
          financial_summaries: Array<
            Pick<FinancialSummaries, "id"> & FinancialSummaryFragment
          >;
        }
      >;
    }
  >;
};

export type GetParentCompanyWithChildCompaniesQueryVariables = Exact<{
  parent_company_id: Scalars["uuid"];
}>;

export type GetParentCompanyWithChildCompaniesQuery = {
  parent_companies_by_pk?: Maybe<
    Pick<ParentCompanies, "id"> & {
      child_companies: Array<
        Pick<Companies, "id"> & CompanyWithSettingsFragment
      >;
    }
  >;
};

export type GetParentCompanyWithSettingsQueryVariables = Exact<{
  parent_company_id: Scalars["uuid"];
}>;

export type GetParentCompanyWithSettingsQuery = {
  parent_companies_by_pk?: Maybe<
    Pick<ParentCompanies, "id" | "name" | "settings">
  >;
};

export type GetAllCompaniesWithMetadataQueryVariables = Exact<{
  date?: Maybe<Scalars["date"]>;
}>;

export type GetAllCompaniesWithMetadataQuery = {
  companies: Array<CustomersWithMetadataFragment>;
};

export type GetParentCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type GetParentCompaniesQuery = {
  parent_companies: Array<Pick<ParentCompanies, "id"> & ParentCompanyFragment>;
};

export type GetBankFinancialSummariesByDateSubscriptionVariables = Exact<{
  date: Scalars["date"];
}>;

export type GetBankFinancialSummariesByDateSubscription = {
  bank_financial_summaries: Array<
    Pick<BankFinancialSummaries, "id"> & BankFinancialSummaryFragment
  >;
};

export type GetLoansCountForBankSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetLoansCountForBankSubscription = {
  loans: Array<Pick<Loans, "id">>;
};

export type GetRepaymentsCountForBankSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetRepaymentsCountForBankSubscription = {
  payments: Array<Pick<Payments, "id">>;
};

export type GetEbbaApplicationsCountForBankSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetEbbaApplicationsCountForBankSubscription = {
  ebba_applications: Array<Pick<EbbaApplications, "id">>;
};

export type GetCompanyWithActiveContractQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type GetCompanyWithActiveContractQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      contract?: Maybe<Pick<Contracts, "id"> & ContractFragment>;
    }
  >;
};

export type GetCompanyEbbaApplicationsInfoQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type GetCompanyEbbaApplicationsInfoQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      ebba_applications: Array<
        Pick<EbbaApplications, "id"> & {
          company: Pick<Companies, "id" | "name">;
          ebba_application_files: Array<
            Pick<EbbaApplicationFiles, "ebba_application_id" | "file_id">
          >;
        } & EbbaApplicationFragment
      >;
      settings?: Maybe<
        Pick<CompanySettings, "id"> & {
          active_ebba_application?: Maybe<
            Pick<EbbaApplications, "id"> & EbbaApplicationFragment
          >;
          active_borrowing_base?: Maybe<
            Pick<EbbaApplications, "id"> & EbbaApplicationFragment
          >;
          active_financial_report?: Maybe<
            Pick<EbbaApplications, "id"> & EbbaApplicationFragment
          >;
        }
      >;
      financial_summaries: Array<
        Pick<FinancialSummaries, "id" | "product_type">
      >;
    }
  >;
};

export type GetCompanyForCustomerContractPageQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type GetCompanyForCustomerContractPageQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      contract?: Maybe<Pick<Contracts, "id"> & ContractFragment>;
      contracts: Array<Pick<Contracts, "id"> & ContractFragment>;
    }
  >;
};

export type GetCompanyWithDetailsByCompanyIdQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type GetCompanyWithDetailsByCompanyIdQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id" | "name"> & {
      contract?: Maybe<Pick<Contracts, "id"> & ContractFragment>;
    }
  >;
};

export type GetCompanyNextLoanIdentifierMutationVariables = Exact<{
  companyId: Scalars["uuid"];
  increment: CompaniesIncInput;
}>;

export type GetCompanyNextLoanIdentifierMutation = {
  update_companies_by_pk?: Maybe<
    Pick<Companies, "id" | "latest_loan_identifier">
  >;
};

export type GetCompanyForBankQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type GetCompanyForBankQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      bank_accounts: Array<Pick<BankAccounts, "id"> & BankAccountFragment>;
      contract?: Maybe<Pick<Contracts, "id"> & ContractFragment>;
      licenses: Array<
        Pick<CompanyLicenses, "id"> & {
          company_facility?: Maybe<Pick<CompanyFacilities, "id" | "name">>;
        } & CompanyLicenseFragment
      >;
      most_recent_financial_summary: Array<
        Pick<FinancialSummaries, "id"> & FinancialSummaryFragment
      >;
      settings?: Maybe<
        {
          advances_bespoke_bank_account?: Maybe<
            Pick<BankAccounts, "id"> & BankAccountFragment
          >;
          collections_bespoke_bank_account?: Maybe<
            Pick<BankAccounts, "id"> & BankAccountFragment
          >;
          advances_bank_account?: Maybe<
            Pick<BankAccounts, "id"> & BankAccountLimitedFragment
          >;
          collections_bank_account?: Maybe<
            Pick<BankAccounts, "id"> & BankAccountLimitedFragment
          >;
        } & CompanyDealOwnersFragment &
          CompanySettingsFragment
      >;
      users: Array<Pick<Users, "id"> & UserFragment>;
    } & CompanyFragment
  >;
};

export type GetCompanyForCustomerQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type GetCompanyForCustomerQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      bank_accounts: Array<
        Pick<BankAccounts, "id"> & BankAccountLimitedFragment
      >;
      contract?: Maybe<Pick<Contracts, "id"> & ContractFragment>;
      settings?: Maybe<
        Pick<CompanySettings, "id"> & {
          advances_bank_account?: Maybe<
            Pick<BankAccounts, "id"> & BankAccountLimitedFragment
          >;
          collections_bank_account?: Maybe<
            Pick<BankAccounts, "id"> & BankAccountLimitedFragment
          >;
        } & CompanySettingsLimitedFragment
      >;
      users: Array<Pick<Users, "id"> & UserFragment>;
    } & CompanyFragment
  >;
};

export type GetCompanyForVendorQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type GetCompanyForVendorQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      bank_accounts: Array<
        Pick<BankAccounts, "id"> & BankAccountLimitedFragment
      >;
      settings?: Maybe<
        Pick<CompanySettings, "id"> & {
          advances_bank_account?: Maybe<
            Pick<BankAccounts, "id"> & BankAccountLimitedFragment
          >;
          collections_bank_account?: Maybe<
            Pick<BankAccounts, "id"> & BankAccountLimitedFragment
          >;
        } & CompanySettingsLimitedFragment
      >;
      users: Array<Pick<Users, "id"> & UserFragment>;
    } & CompanyFragment
  >;
};

export type UpdateCompanyProfileMutationVariables = Exact<{
  id: Scalars["uuid"];
  company: CompaniesSetInput;
}>;

export type UpdateCompanyProfileMutation = {
  update_companies_by_pk?: Maybe<CompanyFragment>;
};

export type UserFragment = Pick<
  Users,
  | "id"
  | "first_name"
  | "last_name"
  | "full_name"
  | "email"
  | "phone_number"
  | "role"
  | "company_role"
  | "created_at"
>;

export type UserWrapperFragment = Pick<Users, "id"> &
  UserFragment &
  UserBankOnlyFragment;

export type UserBankOnlyFragment = Pick<Users, "id" | "company_role_new">;

export type CompanyAgreementFragment = Pick<
  CompanyAgreements,
  "id" | "company_id" | "file_id"
>;

export type CompanyFacilityFragment = Pick<
  CompanyFacilities,
  "id" | "name" | "address"
>;

export type CompanyLicenseFragment = Pick<
  CompanyLicenses,
  | "id"
  | "legal_name"
  | "dba_name"
  | "license_category"
  | "license_description"
  | "license_status"
  | "us_state"
  | "expiration_date"
  | "facility_row_id"
  | "is_underwriting_enabled"
> &
  CompanyLicenseLimitedFragment;

export type CompanyLimitedFragment = Pick<
  Companies,
  "id" | "parent_company_id" | "name" | "dba_name"
>;

export type CompanyFragment = Pick<
  Companies,
  | "id"
  | "identifier"
  | "is_customer"
  | "is_payor"
  | "is_vendor"
  | "is_super_vendor"
  | "contract_name"
  | "employer_identification_number"
  | "address"
  | "phone_number"
> &
  CompanyLimitedFragment;

export type CompanyWithSettingsFragment = Pick<
  Companies,
  | "id"
  | "identifier"
  | "is_customer"
  | "is_payor"
  | "is_vendor"
  | "is_super_vendor"
  | "contract_name"
  | "employer_identification_number"
  | "address"
  | "phone_number"
  | "state"
  | "timezone"
  | "created_at"
> & {
  settings?: Maybe<Pick<CompanySettings, "id"> & CompanySettingsFragment>;
} & CompanyLimitedFragment;

export type ParentCompanyFragment = Pick<ParentCompanies, "id" | "name">;

export type ContractFragment = Pick<
  Contracts,
  | "id"
  | "company_id"
  | "product_type"
  | "product_config"
  | "start_date"
  | "end_date"
  | "adjusted_end_date"
  | "terminated_at"
>;

export type FileFragment = Pick<Files, "id" | "name" | "path">;

export type PurchaseOrderFileFragment = Pick<
  PurchaseOrderFiles,
  "purchase_order_id" | "file_id" | "file_type"
> & { file: Pick<Files, "id"> & FileFragment };

export type BankAccountForVendorFragment = Pick<
  BankAccounts,
  | "id"
  | "company_id"
  | "bank_name"
  | "account_title"
  | "account_type"
  | "account_number"
  | "bank_address"
  | "can_ach"
  | "routing_number"
  | "ach_default_memo"
  | "can_wire"
  | "is_wire_intermediary"
  | "is_bank_international"
  | "intermediary_bank_name"
  | "intermediary_bank_address"
  | "intermediary_account_name"
  | "intermediary_account_number"
  | "wire_routing_number"
  | "recipient_name"
  | "recipient_address"
  | "recipient_address_2"
  | "wire_default_memo"
>;

export type EbbaApplicationFragment = Pick<
  EbbaApplications,
  | "id"
  | "company_id"
  | "category"
  | "application_date"
  | "monthly_accounts_receivable"
  | "monthly_inventory"
  | "monthly_cash"
  | "amount_cash_in_daca"
  | "amount_custom"
  | "amount_custom_note"
  | "calculated_borrowing_base"
  | "status"
  | "rejection_note"
  | "expires_date"
  | "created_at"
  | "approved_at"
> & { submitted_by_user?: Maybe<Pick<Users, "id" | "full_name">> };

export type EbbaApplicationFileFragment = Pick<
  EbbaApplicationFiles,
  "ebba_application_id" | "file_id"
> & { file: Pick<Files, "id"> & FileFragment };

export type LineOfCreditFragment = Pick<
  LineOfCredits,
  | "id"
  | "company_id"
  | "recipient_vendor_id"
  | "is_credit_for_vendor"
  | "customer_note"
> & {
  recipient_vendor?: Maybe<Pick<Companies, "id"> & CompanyLimitedFragment>;
};

export type LoanWithRelationshipsFragment = Pick<Loans, "id"> & {
  purchase_order?: Maybe<
    Pick<PurchaseOrders, "id"> & PurchaseOrderWithRelationshipsFragment
  >;
  line_of_credit?: Maybe<Pick<LineOfCredits, "id"> & LineOfCreditFragment>;
  company: Pick<Companies, "id"> & CompanyLimitedFragment;
  loan_report?: Maybe<
    { debt_facility?: Maybe<DebtFacilityFragment> } & LoanReportFragment
  >;
} & LoanFragment;

export type FinancialSummaryFragment = Pick<
  FinancialSummaries,
  | "id"
  | "company_id"
  | "date"
  | "product_type"
  | "daily_interest_rate"
  | "available_limit"
  | "adjusted_total_limit"
  | "total_outstanding_principal"
  | "total_outstanding_interest"
  | "total_outstanding_fees"
  | "total_principal_in_requested_state"
  | "total_outstanding_principal_for_interest"
  | "total_outstanding_principal_past_due"
  | "total_amount_to_pay_interest_on"
  | "minimum_monthly_payload"
  | "account_level_balance_payload"
  | "day_volume_threshold_met"
  | "interest_accrued_today"
  | "late_fees_accrued_today"
  | "needs_recompute"
  | "minimum_interest_duration"
  | "minimum_interest_amount"
  | "minimum_interest_remaining"
  | "most_overdue_loan_days"
  | "accounting_total_outstanding_principal"
  | "accounting_total_outstanding_interest"
  | "accounting_total_outstanding_late_fees"
  | "accounting_interest_accrued_today"
  | "accounting_late_fees_accrued_today"
>;

export type FinancialSummaryWithLoansInfoFragment = Pick<
  FinancialSummaries,
  "id" | "loans_info"
> &
  FinancialSummaryFragment;

export type InvoiceFileFragment = Pick<
  InvoiceFiles,
  "invoice_id" | "file_id" | "file_type"
> & { file: Pick<Files, "id"> & FileFragment };

export type InvoiceFragment = Pick<Invoices, "id"> & InvoiceLimitedFragment;

export type CompanyDeliveryFragment = Pick<
  CompanyDeliveries,
  | "id"
  | "company_id"
  | "license_number"
  | "us_state"
  | "vendor_id"
  | "payor_id"
  | "transfer_row_id"
  | "transfer_type"
  | "delivery_row_id"
  | "delivery_type"
>;

export type MetrcTransferLimitedFragment = Pick<
  MetrcTransfers,
  | "id"
  | "us_state"
  | "transfer_id"
  | "manifest_number"
  | "created_date"
  | "shipper_facility_license_number"
  | "shipper_facility_name"
  | "lab_results_status"
  | "last_modified_at"
>;

export type MetrcTransferFragment = Pick<
  MetrcTransfers,
  "id" | "transfer_payload"
> &
  MetrcTransferLimitedFragment;

export type MetrcDeliveryLimitedFragment = Pick<
  MetrcDeliveries,
  | "id"
  | "us_state"
  | "delivery_id"
  | "transfer_row_id"
  | "recipient_facility_license_number"
  | "recipient_facility_name"
  | "shipment_type_name"
  | "shipment_transaction_type"
  | "received_datetime"
>;

export type MetrcDeliveryFragment = Pick<
  MetrcDeliveries,
  "id" | "delivery_payload"
> &
  MetrcDeliveryLimitedFragment;

export type MetrcTransferPackageFragment = Pick<
  MetrcTransferPackages,
  | "id"
  | "us_state"
  | "package_id"
  | "transfer_row_id"
  | "delivery_row_id"
  | "delivery_id"
  | "package_label"
  | "package_type"
  | "product_name"
  | "product_category_name"
  | "shipped_quantity"
  | "shipper_wholesale_price"
  | "package_payload"
  | "lab_results_status"
> & { metrc_transfer: Pick<MetrcTransfers, "id" | "manifest_number"> };

export type MetrcPackageFragment = Pick<
  MetrcPackages,
  | "id"
  | "company_id"
  | "package_id"
  | "last_modified_at"
  | "type"
  | "package_label"
  | "package_type"
  | "product_name"
  | "product_category_name"
  | "packaged_date"
  | "quantity"
  | "unit_of_measure"
  | "package_payload"
>;

export type MetrcSalesTransactionFragment = Pick<
  MetrcSalesTransactions,
  | "id"
  | "product_name"
  | "product_category_name"
  | "us_state"
  | "is_deleted"
  | "quantity_sold"
  | "total_price"
  | "unit_of_measure"
>;

export type PurchaseOrderMetrcTransferFragment = Pick<
  PurchaseOrderMetrcTransfers,
  "id" | "purchase_order_id" | "metrc_transfer_id"
>;

export type PurchaseOrderMetrcTransferWithRelationshipsFragment = Pick<
  PurchaseOrderMetrcTransfers,
  "id" | "purchase_order_id" | "metrc_transfer_id"
> & {
  metrc_transfer: Pick<MetrcTransfers, "id"> & {
    metrc_deliveries: Array<
      Pick<MetrcDeliveries, "id"> & MetrcDeliveryFragment
    >;
  } & MetrcTransferFragment;
};

export type UpdateCompanyInfoMutationVariables = Exact<{
  id: Scalars["uuid"];
  company: CompaniesSetInput;
}>;

export type UpdateCompanyInfoMutation = {
  update_companies_by_pk?: Maybe<
    Pick<
      Companies,
      | "id"
      | "name"
      | "address"
      | "country"
      | "state"
      | "city"
      | "zip_code"
      | "phone_number"
    >
  >;
};

export type GetTransactionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTransactionsQuery = {
  transactions: Array<Pick<Transactions, "id"> & TransactionExtendedFragment>;
};

export type GetTransactionsForDateRangeQueryVariables = Exact<{
  from: Scalars["timestamptz"];
  to: Scalars["timestamptz"];
}>;

export type GetTransactionsForDateRangeQuery = {
  transactions: Array<Pick<Transactions, "id"> & TransactionExtendedFragment>;
};

export type GetMetrcTransferQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetMetrcTransferQuery = {
  metrc_transfers_by_pk?: Maybe<
    Pick<MetrcTransfers, "id"> & {
      metrc_deliveries: Array<
        Pick<MetrcDeliveries, "id"> & MetrcDeliveryFragment
      >;
      metrc_transfer_packages: Array<
        Pick<MetrcTransferPackages, "id"> & MetrcTransferPackageFragment
      >;
    } & MetrcTransferFragment
  >;
};

export type GetMetrcTransfersByUsStateManifestNumberQueryVariables = Exact<{
  us_state: Scalars["String"];
  manifest_number: Scalars["String"];
}>;

export type GetMetrcTransfersByUsStateManifestNumberQuery = {
  metrc_transfers: Array<
    Pick<MetrcTransfers, "id"> & {
      metrc_deliveries: Array<
        Pick<MetrcDeliveries, "id"> & MetrcDeliveryFragment
      >;
    } & MetrcTransferFragment
  >;
};

export type GetMetrcTransfersByCompanyIdQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetMetrcTransfersByCompanyIdQuery = {
  metrc_transfers: Array<
    Pick<MetrcTransfers, "id"> & {
      metrc_deliveries: Array<
        Pick<MetrcDeliveries, "id"> & MetrcDeliveryFragment
      >;
    } & MetrcTransferFragment
  >;
};

export type GetMetrcTransferPackageQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetMetrcTransferPackageQuery = {
  metrc_transfer_packages_by_pk?: Maybe<
    Pick<MetrcTransferPackages, "id"> & MetrcTransferPackageFragment
  >;
};

export type GetMetrcTransferPackagesByCompanyIdQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetMetrcTransferPackagesByCompanyIdQuery = {
  metrc_transfer_packages: Array<
    Pick<MetrcTransferPackages, "id"> & MetrcTransferPackageFragment
  >;
};

export type GetActiveMetrcPackagesByCompanyIdQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetActiveMetrcPackagesByCompanyIdQuery = {
  metrc_packages: Array<Pick<MetrcPackages, "id"> & MetrcPackageFragment>;
};

export type GetMetrcPackageQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetMetrcPackageQuery = {
  metrc_packages_by_pk?: Maybe<
    Pick<MetrcPackages, "id"> & MetrcPackageFragment
  >;
};

export type GetMetrcDownloadSummaryQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetMetrcDownloadSummaryQuery = {
  metrc_download_summaries_by_pk?: Maybe<
    Pick<MetrcDownloadSummaries, "id"> & MetrcDownloadSummaryFragment
  >;
};

export type GetMetrcApiKeyQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetMetrcApiKeyQuery = {
  metrc_api_keys_by_pk?: Maybe<Pick<MetrcApiKeys, "id"> & MetrcApiKeyFragment>;
};

export type GetVendorPartnershipQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetVendorPartnershipQuery = {
  company_vendor_partnerships_by_pk?: Maybe<
    {
      company: CompanyFragment & AllCompanyUsersForBankFragment;
      vendor?: Maybe<
        Pick<Vendors, "id"> & {
          settings?: Maybe<Pick<CompanySettings, "id">>;
          users: Array<Pick<Users, "id"> & ContactFragment>;
        }
      >;
    } & VendorPartnershipLimitedFragment
  >;
};

export type GetVendorContactsQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetVendorContactsQuery = {
  company_vendor_partnerships_by_pk?: Maybe<
    {
      company: CompanyFragment;
      vendor?: Maybe<
        Pick<Vendors, "id"> & {
          users: Array<Pick<Users, "id"> & ContactFragment & UserFragment>;
        }
      >;
      vendor_contacts: Array<
        Pick<CompanyVendorContacts, "id"> & CompanyVendorContactFragment
      >;
    } & VendorPartnershipLimitedFragment
  >;
};

export type GetVendorsWithMetadataQueryVariables = Exact<{
  date?: Maybe<Scalars["date"]>;
}>;

export type GetVendorsWithMetadataQuery = {
  vendors: Array<
    Pick<Companies, "id"> & {
      licenses: Array<CompanyLicenseLimitedFragment>;
    } & CustomersWithMetadataFragment
  >;
};

export type GetVendorCompanyFileAttachmentsQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetVendorCompanyFileAttachmentsQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      agreements: Array<CompanyAgreementFragment>;
      licenses: Array<CompanyLicenseFragment>;
    }
  >;
};

export type GetVendorPartnershipForBankQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetVendorPartnershipForBankQuery = {
  company_vendor_partnerships_by_pk?: Maybe<
    {
      company: { settings?: Maybe<CompanySettingsFragment> } & CompanyFragment &
        AllCompanyUsersForBankFragment;
      vendor?: Maybe<
        Pick<Vendors, "id"> & {
          licenses: Array<CompanyLicenseFragment>;
          settings?: Maybe<
            Pick<CompanySettings, "id"> & {
              collections_bespoke_bank_account?: Maybe<BankAccountFragment>;
            } & CompanySettingsFragment
          >;
          users: Array<Pick<Users, "id"> & ContactFragment>;
        }
      >;
      vendor_agreement?: Maybe<CompanyAgreementFragment>;
      vendor_bank_account?: Maybe<
        Pick<BankAccounts, "id"> & BankAccountFragment
      >;
      vendor_contacts: Array<
        Pick<CompanyVendorContacts, "id"> & CompanyVendorContactFragment
      >;
    } & VendorPartnershipFragment
  >;
};

export type GetVendorPartnershipForContactsQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetVendorPartnershipForContactsQuery = {
  company_vendor_partnerships_by_pk?: Maybe<
    {
      vendor?: Maybe<
        Pick<Vendors, "id"> & {
          users: Array<Pick<Users, "id"> & ContactFragment>;
        }
      >;
      vendor_contacts: Array<
        Pick<CompanyVendorContacts, "id" | "is_active"> &
          CompanyVendorContactFragment
      >;
    } & VendorPartnershipFragment
  >;
};

export type GetVendorPartnershipsForBankQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetVendorPartnershipsForBankQuery = {
  company_vendor_partnerships: Array<
    {
      company: Pick<Companies, "id"> & CompanyLimitedFragment;
      vendor?: Maybe<
        Pick<Vendors, "id"> & { users: Array<ContactFragment> } & VendorFragment
      >;
    } & VendorPartnershipFragment
  >;
};

export type GetNotApprovedVendorPartnershipsForBankQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetNotApprovedVendorPartnershipsForBankQuery = {
  company_vendor_partnerships: Array<
    {
      company: Pick<Companies, "id"> & CompanyLimitedFragment;
      vendor?: Maybe<
        Pick<Vendors, "id"> & { users: Array<ContactFragment> } & VendorFragment
      >;
    } & VendorPartnershipFragment
  >;
};

export type GetApprovedVendorPartnershipsForBankQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetApprovedVendorPartnershipsForBankQuery = {
  company_vendor_partnerships: Array<
    {
      company: Pick<Companies, "id"> & CompanyLimitedFragment;
      vendor?: Maybe<
        Pick<Vendors, "id"> & { users: Array<ContactFragment> } & VendorFragment
      >;
    } & VendorPartnershipFragment
  >;
};

export type GetVendorPartnershipsByVendorIdQueryVariables = Exact<{
  vendor_id: Scalars["uuid"];
}>;

export type GetVendorPartnershipsByVendorIdQuery = {
  company_vendor_partnerships: Array<
    Pick<CompanyVendorPartnerships, "id"> & VendorPartnershipFragment
  >;
};

export type CompanyBankAccountsQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type CompanyBankAccountsQuery = {
  bank_accounts: Array<BankAccountFragment>;
};

export type ChangeBankAccountMutationVariables = Exact<{
  companyVendorPartnershipId: Scalars["uuid"];
  bankAccountId?: Maybe<Scalars["uuid"]>;
}>;

export type ChangeBankAccountMutation = {
  update_company_vendor_partnerships_by_pk?: Maybe<
    Pick<CompanyVendorPartnerships, "id"> & {
      vendor_bank_account?: Maybe<BankAccountFragment>;
    }
  >;
};

export type UpdateVendorInfoMutationVariables = Exact<{
  id: Scalars["uuid"];
  company: CompaniesSetInput;
}>;

export type UpdateVendorInfoMutation = {
  update_companies_by_pk?: Maybe<
    Pick<
      Companies,
      | "id"
      | "name"
      | "address"
      | "country"
      | "state"
      | "city"
      | "zip_code"
      | "phone_number"
    >
  >;
};

export type UpdateVendorAgreementIdMutationVariables = Exact<{
  companyVendorPartnershipId: Scalars["uuid"];
  vendorAgreementId?: Maybe<Scalars["uuid"]>;
}>;

export type UpdateVendorAgreementIdMutation = {
  update_company_vendor_partnerships_by_pk?: Maybe<
    Pick<CompanyVendorPartnerships, "id"> & {
      vendor_agreement?: Maybe<CompanyAgreementFragment>;
    }
  >;
};

export type AddCompanyVendorAgreementMutationVariables = Exact<{
  vendorAgreement: CompanyAgreementsInsertInput;
}>;

export type AddCompanyVendorAgreementMutation = {
  insert_company_agreements_one?: Maybe<CompanyAgreementFragment>;
};

export type GetArtifactRelationsByCompanyIdQueryVariables = Exact<{
  company_id: Scalars["uuid"];
}>;

export type GetArtifactRelationsByCompanyIdQuery = {
  companies_by_pk?: Maybe<
    Pick<Companies, "id"> & {
      settings?: Maybe<
        Pick<CompanySettings, "id"> & CompanySettingsLimitedFragment
      >;
      metrc_api_keys: Array<Pick<MetrcApiKeys, "id">>;
    }
  >;
  vendors: Array<
    Pick<Vendors, "id"> & {
      company_vendor_partnerships: Array<
        Pick<CompanyVendorPartnerships, "id"> & VendorPartnershipLimitedFragment
      >;
    } & VendorLimitedFragment
  >;
};

export type GetAllArtifactRelationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllArtifactRelationsQuery = {
  companies: Array<
    Pick<Companies, "id"> & {
      settings?: Maybe<
        Pick<CompanySettings, "id"> & CompanySettingsLimitedFragment
      >;
      metrc_api_keys: Array<Pick<MetrcApiKeys, "id">>;
    }
  >;
  vendors: Array<
    Pick<Vendors, "id"> & {
      company_vendor_partnerships: Array<
        Pick<CompanyVendorPartnerships, "id"> & VendorPartnershipLimitedFragment
      >;
      users: Array<Pick<Users, "id"> & UserFragment>;
      bank_accounts: Array<Pick<BankAccounts, "id"> & BankAccountFragment>;
    } & VendorLimitedFragment
  >;
};

export type GetVendorPartnershipsByCompanyIdQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type GetVendorPartnershipsByCompanyIdQuery = {
  company_vendor_partnerships: Array<VendorPartnershipLimitedFragment>;
};

export type CompanyVendorPartnershipForVendorQueryVariables = Exact<{
  companyId: Scalars["uuid"];
  vendorId: Scalars["uuid"];
}>;

export type CompanyVendorPartnershipForVendorQuery = {
  company_vendor_partnerships: Array<
    Pick<CompanyVendorPartnerships, "id"> & {
      vendor_bank_account?: Maybe<BankAccountForVendorFragment>;
    }
  >;
};

export type CompanyVendorPartnershipForCustomerQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type CompanyVendorPartnershipForCustomerQuery = {
  company_vendor_partnerships: Array<
    Pick<CompanyVendorPartnerships, "id"> & {
      vendor_bank_account?: Maybe<BankAccountForVendorFragment>;
      company: Pick<Companies, "id" | "name">;
      vendor?: Maybe<
        Pick<Vendors, "id" | "name"> & {
          users: Array<Pick<Users, "id"> & UserFragment>;
        }
      >;
      vendor_contacts: Array<
        Pick<CompanyVendorContacts, "id" | "is_active"> & {
          user: Pick<Users, "id"> & UserFragment;
        }
      >;
    }
  >;
};

export type GetVendorPartnershipForContactsForCustomersQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetVendorPartnershipForContactsForCustomersQuery = {
  company_vendor_contacts: Array<
    Pick<CompanyVendorContacts, "id" | "vendor_user_id" | "is_active"> & {
      user: Pick<Users, "id"> & UserFragment;
    }
  >;
};

export type ContactFragment = Pick<
  Users,
  | "id"
  | "company_id"
  | "full_name"
  | "first_name"
  | "last_name"
  | "email"
  | "phone_number"
  | "created_at"
>;

export type AllCompanyUsersForBankFragment = {
  parent_company?: Maybe<
    Pick<ParentCompanies, "id"> & { users: Array<ContactFragment> }
  >;
  users: Array<ContactFragment>;
};

export type CustomersWithMetadataFragment = Pick<Companies, "id"> & {
  financial_summaries: Array<
    Pick<FinancialSummaries, "id"> & FinancialSummaryFragment
  >;
  settings?: Maybe<Pick<CompanySettings, "id"> & CompanySettingsFragment>;
  ebba_applications: Array<
    Pick<EbbaApplications, "id"> & EbbaApplicationFragment
  >;
  most_recent_surveillance_result: Array<
    Pick<CustomerSurveillanceResults, "id"> & CustomerSurveillanceResultFragment
  >;
  parent_company?: Maybe<Pick<ParentCompanies, "id"> & ParentCompanyFragment>;
} & CustomerForBankFragment;

export type CustomerSurveillanceFragment = {
  settings?: Maybe<Pick<CompanySettings, "id"> & CompanySettingsFragment>;
  loans: Array<Pick<Loans, "status" | "amount">>;
  target_surveillance_result: Array<CustomerSurveillanceResultFragment>;
  all_surveillance_results: Array<CustomerSurveillanceResultFragment>;
  most_recent_borrowing_base: Array<
    Pick<EbbaApplications, "id"> & EbbaApplicationFragment
  >;
  most_recent_financial_report: Array<
    Pick<EbbaApplications, "id"> & EbbaApplicationFragment
  >;
  most_recent_financial_summary: Array<
    Pick<FinancialSummaries, "id"> & FinancialSummaryFragment
  >;
} & CustomerForBankFragment;

export type CompanyPayorContactFragment = Pick<
  CompanyPayorContacts,
  "id" | "payor_user_id"
> & { user: Pick<Users, "id"> & ContactFragment };

export type CompanyVendorContactFragment = Pick<
  CompanyVendorContacts,
  "id" | "vendor_user_id" | "is_active"
> & { user: Pick<Users, "id"> & ContactFragment };

export type CompanyDealOwnersFragment = {
  underwriter_user?: Maybe<Pick<Users, "id" | "full_name">>;
  business_development_user?: Maybe<Pick<Users, "id" | "full_name">>;
  client_success_user?: Maybe<Pick<Users, "id" | "full_name">>;
};

export type CustomerForBankFragment = Pick<
  Companies,
  | "id"
  | "identifier"
  | "name"
  | "contract_name"
  | "employer_identification_number"
  | "dba_name"
  | "address"
  | "country"
  | "state"
  | "city"
  | "zip_code"
  | "phone_number"
  | "debt_facility_status"
  | "surveillance_status"
  | "surveillance_status_note"
  | "qualify_for"
  | "is_payor"
  | "is_vendor"
  | "is_customer"
>;

export type CompanySettingsFragment = Pick<
  CompanySettings,
  | "id"
  | "advances_bespoke_bank_account_id"
  | "collections_bespoke_bank_account_id"
  | "two_factor_message_method"
  | "is_dummy_account"
  | "client_success_user_id"
  | "business_development_user_id"
  | "underwriter_user_id"
  | "interest_end_date"
  | "late_fees_end_date"
> &
  CompanySettingsLimitedFragment;

export type BankAccountFragment = Pick<BankAccounts, "id"> &
  BankAccountLimitedFragment;

export type CompanyForDebtFacilityFragment = Pick<
  Companies,
  "id" | "identifier" | "name" | "dba_name" | "state" | "debt_facility_status"
> & {
  contract?: Maybe<Pick<Contracts, "id"> & ContractFragment>;
  most_recent_financial_summary: Array<
    Pick<FinancialSummaries, "id" | "product_type">
  >;
  most_recent_contract: Array<Pick<Contracts, "id"> & ContractFragment>;
};

export type MetrcApiKeyLimitedFragment = Pick<
  MetrcApiKeys,
  | "id"
  | "created_at"
  | "updated_at"
  | "permissions_refreshed_at"
  | "last_used_at"
  | "is_functioning"
  | "status_codes_payload"
  | "us_state"
  | "use_saved_licenses_only"
>;

export type MetrcApiKeyFragment = Pick<
  MetrcApiKeys,
  "id" | "facilities_payload" | "permissions_payload"
> &
  MetrcApiKeyLimitedFragment;

export type VendorFragment = Pick<
  Vendors,
  | "id"
  | "address"
  | "country"
  | "state"
  | "city"
  | "zip_code"
  | "phone_number"
  | "is_cannabis"
> &
  VendorLimitedFragment;

export type PurchaseOrderFragment = Pick<
  PurchaseOrders,
  "id" | "bank_note" | "history"
> &
  PurchaseOrderLimitedFragment;

export type PurchaseOrderNewFragment = Pick<
  PurchaseOrders,
  "id" | "bank_note" | "all_bank_notes" | "history"
> &
  PurchaseOrderLimitedNewFragment;

export type PurchaseOrderForDebtFacilityFragment = Pick<
  PurchaseOrders,
  "id" | "bank_note"
> &
  PurchaseOrderLimitedFragment;

export type LoanFragment = Pick<Loans, "id" | "loan_report_id" | "notes"> &
  LoanLimitedFragment;

export type LoanForDebtFacilityFragment = Pick<
  Loans,
  | "id"
  | "loan_report_id"
  | "notes"
  | "company_id"
  | "loan_type"
  | "artifact_id"
  | "identifier"
  | "disbursement_identifier"
  | "status"
  | "rejection_note"
  | "payment_status"
  | "amount"
  | "requested_payment_date"
  | "origination_date"
  | "maturity_date"
  | "adjusted_maturity_date"
  | "outstanding_principal_balance"
  | "outstanding_interest"
  | "outstanding_fees"
  | "requested_at"
  | "approved_at"
  | "rejected_at"
  | "funded_at"
  | "closed_at"
> & {
  company: Pick<Companies, "id" | "identifier"> &
    CompanyForDebtFacilityFragment;
};

export type LoanReportFragment = Pick<
  LoanReports,
  | "id"
  | "repayment_date"
  | "total_principal_paid"
  | "total_interest_paid"
  | "total_fees_paid"
  | "financing_period"
  | "financing_day_limit"
  | "debt_facility_status"
  | "debt_facility_id"
  | "debt_facility_added_date"
  | "debt_facility_waiver_date"
  | "debt_facility_waiver_expiration_date"
>;

export type LoanArtifactFragment = Pick<Loans, "id"> & {
  invoice?: Maybe<Pick<Invoices, "id"> & InvoiceFragment>;
  line_of_credit?: Maybe<Pick<LineOfCredits, "id"> & LineOfCreditFragment>;
  purchase_order?: Maybe<Pick<PurchaseOrders, "id"> & PurchaseOrderFragment>;
} & LoanArtifactLimitedFragment;

export type PayorFragment = Pick<
  Payors,
  "id" | "address" | "country" | "state" | "city" | "zip_code" | "phone_number"
> &
  PayorLimitedFragment;

export type PayorBankOnlyFragment = Pick<
  Payors,
  "id" | "address" | "country" | "state" | "city" | "zip_code" | "phone_number"
>;

export type PayorCombinedFragment = Pick<Payors, "id"> &
  PayorBankOnlyFragment &
  PayorLimitedFragment;

export type PartnershipRequestFragment = Pick<
  CompanyPartnershipRequests,
  | "id"
  | "requesting_company_id"
  | "two_factor_message_method"
  | "company_type"
  | "company_name"
  | "license_info"
  | "is_cannabis"
  | "user_info"
  | "request_info"
  | "created_at"
  | "requested_by_user_id"
  | "settled_by_user_id"
  | "settled_at"
> & {
  requesting_company: Pick<Companies, "id" | "name">;
  requested_by_user?: Maybe<Pick<Users, "full_name">>;
};

export type PartnershipInvitationFragment = Pick<
  CompanyPartnershipInvitations,
  | "id"
  | "requesting_company_id"
  | "email"
  | "requested_at"
  | "closed_at"
  | "metadata_info"
  | "created_at"
> & { requesting_company: Pick<Companies, "id" | "name"> };

export type VendorPartnershipFragment = Pick<
  CompanyVendorPartnerships,
  "id" | "vendor_bank_id"
> & {
  vendor_bank_account?: Maybe<Pick<BankAccounts, "id" | "verified_at">>;
} & VendorPartnershipLimitedFragment;

export type PayorPartnershipFragment = Pick<CompanyPayorPartnerships, "id"> &
  PayorPartnershipLimitedFragment;

export type PaymentFragment = Pick<
  Payments,
  "id" | "bank_note" | "created_at"
> & {
  company: Pick<
    Companies,
    "id" | "name" | "identifier" | "state" | "debt_facility_status"
  > & {
    most_recent_financial_summary: Array<
      Pick<FinancialSummaries, "id" | "product_type">
    >;
    contract?: Maybe<Pick<Contracts, "id" | "product_type">>;
  };
} & PaymentLimitedFragment;

export type PaymentWithTransactionsFragment = Pick<Payments, "id"> & {
  transactions: Array<
    Pick<
      Transactions,
      "id" | "amount" | "to_fees" | "to_interest" | "to_principal"
    >
  >;
} & PaymentFragment;

export type PaymentBankOnlyFragment = Pick<
  Payments,
  "id" | "bank_note" | "created_at"
>;

export type PaymentCombinedFragment = Pick<Payments, "id"> &
  PaymentBankOnlyFragment &
  PaymentLimitedFragment;

export type TransactionFragment = Pick<
  Transactions,
  | "id"
  | "created_at"
  | "loan_id"
  | "payment_id"
  | "type"
  | "subtype"
  | "amount"
  | "effective_date"
  | "to_principal"
  | "to_interest"
  | "to_fees"
>;

export type TransactionWithLoanFragment = Pick<
  Transactions,
  | "id"
  | "created_at"
  | "loan_id"
  | "payment_id"
  | "type"
  | "subtype"
  | "amount"
  | "effective_date"
  | "to_principal"
  | "to_interest"
  | "to_fees"
> & { loan?: Maybe<Pick<Loans, "id"> & LoanFragment> };

export type TransactionExtendedFragment = Pick<Transactions, "id"> & {
  payment: Pick<Payments, "id"> & {
    company: Pick<Companies, "id" | "name"> & {
      contract?: Maybe<Pick<Contracts, "id" | "product_type">>;
      most_recent_financial_summary: Array<
        Pick<FinancialSummaries, "id" | "product_type">
      >;
    };
  } & PaymentFragment;
  loan?: Maybe<Pick<Loans, "id" | "identifier">>;
} & TransactionFragment;

export type BankFinancialSummaryFragment = Pick<
  BankFinancialSummaries,
  | "id"
  | "updated_at"
  | "date"
  | "product_type"
  | "adjusted_total_limit"
  | "total_outstanding_principal"
  | "total_outstanding_principal_past_due"
  | "total_outstanding_interest"
  | "total_outstanding_fees"
  | "total_principal_in_requested_state"
  | "available_limit"
  | "interest_accrued_today"
>;

export type AsyncPipelineLimitedFragment = Pick<
  AsyncPipelines,
  "id" | "created_at" | "updated_at" | "name" | "status"
>;

export type AsyncPipelineFragment = Pick<
  AsyncPipelines,
  "id" | "internal_state" | "params"
> &
  AsyncPipelineLimitedFragment;

export type MetrcDownloadSummaryLimitedFragment = Pick<
  MetrcDownloadSummaries,
  | "id"
  | "company_id"
  | "metrc_api_key_id"
  | "license_number"
  | "date"
  | "status"
  | "harvests_status"
  | "packages_status"
  | "plant_batches_status"
  | "plants_status"
  | "sales_status"
  | "transfers_status"
  | "updated_at"
>;

export type MetrcDownloadSummaryFragment = Pick<
  MetrcDownloadSummaries,
  "id" | "num_retries" | "err_details"
> &
  MetrcDownloadSummaryLimitedFragment;

export type DebtFacilityFragment = Pick<
  DebtFacilities,
  "id" | "name" | "product_types"
> & {
  maximum_capacities: Array<
    Pick<DebtFacilityCapacities, "id"> & DebtFacilityCapacityLimitedFragment
  >;
  drawn_capacities: Array<
    Pick<DebtFacilityCapacities, "id"> & DebtFacilityCapacityLimitedFragment
  >;
};

export type DebtFacilityCapacityLimitedFragment = Pick<
  DebtFacilityCapacities,
  | "id"
  | "amount"
  | "capacity_type"
  | "changed_at"
  | "changed_by"
  | "debt_facility_id"
>;

export type DebtFacilityReportCompanyDetailsFragment = Pick<
  Companies,
  "id" | "name" | "debt_facility_status" | "state" | "identifier"
> & {
  most_recent_contract: Array<
    Pick<Contracts, "id" | "end_date" | "product_type">
  >;
  financial_summaries: Array<
    Pick<FinancialSummaries, "id" | "product_type" | "loans_info">
  >;
};

export type CompanyForOpenLoansByDebtFacilityStatusesFragment = Pick<
  Companies,
  "id"
> & {
  loans: Array<Pick<Loans, "id"> & OpenLoanForDebtFacilityFragment>;
} & DebtFacilityReportCompanyDetailsFragment;

export type CompanyForByDebtFacilityIdFragment = Pick<Companies, "id"> & {
  loans: Array<Pick<Loans, "id"> & OpenLoanForDebtFacilityFragment>;
} & DebtFacilityReportCompanyDetailsFragment;

export type CompanyForDebtFacilityLoansByIdFragment = Pick<Companies, "id"> & {
  loans: Array<Pick<Loans, "id"> & OpenLoanForDebtFacilityFragment>;
} & DebtFacilityReportCompanyDetailsFragment;

export type CompanyForDebtFacilityReportFragment = Pick<Companies, "id"> & {
  loans: Array<Pick<Loans, "id"> & OpenLoanForDebtFacilityFragment>;
} & DebtFacilityReportCompanyDetailsFragment;

export type OpenLoanForDebtFacilityFragment = {
  loan_report?: Maybe<
    Pick<LoanReports, "id"> & {
      debt_facility?: Maybe<Pick<DebtFacilities, "id"> & DebtFacilityFragment>;
    } & LoanReportFragment
  >;
  purchase_order?: Maybe<
    Pick<PurchaseOrders, "id"> & PurchaseOrderForDebtFacilityFragment
  >;
  invoice?: Maybe<Pick<Invoices, "id"> & InvoiceFragment>;
  line_of_credit?: Maybe<Pick<LineOfCredits, "id"> & LineOfCreditFragment>;
  transactions: Array<Pick<Transactions, "id" | "effective_date">>;
  repayments: Array<Pick<Transactions, "id"> & TransactionFragment>;
} & LoanForDebtFacilityFragment;

export type DebtFacilityEventFragment = Pick<
  DebtFacilityEvents,
  | "id"
  | "company_id"
  | "loan_report_id"
  | "event_amount"
  | "event_category"
  | "event_comments"
  | "event_date"
  | "event_payload"
>;

export type CustomerSurveillanceResultFragment = Pick<
  CustomerSurveillanceResults,
  | "id"
  | "bank_note"
  | "qualifying_product"
  | "qualifying_date"
  | "company_id"
  | "surveillance_status"
  | "surveillance_info"
>;

export type VendorChangeRequestFragment = Pick<
  VendorChangeRequests,
  | "id"
  | "status"
  | "category"
  | "is_deleted"
  | "approved_at"
  | "created_at"
  | "deleted_at"
> &
  VendorChangeRequestLimitedFragment;

export type AsyncJobFragment = Pick<
  AsyncJobs,
  | "id"
  | "name"
  | "queued_at"
  | "initialized_at"
  | "started_at"
  | "ended_at"
  | "submitted_by_user_id"
  | "status"
  | "is_high_priority"
  | "job_payload"
  | "retry_payload"
  | "err_details"
  | "num_retries"
> & { submitted_by_user?: Maybe<Pick<Users, "id" | "full_name">> };

export type RecentMonthlyCalculationsFragment = Pick<
  Companies,
  "id" | "name"
> & {
  monthly_summary_calculations: Array<
    Pick<MonthlySummaryCalculations, "id"> & MonthlySummaryCalculationFragment
  >;
};

export type MonthlySummaryCalculationFragment = Pick<
  MonthlySummaryCalculations,
  "id" | "report_month"
>;

export type BespokeCatalogBrandFragment = Pick<
  BespokeCatalogBrands,
  "id" | "brand_name"
> & { parent_company?: Maybe<Pick<ParentCompanies, "id" | "name">> };

export type BespokeCatalogSkuGroupFragment = Pick<
  BespokeCatalogSkuGroups,
  "id" | "sku_group_name" | "unit_quantity" | "unit_of_measure"
> & {
  bespoke_catalog_brand: Pick<BespokeCatalogBrands, "id"> &
    BespokeCatalogBrandFragment;
};

export type BespokeCatalogSkuFragment = Pick<
  BespokeCatalogSkus,
  "id" | "sku"
> & {
  bespoke_catalog_sku_group: Pick<BespokeCatalogSkuGroups, "id"> &
    BespokeCatalogSkuGroupFragment;
};

export type MetrcToBespokeCatalogSkuFragment = Pick<
  MetrcToBespokeCatalogSkus,
  | "id"
  | "product_name"
  | "product_category_name"
  | "wholesale_quantity"
  | "sku_confidence"
  | "is_sample"
> & {
  bespoke_catalog_sku?: Maybe<
    Pick<BespokeCatalogSkus, "id"> & BespokeCatalogSkuFragment
  >;
  user?: Maybe<Pick<Users, "id" | "full_name">>;
};

export type RepaymentForBankDataGridFragment = Pick<
  Payments,
  "id" | "requested_amount"
> & {
  transactions: Array<
    Pick<Transactions, "id"> & {
      loan?: Maybe<
        Pick<Loans, "id"> & LoanLimitedFragment & LoanArtifactLimitedFragment
      >;
      payment: Pick<Payments, "id"> & PaymentLimitedFragment;
    } & TransactionFragment
  >;
  company: Pick<Companies, "id"> & {
    most_recent_financial_summary: Array<
      Pick<FinancialSummaries, "id" | "product_type">
    >;
  };
  invoice?: Maybe<
    Pick<Invoices, "id"> & { payor?: Maybe<Pick<Payors, "id" | "name">> }
  >;
  settled_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
} & PaymentFragment;

export type CompanySettingsLimitedFragment = Pick<
  CompanySettings,
  | "id"
  | "company_id"
  | "advances_bank_account_id"
  | "collections_bank_account_id"
  | "vendor_agreement_docusign_template"
  | "vendor_onboarding_link"
  | "payor_agreement_docusign_template"
  | "feature_flags_payload"
  | "custom_messages_payload"
  | "has_autofinancing"
  | "is_autogenerate_repayments_enabled"
>;

export type CompanyLicenseLimitedFragment = Pick<
  CompanyLicenses,
  "id" | "company_id" | "file_id" | "license_number"
>;

export type CompanyLicenseLimitedAnonymousFragment = Pick<
  CompanyLicenses,
  "id" | "license_number" | "license_category" | "legal_name" | "us_state"
>;

export type BankAccountLimitedFragment = Pick<
  BankAccounts,
  | "id"
  | "is_cannabis_compliant"
  | "is_bank_international"
  | "verified_at"
  | "verified_date"
> &
  BankAccountForVendorFragment;

export type VendorLimitedFragment = Pick<
  Vendors,
  "id" | "name" | "dba_name"
> & { licenses: Array<CompanyLicenseLimitedFragment> };

export type PayorLimitedFragment = Pick<Payors, "id" | "name" | "dba_name"> & {
  licenses: Array<CompanyLicenseLimitedFragment>;
};

export type VendorPartnershipLimitedFragment = Pick<
  CompanyVendorPartnerships,
  "id" | "company_id" | "vendor_id" | "vendor_agreement_id" | "approved_at"
> & {
  company: Pick<Companies, "id" | "name">;
  vendor?: Maybe<Pick<Vendors, "id"> & VendorLimitedFragment>;
};

export type PayorPartnershipLimitedFragment = Pick<
  CompanyPayorPartnerships,
  "id" | "company_id" | "payor_id" | "payor_agreement_id" | "approved_at"
> & {
  company: Pick<Companies, "id" | "name">;
  payor?: Maybe<Pick<Payors, "id"> & PayorLimitedFragment>;
};

export type PurchaseOrderLimitedFragment = Pick<
  PurchaseOrders,
  | "id"
  | "company_id"
  | "vendor_id"
  | "order_number"
  | "order_date"
  | "delivery_date"
  | "net_terms"
  | "amount"
  | "amount_funded"
  | "is_cannabis"
  | "is_metrc_based"
  | "status"
  | "new_purchase_order_status"
  | "rejection_note"
  | "bank_rejection_note"
  | "bank_incomplete_note"
  | "incompleted_at"
  | "rejected_at"
  | "customer_note"
  | "created_at"
  | "requested_at"
  | "rejected_by_user_id"
  | "approved_at"
  | "approved_by_user_id"
  | "funded_at"
  | "closed_at"
  | "history"
  | "all_customer_notes"
> & {
  company: Pick<Companies, "id"> & CompanyLimitedFragment;
  vendor?: Maybe<Pick<Vendors, "id"> & VendorLimitedFragment>;
};

export type PurchaseOrderLimitedNewFragment = Pick<
  PurchaseOrders,
  | "id"
  | "company_id"
  | "vendor_id"
  | "order_number"
  | "order_date"
  | "delivery_date"
  | "net_terms"
  | "amount"
  | "amount_funded"
  | "is_cannabis"
  | "is_metrc_based"
  | "status"
  | "new_purchase_order_status"
  | "rejection_note"
  | "bank_rejection_note"
  | "bank_incomplete_note"
  | "incompleted_at"
  | "rejected_at"
  | "customer_note"
  | "created_at"
  | "requested_at"
  | "rejected_by_user_id"
  | "approved_at"
  | "approved_by_user_id"
  | "funded_at"
  | "closed_at"
  | "history"
  | "all_customer_notes"
> & {
  company: Pick<Companies, "id"> & CompanyLimitedFragment;
  vendor?: Maybe<Pick<Vendors, "id"> & VendorLimitedFragment>;
};

export type PurchaseOrderWithRelationshipsFragment = MakeOptional<
  Pick<PurchaseOrders, "id" | "bank_note">,
  "bank_note"
> & {
  company: Pick<Companies, "id"> & CompanyLimitedFragment;
  vendor?: Maybe<Pick<Vendors, "id"> & VendorLimitedFragment>;
  loans: Array<Pick<Loans, "id"> & LoanLimitedFragment>;
  purchase_order_files: Array<PurchaseOrderFileFragment>;
  purchase_order_metrc_transfers: Array<
    Pick<PurchaseOrderMetrcTransfers, "id"> &
      PurchaseOrderMetrcTransferWithRelationshipsFragment
  >;
} & PurchaseOrderLimitedFragment;

export type InvoiceLimitedFragment = Pick<
  Invoices,
  | "id"
  | "company_id"
  | "payor_id"
  | "invoice_number"
  | "subtotal_amount"
  | "total_amount"
  | "taxes_amount"
  | "invoice_date"
  | "invoice_due_date"
  | "is_cannabis"
  | "status"
  | "created_at"
  | "approved_at"
  | "funded_at"
  | "payment_requested_at"
  | "payment_confirmed_at"
  | "payment_rejected_at"
> & {
  company: Pick<Companies, "id"> & CompanyLimitedFragment;
  payor?: Maybe<Pick<Payors, "id"> & PayorLimitedFragment>;
};

export type LoanLimitedFragment = Pick<
  Loans,
  | "id"
  | "company_id"
  | "loan_type"
  | "artifact_id"
  | "identifier"
  | "disbursement_identifier"
  | "status"
  | "rejection_note"
  | "payment_status"
  | "amount"
  | "requested_payment_date"
  | "origination_date"
  | "maturity_date"
  | "adjusted_maturity_date"
  | "outstanding_principal_balance"
  | "outstanding_interest"
  | "outstanding_fees"
  | "requested_at"
  | "approved_at"
  | "rejected_at"
  | "funded_at"
  | "closed_at"
  | "customer_notes"
> & {
  company: Pick<Companies, "id" | "identifier"> & CompanyLimitedFragment;
  requested_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
  purchase_order?: Maybe<
    Pick<PurchaseOrders, "id"> & PurchaseOrderLimitedFragment
  >;
};

export type LoanArtifactLimitedFragment = Pick<
  Loans,
  "id" | "loan_type" | "artifact_id" | "identifier"
> & {
  invoice?: Maybe<InvoiceLimitedFragment>;
  line_of_credit?: Maybe<Pick<LineOfCredits, "id"> & LineOfCreditFragment>;
  purchase_order?: Maybe<
    Pick<PurchaseOrders, "id"> & PurchaseOrderLimitedFragment
  >;
};

export type PaymentLimitedFragment = Pick<
  Payments,
  | "id"
  | "company_id"
  | "settlement_identifier"
  | "submitted_at"
  | "settled_at"
  | "reversed_at"
  | "type"
  | "method"
  | "requested_amount"
  | "amount"
  | "requested_payment_date"
  | "payment_date"
  | "deposit_date"
  | "settlement_date"
  | "items_covered"
  | "is_deleted"
> & {
  company: Pick<Companies, "id" | "name" | "identifier" | "state">;
  invoice?: Maybe<
    Pick<Invoices, "id"> & { payor?: Maybe<Pick<Payors, "id" | "name">> }
  >;
  submitted_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
  settled_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
};

export type VendorChangeRequestLimitedFragment = Pick<
  VendorChangeRequests,
  | "id"
  | "status"
  | "category"
  | "updated_at"
  | "created_at"
  | "requesting_user_id"
  | "requested_vendor_id"
  | "request_info"
>;

export type GetCustomersWithMetadataQueryVariables = Exact<{
  date?: Maybe<Scalars["date"]>;
}>;

export type GetCustomersWithMetadataQuery = {
  customers: Array<CustomersWithMetadataFragment>;
};

export type GetActiveCustomersWithMetadataQueryVariables = Exact<{
  date?: Maybe<Scalars["date"]>;
}>;

export type GetActiveCustomersWithMetadataQuery = {
  customers: Array<CustomersWithMetadataFragment>;
};

export type GetCustomersForDropdownQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCustomersForDropdownQuery = {
  customers: Array<Pick<Companies, "id"> & CustomerForBankFragment>;
};

export type GetActiveCustomersForDropdownQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetActiveCustomersForDropdownQuery = {
  customers: Array<Pick<Companies, "id"> & CustomerForBankFragment>;
};

export type CompanyVendorsQueryVariables = Exact<{
  companyId: Scalars["uuid"];
}>;

export type CompanyVendorsQuery = {
  company_vendor_partnerships: Array<{ vendor?: Maybe<Pick<Vendors, "name">> }>;
};

export type GetCompaniesWithMetadataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCompaniesWithMetadataQuery = {
  companies: Array<
    Pick<Companies, "id"> & {
      contract?: Maybe<Pick<Contracts, "id"> & ContractFragment>;
      settings?: Maybe<Pick<CompanySettings, "id"> & CompanySettingsFragment>;
    } & CompanyFragment
  >;
};

export type GetPartnershipRequestsCountForBankSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetPartnershipRequestsCountForBankSubscription = {
  company_partnership_requests: Array<Pick<CompanyPartnershipRequests, "id">>;
};

export type GetSettledPartnershipRequestsForBankSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetSettledPartnershipRequestsForBankSubscription = {
  company_partnership_requests: Array<
    Pick<CompanyPartnershipRequests, "id"> & PartnershipRequestFragment
  >;
};

export type GetPartnershipRequestsForBankSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetPartnershipRequestsForBankSubscription = {
  company_partnership_requests: Array<
    Pick<CompanyPartnershipRequests, "id"> & PartnershipRequestFragment
  >;
};

export type GetPartnershipRequestsForBankByRequestingCompanyIdAndTypeSubscriptionVariables =
  Exact<{
    requesting_company_id: Scalars["uuid"];
    company_type: CompanyTypeEnum;
  }>;

export type GetPartnershipRequestsForBankByRequestingCompanyIdAndTypeSubscription =
  {
    company_partnership_requests: Array<
      Pick<CompanyPartnershipRequests, "id"> & PartnershipRequestFragment
    >;
  };

export type GetPartnershipInvitationsForBankSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetPartnershipInvitationsForBankSubscription = {
  company_partnership_invitations: Array<
    Pick<CompanyPartnershipInvitations, "id"> & PartnershipInvitationFragment
  >;
};

export type GetPartnershipRequestsAndInvitesByRequestingCompanyIdQueryVariables =
  Exact<{
    requesting_company_id: Scalars["uuid"];
  }>;

export type GetPartnershipRequestsAndInvitesByRequestingCompanyIdQuery = {
  company_partnership_requests: Array<
    Pick<
      CompanyPartnershipRequests,
      "id" | "company_name" | "user_info" | "license_info" | "created_at"
    > & { requested_by_user?: Maybe<Pick<Users, "id" | "full_name">> }
  >;
  company_partnership_invitations: Array<
    Pick<
      CompanyPartnershipInvitations,
      "id" | "email" | "metadata_info" | "requested_at"
    > & { submitted_by_user?: Maybe<Pick<Users, "id" | "full_name">> }
  >;
};

export type GetPartnershipChangeRequestsForBankSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetPartnershipChangeRequestsForBankSubscription = {
  vendor_change_requests: Array<
    {
      approved_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
      reviewed_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
      requesting_user: Pick<Users, "id" | "full_name"> & {
        company?: Maybe<Pick<Companies, "id" | "name">>;
      };
      requested_vendor: Pick<Companies, "id" | "name">;
      requesting_company?: Maybe<Pick<Companies, "id" | "name">>;
    } & VendorChangeRequestFragment
  >;
};

export type GetPartnershipChangeDetailsByIdQueryVariables = Exact<{
  requestId: Scalars["uuid"];
}>;

export type GetPartnershipChangeDetailsByIdQuery = {
  vendor_change_requests_by_pk?: Maybe<
    {
      approved_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
      reviewed_by_user?: Maybe<Pick<Users, "id" | "full_name">>;
      requesting_user: Pick<Users, "id" | "full_name"> & {
        company?: Maybe<Pick<Companies, "id">>;
      };
      requested_vendor: Pick<Companies, "id" | "name"> & {
        users: Array<Pick<Users, "id"> & UserFragment>;
        company_vendor_partnerships_by_vendor: Array<
          Pick<CompanyVendorPartnerships, "id"> & {
            company: Pick<Companies, "id" | "name">;
          }
        >;
      };
      requesting_company?: Maybe<Pick<Companies, "id" | "name">>;
    } & VendorChangeRequestFragment
  >;
};

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetUserByIdQuery = {
  users_by_pk?: Maybe<
    Pick<Users, "id"> & {
      company?: Maybe<Pick<Companies, "id" | "name">>;
    } & UserFragment
  >;
};

export type GetUserMenuInfoQueryVariables = Exact<{
  user_id: Scalars["uuid"];
}>;

export type GetUserMenuInfoQuery = {
  users_by_pk?: Maybe<
    Pick<Users, "id"> & {
      parent_company?: Maybe<
        Pick<ParentCompanies, "id" | "name"> & {
          customer_companies: Array<Pick<Companies, "id" | "name">>;
        }
      >;
    } & UserFragment
  >;
};

export type UsersByEmailQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type UsersByEmailQuery = {
  users: Array<Pick<Users, "id" | "company_id" | "role">>;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars["uuid"];
  user: UsersSetInput;
}>;

export type UpdateUserMutation = { update_users_by_pk?: Maybe<UserFragment> };

export const BlazePreapprovalFragmentDoc = gql`
  fragment BlazePreapproval on blaze_preapprovals {
    id
    external_blaze_company_id
    external_blaze_shop_id
    max_credit_limit
    annual_interest_rate
    expiration_date
  }
`;
export const UserFragmentDoc = gql`
  fragment User on users {
    id
    first_name
    last_name
    full_name
    email
    phone_number
    role
    company_role
    created_at
  }
`;
export const UserBankOnlyFragmentDoc = gql`
  fragment UserBankOnly on users {
    id
    company_role_new
  }
`;
export const UserWrapperFragmentDoc = gql`
  fragment UserWrapper on users {
    id
    ...User
    ...UserBankOnly @include(if: $isBankUser)
  }
  ${UserFragmentDoc}
  ${UserBankOnlyFragmentDoc}
`;
export const CompanyAgreementFragmentDoc = gql`
  fragment CompanyAgreement on company_agreements {
    id
    company_id
    file_id
  }
`;
export const CompanyFacilityFragmentDoc = gql`
  fragment CompanyFacility on company_facilities {
    id
    name
    address
  }
`;
export const CompanyLicenseLimitedFragmentDoc = gql`
  fragment CompanyLicenseLimited on company_licenses {
    id
    company_id
    file_id
    license_number
  }
`;
export const CompanyLicenseFragmentDoc = gql`
  fragment CompanyLicense on company_licenses {
    id
    legal_name
    dba_name
    license_category
    license_description
    license_status
    us_state
    expiration_date
    facility_row_id
    is_underwriting_enabled
    ...CompanyLicenseLimited
  }
  ${CompanyLicenseLimitedFragmentDoc}
`;
export const CompanyLimitedFragmentDoc = gql`
  fragment CompanyLimited on companies {
    id
    parent_company_id
    name
    dba_name
  }
`;
export const CompanyFragmentDoc = gql`
  fragment Company on companies {
    id
    identifier
    is_customer
    is_payor
    is_vendor
    is_super_vendor
    contract_name
    employer_identification_number
    address
    phone_number
    ...CompanyLimited
  }
  ${CompanyLimitedFragmentDoc}
`;
export const CompanySettingsLimitedFragmentDoc = gql`
  fragment CompanySettingsLimited on company_settings {
    id
    company_id
    advances_bank_account_id
    collections_bank_account_id
    vendor_agreement_docusign_template
    vendor_onboarding_link
    payor_agreement_docusign_template
    feature_flags_payload
    custom_messages_payload
    has_autofinancing
    is_autogenerate_repayments_enabled
  }
`;
export const CompanySettingsFragmentDoc = gql`
  fragment CompanySettings on company_settings {
    id
    advances_bespoke_bank_account_id
    collections_bespoke_bank_account_id
    two_factor_message_method
    is_dummy_account
    client_success_user_id
    business_development_user_id
    underwriter_user_id
    interest_end_date
    late_fees_end_date
    ...CompanySettingsLimited
  }
  ${CompanySettingsLimitedFragmentDoc}
`;
export const CompanyWithSettingsFragmentDoc = gql`
  fragment CompanyWithSettings on companies {
    id
    identifier
    is_customer
    is_payor
    is_vendor
    is_super_vendor
    contract_name
    employer_identification_number
    address
    phone_number
    state
    timezone
    created_at
    ...CompanyLimited
    settings {
      id
      ...CompanySettings
    }
  }
  ${CompanyLimitedFragmentDoc}
  ${CompanySettingsFragmentDoc}
`;
export const FileFragmentDoc = gql`
  fragment File on files {
    id
    name
    path
  }
`;
export const EbbaApplicationFileFragmentDoc = gql`
  fragment EbbaApplicationFile on ebba_application_files {
    ebba_application_id
    file_id
    file {
      id
      ...File
    }
  }
  ${FileFragmentDoc}
`;
export const VendorLimitedFragmentDoc = gql`
  fragment VendorLimited on vendors {
    id
    name
    dba_name
    licenses(
      where: {
        _or: [
          { is_deleted: { _is_null: true } }
          { is_deleted: { _eq: false } }
        ]
      }
    ) {
      ...CompanyLicenseLimited
    }
  }
  ${CompanyLicenseLimitedFragmentDoc}
`;
export const PurchaseOrderLimitedFragmentDoc = gql`
  fragment PurchaseOrderLimited on purchase_orders {
    id
    company_id
    vendor_id
    order_number
    order_date
    delivery_date
    net_terms
    amount
    amount_funded
    is_cannabis
    is_metrc_based
    status
    new_purchase_order_status
    rejection_note
    bank_rejection_note
    bank_incomplete_note
    incompleted_at
    rejected_at
    customer_note
    created_at
    requested_at
    rejected_by_user_id
    approved_at
    approved_by_user_id
    funded_at
    closed_at
    history
    company {
      id
      ...CompanyLimited
    }
    vendor {
      id
      ...VendorLimited
    }
    all_customer_notes
  }
  ${CompanyLimitedFragmentDoc}
  ${VendorLimitedFragmentDoc}
`;
export const LoanLimitedFragmentDoc = gql`
  fragment LoanLimited on loans {
    id
    company_id
    loan_type
    artifact_id
    identifier
    disbursement_identifier
    status
    rejection_note
    payment_status
    amount
    requested_payment_date
    origination_date
    maturity_date
    adjusted_maturity_date
    outstanding_principal_balance
    outstanding_interest
    outstanding_fees
    requested_at
    approved_at
    rejected_at
    funded_at
    closed_at
    customer_notes
    company {
      id
      identifier
      ...CompanyLimited
    }
    requested_by_user {
      id
      full_name
    }
    purchase_order {
      id
      ...PurchaseOrderLimited
    }
  }
  ${CompanyLimitedFragmentDoc}
  ${PurchaseOrderLimitedFragmentDoc}
`;
export const LoanFragmentDoc = gql`
  fragment Loan on loans {
    id
    loan_report_id
    notes
    ...LoanLimited
  }
  ${LoanLimitedFragmentDoc}
`;
export const PurchaseOrderFileFragmentDoc = gql`
  fragment PurchaseOrderFile on purchase_order_files {
    purchase_order_id
    file_id
    file_type
    file {
      id
      ...File
    }
  }
  ${FileFragmentDoc}
`;
export const MetrcTransferLimitedFragmentDoc = gql`
  fragment MetrcTransferLimited on metrc_transfers {
    id
    us_state
    transfer_id
    manifest_number
    created_date
    shipper_facility_license_number
    shipper_facility_name
    lab_results_status
    last_modified_at
  }
`;
export const MetrcTransferFragmentDoc = gql`
  fragment MetrcTransfer on metrc_transfers {
    id
    transfer_payload
    ...MetrcTransferLimited
  }
  ${MetrcTransferLimitedFragmentDoc}
`;
export const MetrcDeliveryLimitedFragmentDoc = gql`
  fragment MetrcDeliveryLimited on metrc_deliveries {
    id
    us_state
    delivery_id
    transfer_row_id
    recipient_facility_license_number
    recipient_facility_name
    shipment_type_name
    shipment_transaction_type
    received_datetime
  }
`;
export const MetrcDeliveryFragmentDoc = gql`
  fragment MetrcDelivery on metrc_deliveries {
    id
    delivery_payload
    ...MetrcDeliveryLimited
  }
  ${MetrcDeliveryLimitedFragmentDoc}
`;
export const PurchaseOrderMetrcTransferWithRelationshipsFragmentDoc = gql`
  fragment PurchaseOrderMetrcTransferWithRelationships on purchase_order_metrc_transfers {
    id
    purchase_order_id
    metrc_transfer_id
    metrc_transfer {
      id
      ...MetrcTransfer
      metrc_deliveries {
        id
        ...MetrcDelivery
      }
    }
  }
  ${MetrcTransferFragmentDoc}
  ${MetrcDeliveryFragmentDoc}
`;
export const PurchaseOrderWithRelationshipsFragmentDoc = gql`
  fragment PurchaseOrderWithRelationships on purchase_orders {
    id
    ...PurchaseOrderLimited
    bank_note @include(if: $is_bank_user)
    company {
      id
      ...CompanyLimited
    }
    vendor {
      id
      ...VendorLimited
    }
    loans(
      where: {
        _and: [
          { loan_type: { _eq: purchase_order } }
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
        ]
      }
    ) {
      id
      ...LoanLimited
    }
    purchase_order_files {
      ...PurchaseOrderFile
    }
    purchase_order_metrc_transfers {
      id
      ...PurchaseOrderMetrcTransferWithRelationships
    }
  }
  ${PurchaseOrderLimitedFragmentDoc}
  ${CompanyLimitedFragmentDoc}
  ${VendorLimitedFragmentDoc}
  ${LoanLimitedFragmentDoc}
  ${PurchaseOrderFileFragmentDoc}
  ${PurchaseOrderMetrcTransferWithRelationshipsFragmentDoc}
`;
export const LineOfCreditFragmentDoc = gql`
  fragment LineOfCredit on line_of_credits {
    id
    company_id
    recipient_vendor_id
    is_credit_for_vendor
    customer_note
    recipient_vendor {
      id
      ...CompanyLimited
    }
  }
  ${CompanyLimitedFragmentDoc}
`;
export const LoanReportFragmentDoc = gql`
  fragment LoanReport on loan_reports {
    id
    repayment_date
    total_principal_paid
    total_interest_paid
    total_fees_paid
    financing_period
    financing_day_limit
    debt_facility_status
    debt_facility_id
    debt_facility_added_date
    debt_facility_waiver_date
    debt_facility_waiver_expiration_date
  }
`;
export const DebtFacilityCapacityLimitedFragmentDoc = gql`
  fragment DebtFacilityCapacityLimited on debt_facility_capacities {
    id
    amount
    capacity_type
    changed_at
    changed_by
    debt_facility_id
  }
`;
export const DebtFacilityFragmentDoc = gql`
  fragment DebtFacility on debt_facilities {
    id
    name
    product_types
    maximum_capacities: debt_facility_capacities(
      order_by: [{ changed_at: desc }]
      where: { capacity_type: { _eq: "maximum" } }
    ) {
      id
      ...DebtFacilityCapacityLimited
    }
    drawn_capacities: debt_facility_capacities(
      order_by: [{ changed_at: desc }]
      where: { capacity_type: { _eq: "drawn" } }
    ) {
      id
      ...DebtFacilityCapacityLimited
    }
  }
  ${DebtFacilityCapacityLimitedFragmentDoc}
`;
export const LoanWithRelationshipsFragmentDoc = gql`
  fragment LoanWithRelationships on loans {
    id
    ...Loan
    purchase_order {
      id
      ...PurchaseOrderWithRelationships
    }
    line_of_credit {
      id
      ...LineOfCredit
    }
    company {
      id
      ...CompanyLimited
    }
    loan_report @include(if: $is_bank_user) {
      ...LoanReport
      debt_facility {
        ...DebtFacility
      }
    }
  }
  ${LoanFragmentDoc}
  ${PurchaseOrderWithRelationshipsFragmentDoc}
  ${LineOfCreditFragmentDoc}
  ${CompanyLimitedFragmentDoc}
  ${LoanReportFragmentDoc}
  ${DebtFacilityFragmentDoc}
`;
export const FinancialSummaryFragmentDoc = gql`
  fragment FinancialSummary on financial_summaries {
    id
    company_id
    date
    product_type
    daily_interest_rate
    available_limit
    adjusted_total_limit
    total_outstanding_principal
    total_outstanding_interest
    total_outstanding_fees
    total_principal_in_requested_state
    total_outstanding_principal_for_interest
    total_outstanding_principal_past_due
    total_amount_to_pay_interest_on
    minimum_monthly_payload
    account_level_balance_payload
    day_volume_threshold_met
    interest_accrued_today
    late_fees_accrued_today
    needs_recompute
    minimum_interest_duration
    minimum_interest_amount
    minimum_interest_remaining
    most_overdue_loan_days
    accounting_total_outstanding_principal
    accounting_total_outstanding_interest
    accounting_total_outstanding_late_fees
    accounting_interest_accrued_today
    accounting_late_fees_accrued_today
  }
`;
export const FinancialSummaryWithLoansInfoFragmentDoc = gql`
  fragment FinancialSummaryWithLoansInfo on financial_summaries {
    id
    loans_info
    ...FinancialSummary
  }
  ${FinancialSummaryFragmentDoc}
`;
export const InvoiceFileFragmentDoc = gql`
  fragment InvoiceFile on invoice_files {
    invoice_id
    file_id
    file_type
    file {
      id
      ...File
    }
  }
  ${FileFragmentDoc}
`;
export const CompanyDeliveryFragmentDoc = gql`
  fragment CompanyDelivery on company_deliveries {
    id
    company_id
    license_number
    us_state
    vendor_id
    payor_id
    transfer_row_id
    transfer_type
    delivery_row_id
    delivery_type
  }
`;
export const MetrcTransferPackageFragmentDoc = gql`
  fragment MetrcTransferPackage on metrc_transfer_packages {
    id
    us_state
    package_id
    transfer_row_id
    delivery_row_id
    delivery_id
    package_label
    package_type
    product_name
    product_category_name
    shipped_quantity
    shipper_wholesale_price
    package_payload
    lab_results_status
    metrc_transfer {
      id
      manifest_number
    }
  }
`;
export const MetrcPackageFragmentDoc = gql`
  fragment MetrcPackage on metrc_packages {
    id
    company_id
    package_id
    last_modified_at
    type
    package_label
    package_type
    product_name
    product_category_name
    packaged_date
    quantity
    unit_of_measure
    package_payload
  }
`;
export const MetrcSalesTransactionFragmentDoc = gql`
  fragment MetrcSalesTransaction on metrc_sales_transactions {
    id
    product_name
    product_category_name
    us_state
    is_deleted
    quantity_sold
    total_price
    unit_of_measure
  }
`;
export const PurchaseOrderMetrcTransferFragmentDoc = gql`
  fragment PurchaseOrderMetrcTransfer on purchase_order_metrc_transfers {
    id
    purchase_order_id
    metrc_transfer_id
  }
`;
export const ContactFragmentDoc = gql`
  fragment Contact on users {
    id
    company_id
    full_name
    first_name
    last_name
    email
    phone_number
    created_at
  }
`;
export const AllCompanyUsersForBankFragmentDoc = gql`
  fragment AllCompanyUsersForBank on companies {
    parent_company {
      id
      users {
        ...Contact
      }
    }
    users {
      ...Contact
    }
  }
  ${ContactFragmentDoc}
`;
export const CustomerForBankFragmentDoc = gql`
  fragment CustomerForBank on companies {
    id
    identifier
    name
    contract_name
    employer_identification_number
    dba_name
    address
    country
    state
    city
    zip_code
    phone_number
    debt_facility_status
    surveillance_status
    surveillance_status_note
    qualify_for
    is_payor
    is_vendor
    is_customer
  }
`;
export const EbbaApplicationFragmentDoc = gql`
  fragment EbbaApplication on ebba_applications {
    id
    company_id
    category
    application_date
    monthly_accounts_receivable
    monthly_inventory
    monthly_cash
    amount_cash_in_daca
    amount_custom
    amount_custom_note
    calculated_borrowing_base
    status
    rejection_note
    expires_date
    created_at
    approved_at
    submitted_by_user {
      id
      full_name
    }
  }
`;
export const CustomerSurveillanceResultFragmentDoc = gql`
  fragment CustomerSurveillanceResult on customer_surveillance_results {
    id
    bank_note
    qualifying_product
    qualifying_date
    company_id
    surveillance_status
    surveillance_info
  }
`;
export const ParentCompanyFragmentDoc = gql`
  fragment ParentCompany on parent_companies {
    id
    name
  }
`;
export const CustomersWithMetadataFragmentDoc = gql`
  fragment CustomersWithMetadata on companies {
    id
    ...CustomerForBank
    financial_summaries(where: { date: { _eq: $date } }) {
      id
      ...FinancialSummary
    }
    settings {
      id
      ...CompanySettings
    }
    ebba_applications(
      limit: 1
      order_by: [{ application_date: desc }, { created_at: desc }]
      where: { status: { _eq: approved } }
    ) {
      id
      ...EbbaApplication
    }
    most_recent_surveillance_result: customer_surveillance_results(
      limit: 1
      order_by: { qualifying_date: desc }
      where: {
        _or: [
          { is_deleted: { _is_null: true } }
          { is_deleted: { _eq: false } }
        ]
      }
    ) {
      id
      ...CustomerSurveillanceResult
    }
    parent_company {
      id
      ...ParentCompany
    }
  }
  ${CustomerForBankFragmentDoc}
  ${FinancialSummaryFragmentDoc}
  ${CompanySettingsFragmentDoc}
  ${EbbaApplicationFragmentDoc}
  ${CustomerSurveillanceResultFragmentDoc}
  ${ParentCompanyFragmentDoc}
`;
export const CustomerSurveillanceFragmentDoc = gql`
  fragment CustomerSurveillance on companies {
    ...CustomerForBank
    settings {
      id
      ...CompanySettings
    }
    loans(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { funded_at: { _is_null: true } }
          { closed_at: { _is_null: true } }
          { requested_at: { _is_null: false } }
        ]
      }
    ) {
      status
      amount
    }
    target_surveillance_result: customer_surveillance_results(
      where: {
        _and: [
          { qualifying_date: { _eq: $target_date } }
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
        ]
      }
    ) {
      ...CustomerSurveillanceResult
    }
    all_surveillance_results: customer_surveillance_results(
      where: {
        _or: [
          { is_deleted: { _is_null: true } }
          { is_deleted: { _eq: false } }
        ]
      }
      order_by: { qualifying_date: desc }
    ) {
      ...CustomerSurveillanceResult
    }
    most_recent_borrowing_base: ebba_applications(
      limit: 1
      order_by: [{ application_date: desc }, { created_at: desc }]
      where: {
        _and: [
          { category: { _eq: "borrowing_base" } }
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
        ]
      }
    ) {
      id
      ...EbbaApplication
    }
    most_recent_financial_report: ebba_applications(
      limit: 1
      order_by: [{ application_date: desc }, { created_at: desc }]
      where: {
        _and: [
          { category: { _eq: "financial_report" } }
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
        ]
      }
    ) {
      id
      ...EbbaApplication
    }
    most_recent_financial_summary: financial_summaries(
      limit: 1
      order_by: { date: desc }
    ) {
      id
      ...FinancialSummary
    }
  }
  ${CustomerForBankFragmentDoc}
  ${CompanySettingsFragmentDoc}
  ${CustomerSurveillanceResultFragmentDoc}
  ${EbbaApplicationFragmentDoc}
  ${FinancialSummaryFragmentDoc}
`;
export const CompanyPayorContactFragmentDoc = gql`
  fragment CompanyPayorContact on company_payor_contacts {
    id
    payor_user_id
    user {
      id
      ...Contact
    }
  }
  ${ContactFragmentDoc}
`;
export const CompanyVendorContactFragmentDoc = gql`
  fragment CompanyVendorContact on company_vendor_contacts {
    id
    vendor_user_id
    is_active
    user {
      id
      ...Contact
    }
  }
  ${ContactFragmentDoc}
`;
export const CompanyDealOwnersFragmentDoc = gql`
  fragment CompanyDealOwners on company_settings {
    underwriter_user {
      id
      full_name
    }
    business_development_user {
      id
      full_name
    }
    client_success_user {
      id
      full_name
    }
  }
`;
export const BankAccountForVendorFragmentDoc = gql`
  fragment BankAccountForVendor on bank_accounts {
    id
    company_id
    bank_name
    account_title
    account_type
    account_number
    bank_address
    can_ach
    routing_number
    ach_default_memo
    can_wire
    is_wire_intermediary
    is_bank_international
    intermediary_bank_name
    intermediary_bank_address
    intermediary_account_name
    intermediary_account_number
    wire_routing_number
    recipient_name
    recipient_address
    recipient_address_2
    wire_default_memo
  }
`;
export const BankAccountLimitedFragmentDoc = gql`
  fragment BankAccountLimited on bank_accounts {
    id
    is_cannabis_compliant
    is_bank_international
    verified_at
    verified_date
    ...BankAccountForVendor
  }
  ${BankAccountForVendorFragmentDoc}
`;
export const BankAccountFragmentDoc = gql`
  fragment BankAccount on bank_accounts {
    id
    ...BankAccountLimited
  }
  ${BankAccountLimitedFragmentDoc}
`;
export const MetrcApiKeyLimitedFragmentDoc = gql`
  fragment MetrcApiKeyLimited on metrc_api_keys {
    id
    created_at
    updated_at
    permissions_refreshed_at
    last_used_at
    is_functioning
    status_codes_payload
    us_state
    use_saved_licenses_only
  }
`;
export const MetrcApiKeyFragmentDoc = gql`
  fragment MetrcApiKey on metrc_api_keys {
    id
    ...MetrcApiKeyLimited
    facilities_payload
    permissions_payload
  }
  ${MetrcApiKeyLimitedFragmentDoc}
`;
export const VendorFragmentDoc = gql`
  fragment Vendor on vendors {
    id
    address
    country
    state
    city
    zip_code
    phone_number
    is_cannabis
    ...VendorLimited
  }
  ${VendorLimitedFragmentDoc}
`;
export const PurchaseOrderLimitedNewFragmentDoc = gql`
  fragment PurchaseOrderLimitedNew on purchase_orders {
    id
    company_id
    vendor_id
    order_number
    order_date
    delivery_date
    net_terms
    amount
    amount_funded
    is_cannabis
    is_metrc_based
    status
    new_purchase_order_status
    rejection_note
    bank_rejection_note
    bank_incomplete_note
    incompleted_at
    rejected_at
    customer_note
    created_at
    requested_at
    rejected_by_user_id
    approved_at
    approved_by_user_id
    funded_at
    closed_at
    history
    company {
      id
      ...CompanyLimited
    }
    vendor {
      id
      ...VendorLimited
    }
    all_customer_notes
  }
  ${CompanyLimitedFragmentDoc}
  ${VendorLimitedFragmentDoc}
`;
export const PurchaseOrderNewFragmentDoc = gql`
  fragment PurchaseOrderNew on purchase_orders {
    id
    bank_note
    all_bank_notes
    history
    ...PurchaseOrderLimitedNew
  }
  ${PurchaseOrderLimitedNewFragmentDoc}
`;
export const PayorLimitedFragmentDoc = gql`
  fragment PayorLimited on payors {
    id
    name
    dba_name
    licenses(
      where: {
        _or: [
          { is_deleted: { _is_null: true } }
          { is_deleted: { _eq: false } }
        ]
      }
    ) {
      ...CompanyLicenseLimited
    }
  }
  ${CompanyLicenseLimitedFragmentDoc}
`;
export const InvoiceLimitedFragmentDoc = gql`
  fragment InvoiceLimited on invoices {
    id
    company_id
    payor_id
    invoice_number
    subtotal_amount
    total_amount
    taxes_amount
    invoice_date
    invoice_due_date
    is_cannabis
    status
    created_at
    approved_at
    funded_at
    payment_requested_at
    payment_confirmed_at
    payment_rejected_at
    company {
      id
      ...CompanyLimited
    }
    payor {
      id
      ...PayorLimited
    }
  }
  ${CompanyLimitedFragmentDoc}
  ${PayorLimitedFragmentDoc}
`;
export const InvoiceFragmentDoc = gql`
  fragment Invoice on invoices {
    id
    ...InvoiceLimited
  }
  ${InvoiceLimitedFragmentDoc}
`;
export const PurchaseOrderFragmentDoc = gql`
  fragment PurchaseOrder on purchase_orders {
    id
    bank_note
    history
    ...PurchaseOrderLimited
  }
  ${PurchaseOrderLimitedFragmentDoc}
`;
export const LoanArtifactLimitedFragmentDoc = gql`
  fragment LoanArtifactLimited on loans {
    id
    loan_type
    artifact_id
    identifier
    invoice {
      ...InvoiceLimited
    }
    line_of_credit {
      id
      ...LineOfCredit
    }
    purchase_order {
      id
      ...PurchaseOrderLimited
    }
  }
  ${InvoiceLimitedFragmentDoc}
  ${LineOfCreditFragmentDoc}
  ${PurchaseOrderLimitedFragmentDoc}
`;
export const LoanArtifactFragmentDoc = gql`
  fragment LoanArtifact on loans {
    id
    invoice {
      id
      ...Invoice
    }
    line_of_credit {
      id
      ...LineOfCredit
    }
    purchase_order {
      id
      ...PurchaseOrder
    }
    ...LoanArtifactLimited
  }
  ${InvoiceFragmentDoc}
  ${LineOfCreditFragmentDoc}
  ${PurchaseOrderFragmentDoc}
  ${LoanArtifactLimitedFragmentDoc}
`;
export const PayorFragmentDoc = gql`
  fragment Payor on payors {
    id
    address
    country
    state
    city
    zip_code
    phone_number
    ...PayorLimited
  }
  ${PayorLimitedFragmentDoc}
`;
export const PayorBankOnlyFragmentDoc = gql`
  fragment PayorBankOnly on payors {
    id
    address
    country
    state
    city
    zip_code
    phone_number
  }
`;
export const PayorCombinedFragmentDoc = gql`
  fragment PayorCombined on payors {
    id
    ...PayorBankOnly @include(if: $is_bank_user)
    ...PayorLimited
  }
  ${PayorBankOnlyFragmentDoc}
  ${PayorLimitedFragmentDoc}
`;
export const PartnershipRequestFragmentDoc = gql`
  fragment PartnershipRequest on company_partnership_requests {
    id
    requesting_company_id
    two_factor_message_method
    company_type
    company_name
    license_info
    is_cannabis
    user_info
    request_info
    created_at
    requested_by_user_id
    settled_by_user_id
    settled_at
    requesting_company {
      id
      name
    }
    requested_by_user {
      full_name
    }
  }
`;
export const PartnershipInvitationFragmentDoc = gql`
  fragment PartnershipInvitation on company_partnership_invitations {
    id
    requesting_company_id
    email
    requested_at
    closed_at
    metadata_info
    created_at
    requesting_company {
      id
      name
    }
  }
`;
export const VendorPartnershipLimitedFragmentDoc = gql`
  fragment VendorPartnershipLimited on company_vendor_partnerships {
    id
    company_id
    vendor_id
    vendor_agreement_id
    approved_at
    company {
      id
      name
    }
    vendor {
      id
      ...VendorLimited
    }
  }
  ${VendorLimitedFragmentDoc}
`;
export const VendorPartnershipFragmentDoc = gql`
  fragment VendorPartnership on company_vendor_partnerships {
    id
    vendor_bank_id
    vendor_bank_account {
      id
      verified_at
    }
    ...VendorPartnershipLimited
  }
  ${VendorPartnershipLimitedFragmentDoc}
`;
export const PayorPartnershipLimitedFragmentDoc = gql`
  fragment PayorPartnershipLimited on company_payor_partnerships {
    id
    company_id
    payor_id
    payor_agreement_id
    approved_at
    company {
      id
      name
    }
    payor {
      id
      ...PayorLimited
    }
  }
  ${PayorLimitedFragmentDoc}
`;
export const PayorPartnershipFragmentDoc = gql`
  fragment PayorPartnership on company_payor_partnerships {
    id
    ...PayorPartnershipLimited
  }
  ${PayorPartnershipLimitedFragmentDoc}
`;
export const PaymentLimitedFragmentDoc = gql`
  fragment PaymentLimited on payments {
    id
    company_id
    settlement_identifier
    submitted_at
    settled_at
    reversed_at
    type
    method
    requested_amount
    amount
    requested_payment_date
    payment_date
    deposit_date
    settlement_date
    items_covered
    is_deleted
    company {
      id
      name
      identifier
      state
    }
    invoice {
      id
      payor {
        id
        name
      }
    }
    submitted_by_user {
      id
      full_name
    }
    settled_by_user {
      id
      full_name
    }
  }
`;
export const PaymentFragmentDoc = gql`
  fragment Payment on payments {
    id
    bank_note
    created_at
    ...PaymentLimited
    company {
      id
      name
      identifier
      state
      debt_facility_status
      most_recent_financial_summary: financial_summaries(
        order_by: { date: desc }
        limit: 1
      ) {
        id
        product_type
      }
      contract {
        id
        product_type
      }
    }
  }
  ${PaymentLimitedFragmentDoc}
`;
export const PaymentWithTransactionsFragmentDoc = gql`
  fragment PaymentWithTransactions on payments {
    id
    ...Payment
    transactions {
      id
      amount
      to_fees
      to_interest
      to_principal
    }
  }
  ${PaymentFragmentDoc}
`;
export const PaymentBankOnlyFragmentDoc = gql`
  fragment PaymentBankOnly on payments {
    id
    bank_note
    created_at
  }
`;
export const PaymentCombinedFragmentDoc = gql`
  fragment PaymentCombined on payments {
    id
    ...PaymentBankOnly @include(if: $is_bank_user)
    ...PaymentLimited
  }
  ${PaymentBankOnlyFragmentDoc}
  ${PaymentLimitedFragmentDoc}
`;
export const TransactionWithLoanFragmentDoc = gql`
  fragment TransactionWithLoan on transactions {
    id
    created_at
    loan_id
    payment_id
    type
    subtype
    amount
    effective_date
    to_principal
    to_interest
    to_fees
    loan {
      id
      ...Loan
    }
  }
  ${LoanFragmentDoc}
`;
export const TransactionFragmentDoc = gql`
  fragment Transaction on transactions {
    id
    created_at
    loan_id
    payment_id
    type
    subtype
    amount
    effective_date
    to_principal
    to_interest
    to_fees
  }
`;
export const TransactionExtendedFragmentDoc = gql`
  fragment TransactionExtended on transactions {
    id
    ...Transaction
    payment {
      id
      ...Payment
      company {
        id
        name
        contract {
          id
          product_type
        }
        most_recent_financial_summary: financial_summaries(
          limit: 1
          order_by: { date: desc }
        ) {
          id
          product_type
        }
      }
    }
    loan {
      id
      identifier
    }
  }
  ${TransactionFragmentDoc}
  ${PaymentFragmentDoc}
`;
export const BankFinancialSummaryFragmentDoc = gql`
  fragment BankFinancialSummary on bank_financial_summaries {
    id
    updated_at
    date
    product_type
    adjusted_total_limit
    total_outstanding_principal
    total_outstanding_principal_past_due
    total_outstanding_interest
    total_outstanding_fees
    total_principal_in_requested_state
    available_limit
    interest_accrued_today
  }
`;
export const AsyncPipelineLimitedFragmentDoc = gql`
  fragment AsyncPipelineLimited on async_pipelines {
    id
    created_at
    updated_at
    name
    status
  }
`;
export const AsyncPipelineFragmentDoc = gql`
  fragment AsyncPipeline on async_pipelines {
    id
    ...AsyncPipelineLimited
    internal_state
    params
  }
  ${AsyncPipelineLimitedFragmentDoc}
`;
export const MetrcDownloadSummaryLimitedFragmentDoc = gql`
  fragment MetrcDownloadSummaryLimited on metrc_download_summaries {
    id
    company_id
    metrc_api_key_id
    license_number
    date
    status
    harvests_status
    packages_status
    plant_batches_status
    plants_status
    sales_status
    transfers_status
    updated_at
  }
`;
export const MetrcDownloadSummaryFragmentDoc = gql`
  fragment MetrcDownloadSummary on metrc_download_summaries {
    id
    ...MetrcDownloadSummaryLimited
    num_retries
    err_details
  }
  ${MetrcDownloadSummaryLimitedFragmentDoc}
`;
export const DebtFacilityReportCompanyDetailsFragmentDoc = gql`
  fragment DebtFacilityReportCompanyDetails on companies {
    id
    name
    debt_facility_status
    state
    identifier
    most_recent_contract: contracts(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
        ]
      }
      order_by: { start_date: desc }
      limit: 1
    ) {
      id
      end_date
      product_type
    }
    financial_summaries(where: { date: { _eq: $target_date } }) {
      id
      product_type
      loans_info
    }
  }
`;
export const ContractFragmentDoc = gql`
  fragment Contract on contracts {
    id
    company_id
    product_type
    product_config
    start_date
    end_date
    adjusted_end_date
    terminated_at
  }
`;
export const CompanyForDebtFacilityFragmentDoc = gql`
  fragment CompanyForDebtFacility on companies {
    id
    identifier
    name
    dba_name
    state
    debt_facility_status
    contract {
      id
      ...Contract
    }
    most_recent_financial_summary: financial_summaries(
      order_by: { date: desc }
      limit: 1
    ) {
      id
      product_type
    }
    most_recent_contract: contracts(
      where: {
        _or: [
          { is_deleted: { _is_null: true } }
          { is_deleted: { _eq: false } }
        ]
      }
      order_by: { end_date: desc }
      limit: 1
    ) {
      id
      ...Contract
    }
  }
  ${ContractFragmentDoc}
`;
export const LoanForDebtFacilityFragmentDoc = gql`
  fragment LoanForDebtFacility on loans {
    id
    loan_report_id
    notes
    company_id
    loan_type
    artifact_id
    identifier
    disbursement_identifier
    status
    rejection_note
    payment_status
    amount
    requested_payment_date
    origination_date
    maturity_date
    adjusted_maturity_date
    outstanding_principal_balance
    outstanding_interest
    outstanding_fees
    requested_at
    approved_at
    rejected_at
    funded_at
    closed_at
    company {
      id
      identifier
      ...CompanyForDebtFacility
    }
  }
  ${CompanyForDebtFacilityFragmentDoc}
`;
export const PurchaseOrderForDebtFacilityFragmentDoc = gql`
  fragment PurchaseOrderForDebtFacility on purchase_orders {
    id
    bank_note
    ...PurchaseOrderLimited
  }
  ${PurchaseOrderLimitedFragmentDoc}
`;
export const OpenLoanForDebtFacilityFragmentDoc = gql`
  fragment OpenLoanForDebtFacility on loans {
    ...LoanForDebtFacility
    loan_report {
      id
      ...LoanReport
      debt_facility {
        id
        ...DebtFacility
      }
    }
    purchase_order {
      id
      ...PurchaseOrderForDebtFacility
    }
    invoice {
      id
      ...Invoice
    }
    line_of_credit {
      id
      ...LineOfCredit
    }
    transactions(where: { _and: [{ type: { _eq: "advance" } }] }) {
      id
      effective_date
    }
    repayments: transactions(
      where: { _and: [{ type: { _eq: "repayment" } }] }
    ) {
      id
      ...Transaction
    }
  }
  ${LoanForDebtFacilityFragmentDoc}
  ${LoanReportFragmentDoc}
  ${DebtFacilityFragmentDoc}
  ${PurchaseOrderForDebtFacilityFragmentDoc}
  ${InvoiceFragmentDoc}
  ${LineOfCreditFragmentDoc}
  ${TransactionFragmentDoc}
`;
export const CompanyForOpenLoansByDebtFacilityStatusesFragmentDoc = gql`
  fragment CompanyForOpenLoansByDebtFacilityStatuses on companies {
    id
    ...DebtFacilityReportCompanyDetails
    loans(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { closed_at: { _is_null: true } }
          { loan_report: { debt_facility_status: { _in: $statuses } } }
          {
            _or: [
              {
                company: { settings: { is_dummy_account: { _is_null: true } } }
              }
              { company: { settings: { is_dummy_account: { _eq: false } } } }
            ]
          }
          { origination_date: { _is_null: false } }
        ]
      }
    ) {
      id
      ...OpenLoanForDebtFacility
    }
  }
  ${DebtFacilityReportCompanyDetailsFragmentDoc}
  ${OpenLoanForDebtFacilityFragmentDoc}
`;
export const CompanyForByDebtFacilityIdFragmentDoc = gql`
  fragment CompanyForByDebtFacilityId on companies {
    id
    ...DebtFacilityReportCompanyDetails
    loans(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { closed_at: { _is_null: true } }
          { loan_report: { debt_facility_status: { _in: $statuses } } }
          { loan_report: { debt_facility_id: { _in: $target_facility_ids } } }
          {
            _or: [
              {
                company: { settings: { is_dummy_account: { _is_null: true } } }
              }
              { company: { settings: { is_dummy_account: { _eq: false } } } }
            ]
          }
          { origination_date: { _is_null: false } }
        ]
      }
    ) {
      id
      ...OpenLoanForDebtFacility
    }
  }
  ${DebtFacilityReportCompanyDetailsFragmentDoc}
  ${OpenLoanForDebtFacilityFragmentDoc}
`;
export const CompanyForDebtFacilityLoansByIdFragmentDoc = gql`
  fragment CompanyForDebtFacilityLoansById on companies {
    id
    ...DebtFacilityReportCompanyDetails
    loans(where: { id: { _in: $loan_ids } }) {
      id
      ...OpenLoanForDebtFacility
    }
  }
  ${DebtFacilityReportCompanyDetailsFragmentDoc}
  ${OpenLoanForDebtFacilityFragmentDoc}
`;
export const CompanyForDebtFacilityReportFragmentDoc = gql`
  fragment CompanyForDebtFacilityReport on companies {
    id
    ...DebtFacilityReportCompanyDetails
    loans(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          {
            _or: [
              { closed_at: { _gt: "2021-11-25T00:00:00+00:00" } }
              { closed_at: { _is_null: true } }
            ]
          }
          {
            _or: [
              {
                _and: [
                  {
                    loan_report: {
                      debt_facility_status: { _in: $debt_facility_statuses }
                    }
                  }
                  {
                    loan_report: {
                      debt_facility_id: { _in: $target_facility_ids }
                    }
                  }
                ]
              }
              {
                loan_report: { debt_facility_status: { _in: $other_statuses } }
              }
            ]
          }
          {
            _or: [
              {
                company: { settings: { is_dummy_account: { _is_null: true } } }
              }
              { company: { settings: { is_dummy_account: { _eq: false } } } }
            ]
          }
          {
            _and: [
              { origination_date: { _is_null: false } }
              { origination_date: { _lte: $target_date } }
            ]
          }
        ]
      }
    ) {
      id
      ...OpenLoanForDebtFacility
    }
  }
  ${DebtFacilityReportCompanyDetailsFragmentDoc}
  ${OpenLoanForDebtFacilityFragmentDoc}
`;
export const DebtFacilityEventFragmentDoc = gql`
  fragment DebtFacilityEvent on debt_facility_events {
    id
    company_id
    loan_report_id
    event_amount
    event_category
    event_comments
    event_date
    event_payload
  }
`;
export const VendorChangeRequestLimitedFragmentDoc = gql`
  fragment VendorChangeRequestLimited on vendor_change_requests {
    id
    status
    category
    updated_at
    created_at
    requesting_user_id
    requested_vendor_id
    request_info
  }
`;
export const VendorChangeRequestFragmentDoc = gql`
  fragment VendorChangeRequest on vendor_change_requests {
    id
    status
    category
    is_deleted
    approved_at
    created_at
    deleted_at
    ...VendorChangeRequestLimited
  }
  ${VendorChangeRequestLimitedFragmentDoc}
`;
export const AsyncJobFragmentDoc = gql`
  fragment AsyncJob on async_jobs {
    id
    name
    queued_at
    initialized_at
    started_at
    ended_at
    submitted_by_user_id
    submitted_by_user {
      id
      full_name
    }
    status
    is_high_priority
    job_payload
    retry_payload
    err_details
    num_retries
  }
`;
export const MonthlySummaryCalculationFragmentDoc = gql`
  fragment MonthlySummaryCalculation on monthly_summary_calculations {
    id
    report_month
  }
`;
export const RecentMonthlyCalculationsFragmentDoc = gql`
  fragment RecentMonthlyCalculations on companies {
    id
    name
    monthly_summary_calculations(order_by: { report_month: desc }, limit: 1) {
      id
      ...MonthlySummaryCalculation
    }
  }
  ${MonthlySummaryCalculationFragmentDoc}
`;
export const BespokeCatalogBrandFragmentDoc = gql`
  fragment BespokeCatalogBrand on bespoke_catalog_brands {
    id
    brand_name
    parent_company {
      id
      name
    }
  }
`;
export const BespokeCatalogSkuGroupFragmentDoc = gql`
  fragment BespokeCatalogSkuGroup on bespoke_catalog_sku_groups {
    id
    sku_group_name
    unit_quantity
    unit_of_measure
    bespoke_catalog_brand {
      id
      ...BespokeCatalogBrand
    }
  }
  ${BespokeCatalogBrandFragmentDoc}
`;
export const BespokeCatalogSkuFragmentDoc = gql`
  fragment BespokeCatalogSku on bespoke_catalog_skus {
    id
    sku
    bespoke_catalog_sku_group {
      id
      ...BespokeCatalogSkuGroup
    }
  }
  ${BespokeCatalogSkuGroupFragmentDoc}
`;
export const MetrcToBespokeCatalogSkuFragmentDoc = gql`
  fragment MetrcToBespokeCatalogSku on metrc_to_bespoke_catalog_skus {
    id
    product_name
    product_category_name
    wholesale_quantity
    sku_confidence
    is_sample
    bespoke_catalog_sku {
      id
      ...BespokeCatalogSku
    }
    user {
      id
      full_name
    }
  }
  ${BespokeCatalogSkuFragmentDoc}
`;
export const RepaymentForBankDataGridFragmentDoc = gql`
  fragment RepaymentForBankDataGrid on payments {
    id
    ...Payment
    requested_amount
    transactions(
      order_by: [
        { payment: { deposit_date: desc } }
        { payment: { created_at: desc } }
      ]
    ) {
      id
      ...Transaction
      loan {
        id
        ...LoanLimited
        ...LoanArtifactLimited
      }
      payment {
        id
        ...PaymentLimited
      }
    }
    company {
      id
      most_recent_financial_summary: financial_summaries(
        order_by: { date: desc }
        limit: 1
      ) {
        id
        product_type
      }
    }
    invoice {
      id
      payor {
        id
        name
      }
    }
    settled_by_user {
      id
      full_name
    }
  }
  ${PaymentFragmentDoc}
  ${TransactionFragmentDoc}
  ${LoanLimitedFragmentDoc}
  ${LoanArtifactLimitedFragmentDoc}
  ${PaymentLimitedFragmentDoc}
`;
export const CompanyLicenseLimitedAnonymousFragmentDoc = gql`
  fragment CompanyLicenseLimitedAnonymous on company_licenses {
    id
    license_number
    license_category
    legal_name
    us_state
  }
`;
export const GetAdvancesDocument = gql`
  subscription GetAdvances {
    payments(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { type: { _eq: "advance" } }
        ]
      }
      order_by: [{ payment_date: desc }, { created_at: desc }]
    ) {
      id
      ...Payment
      company {
        id
        name
      }
      settled_by_user {
        id
        full_name
      }
    }
  }
  ${PaymentFragmentDoc}
`;

/**
 * __useGetAdvancesSubscription__
 *
 * To run a query within a React component, call `useGetAdvancesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvancesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvancesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetAdvancesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetAdvancesSubscription,
    GetAdvancesSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetAdvancesSubscription,
    GetAdvancesSubscriptionVariables
  >(GetAdvancesDocument, options);
}
export type GetAdvancesSubscriptionHookResult = ReturnType<
  typeof useGetAdvancesSubscription
>;
export type GetAdvancesSubscriptionResult =
  Apollo.SubscriptionResult<GetAdvancesSubscription>;
export const GetAdvancesByPaymentDateDocument = gql`
  query GetAdvancesByPaymentDate($date: date!) {
    payments(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { type: { _eq: "advance" } }
          { payment_date: { _eq: $date } }
        ]
      }
      order_by: [{ settlement_date: asc }, { created_at: asc }]
    ) {
      id
      ...Payment
      company {
        id
        financial_summaries: financial_summaries(
          where: { date: { _eq: $date } }
        ) {
          id
          product_type
        }
      }
      settled_by_user {
        id
        full_name
      }
    }
  }
  ${PaymentFragmentDoc}
`;

/**
 * __useGetAdvancesByPaymentDateQuery__
 *
 * To run a query within a React component, call `useGetAdvancesByPaymentDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvancesByPaymentDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvancesByPaymentDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetAdvancesByPaymentDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAdvancesByPaymentDateQuery,
    GetAdvancesByPaymentDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAdvancesByPaymentDateQuery,
    GetAdvancesByPaymentDateQueryVariables
  >(GetAdvancesByPaymentDateDocument, options);
}
export function useGetAdvancesByPaymentDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdvancesByPaymentDateQuery,
    GetAdvancesByPaymentDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdvancesByPaymentDateQuery,
    GetAdvancesByPaymentDateQueryVariables
  >(GetAdvancesByPaymentDateDocument, options);
}
export type GetAdvancesByPaymentDateQueryHookResult = ReturnType<
  typeof useGetAdvancesByPaymentDateQuery
>;
export type GetAdvancesByPaymentDateLazyQueryHookResult = ReturnType<
  typeof useGetAdvancesByPaymentDateLazyQuery
>;
export type GetAdvancesByPaymentDateQueryResult = Apollo.QueryResult<
  GetAdvancesByPaymentDateQuery,
  GetAdvancesByPaymentDateQueryVariables
>;
export const GetAdvancesByMethodAndPaymentDateDocument = gql`
  query GetAdvancesByMethodAndPaymentDate($method: String!, $date: date!) {
    payments(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { type: { _eq: "advance" } }
          { method: { _eq: $method } }
          { payment_date: { _eq: $date } }
        ]
      }
    ) {
      id
      ...Payment
      company_bank_account {
        id
        us_state
        ...BankAccount
      }
      recipient_bank_account {
        id
        ...BankAccount
      }
    }
  }
  ${PaymentFragmentDoc}
  ${BankAccountFragmentDoc}
`;

/**
 * __useGetAdvancesByMethodAndPaymentDateQuery__
 *
 * To run a query within a React component, call `useGetAdvancesByMethodAndPaymentDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvancesByMethodAndPaymentDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvancesByMethodAndPaymentDateQuery({
 *   variables: {
 *      method: // value for 'method'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetAdvancesByMethodAndPaymentDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAdvancesByMethodAndPaymentDateQuery,
    GetAdvancesByMethodAndPaymentDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAdvancesByMethodAndPaymentDateQuery,
    GetAdvancesByMethodAndPaymentDateQueryVariables
  >(GetAdvancesByMethodAndPaymentDateDocument, options);
}
export function useGetAdvancesByMethodAndPaymentDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdvancesByMethodAndPaymentDateQuery,
    GetAdvancesByMethodAndPaymentDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdvancesByMethodAndPaymentDateQuery,
    GetAdvancesByMethodAndPaymentDateQueryVariables
  >(GetAdvancesByMethodAndPaymentDateDocument, options);
}
export type GetAdvancesByMethodAndPaymentDateQueryHookResult = ReturnType<
  typeof useGetAdvancesByMethodAndPaymentDateQuery
>;
export type GetAdvancesByMethodAndPaymentDateLazyQueryHookResult = ReturnType<
  typeof useGetAdvancesByMethodAndPaymentDateLazyQuery
>;
export type GetAdvancesByMethodAndPaymentDateQueryResult = Apollo.QueryResult<
  GetAdvancesByMethodAndPaymentDateQuery,
  GetAdvancesByMethodAndPaymentDateQueryVariables
>;
export const GetAsyncPipelinesDocument = gql`
  query GetAsyncPipelines {
    async_pipelines: async_pipelines(
      order_by: { updated_at: desc }
      limit: 100
    ) {
      id
      ...AsyncPipelineLimited
    }
  }
  ${AsyncPipelineLimitedFragmentDoc}
`;

/**
 * __useGetAsyncPipelinesQuery__
 *
 * To run a query within a React component, call `useGetAsyncPipelinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAsyncPipelinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAsyncPipelinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAsyncPipelinesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAsyncPipelinesQuery,
    GetAsyncPipelinesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAsyncPipelinesQuery,
    GetAsyncPipelinesQueryVariables
  >(GetAsyncPipelinesDocument, options);
}
export function useGetAsyncPipelinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAsyncPipelinesQuery,
    GetAsyncPipelinesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAsyncPipelinesQuery,
    GetAsyncPipelinesQueryVariables
  >(GetAsyncPipelinesDocument, options);
}
export type GetAsyncPipelinesQueryHookResult = ReturnType<
  typeof useGetAsyncPipelinesQuery
>;
export type GetAsyncPipelinesLazyQueryHookResult = ReturnType<
  typeof useGetAsyncPipelinesLazyQuery
>;
export type GetAsyncPipelinesQueryResult = Apollo.QueryResult<
  GetAsyncPipelinesQuery,
  GetAsyncPipelinesQueryVariables
>;
export const GetAsyncPipelineDocument = gql`
  query GetAsyncPipeline($id: uuid!) {
    async_pipelines_by_pk(id: $id) {
      id
      ...AsyncPipeline
    }
  }
  ${AsyncPipelineFragmentDoc}
`;

/**
 * __useGetAsyncPipelineQuery__
 *
 * To run a query within a React component, call `useGetAsyncPipelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAsyncPipelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAsyncPipelineQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAsyncPipelineQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAsyncPipelineQuery,
    GetAsyncPipelineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAsyncPipelineQuery, GetAsyncPipelineQueryVariables>(
    GetAsyncPipelineDocument,
    options
  );
}
export function useGetAsyncPipelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAsyncPipelineQuery,
    GetAsyncPipelineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAsyncPipelineQuery,
    GetAsyncPipelineQueryVariables
  >(GetAsyncPipelineDocument, options);
}
export type GetAsyncPipelineQueryHookResult = ReturnType<
  typeof useGetAsyncPipelineQuery
>;
export type GetAsyncPipelineLazyQueryHookResult = ReturnType<
  typeof useGetAsyncPipelineLazyQuery
>;
export type GetAsyncPipelineQueryResult = Apollo.QueryResult<
  GetAsyncPipelineQuery,
  GetAsyncPipelineQueryVariables
>;
export const GetBespokeBankAccountsDocument = gql`
  query GetBespokeBankAccounts {
    bank_accounts(
      where: {
        _and: [
          { company_id: { _is_null: true } }
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
        ]
      }
    ) {
      ...BankAccount
    }
  }
  ${BankAccountFragmentDoc}
`;

/**
 * __useGetBespokeBankAccountsQuery__
 *
 * To run a query within a React component, call `useGetBespokeBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBespokeBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBespokeBankAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBespokeBankAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBespokeBankAccountsQuery,
    GetBespokeBankAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBespokeBankAccountsQuery,
    GetBespokeBankAccountsQueryVariables
  >(GetBespokeBankAccountsDocument, options);
}
export function useGetBespokeBankAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBespokeBankAccountsQuery,
    GetBespokeBankAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBespokeBankAccountsQuery,
    GetBespokeBankAccountsQueryVariables
  >(GetBespokeBankAccountsDocument, options);
}
export type GetBespokeBankAccountsQueryHookResult = ReturnType<
  typeof useGetBespokeBankAccountsQuery
>;
export type GetBespokeBankAccountsLazyQueryHookResult = ReturnType<
  typeof useGetBespokeBankAccountsLazyQuery
>;
export type GetBespokeBankAccountsQueryResult = Apollo.QueryResult<
  GetBespokeBankAccountsQuery,
  GetBespokeBankAccountsQueryVariables
>;
export const GetBankAccountsByCompanyIdDocument = gql`
  query GetBankAccountsByCompanyId($companyId: uuid!) {
    bank_accounts(where: { company_id: { _eq: $companyId } }) {
      ...BankAccountLimited
    }
  }
  ${BankAccountLimitedFragmentDoc}
`;

/**
 * __useGetBankAccountsByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetBankAccountsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccountsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccountsByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetBankAccountsByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBankAccountsByCompanyIdQuery,
    GetBankAccountsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBankAccountsByCompanyIdQuery,
    GetBankAccountsByCompanyIdQueryVariables
  >(GetBankAccountsByCompanyIdDocument, options);
}
export function useGetBankAccountsByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBankAccountsByCompanyIdQuery,
    GetBankAccountsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBankAccountsByCompanyIdQuery,
    GetBankAccountsByCompanyIdQueryVariables
  >(GetBankAccountsByCompanyIdDocument, options);
}
export type GetBankAccountsByCompanyIdQueryHookResult = ReturnType<
  typeof useGetBankAccountsByCompanyIdQuery
>;
export type GetBankAccountsByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetBankAccountsByCompanyIdLazyQuery
>;
export type GetBankAccountsByCompanyIdQueryResult = Apollo.QueryResult<
  GetBankAccountsByCompanyIdQuery,
  GetBankAccountsByCompanyIdQueryVariables
>;
export const GetAdvancesBankAccountsForCustomerDocument = gql`
  query GetAdvancesBankAccountsForCustomer(
    $customerId: uuid!
    $vendorId: uuid
  ) {
    companies_by_pk(id: $customerId) {
      id
      name
      company_vendor_partnerships(where: { vendor_id: { _eq: $vendorId } }) {
        id
        vendor {
          id
          name
        }
        vendor_bank_account {
          id
          ...BankAccount
        }
      }
      settings {
        id
        advances_bank_account {
          id
          ...BankAccount
        }
      }
    }
  }
  ${BankAccountFragmentDoc}
`;

/**
 * __useGetAdvancesBankAccountsForCustomerQuery__
 *
 * To run a query within a React component, call `useGetAdvancesBankAccountsForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvancesBankAccountsForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvancesBankAccountsForCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useGetAdvancesBankAccountsForCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAdvancesBankAccountsForCustomerQuery,
    GetAdvancesBankAccountsForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAdvancesBankAccountsForCustomerQuery,
    GetAdvancesBankAccountsForCustomerQueryVariables
  >(GetAdvancesBankAccountsForCustomerDocument, options);
}
export function useGetAdvancesBankAccountsForCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdvancesBankAccountsForCustomerQuery,
    GetAdvancesBankAccountsForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdvancesBankAccountsForCustomerQuery,
    GetAdvancesBankAccountsForCustomerQueryVariables
  >(GetAdvancesBankAccountsForCustomerDocument, options);
}
export type GetAdvancesBankAccountsForCustomerQueryHookResult = ReturnType<
  typeof useGetAdvancesBankAccountsForCustomerQuery
>;
export type GetAdvancesBankAccountsForCustomerLazyQueryHookResult = ReturnType<
  typeof useGetAdvancesBankAccountsForCustomerLazyQuery
>;
export type GetAdvancesBankAccountsForCustomerQueryResult = Apollo.QueryResult<
  GetAdvancesBankAccountsForCustomerQuery,
  GetAdvancesBankAccountsForCustomerQueryVariables
>;
export const BankAccountsForTransferDocument = gql`
  query BankAccountsForTransfer($companyId: uuid!) {
    bank_accounts(where: { company_id: { _is_null: true } }) {
      ...BankAccount
    }
    companies_by_pk(id: $companyId) {
      id
      settings {
        id
        collections_bespoke_bank_account {
          ...BankAccount
        }
      }
    }
  }
  ${BankAccountFragmentDoc}
`;

/**
 * __useBankAccountsForTransferQuery__
 *
 * To run a query within a React component, call `useBankAccountsForTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankAccountsForTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankAccountsForTransferQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useBankAccountsForTransferQuery(
  baseOptions: Apollo.QueryHookOptions<
    BankAccountsForTransferQuery,
    BankAccountsForTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BankAccountsForTransferQuery,
    BankAccountsForTransferQueryVariables
  >(BankAccountsForTransferDocument, options);
}
export function useBankAccountsForTransferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BankAccountsForTransferQuery,
    BankAccountsForTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BankAccountsForTransferQuery,
    BankAccountsForTransferQueryVariables
  >(BankAccountsForTransferDocument, options);
}
export type BankAccountsForTransferQueryHookResult = ReturnType<
  typeof useBankAccountsForTransferQuery
>;
export type BankAccountsForTransferLazyQueryHookResult = ReturnType<
  typeof useBankAccountsForTransferLazyQuery
>;
export type BankAccountsForTransferQueryResult = Apollo.QueryResult<
  BankAccountsForTransferQuery,
  BankAccountsForTransferQueryVariables
>;
export const GetBespokeCatalogSkusDocument = gql`
  query GetBespokeCatalogSkus {
    bespoke_catalog_skus(
      where: { is_deleted: { _eq: false } }
      order_by: { created_at: desc }
    ) {
      id
      ...BespokeCatalogSku
    }
  }
  ${BespokeCatalogSkuFragmentDoc}
`;

/**
 * __useGetBespokeCatalogSkusQuery__
 *
 * To run a query within a React component, call `useGetBespokeCatalogSkusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBespokeCatalogSkusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBespokeCatalogSkusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBespokeCatalogSkusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBespokeCatalogSkusQuery,
    GetBespokeCatalogSkusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBespokeCatalogSkusQuery,
    GetBespokeCatalogSkusQueryVariables
  >(GetBespokeCatalogSkusDocument, options);
}
export function useGetBespokeCatalogSkusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBespokeCatalogSkusQuery,
    GetBespokeCatalogSkusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBespokeCatalogSkusQuery,
    GetBespokeCatalogSkusQueryVariables
  >(GetBespokeCatalogSkusDocument, options);
}
export type GetBespokeCatalogSkusQueryHookResult = ReturnType<
  typeof useGetBespokeCatalogSkusQuery
>;
export type GetBespokeCatalogSkusLazyQueryHookResult = ReturnType<
  typeof useGetBespokeCatalogSkusLazyQuery
>;
export type GetBespokeCatalogSkusQueryResult = Apollo.QueryResult<
  GetBespokeCatalogSkusQuery,
  GetBespokeCatalogSkusQueryVariables
>;
export const GetBespokeCatalogSkuGroupsDocument = gql`
  query GetBespokeCatalogSkuGroups {
    bespoke_catalog_sku_groups(
      where: { is_deleted: { _eq: false } }
      order_by: { created_at: desc }
    ) {
      id
      ...BespokeCatalogSkuGroup
    }
  }
  ${BespokeCatalogSkuGroupFragmentDoc}
`;

/**
 * __useGetBespokeCatalogSkuGroupsQuery__
 *
 * To run a query within a React component, call `useGetBespokeCatalogSkuGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBespokeCatalogSkuGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBespokeCatalogSkuGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBespokeCatalogSkuGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBespokeCatalogSkuGroupsQuery,
    GetBespokeCatalogSkuGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBespokeCatalogSkuGroupsQuery,
    GetBespokeCatalogSkuGroupsQueryVariables
  >(GetBespokeCatalogSkuGroupsDocument, options);
}
export function useGetBespokeCatalogSkuGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBespokeCatalogSkuGroupsQuery,
    GetBespokeCatalogSkuGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBespokeCatalogSkuGroupsQuery,
    GetBespokeCatalogSkuGroupsQueryVariables
  >(GetBespokeCatalogSkuGroupsDocument, options);
}
export type GetBespokeCatalogSkuGroupsQueryHookResult = ReturnType<
  typeof useGetBespokeCatalogSkuGroupsQuery
>;
export type GetBespokeCatalogSkuGroupsLazyQueryHookResult = ReturnType<
  typeof useGetBespokeCatalogSkuGroupsLazyQuery
>;
export type GetBespokeCatalogSkuGroupsQueryResult = Apollo.QueryResult<
  GetBespokeCatalogSkuGroupsQuery,
  GetBespokeCatalogSkuGroupsQueryVariables
>;
export const GetBespokeCatalogSkuGroupsByIdsDocument = gql`
  query GetBespokeCatalogSkuGroupsByIds($ids: [uuid!]!) {
    bespoke_catalog_sku_groups(
      where: { _and: [{ is_deleted: { _eq: false } }, { id: { _in: $ids } }] }
      order_by: { created_at: desc }
    ) {
      id
      ...BespokeCatalogSkuGroup
    }
  }
  ${BespokeCatalogSkuGroupFragmentDoc}
`;

/**
 * __useGetBespokeCatalogSkuGroupsByIdsQuery__
 *
 * To run a query within a React component, call `useGetBespokeCatalogSkuGroupsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBespokeCatalogSkuGroupsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBespokeCatalogSkuGroupsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetBespokeCatalogSkuGroupsByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBespokeCatalogSkuGroupsByIdsQuery,
    GetBespokeCatalogSkuGroupsByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBespokeCatalogSkuGroupsByIdsQuery,
    GetBespokeCatalogSkuGroupsByIdsQueryVariables
  >(GetBespokeCatalogSkuGroupsByIdsDocument, options);
}
export function useGetBespokeCatalogSkuGroupsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBespokeCatalogSkuGroupsByIdsQuery,
    GetBespokeCatalogSkuGroupsByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBespokeCatalogSkuGroupsByIdsQuery,
    GetBespokeCatalogSkuGroupsByIdsQueryVariables
  >(GetBespokeCatalogSkuGroupsByIdsDocument, options);
}
export type GetBespokeCatalogSkuGroupsByIdsQueryHookResult = ReturnType<
  typeof useGetBespokeCatalogSkuGroupsByIdsQuery
>;
export type GetBespokeCatalogSkuGroupsByIdsLazyQueryHookResult = ReturnType<
  typeof useGetBespokeCatalogSkuGroupsByIdsLazyQuery
>;
export type GetBespokeCatalogSkuGroupsByIdsQueryResult = Apollo.QueryResult<
  GetBespokeCatalogSkuGroupsByIdsQuery,
  GetBespokeCatalogSkuGroupsByIdsQueryVariables
>;
export const GetBespokeCatalogBrandsDocument = gql`
  query GetBespokeCatalogBrands {
    bespoke_catalog_brands(
      where: { is_deleted: { _eq: false } }
      order_by: { created_at: desc }
    ) {
      id
      ...BespokeCatalogBrand
    }
  }
  ${BespokeCatalogBrandFragmentDoc}
`;

/**
 * __useGetBespokeCatalogBrandsQuery__
 *
 * To run a query within a React component, call `useGetBespokeCatalogBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBespokeCatalogBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBespokeCatalogBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBespokeCatalogBrandsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBespokeCatalogBrandsQuery,
    GetBespokeCatalogBrandsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBespokeCatalogBrandsQuery,
    GetBespokeCatalogBrandsQueryVariables
  >(GetBespokeCatalogBrandsDocument, options);
}
export function useGetBespokeCatalogBrandsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBespokeCatalogBrandsQuery,
    GetBespokeCatalogBrandsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBespokeCatalogBrandsQuery,
    GetBespokeCatalogBrandsQueryVariables
  >(GetBespokeCatalogBrandsDocument, options);
}
export type GetBespokeCatalogBrandsQueryHookResult = ReturnType<
  typeof useGetBespokeCatalogBrandsQuery
>;
export type GetBespokeCatalogBrandsLazyQueryHookResult = ReturnType<
  typeof useGetBespokeCatalogBrandsLazyQuery
>;
export type GetBespokeCatalogBrandsQueryResult = Apollo.QueryResult<
  GetBespokeCatalogBrandsQuery,
  GetBespokeCatalogBrandsQueryVariables
>;
export const GetMetrcToBespokeCatalogSkusDocument = gql`
  query GetMetrcToBespokeCatalogSkus {
    metrc_to_bespoke_catalog_skus(
      where: { is_deleted: { _eq: false } }
      order_by: { created_at: desc }
      limit: 200
    ) {
      id
      ...MetrcToBespokeCatalogSku
    }
  }
  ${MetrcToBespokeCatalogSkuFragmentDoc}
`;

/**
 * __useGetMetrcToBespokeCatalogSkusQuery__
 *
 * To run a query within a React component, call `useGetMetrcToBespokeCatalogSkusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetrcToBespokeCatalogSkusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetrcToBespokeCatalogSkusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMetrcToBespokeCatalogSkusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMetrcToBespokeCatalogSkusQuery,
    GetMetrcToBespokeCatalogSkusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMetrcToBespokeCatalogSkusQuery,
    GetMetrcToBespokeCatalogSkusQueryVariables
  >(GetMetrcToBespokeCatalogSkusDocument, options);
}
export function useGetMetrcToBespokeCatalogSkusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetrcToBespokeCatalogSkusQuery,
    GetMetrcToBespokeCatalogSkusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMetrcToBespokeCatalogSkusQuery,
    GetMetrcToBespokeCatalogSkusQueryVariables
  >(GetMetrcToBespokeCatalogSkusDocument, options);
}
export type GetMetrcToBespokeCatalogSkusQueryHookResult = ReturnType<
  typeof useGetMetrcToBespokeCatalogSkusQuery
>;
export type GetMetrcToBespokeCatalogSkusLazyQueryHookResult = ReturnType<
  typeof useGetMetrcToBespokeCatalogSkusLazyQuery
>;
export type GetMetrcToBespokeCatalogSkusQueryResult = Apollo.QueryResult<
  GetMetrcToBespokeCatalogSkusQuery,
  GetMetrcToBespokeCatalogSkusQueryVariables
>;
export const GetMetrcToBespokeCatalogSkusByProductNameDocument = gql`
  query GetMetrcToBespokeCatalogSkusByProductName(
    $search_query: String!
    $limit: Int! = 200
  ) {
    metrc_to_bespoke_catalog_skus(
      where: {
        _and: [
          { is_deleted: { _eq: false } }
          { product_name: { _ilike: $search_query } }
        ]
      }
      order_by: { created_at: desc }
      limit: $limit
    ) {
      id
      ...MetrcToBespokeCatalogSku
    }
  }
  ${MetrcToBespokeCatalogSkuFragmentDoc}
`;

/**
 * __useGetMetrcToBespokeCatalogSkusByProductNameQuery__
 *
 * To run a query within a React component, call `useGetMetrcToBespokeCatalogSkusByProductNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetrcToBespokeCatalogSkusByProductNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetrcToBespokeCatalogSkusByProductNameQuery({
 *   variables: {
 *      search_query: // value for 'search_query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMetrcToBespokeCatalogSkusByProductNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMetrcToBespokeCatalogSkusByProductNameQuery,
    GetMetrcToBespokeCatalogSkusByProductNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMetrcToBespokeCatalogSkusByProductNameQuery,
    GetMetrcToBespokeCatalogSkusByProductNameQueryVariables
  >(GetMetrcToBespokeCatalogSkusByProductNameDocument, options);
}
export function useGetMetrcToBespokeCatalogSkusByProductNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetrcToBespokeCatalogSkusByProductNameQuery,
    GetMetrcToBespokeCatalogSkusByProductNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMetrcToBespokeCatalogSkusByProductNameQuery,
    GetMetrcToBespokeCatalogSkusByProductNameQueryVariables
  >(GetMetrcToBespokeCatalogSkusByProductNameDocument, options);
}
export type GetMetrcToBespokeCatalogSkusByProductNameQueryHookResult =
  ReturnType<typeof useGetMetrcToBespokeCatalogSkusByProductNameQuery>;
export type GetMetrcToBespokeCatalogSkusByProductNameLazyQueryHookResult =
  ReturnType<typeof useGetMetrcToBespokeCatalogSkusByProductNameLazyQuery>;
export type GetMetrcToBespokeCatalogSkusByProductNameQueryResult =
  Apollo.QueryResult<
    GetMetrcToBespokeCatalogSkusByProductNameQuery,
    GetMetrcToBespokeCatalogSkusByProductNameQueryVariables
  >;
export const GetBespokeCatalogSkusBySkuNameDocument = gql`
  query GetBespokeCatalogSkusBySkuName($search_prefix: String!) {
    bespoke_catalog_skus(
      where: {
        _and: [
          { is_deleted: { _eq: false } }
          { sku: { _ilike: $search_prefix } }
        ]
      }
      order_by: { created_at: desc }
      limit: 10
    ) {
      id
      ...BespokeCatalogSku
    }
  }
  ${BespokeCatalogSkuFragmentDoc}
`;

/**
 * __useGetBespokeCatalogSkusBySkuNameQuery__
 *
 * To run a query within a React component, call `useGetBespokeCatalogSkusBySkuNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBespokeCatalogSkusBySkuNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBespokeCatalogSkusBySkuNameQuery({
 *   variables: {
 *      search_prefix: // value for 'search_prefix'
 *   },
 * });
 */
export function useGetBespokeCatalogSkusBySkuNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBespokeCatalogSkusBySkuNameQuery,
    GetBespokeCatalogSkusBySkuNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBespokeCatalogSkusBySkuNameQuery,
    GetBespokeCatalogSkusBySkuNameQueryVariables
  >(GetBespokeCatalogSkusBySkuNameDocument, options);
}
export function useGetBespokeCatalogSkusBySkuNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBespokeCatalogSkusBySkuNameQuery,
    GetBespokeCatalogSkusBySkuNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBespokeCatalogSkusBySkuNameQuery,
    GetBespokeCatalogSkusBySkuNameQueryVariables
  >(GetBespokeCatalogSkusBySkuNameDocument, options);
}
export type GetBespokeCatalogSkusBySkuNameQueryHookResult = ReturnType<
  typeof useGetBespokeCatalogSkusBySkuNameQuery
>;
export type GetBespokeCatalogSkusBySkuNameLazyQueryHookResult = ReturnType<
  typeof useGetBespokeCatalogSkusBySkuNameLazyQuery
>;
export type GetBespokeCatalogSkusBySkuNameQueryResult = Apollo.QueryResult<
  GetBespokeCatalogSkusBySkuNameQuery,
  GetBespokeCatalogSkusBySkuNameQueryVariables
>;
export const GetBespokeCatalogSkuGroupsBySkuGroupNameDocument = gql`
  query GetBespokeCatalogSkuGroupsBySkuGroupName($search_prefix: String!) {
    bespoke_catalog_sku_groups(
      where: {
        _and: [
          { is_deleted: { _eq: false } }
          { sku_group_name: { _ilike: $search_prefix } }
        ]
      }
      order_by: { created_at: desc }
      limit: 25
    ) {
      id
      ...BespokeCatalogSkuGroup
    }
  }
  ${BespokeCatalogSkuGroupFragmentDoc}
`;

/**
 * __useGetBespokeCatalogSkuGroupsBySkuGroupNameQuery__
 *
 * To run a query within a React component, call `useGetBespokeCatalogSkuGroupsBySkuGroupNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBespokeCatalogSkuGroupsBySkuGroupNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBespokeCatalogSkuGroupsBySkuGroupNameQuery({
 *   variables: {
 *      search_prefix: // value for 'search_prefix'
 *   },
 * });
 */
export function useGetBespokeCatalogSkuGroupsBySkuGroupNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBespokeCatalogSkuGroupsBySkuGroupNameQuery,
    GetBespokeCatalogSkuGroupsBySkuGroupNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBespokeCatalogSkuGroupsBySkuGroupNameQuery,
    GetBespokeCatalogSkuGroupsBySkuGroupNameQueryVariables
  >(GetBespokeCatalogSkuGroupsBySkuGroupNameDocument, options);
}
export function useGetBespokeCatalogSkuGroupsBySkuGroupNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBespokeCatalogSkuGroupsBySkuGroupNameQuery,
    GetBespokeCatalogSkuGroupsBySkuGroupNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBespokeCatalogSkuGroupsBySkuGroupNameQuery,
    GetBespokeCatalogSkuGroupsBySkuGroupNameQueryVariables
  >(GetBespokeCatalogSkuGroupsBySkuGroupNameDocument, options);
}
export type GetBespokeCatalogSkuGroupsBySkuGroupNameQueryHookResult =
  ReturnType<typeof useGetBespokeCatalogSkuGroupsBySkuGroupNameQuery>;
export type GetBespokeCatalogSkuGroupsBySkuGroupNameLazyQueryHookResult =
  ReturnType<typeof useGetBespokeCatalogSkuGroupsBySkuGroupNameLazyQuery>;
export type GetBespokeCatalogSkuGroupsBySkuGroupNameQueryResult =
  Apollo.QueryResult<
    GetBespokeCatalogSkuGroupsBySkuGroupNameQuery,
    GetBespokeCatalogSkuGroupsBySkuGroupNameQueryVariables
  >;
export const GetBespokeCatalogBrandsByBrandNameDocument = gql`
  query GetBespokeCatalogBrandsByBrandName($search_prefix: String!) {
    bespoke_catalog_brands(
      where: {
        _and: [
          { is_deleted: { _eq: false } }
          { brand_name: { _ilike: $search_prefix } }
        ]
      }
      order_by: { created_at: desc }
      limit: 10
    ) {
      id
      ...BespokeCatalogBrand
    }
  }
  ${BespokeCatalogBrandFragmentDoc}
`;

/**
 * __useGetBespokeCatalogBrandsByBrandNameQuery__
 *
 * To run a query within a React component, call `useGetBespokeCatalogBrandsByBrandNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBespokeCatalogBrandsByBrandNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBespokeCatalogBrandsByBrandNameQuery({
 *   variables: {
 *      search_prefix: // value for 'search_prefix'
 *   },
 * });
 */
export function useGetBespokeCatalogBrandsByBrandNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBespokeCatalogBrandsByBrandNameQuery,
    GetBespokeCatalogBrandsByBrandNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBespokeCatalogBrandsByBrandNameQuery,
    GetBespokeCatalogBrandsByBrandNameQueryVariables
  >(GetBespokeCatalogBrandsByBrandNameDocument, options);
}
export function useGetBespokeCatalogBrandsByBrandNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBespokeCatalogBrandsByBrandNameQuery,
    GetBespokeCatalogBrandsByBrandNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBespokeCatalogBrandsByBrandNameQuery,
    GetBespokeCatalogBrandsByBrandNameQueryVariables
  >(GetBespokeCatalogBrandsByBrandNameDocument, options);
}
export type GetBespokeCatalogBrandsByBrandNameQueryHookResult = ReturnType<
  typeof useGetBespokeCatalogBrandsByBrandNameQuery
>;
export type GetBespokeCatalogBrandsByBrandNameLazyQueryHookResult = ReturnType<
  typeof useGetBespokeCatalogBrandsByBrandNameLazyQuery
>;
export type GetBespokeCatalogBrandsByBrandNameQueryResult = Apollo.QueryResult<
  GetBespokeCatalogBrandsByBrandNameQuery,
  GetBespokeCatalogBrandsByBrandNameQueryVariables
>;
export const GetParentCompaniesByNameDocument = gql`
  query GetParentCompaniesByName($search_prefix: String!) {
    parent_companies(
      where: { name: { _ilike: $search_prefix } }
      order_by: { created_at: desc }
      limit: 10
    ) {
      id
      ...ParentCompany
    }
  }
  ${ParentCompanyFragmentDoc}
`;

/**
 * __useGetParentCompaniesByNameQuery__
 *
 * To run a query within a React component, call `useGetParentCompaniesByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentCompaniesByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentCompaniesByNameQuery({
 *   variables: {
 *      search_prefix: // value for 'search_prefix'
 *   },
 * });
 */
export function useGetParentCompaniesByNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParentCompaniesByNameQuery,
    GetParentCompaniesByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetParentCompaniesByNameQuery,
    GetParentCompaniesByNameQueryVariables
  >(GetParentCompaniesByNameDocument, options);
}
export function useGetParentCompaniesByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParentCompaniesByNameQuery,
    GetParentCompaniesByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParentCompaniesByNameQuery,
    GetParentCompaniesByNameQueryVariables
  >(GetParentCompaniesByNameDocument, options);
}
export type GetParentCompaniesByNameQueryHookResult = ReturnType<
  typeof useGetParentCompaniesByNameQuery
>;
export type GetParentCompaniesByNameLazyQueryHookResult = ReturnType<
  typeof useGetParentCompaniesByNameLazyQuery
>;
export type GetParentCompaniesByNameQueryResult = Apollo.QueryResult<
  GetParentCompaniesByNameQuery,
  GetParentCompaniesByNameQueryVariables
>;
export const GetCompanyFacilityDocument = gql`
  query GetCompanyFacility($id: uuid!) {
    company_facilities_by_pk(id: $id) {
      id
      ...CompanyFacility
    }
  }
  ${CompanyFacilityFragmentDoc}
`;

/**
 * __useGetCompanyFacilityQuery__
 *
 * To run a query within a React component, call `useGetCompanyFacilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyFacilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyFacilityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyFacilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyFacilityQuery,
    GetCompanyFacilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyFacilityQuery,
    GetCompanyFacilityQueryVariables
  >(GetCompanyFacilityDocument, options);
}
export function useGetCompanyFacilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyFacilityQuery,
    GetCompanyFacilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyFacilityQuery,
    GetCompanyFacilityQueryVariables
  >(GetCompanyFacilityDocument, options);
}
export type GetCompanyFacilityQueryHookResult = ReturnType<
  typeof useGetCompanyFacilityQuery
>;
export type GetCompanyFacilityLazyQueryHookResult = ReturnType<
  typeof useGetCompanyFacilityLazyQuery
>;
export type GetCompanyFacilityQueryResult = Apollo.QueryResult<
  GetCompanyFacilityQuery,
  GetCompanyFacilityQueryVariables
>;
export const GetCompanyLicenseDocument = gql`
  query GetCompanyLicense($id: uuid!) {
    company_licenses_by_pk(id: $id) {
      id
      ...CompanyLicense
    }
  }
  ${CompanyLicenseFragmentDoc}
`;

/**
 * __useGetCompanyLicenseQuery__
 *
 * To run a query within a React component, call `useGetCompanyLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyLicenseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyLicenseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyLicenseQuery,
    GetCompanyLicenseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyLicenseQuery,
    GetCompanyLicenseQueryVariables
  >(GetCompanyLicenseDocument, options);
}
export function useGetCompanyLicenseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyLicenseQuery,
    GetCompanyLicenseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyLicenseQuery,
    GetCompanyLicenseQueryVariables
  >(GetCompanyLicenseDocument, options);
}
export type GetCompanyLicenseQueryHookResult = ReturnType<
  typeof useGetCompanyLicenseQuery
>;
export type GetCompanyLicenseLazyQueryHookResult = ReturnType<
  typeof useGetCompanyLicenseLazyQuery
>;
export type GetCompanyLicenseQueryResult = Apollo.QueryResult<
  GetCompanyLicenseQuery,
  GetCompanyLicenseQueryVariables
>;
export const GetCompanyLicenseRelationsByCompanyIdDocument = gql`
  query GetCompanyLicenseRelationsByCompanyId($company_id: uuid!) {
    companies_by_pk(id: $company_id) {
      id
      company_facilities(
        where: {
          _or: [
            { is_deleted: { _is_null: true } }
            { is_deleted: { _eq: false } }
          ]
        }
        order_by: { name: asc }
      ) {
        id
        ...CompanyFacility
      }
    }
  }
  ${CompanyFacilityFragmentDoc}
`;

/**
 * __useGetCompanyLicenseRelationsByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyLicenseRelationsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyLicenseRelationsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyLicenseRelationsByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetCompanyLicenseRelationsByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyLicenseRelationsByCompanyIdQuery,
    GetCompanyLicenseRelationsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyLicenseRelationsByCompanyIdQuery,
    GetCompanyLicenseRelationsByCompanyIdQueryVariables
  >(GetCompanyLicenseRelationsByCompanyIdDocument, options);
}
export function useGetCompanyLicenseRelationsByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyLicenseRelationsByCompanyIdQuery,
    GetCompanyLicenseRelationsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyLicenseRelationsByCompanyIdQuery,
    GetCompanyLicenseRelationsByCompanyIdQueryVariables
  >(GetCompanyLicenseRelationsByCompanyIdDocument, options);
}
export type GetCompanyLicenseRelationsByCompanyIdQueryHookResult = ReturnType<
  typeof useGetCompanyLicenseRelationsByCompanyIdQuery
>;
export type GetCompanyLicenseRelationsByCompanyIdLazyQueryHookResult =
  ReturnType<typeof useGetCompanyLicenseRelationsByCompanyIdLazyQuery>;
export type GetCompanyLicenseRelationsByCompanyIdQueryResult =
  Apollo.QueryResult<
    GetCompanyLicenseRelationsByCompanyIdQuery,
    GetCompanyLicenseRelationsByCompanyIdQueryVariables
  >;
export const GetCompanyLicensesByLicenseNumberDocument = gql`
  query GetCompanyLicensesByLicenseNumber($license_number: String!) {
    company_licenses(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { company_id: { _is_null: true } }
          { license_number: { _eq: $license_number } }
        ]
      }
    ) {
      id
      ...CompanyLicenseLimited
    }
  }
  ${CompanyLicenseLimitedFragmentDoc}
`;

/**
 * __useGetCompanyLicensesByLicenseNumberQuery__
 *
 * To run a query within a React component, call `useGetCompanyLicensesByLicenseNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyLicensesByLicenseNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyLicensesByLicenseNumberQuery({
 *   variables: {
 *      license_number: // value for 'license_number'
 *   },
 * });
 */
export function useGetCompanyLicensesByLicenseNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyLicensesByLicenseNumberQuery,
    GetCompanyLicensesByLicenseNumberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyLicensesByLicenseNumberQuery,
    GetCompanyLicensesByLicenseNumberQueryVariables
  >(GetCompanyLicensesByLicenseNumberDocument, options);
}
export function useGetCompanyLicensesByLicenseNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyLicensesByLicenseNumberQuery,
    GetCompanyLicensesByLicenseNumberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyLicensesByLicenseNumberQuery,
    GetCompanyLicensesByLicenseNumberQueryVariables
  >(GetCompanyLicensesByLicenseNumberDocument, options);
}
export type GetCompanyLicensesByLicenseNumberQueryHookResult = ReturnType<
  typeof useGetCompanyLicensesByLicenseNumberQuery
>;
export type GetCompanyLicensesByLicenseNumberLazyQueryHookResult = ReturnType<
  typeof useGetCompanyLicensesByLicenseNumberLazyQuery
>;
export type GetCompanyLicensesByLicenseNumberQueryResult = Apollo.QueryResult<
  GetCompanyLicensesByLicenseNumberQuery,
  GetCompanyLicensesByLicenseNumberQueryVariables
>;
export const GetCompanyLicensesByLicenseNumbersDocument = gql`
  query GetCompanyLicensesByLicenseNumbers($license_numbers: [String!]!) {
    company_licenses(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { license_number: { _in: $license_numbers } }
        ]
      }
    ) {
      id
      ...CompanyLicense
    }
  }
  ${CompanyLicenseFragmentDoc}
`;

/**
 * __useGetCompanyLicensesByLicenseNumbersQuery__
 *
 * To run a query within a React component, call `useGetCompanyLicensesByLicenseNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyLicensesByLicenseNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyLicensesByLicenseNumbersQuery({
 *   variables: {
 *      license_numbers: // value for 'license_numbers'
 *   },
 * });
 */
export function useGetCompanyLicensesByLicenseNumbersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyLicensesByLicenseNumbersQuery,
    GetCompanyLicensesByLicenseNumbersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyLicensesByLicenseNumbersQuery,
    GetCompanyLicensesByLicenseNumbersQueryVariables
  >(GetCompanyLicensesByLicenseNumbersDocument, options);
}
export function useGetCompanyLicensesByLicenseNumbersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyLicensesByLicenseNumbersQuery,
    GetCompanyLicensesByLicenseNumbersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyLicensesByLicenseNumbersQuery,
    GetCompanyLicensesByLicenseNumbersQueryVariables
  >(GetCompanyLicensesByLicenseNumbersDocument, options);
}
export type GetCompanyLicensesByLicenseNumbersQueryHookResult = ReturnType<
  typeof useGetCompanyLicensesByLicenseNumbersQuery
>;
export type GetCompanyLicensesByLicenseNumbersLazyQueryHookResult = ReturnType<
  typeof useGetCompanyLicensesByLicenseNumbersLazyQuery
>;
export type GetCompanyLicensesByLicenseNumbersQueryResult = Apollo.QueryResult<
  GetCompanyLicensesByLicenseNumbersQuery,
  GetCompanyLicensesByLicenseNumbersQueryVariables
>;
export const GetAllCompanyLicensesDocument = gql`
  query GetAllCompanyLicenses($license_number: String!) {
    company_licenses(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { license_number: { _eq: $license_number } }
        ]
      }
    ) {
      ...CompanyLicense
    }
  }
  ${CompanyLicenseFragmentDoc}
`;

/**
 * __useGetAllCompanyLicensesQuery__
 *
 * To run a query within a React component, call `useGetAllCompanyLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompanyLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompanyLicensesQuery({
 *   variables: {
 *      license_number: // value for 'license_number'
 *   },
 * });
 */
export function useGetAllCompanyLicensesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllCompanyLicensesQuery,
    GetAllCompanyLicensesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCompanyLicensesQuery,
    GetAllCompanyLicensesQueryVariables
  >(GetAllCompanyLicensesDocument, options);
}
export function useGetAllCompanyLicensesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCompanyLicensesQuery,
    GetAllCompanyLicensesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCompanyLicensesQuery,
    GetAllCompanyLicensesQueryVariables
  >(GetAllCompanyLicensesDocument, options);
}
export type GetAllCompanyLicensesQueryHookResult = ReturnType<
  typeof useGetAllCompanyLicensesQuery
>;
export type GetAllCompanyLicensesLazyQueryHookResult = ReturnType<
  typeof useGetAllCompanyLicensesLazyQuery
>;
export type GetAllCompanyLicensesQueryResult = Apollo.QueryResult<
  GetAllCompanyLicensesQuery,
  GetAllCompanyLicensesQueryVariables
>;
export const GetCompanyLicensesForVendorOnboardingDocument = gql`
  query GetCompanyLicensesForVendorOnboarding($license_number_search: String!) {
    company_licenses(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { license_number: { _ilike: $license_number_search } }
        ]
      }
      limit: 20
    ) {
      ...CompanyLicenseLimitedAnonymous
    }
  }
  ${CompanyLicenseLimitedAnonymousFragmentDoc}
`;

/**
 * __useGetCompanyLicensesForVendorOnboardingQuery__
 *
 * To run a query within a React component, call `useGetCompanyLicensesForVendorOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyLicensesForVendorOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyLicensesForVendorOnboardingQuery({
 *   variables: {
 *      license_number_search: // value for 'license_number_search'
 *   },
 * });
 */
export function useGetCompanyLicensesForVendorOnboardingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyLicensesForVendorOnboardingQuery,
    GetCompanyLicensesForVendorOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyLicensesForVendorOnboardingQuery,
    GetCompanyLicensesForVendorOnboardingQueryVariables
  >(GetCompanyLicensesForVendorOnboardingDocument, options);
}
export function useGetCompanyLicensesForVendorOnboardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyLicensesForVendorOnboardingQuery,
    GetCompanyLicensesForVendorOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyLicensesForVendorOnboardingQuery,
    GetCompanyLicensesForVendorOnboardingQueryVariables
  >(GetCompanyLicensesForVendorOnboardingDocument, options);
}
export type GetCompanyLicensesForVendorOnboardingQueryHookResult = ReturnType<
  typeof useGetCompanyLicensesForVendorOnboardingQuery
>;
export type GetCompanyLicensesForVendorOnboardingLazyQueryHookResult =
  ReturnType<typeof useGetCompanyLicensesForVendorOnboardingLazyQuery>;
export type GetCompanyLicensesForVendorOnboardingQueryResult =
  Apollo.QueryResult<
    GetCompanyLicensesForVendorOnboardingQuery,
    GetCompanyLicensesForVendorOnboardingQueryVariables
  >;
export const GetCustomerFinancialSummaryByDateDocument = gql`
  subscription GetCustomerFinancialSummaryByDate(
    $company_id: uuid!
    $date: date!
  ) {
    companies_by_pk(id: $company_id) {
      id
      financial_summaries(where: { date: { _eq: $date } }) {
        id
        ...FinancialSummary
      }
    }
  }
  ${FinancialSummaryFragmentDoc}
`;

/**
 * __useGetCustomerFinancialSummaryByDateSubscription__
 *
 * To run a query within a React component, call `useGetCustomerFinancialSummaryByDateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerFinancialSummaryByDateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerFinancialSummaryByDateSubscription({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetCustomerFinancialSummaryByDateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetCustomerFinancialSummaryByDateSubscription,
    GetCustomerFinancialSummaryByDateSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetCustomerFinancialSummaryByDateSubscription,
    GetCustomerFinancialSummaryByDateSubscriptionVariables
  >(GetCustomerFinancialSummaryByDateDocument, options);
}
export type GetCustomerFinancialSummaryByDateSubscriptionHookResult =
  ReturnType<typeof useGetCustomerFinancialSummaryByDateSubscription>;
export type GetCustomerFinancialSummaryByDateSubscriptionResult =
  Apollo.SubscriptionResult<GetCustomerFinancialSummaryByDateSubscription>;
export const GetCustomerOverviewDocument = gql`
  query GetCustomerOverview($company_id: uuid!, $loan_type: loan_type_enum) {
    companies_by_pk(id: $company_id) {
      id
      name
      contract {
        id
        ...Contract
      }
      outstanding_loans: loans(
        where: {
          _and: [
            {
              _or: [
                { is_deleted: { _is_null: true } }
                { is_deleted: { _eq: false } }
              ]
            }
            { loan_type: { _eq: $loan_type } }
            { funded_at: { _is_null: false } }
            { closed_at: { _is_null: true } }
          ]
        }
        order_by: [
          { adjusted_maturity_date: asc }
          { origination_date: asc }
          { created_at: asc }
          { amount: asc }
        ]
      ) {
        id
        ...LoanLimited
        ...LoanArtifactLimited
      }
      parent_company {
        id
        name
        companies {
          id
        }
      }
      pending_payments: payments(
        where: {
          _and: [
            {
              _or: [
                { is_deleted: { _is_null: true } }
                { is_deleted: { _eq: false } }
              ]
            }
            { type: { _in: ["repayment", "repayment_account_fee"] } }
            { submitted_at: { _is_null: false } }
            { settled_at: { _is_null: true } }
            { reversed_at: { _is_null: true } }
          ]
        }
      ) {
        id
        ...PaymentLimited
      }
      settings {
        id
        ...CompanySettingsLimited
      }
      financial_summaries {
        id
        available_limit
        date
        needs_recompute
      }
    }
  }
  ${ContractFragmentDoc}
  ${LoanLimitedFragmentDoc}
  ${LoanArtifactLimitedFragmentDoc}
  ${PaymentLimitedFragmentDoc}
  ${CompanySettingsLimitedFragmentDoc}
`;

/**
 * __useGetCustomerOverviewQuery__
 *
 * To run a query within a React component, call `useGetCustomerOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerOverviewQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      loan_type: // value for 'loan_type'
 *   },
 * });
 */
export function useGetCustomerOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerOverviewQuery,
    GetCustomerOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomerOverviewQuery,
    GetCustomerOverviewQueryVariables
  >(GetCustomerOverviewDocument, options);
}
export function useGetCustomerOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerOverviewQuery,
    GetCustomerOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomerOverviewQuery,
    GetCustomerOverviewQueryVariables
  >(GetCustomerOverviewDocument, options);
}
export type GetCustomerOverviewQueryHookResult = ReturnType<
  typeof useGetCustomerOverviewQuery
>;
export type GetCustomerOverviewLazyQueryHookResult = ReturnType<
  typeof useGetCustomerOverviewLazyQuery
>;
export type GetCustomerOverviewQueryResult = Apollo.QueryResult<
  GetCustomerOverviewQuery,
  GetCustomerOverviewQueryVariables
>;
export const GetCustomerAccountDocument = gql`
  query GetCustomerAccount($company_id: uuid!) {
    companies_by_pk(id: $company_id) {
      id
      fee_payments: payments(
        where: {
          _and: [
            {
              _or: [
                { is_deleted: { _is_null: true } }
                { is_deleted: { _eq: false } }
              ]
            }
            {
              type: {
                _in: ["fee", "fee_waiver", "repayment_account_fee", "repayment"]
              }
            }
          ]
        }
        order_by: [
          { deposit_date: desc }
          { settlement_date: desc }
          { submitted_at: desc }
        ]
      ) {
        id
        ...PaymentLimited
        transactions {
          id
          ...Transaction
          loan {
            id
            ...Loan
          }
        }
      }
      pending_payments: payments(
        where: {
          _and: [
            {
              _or: [
                { is_deleted: { _is_null: true } }
                { is_deleted: { _eq: false } }
              ]
            }
            { type: { _eq: "repayment" } }
            { submitted_at: { _is_null: false } }
            { settled_at: { _is_null: true } }
            { reversed_at: { _is_null: true } }
          ]
        }
        order_by: { created_at: asc }
      ) {
        id
        ...PaymentLimited
      }
    }
  }
  ${PaymentLimitedFragmentDoc}
  ${TransactionFragmentDoc}
  ${LoanFragmentDoc}
`;

/**
 * __useGetCustomerAccountQuery__
 *
 * To run a query within a React component, call `useGetCustomerAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAccountQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetCustomerAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerAccountQuery,
    GetCustomerAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomerAccountQuery,
    GetCustomerAccountQueryVariables
  >(GetCustomerAccountDocument, options);
}
export function useGetCustomerAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerAccountQuery,
    GetCustomerAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomerAccountQuery,
    GetCustomerAccountQueryVariables
  >(GetCustomerAccountDocument, options);
}
export type GetCustomerAccountQueryHookResult = ReturnType<
  typeof useGetCustomerAccountQuery
>;
export type GetCustomerAccountLazyQueryHookResult = ReturnType<
  typeof useGetCustomerAccountLazyQuery
>;
export type GetCustomerAccountQueryResult = Apollo.QueryResult<
  GetCustomerAccountQuery,
  GetCustomerAccountQueryVariables
>;
export const GetCompanyForBankCompanyPageDocument = gql`
  query GetCompanyForBankCompanyPage($id: uuid!) {
    companies_by_pk(id: $id) {
      id
      name
      is_customer
      is_payor
      is_vendor
      surveillance_status
      contract {
        id
        product_type
        start_date
        terminated_at
      }
      most_recent_surveillance_result: customer_surveillance_results(
        limit: 1
        order_by: { qualifying_date: desc }
        where: {
          _or: [
            { is_deleted: { _is_null: true } }
            { is_deleted: { _eq: false } }
          ]
        }
      ) {
        id
        ...CustomerSurveillanceResult
      }
    }
  }
  ${CustomerSurveillanceResultFragmentDoc}
`;

/**
 * __useGetCompanyForBankCompanyPageQuery__
 *
 * To run a query within a React component, call `useGetCompanyForBankCompanyPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyForBankCompanyPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyForBankCompanyPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyForBankCompanyPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyForBankCompanyPageQuery,
    GetCompanyForBankCompanyPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyForBankCompanyPageQuery,
    GetCompanyForBankCompanyPageQueryVariables
  >(GetCompanyForBankCompanyPageDocument, options);
}
export function useGetCompanyForBankCompanyPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyForBankCompanyPageQuery,
    GetCompanyForBankCompanyPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyForBankCompanyPageQuery,
    GetCompanyForBankCompanyPageQueryVariables
  >(GetCompanyForBankCompanyPageDocument, options);
}
export type GetCompanyForBankCompanyPageQueryHookResult = ReturnType<
  typeof useGetCompanyForBankCompanyPageQuery
>;
export type GetCompanyForBankCompanyPageLazyQueryHookResult = ReturnType<
  typeof useGetCompanyForBankCompanyPageLazyQuery
>;
export type GetCompanyForBankCompanyPageQueryResult = Apollo.QueryResult<
  GetCompanyForBankCompanyPageQuery,
  GetCompanyForBankCompanyPageQueryVariables
>;
export const GetParentCompanyForBankParentCompanyPageDocument = gql`
  query GetParentCompanyForBankParentCompanyPage($id: uuid!) {
    parent_companies_by_pk(id: $id) {
      id
      name
      companies {
        id
        ...Company
      }
      users {
        id
        ...User
      }
    }
  }
  ${CompanyFragmentDoc}
  ${UserFragmentDoc}
`;

/**
 * __useGetParentCompanyForBankParentCompanyPageQuery__
 *
 * To run a query within a React component, call `useGetParentCompanyForBankParentCompanyPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentCompanyForBankParentCompanyPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentCompanyForBankParentCompanyPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetParentCompanyForBankParentCompanyPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParentCompanyForBankParentCompanyPageQuery,
    GetParentCompanyForBankParentCompanyPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetParentCompanyForBankParentCompanyPageQuery,
    GetParentCompanyForBankParentCompanyPageQueryVariables
  >(GetParentCompanyForBankParentCompanyPageDocument, options);
}
export function useGetParentCompanyForBankParentCompanyPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParentCompanyForBankParentCompanyPageQuery,
    GetParentCompanyForBankParentCompanyPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParentCompanyForBankParentCompanyPageQuery,
    GetParentCompanyForBankParentCompanyPageQueryVariables
  >(GetParentCompanyForBankParentCompanyPageDocument, options);
}
export type GetParentCompanyForBankParentCompanyPageQueryHookResult =
  ReturnType<typeof useGetParentCompanyForBankParentCompanyPageQuery>;
export type GetParentCompanyForBankParentCompanyPageLazyQueryHookResult =
  ReturnType<typeof useGetParentCompanyForBankParentCompanyPageLazyQuery>;
export type GetParentCompanyForBankParentCompanyPageQueryResult =
  Apollo.QueryResult<
    GetParentCompanyForBankParentCompanyPageQuery,
    GetParentCompanyForBankParentCompanyPageQueryVariables
  >;
export const GetCompanyForVendorOnboardingDocument = gql`
  query GetCompanyForVendorOnboarding($id: uuid!) {
    companies_by_pk(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useGetCompanyForVendorOnboardingQuery__
 *
 * To run a query within a React component, call `useGetCompanyForVendorOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyForVendorOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyForVendorOnboardingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyForVendorOnboardingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyForVendorOnboardingQuery,
    GetCompanyForVendorOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyForVendorOnboardingQuery,
    GetCompanyForVendorOnboardingQueryVariables
  >(GetCompanyForVendorOnboardingDocument, options);
}
export function useGetCompanyForVendorOnboardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyForVendorOnboardingQuery,
    GetCompanyForVendorOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyForVendorOnboardingQuery,
    GetCompanyForVendorOnboardingQueryVariables
  >(GetCompanyForVendorOnboardingDocument, options);
}
export type GetCompanyForVendorOnboardingQueryHookResult = ReturnType<
  typeof useGetCompanyForVendorOnboardingQuery
>;
export type GetCompanyForVendorOnboardingLazyQueryHookResult = ReturnType<
  typeof useGetCompanyForVendorOnboardingLazyQuery
>;
export type GetCompanyForVendorOnboardingQueryResult = Apollo.QueryResult<
  GetCompanyForVendorOnboardingQuery,
  GetCompanyForVendorOnboardingQueryVariables
>;
export const GetCustomerOldestContractDocument = gql`
  query GetCustomerOldestContract($company_id: uuid!) {
    oldest_contract: contracts(
      where: { company_id: { _eq: $company_id } }
      order_by: { start_date: asc }
      limit: 1
    ) {
      id
      ...Contract
    }
  }
  ${ContractFragmentDoc}
`;

/**
 * __useGetCustomerOldestContractQuery__
 *
 * To run a query within a React component, call `useGetCustomerOldestContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerOldestContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerOldestContractQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetCustomerOldestContractQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerOldestContractQuery,
    GetCustomerOldestContractQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomerOldestContractQuery,
    GetCustomerOldestContractQueryVariables
  >(GetCustomerOldestContractDocument, options);
}
export function useGetCustomerOldestContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerOldestContractQuery,
    GetCustomerOldestContractQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomerOldestContractQuery,
    GetCustomerOldestContractQueryVariables
  >(GetCustomerOldestContractDocument, options);
}
export type GetCustomerOldestContractQueryHookResult = ReturnType<
  typeof useGetCustomerOldestContractQuery
>;
export type GetCustomerOldestContractLazyQueryHookResult = ReturnType<
  typeof useGetCustomerOldestContractLazyQuery
>;
export type GetCustomerOldestContractQueryResult = Apollo.QueryResult<
  GetCustomerOldestContractQuery,
  GetCustomerOldestContractQueryVariables
>;
export const GetFinancialSummariesByCompanyIdDocument = gql`
  query GetFinancialSummariesByCompanyId($companyId: uuid!) {
    financial_summaries(
      where: { company_id: { _eq: $companyId } }
      order_by: { date: desc }
    ) {
      id
      ...FinancialSummary
      company {
        id
        name
        debt_facility_status
      }
    }
  }
  ${FinancialSummaryFragmentDoc}
`;

/**
 * __useGetFinancialSummariesByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetFinancialSummariesByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialSummariesByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialSummariesByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetFinancialSummariesByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFinancialSummariesByCompanyIdQuery,
    GetFinancialSummariesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFinancialSummariesByCompanyIdQuery,
    GetFinancialSummariesByCompanyIdQueryVariables
  >(GetFinancialSummariesByCompanyIdDocument, options);
}
export function useGetFinancialSummariesByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFinancialSummariesByCompanyIdQuery,
    GetFinancialSummariesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFinancialSummariesByCompanyIdQuery,
    GetFinancialSummariesByCompanyIdQueryVariables
  >(GetFinancialSummariesByCompanyIdDocument, options);
}
export type GetFinancialSummariesByCompanyIdQueryHookResult = ReturnType<
  typeof useGetFinancialSummariesByCompanyIdQuery
>;
export type GetFinancialSummariesByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetFinancialSummariesByCompanyIdLazyQuery
>;
export type GetFinancialSummariesByCompanyIdQueryResult = Apollo.QueryResult<
  GetFinancialSummariesByCompanyIdQuery,
  GetFinancialSummariesByCompanyIdQueryVariables
>;
export const GetMostRecentFinancialSummaryAndContractByCompanyIdDocument = gql`
  query GetMostRecentFinancialSummaryAndContractByCompanyId($companyId: uuid!) {
    financial_summaries(
      where: { company_id: { _eq: $companyId } }
      order_by: { date: desc }
      limit: 1
    ) {
      id
      product_type
      ...FinancialSummary
    }
    contracts(
      where: { company_id: { _eq: $companyId } }
      order_by: { adjusted_end_date: desc }
      limit: 1
    ) {
      id
      ...Contract
    }
  }
  ${FinancialSummaryFragmentDoc}
  ${ContractFragmentDoc}
`;

/**
 * __useGetMostRecentFinancialSummaryAndContractByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetMostRecentFinancialSummaryAndContractByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostRecentFinancialSummaryAndContractByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostRecentFinancialSummaryAndContractByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetMostRecentFinancialSummaryAndContractByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMostRecentFinancialSummaryAndContractByCompanyIdQuery,
    GetMostRecentFinancialSummaryAndContractByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMostRecentFinancialSummaryAndContractByCompanyIdQuery,
    GetMostRecentFinancialSummaryAndContractByCompanyIdQueryVariables
  >(GetMostRecentFinancialSummaryAndContractByCompanyIdDocument, options);
}
export function useGetMostRecentFinancialSummaryAndContractByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMostRecentFinancialSummaryAndContractByCompanyIdQuery,
    GetMostRecentFinancialSummaryAndContractByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMostRecentFinancialSummaryAndContractByCompanyIdQuery,
    GetMostRecentFinancialSummaryAndContractByCompanyIdQueryVariables
  >(GetMostRecentFinancialSummaryAndContractByCompanyIdDocument, options);
}
export type GetMostRecentFinancialSummaryAndContractByCompanyIdQueryHookResult =
  ReturnType<
    typeof useGetMostRecentFinancialSummaryAndContractByCompanyIdQuery
  >;
export type GetMostRecentFinancialSummaryAndContractByCompanyIdLazyQueryHookResult =
  ReturnType<
    typeof useGetMostRecentFinancialSummaryAndContractByCompanyIdLazyQuery
  >;
export type GetMostRecentFinancialSummaryAndContractByCompanyIdQueryResult =
  Apollo.QueryResult<
    GetMostRecentFinancialSummaryAndContractByCompanyIdQuery,
    GetMostRecentFinancialSummaryAndContractByCompanyIdQueryVariables
  >;
export const GetFinancialSummariesByDateDocument = gql`
  query GetFinancialSummariesByDate($date: date!) {
    financial_summaries(where: { date: { _eq: $date } }) {
      id
      ...FinancialSummary
      company {
        id
        name
      }
    }
  }
  ${FinancialSummaryFragmentDoc}
`;

/**
 * __useGetFinancialSummariesByDateQuery__
 *
 * To run a query within a React component, call `useGetFinancialSummariesByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialSummariesByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialSummariesByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetFinancialSummariesByDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFinancialSummariesByDateQuery,
    GetFinancialSummariesByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFinancialSummariesByDateQuery,
    GetFinancialSummariesByDateQueryVariables
  >(GetFinancialSummariesByDateDocument, options);
}
export function useGetFinancialSummariesByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFinancialSummariesByDateQuery,
    GetFinancialSummariesByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFinancialSummariesByDateQuery,
    GetFinancialSummariesByDateQueryVariables
  >(GetFinancialSummariesByDateDocument, options);
}
export type GetFinancialSummariesByDateQueryHookResult = ReturnType<
  typeof useGetFinancialSummariesByDateQuery
>;
export type GetFinancialSummariesByDateLazyQueryHookResult = ReturnType<
  typeof useGetFinancialSummariesByDateLazyQuery
>;
export type GetFinancialSummariesByDateQueryResult = Apollo.QueryResult<
  GetFinancialSummariesByDateQuery,
  GetFinancialSummariesByDateQueryVariables
>;
export const GetActiveFinancialSummariesByDateDocument = gql`
  query GetActiveFinancialSummariesByDate($date: date!) {
    financial_summaries(
      where: {
        _and: [
          { date: { _eq: $date } }
          { product_type: { _is_null: false } }
          {
            _or: [
              {
                company: { settings: { is_dummy_account: { _is_null: true } } }
              }
              { company: { settings: { is_dummy_account: { _eq: false } } } }
            ]
          }
        ]
      }
    ) {
      id
      ...FinancialSummary
      company {
        id
        name
        debt_facility_status
      }
    }
  }
  ${FinancialSummaryFragmentDoc}
`;

/**
 * __useGetActiveFinancialSummariesByDateQuery__
 *
 * To run a query within a React component, call `useGetActiveFinancialSummariesByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveFinancialSummariesByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveFinancialSummariesByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetActiveFinancialSummariesByDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActiveFinancialSummariesByDateQuery,
    GetActiveFinancialSummariesByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveFinancialSummariesByDateQuery,
    GetActiveFinancialSummariesByDateQueryVariables
  >(GetActiveFinancialSummariesByDateDocument, options);
}
export function useGetActiveFinancialSummariesByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveFinancialSummariesByDateQuery,
    GetActiveFinancialSummariesByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveFinancialSummariesByDateQuery,
    GetActiveFinancialSummariesByDateQueryVariables
  >(GetActiveFinancialSummariesByDateDocument, options);
}
export type GetActiveFinancialSummariesByDateQueryHookResult = ReturnType<
  typeof useGetActiveFinancialSummariesByDateQuery
>;
export type GetActiveFinancialSummariesByDateLazyQueryHookResult = ReturnType<
  typeof useGetActiveFinancialSummariesByDateLazyQuery
>;
export type GetActiveFinancialSummariesByDateQueryResult = Apollo.QueryResult<
  GetActiveFinancialSummariesByDateQuery,
  GetActiveFinancialSummariesByDateQueryVariables
>;
export const GetFinancialSummariesAndLoansByCompanyIdDocument = gql`
  query GetFinancialSummariesAndLoansByCompanyId($companyId: uuid!) {
    companies_by_pk(id: $companyId) {
      id
      identifier
      name
      financial_summaries(order_by: { date: desc }) {
        id
        ...FinancialSummaryWithLoansInfo
      }
      loans(
        where: {
          _or: [
            { is_deleted: { _is_null: true } }
            { is_deleted: { _eq: false } }
          ]
        }
      ) {
        id
        identifier
        adjusted_maturity_date
      }
      contracts(order_by: { start_date: desc }) {
        id
        ...Contract
      }
    }
  }
  ${FinancialSummaryWithLoansInfoFragmentDoc}
  ${ContractFragmentDoc}
`;

/**
 * __useGetFinancialSummariesAndLoansByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetFinancialSummariesAndLoansByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialSummariesAndLoansByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialSummariesAndLoansByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetFinancialSummariesAndLoansByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFinancialSummariesAndLoansByCompanyIdQuery,
    GetFinancialSummariesAndLoansByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFinancialSummariesAndLoansByCompanyIdQuery,
    GetFinancialSummariesAndLoansByCompanyIdQueryVariables
  >(GetFinancialSummariesAndLoansByCompanyIdDocument, options);
}
export function useGetFinancialSummariesAndLoansByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFinancialSummariesAndLoansByCompanyIdQuery,
    GetFinancialSummariesAndLoansByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFinancialSummariesAndLoansByCompanyIdQuery,
    GetFinancialSummariesAndLoansByCompanyIdQueryVariables
  >(GetFinancialSummariesAndLoansByCompanyIdDocument, options);
}
export type GetFinancialSummariesAndLoansByCompanyIdQueryHookResult =
  ReturnType<typeof useGetFinancialSummariesAndLoansByCompanyIdQuery>;
export type GetFinancialSummariesAndLoansByCompanyIdLazyQueryHookResult =
  ReturnType<typeof useGetFinancialSummariesAndLoansByCompanyIdLazyQuery>;
export type GetFinancialSummariesAndLoansByCompanyIdQueryResult =
  Apollo.QueryResult<
    GetFinancialSummariesAndLoansByCompanyIdQuery,
    GetFinancialSummariesAndLoansByCompanyIdQueryVariables
  >;
export const GetCustomersSurveillanceDocument = gql`
  subscription GetCustomersSurveillance($target_date: date) {
    customers: companies(
      where: {
        _and: [
          {
            _or: [
              { settings: { is_dummy_account: { _is_null: true } } }
              { settings: { is_dummy_account: { _eq: false } } }
            ]
          }
          { is_customer: { _eq: true } }
        ]
      }
      order_by: { name: asc }
    ) {
      id
      ...CustomerSurveillance
    }
  }
  ${CustomerSurveillanceFragmentDoc}
`;

/**
 * __useGetCustomersSurveillanceSubscription__
 *
 * To run a query within a React component, call `useGetCustomersSurveillanceSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersSurveillanceSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersSurveillanceSubscription({
 *   variables: {
 *      target_date: // value for 'target_date'
 *   },
 * });
 */
export function useGetCustomersSurveillanceSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetCustomersSurveillanceSubscription,
    GetCustomersSurveillanceSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetCustomersSurveillanceSubscription,
    GetCustomersSurveillanceSubscriptionVariables
  >(GetCustomersSurveillanceDocument, options);
}
export type GetCustomersSurveillanceSubscriptionHookResult = ReturnType<
  typeof useGetCustomersSurveillanceSubscription
>;
export type GetCustomersSurveillanceSubscriptionResult =
  Apollo.SubscriptionResult<GetCustomersSurveillanceSubscription>;
export const GetCustomersSurveillanceByCompanyIdDocument = gql`
  query GetCustomersSurveillanceByCompanyId($target_date: date, $id: uuid!) {
    customer: companies_by_pk(id: $id) {
      id
      ...CustomerSurveillance
      settings {
        ...CompanyDealOwners
      }
    }
  }
  ${CustomerSurveillanceFragmentDoc}
  ${CompanyDealOwnersFragmentDoc}
`;

/**
 * __useGetCustomersSurveillanceByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCustomersSurveillanceByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersSurveillanceByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersSurveillanceByCompanyIdQuery({
 *   variables: {
 *      target_date: // value for 'target_date'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomersSurveillanceByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomersSurveillanceByCompanyIdQuery,
    GetCustomersSurveillanceByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomersSurveillanceByCompanyIdQuery,
    GetCustomersSurveillanceByCompanyIdQueryVariables
  >(GetCustomersSurveillanceByCompanyIdDocument, options);
}
export function useGetCustomersSurveillanceByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomersSurveillanceByCompanyIdQuery,
    GetCustomersSurveillanceByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomersSurveillanceByCompanyIdQuery,
    GetCustomersSurveillanceByCompanyIdQueryVariables
  >(GetCustomersSurveillanceByCompanyIdDocument, options);
}
export type GetCustomersSurveillanceByCompanyIdQueryHookResult = ReturnType<
  typeof useGetCustomersSurveillanceByCompanyIdQuery
>;
export type GetCustomersSurveillanceByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetCustomersSurveillanceByCompanyIdLazyQuery
>;
export type GetCustomersSurveillanceByCompanyIdQueryResult = Apollo.QueryResult<
  GetCustomersSurveillanceByCompanyIdQuery,
  GetCustomersSurveillanceByCompanyIdQueryVariables
>;
export const GetSurveillanceResultByIdDocument = gql`
  query GetSurveillanceResultById($surveillance_result_id: uuid!) {
    surveillance_result: customer_surveillance_results_by_pk(
      id: $surveillance_result_id
    ) {
      id
      ...CustomerSurveillanceResult
      submitting_user {
        id
        first_name
        last_name
      }
    }
  }
  ${CustomerSurveillanceResultFragmentDoc}
`;

/**
 * __useGetSurveillanceResultByIdQuery__
 *
 * To run a query within a React component, call `useGetSurveillanceResultByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveillanceResultByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveillanceResultByIdQuery({
 *   variables: {
 *      surveillance_result_id: // value for 'surveillance_result_id'
 *   },
 * });
 */
export function useGetSurveillanceResultByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSurveillanceResultByIdQuery,
    GetSurveillanceResultByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSurveillanceResultByIdQuery,
    GetSurveillanceResultByIdQueryVariables
  >(GetSurveillanceResultByIdDocument, options);
}
export function useGetSurveillanceResultByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSurveillanceResultByIdQuery,
    GetSurveillanceResultByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSurveillanceResultByIdQuery,
    GetSurveillanceResultByIdQueryVariables
  >(GetSurveillanceResultByIdDocument, options);
}
export type GetSurveillanceResultByIdQueryHookResult = ReturnType<
  typeof useGetSurveillanceResultByIdQuery
>;
export type GetSurveillanceResultByIdLazyQueryHookResult = ReturnType<
  typeof useGetSurveillanceResultByIdLazyQuery
>;
export type GetSurveillanceResultByIdQueryResult = Apollo.QueryResult<
  GetSurveillanceResultByIdQuery,
  GetSurveillanceResultByIdQueryVariables
>;
export const GetSurveillanceResultByCompanyIdDocument = gql`
  query GetSurveillanceResultByCompanyId($company_id: uuid!) {
    customer_surveillance_results(
      where: {
        _and: [
          { company_id: { _eq: $company_id } }
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _neq: false } }
            ]
          }
          { _or: [{ deleted_at: { _is_null: true } }] }
        ]
      }
      order_by: { qualifying_date: desc }
      limit: 1
    ) {
      id
      ...CustomerSurveillanceResult
    }
  }
  ${CustomerSurveillanceResultFragmentDoc}
`;

/**
 * __useGetSurveillanceResultByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetSurveillanceResultByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveillanceResultByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveillanceResultByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetSurveillanceResultByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSurveillanceResultByCompanyIdQuery,
    GetSurveillanceResultByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSurveillanceResultByCompanyIdQuery,
    GetSurveillanceResultByCompanyIdQueryVariables
  >(GetSurveillanceResultByCompanyIdDocument, options);
}
export function useGetSurveillanceResultByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSurveillanceResultByCompanyIdQuery,
    GetSurveillanceResultByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSurveillanceResultByCompanyIdQuery,
    GetSurveillanceResultByCompanyIdQueryVariables
  >(GetSurveillanceResultByCompanyIdDocument, options);
}
export type GetSurveillanceResultByCompanyIdQueryHookResult = ReturnType<
  typeof useGetSurveillanceResultByCompanyIdQuery
>;
export type GetSurveillanceResultByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetSurveillanceResultByCompanyIdLazyQuery
>;
export type GetSurveillanceResultByCompanyIdQueryResult = Apollo.QueryResult<
  GetSurveillanceResultByCompanyIdQuery,
  GetSurveillanceResultByCompanyIdQueryVariables
>;
export const GetOpenLoansByDebtFacilityStatusesDocument = gql`
  query GetOpenLoansByDebtFacilityStatuses(
    $statuses: [String!]
    $target_date: date!
  ) {
    companies(
      where: {
        _and: [
          { is_customer: { _eq: true } }
          {
            _or: [
              { settings: { is_dummy_account: { _is_null: true } } }
              { settings: { is_dummy_account: { _eq: false } } }
            ]
          }
        ]
      }
    ) {
      id
      ...CompanyForOpenLoansByDebtFacilityStatuses
    }
  }
  ${CompanyForOpenLoansByDebtFacilityStatusesFragmentDoc}
`;

/**
 * __useGetOpenLoansByDebtFacilityStatusesQuery__
 *
 * To run a query within a React component, call `useGetOpenLoansByDebtFacilityStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenLoansByDebtFacilityStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenLoansByDebtFacilityStatusesQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      target_date: // value for 'target_date'
 *   },
 * });
 */
export function useGetOpenLoansByDebtFacilityStatusesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOpenLoansByDebtFacilityStatusesQuery,
    GetOpenLoansByDebtFacilityStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOpenLoansByDebtFacilityStatusesQuery,
    GetOpenLoansByDebtFacilityStatusesQueryVariables
  >(GetOpenLoansByDebtFacilityStatusesDocument, options);
}
export function useGetOpenLoansByDebtFacilityStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOpenLoansByDebtFacilityStatusesQuery,
    GetOpenLoansByDebtFacilityStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOpenLoansByDebtFacilityStatusesQuery,
    GetOpenLoansByDebtFacilityStatusesQueryVariables
  >(GetOpenLoansByDebtFacilityStatusesDocument, options);
}
export type GetOpenLoansByDebtFacilityStatusesQueryHookResult = ReturnType<
  typeof useGetOpenLoansByDebtFacilityStatusesQuery
>;
export type GetOpenLoansByDebtFacilityStatusesLazyQueryHookResult = ReturnType<
  typeof useGetOpenLoansByDebtFacilityStatusesLazyQuery
>;
export type GetOpenLoansByDebtFacilityStatusesQueryResult = Apollo.QueryResult<
  GetOpenLoansByDebtFacilityStatusesQuery,
  GetOpenLoansByDebtFacilityStatusesQueryVariables
>;
export const GetOpenLoansByDebtFacilityIdDocument = gql`
  query GetOpenLoansByDebtFacilityId(
    $statuses: [String!]
    $target_facility_ids: [uuid!]
    $target_date: date!
  ) {
    companies(
      where: {
        _and: [
          { is_customer: { _eq: true } }
          {
            _or: [
              { settings: { is_dummy_account: { _is_null: true } } }
              { settings: { is_dummy_account: { _eq: false } } }
            ]
          }
        ]
      }
    ) {
      id
      ...CompanyForByDebtFacilityId
    }
  }
  ${CompanyForByDebtFacilityIdFragmentDoc}
`;

/**
 * __useGetOpenLoansByDebtFacilityIdQuery__
 *
 * To run a query within a React component, call `useGetOpenLoansByDebtFacilityIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenLoansByDebtFacilityIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenLoansByDebtFacilityIdQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      target_facility_ids: // value for 'target_facility_ids'
 *      target_date: // value for 'target_date'
 *   },
 * });
 */
export function useGetOpenLoansByDebtFacilityIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOpenLoansByDebtFacilityIdQuery,
    GetOpenLoansByDebtFacilityIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOpenLoansByDebtFacilityIdQuery,
    GetOpenLoansByDebtFacilityIdQueryVariables
  >(GetOpenLoansByDebtFacilityIdDocument, options);
}
export function useGetOpenLoansByDebtFacilityIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOpenLoansByDebtFacilityIdQuery,
    GetOpenLoansByDebtFacilityIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOpenLoansByDebtFacilityIdQuery,
    GetOpenLoansByDebtFacilityIdQueryVariables
  >(GetOpenLoansByDebtFacilityIdDocument, options);
}
export type GetOpenLoansByDebtFacilityIdQueryHookResult = ReturnType<
  typeof useGetOpenLoansByDebtFacilityIdQuery
>;
export type GetOpenLoansByDebtFacilityIdLazyQueryHookResult = ReturnType<
  typeof useGetOpenLoansByDebtFacilityIdLazyQuery
>;
export type GetOpenLoansByDebtFacilityIdQueryResult = Apollo.QueryResult<
  GetOpenLoansByDebtFacilityIdQuery,
  GetOpenLoansByDebtFacilityIdQueryVariables
>;
export const GetReportLoansByDebtFacilityIdDocument = gql`
  query GetReportLoansByDebtFacilityId(
    $debt_facility_statuses: [String!]
    $other_statuses: [String!]
    $target_facility_ids: [uuid!]
    $target_date: date!
  ) {
    companies(
      where: {
        _and: [
          { is_customer: { _eq: true } }
          {
            _or: [
              { settings: { is_dummy_account: { _is_null: true } } }
              { settings: { is_dummy_account: { _eq: false } } }
            ]
          }
        ]
      }
    ) {
      id
      ...CompanyForDebtFacilityReport
    }
  }
  ${CompanyForDebtFacilityReportFragmentDoc}
`;

/**
 * __useGetReportLoansByDebtFacilityIdQuery__
 *
 * To run a query within a React component, call `useGetReportLoansByDebtFacilityIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportLoansByDebtFacilityIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportLoansByDebtFacilityIdQuery({
 *   variables: {
 *      debt_facility_statuses: // value for 'debt_facility_statuses'
 *      other_statuses: // value for 'other_statuses'
 *      target_facility_ids: // value for 'target_facility_ids'
 *      target_date: // value for 'target_date'
 *   },
 * });
 */
export function useGetReportLoansByDebtFacilityIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReportLoansByDebtFacilityIdQuery,
    GetReportLoansByDebtFacilityIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetReportLoansByDebtFacilityIdQuery,
    GetReportLoansByDebtFacilityIdQueryVariables
  >(GetReportLoansByDebtFacilityIdDocument, options);
}
export function useGetReportLoansByDebtFacilityIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReportLoansByDebtFacilityIdQuery,
    GetReportLoansByDebtFacilityIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetReportLoansByDebtFacilityIdQuery,
    GetReportLoansByDebtFacilityIdQueryVariables
  >(GetReportLoansByDebtFacilityIdDocument, options);
}
export type GetReportLoansByDebtFacilityIdQueryHookResult = ReturnType<
  typeof useGetReportLoansByDebtFacilityIdQuery
>;
export type GetReportLoansByDebtFacilityIdLazyQueryHookResult = ReturnType<
  typeof useGetReportLoansByDebtFacilityIdLazyQuery
>;
export type GetReportLoansByDebtFacilityIdQueryResult = Apollo.QueryResult<
  GetReportLoansByDebtFacilityIdQuery,
  GetReportLoansByDebtFacilityIdQueryVariables
>;
export const GetDebtFacilityLoansByIdDocument = gql`
  query GetDebtFacilityLoansById($loan_ids: [uuid!], $target_date: date!) {
    companies(
      where: {
        _and: [
          { is_customer: { _eq: true } }
          {
            _or: [
              { settings: { is_dummy_account: { _is_null: true } } }
              { settings: { is_dummy_account: { _eq: false } } }
            ]
          }
        ]
      }
    ) {
      id
      ...CompanyForDebtFacilityLoansById
    }
  }
  ${CompanyForDebtFacilityLoansByIdFragmentDoc}
`;

/**
 * __useGetDebtFacilityLoansByIdQuery__
 *
 * To run a query within a React component, call `useGetDebtFacilityLoansByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDebtFacilityLoansByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDebtFacilityLoansByIdQuery({
 *   variables: {
 *      loan_ids: // value for 'loan_ids'
 *      target_date: // value for 'target_date'
 *   },
 * });
 */
export function useGetDebtFacilityLoansByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDebtFacilityLoansByIdQuery,
    GetDebtFacilityLoansByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDebtFacilityLoansByIdQuery,
    GetDebtFacilityLoansByIdQueryVariables
  >(GetDebtFacilityLoansByIdDocument, options);
}
export function useGetDebtFacilityLoansByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDebtFacilityLoansByIdQuery,
    GetDebtFacilityLoansByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDebtFacilityLoansByIdQuery,
    GetDebtFacilityLoansByIdQueryVariables
  >(GetDebtFacilityLoansByIdDocument, options);
}
export type GetDebtFacilityLoansByIdQueryHookResult = ReturnType<
  typeof useGetDebtFacilityLoansByIdQuery
>;
export type GetDebtFacilityLoansByIdLazyQueryHookResult = ReturnType<
  typeof useGetDebtFacilityLoansByIdLazyQuery
>;
export type GetDebtFacilityLoansByIdQueryResult = Apollo.QueryResult<
  GetDebtFacilityLoansByIdQuery,
  GetDebtFacilityLoansByIdQueryVariables
>;
export const GetDebtFacilitiesDocument = gql`
  query GetDebtFacilities {
    debt_facilities(order_by: [{ name: desc }]) {
      id
      ...DebtFacility
    }
  }
  ${DebtFacilityFragmentDoc}
`;

/**
 * __useGetDebtFacilitiesQuery__
 *
 * To run a query within a React component, call `useGetDebtFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDebtFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDebtFacilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDebtFacilitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDebtFacilitiesQuery,
    GetDebtFacilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDebtFacilitiesQuery,
    GetDebtFacilitiesQueryVariables
  >(GetDebtFacilitiesDocument, options);
}
export function useGetDebtFacilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDebtFacilitiesQuery,
    GetDebtFacilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDebtFacilitiesQuery,
    GetDebtFacilitiesQueryVariables
  >(GetDebtFacilitiesDocument, options);
}
export type GetDebtFacilitiesQueryHookResult = ReturnType<
  typeof useGetDebtFacilitiesQuery
>;
export type GetDebtFacilitiesLazyQueryHookResult = ReturnType<
  typeof useGetDebtFacilitiesLazyQuery
>;
export type GetDebtFacilitiesQueryResult = Apollo.QueryResult<
  GetDebtFacilitiesQuery,
  GetDebtFacilitiesQueryVariables
>;
export const GetDebtFacilityCurrentCapacityDocument = gql`
  subscription GetDebtFacilityCurrentCapacity($target_facility_id: uuid!) {
    debt_facilities(where: { id: { _eq: $target_facility_id } }) {
      id
      ...DebtFacility
    }
  }
  ${DebtFacilityFragmentDoc}
`;

/**
 * __useGetDebtFacilityCurrentCapacitySubscription__
 *
 * To run a query within a React component, call `useGetDebtFacilityCurrentCapacitySubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetDebtFacilityCurrentCapacitySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDebtFacilityCurrentCapacitySubscription({
 *   variables: {
 *      target_facility_id: // value for 'target_facility_id'
 *   },
 * });
 */
export function useGetDebtFacilityCurrentCapacitySubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetDebtFacilityCurrentCapacitySubscription,
    GetDebtFacilityCurrentCapacitySubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetDebtFacilityCurrentCapacitySubscription,
    GetDebtFacilityCurrentCapacitySubscriptionVariables
  >(GetDebtFacilityCurrentCapacityDocument, options);
}
export type GetDebtFacilityCurrentCapacitySubscriptionHookResult = ReturnType<
  typeof useGetDebtFacilityCurrentCapacitySubscription
>;
export type GetDebtFacilityCurrentCapacitySubscriptionResult =
  Apollo.SubscriptionResult<GetDebtFacilityCurrentCapacitySubscription>;
export const GetDebtFacilityEventsByLoanReportIdDocument = gql`
  query GetDebtFacilityEventsByLoanReportId($loan_report_id: uuid!) {
    debt_facility_events(
      where: { loan_report_id: { _eq: $loan_report_id } }
      order_by: { event_date: desc }
    ) {
      ...DebtFacilityEvent
    }
  }
  ${DebtFacilityEventFragmentDoc}
`;

/**
 * __useGetDebtFacilityEventsByLoanReportIdQuery__
 *
 * To run a query within a React component, call `useGetDebtFacilityEventsByLoanReportIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDebtFacilityEventsByLoanReportIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDebtFacilityEventsByLoanReportIdQuery({
 *   variables: {
 *      loan_report_id: // value for 'loan_report_id'
 *   },
 * });
 */
export function useGetDebtFacilityEventsByLoanReportIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDebtFacilityEventsByLoanReportIdQuery,
    GetDebtFacilityEventsByLoanReportIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDebtFacilityEventsByLoanReportIdQuery,
    GetDebtFacilityEventsByLoanReportIdQueryVariables
  >(GetDebtFacilityEventsByLoanReportIdDocument, options);
}
export function useGetDebtFacilityEventsByLoanReportIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDebtFacilityEventsByLoanReportIdQuery,
    GetDebtFacilityEventsByLoanReportIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDebtFacilityEventsByLoanReportIdQuery,
    GetDebtFacilityEventsByLoanReportIdQueryVariables
  >(GetDebtFacilityEventsByLoanReportIdDocument, options);
}
export type GetDebtFacilityEventsByLoanReportIdQueryHookResult = ReturnType<
  typeof useGetDebtFacilityEventsByLoanReportIdQuery
>;
export type GetDebtFacilityEventsByLoanReportIdLazyQueryHookResult = ReturnType<
  typeof useGetDebtFacilityEventsByLoanReportIdLazyQuery
>;
export type GetDebtFacilityEventsByLoanReportIdQueryResult = Apollo.QueryResult<
  GetDebtFacilityEventsByLoanReportIdQuery,
  GetDebtFacilityEventsByLoanReportIdQueryVariables
>;
export const GetEbbaApplicationDocument = gql`
  query GetEbbaApplication($id: uuid!) {
    ebba_applications_by_pk(id: $id) {
      id
      ...EbbaApplication
      approved_by_user {
        id
        full_name
      }
      rejected_by_user {
        id
        full_name
      }
      bank_note
      company {
        id
        name
      }
      ebba_application_files {
        ...EbbaApplicationFile
      }
    }
  }
  ${EbbaApplicationFragmentDoc}
  ${EbbaApplicationFileFragmentDoc}
`;

/**
 * __useGetEbbaApplicationQuery__
 *
 * To run a query within a React component, call `useGetEbbaApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEbbaApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEbbaApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEbbaApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEbbaApplicationQuery,
    GetEbbaApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEbbaApplicationQuery,
    GetEbbaApplicationQueryVariables
  >(GetEbbaApplicationDocument, options);
}
export function useGetEbbaApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEbbaApplicationQuery,
    GetEbbaApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEbbaApplicationQuery,
    GetEbbaApplicationQueryVariables
  >(GetEbbaApplicationDocument, options);
}
export type GetEbbaApplicationQueryHookResult = ReturnType<
  typeof useGetEbbaApplicationQuery
>;
export type GetEbbaApplicationLazyQueryHookResult = ReturnType<
  typeof useGetEbbaApplicationLazyQuery
>;
export type GetEbbaApplicationQueryResult = Apollo.QueryResult<
  GetEbbaApplicationQuery,
  GetEbbaApplicationQueryVariables
>;
export const GetEbbaApplicationsByCompanyIdDocument = gql`
  query GetEbbaApplicationsByCompanyId($company_id: uuid!, $category: String!) {
    ebba_applications(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { company_id: { _eq: $company_id } }
          { category: { _eq: $category } }
        ]
      }
      order_by: [{ application_date: desc }, { created_at: desc }]
    ) {
      id
      ...EbbaApplication
      company {
        id
        name
        contract {
          id
          product_type
        }
      }
    }
  }
  ${EbbaApplicationFragmentDoc}
`;

/**
 * __useGetEbbaApplicationsByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetEbbaApplicationsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEbbaApplicationsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEbbaApplicationsByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetEbbaApplicationsByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEbbaApplicationsByCompanyIdQuery,
    GetEbbaApplicationsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEbbaApplicationsByCompanyIdQuery,
    GetEbbaApplicationsByCompanyIdQueryVariables
  >(GetEbbaApplicationsByCompanyIdDocument, options);
}
export function useGetEbbaApplicationsByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEbbaApplicationsByCompanyIdQuery,
    GetEbbaApplicationsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEbbaApplicationsByCompanyIdQuery,
    GetEbbaApplicationsByCompanyIdQueryVariables
  >(GetEbbaApplicationsByCompanyIdDocument, options);
}
export type GetEbbaApplicationsByCompanyIdQueryHookResult = ReturnType<
  typeof useGetEbbaApplicationsByCompanyIdQuery
>;
export type GetEbbaApplicationsByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetEbbaApplicationsByCompanyIdLazyQuery
>;
export type GetEbbaApplicationsByCompanyIdQueryResult = Apollo.QueryResult<
  GetEbbaApplicationsByCompanyIdQuery,
  GetEbbaApplicationsByCompanyIdQueryVariables
>;
export const GetOpenEbbaApplicationsCountForBankDocument = gql`
  subscription GetOpenEbbaApplicationsCountForBank {
    ebba_applications(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { status: { _in: [approval_requested, rejected] } }
        ]
      }
    ) {
      id
      category
    }
  }
`;

/**
 * __useGetOpenEbbaApplicationsCountForBankSubscription__
 *
 * To run a query within a React component, call `useGetOpenEbbaApplicationsCountForBankSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenEbbaApplicationsCountForBankSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenEbbaApplicationsCountForBankSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetOpenEbbaApplicationsCountForBankSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetOpenEbbaApplicationsCountForBankSubscription,
    GetOpenEbbaApplicationsCountForBankSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetOpenEbbaApplicationsCountForBankSubscription,
    GetOpenEbbaApplicationsCountForBankSubscriptionVariables
  >(GetOpenEbbaApplicationsCountForBankDocument, options);
}
export type GetOpenEbbaApplicationsCountForBankSubscriptionHookResult =
  ReturnType<typeof useGetOpenEbbaApplicationsCountForBankSubscription>;
export type GetOpenEbbaApplicationsCountForBankSubscriptionResult =
  Apollo.SubscriptionResult<GetOpenEbbaApplicationsCountForBankSubscription>;
export const GetOpenEbbaApplicationsByCategoryDocument = gql`
  query GetOpenEbbaApplicationsByCategory($category: String!) {
    ebba_applications(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { category: { _eq: $category } }
          { status: { _in: [approval_requested, rejected] } }
        ]
      }
      order_by: [{ application_date: desc }, { created_at: desc }]
    ) {
      id
      ...EbbaApplication
      bank_note
      rejected_at
      rejected_by_user {
        id
        full_name
      }
      company {
        id
        name
        contract {
          id
          product_type
        }
      }
      ebba_application_files {
        ebba_application_id
        file_id
      }
    }
  }
  ${EbbaApplicationFragmentDoc}
`;

/**
 * __useGetOpenEbbaApplicationsByCategoryQuery__
 *
 * To run a query within a React component, call `useGetOpenEbbaApplicationsByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenEbbaApplicationsByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenEbbaApplicationsByCategoryQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetOpenEbbaApplicationsByCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOpenEbbaApplicationsByCategoryQuery,
    GetOpenEbbaApplicationsByCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOpenEbbaApplicationsByCategoryQuery,
    GetOpenEbbaApplicationsByCategoryQueryVariables
  >(GetOpenEbbaApplicationsByCategoryDocument, options);
}
export function useGetOpenEbbaApplicationsByCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOpenEbbaApplicationsByCategoryQuery,
    GetOpenEbbaApplicationsByCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOpenEbbaApplicationsByCategoryQuery,
    GetOpenEbbaApplicationsByCategoryQueryVariables
  >(GetOpenEbbaApplicationsByCategoryDocument, options);
}
export type GetOpenEbbaApplicationsByCategoryQueryHookResult = ReturnType<
  typeof useGetOpenEbbaApplicationsByCategoryQuery
>;
export type GetOpenEbbaApplicationsByCategoryLazyQueryHookResult = ReturnType<
  typeof useGetOpenEbbaApplicationsByCategoryLazyQuery
>;
export type GetOpenEbbaApplicationsByCategoryQueryResult = Apollo.QueryResult<
  GetOpenEbbaApplicationsByCategoryQuery,
  GetOpenEbbaApplicationsByCategoryQueryVariables
>;
export const GetClosedEbbaApplicationsDocument = gql`
  query GetClosedEbbaApplications {
    ebba_applications(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { approved_at: { _is_null: false } }
        ]
      }
      order_by: [{ application_date: desc }, { created_at: desc }]
    ) {
      id
      ...EbbaApplication
      approved_by_user {
        id
        full_name
      }
      bank_note
      company {
        id
        name
        contract {
          id
          product_type
        }
      }
      ebba_application_files {
        ebba_application_id
        file_id
      }
    }
  }
  ${EbbaApplicationFragmentDoc}
`;

/**
 * __useGetClosedEbbaApplicationsQuery__
 *
 * To run a query within a React component, call `useGetClosedEbbaApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClosedEbbaApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClosedEbbaApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClosedEbbaApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClosedEbbaApplicationsQuery,
    GetClosedEbbaApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClosedEbbaApplicationsQuery,
    GetClosedEbbaApplicationsQueryVariables
  >(GetClosedEbbaApplicationsDocument, options);
}
export function useGetClosedEbbaApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClosedEbbaApplicationsQuery,
    GetClosedEbbaApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClosedEbbaApplicationsQuery,
    GetClosedEbbaApplicationsQueryVariables
  >(GetClosedEbbaApplicationsDocument, options);
}
export type GetClosedEbbaApplicationsQueryHookResult = ReturnType<
  typeof useGetClosedEbbaApplicationsQuery
>;
export type GetClosedEbbaApplicationsLazyQueryHookResult = ReturnType<
  typeof useGetClosedEbbaApplicationsLazyQuery
>;
export type GetClosedEbbaApplicationsQueryResult = Apollo.QueryResult<
  GetClosedEbbaApplicationsQuery,
  GetClosedEbbaApplicationsQueryVariables
>;
export const GetClosedEbbaApplicationsByCompanyIdDocument = gql`
  query GetClosedEbbaApplicationsByCompanyId(
    $company_id: uuid!
    $financial_report_amount: Int!
  ) {
    borrowing_base: ebba_applications(
      where: {
        _and: [
          { category: { _eq: "borrowing_base" } }
          { company_id: { _eq: $company_id } }
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          {
            _and: [
              { status: { _neq: drafted } }
              { status: { _neq: rejected } }
            ]
          }
          { application_date: { _gte: "2022-01-01" } }
        ]
      }
      order_by: [{ application_date: desc }, { created_at: desc }]
      limit: 1
    ) {
      ...EbbaApplication
    }
    financial_reports: ebba_applications(
      where: {
        _and: [
          { category: { _eq: "financial_report" } }
          { company_id: { _eq: $company_id } }
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          {
            _and: [
              { status: { _neq: drafted } }
              { status: { _neq: rejected } }
            ]
          }
          { application_date: { _gte: "2022-01-01" } }
        ]
      }
      order_by: [{ application_date: desc }, { created_at: desc }]
      limit: $financial_report_amount
    ) {
      ...EbbaApplication
    }
  }
  ${EbbaApplicationFragmentDoc}
`;

/**
 * __useGetClosedEbbaApplicationsByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetClosedEbbaApplicationsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClosedEbbaApplicationsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClosedEbbaApplicationsByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      financial_report_amount: // value for 'financial_report_amount'
 *   },
 * });
 */
export function useGetClosedEbbaApplicationsByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClosedEbbaApplicationsByCompanyIdQuery,
    GetClosedEbbaApplicationsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClosedEbbaApplicationsByCompanyIdQuery,
    GetClosedEbbaApplicationsByCompanyIdQueryVariables
  >(GetClosedEbbaApplicationsByCompanyIdDocument, options);
}
export function useGetClosedEbbaApplicationsByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClosedEbbaApplicationsByCompanyIdQuery,
    GetClosedEbbaApplicationsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClosedEbbaApplicationsByCompanyIdQuery,
    GetClosedEbbaApplicationsByCompanyIdQueryVariables
  >(GetClosedEbbaApplicationsByCompanyIdDocument, options);
}
export type GetClosedEbbaApplicationsByCompanyIdQueryHookResult = ReturnType<
  typeof useGetClosedEbbaApplicationsByCompanyIdQuery
>;
export type GetClosedEbbaApplicationsByCompanyIdLazyQueryHookResult =
  ReturnType<typeof useGetClosedEbbaApplicationsByCompanyIdLazyQuery>;
export type GetClosedEbbaApplicationsByCompanyIdQueryResult =
  Apollo.QueryResult<
    GetClosedEbbaApplicationsByCompanyIdQuery,
    GetClosedEbbaApplicationsByCompanyIdQueryVariables
  >;
export const PayorsByPartnerCompanyDocument = gql`
  query PayorsByPartnerCompany($companyId: uuid!) {
    payors(
      where: { company_payor_partnerships: { company_id: { _eq: $companyId } } }
    ) {
      id
      ...PayorLimited
      company_payor_partnerships(where: { company_id: { _eq: $companyId } }) {
        id
        approved_at
      }
    }
  }
  ${PayorLimitedFragmentDoc}
`;

/**
 * __usePayorsByPartnerCompanyQuery__
 *
 * To run a query within a React component, call `usePayorsByPartnerCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayorsByPartnerCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayorsByPartnerCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePayorsByPartnerCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayorsByPartnerCompanyQuery,
    PayorsByPartnerCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayorsByPartnerCompanyQuery,
    PayorsByPartnerCompanyQueryVariables
  >(PayorsByPartnerCompanyDocument, options);
}
export function usePayorsByPartnerCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayorsByPartnerCompanyQuery,
    PayorsByPartnerCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayorsByPartnerCompanyQuery,
    PayorsByPartnerCompanyQueryVariables
  >(PayorsByPartnerCompanyDocument, options);
}
export type PayorsByPartnerCompanyQueryHookResult = ReturnType<
  typeof usePayorsByPartnerCompanyQuery
>;
export type PayorsByPartnerCompanyLazyQueryHookResult = ReturnType<
  typeof usePayorsByPartnerCompanyLazyQuery
>;
export type PayorsByPartnerCompanyQueryResult = Apollo.QueryResult<
  PayorsByPartnerCompanyQuery,
  PayorsByPartnerCompanyQueryVariables
>;
export const GetInvoiceByIdDocument = gql`
  query GetInvoiceById($id: uuid!) {
    invoices_by_pk(id: $id) {
      id
      ...Invoice
      company {
        id
        contract {
          id
          product_type
        }
      }
      loans(where: { loan_type: { _eq: invoice } }) {
        id
        ...LoanLimited
      }
      invoice_files {
        ...InvoiceFile
      }
    }
  }
  ${InvoiceFragmentDoc}
  ${LoanLimitedFragmentDoc}
  ${InvoiceFileFragmentDoc}
`;

/**
 * __useGetInvoiceByIdQuery__
 *
 * To run a query within a React component, call `useGetInvoiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvoiceByIdQuery,
    GetInvoiceByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>(
    GetInvoiceByIdDocument,
    options
  );
}
export function useGetInvoiceByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoiceByIdQuery,
    GetInvoiceByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>(
    GetInvoiceByIdDocument,
    options
  );
}
export type GetInvoiceByIdQueryHookResult = ReturnType<
  typeof useGetInvoiceByIdQuery
>;
export type GetInvoiceByIdLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceByIdLazyQuery
>;
export type GetInvoiceByIdQueryResult = Apollo.QueryResult<
  GetInvoiceByIdQuery,
  GetInvoiceByIdQueryVariables
>;
export const GetInvoiceForReviewDocument = gql`
  query GetInvoiceForReview($id: uuid!) {
    invoices_by_pk(id: $id) {
      id
      company_id
      payor_id
      invoice_number
      invoice_date
      invoice_due_date
      subtotal_amount
      total_amount
      taxes_amount
      is_cannabis
      status
      created_at
      payment_requested_at
      payment_confirmed_at
      payment_rejected_at
      payment_rejection_note
      invoice_files {
        invoice_id
        file_id
        ...InvoiceFile
      }
      company {
        id
        ...CompanyLimited
      }
      payor {
        id
        ...PayorLimited
        settings {
          id
          collections_bespoke_bank_account {
            id
            ...BankAccountLimited
          }
        }
      }
    }
  }
  ${InvoiceFileFragmentDoc}
  ${CompanyLimitedFragmentDoc}
  ${PayorLimitedFragmentDoc}
  ${BankAccountLimitedFragmentDoc}
`;

/**
 * __useGetInvoiceForReviewQuery__
 *
 * To run a query within a React component, call `useGetInvoiceForReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceForReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceForReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceForReviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvoiceForReviewQuery,
    GetInvoiceForReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInvoiceForReviewQuery,
    GetInvoiceForReviewQueryVariables
  >(GetInvoiceForReviewDocument, options);
}
export function useGetInvoiceForReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoiceForReviewQuery,
    GetInvoiceForReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInvoiceForReviewQuery,
    GetInvoiceForReviewQueryVariables
  >(GetInvoiceForReviewDocument, options);
}
export type GetInvoiceForReviewQueryHookResult = ReturnType<
  typeof useGetInvoiceForReviewQuery
>;
export type GetInvoiceForReviewLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceForReviewLazyQuery
>;
export type GetInvoiceForReviewQueryResult = Apollo.QueryResult<
  GetInvoiceForReviewQuery,
  GetInvoiceForReviewQueryVariables
>;
export const GetInvoicesDocument = gql`
  subscription GetInvoices {
    invoices(
      where: {
        _or: [
          { is_deleted: { _is_null: true } }
          { is_deleted: { _eq: false } }
        ]
      }
    ) {
      id
      ...Invoice
      company {
        id
        contract {
          id
          company_id
          product_type
        }
      }
    }
  }
  ${InvoiceFragmentDoc}
`;

/**
 * __useGetInvoicesSubscription__
 *
 * To run a query within a React component, call `useGetInvoicesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoicesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetInvoicesSubscription,
    GetInvoicesSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetInvoicesSubscription,
    GetInvoicesSubscriptionVariables
  >(GetInvoicesDocument, options);
}
export type GetInvoicesSubscriptionHookResult = ReturnType<
  typeof useGetInvoicesSubscription
>;
export type GetInvoicesSubscriptionResult =
  Apollo.SubscriptionResult<GetInvoicesSubscription>;
export const GetAllUnconfirmedInvoicesDocument = gql`
  subscription GetAllUnconfirmedInvoices {
    invoices(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { funded_at: { _is_null: true } }
          { status: { _neq: approved } }
        ]
      }
      order_by: [{ created_at: desc }]
    ) {
      id
      ...Invoice
      company {
        id
        contract {
          id
          company_id
          product_type
        }
      }
    }
  }
  ${InvoiceFragmentDoc}
`;

/**
 * __useGetAllUnconfirmedInvoicesSubscription__
 *
 * To run a query within a React component, call `useGetAllUnconfirmedInvoicesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUnconfirmedInvoicesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUnconfirmedInvoicesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUnconfirmedInvoicesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetAllUnconfirmedInvoicesSubscription,
    GetAllUnconfirmedInvoicesSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetAllUnconfirmedInvoicesSubscription,
    GetAllUnconfirmedInvoicesSubscriptionVariables
  >(GetAllUnconfirmedInvoicesDocument, options);
}
export type GetAllUnconfirmedInvoicesSubscriptionHookResult = ReturnType<
  typeof useGetAllUnconfirmedInvoicesSubscription
>;
export type GetAllUnconfirmedInvoicesSubscriptionResult =
  Apollo.SubscriptionResult<GetAllUnconfirmedInvoicesSubscription>;
export const GetAllConfirmedInvoicesDocument = gql`
  subscription GetAllConfirmedInvoices {
    invoices(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { status: { _eq: approved } }
        ]
      }
      order_by: [{ created_at: desc }]
    ) {
      id
      ...Invoice
      company {
        id
        contract {
          id
          company_id
          product_type
        }
      }
    }
  }
  ${InvoiceFragmentDoc}
`;

/**
 * __useGetAllConfirmedInvoicesSubscription__
 *
 * To run a query within a React component, call `useGetAllConfirmedInvoicesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetAllConfirmedInvoicesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllConfirmedInvoicesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetAllConfirmedInvoicesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetAllConfirmedInvoicesSubscription,
    GetAllConfirmedInvoicesSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetAllConfirmedInvoicesSubscription,
    GetAllConfirmedInvoicesSubscriptionVariables
  >(GetAllConfirmedInvoicesDocument, options);
}
export type GetAllConfirmedInvoicesSubscriptionHookResult = ReturnType<
  typeof useGetAllConfirmedInvoicesSubscription
>;
export type GetAllConfirmedInvoicesSubscriptionResult =
  Apollo.SubscriptionResult<GetAllConfirmedInvoicesSubscription>;
export const GetInvoicesByCompanyIdDocument = gql`
  query GetInvoicesByCompanyId($company_id: uuid!) {
    invoices(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { company_id: { _eq: $company_id } }
        ]
      }
    ) {
      ...Invoice
    }
  }
  ${InvoiceFragmentDoc}
`;

/**
 * __useGetInvoicesByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetInvoicesByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetInvoicesByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvoicesByCompanyIdQuery,
    GetInvoicesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInvoicesByCompanyIdQuery,
    GetInvoicesByCompanyIdQueryVariables
  >(GetInvoicesByCompanyIdDocument, options);
}
export function useGetInvoicesByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoicesByCompanyIdQuery,
    GetInvoicesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInvoicesByCompanyIdQuery,
    GetInvoicesByCompanyIdQueryVariables
  >(GetInvoicesByCompanyIdDocument, options);
}
export type GetInvoicesByCompanyIdQueryHookResult = ReturnType<
  typeof useGetInvoicesByCompanyIdQuery
>;
export type GetInvoicesByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetInvoicesByCompanyIdLazyQuery
>;
export type GetInvoicesByCompanyIdQueryResult = Apollo.QueryResult<
  GetInvoicesByCompanyIdQuery,
  GetInvoicesByCompanyIdQueryVariables
>;
export const GetOpenInvoicesByCompanyIdDocument = gql`
  query GetOpenInvoicesByCompanyId($company_id: uuid!) {
    invoices(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { company_id: { _eq: $company_id } }
          { funded_at: { _is_null: true } }
        ]
      }
    ) {
      ...InvoiceLimited
    }
  }
  ${InvoiceLimitedFragmentDoc}
`;

/**
 * __useGetOpenInvoicesByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetOpenInvoicesByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenInvoicesByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenInvoicesByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetOpenInvoicesByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOpenInvoicesByCompanyIdQuery,
    GetOpenInvoicesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOpenInvoicesByCompanyIdQuery,
    GetOpenInvoicesByCompanyIdQueryVariables
  >(GetOpenInvoicesByCompanyIdDocument, options);
}
export function useGetOpenInvoicesByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOpenInvoicesByCompanyIdQuery,
    GetOpenInvoicesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOpenInvoicesByCompanyIdQuery,
    GetOpenInvoicesByCompanyIdQueryVariables
  >(GetOpenInvoicesByCompanyIdDocument, options);
}
export type GetOpenInvoicesByCompanyIdQueryHookResult = ReturnType<
  typeof useGetOpenInvoicesByCompanyIdQuery
>;
export type GetOpenInvoicesByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetOpenInvoicesByCompanyIdLazyQuery
>;
export type GetOpenInvoicesByCompanyIdQueryResult = Apollo.QueryResult<
  GetOpenInvoicesByCompanyIdQuery,
  GetOpenInvoicesByCompanyIdQueryVariables
>;
export const GetClosedInvoicesByCompanyIdDocument = gql`
  query GetClosedInvoicesByCompanyId($company_id: uuid!) {
    invoices(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { company_id: { _eq: $company_id } }
          { funded_at: { _is_null: false } }
        ]
      }
    ) {
      ...InvoiceLimited
    }
  }
  ${InvoiceLimitedFragmentDoc}
`;

/**
 * __useGetClosedInvoicesByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetClosedInvoicesByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClosedInvoicesByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClosedInvoicesByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetClosedInvoicesByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClosedInvoicesByCompanyIdQuery,
    GetClosedInvoicesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClosedInvoicesByCompanyIdQuery,
    GetClosedInvoicesByCompanyIdQueryVariables
  >(GetClosedInvoicesByCompanyIdDocument, options);
}
export function useGetClosedInvoicesByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClosedInvoicesByCompanyIdQuery,
    GetClosedInvoicesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClosedInvoicesByCompanyIdQuery,
    GetClosedInvoicesByCompanyIdQueryVariables
  >(GetClosedInvoicesByCompanyIdDocument, options);
}
export type GetClosedInvoicesByCompanyIdQueryHookResult = ReturnType<
  typeof useGetClosedInvoicesByCompanyIdQuery
>;
export type GetClosedInvoicesByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetClosedInvoicesByCompanyIdLazyQuery
>;
export type GetClosedInvoicesByCompanyIdQueryResult = Apollo.QueryResult<
  GetClosedInvoicesByCompanyIdQuery,
  GetClosedInvoicesByCompanyIdQueryVariables
>;
export const GetApprovedInvoicesByCompanyIdDocument = gql`
  query GetApprovedInvoicesByCompanyId($companyId: uuid!) {
    invoices(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { company_id: { _eq: $companyId } }
          { approved_at: { _is_null: false } }
          { funded_at: { _is_null: true } }
        ]
      }
    ) {
      ...Invoice
    }
  }
  ${InvoiceFragmentDoc}
`;

/**
 * __useGetApprovedInvoicesByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetApprovedInvoicesByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovedInvoicesByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovedInvoicesByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetApprovedInvoicesByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApprovedInvoicesByCompanyIdQuery,
    GetApprovedInvoicesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApprovedInvoicesByCompanyIdQuery,
    GetApprovedInvoicesByCompanyIdQueryVariables
  >(GetApprovedInvoicesByCompanyIdDocument, options);
}
export function useGetApprovedInvoicesByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApprovedInvoicesByCompanyIdQuery,
    GetApprovedInvoicesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApprovedInvoicesByCompanyIdQuery,
    GetApprovedInvoicesByCompanyIdQueryVariables
  >(GetApprovedInvoicesByCompanyIdDocument, options);
}
export type GetApprovedInvoicesByCompanyIdQueryHookResult = ReturnType<
  typeof useGetApprovedInvoicesByCompanyIdQuery
>;
export type GetApprovedInvoicesByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetApprovedInvoicesByCompanyIdLazyQuery
>;
export type GetApprovedInvoicesByCompanyIdQueryResult = Apollo.QueryResult<
  GetApprovedInvoicesByCompanyIdQuery,
  GetApprovedInvoicesByCompanyIdQueryVariables
>;
export const AddLineOfCreditDocument = gql`
  mutation AddLineOfCredit($lineOfCredit: line_of_credits_insert_input!) {
    insert_line_of_credits_one(object: $lineOfCredit) {
      id
      ...LineOfCredit
    }
  }
  ${LineOfCreditFragmentDoc}
`;
export type AddLineOfCreditMutationFn = Apollo.MutationFunction<
  AddLineOfCreditMutation,
  AddLineOfCreditMutationVariables
>;

/**
 * __useAddLineOfCreditMutation__
 *
 * To run a mutation, you first call `useAddLineOfCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLineOfCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLineOfCreditMutation, { data, loading, error }] = useAddLineOfCreditMutation({
 *   variables: {
 *      lineOfCredit: // value for 'lineOfCredit'
 *   },
 * });
 */
export function useAddLineOfCreditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLineOfCreditMutation,
    AddLineOfCreditMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddLineOfCreditMutation,
    AddLineOfCreditMutationVariables
  >(AddLineOfCreditDocument, options);
}
export type AddLineOfCreditMutationHookResult = ReturnType<
  typeof useAddLineOfCreditMutation
>;
export type AddLineOfCreditMutationResult =
  Apollo.MutationResult<AddLineOfCreditMutation>;
export type AddLineOfCreditMutationOptions = Apollo.BaseMutationOptions<
  AddLineOfCreditMutation,
  AddLineOfCreditMutationVariables
>;
export const UpdateLineOfCreditAndLoanDocument = gql`
  mutation UpdateLineOfCreditAndLoan(
    $lineOfCreditId: uuid!
    $lineOfCredit: line_of_credits_set_input!
    $loanId: uuid!
    $loan: loans_set_input!
  ) {
    update_line_of_credits_by_pk(
      pk_columns: { id: $lineOfCreditId }
      _set: $lineOfCredit
    ) {
      id
      ...LineOfCredit
    }
    update_loans_by_pk(pk_columns: { id: $loanId }, _set: $loan) {
      id
      ...LoanLimited
    }
  }
  ${LineOfCreditFragmentDoc}
  ${LoanLimitedFragmentDoc}
`;
export type UpdateLineOfCreditAndLoanMutationFn = Apollo.MutationFunction<
  UpdateLineOfCreditAndLoanMutation,
  UpdateLineOfCreditAndLoanMutationVariables
>;

/**
 * __useUpdateLineOfCreditAndLoanMutation__
 *
 * To run a mutation, you first call `useUpdateLineOfCreditAndLoanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLineOfCreditAndLoanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLineOfCreditAndLoanMutation, { data, loading, error }] = useUpdateLineOfCreditAndLoanMutation({
 *   variables: {
 *      lineOfCreditId: // value for 'lineOfCreditId'
 *      lineOfCredit: // value for 'lineOfCredit'
 *      loanId: // value for 'loanId'
 *      loan: // value for 'loan'
 *   },
 * });
 */
export function useUpdateLineOfCreditAndLoanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLineOfCreditAndLoanMutation,
    UpdateLineOfCreditAndLoanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLineOfCreditAndLoanMutation,
    UpdateLineOfCreditAndLoanMutationVariables
  >(UpdateLineOfCreditAndLoanDocument, options);
}
export type UpdateLineOfCreditAndLoanMutationHookResult = ReturnType<
  typeof useUpdateLineOfCreditAndLoanMutation
>;
export type UpdateLineOfCreditAndLoanMutationResult =
  Apollo.MutationResult<UpdateLineOfCreditAndLoanMutation>;
export type UpdateLineOfCreditAndLoanMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateLineOfCreditAndLoanMutation,
    UpdateLineOfCreditAndLoanMutationVariables
  >;
export const GetPurchaseOrdersForIdsDocument = gql`
  query GetPurchaseOrdersForIds($purchaseOrderIds: [uuid!]) {
    purchase_orders(
      where: {
        _and: [
          { id: { _in: $purchaseOrderIds } }
          { status: { _eq: approved } }
        ]
      }
    ) {
      id
      ...PurchaseOrder
      loans {
        id
        ...Loan
      }
    }
  }
  ${PurchaseOrderFragmentDoc}
  ${LoanFragmentDoc}
`;

/**
 * __useGetPurchaseOrdersForIdsQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrdersForIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrdersForIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrdersForIdsQuery({
 *   variables: {
 *      purchaseOrderIds: // value for 'purchaseOrderIds'
 *   },
 * });
 */
export function useGetPurchaseOrdersForIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPurchaseOrdersForIdsQuery,
    GetPurchaseOrdersForIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPurchaseOrdersForIdsQuery,
    GetPurchaseOrdersForIdsQueryVariables
  >(GetPurchaseOrdersForIdsDocument, options);
}
export function useGetPurchaseOrdersForIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPurchaseOrdersForIdsQuery,
    GetPurchaseOrdersForIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPurchaseOrdersForIdsQuery,
    GetPurchaseOrdersForIdsQueryVariables
  >(GetPurchaseOrdersForIdsDocument, options);
}
export type GetPurchaseOrdersForIdsQueryHookResult = ReturnType<
  typeof useGetPurchaseOrdersForIdsQuery
>;
export type GetPurchaseOrdersForIdsLazyQueryHookResult = ReturnType<
  typeof useGetPurchaseOrdersForIdsLazyQuery
>;
export type GetPurchaseOrdersForIdsQueryResult = Apollo.QueryResult<
  GetPurchaseOrdersForIdsQuery,
  GetPurchaseOrdersForIdsQueryVariables
>;
export const GetPurchaseOrdersForIdsLimitedDocument = gql`
  query GetPurchaseOrdersForIdsLimited($purchaseOrderIds: [uuid!]) {
    purchase_orders(
      where: {
        _and: [
          { id: { _in: $purchaseOrderIds } }
          { status: { _eq: approved } }
        ]
      }
    ) {
      id
      ...PurchaseOrderLimitedNew
      loans(
        where: {
          _or: [
            { is_deleted: { _is_null: true } }
            { is_deleted: { _eq: false } }
          ]
        }
      ) {
        id
        ...LoanLimited
      }
    }
  }
  ${PurchaseOrderLimitedNewFragmentDoc}
  ${LoanLimitedFragmentDoc}
`;

/**
 * __useGetPurchaseOrdersForIdsLimitedQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrdersForIdsLimitedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrdersForIdsLimitedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrdersForIdsLimitedQuery({
 *   variables: {
 *      purchaseOrderIds: // value for 'purchaseOrderIds'
 *   },
 * });
 */
export function useGetPurchaseOrdersForIdsLimitedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPurchaseOrdersForIdsLimitedQuery,
    GetPurchaseOrdersForIdsLimitedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPurchaseOrdersForIdsLimitedQuery,
    GetPurchaseOrdersForIdsLimitedQueryVariables
  >(GetPurchaseOrdersForIdsLimitedDocument, options);
}
export function useGetPurchaseOrdersForIdsLimitedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPurchaseOrdersForIdsLimitedQuery,
    GetPurchaseOrdersForIdsLimitedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPurchaseOrdersForIdsLimitedQuery,
    GetPurchaseOrdersForIdsLimitedQueryVariables
  >(GetPurchaseOrdersForIdsLimitedDocument, options);
}
export type GetPurchaseOrdersForIdsLimitedQueryHookResult = ReturnType<
  typeof useGetPurchaseOrdersForIdsLimitedQuery
>;
export type GetPurchaseOrdersForIdsLimitedLazyQueryHookResult = ReturnType<
  typeof useGetPurchaseOrdersForIdsLimitedLazyQuery
>;
export type GetPurchaseOrdersForIdsLimitedQueryResult = Apollo.QueryResult<
  GetPurchaseOrdersForIdsLimitedQuery,
  GetPurchaseOrdersForIdsLimitedQueryVariables
>;
export const GetLoanDocument = gql`
  query GetLoan($id: uuid!) {
    loans_by_pk(id: $id) {
      ...Loan
    }
  }
  ${LoanFragmentDoc}
`;

/**
 * __useGetLoanQuery__
 *
 * To run a query within a React component, call `useGetLoanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLoanQuery(
  baseOptions: Apollo.QueryHookOptions<GetLoanQuery, GetLoanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLoanQuery, GetLoanQueryVariables>(
    GetLoanDocument,
    options
  );
}
export function useGetLoanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLoanQuery, GetLoanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLoanQuery, GetLoanQueryVariables>(
    GetLoanDocument,
    options
  );
}
export type GetLoanQueryHookResult = ReturnType<typeof useGetLoanQuery>;
export type GetLoanLazyQueryHookResult = ReturnType<typeof useGetLoanLazyQuery>;
export type GetLoanQueryResult = Apollo.QueryResult<
  GetLoanQuery,
  GetLoanQueryVariables
>;
export const GetLoanForCustomerDocument = gql`
  query GetLoanForCustomer($id: uuid!) {
    loans_by_pk(id: $id) {
      ...LoanLimited
    }
  }
  ${LoanLimitedFragmentDoc}
`;

/**
 * __useGetLoanForCustomerQuery__
 *
 * To run a query within a React component, call `useGetLoanForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanForCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLoanForCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLoanForCustomerQuery,
    GetLoanForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLoanForCustomerQuery,
    GetLoanForCustomerQueryVariables
  >(GetLoanForCustomerDocument, options);
}
export function useGetLoanForCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoanForCustomerQuery,
    GetLoanForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLoanForCustomerQuery,
    GetLoanForCustomerQueryVariables
  >(GetLoanForCustomerDocument, options);
}
export type GetLoanForCustomerQueryHookResult = ReturnType<
  typeof useGetLoanForCustomerQuery
>;
export type GetLoanForCustomerLazyQueryHookResult = ReturnType<
  typeof useGetLoanForCustomerLazyQuery
>;
export type GetLoanForCustomerQueryResult = Apollo.QueryResult<
  GetLoanForCustomerQuery,
  GetLoanForCustomerQueryVariables
>;
export const GetLoanWithArtifactForCustomerDocument = gql`
  query GetLoanWithArtifactForCustomer($id: uuid!) {
    loans_by_pk(id: $id) {
      ...LoanLimited
      ...LoanArtifactLimited
    }
  }
  ${LoanLimitedFragmentDoc}
  ${LoanArtifactLimitedFragmentDoc}
`;

/**
 * __useGetLoanWithArtifactForCustomerQuery__
 *
 * To run a query within a React component, call `useGetLoanWithArtifactForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanWithArtifactForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanWithArtifactForCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLoanWithArtifactForCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLoanWithArtifactForCustomerQuery,
    GetLoanWithArtifactForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLoanWithArtifactForCustomerQuery,
    GetLoanWithArtifactForCustomerQueryVariables
  >(GetLoanWithArtifactForCustomerDocument, options);
}
export function useGetLoanWithArtifactForCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoanWithArtifactForCustomerQuery,
    GetLoanWithArtifactForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLoanWithArtifactForCustomerQuery,
    GetLoanWithArtifactForCustomerQueryVariables
  >(GetLoanWithArtifactForCustomerDocument, options);
}
export type GetLoanWithArtifactForCustomerQueryHookResult = ReturnType<
  typeof useGetLoanWithArtifactForCustomerQuery
>;
export type GetLoanWithArtifactForCustomerLazyQueryHookResult = ReturnType<
  typeof useGetLoanWithArtifactForCustomerLazyQuery
>;
export type GetLoanWithArtifactForCustomerQueryResult = Apollo.QueryResult<
  GetLoanWithArtifactForCustomerQuery,
  GetLoanWithArtifactForCustomerQueryVariables
>;
export const GetLoanWithArtifactForBankDocument = gql`
  query GetLoanWithArtifactForBank($id: uuid!) {
    loans_by_pk(id: $id) {
      ...Loan
      ...LoanArtifact
      loan_report {
        ...LoanReport
        debt_facility {
          ...DebtFacility
        }
      }
    }
  }
  ${LoanFragmentDoc}
  ${LoanArtifactFragmentDoc}
  ${LoanReportFragmentDoc}
  ${DebtFacilityFragmentDoc}
`;

/**
 * __useGetLoanWithArtifactForBankQuery__
 *
 * To run a query within a React component, call `useGetLoanWithArtifactForBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanWithArtifactForBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanWithArtifactForBankQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLoanWithArtifactForBankQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLoanWithArtifactForBankQuery,
    GetLoanWithArtifactForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLoanWithArtifactForBankQuery,
    GetLoanWithArtifactForBankQueryVariables
  >(GetLoanWithArtifactForBankDocument, options);
}
export function useGetLoanWithArtifactForBankLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoanWithArtifactForBankQuery,
    GetLoanWithArtifactForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLoanWithArtifactForBankQuery,
    GetLoanWithArtifactForBankQueryVariables
  >(GetLoanWithArtifactForBankDocument, options);
}
export type GetLoanWithArtifactForBankQueryHookResult = ReturnType<
  typeof useGetLoanWithArtifactForBankQuery
>;
export type GetLoanWithArtifactForBankLazyQueryHookResult = ReturnType<
  typeof useGetLoanWithArtifactForBankLazyQuery
>;
export type GetLoanWithArtifactForBankQueryResult = Apollo.QueryResult<
  GetLoanWithArtifactForBankQuery,
  GetLoanWithArtifactForBankQueryVariables
>;
export const GetTransactionsForLoanDocument = gql`
  query GetTransactionsForLoan($loan_id: uuid!) {
    transactions(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { loan_id: { _eq: $loan_id } }
        ]
      }
    ) {
      id
      ...TransactionExtended
    }
  }
  ${TransactionExtendedFragmentDoc}
`;

/**
 * __useGetTransactionsForLoanQuery__
 *
 * To run a query within a React component, call `useGetTransactionsForLoanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsForLoanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsForLoanQuery({
 *   variables: {
 *      loan_id: // value for 'loan_id'
 *   },
 * });
 */
export function useGetTransactionsForLoanQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTransactionsForLoanQuery,
    GetTransactionsForLoanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTransactionsForLoanQuery,
    GetTransactionsForLoanQueryVariables
  >(GetTransactionsForLoanDocument, options);
}
export function useGetTransactionsForLoanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransactionsForLoanQuery,
    GetTransactionsForLoanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTransactionsForLoanQuery,
    GetTransactionsForLoanQueryVariables
  >(GetTransactionsForLoanDocument, options);
}
export type GetTransactionsForLoanQueryHookResult = ReturnType<
  typeof useGetTransactionsForLoanQuery
>;
export type GetTransactionsForLoanLazyQueryHookResult = ReturnType<
  typeof useGetTransactionsForLoanLazyQuery
>;
export type GetTransactionsForLoanQueryResult = Apollo.QueryResult<
  GetTransactionsForLoanQuery,
  GetTransactionsForLoanQueryVariables
>;
export const AddLoanDocument = gql`
  mutation AddLoan($loan: loans_insert_input!) {
    insert_loans_one(object: $loan) {
      ...LoanLimited
    }
  }
  ${LoanLimitedFragmentDoc}
`;
export type AddLoanMutationFn = Apollo.MutationFunction<
  AddLoanMutation,
  AddLoanMutationVariables
>;

/**
 * __useAddLoanMutation__
 *
 * To run a mutation, you first call `useAddLoanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLoanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLoanMutation, { data, loading, error }] = useAddLoanMutation({
 *   variables: {
 *      loan: // value for 'loan'
 *   },
 * });
 */
export function useAddLoanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLoanMutation,
    AddLoanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddLoanMutation, AddLoanMutationVariables>(
    AddLoanDocument,
    options
  );
}
export type AddLoanMutationHookResult = ReturnType<typeof useAddLoanMutation>;
export type AddLoanMutationResult = Apollo.MutationResult<AddLoanMutation>;
export type AddLoanMutationOptions = Apollo.BaseMutationOptions<
  AddLoanMutation,
  AddLoanMutationVariables
>;
export const UpdateLoanDocument = gql`
  mutation UpdateLoan($id: uuid!, $loan: loans_set_input!) {
    update_loans_by_pk(pk_columns: { id: $id }, _set: $loan) {
      ...LoanLimited
    }
  }
  ${LoanLimitedFragmentDoc}
`;
export type UpdateLoanMutationFn = Apollo.MutationFunction<
  UpdateLoanMutation,
  UpdateLoanMutationVariables
>;

/**
 * __useUpdateLoanMutation__
 *
 * To run a mutation, you first call `useUpdateLoanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoanMutation, { data, loading, error }] = useUpdateLoanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      loan: // value for 'loan'
 *   },
 * });
 */
export function useUpdateLoanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLoanMutation,
    UpdateLoanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLoanMutation, UpdateLoanMutationVariables>(
    UpdateLoanDocument,
    options
  );
}
export type UpdateLoanMutationHookResult = ReturnType<
  typeof useUpdateLoanMutation
>;
export type UpdateLoanMutationResult =
  Apollo.MutationResult<UpdateLoanMutation>;
export type UpdateLoanMutationOptions = Apollo.BaseMutationOptions<
  UpdateLoanMutation,
  UpdateLoanMutationVariables
>;
export const GetLoanWithRelationshipsDocument = gql`
  query GetLoanWithRelationships($id: uuid!, $is_bank_user: Boolean!) {
    loans_by_pk(id: $id) {
      id
      ...LoanWithRelationships
    }
  }
  ${LoanWithRelationshipsFragmentDoc}
`;

/**
 * __useGetLoanWithRelationshipsQuery__
 *
 * To run a query within a React component, call `useGetLoanWithRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanWithRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanWithRelationshipsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      is_bank_user: // value for 'is_bank_user'
 *   },
 * });
 */
export function useGetLoanWithRelationshipsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLoanWithRelationshipsQuery,
    GetLoanWithRelationshipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLoanWithRelationshipsQuery,
    GetLoanWithRelationshipsQueryVariables
  >(GetLoanWithRelationshipsDocument, options);
}
export function useGetLoanWithRelationshipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoanWithRelationshipsQuery,
    GetLoanWithRelationshipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLoanWithRelationshipsQuery,
    GetLoanWithRelationshipsQueryVariables
  >(GetLoanWithRelationshipsDocument, options);
}
export type GetLoanWithRelationshipsQueryHookResult = ReturnType<
  typeof useGetLoanWithRelationshipsQuery
>;
export type GetLoanWithRelationshipsLazyQueryHookResult = ReturnType<
  typeof useGetLoanWithRelationshipsLazyQuery
>;
export type GetLoanWithRelationshipsQueryResult = Apollo.QueryResult<
  GetLoanWithRelationshipsQuery,
  GetLoanWithRelationshipsQueryVariables
>;
export const GetLoansForBankDocument = gql`
  subscription GetLoansForBank {
    loans(
      where: {
        _or: [
          { is_deleted: { _is_null: true } }
          { is_deleted: { _eq: false } }
        ]
      }
      order_by: [
        { adjusted_maturity_date: asc }
        { origination_date: asc }
        { created_at: asc }
        { amount: asc }
      ]
    ) {
      id
      ...Loan
      ...LoanArtifact
      loan_report {
        ...LoanReport
      }
      company {
        debt_facility_status
      }
    }
  }
  ${LoanFragmentDoc}
  ${LoanArtifactFragmentDoc}
  ${LoanReportFragmentDoc}
`;

/**
 * __useGetLoansForBankSubscription__
 *
 * To run a query within a React component, call `useGetLoansForBankSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetLoansForBankSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoansForBankSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetLoansForBankSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetLoansForBankSubscription,
    GetLoansForBankSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetLoansForBankSubscription,
    GetLoansForBankSubscriptionVariables
  >(GetLoansForBankDocument, options);
}
export type GetLoansForBankSubscriptionHookResult = ReturnType<
  typeof useGetLoansForBankSubscription
>;
export type GetLoansForBankSubscriptionResult =
  Apollo.SubscriptionResult<GetLoansForBankSubscription>;
export const GetFundedLoansForBankIncludingClosedDocument = gql`
  subscription GetFundedLoansForBankIncludingClosed {
    loans(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { funded_at: { _is_null: false } }
        ]
      }
      order_by: [
        { adjusted_maturity_date: asc }
        { origination_date: asc }
        { created_at: asc }
        { amount: asc }
      ]
    ) {
      id
      ...Loan
      ...LoanArtifact
      loan_report {
        ...LoanReport
      }
    }
  }
  ${LoanFragmentDoc}
  ${LoanArtifactFragmentDoc}
  ${LoanReportFragmentDoc}
`;

/**
 * __useGetFundedLoansForBankIncludingClosedSubscription__
 *
 * To run a query within a React component, call `useGetFundedLoansForBankIncludingClosedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetFundedLoansForBankIncludingClosedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFundedLoansForBankIncludingClosedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetFundedLoansForBankIncludingClosedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetFundedLoansForBankIncludingClosedSubscription,
    GetFundedLoansForBankIncludingClosedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetFundedLoansForBankIncludingClosedSubscription,
    GetFundedLoansForBankIncludingClosedSubscriptionVariables
  >(GetFundedLoansForBankIncludingClosedDocument, options);
}
export type GetFundedLoansForBankIncludingClosedSubscriptionHookResult =
  ReturnType<typeof useGetFundedLoansForBankIncludingClosedSubscription>;
export type GetFundedLoansForBankIncludingClosedSubscriptionResult =
  Apollo.SubscriptionResult<GetFundedLoansForBankIncludingClosedSubscription>;
export const GetNotFundedLoansForBankDocument = gql`
  subscription GetNotFundedLoansForBank {
    loans(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { funded_at: { _is_null: true } }
          { closed_at: { _is_null: true } }
        ]
      }
      order_by: [{ requested_payment_date: asc }, { company_id: asc }]
    ) {
      id
      ...Loan
      ...LoanArtifact
      company {
        most_recent_surveillance_result: customer_surveillance_results(
          limit: 1
          order_by: { qualifying_date: desc }
          where: {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
        ) {
          id
          ...CustomerSurveillanceResult
        }
        most_recent_financial_summary: financial_summaries(
          order_by: { date: desc }
          limit: 1
        ) {
          id
          product_type
        }
      }
    }
  }
  ${LoanFragmentDoc}
  ${LoanArtifactFragmentDoc}
  ${CustomerSurveillanceResultFragmentDoc}
`;

/**
 * __useGetNotFundedLoansForBankSubscription__
 *
 * To run a query within a React component, call `useGetNotFundedLoansForBankSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetNotFundedLoansForBankSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotFundedLoansForBankSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetNotFundedLoansForBankSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetNotFundedLoansForBankSubscription,
    GetNotFundedLoansForBankSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetNotFundedLoansForBankSubscription,
    GetNotFundedLoansForBankSubscriptionVariables
  >(GetNotFundedLoansForBankDocument, options);
}
export type GetNotFundedLoansForBankSubscriptionHookResult = ReturnType<
  typeof useGetNotFundedLoansForBankSubscription
>;
export type GetNotFundedLoansForBankSubscriptionResult =
  Apollo.SubscriptionResult<GetNotFundedLoansForBankSubscription>;
export const GetFundedLoansForBankDocument = gql`
  subscription GetFundedLoansForBank {
    loans(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { funded_at: { _is_null: false } }
          { closed_at: { _is_null: true } }
        ]
      }
      order_by: [
        { adjusted_maturity_date: asc }
        { origination_date: asc }
        { created_at: asc }
        { amount: asc }
      ]
    ) {
      id
      ...Loan
      ...LoanArtifact
      loan_report {
        ...LoanReport
      }
    }
  }
  ${LoanFragmentDoc}
  ${LoanArtifactFragmentDoc}
  ${LoanReportFragmentDoc}
`;

/**
 * __useGetFundedLoansForBankSubscription__
 *
 * To run a query within a React component, call `useGetFundedLoansForBankSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetFundedLoansForBankSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFundedLoansForBankSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetFundedLoansForBankSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetFundedLoansForBankSubscription,
    GetFundedLoansForBankSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetFundedLoansForBankSubscription,
    GetFundedLoansForBankSubscriptionVariables
  >(GetFundedLoansForBankDocument, options);
}
export type GetFundedLoansForBankSubscriptionHookResult = ReturnType<
  typeof useGetFundedLoansForBankSubscription
>;
export type GetFundedLoansForBankSubscriptionResult =
  Apollo.SubscriptionResult<GetFundedLoansForBankSubscription>;
export const GetClosedLoansForBankDocument = gql`
  subscription GetClosedLoansForBank {
    loans(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { funded_at: { _is_null: false } }
          { closed_at: { _is_null: false } }
        ]
      }
      order_by: [
        { adjusted_maturity_date: asc }
        { origination_date: asc }
        { created_at: asc }
        { amount: asc }
      ]
    ) {
      id
      ...Loan
      ...LoanArtifact
      loan_report {
        ...LoanReport
      }
    }
  }
  ${LoanFragmentDoc}
  ${LoanArtifactFragmentDoc}
  ${LoanReportFragmentDoc}
`;

/**
 * __useGetClosedLoansForBankSubscription__
 *
 * To run a query within a React component, call `useGetClosedLoansForBankSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetClosedLoansForBankSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClosedLoansForBankSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetClosedLoansForBankSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetClosedLoansForBankSubscription,
    GetClosedLoansForBankSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetClosedLoansForBankSubscription,
    GetClosedLoansForBankSubscriptionVariables
  >(GetClosedLoansForBankDocument, options);
}
export type GetClosedLoansForBankSubscriptionHookResult = ReturnType<
  typeof useGetClosedLoansForBankSubscription
>;
export type GetClosedLoansForBankSubscriptionResult =
  Apollo.SubscriptionResult<GetClosedLoansForBankSubscription>;
export const GetActiveLoansForCompanyDocument = gql`
  query GetActiveLoansForCompany($companyId: uuid!, $loanType: loan_type_enum) {
    companies_by_pk(id: $companyId) {
      id
      loans(
        where: {
          _and: [
            {
              _or: [
                { is_deleted: { _is_null: true } }
                { is_deleted: { _eq: false } }
              ]
            }
            { closed_at: { _is_null: true } }
          ]
        }
        order_by: [
          { adjusted_maturity_date: asc }
          { origination_date: asc }
          { created_at: asc }
          { amount: asc }
        ]
      ) {
        id
        ...LoanLimited
        ...LoanArtifactLimited
      }
    }
  }
  ${LoanLimitedFragmentDoc}
  ${LoanArtifactLimitedFragmentDoc}
`;

/**
 * __useGetActiveLoansForCompanyQuery__
 *
 * To run a query within a React component, call `useGetActiveLoansForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveLoansForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveLoansForCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      loanType: // value for 'loanType'
 *   },
 * });
 */
export function useGetActiveLoansForCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActiveLoansForCompanyQuery,
    GetActiveLoansForCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveLoansForCompanyQuery,
    GetActiveLoansForCompanyQueryVariables
  >(GetActiveLoansForCompanyDocument, options);
}
export function useGetActiveLoansForCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveLoansForCompanyQuery,
    GetActiveLoansForCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveLoansForCompanyQuery,
    GetActiveLoansForCompanyQueryVariables
  >(GetActiveLoansForCompanyDocument, options);
}
export type GetActiveLoansForCompanyQueryHookResult = ReturnType<
  typeof useGetActiveLoansForCompanyQuery
>;
export type GetActiveLoansForCompanyLazyQueryHookResult = ReturnType<
  typeof useGetActiveLoansForCompanyLazyQuery
>;
export type GetActiveLoansForCompanyQueryResult = Apollo.QueryResult<
  GetActiveLoansForCompanyQuery,
  GetActiveLoansForCompanyQueryVariables
>;
export const GetFinancingRequestsForCompanyDocument = gql`
  query GetFinancingRequestsForCompany(
    $companyId: uuid!
    $loanType: loan_type_enum
  ) {
    companies_by_pk(id: $companyId) {
      id
      name
      loans(
        where: {
          _and: [
            {
              _or: [
                { is_deleted: { _is_null: true } }
                { is_deleted: { _eq: false } }
              ]
            }
            { closed_at: { _is_null: true } }
            { funded_at: { _is_null: true } }
          ]
        }
        order_by: [
          { adjusted_maturity_date: asc }
          { origination_date: asc }
          { created_at: asc }
          { amount: asc }
        ]
      ) {
        id
        ...LoanLimited
        ...LoanArtifactLimited
      }
    }
  }
  ${LoanLimitedFragmentDoc}
  ${LoanArtifactLimitedFragmentDoc}
`;

/**
 * __useGetFinancingRequestsForCompanyQuery__
 *
 * To run a query within a React component, call `useGetFinancingRequestsForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancingRequestsForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancingRequestsForCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      loanType: // value for 'loanType'
 *   },
 * });
 */
export function useGetFinancingRequestsForCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFinancingRequestsForCompanyQuery,
    GetFinancingRequestsForCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFinancingRequestsForCompanyQuery,
    GetFinancingRequestsForCompanyQueryVariables
  >(GetFinancingRequestsForCompanyDocument, options);
}
export function useGetFinancingRequestsForCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFinancingRequestsForCompanyQuery,
    GetFinancingRequestsForCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFinancingRequestsForCompanyQuery,
    GetFinancingRequestsForCompanyQueryVariables
  >(GetFinancingRequestsForCompanyDocument, options);
}
export type GetFinancingRequestsForCompanyQueryHookResult = ReturnType<
  typeof useGetFinancingRequestsForCompanyQuery
>;
export type GetFinancingRequestsForCompanyLazyQueryHookResult = ReturnType<
  typeof useGetFinancingRequestsForCompanyLazyQuery
>;
export type GetFinancingRequestsForCompanyQueryResult = Apollo.QueryResult<
  GetFinancingRequestsForCompanyQuery,
  GetFinancingRequestsForCompanyQueryVariables
>;
export const GetClosedLoansForCompanyDocument = gql`
  query GetClosedLoansForCompany($companyId: uuid!, $loanType: loan_type_enum) {
    companies_by_pk(id: $companyId) {
      id
      loans(
        where: {
          _and: [
            {
              _or: [
                { is_deleted: { _is_null: true } }
                { is_deleted: { _eq: false } }
              ]
            }
            { closed_at: { _is_null: false } }
          ]
        }
        order_by: [
          { adjusted_maturity_date: asc }
          { origination_date: asc }
          { created_at: asc }
          { amount: asc }
        ]
      ) {
        id
        ...LoanLimited
        ...LoanArtifactLimited
      }
    }
  }
  ${LoanLimitedFragmentDoc}
  ${LoanArtifactLimitedFragmentDoc}
`;

/**
 * __useGetClosedLoansForCompanyQuery__
 *
 * To run a query within a React component, call `useGetClosedLoansForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClosedLoansForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClosedLoansForCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      loanType: // value for 'loanType'
 *   },
 * });
 */
export function useGetClosedLoansForCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClosedLoansForCompanyQuery,
    GetClosedLoansForCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClosedLoansForCompanyQuery,
    GetClosedLoansForCompanyQueryVariables
  >(GetClosedLoansForCompanyDocument, options);
}
export function useGetClosedLoansForCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClosedLoansForCompanyQuery,
    GetClosedLoansForCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClosedLoansForCompanyQuery,
    GetClosedLoansForCompanyQueryVariables
  >(GetClosedLoansForCompanyDocument, options);
}
export type GetClosedLoansForCompanyQueryHookResult = ReturnType<
  typeof useGetClosedLoansForCompanyQuery
>;
export type GetClosedLoansForCompanyLazyQueryHookResult = ReturnType<
  typeof useGetClosedLoansForCompanyLazyQuery
>;
export type GetClosedLoansForCompanyQueryResult = Apollo.QueryResult<
  GetClosedLoansForCompanyQuery,
  GetClosedLoansForCompanyQueryVariables
>;
export const GetAllLoansForCompanyDocument = gql`
  query GetAllLoansForCompany($companyId: uuid!) {
    loans(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { company_id: { _eq: $companyId } }
        ]
      }
      order_by: [
        { adjusted_maturity_date: asc }
        { origination_date: asc }
        { created_at: asc }
        { amount: asc }
      ]
    ) {
      id
      ...LoanLimited
      ...LoanArtifactLimited
    }
  }
  ${LoanLimitedFragmentDoc}
  ${LoanArtifactLimitedFragmentDoc}
`;

/**
 * __useGetAllLoansForCompanyQuery__
 *
 * To run a query within a React component, call `useGetAllLoansForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLoansForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLoansForCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetAllLoansForCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllLoansForCompanyQuery,
    GetAllLoansForCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllLoansForCompanyQuery,
    GetAllLoansForCompanyQueryVariables
  >(GetAllLoansForCompanyDocument, options);
}
export function useGetAllLoansForCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllLoansForCompanyQuery,
    GetAllLoansForCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllLoansForCompanyQuery,
    GetAllLoansForCompanyQueryVariables
  >(GetAllLoansForCompanyDocument, options);
}
export type GetAllLoansForCompanyQueryHookResult = ReturnType<
  typeof useGetAllLoansForCompanyQuery
>;
export type GetAllLoansForCompanyLazyQueryHookResult = ReturnType<
  typeof useGetAllLoansForCompanyLazyQuery
>;
export type GetAllLoansForCompanyQueryResult = Apollo.QueryResult<
  GetAllLoansForCompanyQuery,
  GetAllLoansForCompanyQueryVariables
>;
export const GetLoansByArtifactIdDocument = gql`
  query GetLoansByArtifactId($artifact_id: uuid!) {
    loans(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { closed_at: { _is_null: true } }
          { artifact_id: { _eq: $artifact_id } }
        ]
      }
      order_by: [{ requested_payment_date: asc }, { company_id: asc }]
    ) {
      id
      ...LoanLimited
      ...LoanArtifactLimited
    }
  }
  ${LoanLimitedFragmentDoc}
  ${LoanArtifactLimitedFragmentDoc}
`;

/**
 * __useGetLoansByArtifactIdQuery__
 *
 * To run a query within a React component, call `useGetLoansByArtifactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoansByArtifactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoansByArtifactIdQuery({
 *   variables: {
 *      artifact_id: // value for 'artifact_id'
 *   },
 * });
 */
export function useGetLoansByArtifactIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLoansByArtifactIdQuery,
    GetLoansByArtifactIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLoansByArtifactIdQuery,
    GetLoansByArtifactIdQueryVariables
  >(GetLoansByArtifactIdDocument, options);
}
export function useGetLoansByArtifactIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoansByArtifactIdQuery,
    GetLoansByArtifactIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLoansByArtifactIdQuery,
    GetLoansByArtifactIdQueryVariables
  >(GetLoansByArtifactIdDocument, options);
}
export type GetLoansByArtifactIdQueryHookResult = ReturnType<
  typeof useGetLoansByArtifactIdQuery
>;
export type GetLoansByArtifactIdLazyQueryHookResult = ReturnType<
  typeof useGetLoansByArtifactIdLazyQuery
>;
export type GetLoansByArtifactIdQueryResult = Apollo.QueryResult<
  GetLoansByArtifactIdQuery,
  GetLoansByArtifactIdQueryVariables
>;
export const GetOpenFundedLoansByCompanyAndLoanTypeDocument = gql`
  query GetOpenFundedLoansByCompanyAndLoanType(
    $companyId: uuid!
    $loanType: loan_type_enum!
  ) {
    loans(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { company_id: { _eq: $companyId } }
          { loan_type: { _eq: $loanType } }
          { origination_date: { _is_null: false } }
          { closed_at: { _is_null: true } }
        ]
      }
      order_by: [
        { adjusted_maturity_date: asc }
        { origination_date: asc }
        { created_at: asc }
        { amount: asc }
      ]
    ) {
      id
      ...LoanLimited
      ...LoanArtifactLimited
    }
  }
  ${LoanLimitedFragmentDoc}
  ${LoanArtifactLimitedFragmentDoc}
`;

/**
 * __useGetOpenFundedLoansByCompanyAndLoanTypeQuery__
 *
 * To run a query within a React component, call `useGetOpenFundedLoansByCompanyAndLoanTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenFundedLoansByCompanyAndLoanTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenFundedLoansByCompanyAndLoanTypeQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      loanType: // value for 'loanType'
 *   },
 * });
 */
export function useGetOpenFundedLoansByCompanyAndLoanTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOpenFundedLoansByCompanyAndLoanTypeQuery,
    GetOpenFundedLoansByCompanyAndLoanTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOpenFundedLoansByCompanyAndLoanTypeQuery,
    GetOpenFundedLoansByCompanyAndLoanTypeQueryVariables
  >(GetOpenFundedLoansByCompanyAndLoanTypeDocument, options);
}
export function useGetOpenFundedLoansByCompanyAndLoanTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOpenFundedLoansByCompanyAndLoanTypeQuery,
    GetOpenFundedLoansByCompanyAndLoanTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOpenFundedLoansByCompanyAndLoanTypeQuery,
    GetOpenFundedLoansByCompanyAndLoanTypeQueryVariables
  >(GetOpenFundedLoansByCompanyAndLoanTypeDocument, options);
}
export type GetOpenFundedLoansByCompanyAndLoanTypeQueryHookResult = ReturnType<
  typeof useGetOpenFundedLoansByCompanyAndLoanTypeQuery
>;
export type GetOpenFundedLoansByCompanyAndLoanTypeLazyQueryHookResult =
  ReturnType<typeof useGetOpenFundedLoansByCompanyAndLoanTypeLazyQuery>;
export type GetOpenFundedLoansByCompanyAndLoanTypeQueryResult =
  Apollo.QueryResult<
    GetOpenFundedLoansByCompanyAndLoanTypeQuery,
    GetOpenFundedLoansByCompanyAndLoanTypeQueryVariables
  >;
export const GetLoansByLoanIdsDocument = gql`
  query GetLoansByLoanIds($loan_ids: [uuid!]!) {
    loans(where: { id: { _in: $loan_ids } }) {
      id
      ...Loan
      ...LoanArtifact
      company {
        id
        ...Company
        contract {
          id
          ...Contract
        }
      }
    }
  }
  ${LoanFragmentDoc}
  ${LoanArtifactFragmentDoc}
  ${CompanyFragmentDoc}
  ${ContractFragmentDoc}
`;

/**
 * __useGetLoansByLoanIdsQuery__
 *
 * To run a query within a React component, call `useGetLoansByLoanIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoansByLoanIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoansByLoanIdsQuery({
 *   variables: {
 *      loan_ids: // value for 'loan_ids'
 *   },
 * });
 */
export function useGetLoansByLoanIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLoansByLoanIdsQuery,
    GetLoansByLoanIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLoansByLoanIdsQuery,
    GetLoansByLoanIdsQueryVariables
  >(GetLoansByLoanIdsDocument, options);
}
export function useGetLoansByLoanIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoansByLoanIdsQuery,
    GetLoansByLoanIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLoansByLoanIdsQuery,
    GetLoansByLoanIdsQueryVariables
  >(GetLoansByLoanIdsDocument, options);
}
export type GetLoansByLoanIdsQueryHookResult = ReturnType<
  typeof useGetLoansByLoanIdsQuery
>;
export type GetLoansByLoanIdsLazyQueryHookResult = ReturnType<
  typeof useGetLoansByLoanIdsLazyQuery
>;
export type GetLoansByLoanIdsQueryResult = Apollo.QueryResult<
  GetLoansByLoanIdsQuery,
  GetLoansByLoanIdsQueryVariables
>;
export const GetLimitedLoansByLoanIdsDocument = gql`
  query GetLimitedLoansByLoanIds($loan_ids: [uuid!]!) {
    loans(where: { id: { _in: $loan_ids } }) {
      id
      ...LoanLimited
      ...LoanArtifactLimited
      company {
        id
        ...Company
        contract {
          id
          ...Contract
        }
      }
    }
  }
  ${LoanLimitedFragmentDoc}
  ${LoanArtifactLimitedFragmentDoc}
  ${CompanyFragmentDoc}
  ${ContractFragmentDoc}
`;

/**
 * __useGetLimitedLoansByLoanIdsQuery__
 *
 * To run a query within a React component, call `useGetLimitedLoansByLoanIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLimitedLoansByLoanIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLimitedLoansByLoanIdsQuery({
 *   variables: {
 *      loan_ids: // value for 'loan_ids'
 *   },
 * });
 */
export function useGetLimitedLoansByLoanIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLimitedLoansByLoanIdsQuery,
    GetLimitedLoansByLoanIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLimitedLoansByLoanIdsQuery,
    GetLimitedLoansByLoanIdsQueryVariables
  >(GetLimitedLoansByLoanIdsDocument, options);
}
export function useGetLimitedLoansByLoanIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLimitedLoansByLoanIdsQuery,
    GetLimitedLoansByLoanIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLimitedLoansByLoanIdsQuery,
    GetLimitedLoansByLoanIdsQueryVariables
  >(GetLimitedLoansByLoanIdsDocument, options);
}
export type GetLimitedLoansByLoanIdsQueryHookResult = ReturnType<
  typeof useGetLimitedLoansByLoanIdsQuery
>;
export type GetLimitedLoansByLoanIdsLazyQueryHookResult = ReturnType<
  typeof useGetLimitedLoansByLoanIdsLazyQuery
>;
export type GetLimitedLoansByLoanIdsQueryResult = Apollo.QueryResult<
  GetLimitedLoansByLoanIdsQuery,
  GetLimitedLoansByLoanIdsQueryVariables
>;
export const GetClosedLimitedLoansByLoanIdsDocument = gql`
  query GetClosedLimitedLoansByLoanIds($loan_ids: [uuid!]!) {
    loans(
      where: {
        _and: [{ id: { _in: $loan_ids } }, { closed_at: { _is_null: false } }]
      }
    ) {
      id
      ...LoanLimited
      ...LoanArtifactLimited
    }
  }
  ${LoanLimitedFragmentDoc}
  ${LoanArtifactLimitedFragmentDoc}
`;

/**
 * __useGetClosedLimitedLoansByLoanIdsQuery__
 *
 * To run a query within a React component, call `useGetClosedLimitedLoansByLoanIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClosedLimitedLoansByLoanIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClosedLimitedLoansByLoanIdsQuery({
 *   variables: {
 *      loan_ids: // value for 'loan_ids'
 *   },
 * });
 */
export function useGetClosedLimitedLoansByLoanIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClosedLimitedLoansByLoanIdsQuery,
    GetClosedLimitedLoansByLoanIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClosedLimitedLoansByLoanIdsQuery,
    GetClosedLimitedLoansByLoanIdsQueryVariables
  >(GetClosedLimitedLoansByLoanIdsDocument, options);
}
export function useGetClosedLimitedLoansByLoanIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClosedLimitedLoansByLoanIdsQuery,
    GetClosedLimitedLoansByLoanIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClosedLimitedLoansByLoanIdsQuery,
    GetClosedLimitedLoansByLoanIdsQueryVariables
  >(GetClosedLimitedLoansByLoanIdsDocument, options);
}
export type GetClosedLimitedLoansByLoanIdsQueryHookResult = ReturnType<
  typeof useGetClosedLimitedLoansByLoanIdsQuery
>;
export type GetClosedLimitedLoansByLoanIdsLazyQueryHookResult = ReturnType<
  typeof useGetClosedLimitedLoansByLoanIdsLazyQuery
>;
export type GetClosedLimitedLoansByLoanIdsQueryResult = Apollo.QueryResult<
  GetClosedLimitedLoansByLoanIdsQuery,
  GetClosedLimitedLoansByLoanIdsQueryVariables
>;
export const GetMetrcApiKeysByCompanyIdDocument = gql`
  query GetMetrcApiKeysByCompanyId($companyId: uuid!) {
    metrc_api_keys(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { company_id: { _eq: $companyId } }
        ]
      }
    ) {
      id
      ...MetrcApiKeyLimited
    }
  }
  ${MetrcApiKeyLimitedFragmentDoc}
`;

/**
 * __useGetMetrcApiKeysByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetMetrcApiKeysByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetrcApiKeysByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetrcApiKeysByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetMetrcApiKeysByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMetrcApiKeysByCompanyIdQuery,
    GetMetrcApiKeysByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMetrcApiKeysByCompanyIdQuery,
    GetMetrcApiKeysByCompanyIdQueryVariables
  >(GetMetrcApiKeysByCompanyIdDocument, options);
}
export function useGetMetrcApiKeysByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetrcApiKeysByCompanyIdQuery,
    GetMetrcApiKeysByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMetrcApiKeysByCompanyIdQuery,
    GetMetrcApiKeysByCompanyIdQueryVariables
  >(GetMetrcApiKeysByCompanyIdDocument, options);
}
export type GetMetrcApiKeysByCompanyIdQueryHookResult = ReturnType<
  typeof useGetMetrcApiKeysByCompanyIdQuery
>;
export type GetMetrcApiKeysByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetMetrcApiKeysByCompanyIdLazyQuery
>;
export type GetMetrcApiKeysByCompanyIdQueryResult = Apollo.QueryResult<
  GetMetrcApiKeysByCompanyIdQuery,
  GetMetrcApiKeysByCompanyIdQueryVariables
>;
export const GetMetrcMetadataByCompanyIdDocument = gql`
  query GetMetrcMetadataByCompanyId($company_id: uuid!) {
    companies_by_pk(id: $company_id) {
      id
      company_facilities(
        where: {
          _or: [
            { is_deleted: { _is_null: true } }
            { is_deleted: { _eq: false } }
          ]
        }
        order_by: { name: asc }
      ) {
        id
        ...CompanyFacility
      }
      licenses(
        where: {
          _or: [
            { is_deleted: { _is_null: true } }
            { is_deleted: { _eq: false } }
          ]
        }
        order_by: { license_number: asc }
      ) {
        id
        company_facility {
          id
          name
        }
        ...CompanyLicense
      }
      metrc_api_keys(
        where: {
          _and: [
            {
              _or: [
                { is_deleted: { _is_null: true } }
                { is_deleted: { _eq: false } }
              ]
            }
            { company_id: { _eq: $company_id } }
          ]
        }
      ) {
        id
        ...MetrcApiKeyLimited
      }
    }
  }
  ${CompanyFacilityFragmentDoc}
  ${CompanyLicenseFragmentDoc}
  ${MetrcApiKeyLimitedFragmentDoc}
`;

/**
 * __useGetMetrcMetadataByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetMetrcMetadataByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetrcMetadataByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetrcMetadataByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetMetrcMetadataByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMetrcMetadataByCompanyIdQuery,
    GetMetrcMetadataByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMetrcMetadataByCompanyIdQuery,
    GetMetrcMetadataByCompanyIdQueryVariables
  >(GetMetrcMetadataByCompanyIdDocument, options);
}
export function useGetMetrcMetadataByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetrcMetadataByCompanyIdQuery,
    GetMetrcMetadataByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMetrcMetadataByCompanyIdQuery,
    GetMetrcMetadataByCompanyIdQueryVariables
  >(GetMetrcMetadataByCompanyIdDocument, options);
}
export type GetMetrcMetadataByCompanyIdQueryHookResult = ReturnType<
  typeof useGetMetrcMetadataByCompanyIdQuery
>;
export type GetMetrcMetadataByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetMetrcMetadataByCompanyIdLazyQuery
>;
export type GetMetrcMetadataByCompanyIdQueryResult = Apollo.QueryResult<
  GetMetrcMetadataByCompanyIdQuery,
  GetMetrcMetadataByCompanyIdQueryVariables
>;
export const GetMetrcDownloadSummariesByMetrcApiKeyIdDocument = gql`
  query GetMetrcDownloadSummariesByMetrcApiKeyId($metrcApiKeyId: uuid!) {
    metrc_download_summaries(
      where: { metrc_api_key_id: { _eq: $metrcApiKeyId } }
      order_by: { date: desc }
    ) {
      id
      ...MetrcDownloadSummaryLimited
    }
  }
  ${MetrcDownloadSummaryLimitedFragmentDoc}
`;

/**
 * __useGetMetrcDownloadSummariesByMetrcApiKeyIdQuery__
 *
 * To run a query within a React component, call `useGetMetrcDownloadSummariesByMetrcApiKeyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetrcDownloadSummariesByMetrcApiKeyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetrcDownloadSummariesByMetrcApiKeyIdQuery({
 *   variables: {
 *      metrcApiKeyId: // value for 'metrcApiKeyId'
 *   },
 * });
 */
export function useGetMetrcDownloadSummariesByMetrcApiKeyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMetrcDownloadSummariesByMetrcApiKeyIdQuery,
    GetMetrcDownloadSummariesByMetrcApiKeyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMetrcDownloadSummariesByMetrcApiKeyIdQuery,
    GetMetrcDownloadSummariesByMetrcApiKeyIdQueryVariables
  >(GetMetrcDownloadSummariesByMetrcApiKeyIdDocument, options);
}
export function useGetMetrcDownloadSummariesByMetrcApiKeyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetrcDownloadSummariesByMetrcApiKeyIdQuery,
    GetMetrcDownloadSummariesByMetrcApiKeyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMetrcDownloadSummariesByMetrcApiKeyIdQuery,
    GetMetrcDownloadSummariesByMetrcApiKeyIdQueryVariables
  >(GetMetrcDownloadSummariesByMetrcApiKeyIdDocument, options);
}
export type GetMetrcDownloadSummariesByMetrcApiKeyIdQueryHookResult =
  ReturnType<typeof useGetMetrcDownloadSummariesByMetrcApiKeyIdQuery>;
export type GetMetrcDownloadSummariesByMetrcApiKeyIdLazyQueryHookResult =
  ReturnType<typeof useGetMetrcDownloadSummariesByMetrcApiKeyIdLazyQuery>;
export type GetMetrcDownloadSummariesByMetrcApiKeyIdQueryResult =
  Apollo.QueryResult<
    GetMetrcDownloadSummariesByMetrcApiKeyIdQuery,
    GetMetrcDownloadSummariesByMetrcApiKeyIdQueryVariables
  >;
export const GetMetrcDownloadSummariesByLicenseNumberDocument = gql`
  query GetMetrcDownloadSummariesByLicenseNumber($license_number: String!) {
    metrc_download_summaries(
      where: { license_number: { _eq: $license_number } }
      order_by: { date: desc }
    ) {
      id
      ...MetrcDownloadSummaryLimited
    }
  }
  ${MetrcDownloadSummaryLimitedFragmentDoc}
`;

/**
 * __useGetMetrcDownloadSummariesByLicenseNumberQuery__
 *
 * To run a query within a React component, call `useGetMetrcDownloadSummariesByLicenseNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetrcDownloadSummariesByLicenseNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetrcDownloadSummariesByLicenseNumberQuery({
 *   variables: {
 *      license_number: // value for 'license_number'
 *   },
 * });
 */
export function useGetMetrcDownloadSummariesByLicenseNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMetrcDownloadSummariesByLicenseNumberQuery,
    GetMetrcDownloadSummariesByLicenseNumberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMetrcDownloadSummariesByLicenseNumberQuery,
    GetMetrcDownloadSummariesByLicenseNumberQueryVariables
  >(GetMetrcDownloadSummariesByLicenseNumberDocument, options);
}
export function useGetMetrcDownloadSummariesByLicenseNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetrcDownloadSummariesByLicenseNumberQuery,
    GetMetrcDownloadSummariesByLicenseNumberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMetrcDownloadSummariesByLicenseNumberQuery,
    GetMetrcDownloadSummariesByLicenseNumberQueryVariables
  >(GetMetrcDownloadSummariesByLicenseNumberDocument, options);
}
export type GetMetrcDownloadSummariesByLicenseNumberQueryHookResult =
  ReturnType<typeof useGetMetrcDownloadSummariesByLicenseNumberQuery>;
export type GetMetrcDownloadSummariesByLicenseNumberLazyQueryHookResult =
  ReturnType<typeof useGetMetrcDownloadSummariesByLicenseNumberLazyQuery>;
export type GetMetrcDownloadSummariesByLicenseNumberQueryResult =
  Apollo.QueryResult<
    GetMetrcDownloadSummariesByLicenseNumberQuery,
    GetMetrcDownloadSummariesByLicenseNumberQueryVariables
  >;
export const GetBankPayorPartnershipDocument = gql`
  query GetBankPayorPartnership($id: uuid!) {
    company_payor_partnerships_by_pk(id: $id) {
      ...PayorPartnership
      company {
        ...Company
        ...AllCompanyUsersForBank
        settings {
          ...CompanySettings
        }
      }
      payor {
        id
        licenses(
          where: {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
        ) {
          ...CompanyLicenseLimited
        }
        settings {
          id
          collections_bespoke_bank_account {
            ...BankAccount
          }
        }
        users {
          ...Contact
        }
      }
      payor_agreement {
        ...CompanyAgreement
      }
      payor_contacts {
        id
        ...CompanyPayorContact
      }
    }
  }
  ${PayorPartnershipFragmentDoc}
  ${CompanyFragmentDoc}
  ${AllCompanyUsersForBankFragmentDoc}
  ${CompanySettingsFragmentDoc}
  ${CompanyLicenseLimitedFragmentDoc}
  ${BankAccountFragmentDoc}
  ${ContactFragmentDoc}
  ${CompanyAgreementFragmentDoc}
  ${CompanyPayorContactFragmentDoc}
`;

/**
 * __useGetBankPayorPartnershipQuery__
 *
 * To run a query within a React component, call `useGetBankPayorPartnershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankPayorPartnershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankPayorPartnershipQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBankPayorPartnershipQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBankPayorPartnershipQuery,
    GetBankPayorPartnershipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBankPayorPartnershipQuery,
    GetBankPayorPartnershipQueryVariables
  >(GetBankPayorPartnershipDocument, options);
}
export function useGetBankPayorPartnershipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBankPayorPartnershipQuery,
    GetBankPayorPartnershipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBankPayorPartnershipQuery,
    GetBankPayorPartnershipQueryVariables
  >(GetBankPayorPartnershipDocument, options);
}
export type GetBankPayorPartnershipQueryHookResult = ReturnType<
  typeof useGetBankPayorPartnershipQuery
>;
export type GetBankPayorPartnershipLazyQueryHookResult = ReturnType<
  typeof useGetBankPayorPartnershipLazyQuery
>;
export type GetBankPayorPartnershipQueryResult = Apollo.QueryResult<
  GetBankPayorPartnershipQuery,
  GetBankPayorPartnershipQueryVariables
>;
export const GetPayorPartnershipForContactsDocument = gql`
  query GetPayorPartnershipForContacts($id: uuid!) {
    company_payor_partnerships_by_pk(id: $id) {
      ...PayorPartnership
      payor {
        id
        users {
          id
          ...Contact
        }
      }
      payor_contacts {
        id
        ...CompanyPayorContact
      }
    }
  }
  ${PayorPartnershipFragmentDoc}
  ${ContactFragmentDoc}
  ${CompanyPayorContactFragmentDoc}
`;

/**
 * __useGetPayorPartnershipForContactsQuery__
 *
 * To run a query within a React component, call `useGetPayorPartnershipForContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayorPartnershipForContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayorPartnershipForContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPayorPartnershipForContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayorPartnershipForContactsQuery,
    GetPayorPartnershipForContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPayorPartnershipForContactsQuery,
    GetPayorPartnershipForContactsQueryVariables
  >(GetPayorPartnershipForContactsDocument, options);
}
export function useGetPayorPartnershipForContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayorPartnershipForContactsQuery,
    GetPayorPartnershipForContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPayorPartnershipForContactsQuery,
    GetPayorPartnershipForContactsQueryVariables
  >(GetPayorPartnershipForContactsDocument, options);
}
export type GetPayorPartnershipForContactsQueryHookResult = ReturnType<
  typeof useGetPayorPartnershipForContactsQuery
>;
export type GetPayorPartnershipForContactsLazyQueryHookResult = ReturnType<
  typeof useGetPayorPartnershipForContactsLazyQuery
>;
export type GetPayorPartnershipForContactsQueryResult = Apollo.QueryResult<
  GetPayorPartnershipForContactsQuery,
  GetPayorPartnershipForContactsQueryVariables
>;
export const GetPayorPartnershipsForBankDocument = gql`
  query GetPayorPartnershipsForBank {
    company_payor_partnerships(order_by: { payor: { name: asc } }) {
      ...PayorPartnership
      company {
        id
        ...CompanyLimited
      }
      payor {
        id
        ...Payor
        users {
          ...Contact
        }
      }
    }
  }
  ${PayorPartnershipFragmentDoc}
  ${CompanyLimitedFragmentDoc}
  ${PayorFragmentDoc}
  ${ContactFragmentDoc}
`;

/**
 * __useGetPayorPartnershipsForBankQuery__
 *
 * To run a query within a React component, call `useGetPayorPartnershipsForBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayorPartnershipsForBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayorPartnershipsForBankQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayorPartnershipsForBankQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPayorPartnershipsForBankQuery,
    GetPayorPartnershipsForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPayorPartnershipsForBankQuery,
    GetPayorPartnershipsForBankQueryVariables
  >(GetPayorPartnershipsForBankDocument, options);
}
export function useGetPayorPartnershipsForBankLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayorPartnershipsForBankQuery,
    GetPayorPartnershipsForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPayorPartnershipsForBankQuery,
    GetPayorPartnershipsForBankQueryVariables
  >(GetPayorPartnershipsForBankDocument, options);
}
export type GetPayorPartnershipsForBankQueryHookResult = ReturnType<
  typeof useGetPayorPartnershipsForBankQuery
>;
export type GetPayorPartnershipsForBankLazyQueryHookResult = ReturnType<
  typeof useGetPayorPartnershipsForBankLazyQuery
>;
export type GetPayorPartnershipsForBankQueryResult = Apollo.QueryResult<
  GetPayorPartnershipsForBankQuery,
  GetPayorPartnershipsForBankQueryVariables
>;
export const GetPayorPartnershipsByPayorIdDocument = gql`
  query GetPayorPartnershipsByPayorId($payor_id: uuid!) {
    company_payor_partnerships(
      where: { payor_id: { _eq: $payor_id } }
      order_by: { company: { name: asc } }
    ) {
      id
      ...PayorPartnership
    }
  }
  ${PayorPartnershipFragmentDoc}
`;

/**
 * __useGetPayorPartnershipsByPayorIdQuery__
 *
 * To run a query within a React component, call `useGetPayorPartnershipsByPayorIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayorPartnershipsByPayorIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayorPartnershipsByPayorIdQuery({
 *   variables: {
 *      payor_id: // value for 'payor_id'
 *   },
 * });
 */
export function useGetPayorPartnershipsByPayorIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayorPartnershipsByPayorIdQuery,
    GetPayorPartnershipsByPayorIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPayorPartnershipsByPayorIdQuery,
    GetPayorPartnershipsByPayorIdQueryVariables
  >(GetPayorPartnershipsByPayorIdDocument, options);
}
export function useGetPayorPartnershipsByPayorIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayorPartnershipsByPayorIdQuery,
    GetPayorPartnershipsByPayorIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPayorPartnershipsByPayorIdQuery,
    GetPayorPartnershipsByPayorIdQueryVariables
  >(GetPayorPartnershipsByPayorIdDocument, options);
}
export type GetPayorPartnershipsByPayorIdQueryHookResult = ReturnType<
  typeof useGetPayorPartnershipsByPayorIdQuery
>;
export type GetPayorPartnershipsByPayorIdLazyQueryHookResult = ReturnType<
  typeof useGetPayorPartnershipsByPayorIdLazyQuery
>;
export type GetPayorPartnershipsByPayorIdQueryResult = Apollo.QueryResult<
  GetPayorPartnershipsByPayorIdQuery,
  GetPayorPartnershipsByPayorIdQueryVariables
>;
export const UpdatePayorAgreementIdDocument = gql`
  mutation UpdatePayorAgreementId(
    $companyPayorPartnershipId: uuid!
    $payorAgreementId: uuid
  ) {
    update_company_payor_partnerships_by_pk(
      pk_columns: { id: $companyPayorPartnershipId }
      _set: { payor_agreement_id: $payorAgreementId }
    ) {
      id
      payor_agreement {
        ...CompanyAgreement
      }
    }
  }
  ${CompanyAgreementFragmentDoc}
`;
export type UpdatePayorAgreementIdMutationFn = Apollo.MutationFunction<
  UpdatePayorAgreementIdMutation,
  UpdatePayorAgreementIdMutationVariables
>;

/**
 * __useUpdatePayorAgreementIdMutation__
 *
 * To run a mutation, you first call `useUpdatePayorAgreementIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayorAgreementIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayorAgreementIdMutation, { data, loading, error }] = useUpdatePayorAgreementIdMutation({
 *   variables: {
 *      companyPayorPartnershipId: // value for 'companyPayorPartnershipId'
 *      payorAgreementId: // value for 'payorAgreementId'
 *   },
 * });
 */
export function useUpdatePayorAgreementIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayorAgreementIdMutation,
    UpdatePayorAgreementIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePayorAgreementIdMutation,
    UpdatePayorAgreementIdMutationVariables
  >(UpdatePayorAgreementIdDocument, options);
}
export type UpdatePayorAgreementIdMutationHookResult = ReturnType<
  typeof useUpdatePayorAgreementIdMutation
>;
export type UpdatePayorAgreementIdMutationResult =
  Apollo.MutationResult<UpdatePayorAgreementIdMutation>;
export type UpdatePayorAgreementIdMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayorAgreementIdMutation,
  UpdatePayorAgreementIdMutationVariables
>;
export const AddCompanyPayorAgreementDocument = gql`
  mutation AddCompanyPayorAgreement(
    $payorAgreement: company_agreements_insert_input!
  ) {
    insert_company_agreements_one(object: $payorAgreement) {
      ...CompanyAgreement
    }
  }
  ${CompanyAgreementFragmentDoc}
`;
export type AddCompanyPayorAgreementMutationFn = Apollo.MutationFunction<
  AddCompanyPayorAgreementMutation,
  AddCompanyPayorAgreementMutationVariables
>;

/**
 * __useAddCompanyPayorAgreementMutation__
 *
 * To run a mutation, you first call `useAddCompanyPayorAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyPayorAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyPayorAgreementMutation, { data, loading, error }] = useAddCompanyPayorAgreementMutation({
 *   variables: {
 *      payorAgreement: // value for 'payorAgreement'
 *   },
 * });
 */
export function useAddCompanyPayorAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCompanyPayorAgreementMutation,
    AddCompanyPayorAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCompanyPayorAgreementMutation,
    AddCompanyPayorAgreementMutationVariables
  >(AddCompanyPayorAgreementDocument, options);
}
export type AddCompanyPayorAgreementMutationHookResult = ReturnType<
  typeof useAddCompanyPayorAgreementMutation
>;
export type AddCompanyPayorAgreementMutationResult =
  Apollo.MutationResult<AddCompanyPayorAgreementMutation>;
export type AddCompanyPayorAgreementMutationOptions =
  Apollo.BaseMutationOptions<
    AddCompanyPayorAgreementMutation,
    AddCompanyPayorAgreementMutationVariables
  >;
export const GetPayorsWithMetadataDocument = gql`
  query GetPayorsWithMetadata($date: date) {
    payors: companies(
      where: { is_payor: { _eq: true } }
      order_by: { name: asc }
    ) {
      id
      ...CustomersWithMetadata
      licenses(
        where: {
          _or: [
            { is_deleted: { _is_null: true } }
            { is_deleted: { _eq: false } }
          ]
        }
      ) {
        ...CompanyLicenseLimited
      }
    }
  }
  ${CustomersWithMetadataFragmentDoc}
  ${CompanyLicenseLimitedFragmentDoc}
`;

/**
 * __useGetPayorsWithMetadataQuery__
 *
 * To run a query within a React component, call `useGetPayorsWithMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayorsWithMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayorsWithMetadataQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetPayorsWithMetadataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPayorsWithMetadataQuery,
    GetPayorsWithMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPayorsWithMetadataQuery,
    GetPayorsWithMetadataQueryVariables
  >(GetPayorsWithMetadataDocument, options);
}
export function useGetPayorsWithMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayorsWithMetadataQuery,
    GetPayorsWithMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPayorsWithMetadataQuery,
    GetPayorsWithMetadataQueryVariables
  >(GetPayorsWithMetadataDocument, options);
}
export type GetPayorsWithMetadataQueryHookResult = ReturnType<
  typeof useGetPayorsWithMetadataQuery
>;
export type GetPayorsWithMetadataLazyQueryHookResult = ReturnType<
  typeof useGetPayorsWithMetadataLazyQuery
>;
export type GetPayorsWithMetadataQueryResult = Apollo.QueryResult<
  GetPayorsWithMetadataQuery,
  GetPayorsWithMetadataQueryVariables
>;
export const ListPayorPartnershipsByCompanyIdDocument = gql`
  query ListPayorPartnershipsByCompanyId($companyId: uuid!) {
    company_payor_partnerships(where: { company_id: { _eq: $companyId } }) {
      id
      ...PayorPartnership
      payor_limited {
        ...PayorLimited
      }
    }
  }
  ${PayorPartnershipFragmentDoc}
  ${PayorLimitedFragmentDoc}
`;

/**
 * __useListPayorPartnershipsByCompanyIdQuery__
 *
 * To run a query within a React component, call `useListPayorPartnershipsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPayorPartnershipsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPayorPartnershipsByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useListPayorPartnershipsByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListPayorPartnershipsByCompanyIdQuery,
    ListPayorPartnershipsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListPayorPartnershipsByCompanyIdQuery,
    ListPayorPartnershipsByCompanyIdQueryVariables
  >(ListPayorPartnershipsByCompanyIdDocument, options);
}
export function useListPayorPartnershipsByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListPayorPartnershipsByCompanyIdQuery,
    ListPayorPartnershipsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListPayorPartnershipsByCompanyIdQuery,
    ListPayorPartnershipsByCompanyIdQueryVariables
  >(ListPayorPartnershipsByCompanyIdDocument, options);
}
export type ListPayorPartnershipsByCompanyIdQueryHookResult = ReturnType<
  typeof useListPayorPartnershipsByCompanyIdQuery
>;
export type ListPayorPartnershipsByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useListPayorPartnershipsByCompanyIdLazyQuery
>;
export type ListPayorPartnershipsByCompanyIdQueryResult = Apollo.QueryResult<
  ListPayorPartnershipsByCompanyIdQuery,
  ListPayorPartnershipsByCompanyIdQueryVariables
>;
export const GetCompanyDeliveryDocument = gql`
  query GetCompanyDelivery($id: uuid!, $company_id: uuid!) {
    company_deliveries_by_pk(id: $id) {
      id
      ...CompanyDelivery
      metrc_transfer {
        id
        ...MetrcTransfer
        metrc_transfer_packages {
          id
          ...MetrcTransferPackage
        }
      }
      metrc_delivery {
        id
        ...MetrcDeliveryLimited
      }
      vendor {
        id
        name
        company_vendor_partnerships(
          where: { company_id: { _eq: $company_id } }
        ) {
          id
          approved_at
        }
      }
    }
  }
  ${CompanyDeliveryFragmentDoc}
  ${MetrcTransferFragmentDoc}
  ${MetrcTransferPackageFragmentDoc}
  ${MetrcDeliveryLimitedFragmentDoc}
`;

/**
 * __useGetCompanyDeliveryQuery__
 *
 * To run a query within a React component, call `useGetCompanyDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDeliveryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetCompanyDeliveryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyDeliveryQuery,
    GetCompanyDeliveryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyDeliveryQuery,
    GetCompanyDeliveryQueryVariables
  >(GetCompanyDeliveryDocument, options);
}
export function useGetCompanyDeliveryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyDeliveryQuery,
    GetCompanyDeliveryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyDeliveryQuery,
    GetCompanyDeliveryQueryVariables
  >(GetCompanyDeliveryDocument, options);
}
export type GetCompanyDeliveryQueryHookResult = ReturnType<
  typeof useGetCompanyDeliveryQuery
>;
export type GetCompanyDeliveryLazyQueryHookResult = ReturnType<
  typeof useGetCompanyDeliveryLazyQuery
>;
export type GetCompanyDeliveryQueryResult = Apollo.QueryResult<
  GetCompanyDeliveryQuery,
  GetCompanyDeliveryQueryVariables
>;
export const GetIncomingFromVendorCompanyDeliveriesByCompanyIdDocument = gql`
  query GetIncomingFromVendorCompanyDeliveriesByCompanyId(
    $company_id: uuid!
    $start_created_date: date!
    $transfer_row_ids: [uuid!]!
  ) {
    company_deliveries(
      where: {
        _and: [
          { company_id: { _eq: $company_id } }
          {
            delivery_type: { _in: ["INCOMING_FROM_VENDOR", "INCOMING_UNKNOWN"] }
          }
          {
            _or: [
              {
                metrc_transfer: { created_date: { _gte: $start_created_date } }
              }
              { metrc_transfer: { id: { _in: $transfer_row_ids } } }
            ]
          }
        ]
      }
      order_by: { metrc_transfer: { created_date: desc } }
    ) {
      id
      ...CompanyDelivery
      metrc_transfer {
        id
        ...MetrcTransfer
      }
      metrc_delivery {
        id
        ...MetrcDeliveryLimited
      }
      vendor {
        id
        name
        company_vendor_partnerships(
          where: { company_id: { _eq: $company_id } }
        ) {
          id
          approved_at
        }
      }
    }
  }
  ${CompanyDeliveryFragmentDoc}
  ${MetrcTransferFragmentDoc}
  ${MetrcDeliveryLimitedFragmentDoc}
`;

/**
 * __useGetIncomingFromVendorCompanyDeliveriesByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetIncomingFromVendorCompanyDeliveriesByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncomingFromVendorCompanyDeliveriesByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncomingFromVendorCompanyDeliveriesByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      start_created_date: // value for 'start_created_date'
 *      transfer_row_ids: // value for 'transfer_row_ids'
 *   },
 * });
 */
export function useGetIncomingFromVendorCompanyDeliveriesByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIncomingFromVendorCompanyDeliveriesByCompanyIdQuery,
    GetIncomingFromVendorCompanyDeliveriesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIncomingFromVendorCompanyDeliveriesByCompanyIdQuery,
    GetIncomingFromVendorCompanyDeliveriesByCompanyIdQueryVariables
  >(GetIncomingFromVendorCompanyDeliveriesByCompanyIdDocument, options);
}
export function useGetIncomingFromVendorCompanyDeliveriesByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIncomingFromVendorCompanyDeliveriesByCompanyIdQuery,
    GetIncomingFromVendorCompanyDeliveriesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIncomingFromVendorCompanyDeliveriesByCompanyIdQuery,
    GetIncomingFromVendorCompanyDeliveriesByCompanyIdQueryVariables
  >(GetIncomingFromVendorCompanyDeliveriesByCompanyIdDocument, options);
}
export type GetIncomingFromVendorCompanyDeliveriesByCompanyIdQueryHookResult =
  ReturnType<typeof useGetIncomingFromVendorCompanyDeliveriesByCompanyIdQuery>;
export type GetIncomingFromVendorCompanyDeliveriesByCompanyIdLazyQueryHookResult =
  ReturnType<
    typeof useGetIncomingFromVendorCompanyDeliveriesByCompanyIdLazyQuery
  >;
export type GetIncomingFromVendorCompanyDeliveriesByCompanyIdQueryResult =
  Apollo.QueryResult<
    GetIncomingFromVendorCompanyDeliveriesByCompanyIdQuery,
    GetIncomingFromVendorCompanyDeliveriesByCompanyIdQueryVariables
  >;
export const GetPurchaseOrderForBankDocument = gql`
  query GetPurchaseOrderForBank($id: uuid!) {
    purchase_orders_by_pk(id: $id) {
      id
      ...PurchaseOrder
      loans(where: { loan_type: { _eq: purchase_order } }) {
        id
        ...LoanLimited
      }
      purchase_order_files {
        ...PurchaseOrderFile
      }
      purchase_order_metrc_transfers {
        id
        ...PurchaseOrderMetrcTransfer
        metrc_transfer {
          id
          ...MetrcTransfer
          metrc_transfer_packages {
            id
            ...MetrcTransferPackage
          }
        }
      }
    }
  }
  ${PurchaseOrderFragmentDoc}
  ${LoanLimitedFragmentDoc}
  ${PurchaseOrderFileFragmentDoc}
  ${PurchaseOrderMetrcTransferFragmentDoc}
  ${MetrcTransferFragmentDoc}
  ${MetrcTransferPackageFragmentDoc}
`;

/**
 * __useGetPurchaseOrderForBankQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrderForBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrderForBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrderForBankQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPurchaseOrderForBankQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPurchaseOrderForBankQuery,
    GetPurchaseOrderForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPurchaseOrderForBankQuery,
    GetPurchaseOrderForBankQueryVariables
  >(GetPurchaseOrderForBankDocument, options);
}
export function useGetPurchaseOrderForBankLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPurchaseOrderForBankQuery,
    GetPurchaseOrderForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPurchaseOrderForBankQuery,
    GetPurchaseOrderForBankQueryVariables
  >(GetPurchaseOrderForBankDocument, options);
}
export type GetPurchaseOrderForBankQueryHookResult = ReturnType<
  typeof useGetPurchaseOrderForBankQuery
>;
export type GetPurchaseOrderForBankLazyQueryHookResult = ReturnType<
  typeof useGetPurchaseOrderForBankLazyQuery
>;
export type GetPurchaseOrderForBankQueryResult = Apollo.QueryResult<
  GetPurchaseOrderForBankQuery,
  GetPurchaseOrderForBankQueryVariables
>;
export const GetPurchaseOrderForCustomerDocument = gql`
  query GetPurchaseOrderForCustomer($id: uuid!) {
    purchase_orders_by_pk(id: $id) {
      id
      ...PurchaseOrderLimited
      loans(
        where: {
          _and: [
            { loan_type: { _eq: purchase_order } }
            {
              _or: [
                { is_deleted: { _is_null: true } }
                { is_deleted: { _eq: false } }
              ]
            }
          ]
        }
      ) {
        id
        ...LoanLimited
      }
      purchase_order_files {
        ...PurchaseOrderFile
      }
      purchase_order_metrc_transfers {
        id
        ...PurchaseOrderMetrcTransfer
        metrc_transfer {
          id
          ...MetrcTransfer
        }
      }
    }
  }
  ${PurchaseOrderLimitedFragmentDoc}
  ${LoanLimitedFragmentDoc}
  ${PurchaseOrderFileFragmentDoc}
  ${PurchaseOrderMetrcTransferFragmentDoc}
  ${MetrcTransferFragmentDoc}
`;

/**
 * __useGetPurchaseOrderForCustomerQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrderForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrderForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrderForCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPurchaseOrderForCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPurchaseOrderForCustomerQuery,
    GetPurchaseOrderForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPurchaseOrderForCustomerQuery,
    GetPurchaseOrderForCustomerQueryVariables
  >(GetPurchaseOrderForCustomerDocument, options);
}
export function useGetPurchaseOrderForCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPurchaseOrderForCustomerQuery,
    GetPurchaseOrderForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPurchaseOrderForCustomerQuery,
    GetPurchaseOrderForCustomerQueryVariables
  >(GetPurchaseOrderForCustomerDocument, options);
}
export type GetPurchaseOrderForCustomerQueryHookResult = ReturnType<
  typeof useGetPurchaseOrderForCustomerQuery
>;
export type GetPurchaseOrderForCustomerLazyQueryHookResult = ReturnType<
  typeof useGetPurchaseOrderForCustomerLazyQuery
>;
export type GetPurchaseOrderForCustomerQueryResult = Apollo.QueryResult<
  GetPurchaseOrderForCustomerQuery,
  GetPurchaseOrderForCustomerQueryVariables
>;
export const GetPurchaseOrderForCombinedDocument = gql`
  query GetPurchaseOrderForCombined($id: uuid!, $is_bank_user: Boolean!) {
    purchase_orders_by_pk(id: $id) {
      id
      ...PurchaseOrderWithRelationships
    }
  }
  ${PurchaseOrderWithRelationshipsFragmentDoc}
`;

/**
 * __useGetPurchaseOrderForCombinedQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrderForCombinedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrderForCombinedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrderForCombinedQuery({
 *   variables: {
 *      id: // value for 'id'
 *      is_bank_user: // value for 'is_bank_user'
 *   },
 * });
 */
export function useGetPurchaseOrderForCombinedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPurchaseOrderForCombinedQuery,
    GetPurchaseOrderForCombinedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPurchaseOrderForCombinedQuery,
    GetPurchaseOrderForCombinedQueryVariables
  >(GetPurchaseOrderForCombinedDocument, options);
}
export function useGetPurchaseOrderForCombinedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPurchaseOrderForCombinedQuery,
    GetPurchaseOrderForCombinedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPurchaseOrderForCombinedQuery,
    GetPurchaseOrderForCombinedQueryVariables
  >(GetPurchaseOrderForCombinedDocument, options);
}
export type GetPurchaseOrderForCombinedQueryHookResult = ReturnType<
  typeof useGetPurchaseOrderForCombinedQuery
>;
export type GetPurchaseOrderForCombinedLazyQueryHookResult = ReturnType<
  typeof useGetPurchaseOrderForCombinedLazyQuery
>;
export type GetPurchaseOrderForCombinedQueryResult = Apollo.QueryResult<
  GetPurchaseOrderForCombinedQuery,
  GetPurchaseOrderForCombinedQueryVariables
>;
export const GetPurchaseOrderForReviewDocument = gql`
  query GetPurchaseOrderForReview($id: uuid!) {
    purchase_orders_by_pk(id: $id) {
      id
      company_id
      vendor_id
      order_number
      order_date
      net_terms
      amount
      is_cannabis
      is_metrc_based
      is_deleted
      status
      created_at
      all_customer_notes
      purchase_order_files {
        purchase_order_id
        file_id
        ...PurchaseOrderFile
      }
      company {
        id
        ...CompanyLimited
      }
      vendor {
        id
        ...VendorLimited
      }
      purchase_order_metrc_transfers {
        id
        ...PurchaseOrderMetrcTransfer
        metrc_transfer {
          id
          ...MetrcTransfer
          metrc_transfer_packages {
            id
            ...MetrcTransferPackage
          }
        }
      }
    }
  }
  ${PurchaseOrderFileFragmentDoc}
  ${CompanyLimitedFragmentDoc}
  ${VendorLimitedFragmentDoc}
  ${PurchaseOrderMetrcTransferFragmentDoc}
  ${MetrcTransferFragmentDoc}
  ${MetrcTransferPackageFragmentDoc}
`;

/**
 * __useGetPurchaseOrderForReviewQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrderForReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrderForReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrderForReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPurchaseOrderForReviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPurchaseOrderForReviewQuery,
    GetPurchaseOrderForReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPurchaseOrderForReviewQuery,
    GetPurchaseOrderForReviewQueryVariables
  >(GetPurchaseOrderForReviewDocument, options);
}
export function useGetPurchaseOrderForReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPurchaseOrderForReviewQuery,
    GetPurchaseOrderForReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPurchaseOrderForReviewQuery,
    GetPurchaseOrderForReviewQueryVariables
  >(GetPurchaseOrderForReviewDocument, options);
}
export type GetPurchaseOrderForReviewQueryHookResult = ReturnType<
  typeof useGetPurchaseOrderForReviewQuery
>;
export type GetPurchaseOrderForReviewLazyQueryHookResult = ReturnType<
  typeof useGetPurchaseOrderForReviewLazyQuery
>;
export type GetPurchaseOrderForReviewQueryResult = Apollo.QueryResult<
  GetPurchaseOrderForReviewQuery,
  GetPurchaseOrderForReviewQueryVariables
>;
export const GetPurchaseOrdersDocument = gql`
  subscription GetPurchaseOrders {
    purchase_orders(
      where: {
        _or: [
          { is_deleted: { _is_null: true } }
          { is_deleted: { _eq: false } }
        ]
      }
      order_by: [{ updated_at: desc }]
    ) {
      id
      ...PurchaseOrder
    }
  }
  ${PurchaseOrderFragmentDoc}
`;

/**
 * __useGetPurchaseOrdersSubscription__
 *
 * To run a query within a React component, call `useGetPurchaseOrdersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrdersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrdersSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetPurchaseOrdersSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetPurchaseOrdersSubscription,
    GetPurchaseOrdersSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetPurchaseOrdersSubscription,
    GetPurchaseOrdersSubscriptionVariables
  >(GetPurchaseOrdersDocument, options);
}
export type GetPurchaseOrdersSubscriptionHookResult = ReturnType<
  typeof useGetPurchaseOrdersSubscription
>;
export type GetPurchaseOrdersSubscriptionResult =
  Apollo.SubscriptionResult<GetPurchaseOrdersSubscription>;
export const GetPurchaseOrdersByNewStatusDocument = gql`
  subscription GetPurchaseOrdersByNewStatus($statuses: [String!]) {
    purchase_orders(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { new_purchase_order_status: { _in: $statuses } }
        ]
      }
      order_by: [{ requested_at: desc }, { created_at: desc }]
    ) {
      id
      ...PurchaseOrder
    }
  }
  ${PurchaseOrderFragmentDoc}
`;

/**
 * __useGetPurchaseOrdersByNewStatusSubscription__
 *
 * To run a query within a React component, call `useGetPurchaseOrdersByNewStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrdersByNewStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrdersByNewStatusSubscription({
 *   variables: {
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useGetPurchaseOrdersByNewStatusSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetPurchaseOrdersByNewStatusSubscription,
    GetPurchaseOrdersByNewStatusSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetPurchaseOrdersByNewStatusSubscription,
    GetPurchaseOrdersByNewStatusSubscriptionVariables
  >(GetPurchaseOrdersByNewStatusDocument, options);
}
export type GetPurchaseOrdersByNewStatusSubscriptionHookResult = ReturnType<
  typeof useGetPurchaseOrdersByNewStatusSubscription
>;
export type GetPurchaseOrdersByNewStatusSubscriptionResult =
  Apollo.SubscriptionResult<GetPurchaseOrdersByNewStatusSubscription>;
export const GetConfirmedPurchaseOrdersDocument = gql`
  subscription GetConfirmedPurchaseOrders {
    purchase_orders(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { approved_at: { _is_null: false } }
        ]
      }
      order_by: [{ approved_at: desc }, { created_at: desc }]
    ) {
      id
      ...PurchaseOrder
    }
  }
  ${PurchaseOrderFragmentDoc}
`;

/**
 * __useGetConfirmedPurchaseOrdersSubscription__
 *
 * To run a query within a React component, call `useGetConfirmedPurchaseOrdersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetConfirmedPurchaseOrdersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfirmedPurchaseOrdersSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetConfirmedPurchaseOrdersSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetConfirmedPurchaseOrdersSubscription,
    GetConfirmedPurchaseOrdersSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetConfirmedPurchaseOrdersSubscription,
    GetConfirmedPurchaseOrdersSubscriptionVariables
  >(GetConfirmedPurchaseOrdersDocument, options);
}
export type GetConfirmedPurchaseOrdersSubscriptionHookResult = ReturnType<
  typeof useGetConfirmedPurchaseOrdersSubscription
>;
export type GetConfirmedPurchaseOrdersSubscriptionResult =
  Apollo.SubscriptionResult<GetConfirmedPurchaseOrdersSubscription>;
export const GetConfirmedPurchaseOrdersNewDocument = gql`
  subscription GetConfirmedPurchaseOrdersNew {
    purchase_orders(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { approved_at: { _is_null: false } }
          { new_purchase_order_status: { _neq: "archived" } }
        ]
      }
      order_by: [{ approved_at: desc }, { created_at: desc }]
    ) {
      id
      ...PurchaseOrder
    }
  }
  ${PurchaseOrderFragmentDoc}
`;

/**
 * __useGetConfirmedPurchaseOrdersNewSubscription__
 *
 * To run a query within a React component, call `useGetConfirmedPurchaseOrdersNewSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetConfirmedPurchaseOrdersNewSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfirmedPurchaseOrdersNewSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetConfirmedPurchaseOrdersNewSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetConfirmedPurchaseOrdersNewSubscription,
    GetConfirmedPurchaseOrdersNewSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetConfirmedPurchaseOrdersNewSubscription,
    GetConfirmedPurchaseOrdersNewSubscriptionVariables
  >(GetConfirmedPurchaseOrdersNewDocument, options);
}
export type GetConfirmedPurchaseOrdersNewSubscriptionHookResult = ReturnType<
  typeof useGetConfirmedPurchaseOrdersNewSubscription
>;
export type GetConfirmedPurchaseOrdersNewSubscriptionResult =
  Apollo.SubscriptionResult<GetConfirmedPurchaseOrdersNewSubscription>;
export const GetAllPurchaseOrdersByCompanyIdNewDocument = gql`
  query GetAllPurchaseOrdersByCompanyIdNew($company_id: uuid!) {
    companies_by_pk(id: $company_id) {
      id
      settings {
        id
        has_autofinancing
      }
    }
    purchase_orders(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { company_id: { _eq: $company_id } }
        ]
      }
    ) {
      ...PurchaseOrderLimitedNew
    }
  }
  ${PurchaseOrderLimitedNewFragmentDoc}
`;

/**
 * __useGetAllPurchaseOrdersByCompanyIdNewQuery__
 *
 * To run a query within a React component, call `useGetAllPurchaseOrdersByCompanyIdNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPurchaseOrdersByCompanyIdNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPurchaseOrdersByCompanyIdNewQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetAllPurchaseOrdersByCompanyIdNewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllPurchaseOrdersByCompanyIdNewQuery,
    GetAllPurchaseOrdersByCompanyIdNewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllPurchaseOrdersByCompanyIdNewQuery,
    GetAllPurchaseOrdersByCompanyIdNewQueryVariables
  >(GetAllPurchaseOrdersByCompanyIdNewDocument, options);
}
export function useGetAllPurchaseOrdersByCompanyIdNewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPurchaseOrdersByCompanyIdNewQuery,
    GetAllPurchaseOrdersByCompanyIdNewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllPurchaseOrdersByCompanyIdNewQuery,
    GetAllPurchaseOrdersByCompanyIdNewQueryVariables
  >(GetAllPurchaseOrdersByCompanyIdNewDocument, options);
}
export type GetAllPurchaseOrdersByCompanyIdNewQueryHookResult = ReturnType<
  typeof useGetAllPurchaseOrdersByCompanyIdNewQuery
>;
export type GetAllPurchaseOrdersByCompanyIdNewLazyQueryHookResult = ReturnType<
  typeof useGetAllPurchaseOrdersByCompanyIdNewLazyQuery
>;
export type GetAllPurchaseOrdersByCompanyIdNewQueryResult = Apollo.QueryResult<
  GetAllPurchaseOrdersByCompanyIdNewQuery,
  GetAllPurchaseOrdersByCompanyIdNewQueryVariables
>;
export const GetFundablePurchaseOrdersByCompanyIdDocument = gql`
  query GetFundablePurchaseOrdersByCompanyId($company_id: uuid!) {
    purchase_orders(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { company_id: { _eq: $company_id } }
          { approved_at: { _is_null: false } }
          { funded_at: { _is_null: true } }
        ]
      }
    ) {
      ...PurchaseOrderLimited
    }
  }
  ${PurchaseOrderLimitedFragmentDoc}
`;

/**
 * __useGetFundablePurchaseOrdersByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetFundablePurchaseOrdersByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFundablePurchaseOrdersByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFundablePurchaseOrdersByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetFundablePurchaseOrdersByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFundablePurchaseOrdersByCompanyIdQuery,
    GetFundablePurchaseOrdersByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFundablePurchaseOrdersByCompanyIdQuery,
    GetFundablePurchaseOrdersByCompanyIdQueryVariables
  >(GetFundablePurchaseOrdersByCompanyIdDocument, options);
}
export function useGetFundablePurchaseOrdersByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFundablePurchaseOrdersByCompanyIdQuery,
    GetFundablePurchaseOrdersByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFundablePurchaseOrdersByCompanyIdQuery,
    GetFundablePurchaseOrdersByCompanyIdQueryVariables
  >(GetFundablePurchaseOrdersByCompanyIdDocument, options);
}
export type GetFundablePurchaseOrdersByCompanyIdQueryHookResult = ReturnType<
  typeof useGetFundablePurchaseOrdersByCompanyIdQuery
>;
export type GetFundablePurchaseOrdersByCompanyIdLazyQueryHookResult =
  ReturnType<typeof useGetFundablePurchaseOrdersByCompanyIdLazyQuery>;
export type GetFundablePurchaseOrdersByCompanyIdQueryResult =
  Apollo.QueryResult<
    GetFundablePurchaseOrdersByCompanyIdQuery,
    GetFundablePurchaseOrdersByCompanyIdQueryVariables
  >;
export const GetVendorReviewablePurchaseOrdersDocument = gql`
  query GetVendorReviewablePurchaseOrders($vendor_id: uuid!) {
    purchase_orders(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { vendor_id: { _eq: $vendor_id } }
          { new_purchase_order_status: { _eq: "pending_approval_by_vendor" } }
        ]
      }
      order_by: [{ requested_at: asc }]
    ) {
      ...PurchaseOrderLimitedNew
    }
  }
  ${PurchaseOrderLimitedNewFragmentDoc}
`;

/**
 * __useGetVendorReviewablePurchaseOrdersQuery__
 *
 * To run a query within a React component, call `useGetVendorReviewablePurchaseOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorReviewablePurchaseOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorReviewablePurchaseOrdersQuery({
 *   variables: {
 *      vendor_id: // value for 'vendor_id'
 *   },
 * });
 */
export function useGetVendorReviewablePurchaseOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVendorReviewablePurchaseOrdersQuery,
    GetVendorReviewablePurchaseOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVendorReviewablePurchaseOrdersQuery,
    GetVendorReviewablePurchaseOrdersQueryVariables
  >(GetVendorReviewablePurchaseOrdersDocument, options);
}
export function useGetVendorReviewablePurchaseOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVendorReviewablePurchaseOrdersQuery,
    GetVendorReviewablePurchaseOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVendorReviewablePurchaseOrdersQuery,
    GetVendorReviewablePurchaseOrdersQueryVariables
  >(GetVendorReviewablePurchaseOrdersDocument, options);
}
export type GetVendorReviewablePurchaseOrdersQueryHookResult = ReturnType<
  typeof useGetVendorReviewablePurchaseOrdersQuery
>;
export type GetVendorReviewablePurchaseOrdersLazyQueryHookResult = ReturnType<
  typeof useGetVendorReviewablePurchaseOrdersLazyQuery
>;
export type GetVendorReviewablePurchaseOrdersQueryResult = Apollo.QueryResult<
  GetVendorReviewablePurchaseOrdersQuery,
  GetVendorReviewablePurchaseOrdersQueryVariables
>;
export const GetPurchaseOrdersChangesRequestedCountForCustomerDocument = gql`
  query GetPurchaseOrdersChangesRequestedCountForCustomer($company_id: uuid!) {
    purchase_orders(
      where: {
        _and: [
          { company_id: { _eq: $company_id } }
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          {
            new_purchase_order_status: {
              _in: [
                "changes_requested_by_vendor"
                "changes_requested_by_bespoke"
              ]
            }
          }
        ]
      }
    ) {
      id
    }
  }
`;

/**
 * __useGetPurchaseOrdersChangesRequestedCountForCustomerQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrdersChangesRequestedCountForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrdersChangesRequestedCountForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrdersChangesRequestedCountForCustomerQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetPurchaseOrdersChangesRequestedCountForCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPurchaseOrdersChangesRequestedCountForCustomerQuery,
    GetPurchaseOrdersChangesRequestedCountForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPurchaseOrdersChangesRequestedCountForCustomerQuery,
    GetPurchaseOrdersChangesRequestedCountForCustomerQueryVariables
  >(GetPurchaseOrdersChangesRequestedCountForCustomerDocument, options);
}
export function useGetPurchaseOrdersChangesRequestedCountForCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPurchaseOrdersChangesRequestedCountForCustomerQuery,
    GetPurchaseOrdersChangesRequestedCountForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPurchaseOrdersChangesRequestedCountForCustomerQuery,
    GetPurchaseOrdersChangesRequestedCountForCustomerQueryVariables
  >(GetPurchaseOrdersChangesRequestedCountForCustomerDocument, options);
}
export type GetPurchaseOrdersChangesRequestedCountForCustomerQueryHookResult =
  ReturnType<typeof useGetPurchaseOrdersChangesRequestedCountForCustomerQuery>;
export type GetPurchaseOrdersChangesRequestedCountForCustomerLazyQueryHookResult =
  ReturnType<
    typeof useGetPurchaseOrdersChangesRequestedCountForCustomerLazyQuery
  >;
export type GetPurchaseOrdersChangesRequestedCountForCustomerQueryResult =
  Apollo.QueryResult<
    GetPurchaseOrdersChangesRequestedCountForCustomerQuery,
    GetPurchaseOrdersChangesRequestedCountForCustomerQueryVariables
  >;
export const GetVendorPurchaseOrdersByStatusDocument = gql`
  query GetVendorPurchaseOrdersByStatus(
    $vendor_id: uuid!
    $statuses: [String!]
  ) {
    purchase_orders(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { vendor_id: { _eq: $vendor_id } }
          { new_purchase_order_status: { _in: $statuses } }
        ]
      }
    ) {
      ...PurchaseOrderLimitedNew
    }
  }
  ${PurchaseOrderLimitedNewFragmentDoc}
`;

/**
 * __useGetVendorPurchaseOrdersByStatusQuery__
 *
 * To run a query within a React component, call `useGetVendorPurchaseOrdersByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorPurchaseOrdersByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorPurchaseOrdersByStatusQuery({
 *   variables: {
 *      vendor_id: // value for 'vendor_id'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useGetVendorPurchaseOrdersByStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVendorPurchaseOrdersByStatusQuery,
    GetVendorPurchaseOrdersByStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVendorPurchaseOrdersByStatusQuery,
    GetVendorPurchaseOrdersByStatusQueryVariables
  >(GetVendorPurchaseOrdersByStatusDocument, options);
}
export function useGetVendorPurchaseOrdersByStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVendorPurchaseOrdersByStatusQuery,
    GetVendorPurchaseOrdersByStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVendorPurchaseOrdersByStatusQuery,
    GetVendorPurchaseOrdersByStatusQueryVariables
  >(GetVendorPurchaseOrdersByStatusDocument, options);
}
export type GetVendorPurchaseOrdersByStatusQueryHookResult = ReturnType<
  typeof useGetVendorPurchaseOrdersByStatusQuery
>;
export type GetVendorPurchaseOrdersByStatusLazyQueryHookResult = ReturnType<
  typeof useGetVendorPurchaseOrdersByStatusLazyQuery
>;
export type GetVendorPurchaseOrdersByStatusQueryResult = Apollo.QueryResult<
  GetVendorPurchaseOrdersByStatusQuery,
  GetVendorPurchaseOrdersByStatusQueryVariables
>;
export const GetPaymentDocument = gql`
  query GetPayment($id: uuid!) {
    payments_by_pk(id: $id) {
      id
      ...PaymentLimited
      company {
        id
        name
      }
      settled_by_user {
        id
        full_name
      }
    }
  }
  ${PaymentLimitedFragmentDoc}
`;

/**
 * __useGetPaymentQuery__
 *
 * To run a query within a React component, call `useGetPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPaymentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentQuery,
    GetPaymentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPaymentQuery, GetPaymentQueryVariables>(
    GetPaymentDocument,
    options
  );
}
export function useGetPaymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentQuery,
    GetPaymentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPaymentQuery, GetPaymentQueryVariables>(
    GetPaymentDocument,
    options
  );
}
export type GetPaymentQueryHookResult = ReturnType<typeof useGetPaymentQuery>;
export type GetPaymentLazyQueryHookResult = ReturnType<
  typeof useGetPaymentLazyQuery
>;
export type GetPaymentQueryResult = Apollo.QueryResult<
  GetPaymentQuery,
  GetPaymentQueryVariables
>;
export const GetPaymentForBankDocument = gql`
  query GetPaymentForBank($id: uuid!) {
    payments_by_pk(id: $id) {
      id
      ...Payment
      company {
        id
        name
      }
      settled_by_user {
        id
        full_name
      }
    }
  }
  ${PaymentFragmentDoc}
`;

/**
 * __useGetPaymentForBankQuery__
 *
 * To run a query within a React component, call `useGetPaymentForBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentForBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentForBankQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPaymentForBankQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentForBankQuery,
    GetPaymentForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaymentForBankQuery,
    GetPaymentForBankQueryVariables
  >(GetPaymentForBankDocument, options);
}
export function useGetPaymentForBankLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentForBankQuery,
    GetPaymentForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaymentForBankQuery,
    GetPaymentForBankQueryVariables
  >(GetPaymentForBankDocument, options);
}
export type GetPaymentForBankQueryHookResult = ReturnType<
  typeof useGetPaymentForBankQuery
>;
export type GetPaymentForBankLazyQueryHookResult = ReturnType<
  typeof useGetPaymentForBankLazyQuery
>;
export type GetPaymentForBankQueryResult = Apollo.QueryResult<
  GetPaymentForBankQuery,
  GetPaymentForBankQueryVariables
>;
export const GetPaymentForSettlementDocument = gql`
  query GetPaymentForSettlement(
    $id: uuid!
    $today: date!
    $is_bank_user: Boolean = true
  ) {
    payments_by_pk(id: $id) {
      id
      ...PaymentCombined
      company {
        id
        name
        contract {
          id
          ...Contract
        }
        financial_summaries(where: { date: { _eq: $today } }) {
          id
          ...FinancialSummary
        }
      }
      company_bank_account {
        id
        ...BankAccount
      }
      invoice {
        id
        payor {
          id
          ...PayorCombined
        }
      }
      settled_by_user {
        id
        full_name
      }
    }
  }
  ${PaymentCombinedFragmentDoc}
  ${ContractFragmentDoc}
  ${FinancialSummaryFragmentDoc}
  ${BankAccountFragmentDoc}
  ${PayorCombinedFragmentDoc}
`;

/**
 * __useGetPaymentForSettlementQuery__
 *
 * To run a query within a React component, call `useGetPaymentForSettlementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentForSettlementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentForSettlementQuery({
 *   variables: {
 *      id: // value for 'id'
 *      today: // value for 'today'
 *      is_bank_user: // value for 'is_bank_user'
 *   },
 * });
 */
export function useGetPaymentForSettlementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentForSettlementQuery,
    GetPaymentForSettlementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaymentForSettlementQuery,
    GetPaymentForSettlementQueryVariables
  >(GetPaymentForSettlementDocument, options);
}
export function useGetPaymentForSettlementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentForSettlementQuery,
    GetPaymentForSettlementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaymentForSettlementQuery,
    GetPaymentForSettlementQueryVariables
  >(GetPaymentForSettlementDocument, options);
}
export type GetPaymentForSettlementQueryHookResult = ReturnType<
  typeof useGetPaymentForSettlementQuery
>;
export type GetPaymentForSettlementLazyQueryHookResult = ReturnType<
  typeof useGetPaymentForSettlementLazyQuery
>;
export type GetPaymentForSettlementQueryResult = Apollo.QueryResult<
  GetPaymentForSettlementQuery,
  GetPaymentForSettlementQueryVariables
>;
export const GetRepaymentsDocument = gql`
  subscription GetRepayments {
    payments(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { type: { _eq: "repayment" } }
        ]
      }
      order_by: [{ deposit_date: desc }, { created_at: desc }]
    ) {
      id
      ...Payment
      transactions(
        order_by: [
          { payment: { deposit_date: desc } }
          { payment: { created_at: desc } }
        ]
      ) {
        id
        ...Transaction
        loan {
          id
          ...LoanLimited
          ...LoanArtifactLimited
        }
        payment {
          id
          ...PaymentLimited
        }
      }
      company {
        id
        most_recent_financial_summary: financial_summaries(
          order_by: { date: desc }
          limit: 1
        ) {
          id
          product_type
        }
      }
      settled_by_user {
        id
        full_name
      }
    }
  }
  ${PaymentFragmentDoc}
  ${TransactionFragmentDoc}
  ${LoanLimitedFragmentDoc}
  ${LoanArtifactLimitedFragmentDoc}
  ${PaymentLimitedFragmentDoc}
`;

/**
 * __useGetRepaymentsSubscription__
 *
 * To run a query within a React component, call `useGetRepaymentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetRepaymentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepaymentsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetRepaymentsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetRepaymentsSubscription,
    GetRepaymentsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetRepaymentsSubscription,
    GetRepaymentsSubscriptionVariables
  >(GetRepaymentsDocument, options);
}
export type GetRepaymentsSubscriptionHookResult = ReturnType<
  typeof useGetRepaymentsSubscription
>;
export type GetRepaymentsSubscriptionResult =
  Apollo.SubscriptionResult<GetRepaymentsSubscription>;
export const GetRepaymentsByDepositDateRangeDocument = gql`
  query GetRepaymentsByDepositDateRange(
    $start_date: date!
    $end_date: date!
    $start_datetime: timestamptz!
    $end_datetime: timestamptz!
  ) {
    payments(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { type: { _in: ["repayment", "repayment_account_fee"] } }
          {
            _or: [
              { deposit_date: { _gte: $start_date, _lte: $end_date } }
              { reversed_at: { _gte: $start_datetime, _lte: $end_datetime } }
            ]
          }
        ]
      }
      order_by: [{ settlement_date: asc }, { created_at: asc }]
    ) {
      id
      ...RepaymentForBankDataGrid
    }
  }
  ${RepaymentForBankDataGridFragmentDoc}
`;

/**
 * __useGetRepaymentsByDepositDateRangeQuery__
 *
 * To run a query within a React component, call `useGetRepaymentsByDepositDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepaymentsByDepositDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepaymentsByDepositDateRangeQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      start_datetime: // value for 'start_datetime'
 *      end_datetime: // value for 'end_datetime'
 *   },
 * });
 */
export function useGetRepaymentsByDepositDateRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRepaymentsByDepositDateRangeQuery,
    GetRepaymentsByDepositDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRepaymentsByDepositDateRangeQuery,
    GetRepaymentsByDepositDateRangeQueryVariables
  >(GetRepaymentsByDepositDateRangeDocument, options);
}
export function useGetRepaymentsByDepositDateRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRepaymentsByDepositDateRangeQuery,
    GetRepaymentsByDepositDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRepaymentsByDepositDateRangeQuery,
    GetRepaymentsByDepositDateRangeQueryVariables
  >(GetRepaymentsByDepositDateRangeDocument, options);
}
export type GetRepaymentsByDepositDateRangeQueryHookResult = ReturnType<
  typeof useGetRepaymentsByDepositDateRangeQuery
>;
export type GetRepaymentsByDepositDateRangeLazyQueryHookResult = ReturnType<
  typeof useGetRepaymentsByDepositDateRangeLazyQuery
>;
export type GetRepaymentsByDepositDateRangeQueryResult = Apollo.QueryResult<
  GetRepaymentsByDepositDateRangeQuery,
  GetRepaymentsByDepositDateRangeQueryVariables
>;
export const GetSubmittedPaymentsDocument = gql`
  subscription GetSubmittedPayments {
    payments(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { type: { _in: ["repayment", "repayment_account_fee"] } }
          { submitted_at: { _is_null: false } }
          { settled_at: { _is_null: true } }
          { reversed_at: { _is_null: true } }
        ]
      }
      order_by: [{ requested_payment_date: asc }]
    ) {
      id
      ...RepaymentForBankDataGrid
    }
  }
  ${RepaymentForBankDataGridFragmentDoc}
`;

/**
 * __useGetSubmittedPaymentsSubscription__
 *
 * To run a query within a React component, call `useGetSubmittedPaymentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmittedPaymentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmittedPaymentsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetSubmittedPaymentsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetSubmittedPaymentsSubscription,
    GetSubmittedPaymentsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetSubmittedPaymentsSubscription,
    GetSubmittedPaymentsSubscriptionVariables
  >(GetSubmittedPaymentsDocument, options);
}
export type GetSubmittedPaymentsSubscriptionHookResult = ReturnType<
  typeof useGetSubmittedPaymentsSubscription
>;
export type GetSubmittedPaymentsSubscriptionResult =
  Apollo.SubscriptionResult<GetSubmittedPaymentsSubscription>;
export const GetRepaymentsForCompanyDocument = gql`
  query GetRepaymentsForCompany($company_id: uuid!) {
    companies_by_pk(id: $company_id) {
      id
      payments(
        where: {
          _and: [
            {
              _or: [
                { is_deleted: { _is_null: true } }
                { is_deleted: { _eq: false } }
              ]
            }
            { type: { _in: ["repayment", "repayment_account_fee"] } }
            {
              _or: [
                { reversed_at: { _is_null: false } }
                { settlement_date: { _is_null: false } }
              ]
            }
          ]
        }
        order_by: [{ deposit_date: desc }, { created_at: desc }]
      ) {
        id
        ...PaymentLimited
        transactions(
          order_by: [
            { payment: { deposit_date: desc } }
            { payment: { created_at: desc } }
          ]
        ) {
          id
          ...Transaction
          loan {
            id
            ...LoanLimited
            ...LoanArtifactLimited
          }
          payment {
            id
            ...PaymentLimited
          }
        }
      }
    }
  }
  ${PaymentLimitedFragmentDoc}
  ${TransactionFragmentDoc}
  ${LoanLimitedFragmentDoc}
  ${LoanArtifactLimitedFragmentDoc}
`;

/**
 * __useGetRepaymentsForCompanyQuery__
 *
 * To run a query within a React component, call `useGetRepaymentsForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepaymentsForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepaymentsForCompanyQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetRepaymentsForCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRepaymentsForCompanyQuery,
    GetRepaymentsForCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRepaymentsForCompanyQuery,
    GetRepaymentsForCompanyQueryVariables
  >(GetRepaymentsForCompanyDocument, options);
}
export function useGetRepaymentsForCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRepaymentsForCompanyQuery,
    GetRepaymentsForCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRepaymentsForCompanyQuery,
    GetRepaymentsForCompanyQueryVariables
  >(GetRepaymentsForCompanyDocument, options);
}
export type GetRepaymentsForCompanyQueryHookResult = ReturnType<
  typeof useGetRepaymentsForCompanyQuery
>;
export type GetRepaymentsForCompanyLazyQueryHookResult = ReturnType<
  typeof useGetRepaymentsForCompanyLazyQuery
>;
export type GetRepaymentsForCompanyQueryResult = Apollo.QueryResult<
  GetRepaymentsForCompanyQuery,
  GetRepaymentsForCompanyQueryVariables
>;
export const GetRepaymentsByMethodAndPaymentDateDocument = gql`
  query GetRepaymentsByMethodAndPaymentDate($method: String!, $date: date!) {
    payments(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { type: { _in: ["repayment", "repayment_account_fee"] } }
          { method: { _eq: $method } }
          { payment_date: { _eq: $date } }
        ]
      }
    ) {
      id
      ...Payment
      company_bank_account {
        id
        ...BankAccount
      }
      recipient_bank_account {
        id
        ...BankAccount
      }
    }
  }
  ${PaymentFragmentDoc}
  ${BankAccountFragmentDoc}
`;

/**
 * __useGetRepaymentsByMethodAndPaymentDateQuery__
 *
 * To run a query within a React component, call `useGetRepaymentsByMethodAndPaymentDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepaymentsByMethodAndPaymentDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepaymentsByMethodAndPaymentDateQuery({
 *   variables: {
 *      method: // value for 'method'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetRepaymentsByMethodAndPaymentDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRepaymentsByMethodAndPaymentDateQuery,
    GetRepaymentsByMethodAndPaymentDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRepaymentsByMethodAndPaymentDateQuery,
    GetRepaymentsByMethodAndPaymentDateQueryVariables
  >(GetRepaymentsByMethodAndPaymentDateDocument, options);
}
export function useGetRepaymentsByMethodAndPaymentDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRepaymentsByMethodAndPaymentDateQuery,
    GetRepaymentsByMethodAndPaymentDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRepaymentsByMethodAndPaymentDateQuery,
    GetRepaymentsByMethodAndPaymentDateQueryVariables
  >(GetRepaymentsByMethodAndPaymentDateDocument, options);
}
export type GetRepaymentsByMethodAndPaymentDateQueryHookResult = ReturnType<
  typeof useGetRepaymentsByMethodAndPaymentDateQuery
>;
export type GetRepaymentsByMethodAndPaymentDateLazyQueryHookResult = ReturnType<
  typeof useGetRepaymentsByMethodAndPaymentDateLazyQuery
>;
export type GetRepaymentsByMethodAndPaymentDateQueryResult = Apollo.QueryResult<
  GetRepaymentsByMethodAndPaymentDateQuery,
  GetRepaymentsByMethodAndPaymentDateQueryVariables
>;
export const GetMostRecentMonthlyCalculationsDocument = gql`
  query GetMostRecentMonthlyCalculations {
    companies(
      where: {
        _and: [
          { is_customer: { _eq: true } }
          {
            _or: [
              { settings: { is_dummy_account: { _is_null: true } } }
              { settings: { is_dummy_account: { _eq: false } } }
            ]
          }
          { financial_summaries: { product_type: { _is_null: false } } }
        ]
      }
      order_by: { name: asc }
    ) {
      id
      ...RecentMonthlyCalculations
    }
  }
  ${RecentMonthlyCalculationsFragmentDoc}
`;

/**
 * __useGetMostRecentMonthlyCalculationsQuery__
 *
 * To run a query within a React component, call `useGetMostRecentMonthlyCalculationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostRecentMonthlyCalculationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostRecentMonthlyCalculationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMostRecentMonthlyCalculationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMostRecentMonthlyCalculationsQuery,
    GetMostRecentMonthlyCalculationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMostRecentMonthlyCalculationsQuery,
    GetMostRecentMonthlyCalculationsQueryVariables
  >(GetMostRecentMonthlyCalculationsDocument, options);
}
export function useGetMostRecentMonthlyCalculationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMostRecentMonthlyCalculationsQuery,
    GetMostRecentMonthlyCalculationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMostRecentMonthlyCalculationsQuery,
    GetMostRecentMonthlyCalculationsQueryVariables
  >(GetMostRecentMonthlyCalculationsDocument, options);
}
export type GetMostRecentMonthlyCalculationsQueryHookResult = ReturnType<
  typeof useGetMostRecentMonthlyCalculationsQuery
>;
export type GetMostRecentMonthlyCalculationsLazyQueryHookResult = ReturnType<
  typeof useGetMostRecentMonthlyCalculationsLazyQuery
>;
export type GetMostRecentMonthlyCalculationsQueryResult = Apollo.QueryResult<
  GetMostRecentMonthlyCalculationsQuery,
  GetMostRecentMonthlyCalculationsQueryVariables
>;
export const LastMonthlySummaryReportLiveRunDocument = gql`
  query LastMonthlySummaryReportLiveRun {
    sync_pipelines(
      where: { name: { _eq: "monthly_summary_live_run" } }
      order_by: [{ created_at: desc }]
      limit: 1
    ) {
      id
      name
      internal_state
      params
      created_at
    }
  }
`;

/**
 * __useLastMonthlySummaryReportLiveRunQuery__
 *
 * To run a query within a React component, call `useLastMonthlySummaryReportLiveRunQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastMonthlySummaryReportLiveRunQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastMonthlySummaryReportLiveRunQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastMonthlySummaryReportLiveRunQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LastMonthlySummaryReportLiveRunQuery,
    LastMonthlySummaryReportLiveRunQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LastMonthlySummaryReportLiveRunQuery,
    LastMonthlySummaryReportLiveRunQueryVariables
  >(LastMonthlySummaryReportLiveRunDocument, options);
}
export function useLastMonthlySummaryReportLiveRunLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LastMonthlySummaryReportLiveRunQuery,
    LastMonthlySummaryReportLiveRunQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LastMonthlySummaryReportLiveRunQuery,
    LastMonthlySummaryReportLiveRunQueryVariables
  >(LastMonthlySummaryReportLiveRunDocument, options);
}
export type LastMonthlySummaryReportLiveRunQueryHookResult = ReturnType<
  typeof useLastMonthlySummaryReportLiveRunQuery
>;
export type LastMonthlySummaryReportLiveRunLazyQueryHookResult = ReturnType<
  typeof useLastMonthlySummaryReportLiveRunLazyQuery
>;
export type LastMonthlySummaryReportLiveRunQueryResult = Apollo.QueryResult<
  LastMonthlySummaryReportLiveRunQuery,
  LastMonthlySummaryReportLiveRunQueryVariables
>;
export const GetOpenAsyncJobsDocument = gql`
  subscription GetOpenAsyncJobs {
    async_jobs(
      order_by: [{ queued_at: desc }]
      where: {
        _or: [
          { is_deleted: { _is_null: true } }
          { is_deleted: { _eq: false } }
        ]
        status: { _neq: "completed" }
      }
    ) {
      id
      ...AsyncJob
    }
  }
  ${AsyncJobFragmentDoc}
`;

/**
 * __useGetOpenAsyncJobsSubscription__
 *
 * To run a query within a React component, call `useGetOpenAsyncJobsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenAsyncJobsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenAsyncJobsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetOpenAsyncJobsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetOpenAsyncJobsSubscription,
    GetOpenAsyncJobsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetOpenAsyncJobsSubscription,
    GetOpenAsyncJobsSubscriptionVariables
  >(GetOpenAsyncJobsDocument, options);
}
export type GetOpenAsyncJobsSubscriptionHookResult = ReturnType<
  typeof useGetOpenAsyncJobsSubscription
>;
export type GetOpenAsyncJobsSubscriptionResult =
  Apollo.SubscriptionResult<GetOpenAsyncJobsSubscription>;
export const GetCompletedAsyncJobsDocument = gql`
  query GetCompletedAsyncJobs {
    async_jobs(
      order_by: [{ ended_at: desc }]
      where: {
        _or: [
          { is_deleted: { _is_null: true } }
          { is_deleted: { _eq: false } }
        ]
        status: { _eq: "completed" }
      }
      limit: 500
    ) {
      id
      ...AsyncJob
    }
  }
  ${AsyncJobFragmentDoc}
`;

/**
 * __useGetCompletedAsyncJobsQuery__
 *
 * To run a query within a React component, call `useGetCompletedAsyncJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletedAsyncJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletedAsyncJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompletedAsyncJobsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompletedAsyncJobsQuery,
    GetCompletedAsyncJobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompletedAsyncJobsQuery,
    GetCompletedAsyncJobsQueryVariables
  >(GetCompletedAsyncJobsDocument, options);
}
export function useGetCompletedAsyncJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompletedAsyncJobsQuery,
    GetCompletedAsyncJobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompletedAsyncJobsQuery,
    GetCompletedAsyncJobsQueryVariables
  >(GetCompletedAsyncJobsDocument, options);
}
export type GetCompletedAsyncJobsQueryHookResult = ReturnType<
  typeof useGetCompletedAsyncJobsQuery
>;
export type GetCompletedAsyncJobsLazyQueryHookResult = ReturnType<
  typeof useGetCompletedAsyncJobsLazyQuery
>;
export type GetCompletedAsyncJobsQueryResult = Apollo.QueryResult<
  GetCompletedAsyncJobsQuery,
  GetCompletedAsyncJobsQueryVariables
>;
export const GetAsyncJobByIdDocument = gql`
  query GetAsyncJobById($id: uuid!) {
    async_jobs_by_pk(id: $id) {
      id
      ...AsyncJob
    }
  }
  ${AsyncJobFragmentDoc}
`;

/**
 * __useGetAsyncJobByIdQuery__
 *
 * To run a query within a React component, call `useGetAsyncJobByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAsyncJobByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAsyncJobByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAsyncJobByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAsyncJobByIdQuery,
    GetAsyncJobByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAsyncJobByIdQuery, GetAsyncJobByIdQueryVariables>(
    GetAsyncJobByIdDocument,
    options
  );
}
export function useGetAsyncJobByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAsyncJobByIdQuery,
    GetAsyncJobByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAsyncJobByIdQuery,
    GetAsyncJobByIdQueryVariables
  >(GetAsyncJobByIdDocument, options);
}
export type GetAsyncJobByIdQueryHookResult = ReturnType<
  typeof useGetAsyncJobByIdQuery
>;
export type GetAsyncJobByIdLazyQueryHookResult = ReturnType<
  typeof useGetAsyncJobByIdLazyQuery
>;
export type GetAsyncJobByIdQueryResult = Apollo.QueryResult<
  GetAsyncJobByIdQuery,
  GetAsyncJobByIdQueryVariables
>;
export const GetCompanySettingsDocument = gql`
  query GetCompanySettings($company_settings_id: uuid!) {
    company_settings_by_pk(id: $company_settings_id) {
      ...CompanySettings
    }
  }
  ${CompanySettingsFragmentDoc}
`;

/**
 * __useGetCompanySettingsQuery__
 *
 * To run a query within a React component, call `useGetCompanySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySettingsQuery({
 *   variables: {
 *      company_settings_id: // value for 'company_settings_id'
 *   },
 * });
 */
export function useGetCompanySettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanySettingsQuery,
    GetCompanySettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanySettingsQuery,
    GetCompanySettingsQueryVariables
  >(GetCompanySettingsDocument, options);
}
export function useGetCompanySettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanySettingsQuery,
    GetCompanySettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanySettingsQuery,
    GetCompanySettingsQueryVariables
  >(GetCompanySettingsDocument, options);
}
export type GetCompanySettingsQueryHookResult = ReturnType<
  typeof useGetCompanySettingsQuery
>;
export type GetCompanySettingsLazyQueryHookResult = ReturnType<
  typeof useGetCompanySettingsLazyQuery
>;
export type GetCompanySettingsQueryResult = Apollo.QueryResult<
  GetCompanySettingsQuery,
  GetCompanySettingsQueryVariables
>;
export const GetCompanySettingsByCompanyIdForCustomerDocument = gql`
  query GetCompanySettingsByCompanyIdForCustomer($company_id: uuid!) {
    company_settings(where: { company_id: { _eq: $company_id } }) {
      id
      ...CompanySettingsLimited
    }
  }
  ${CompanySettingsLimitedFragmentDoc}
`;

/**
 * __useGetCompanySettingsByCompanyIdForCustomerQuery__
 *
 * To run a query within a React component, call `useGetCompanySettingsByCompanyIdForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySettingsByCompanyIdForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySettingsByCompanyIdForCustomerQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetCompanySettingsByCompanyIdForCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanySettingsByCompanyIdForCustomerQuery,
    GetCompanySettingsByCompanyIdForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanySettingsByCompanyIdForCustomerQuery,
    GetCompanySettingsByCompanyIdForCustomerQueryVariables
  >(GetCompanySettingsByCompanyIdForCustomerDocument, options);
}
export function useGetCompanySettingsByCompanyIdForCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanySettingsByCompanyIdForCustomerQuery,
    GetCompanySettingsByCompanyIdForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanySettingsByCompanyIdForCustomerQuery,
    GetCompanySettingsByCompanyIdForCustomerQueryVariables
  >(GetCompanySettingsByCompanyIdForCustomerDocument, options);
}
export type GetCompanySettingsByCompanyIdForCustomerQueryHookResult =
  ReturnType<typeof useGetCompanySettingsByCompanyIdForCustomerQuery>;
export type GetCompanySettingsByCompanyIdForCustomerLazyQueryHookResult =
  ReturnType<typeof useGetCompanySettingsByCompanyIdForCustomerLazyQuery>;
export type GetCompanySettingsByCompanyIdForCustomerQueryResult =
  Apollo.QueryResult<
    GetCompanySettingsByCompanyIdForCustomerQuery,
    GetCompanySettingsByCompanyIdForCustomerQueryVariables
  >;
export const UpdateIsDummyAccountDocument = gql`
  mutation UpdateIsDummyAccount(
    $companySettingsId: uuid!
    $isDummyAccount: Boolean
  ) {
    update_company_settings_by_pk(
      pk_columns: { id: $companySettingsId }
      _set: { is_dummy_account: $isDummyAccount }
    ) {
      ...CompanySettings
    }
  }
  ${CompanySettingsFragmentDoc}
`;
export type UpdateIsDummyAccountMutationFn = Apollo.MutationFunction<
  UpdateIsDummyAccountMutation,
  UpdateIsDummyAccountMutationVariables
>;

/**
 * __useUpdateIsDummyAccountMutation__
 *
 * To run a mutation, you first call `useUpdateIsDummyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIsDummyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIsDummyAccountMutation, { data, loading, error }] = useUpdateIsDummyAccountMutation({
 *   variables: {
 *      companySettingsId: // value for 'companySettingsId'
 *      isDummyAccount: // value for 'isDummyAccount'
 *   },
 * });
 */
export function useUpdateIsDummyAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIsDummyAccountMutation,
    UpdateIsDummyAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateIsDummyAccountMutation,
    UpdateIsDummyAccountMutationVariables
  >(UpdateIsDummyAccountDocument, options);
}
export type UpdateIsDummyAccountMutationHookResult = ReturnType<
  typeof useUpdateIsDummyAccountMutation
>;
export type UpdateIsDummyAccountMutationResult =
  Apollo.MutationResult<UpdateIsDummyAccountMutation>;
export type UpdateIsDummyAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateIsDummyAccountMutation,
  UpdateIsDummyAccountMutationVariables
>;
export const UpdateCompanySettingsDocument = gql`
  mutation UpdateCompanySettings(
    $company_settings_id: uuid!
    $company_settings: company_settings_set_input!
  ) {
    update_company_settings_by_pk(
      pk_columns: { id: $company_settings_id }
      _set: $company_settings
    ) {
      ...CompanySettings
    }
  }
  ${CompanySettingsFragmentDoc}
`;
export type UpdateCompanySettingsMutationFn = Apollo.MutationFunction<
  UpdateCompanySettingsMutation,
  UpdateCompanySettingsMutationVariables
>;

/**
 * __useUpdateCompanySettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCompanySettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanySettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanySettingsMutation, { data, loading, error }] = useUpdateCompanySettingsMutation({
 *   variables: {
 *      company_settings_id: // value for 'company_settings_id'
 *      company_settings: // value for 'company_settings'
 *   },
 * });
 */
export function useUpdateCompanySettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanySettingsMutation,
    UpdateCompanySettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompanySettingsMutation,
    UpdateCompanySettingsMutationVariables
  >(UpdateCompanySettingsDocument, options);
}
export type UpdateCompanySettingsMutationHookResult = ReturnType<
  typeof useUpdateCompanySettingsMutation
>;
export type UpdateCompanySettingsMutationResult =
  Apollo.MutationResult<UpdateCompanySettingsMutation>;
export type UpdateCompanySettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanySettingsMutation,
  UpdateCompanySettingsMutationVariables
>;
export const GetContractDocument = gql`
  query GetContract($id: uuid!) {
    contracts_by_pk(id: $id) {
      id
      ...Contract
      company {
        id
        name
      }
    }
  }
  ${ContractFragmentDoc}
`;

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractQuery,
    GetContractQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContractQuery, GetContractQueryVariables>(
    GetContractDocument,
    options
  );
}
export function useGetContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractQuery,
    GetContractQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContractQuery, GetContractQueryVariables>(
    GetContractDocument,
    options
  );
}
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>;
export type GetContractLazyQueryHookResult = ReturnType<
  typeof useGetContractLazyQuery
>;
export type GetContractQueryResult = Apollo.QueryResult<
  GetContractQuery,
  GetContractQueryVariables
>;
export const GetUserDocument = gql`
  query GetUser($id: uuid!) {
    users_by_pk(id: $id) {
      id
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const GetUserByEmailDocument = gql`
  query GetUserByEmail($email: String!) {
    users(where: { email: { _eq: $email } }) {
      id
      ...User
      company {
        id
        name
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useGetUserByEmailQuery__
 *
 * To run a query within a React component, call `useGetUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserByEmailQuery,
    GetUserByEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(
    GetUserByEmailDocument,
    options
  );
}
export function useGetUserByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserByEmailQuery,
    GetUserByEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(
    GetUserByEmailDocument,
    options
  );
}
export type GetUserByEmailQueryHookResult = ReturnType<
  typeof useGetUserByEmailQuery
>;
export type GetUserByEmailLazyQueryHookResult = ReturnType<
  typeof useGetUserByEmailLazyQuery
>;
export type GetUserByEmailQueryResult = Apollo.QueryResult<
  GetUserByEmailQuery,
  GetUserByEmailQueryVariables
>;
export const GetActiveUsersByRolesDocument = gql`
  query GetActiveUsersByRoles(
    $roles: [user_roles_enum!]!
    $isBankUser: Boolean = false
  ) {
    users(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { role: { _in: $roles } }
        ]
      }
    ) {
      id
      ...UserWrapper
      company {
        id
        name
      }
    }
  }
  ${UserWrapperFragmentDoc}
`;

/**
 * __useGetActiveUsersByRolesQuery__
 *
 * To run a query within a React component, call `useGetActiveUsersByRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveUsersByRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveUsersByRolesQuery({
 *   variables: {
 *      roles: // value for 'roles'
 *      isBankUser: // value for 'isBankUser'
 *   },
 * });
 */
export function useGetActiveUsersByRolesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActiveUsersByRolesQuery,
    GetActiveUsersByRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveUsersByRolesQuery,
    GetActiveUsersByRolesQueryVariables
  >(GetActiveUsersByRolesDocument, options);
}
export function useGetActiveUsersByRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveUsersByRolesQuery,
    GetActiveUsersByRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveUsersByRolesQuery,
    GetActiveUsersByRolesQueryVariables
  >(GetActiveUsersByRolesDocument, options);
}
export type GetActiveUsersByRolesQueryHookResult = ReturnType<
  typeof useGetActiveUsersByRolesQuery
>;
export type GetActiveUsersByRolesLazyQueryHookResult = ReturnType<
  typeof useGetActiveUsersByRolesLazyQuery
>;
export type GetActiveUsersByRolesQueryResult = Apollo.QueryResult<
  GetActiveUsersByRolesQuery,
  GetActiveUsersByRolesQueryVariables
>;
export const GetActiveTeamMembersDocument = gql`
  query GetActiveTeamMembers {
    client_success_team_members: users(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { company_role: { _eq: "client_success" } }
        ]
      }
    ) {
      id
      full_name
    }
    business_development_team_members: users(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { company_role: { _eq: "business_development" } }
        ]
      }
    ) {
      id
      full_name
    }
    underwriting_team_members: users(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { company_role: { _eq: "underwriter" } }
        ]
      }
    ) {
      id
      full_name
    }
  }
`;

/**
 * __useGetActiveTeamMembersQuery__
 *
 * To run a query within a React component, call `useGetActiveTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveTeamMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveTeamMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveTeamMembersQuery,
    GetActiveTeamMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveTeamMembersQuery,
    GetActiveTeamMembersQueryVariables
  >(GetActiveTeamMembersDocument, options);
}
export function useGetActiveTeamMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveTeamMembersQuery,
    GetActiveTeamMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveTeamMembersQuery,
    GetActiveTeamMembersQueryVariables
  >(GetActiveTeamMembersDocument, options);
}
export type GetActiveTeamMembersQueryHookResult = ReturnType<
  typeof useGetActiveTeamMembersQuery
>;
export type GetActiveTeamMembersLazyQueryHookResult = ReturnType<
  typeof useGetActiveTeamMembersLazyQuery
>;
export type GetActiveTeamMembersQueryResult = Apollo.QueryResult<
  GetActiveTeamMembersQuery,
  GetActiveTeamMembersQueryVariables
>;
export const GetDeactivatedUsersByRolesDocument = gql`
  query GetDeactivatedUsersByRoles($roles: [user_roles_enum!]!) {
    users(
      where: {
        _and: [{ is_deleted: { _eq: true } }, { role: { _in: $roles } }]
      }
    ) {
      id
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useGetDeactivatedUsersByRolesQuery__
 *
 * To run a query within a React component, call `useGetDeactivatedUsersByRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeactivatedUsersByRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeactivatedUsersByRolesQuery({
 *   variables: {
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useGetDeactivatedUsersByRolesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDeactivatedUsersByRolesQuery,
    GetDeactivatedUsersByRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDeactivatedUsersByRolesQuery,
    GetDeactivatedUsersByRolesQueryVariables
  >(GetDeactivatedUsersByRolesDocument, options);
}
export function useGetDeactivatedUsersByRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeactivatedUsersByRolesQuery,
    GetDeactivatedUsersByRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDeactivatedUsersByRolesQuery,
    GetDeactivatedUsersByRolesQueryVariables
  >(GetDeactivatedUsersByRolesDocument, options);
}
export type GetDeactivatedUsersByRolesQueryHookResult = ReturnType<
  typeof useGetDeactivatedUsersByRolesQuery
>;
export type GetDeactivatedUsersByRolesLazyQueryHookResult = ReturnType<
  typeof useGetDeactivatedUsersByRolesLazyQuery
>;
export type GetDeactivatedUsersByRolesQueryResult = Apollo.QueryResult<
  GetDeactivatedUsersByRolesQuery,
  GetDeactivatedUsersByRolesQueryVariables
>;
export const GetUsersForCompanyDocument = gql`
  query GetUsersForCompany(
    $parent_company_id: uuid!
    $company_id: uuid!
    $isBankUser: Boolean = false
  ) {
    users(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          {
            _or: [
              { parent_company_id: { _eq: $parent_company_id } }
              { company_id: { _eq: $company_id } }
            ]
          }
        ]
      }
      order_by: { full_name: asc }
    ) {
      ...UserWrapper
    }
  }
  ${UserWrapperFragmentDoc}
`;

/**
 * __useGetUsersForCompanyQuery__
 *
 * To run a query within a React component, call `useGetUsersForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForCompanyQuery({
 *   variables: {
 *      parent_company_id: // value for 'parent_company_id'
 *      company_id: // value for 'company_id'
 *      isBankUser: // value for 'isBankUser'
 *   },
 * });
 */
export function useGetUsersForCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersForCompanyQuery,
    GetUsersForCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersForCompanyQuery,
    GetUsersForCompanyQueryVariables
  >(GetUsersForCompanyDocument, options);
}
export function useGetUsersForCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersForCompanyQuery,
    GetUsersForCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersForCompanyQuery,
    GetUsersForCompanyQueryVariables
  >(GetUsersForCompanyDocument, options);
}
export type GetUsersForCompanyQueryHookResult = ReturnType<
  typeof useGetUsersForCompanyQuery
>;
export type GetUsersForCompanyLazyQueryHookResult = ReturnType<
  typeof useGetUsersForCompanyLazyQuery
>;
export type GetUsersForCompanyQueryResult = Apollo.QueryResult<
  GetUsersForCompanyQuery,
  GetUsersForCompanyQueryVariables
>;
export const GetUsersForParentCompanyDocument = gql`
  query GetUsersForParentCompany(
    $parent_company_id: uuid!
    $isBankUser: Boolean = false
  ) {
    users(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { parent_company_id: { _eq: $parent_company_id } }
        ]
      }
      order_by: { full_name: asc }
    ) {
      ...UserWrapper
    }
  }
  ${UserWrapperFragmentDoc}
`;

/**
 * __useGetUsersForParentCompanyQuery__
 *
 * To run a query within a React component, call `useGetUsersForParentCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForParentCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForParentCompanyQuery({
 *   variables: {
 *      parent_company_id: // value for 'parent_company_id'
 *      isBankUser: // value for 'isBankUser'
 *   },
 * });
 */
export function useGetUsersForParentCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersForParentCompanyQuery,
    GetUsersForParentCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersForParentCompanyQuery,
    GetUsersForParentCompanyQueryVariables
  >(GetUsersForParentCompanyDocument, options);
}
export function useGetUsersForParentCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersForParentCompanyQuery,
    GetUsersForParentCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersForParentCompanyQuery,
    GetUsersForParentCompanyQueryVariables
  >(GetUsersForParentCompanyDocument, options);
}
export type GetUsersForParentCompanyQueryHookResult = ReturnType<
  typeof useGetUsersForParentCompanyQuery
>;
export type GetUsersForParentCompanyLazyQueryHookResult = ReturnType<
  typeof useGetUsersForParentCompanyLazyQuery
>;
export type GetUsersForParentCompanyQueryResult = Apollo.QueryResult<
  GetUsersForParentCompanyQuery,
  GetUsersForParentCompanyQueryVariables
>;
export const GetDeactivatedUsersForParentCompanyDocument = gql`
  query GetDeactivatedUsersForParentCompany($parent_company_id: uuid!) {
    users(
      where: {
        _and: [
          { is_deleted: { _eq: true } }
          { parent_company_id: { _eq: $parent_company_id } }
        ]
      }
      order_by: { full_name: asc }
    ) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useGetDeactivatedUsersForParentCompanyQuery__
 *
 * To run a query within a React component, call `useGetDeactivatedUsersForParentCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeactivatedUsersForParentCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeactivatedUsersForParentCompanyQuery({
 *   variables: {
 *      parent_company_id: // value for 'parent_company_id'
 *   },
 * });
 */
export function useGetDeactivatedUsersForParentCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDeactivatedUsersForParentCompanyQuery,
    GetDeactivatedUsersForParentCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDeactivatedUsersForParentCompanyQuery,
    GetDeactivatedUsersForParentCompanyQueryVariables
  >(GetDeactivatedUsersForParentCompanyDocument, options);
}
export function useGetDeactivatedUsersForParentCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeactivatedUsersForParentCompanyQuery,
    GetDeactivatedUsersForParentCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDeactivatedUsersForParentCompanyQuery,
    GetDeactivatedUsersForParentCompanyQueryVariables
  >(GetDeactivatedUsersForParentCompanyDocument, options);
}
export type GetDeactivatedUsersForParentCompanyQueryHookResult = ReturnType<
  typeof useGetDeactivatedUsersForParentCompanyQuery
>;
export type GetDeactivatedUsersForParentCompanyLazyQueryHookResult = ReturnType<
  typeof useGetDeactivatedUsersForParentCompanyLazyQuery
>;
export type GetDeactivatedUsersForParentCompanyQueryResult = Apollo.QueryResult<
  GetDeactivatedUsersForParentCompanyQuery,
  GetDeactivatedUsersForParentCompanyQueryVariables
>;
export const GetDeactivatedUsersForCompanyDocument = gql`
  query GetDeactivatedUsersForCompany(
    $parent_company_id: uuid!
    $company_id: uuid!
  ) {
    users(
      where: {
        _and: [
          { is_deleted: { _eq: true } }
          {
            _or: [
              { parent_company_id: { _eq: $parent_company_id } }
              { company_id: { _eq: $company_id } }
            ]
          }
        ]
      }
      order_by: { full_name: asc }
    ) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useGetDeactivatedUsersForCompanyQuery__
 *
 * To run a query within a React component, call `useGetDeactivatedUsersForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeactivatedUsersForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeactivatedUsersForCompanyQuery({
 *   variables: {
 *      parent_company_id: // value for 'parent_company_id'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetDeactivatedUsersForCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDeactivatedUsersForCompanyQuery,
    GetDeactivatedUsersForCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDeactivatedUsersForCompanyQuery,
    GetDeactivatedUsersForCompanyQueryVariables
  >(GetDeactivatedUsersForCompanyDocument, options);
}
export function useGetDeactivatedUsersForCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeactivatedUsersForCompanyQuery,
    GetDeactivatedUsersForCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDeactivatedUsersForCompanyQuery,
    GetDeactivatedUsersForCompanyQueryVariables
  >(GetDeactivatedUsersForCompanyDocument, options);
}
export type GetDeactivatedUsersForCompanyQueryHookResult = ReturnType<
  typeof useGetDeactivatedUsersForCompanyQuery
>;
export type GetDeactivatedUsersForCompanyLazyQueryHookResult = ReturnType<
  typeof useGetDeactivatedUsersForCompanyLazyQuery
>;
export type GetDeactivatedUsersForCompanyQueryResult = Apollo.QueryResult<
  GetDeactivatedUsersForCompanyQuery,
  GetDeactivatedUsersForCompanyQueryVariables
>;
export const AssignAdvancesBespokeBankAccountDocument = gql`
  mutation AssignAdvancesBespokeBankAccount(
    $companySettingsId: uuid!
    $bankAccountId: uuid
  ) {
    update_company_settings_by_pk(
      pk_columns: { id: $companySettingsId }
      _set: { advances_bespoke_bank_account_id: $bankAccountId }
    ) {
      id
      advances_bespoke_bank_account {
        ...BankAccount
      }
    }
  }
  ${BankAccountFragmentDoc}
`;
export type AssignAdvancesBespokeBankAccountMutationFn =
  Apollo.MutationFunction<
    AssignAdvancesBespokeBankAccountMutation,
    AssignAdvancesBespokeBankAccountMutationVariables
  >;

/**
 * __useAssignAdvancesBespokeBankAccountMutation__
 *
 * To run a mutation, you first call `useAssignAdvancesBespokeBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignAdvancesBespokeBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignAdvancesBespokeBankAccountMutation, { data, loading, error }] = useAssignAdvancesBespokeBankAccountMutation({
 *   variables: {
 *      companySettingsId: // value for 'companySettingsId'
 *      bankAccountId: // value for 'bankAccountId'
 *   },
 * });
 */
export function useAssignAdvancesBespokeBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignAdvancesBespokeBankAccountMutation,
    AssignAdvancesBespokeBankAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignAdvancesBespokeBankAccountMutation,
    AssignAdvancesBespokeBankAccountMutationVariables
  >(AssignAdvancesBespokeBankAccountDocument, options);
}
export type AssignAdvancesBespokeBankAccountMutationHookResult = ReturnType<
  typeof useAssignAdvancesBespokeBankAccountMutation
>;
export type AssignAdvancesBespokeBankAccountMutationResult =
  Apollo.MutationResult<AssignAdvancesBespokeBankAccountMutation>;
export type AssignAdvancesBespokeBankAccountMutationOptions =
  Apollo.BaseMutationOptions<
    AssignAdvancesBespokeBankAccountMutation,
    AssignAdvancesBespokeBankAccountMutationVariables
  >;
export const AssignCollectionsBespokeBankAccountDocument = gql`
  mutation AssignCollectionsBespokeBankAccount(
    $companySettingsId: uuid!
    $bankAccountId: uuid
  ) {
    update_company_settings_by_pk(
      pk_columns: { id: $companySettingsId }
      _set: { collections_bespoke_bank_account_id: $bankAccountId }
    ) {
      id
      collections_bespoke_bank_account {
        ...BankAccount
      }
    }
  }
  ${BankAccountFragmentDoc}
`;
export type AssignCollectionsBespokeBankAccountMutationFn =
  Apollo.MutationFunction<
    AssignCollectionsBespokeBankAccountMutation,
    AssignCollectionsBespokeBankAccountMutationVariables
  >;

/**
 * __useAssignCollectionsBespokeBankAccountMutation__
 *
 * To run a mutation, you first call `useAssignCollectionsBespokeBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCollectionsBespokeBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCollectionsBespokeBankAccountMutation, { data, loading, error }] = useAssignCollectionsBespokeBankAccountMutation({
 *   variables: {
 *      companySettingsId: // value for 'companySettingsId'
 *      bankAccountId: // value for 'bankAccountId'
 *   },
 * });
 */
export function useAssignCollectionsBespokeBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignCollectionsBespokeBankAccountMutation,
    AssignCollectionsBespokeBankAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignCollectionsBespokeBankAccountMutation,
    AssignCollectionsBespokeBankAccountMutationVariables
  >(AssignCollectionsBespokeBankAccountDocument, options);
}
export type AssignCollectionsBespokeBankAccountMutationHookResult = ReturnType<
  typeof useAssignCollectionsBespokeBankAccountMutation
>;
export type AssignCollectionsBespokeBankAccountMutationResult =
  Apollo.MutationResult<AssignCollectionsBespokeBankAccountMutation>;
export type AssignCollectionsBespokeBankAccountMutationOptions =
  Apollo.BaseMutationOptions<
    AssignCollectionsBespokeBankAccountMutation,
    AssignCollectionsBespokeBankAccountMutationVariables
  >;
export const AssignAdvancesBankAccountDocument = gql`
  mutation AssignAdvancesBankAccount(
    $companySettingsId: uuid!
    $bankAccountId: uuid
  ) {
    update_company_settings_by_pk(
      pk_columns: { id: $companySettingsId }
      _set: { advances_bank_account_id: $bankAccountId }
    ) {
      id
      advances_bank_account {
        ...BankAccountLimited
      }
    }
  }
  ${BankAccountLimitedFragmentDoc}
`;
export type AssignAdvancesBankAccountMutationFn = Apollo.MutationFunction<
  AssignAdvancesBankAccountMutation,
  AssignAdvancesBankAccountMutationVariables
>;

/**
 * __useAssignAdvancesBankAccountMutation__
 *
 * To run a mutation, you first call `useAssignAdvancesBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignAdvancesBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignAdvancesBankAccountMutation, { data, loading, error }] = useAssignAdvancesBankAccountMutation({
 *   variables: {
 *      companySettingsId: // value for 'companySettingsId'
 *      bankAccountId: // value for 'bankAccountId'
 *   },
 * });
 */
export function useAssignAdvancesBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignAdvancesBankAccountMutation,
    AssignAdvancesBankAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignAdvancesBankAccountMutation,
    AssignAdvancesBankAccountMutationVariables
  >(AssignAdvancesBankAccountDocument, options);
}
export type AssignAdvancesBankAccountMutationHookResult = ReturnType<
  typeof useAssignAdvancesBankAccountMutation
>;
export type AssignAdvancesBankAccountMutationResult =
  Apollo.MutationResult<AssignAdvancesBankAccountMutation>;
export type AssignAdvancesBankAccountMutationOptions =
  Apollo.BaseMutationOptions<
    AssignAdvancesBankAccountMutation,
    AssignAdvancesBankAccountMutationVariables
  >;
export const AssignCollectionsBankAccountDocument = gql`
  mutation AssignCollectionsBankAccount(
    $companySettingsId: uuid!
    $bankAccountId: uuid
  ) {
    update_company_settings_by_pk(
      pk_columns: { id: $companySettingsId }
      _set: { collections_bank_account_id: $bankAccountId }
    ) {
      id
      collections_bank_account {
        ...BankAccountLimited
      }
    }
  }
  ${BankAccountLimitedFragmentDoc}
`;
export type AssignCollectionsBankAccountMutationFn = Apollo.MutationFunction<
  AssignCollectionsBankAccountMutation,
  AssignCollectionsBankAccountMutationVariables
>;

/**
 * __useAssignCollectionsBankAccountMutation__
 *
 * To run a mutation, you first call `useAssignCollectionsBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCollectionsBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCollectionsBankAccountMutation, { data, loading, error }] = useAssignCollectionsBankAccountMutation({
 *   variables: {
 *      companySettingsId: // value for 'companySettingsId'
 *      bankAccountId: // value for 'bankAccountId'
 *   },
 * });
 */
export function useAssignCollectionsBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignCollectionsBankAccountMutation,
    AssignCollectionsBankAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignCollectionsBankAccountMutation,
    AssignCollectionsBankAccountMutationVariables
  >(AssignCollectionsBankAccountDocument, options);
}
export type AssignCollectionsBankAccountMutationHookResult = ReturnType<
  typeof useAssignCollectionsBankAccountMutation
>;
export type AssignCollectionsBankAccountMutationResult =
  Apollo.MutationResult<AssignCollectionsBankAccountMutation>;
export type AssignCollectionsBankAccountMutationOptions =
  Apollo.BaseMutationOptions<
    AssignCollectionsBankAccountMutation,
    AssignCollectionsBankAccountMutationVariables
  >;
export const GetCompaniesWithLicensesDocument = gql`
  query GetCompaniesWithLicenses {
    companies: companies(order_by: { name: asc }) {
      id
      ...CompanyLimited
      licenses(
        where: {
          _or: [
            { is_deleted: { _is_null: true } }
            { is_deleted: { _eq: false } }
          ]
        }
      ) {
        id
        license_number
      }
    }
  }
  ${CompanyLimitedFragmentDoc}
`;

/**
 * __useGetCompaniesWithLicensesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesWithLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesWithLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesWithLicensesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompaniesWithLicensesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompaniesWithLicensesQuery,
    GetCompaniesWithLicensesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompaniesWithLicensesQuery,
    GetCompaniesWithLicensesQueryVariables
  >(GetCompaniesWithLicensesDocument, options);
}
export function useGetCompaniesWithLicensesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompaniesWithLicensesQuery,
    GetCompaniesWithLicensesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompaniesWithLicensesQuery,
    GetCompaniesWithLicensesQueryVariables
  >(GetCompaniesWithLicensesDocument, options);
}
export type GetCompaniesWithLicensesQueryHookResult = ReturnType<
  typeof useGetCompaniesWithLicensesQuery
>;
export type GetCompaniesWithLicensesLazyQueryHookResult = ReturnType<
  typeof useGetCompaniesWithLicensesLazyQuery
>;
export type GetCompaniesWithLicensesQueryResult = Apollo.QueryResult<
  GetCompaniesWithLicensesQuery,
  GetCompaniesWithLicensesQueryVariables
>;
export const GetParentCompanyWithCustomerCompaniesDocument = gql`
  query GetParentCompanyWithCustomerCompanies(
    $parent_company_id: uuid!
    $date: date!
  ) {
    parent_companies_by_pk(id: $parent_company_id) {
      id
      customer_companies: companies(
        where: { is_customer: { _eq: true } }
        order_by: { name: asc }
      ) {
        id
        name
        contract {
          id
          ...Contract
        }
        financial_summaries(where: { date: { _eq: $date } }) {
          id
          ...FinancialSummary
        }
      }
    }
  }
  ${ContractFragmentDoc}
  ${FinancialSummaryFragmentDoc}
`;

/**
 * __useGetParentCompanyWithCustomerCompaniesQuery__
 *
 * To run a query within a React component, call `useGetParentCompanyWithCustomerCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentCompanyWithCustomerCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentCompanyWithCustomerCompaniesQuery({
 *   variables: {
 *      parent_company_id: // value for 'parent_company_id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetParentCompanyWithCustomerCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParentCompanyWithCustomerCompaniesQuery,
    GetParentCompanyWithCustomerCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetParentCompanyWithCustomerCompaniesQuery,
    GetParentCompanyWithCustomerCompaniesQueryVariables
  >(GetParentCompanyWithCustomerCompaniesDocument, options);
}
export function useGetParentCompanyWithCustomerCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParentCompanyWithCustomerCompaniesQuery,
    GetParentCompanyWithCustomerCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParentCompanyWithCustomerCompaniesQuery,
    GetParentCompanyWithCustomerCompaniesQueryVariables
  >(GetParentCompanyWithCustomerCompaniesDocument, options);
}
export type GetParentCompanyWithCustomerCompaniesQueryHookResult = ReturnType<
  typeof useGetParentCompanyWithCustomerCompaniesQuery
>;
export type GetParentCompanyWithCustomerCompaniesLazyQueryHookResult =
  ReturnType<typeof useGetParentCompanyWithCustomerCompaniesLazyQuery>;
export type GetParentCompanyWithCustomerCompaniesQueryResult =
  Apollo.QueryResult<
    GetParentCompanyWithCustomerCompaniesQuery,
    GetParentCompanyWithCustomerCompaniesQueryVariables
  >;
export const GetParentCompanyWithChildCompaniesDocument = gql`
  query GetParentCompanyWithChildCompanies($parent_company_id: uuid!) {
    parent_companies_by_pk(id: $parent_company_id) {
      id
      child_companies: companies(order_by: { name: asc }) {
        id
        ...CompanyWithSettings
      }
    }
  }
  ${CompanyWithSettingsFragmentDoc}
`;

/**
 * __useGetParentCompanyWithChildCompaniesQuery__
 *
 * To run a query within a React component, call `useGetParentCompanyWithChildCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentCompanyWithChildCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentCompanyWithChildCompaniesQuery({
 *   variables: {
 *      parent_company_id: // value for 'parent_company_id'
 *   },
 * });
 */
export function useGetParentCompanyWithChildCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParentCompanyWithChildCompaniesQuery,
    GetParentCompanyWithChildCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetParentCompanyWithChildCompaniesQuery,
    GetParentCompanyWithChildCompaniesQueryVariables
  >(GetParentCompanyWithChildCompaniesDocument, options);
}
export function useGetParentCompanyWithChildCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParentCompanyWithChildCompaniesQuery,
    GetParentCompanyWithChildCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParentCompanyWithChildCompaniesQuery,
    GetParentCompanyWithChildCompaniesQueryVariables
  >(GetParentCompanyWithChildCompaniesDocument, options);
}
export type GetParentCompanyWithChildCompaniesQueryHookResult = ReturnType<
  typeof useGetParentCompanyWithChildCompaniesQuery
>;
export type GetParentCompanyWithChildCompaniesLazyQueryHookResult = ReturnType<
  typeof useGetParentCompanyWithChildCompaniesLazyQuery
>;
export type GetParentCompanyWithChildCompaniesQueryResult = Apollo.QueryResult<
  GetParentCompanyWithChildCompaniesQuery,
  GetParentCompanyWithChildCompaniesQueryVariables
>;
export const GetParentCompanyWithSettingsDocument = gql`
  query GetParentCompanyWithSettings($parent_company_id: uuid!) {
    parent_companies_by_pk(id: $parent_company_id) {
      id
      name
      settings
    }
  }
`;

/**
 * __useGetParentCompanyWithSettingsQuery__
 *
 * To run a query within a React component, call `useGetParentCompanyWithSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentCompanyWithSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentCompanyWithSettingsQuery({
 *   variables: {
 *      parent_company_id: // value for 'parent_company_id'
 *   },
 * });
 */
export function useGetParentCompanyWithSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParentCompanyWithSettingsQuery,
    GetParentCompanyWithSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetParentCompanyWithSettingsQuery,
    GetParentCompanyWithSettingsQueryVariables
  >(GetParentCompanyWithSettingsDocument, options);
}
export function useGetParentCompanyWithSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParentCompanyWithSettingsQuery,
    GetParentCompanyWithSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParentCompanyWithSettingsQuery,
    GetParentCompanyWithSettingsQueryVariables
  >(GetParentCompanyWithSettingsDocument, options);
}
export type GetParentCompanyWithSettingsQueryHookResult = ReturnType<
  typeof useGetParentCompanyWithSettingsQuery
>;
export type GetParentCompanyWithSettingsLazyQueryHookResult = ReturnType<
  typeof useGetParentCompanyWithSettingsLazyQuery
>;
export type GetParentCompanyWithSettingsQueryResult = Apollo.QueryResult<
  GetParentCompanyWithSettingsQuery,
  GetParentCompanyWithSettingsQueryVariables
>;
export const GetAllCompaniesWithMetadataDocument = gql`
  query GetAllCompaniesWithMetadata($date: date) {
    companies: companies(order_by: { name: asc }) {
      ...CustomersWithMetadata
    }
  }
  ${CustomersWithMetadataFragmentDoc}
`;

/**
 * __useGetAllCompaniesWithMetadataQuery__
 *
 * To run a query within a React component, call `useGetAllCompaniesWithMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompaniesWithMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompaniesWithMetadataQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetAllCompaniesWithMetadataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllCompaniesWithMetadataQuery,
    GetAllCompaniesWithMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCompaniesWithMetadataQuery,
    GetAllCompaniesWithMetadataQueryVariables
  >(GetAllCompaniesWithMetadataDocument, options);
}
export function useGetAllCompaniesWithMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCompaniesWithMetadataQuery,
    GetAllCompaniesWithMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCompaniesWithMetadataQuery,
    GetAllCompaniesWithMetadataQueryVariables
  >(GetAllCompaniesWithMetadataDocument, options);
}
export type GetAllCompaniesWithMetadataQueryHookResult = ReturnType<
  typeof useGetAllCompaniesWithMetadataQuery
>;
export type GetAllCompaniesWithMetadataLazyQueryHookResult = ReturnType<
  typeof useGetAllCompaniesWithMetadataLazyQuery
>;
export type GetAllCompaniesWithMetadataQueryResult = Apollo.QueryResult<
  GetAllCompaniesWithMetadataQuery,
  GetAllCompaniesWithMetadataQueryVariables
>;
export const GetParentCompaniesDocument = gql`
  query GetParentCompanies {
    parent_companies(order_by: { name: asc }) {
      id
      ...ParentCompany
    }
  }
  ${ParentCompanyFragmentDoc}
`;

/**
 * __useGetParentCompaniesQuery__
 *
 * To run a query within a React component, call `useGetParentCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetParentCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetParentCompaniesQuery,
    GetParentCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetParentCompaniesQuery,
    GetParentCompaniesQueryVariables
  >(GetParentCompaniesDocument, options);
}
export function useGetParentCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParentCompaniesQuery,
    GetParentCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParentCompaniesQuery,
    GetParentCompaniesQueryVariables
  >(GetParentCompaniesDocument, options);
}
export type GetParentCompaniesQueryHookResult = ReturnType<
  typeof useGetParentCompaniesQuery
>;
export type GetParentCompaniesLazyQueryHookResult = ReturnType<
  typeof useGetParentCompaniesLazyQuery
>;
export type GetParentCompaniesQueryResult = Apollo.QueryResult<
  GetParentCompaniesQuery,
  GetParentCompaniesQueryVariables
>;
export const GetBankFinancialSummariesByDateDocument = gql`
  subscription GetBankFinancialSummariesByDate($date: date!) {
    bank_financial_summaries(
      where: { date: { _eq: $date } }
      order_by: { total_outstanding_principal: desc }
    ) {
      id
      ...BankFinancialSummary
    }
  }
  ${BankFinancialSummaryFragmentDoc}
`;

/**
 * __useGetBankFinancialSummariesByDateSubscription__
 *
 * To run a query within a React component, call `useGetBankFinancialSummariesByDateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetBankFinancialSummariesByDateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankFinancialSummariesByDateSubscription({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetBankFinancialSummariesByDateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetBankFinancialSummariesByDateSubscription,
    GetBankFinancialSummariesByDateSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetBankFinancialSummariesByDateSubscription,
    GetBankFinancialSummariesByDateSubscriptionVariables
  >(GetBankFinancialSummariesByDateDocument, options);
}
export type GetBankFinancialSummariesByDateSubscriptionHookResult = ReturnType<
  typeof useGetBankFinancialSummariesByDateSubscription
>;
export type GetBankFinancialSummariesByDateSubscriptionResult =
  Apollo.SubscriptionResult<GetBankFinancialSummariesByDateSubscription>;
export const GetLoansCountForBankDocument = gql`
  subscription GetLoansCountForBank {
    loans(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { funded_at: { _is_null: true } }
          { closed_at: { _is_null: true } }
          { status: { _neq: "archived" } }
        ]
      }
      order_by: { requested_payment_date: asc }
    ) {
      id
    }
  }
`;

/**
 * __useGetLoansCountForBankSubscription__
 *
 * To run a query within a React component, call `useGetLoansCountForBankSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetLoansCountForBankSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoansCountForBankSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetLoansCountForBankSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetLoansCountForBankSubscription,
    GetLoansCountForBankSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetLoansCountForBankSubscription,
    GetLoansCountForBankSubscriptionVariables
  >(GetLoansCountForBankDocument, options);
}
export type GetLoansCountForBankSubscriptionHookResult = ReturnType<
  typeof useGetLoansCountForBankSubscription
>;
export type GetLoansCountForBankSubscriptionResult =
  Apollo.SubscriptionResult<GetLoansCountForBankSubscription>;
export const GetRepaymentsCountForBankDocument = gql`
  subscription GetRepaymentsCountForBank {
    payments(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { type: { _in: ["repayment", "repayment_account_fee"] } }
          { method: { _eq: "reverse_draft_ach" } }
          { payment_date: { _is_null: true } }
        ]
      }
      order_by: { created_at: desc }
    ) {
      id
    }
  }
`;

/**
 * __useGetRepaymentsCountForBankSubscription__
 *
 * To run a query within a React component, call `useGetRepaymentsCountForBankSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetRepaymentsCountForBankSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepaymentsCountForBankSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetRepaymentsCountForBankSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetRepaymentsCountForBankSubscription,
    GetRepaymentsCountForBankSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetRepaymentsCountForBankSubscription,
    GetRepaymentsCountForBankSubscriptionVariables
  >(GetRepaymentsCountForBankDocument, options);
}
export type GetRepaymentsCountForBankSubscriptionHookResult = ReturnType<
  typeof useGetRepaymentsCountForBankSubscription
>;
export type GetRepaymentsCountForBankSubscriptionResult =
  Apollo.SubscriptionResult<GetRepaymentsCountForBankSubscription>;
export const GetEbbaApplicationsCountForBankDocument = gql`
  subscription GetEbbaApplicationsCountForBank {
    ebba_applications(
      where: {
        _and: [
          {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
          { application_date: { _is_null: false } }
          { approved_at: { _is_null: true } }
        ]
      }
    ) {
      id
    }
  }
`;

/**
 * __useGetEbbaApplicationsCountForBankSubscription__
 *
 * To run a query within a React component, call `useGetEbbaApplicationsCountForBankSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetEbbaApplicationsCountForBankSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEbbaApplicationsCountForBankSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetEbbaApplicationsCountForBankSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetEbbaApplicationsCountForBankSubscription,
    GetEbbaApplicationsCountForBankSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetEbbaApplicationsCountForBankSubscription,
    GetEbbaApplicationsCountForBankSubscriptionVariables
  >(GetEbbaApplicationsCountForBankDocument, options);
}
export type GetEbbaApplicationsCountForBankSubscriptionHookResult = ReturnType<
  typeof useGetEbbaApplicationsCountForBankSubscription
>;
export type GetEbbaApplicationsCountForBankSubscriptionResult =
  Apollo.SubscriptionResult<GetEbbaApplicationsCountForBankSubscription>;
export const GetCompanyWithActiveContractDocument = gql`
  query GetCompanyWithActiveContract($companyId: uuid!) {
    companies_by_pk(id: $companyId) {
      id
      contract {
        id
        ...Contract
      }
    }
  }
  ${ContractFragmentDoc}
`;

/**
 * __useGetCompanyWithActiveContractQuery__
 *
 * To run a query within a React component, call `useGetCompanyWithActiveContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyWithActiveContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyWithActiveContractQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyWithActiveContractQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyWithActiveContractQuery,
    GetCompanyWithActiveContractQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyWithActiveContractQuery,
    GetCompanyWithActiveContractQueryVariables
  >(GetCompanyWithActiveContractDocument, options);
}
export function useGetCompanyWithActiveContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyWithActiveContractQuery,
    GetCompanyWithActiveContractQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyWithActiveContractQuery,
    GetCompanyWithActiveContractQueryVariables
  >(GetCompanyWithActiveContractDocument, options);
}
export type GetCompanyWithActiveContractQueryHookResult = ReturnType<
  typeof useGetCompanyWithActiveContractQuery
>;
export type GetCompanyWithActiveContractLazyQueryHookResult = ReturnType<
  typeof useGetCompanyWithActiveContractLazyQuery
>;
export type GetCompanyWithActiveContractQueryResult = Apollo.QueryResult<
  GetCompanyWithActiveContractQuery,
  GetCompanyWithActiveContractQueryVariables
>;
export const GetCompanyEbbaApplicationsInfoDocument = gql`
  query GetCompanyEbbaApplicationsInfo($companyId: uuid!) {
    companies_by_pk(id: $companyId) {
      id
      ebba_applications(
        where: {
          _or: [
            { is_deleted: { _is_null: true } }
            { is_deleted: { _eq: false } }
          ]
        }
        order_by: [{ application_date: desc }, { created_at: desc }]
      ) {
        id
        ...EbbaApplication
        company {
          id
          name
        }
        ebba_application_files {
          ebba_application_id
          file_id
        }
      }
      settings {
        id
        active_ebba_application {
          id
          ...EbbaApplication
        }
        active_borrowing_base {
          id
          ...EbbaApplication
        }
        active_financial_report {
          id
          ...EbbaApplication
        }
      }
      financial_summaries {
        id
        product_type
      }
    }
  }
  ${EbbaApplicationFragmentDoc}
`;

/**
 * __useGetCompanyEbbaApplicationsInfoQuery__
 *
 * To run a query within a React component, call `useGetCompanyEbbaApplicationsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyEbbaApplicationsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyEbbaApplicationsInfoQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyEbbaApplicationsInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyEbbaApplicationsInfoQuery,
    GetCompanyEbbaApplicationsInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyEbbaApplicationsInfoQuery,
    GetCompanyEbbaApplicationsInfoQueryVariables
  >(GetCompanyEbbaApplicationsInfoDocument, options);
}
export function useGetCompanyEbbaApplicationsInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyEbbaApplicationsInfoQuery,
    GetCompanyEbbaApplicationsInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyEbbaApplicationsInfoQuery,
    GetCompanyEbbaApplicationsInfoQueryVariables
  >(GetCompanyEbbaApplicationsInfoDocument, options);
}
export type GetCompanyEbbaApplicationsInfoQueryHookResult = ReturnType<
  typeof useGetCompanyEbbaApplicationsInfoQuery
>;
export type GetCompanyEbbaApplicationsInfoLazyQueryHookResult = ReturnType<
  typeof useGetCompanyEbbaApplicationsInfoLazyQuery
>;
export type GetCompanyEbbaApplicationsInfoQueryResult = Apollo.QueryResult<
  GetCompanyEbbaApplicationsInfoQuery,
  GetCompanyEbbaApplicationsInfoQueryVariables
>;
export const GetCompanyForCustomerContractPageDocument = gql`
  query GetCompanyForCustomerContractPage($companyId: uuid!) {
    companies_by_pk(id: $companyId) {
      id
      contract {
        id
        ...Contract
      }
      contracts(
        order_by: [{ adjusted_end_date: desc }]
        where: {
          _or: [
            { is_deleted: { _is_null: true } }
            { is_deleted: { _eq: false } }
          ]
        }
      ) {
        id
        ...Contract
      }
    }
  }
  ${ContractFragmentDoc}
`;

/**
 * __useGetCompanyForCustomerContractPageQuery__
 *
 * To run a query within a React component, call `useGetCompanyForCustomerContractPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyForCustomerContractPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyForCustomerContractPageQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyForCustomerContractPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyForCustomerContractPageQuery,
    GetCompanyForCustomerContractPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyForCustomerContractPageQuery,
    GetCompanyForCustomerContractPageQueryVariables
  >(GetCompanyForCustomerContractPageDocument, options);
}
export function useGetCompanyForCustomerContractPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyForCustomerContractPageQuery,
    GetCompanyForCustomerContractPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyForCustomerContractPageQuery,
    GetCompanyForCustomerContractPageQueryVariables
  >(GetCompanyForCustomerContractPageDocument, options);
}
export type GetCompanyForCustomerContractPageQueryHookResult = ReturnType<
  typeof useGetCompanyForCustomerContractPageQuery
>;
export type GetCompanyForCustomerContractPageLazyQueryHookResult = ReturnType<
  typeof useGetCompanyForCustomerContractPageLazyQuery
>;
export type GetCompanyForCustomerContractPageQueryResult = Apollo.QueryResult<
  GetCompanyForCustomerContractPageQuery,
  GetCompanyForCustomerContractPageQueryVariables
>;
export const GetCompanyWithDetailsByCompanyIdDocument = gql`
  query GetCompanyWithDetailsByCompanyId($companyId: uuid!) {
    companies_by_pk(id: $companyId) {
      id
      name
      contract {
        id
        ...Contract
      }
    }
  }
  ${ContractFragmentDoc}
`;

/**
 * __useGetCompanyWithDetailsByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyWithDetailsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyWithDetailsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyWithDetailsByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyWithDetailsByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyWithDetailsByCompanyIdQuery,
    GetCompanyWithDetailsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyWithDetailsByCompanyIdQuery,
    GetCompanyWithDetailsByCompanyIdQueryVariables
  >(GetCompanyWithDetailsByCompanyIdDocument, options);
}
export function useGetCompanyWithDetailsByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyWithDetailsByCompanyIdQuery,
    GetCompanyWithDetailsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyWithDetailsByCompanyIdQuery,
    GetCompanyWithDetailsByCompanyIdQueryVariables
  >(GetCompanyWithDetailsByCompanyIdDocument, options);
}
export type GetCompanyWithDetailsByCompanyIdQueryHookResult = ReturnType<
  typeof useGetCompanyWithDetailsByCompanyIdQuery
>;
export type GetCompanyWithDetailsByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetCompanyWithDetailsByCompanyIdLazyQuery
>;
export type GetCompanyWithDetailsByCompanyIdQueryResult = Apollo.QueryResult<
  GetCompanyWithDetailsByCompanyIdQuery,
  GetCompanyWithDetailsByCompanyIdQueryVariables
>;
export const GetCompanyNextLoanIdentifierDocument = gql`
  mutation GetCompanyNextLoanIdentifier(
    $companyId: uuid!
    $increment: companies_inc_input!
  ) {
    update_companies_by_pk(pk_columns: { id: $companyId }, _inc: $increment) {
      id
      latest_loan_identifier
    }
  }
`;
export type GetCompanyNextLoanIdentifierMutationFn = Apollo.MutationFunction<
  GetCompanyNextLoanIdentifierMutation,
  GetCompanyNextLoanIdentifierMutationVariables
>;

/**
 * __useGetCompanyNextLoanIdentifierMutation__
 *
 * To run a mutation, you first call `useGetCompanyNextLoanIdentifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyNextLoanIdentifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCompanyNextLoanIdentifierMutation, { data, loading, error }] = useGetCompanyNextLoanIdentifierMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      increment: // value for 'increment'
 *   },
 * });
 */
export function useGetCompanyNextLoanIdentifierMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetCompanyNextLoanIdentifierMutation,
    GetCompanyNextLoanIdentifierMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetCompanyNextLoanIdentifierMutation,
    GetCompanyNextLoanIdentifierMutationVariables
  >(GetCompanyNextLoanIdentifierDocument, options);
}
export type GetCompanyNextLoanIdentifierMutationHookResult = ReturnType<
  typeof useGetCompanyNextLoanIdentifierMutation
>;
export type GetCompanyNextLoanIdentifierMutationResult =
  Apollo.MutationResult<GetCompanyNextLoanIdentifierMutation>;
export type GetCompanyNextLoanIdentifierMutationOptions =
  Apollo.BaseMutationOptions<
    GetCompanyNextLoanIdentifierMutation,
    GetCompanyNextLoanIdentifierMutationVariables
  >;
export const GetCompanyForBankDocument = gql`
  query GetCompanyForBank($companyId: uuid!) {
    companies_by_pk(id: $companyId) {
      id
      ...Company
      bank_accounts(
        where: {
          _or: [
            { is_deleted: { _is_null: true } }
            { is_deleted: { _eq: false } }
          ]
        }
      ) {
        id
        ...BankAccount
      }
      contract {
        id
        ...Contract
      }
      licenses(
        where: {
          _or: [
            { is_deleted: { _is_null: true } }
            { is_deleted: { _eq: false } }
          ]
        }
        order_by: { license_number: asc }
      ) {
        id
        company_facility {
          id
          name
        }
        ...CompanyLicense
      }
      most_recent_financial_summary: financial_summaries(
        limit: 1
        order_by: { date: desc }
      ) {
        id
        ...FinancialSummary
      }
      settings {
        ...CompanyDealOwners
        ...CompanySettings
        advances_bespoke_bank_account {
          id
          ...BankAccount
        }
        collections_bespoke_bank_account {
          id
          ...BankAccount
        }
        advances_bank_account {
          id
          ...BankAccountLimited
        }
        collections_bank_account {
          id
          ...BankAccountLimited
        }
      }
      users {
        id
        ...User
      }
    }
  }
  ${CompanyFragmentDoc}
  ${BankAccountFragmentDoc}
  ${ContractFragmentDoc}
  ${CompanyLicenseFragmentDoc}
  ${FinancialSummaryFragmentDoc}
  ${CompanyDealOwnersFragmentDoc}
  ${CompanySettingsFragmentDoc}
  ${BankAccountLimitedFragmentDoc}
  ${UserFragmentDoc}
`;

/**
 * __useGetCompanyForBankQuery__
 *
 * To run a query within a React component, call `useGetCompanyForBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyForBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyForBankQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyForBankQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyForBankQuery,
    GetCompanyForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyForBankQuery,
    GetCompanyForBankQueryVariables
  >(GetCompanyForBankDocument, options);
}
export function useGetCompanyForBankLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyForBankQuery,
    GetCompanyForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyForBankQuery,
    GetCompanyForBankQueryVariables
  >(GetCompanyForBankDocument, options);
}
export type GetCompanyForBankQueryHookResult = ReturnType<
  typeof useGetCompanyForBankQuery
>;
export type GetCompanyForBankLazyQueryHookResult = ReturnType<
  typeof useGetCompanyForBankLazyQuery
>;
export type GetCompanyForBankQueryResult = Apollo.QueryResult<
  GetCompanyForBankQuery,
  GetCompanyForBankQueryVariables
>;
export const GetCompanyForCustomerDocument = gql`
  query GetCompanyForCustomer($companyId: uuid!) {
    companies_by_pk(id: $companyId) {
      id
      ...Company
      bank_accounts {
        id
        ...BankAccountLimited
      }
      contract {
        id
        ...Contract
      }
      settings {
        id
        ...CompanySettingsLimited
        advances_bank_account {
          id
          ...BankAccountLimited
        }
        collections_bank_account {
          id
          ...BankAccountLimited
        }
      }
      users {
        id
        ...User
      }
    }
  }
  ${CompanyFragmentDoc}
  ${BankAccountLimitedFragmentDoc}
  ${ContractFragmentDoc}
  ${CompanySettingsLimitedFragmentDoc}
  ${UserFragmentDoc}
`;

/**
 * __useGetCompanyForCustomerQuery__
 *
 * To run a query within a React component, call `useGetCompanyForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyForCustomerQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyForCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyForCustomerQuery,
    GetCompanyForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyForCustomerQuery,
    GetCompanyForCustomerQueryVariables
  >(GetCompanyForCustomerDocument, options);
}
export function useGetCompanyForCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyForCustomerQuery,
    GetCompanyForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyForCustomerQuery,
    GetCompanyForCustomerQueryVariables
  >(GetCompanyForCustomerDocument, options);
}
export type GetCompanyForCustomerQueryHookResult = ReturnType<
  typeof useGetCompanyForCustomerQuery
>;
export type GetCompanyForCustomerLazyQueryHookResult = ReturnType<
  typeof useGetCompanyForCustomerLazyQuery
>;
export type GetCompanyForCustomerQueryResult = Apollo.QueryResult<
  GetCompanyForCustomerQuery,
  GetCompanyForCustomerQueryVariables
>;
export const GetCompanyForVendorDocument = gql`
  query GetCompanyForVendor($companyId: uuid!) {
    companies_by_pk(id: $companyId) {
      id
      ...Company
      bank_accounts {
        id
        ...BankAccountLimited
      }
      settings {
        id
        ...CompanySettingsLimited
        advances_bank_account {
          id
          ...BankAccountLimited
        }
        collections_bank_account {
          id
          ...BankAccountLimited
        }
      }
      users {
        id
        ...User
      }
    }
  }
  ${CompanyFragmentDoc}
  ${BankAccountLimitedFragmentDoc}
  ${CompanySettingsLimitedFragmentDoc}
  ${UserFragmentDoc}
`;

/**
 * __useGetCompanyForVendorQuery__
 *
 * To run a query within a React component, call `useGetCompanyForVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyForVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyForVendorQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyForVendorQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyForVendorQuery,
    GetCompanyForVendorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyForVendorQuery,
    GetCompanyForVendorQueryVariables
  >(GetCompanyForVendorDocument, options);
}
export function useGetCompanyForVendorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyForVendorQuery,
    GetCompanyForVendorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyForVendorQuery,
    GetCompanyForVendorQueryVariables
  >(GetCompanyForVendorDocument, options);
}
export type GetCompanyForVendorQueryHookResult = ReturnType<
  typeof useGetCompanyForVendorQuery
>;
export type GetCompanyForVendorLazyQueryHookResult = ReturnType<
  typeof useGetCompanyForVendorLazyQuery
>;
export type GetCompanyForVendorQueryResult = Apollo.QueryResult<
  GetCompanyForVendorQuery,
  GetCompanyForVendorQueryVariables
>;
export const UpdateCompanyProfileDocument = gql`
  mutation UpdateCompanyProfile($id: uuid!, $company: companies_set_input!) {
    update_companies_by_pk(pk_columns: { id: $id }, _set: $company) {
      ...Company
    }
  }
  ${CompanyFragmentDoc}
`;
export type UpdateCompanyProfileMutationFn = Apollo.MutationFunction<
  UpdateCompanyProfileMutation,
  UpdateCompanyProfileMutationVariables
>;

/**
 * __useUpdateCompanyProfileMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyProfileMutation, { data, loading, error }] = useUpdateCompanyProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useUpdateCompanyProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyProfileMutation,
    UpdateCompanyProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompanyProfileMutation,
    UpdateCompanyProfileMutationVariables
  >(UpdateCompanyProfileDocument, options);
}
export type UpdateCompanyProfileMutationHookResult = ReturnType<
  typeof useUpdateCompanyProfileMutation
>;
export type UpdateCompanyProfileMutationResult =
  Apollo.MutationResult<UpdateCompanyProfileMutation>;
export type UpdateCompanyProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyProfileMutation,
  UpdateCompanyProfileMutationVariables
>;
export const UpdateCompanyInfoDocument = gql`
  mutation UpdateCompanyInfo($id: uuid!, $company: companies_set_input!) {
    update_companies_by_pk(pk_columns: { id: $id }, _set: $company) {
      id
      name
      address
      country
      state
      city
      zip_code
      phone_number
    }
  }
`;
export type UpdateCompanyInfoMutationFn = Apollo.MutationFunction<
  UpdateCompanyInfoMutation,
  UpdateCompanyInfoMutationVariables
>;

/**
 * __useUpdateCompanyInfoMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyInfoMutation, { data, loading, error }] = useUpdateCompanyInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useUpdateCompanyInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyInfoMutation,
    UpdateCompanyInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompanyInfoMutation,
    UpdateCompanyInfoMutationVariables
  >(UpdateCompanyInfoDocument, options);
}
export type UpdateCompanyInfoMutationHookResult = ReturnType<
  typeof useUpdateCompanyInfoMutation
>;
export type UpdateCompanyInfoMutationResult =
  Apollo.MutationResult<UpdateCompanyInfoMutation>;
export type UpdateCompanyInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyInfoMutation,
  UpdateCompanyInfoMutationVariables
>;
export const GetTransactionsDocument = gql`
  query GetTransactions {
    transactions(order_by: { created_at: desc }) {
      id
      ...TransactionExtended
    }
  }
  ${TransactionExtendedFragmentDoc}
`;

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTransactionsQuery,
    GetTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(
    GetTransactionsDocument,
    options
  );
}
export function useGetTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransactionsQuery,
    GetTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTransactionsQuery,
    GetTransactionsQueryVariables
  >(GetTransactionsDocument, options);
}
export type GetTransactionsQueryHookResult = ReturnType<
  typeof useGetTransactionsQuery
>;
export type GetTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetTransactionsLazyQuery
>;
export type GetTransactionsQueryResult = Apollo.QueryResult<
  GetTransactionsQuery,
  GetTransactionsQueryVariables
>;
export const GetTransactionsForDateRangeDocument = gql`
  query GetTransactionsForDateRange($from: timestamptz!, $to: timestamptz!) {
    transactions(
      where: { created_at: { _gte: $from, _lte: $to } }
      order_by: { created_at: desc }
    ) {
      id
      ...TransactionExtended
    }
  }
  ${TransactionExtendedFragmentDoc}
`;

/**
 * __useGetTransactionsForDateRangeQuery__
 *
 * To run a query within a React component, call `useGetTransactionsForDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsForDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsForDateRangeQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetTransactionsForDateRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTransactionsForDateRangeQuery,
    GetTransactionsForDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTransactionsForDateRangeQuery,
    GetTransactionsForDateRangeQueryVariables
  >(GetTransactionsForDateRangeDocument, options);
}
export function useGetTransactionsForDateRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransactionsForDateRangeQuery,
    GetTransactionsForDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTransactionsForDateRangeQuery,
    GetTransactionsForDateRangeQueryVariables
  >(GetTransactionsForDateRangeDocument, options);
}
export type GetTransactionsForDateRangeQueryHookResult = ReturnType<
  typeof useGetTransactionsForDateRangeQuery
>;
export type GetTransactionsForDateRangeLazyQueryHookResult = ReturnType<
  typeof useGetTransactionsForDateRangeLazyQuery
>;
export type GetTransactionsForDateRangeQueryResult = Apollo.QueryResult<
  GetTransactionsForDateRangeQuery,
  GetTransactionsForDateRangeQueryVariables
>;
export const GetMetrcTransferDocument = gql`
  query GetMetrcTransfer($id: uuid!) {
    metrc_transfers_by_pk(id: $id) {
      id
      ...MetrcTransfer
      metrc_deliveries {
        id
        ...MetrcDelivery
      }
      metrc_transfer_packages {
        id
        ...MetrcTransferPackage
      }
    }
  }
  ${MetrcTransferFragmentDoc}
  ${MetrcDeliveryFragmentDoc}
  ${MetrcTransferPackageFragmentDoc}
`;

/**
 * __useGetMetrcTransferQuery__
 *
 * To run a query within a React component, call `useGetMetrcTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetrcTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetrcTransferQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMetrcTransferQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMetrcTransferQuery,
    GetMetrcTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMetrcTransferQuery, GetMetrcTransferQueryVariables>(
    GetMetrcTransferDocument,
    options
  );
}
export function useGetMetrcTransferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetrcTransferQuery,
    GetMetrcTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMetrcTransferQuery,
    GetMetrcTransferQueryVariables
  >(GetMetrcTransferDocument, options);
}
export type GetMetrcTransferQueryHookResult = ReturnType<
  typeof useGetMetrcTransferQuery
>;
export type GetMetrcTransferLazyQueryHookResult = ReturnType<
  typeof useGetMetrcTransferLazyQuery
>;
export type GetMetrcTransferQueryResult = Apollo.QueryResult<
  GetMetrcTransferQuery,
  GetMetrcTransferQueryVariables
>;
export const GetMetrcTransfersByUsStateManifestNumberDocument = gql`
  query GetMetrcTransfersByUsStateManifestNumber(
    $us_state: String!
    $manifest_number: String!
  ) {
    metrc_transfers(
      where: {
        _and: [
          { us_state: { _eq: $us_state } }
          { manifest_number: { _eq: $manifest_number } }
        ]
      }
    ) {
      id
      ...MetrcTransfer
      metrc_deliveries {
        id
        ...MetrcDelivery
      }
    }
  }
  ${MetrcTransferFragmentDoc}
  ${MetrcDeliveryFragmentDoc}
`;

/**
 * __useGetMetrcTransfersByUsStateManifestNumberQuery__
 *
 * To run a query within a React component, call `useGetMetrcTransfersByUsStateManifestNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetrcTransfersByUsStateManifestNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetrcTransfersByUsStateManifestNumberQuery({
 *   variables: {
 *      us_state: // value for 'us_state'
 *      manifest_number: // value for 'manifest_number'
 *   },
 * });
 */
export function useGetMetrcTransfersByUsStateManifestNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMetrcTransfersByUsStateManifestNumberQuery,
    GetMetrcTransfersByUsStateManifestNumberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMetrcTransfersByUsStateManifestNumberQuery,
    GetMetrcTransfersByUsStateManifestNumberQueryVariables
  >(GetMetrcTransfersByUsStateManifestNumberDocument, options);
}
export function useGetMetrcTransfersByUsStateManifestNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetrcTransfersByUsStateManifestNumberQuery,
    GetMetrcTransfersByUsStateManifestNumberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMetrcTransfersByUsStateManifestNumberQuery,
    GetMetrcTransfersByUsStateManifestNumberQueryVariables
  >(GetMetrcTransfersByUsStateManifestNumberDocument, options);
}
export type GetMetrcTransfersByUsStateManifestNumberQueryHookResult =
  ReturnType<typeof useGetMetrcTransfersByUsStateManifestNumberQuery>;
export type GetMetrcTransfersByUsStateManifestNumberLazyQueryHookResult =
  ReturnType<typeof useGetMetrcTransfersByUsStateManifestNumberLazyQuery>;
export type GetMetrcTransfersByUsStateManifestNumberQueryResult =
  Apollo.QueryResult<
    GetMetrcTransfersByUsStateManifestNumberQuery,
    GetMetrcTransfersByUsStateManifestNumberQueryVariables
  >;
export const GetMetrcTransfersByCompanyIdDocument = gql`
  query GetMetrcTransfersByCompanyId($company_id: uuid!) {
    metrc_transfers(
      where: { company_deliveries: { company_id: { _eq: $company_id } } }
      order_by: { manifest_number: desc }
    ) {
      id
      ...MetrcTransfer
      metrc_deliveries {
        id
        ...MetrcDelivery
      }
    }
  }
  ${MetrcTransferFragmentDoc}
  ${MetrcDeliveryFragmentDoc}
`;

/**
 * __useGetMetrcTransfersByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetMetrcTransfersByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetrcTransfersByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetrcTransfersByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetMetrcTransfersByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMetrcTransfersByCompanyIdQuery,
    GetMetrcTransfersByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMetrcTransfersByCompanyIdQuery,
    GetMetrcTransfersByCompanyIdQueryVariables
  >(GetMetrcTransfersByCompanyIdDocument, options);
}
export function useGetMetrcTransfersByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetrcTransfersByCompanyIdQuery,
    GetMetrcTransfersByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMetrcTransfersByCompanyIdQuery,
    GetMetrcTransfersByCompanyIdQueryVariables
  >(GetMetrcTransfersByCompanyIdDocument, options);
}
export type GetMetrcTransfersByCompanyIdQueryHookResult = ReturnType<
  typeof useGetMetrcTransfersByCompanyIdQuery
>;
export type GetMetrcTransfersByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetMetrcTransfersByCompanyIdLazyQuery
>;
export type GetMetrcTransfersByCompanyIdQueryResult = Apollo.QueryResult<
  GetMetrcTransfersByCompanyIdQuery,
  GetMetrcTransfersByCompanyIdQueryVariables
>;
export const GetMetrcTransferPackageDocument = gql`
  query GetMetrcTransferPackage($id: uuid!) {
    metrc_transfer_packages_by_pk(id: $id) {
      id
      ...MetrcTransferPackage
    }
  }
  ${MetrcTransferPackageFragmentDoc}
`;

/**
 * __useGetMetrcTransferPackageQuery__
 *
 * To run a query within a React component, call `useGetMetrcTransferPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetrcTransferPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetrcTransferPackageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMetrcTransferPackageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMetrcTransferPackageQuery,
    GetMetrcTransferPackageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMetrcTransferPackageQuery,
    GetMetrcTransferPackageQueryVariables
  >(GetMetrcTransferPackageDocument, options);
}
export function useGetMetrcTransferPackageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetrcTransferPackageQuery,
    GetMetrcTransferPackageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMetrcTransferPackageQuery,
    GetMetrcTransferPackageQueryVariables
  >(GetMetrcTransferPackageDocument, options);
}
export type GetMetrcTransferPackageQueryHookResult = ReturnType<
  typeof useGetMetrcTransferPackageQuery
>;
export type GetMetrcTransferPackageLazyQueryHookResult = ReturnType<
  typeof useGetMetrcTransferPackageLazyQuery
>;
export type GetMetrcTransferPackageQueryResult = Apollo.QueryResult<
  GetMetrcTransferPackageQuery,
  GetMetrcTransferPackageQueryVariables
>;
export const GetMetrcTransferPackagesByCompanyIdDocument = gql`
  query GetMetrcTransferPackagesByCompanyId($company_id: uuid!) {
    metrc_transfer_packages(
      where: {
        metrc_transfer: {
          company_deliveries: { company_id: { _eq: $company_id } }
        }
      }
      order_by: [
        { metrc_transfer: { manifest_number: desc } }
        { package_id: asc }
      ]
    ) {
      id
      ...MetrcTransferPackage
    }
  }
  ${MetrcTransferPackageFragmentDoc}
`;

/**
 * __useGetMetrcTransferPackagesByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetMetrcTransferPackagesByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetrcTransferPackagesByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetrcTransferPackagesByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetMetrcTransferPackagesByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMetrcTransferPackagesByCompanyIdQuery,
    GetMetrcTransferPackagesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMetrcTransferPackagesByCompanyIdQuery,
    GetMetrcTransferPackagesByCompanyIdQueryVariables
  >(GetMetrcTransferPackagesByCompanyIdDocument, options);
}
export function useGetMetrcTransferPackagesByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetrcTransferPackagesByCompanyIdQuery,
    GetMetrcTransferPackagesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMetrcTransferPackagesByCompanyIdQuery,
    GetMetrcTransferPackagesByCompanyIdQueryVariables
  >(GetMetrcTransferPackagesByCompanyIdDocument, options);
}
export type GetMetrcTransferPackagesByCompanyIdQueryHookResult = ReturnType<
  typeof useGetMetrcTransferPackagesByCompanyIdQuery
>;
export type GetMetrcTransferPackagesByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetMetrcTransferPackagesByCompanyIdLazyQuery
>;
export type GetMetrcTransferPackagesByCompanyIdQueryResult = Apollo.QueryResult<
  GetMetrcTransferPackagesByCompanyIdQuery,
  GetMetrcTransferPackagesByCompanyIdQueryVariables
>;
export const GetActiveMetrcPackagesByCompanyIdDocument = gql`
  query GetActiveMetrcPackagesByCompanyId($company_id: uuid!) {
    metrc_packages(
      where: {
        _and: [
          { company_id: { _eq: $company_id } }
          { type: { _in: ["active", "onhold"] } }
        ]
      }
      order_by: [{ packaged_date: asc }, { last_modified_at: asc }]
    ) {
      id
      ...MetrcPackage
    }
  }
  ${MetrcPackageFragmentDoc}
`;

/**
 * __useGetActiveMetrcPackagesByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetActiveMetrcPackagesByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveMetrcPackagesByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveMetrcPackagesByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetActiveMetrcPackagesByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActiveMetrcPackagesByCompanyIdQuery,
    GetActiveMetrcPackagesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveMetrcPackagesByCompanyIdQuery,
    GetActiveMetrcPackagesByCompanyIdQueryVariables
  >(GetActiveMetrcPackagesByCompanyIdDocument, options);
}
export function useGetActiveMetrcPackagesByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveMetrcPackagesByCompanyIdQuery,
    GetActiveMetrcPackagesByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveMetrcPackagesByCompanyIdQuery,
    GetActiveMetrcPackagesByCompanyIdQueryVariables
  >(GetActiveMetrcPackagesByCompanyIdDocument, options);
}
export type GetActiveMetrcPackagesByCompanyIdQueryHookResult = ReturnType<
  typeof useGetActiveMetrcPackagesByCompanyIdQuery
>;
export type GetActiveMetrcPackagesByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetActiveMetrcPackagesByCompanyIdLazyQuery
>;
export type GetActiveMetrcPackagesByCompanyIdQueryResult = Apollo.QueryResult<
  GetActiveMetrcPackagesByCompanyIdQuery,
  GetActiveMetrcPackagesByCompanyIdQueryVariables
>;
export const GetMetrcPackageDocument = gql`
  query GetMetrcPackage($id: uuid!) {
    metrc_packages_by_pk(id: $id) {
      id
      ...MetrcPackage
    }
  }
  ${MetrcPackageFragmentDoc}
`;

/**
 * __useGetMetrcPackageQuery__
 *
 * To run a query within a React component, call `useGetMetrcPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetrcPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetrcPackageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMetrcPackageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMetrcPackageQuery,
    GetMetrcPackageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMetrcPackageQuery, GetMetrcPackageQueryVariables>(
    GetMetrcPackageDocument,
    options
  );
}
export function useGetMetrcPackageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetrcPackageQuery,
    GetMetrcPackageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMetrcPackageQuery,
    GetMetrcPackageQueryVariables
  >(GetMetrcPackageDocument, options);
}
export type GetMetrcPackageQueryHookResult = ReturnType<
  typeof useGetMetrcPackageQuery
>;
export type GetMetrcPackageLazyQueryHookResult = ReturnType<
  typeof useGetMetrcPackageLazyQuery
>;
export type GetMetrcPackageQueryResult = Apollo.QueryResult<
  GetMetrcPackageQuery,
  GetMetrcPackageQueryVariables
>;
export const GetMetrcDownloadSummaryDocument = gql`
  query GetMetrcDownloadSummary($id: uuid!) {
    metrc_download_summaries_by_pk(id: $id) {
      id
      ...MetrcDownloadSummary
    }
  }
  ${MetrcDownloadSummaryFragmentDoc}
`;

/**
 * __useGetMetrcDownloadSummaryQuery__
 *
 * To run a query within a React component, call `useGetMetrcDownloadSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetrcDownloadSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetrcDownloadSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMetrcDownloadSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMetrcDownloadSummaryQuery,
    GetMetrcDownloadSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMetrcDownloadSummaryQuery,
    GetMetrcDownloadSummaryQueryVariables
  >(GetMetrcDownloadSummaryDocument, options);
}
export function useGetMetrcDownloadSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetrcDownloadSummaryQuery,
    GetMetrcDownloadSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMetrcDownloadSummaryQuery,
    GetMetrcDownloadSummaryQueryVariables
  >(GetMetrcDownloadSummaryDocument, options);
}
export type GetMetrcDownloadSummaryQueryHookResult = ReturnType<
  typeof useGetMetrcDownloadSummaryQuery
>;
export type GetMetrcDownloadSummaryLazyQueryHookResult = ReturnType<
  typeof useGetMetrcDownloadSummaryLazyQuery
>;
export type GetMetrcDownloadSummaryQueryResult = Apollo.QueryResult<
  GetMetrcDownloadSummaryQuery,
  GetMetrcDownloadSummaryQueryVariables
>;
export const GetMetrcApiKeyDocument = gql`
  query GetMetrcApiKey($id: uuid!) {
    metrc_api_keys_by_pk(id: $id) {
      id
      ...MetrcApiKey
    }
  }
  ${MetrcApiKeyFragmentDoc}
`;

/**
 * __useGetMetrcApiKeyQuery__
 *
 * To run a query within a React component, call `useGetMetrcApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetrcApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetrcApiKeyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMetrcApiKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMetrcApiKeyQuery,
    GetMetrcApiKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMetrcApiKeyQuery, GetMetrcApiKeyQueryVariables>(
    GetMetrcApiKeyDocument,
    options
  );
}
export function useGetMetrcApiKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetrcApiKeyQuery,
    GetMetrcApiKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMetrcApiKeyQuery, GetMetrcApiKeyQueryVariables>(
    GetMetrcApiKeyDocument,
    options
  );
}
export type GetMetrcApiKeyQueryHookResult = ReturnType<
  typeof useGetMetrcApiKeyQuery
>;
export type GetMetrcApiKeyLazyQueryHookResult = ReturnType<
  typeof useGetMetrcApiKeyLazyQuery
>;
export type GetMetrcApiKeyQueryResult = Apollo.QueryResult<
  GetMetrcApiKeyQuery,
  GetMetrcApiKeyQueryVariables
>;
export const GetVendorPartnershipDocument = gql`
  query GetVendorPartnership($id: uuid!) {
    company_vendor_partnerships_by_pk(id: $id) {
      ...VendorPartnershipLimited
      company {
        ...Company
        ...AllCompanyUsersForBank
      }
      vendor {
        id
        settings {
          id
        }
        users(
          where: {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
        ) {
          id
          ...Contact
        }
      }
    }
  }
  ${VendorPartnershipLimitedFragmentDoc}
  ${CompanyFragmentDoc}
  ${AllCompanyUsersForBankFragmentDoc}
  ${ContactFragmentDoc}
`;

/**
 * __useGetVendorPartnershipQuery__
 *
 * To run a query within a React component, call `useGetVendorPartnershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorPartnershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorPartnershipQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVendorPartnershipQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVendorPartnershipQuery,
    GetVendorPartnershipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVendorPartnershipQuery,
    GetVendorPartnershipQueryVariables
  >(GetVendorPartnershipDocument, options);
}
export function useGetVendorPartnershipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVendorPartnershipQuery,
    GetVendorPartnershipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVendorPartnershipQuery,
    GetVendorPartnershipQueryVariables
  >(GetVendorPartnershipDocument, options);
}
export type GetVendorPartnershipQueryHookResult = ReturnType<
  typeof useGetVendorPartnershipQuery
>;
export type GetVendorPartnershipLazyQueryHookResult = ReturnType<
  typeof useGetVendorPartnershipLazyQuery
>;
export type GetVendorPartnershipQueryResult = Apollo.QueryResult<
  GetVendorPartnershipQuery,
  GetVendorPartnershipQueryVariables
>;
export const GetVendorContactsDocument = gql`
  query GetVendorContacts($id: uuid!) {
    company_vendor_partnerships_by_pk(id: $id) {
      ...VendorPartnershipLimited
      company {
        ...Company
      }
      vendor {
        id
        users(
          where: {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
        ) {
          id
          ...Contact
          ...User
        }
      }
      vendor_contacts {
        id
        ...CompanyVendorContact
      }
    }
  }
  ${VendorPartnershipLimitedFragmentDoc}
  ${CompanyFragmentDoc}
  ${ContactFragmentDoc}
  ${UserFragmentDoc}
  ${CompanyVendorContactFragmentDoc}
`;

/**
 * __useGetVendorContactsQuery__
 *
 * To run a query within a React component, call `useGetVendorContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVendorContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVendorContactsQuery,
    GetVendorContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVendorContactsQuery,
    GetVendorContactsQueryVariables
  >(GetVendorContactsDocument, options);
}
export function useGetVendorContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVendorContactsQuery,
    GetVendorContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVendorContactsQuery,
    GetVendorContactsQueryVariables
  >(GetVendorContactsDocument, options);
}
export type GetVendorContactsQueryHookResult = ReturnType<
  typeof useGetVendorContactsQuery
>;
export type GetVendorContactsLazyQueryHookResult = ReturnType<
  typeof useGetVendorContactsLazyQuery
>;
export type GetVendorContactsQueryResult = Apollo.QueryResult<
  GetVendorContactsQuery,
  GetVendorContactsQueryVariables
>;
export const GetVendorsWithMetadataDocument = gql`
  query GetVendorsWithMetadata($date: date) {
    vendors: companies(
      where: { is_vendor: { _eq: true } }
      order_by: { name: asc }
    ) {
      id
      ...CustomersWithMetadata
      licenses(
        where: {
          _or: [
            { is_deleted: { _is_null: true } }
            { is_deleted: { _eq: false } }
          ]
        }
      ) {
        ...CompanyLicenseLimited
      }
    }
  }
  ${CustomersWithMetadataFragmentDoc}
  ${CompanyLicenseLimitedFragmentDoc}
`;

/**
 * __useGetVendorsWithMetadataQuery__
 *
 * To run a query within a React component, call `useGetVendorsWithMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorsWithMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorsWithMetadataQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetVendorsWithMetadataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVendorsWithMetadataQuery,
    GetVendorsWithMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVendorsWithMetadataQuery,
    GetVendorsWithMetadataQueryVariables
  >(GetVendorsWithMetadataDocument, options);
}
export function useGetVendorsWithMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVendorsWithMetadataQuery,
    GetVendorsWithMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVendorsWithMetadataQuery,
    GetVendorsWithMetadataQueryVariables
  >(GetVendorsWithMetadataDocument, options);
}
export type GetVendorsWithMetadataQueryHookResult = ReturnType<
  typeof useGetVendorsWithMetadataQuery
>;
export type GetVendorsWithMetadataLazyQueryHookResult = ReturnType<
  typeof useGetVendorsWithMetadataLazyQuery
>;
export type GetVendorsWithMetadataQueryResult = Apollo.QueryResult<
  GetVendorsWithMetadataQuery,
  GetVendorsWithMetadataQueryVariables
>;
export const GetVendorCompanyFileAttachmentsDocument = gql`
  query GetVendorCompanyFileAttachments($company_id: uuid!) {
    companies_by_pk(id: $company_id) {
      id
      agreements {
        ...CompanyAgreement
      }
      licenses(
        where: {
          _or: [
            { is_deleted: { _is_null: true } }
            { is_deleted: { _eq: false } }
          ]
        }
      ) {
        ...CompanyLicense
      }
    }
  }
  ${CompanyAgreementFragmentDoc}
  ${CompanyLicenseFragmentDoc}
`;

/**
 * __useGetVendorCompanyFileAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetVendorCompanyFileAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorCompanyFileAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorCompanyFileAttachmentsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetVendorCompanyFileAttachmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVendorCompanyFileAttachmentsQuery,
    GetVendorCompanyFileAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVendorCompanyFileAttachmentsQuery,
    GetVendorCompanyFileAttachmentsQueryVariables
  >(GetVendorCompanyFileAttachmentsDocument, options);
}
export function useGetVendorCompanyFileAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVendorCompanyFileAttachmentsQuery,
    GetVendorCompanyFileAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVendorCompanyFileAttachmentsQuery,
    GetVendorCompanyFileAttachmentsQueryVariables
  >(GetVendorCompanyFileAttachmentsDocument, options);
}
export type GetVendorCompanyFileAttachmentsQueryHookResult = ReturnType<
  typeof useGetVendorCompanyFileAttachmentsQuery
>;
export type GetVendorCompanyFileAttachmentsLazyQueryHookResult = ReturnType<
  typeof useGetVendorCompanyFileAttachmentsLazyQuery
>;
export type GetVendorCompanyFileAttachmentsQueryResult = Apollo.QueryResult<
  GetVendorCompanyFileAttachmentsQuery,
  GetVendorCompanyFileAttachmentsQueryVariables
>;
export const GetVendorPartnershipForBankDocument = gql`
  query GetVendorPartnershipForBank($id: uuid!) {
    company_vendor_partnerships_by_pk(id: $id) {
      ...VendorPartnership
      company {
        ...Company
        ...AllCompanyUsersForBank
        settings {
          ...CompanySettings
        }
      }
      vendor {
        id
        licenses(
          where: {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
        ) {
          ...CompanyLicense
        }
        settings {
          id
          ...CompanySettings
          collections_bespoke_bank_account {
            ...BankAccount
          }
        }
        users(
          where: {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
        ) {
          id
          ...Contact
        }
      }
      vendor_agreement {
        ...CompanyAgreement
      }
      vendor_bank_account {
        id
        ...BankAccount
      }
      vendor_contacts(
        where: {
          _or: [
            { user: { is_deleted: { _is_null: true } } }
            { user: { is_deleted: { _eq: false } } }
          ]
        }
      ) {
        id
        ...CompanyVendorContact
      }
    }
  }
  ${VendorPartnershipFragmentDoc}
  ${CompanyFragmentDoc}
  ${AllCompanyUsersForBankFragmentDoc}
  ${CompanySettingsFragmentDoc}
  ${CompanyLicenseFragmentDoc}
  ${BankAccountFragmentDoc}
  ${ContactFragmentDoc}
  ${CompanyAgreementFragmentDoc}
  ${CompanyVendorContactFragmentDoc}
`;

/**
 * __useGetVendorPartnershipForBankQuery__
 *
 * To run a query within a React component, call `useGetVendorPartnershipForBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorPartnershipForBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorPartnershipForBankQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVendorPartnershipForBankQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVendorPartnershipForBankQuery,
    GetVendorPartnershipForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVendorPartnershipForBankQuery,
    GetVendorPartnershipForBankQueryVariables
  >(GetVendorPartnershipForBankDocument, options);
}
export function useGetVendorPartnershipForBankLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVendorPartnershipForBankQuery,
    GetVendorPartnershipForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVendorPartnershipForBankQuery,
    GetVendorPartnershipForBankQueryVariables
  >(GetVendorPartnershipForBankDocument, options);
}
export type GetVendorPartnershipForBankQueryHookResult = ReturnType<
  typeof useGetVendorPartnershipForBankQuery
>;
export type GetVendorPartnershipForBankLazyQueryHookResult = ReturnType<
  typeof useGetVendorPartnershipForBankLazyQuery
>;
export type GetVendorPartnershipForBankQueryResult = Apollo.QueryResult<
  GetVendorPartnershipForBankQuery,
  GetVendorPartnershipForBankQueryVariables
>;
export const GetVendorPartnershipForContactsDocument = gql`
  query GetVendorPartnershipForContacts($id: uuid!) {
    company_vendor_partnerships_by_pk(id: $id) {
      ...VendorPartnership
      vendor {
        id
        users(
          where: {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
        ) {
          id
          ...Contact
        }
      }
      vendor_contacts {
        id
        is_active
        ...CompanyVendorContact
      }
    }
  }
  ${VendorPartnershipFragmentDoc}
  ${ContactFragmentDoc}
  ${CompanyVendorContactFragmentDoc}
`;

/**
 * __useGetVendorPartnershipForContactsQuery__
 *
 * To run a query within a React component, call `useGetVendorPartnershipForContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorPartnershipForContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorPartnershipForContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVendorPartnershipForContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVendorPartnershipForContactsQuery,
    GetVendorPartnershipForContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVendorPartnershipForContactsQuery,
    GetVendorPartnershipForContactsQueryVariables
  >(GetVendorPartnershipForContactsDocument, options);
}
export function useGetVendorPartnershipForContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVendorPartnershipForContactsQuery,
    GetVendorPartnershipForContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVendorPartnershipForContactsQuery,
    GetVendorPartnershipForContactsQueryVariables
  >(GetVendorPartnershipForContactsDocument, options);
}
export type GetVendorPartnershipForContactsQueryHookResult = ReturnType<
  typeof useGetVendorPartnershipForContactsQuery
>;
export type GetVendorPartnershipForContactsLazyQueryHookResult = ReturnType<
  typeof useGetVendorPartnershipForContactsLazyQuery
>;
export type GetVendorPartnershipForContactsQueryResult = Apollo.QueryResult<
  GetVendorPartnershipForContactsQuery,
  GetVendorPartnershipForContactsQueryVariables
>;
export const GetVendorPartnershipsForBankDocument = gql`
  query GetVendorPartnershipsForBank {
    company_vendor_partnerships(order_by: { vendor: { name: asc } }) {
      ...VendorPartnership
      company {
        id
        ...CompanyLimited
      }
      vendor {
        id
        ...Vendor
        users {
          ...Contact
        }
      }
    }
  }
  ${VendorPartnershipFragmentDoc}
  ${CompanyLimitedFragmentDoc}
  ${VendorFragmentDoc}
  ${ContactFragmentDoc}
`;

/**
 * __useGetVendorPartnershipsForBankQuery__
 *
 * To run a query within a React component, call `useGetVendorPartnershipsForBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorPartnershipsForBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorPartnershipsForBankQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVendorPartnershipsForBankQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVendorPartnershipsForBankQuery,
    GetVendorPartnershipsForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVendorPartnershipsForBankQuery,
    GetVendorPartnershipsForBankQueryVariables
  >(GetVendorPartnershipsForBankDocument, options);
}
export function useGetVendorPartnershipsForBankLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVendorPartnershipsForBankQuery,
    GetVendorPartnershipsForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVendorPartnershipsForBankQuery,
    GetVendorPartnershipsForBankQueryVariables
  >(GetVendorPartnershipsForBankDocument, options);
}
export type GetVendorPartnershipsForBankQueryHookResult = ReturnType<
  typeof useGetVendorPartnershipsForBankQuery
>;
export type GetVendorPartnershipsForBankLazyQueryHookResult = ReturnType<
  typeof useGetVendorPartnershipsForBankLazyQuery
>;
export type GetVendorPartnershipsForBankQueryResult = Apollo.QueryResult<
  GetVendorPartnershipsForBankQuery,
  GetVendorPartnershipsForBankQueryVariables
>;
export const GetNotApprovedVendorPartnershipsForBankDocument = gql`
  query GetNotApprovedVendorPartnershipsForBank {
    company_vendor_partnerships(
      where: { approved_at: { _is_null: true } }
      order_by: { vendor: { name: asc } }
    ) {
      ...VendorPartnership
      company {
        id
        ...CompanyLimited
      }
      vendor {
        id
        ...Vendor
        users {
          ...Contact
        }
      }
    }
  }
  ${VendorPartnershipFragmentDoc}
  ${CompanyLimitedFragmentDoc}
  ${VendorFragmentDoc}
  ${ContactFragmentDoc}
`;

/**
 * __useGetNotApprovedVendorPartnershipsForBankQuery__
 *
 * To run a query within a React component, call `useGetNotApprovedVendorPartnershipsForBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotApprovedVendorPartnershipsForBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotApprovedVendorPartnershipsForBankQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotApprovedVendorPartnershipsForBankQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNotApprovedVendorPartnershipsForBankQuery,
    GetNotApprovedVendorPartnershipsForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNotApprovedVendorPartnershipsForBankQuery,
    GetNotApprovedVendorPartnershipsForBankQueryVariables
  >(GetNotApprovedVendorPartnershipsForBankDocument, options);
}
export function useGetNotApprovedVendorPartnershipsForBankLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotApprovedVendorPartnershipsForBankQuery,
    GetNotApprovedVendorPartnershipsForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNotApprovedVendorPartnershipsForBankQuery,
    GetNotApprovedVendorPartnershipsForBankQueryVariables
  >(GetNotApprovedVendorPartnershipsForBankDocument, options);
}
export type GetNotApprovedVendorPartnershipsForBankQueryHookResult = ReturnType<
  typeof useGetNotApprovedVendorPartnershipsForBankQuery
>;
export type GetNotApprovedVendorPartnershipsForBankLazyQueryHookResult =
  ReturnType<typeof useGetNotApprovedVendorPartnershipsForBankLazyQuery>;
export type GetNotApprovedVendorPartnershipsForBankQueryResult =
  Apollo.QueryResult<
    GetNotApprovedVendorPartnershipsForBankQuery,
    GetNotApprovedVendorPartnershipsForBankQueryVariables
  >;
export const GetApprovedVendorPartnershipsForBankDocument = gql`
  query GetApprovedVendorPartnershipsForBank {
    company_vendor_partnerships(
      where: { approved_at: { _is_null: false } }
      order_by: { vendor: { name: asc } }
    ) {
      ...VendorPartnership
      company {
        id
        ...CompanyLimited
      }
      vendor {
        id
        ...Vendor
        users {
          ...Contact
        }
      }
    }
  }
  ${VendorPartnershipFragmentDoc}
  ${CompanyLimitedFragmentDoc}
  ${VendorFragmentDoc}
  ${ContactFragmentDoc}
`;

/**
 * __useGetApprovedVendorPartnershipsForBankQuery__
 *
 * To run a query within a React component, call `useGetApprovedVendorPartnershipsForBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovedVendorPartnershipsForBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovedVendorPartnershipsForBankQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApprovedVendorPartnershipsForBankQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetApprovedVendorPartnershipsForBankQuery,
    GetApprovedVendorPartnershipsForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApprovedVendorPartnershipsForBankQuery,
    GetApprovedVendorPartnershipsForBankQueryVariables
  >(GetApprovedVendorPartnershipsForBankDocument, options);
}
export function useGetApprovedVendorPartnershipsForBankLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApprovedVendorPartnershipsForBankQuery,
    GetApprovedVendorPartnershipsForBankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApprovedVendorPartnershipsForBankQuery,
    GetApprovedVendorPartnershipsForBankQueryVariables
  >(GetApprovedVendorPartnershipsForBankDocument, options);
}
export type GetApprovedVendorPartnershipsForBankQueryHookResult = ReturnType<
  typeof useGetApprovedVendorPartnershipsForBankQuery
>;
export type GetApprovedVendorPartnershipsForBankLazyQueryHookResult =
  ReturnType<typeof useGetApprovedVendorPartnershipsForBankLazyQuery>;
export type GetApprovedVendorPartnershipsForBankQueryResult =
  Apollo.QueryResult<
    GetApprovedVendorPartnershipsForBankQuery,
    GetApprovedVendorPartnershipsForBankQueryVariables
  >;
export const GetVendorPartnershipsByVendorIdDocument = gql`
  query GetVendorPartnershipsByVendorId($vendor_id: uuid!) {
    company_vendor_partnerships(
      where: { vendor_id: { _eq: $vendor_id } }
      order_by: { company: { name: asc } }
    ) {
      id
      ...VendorPartnership
    }
  }
  ${VendorPartnershipFragmentDoc}
`;

/**
 * __useGetVendorPartnershipsByVendorIdQuery__
 *
 * To run a query within a React component, call `useGetVendorPartnershipsByVendorIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorPartnershipsByVendorIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorPartnershipsByVendorIdQuery({
 *   variables: {
 *      vendor_id: // value for 'vendor_id'
 *   },
 * });
 */
export function useGetVendorPartnershipsByVendorIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVendorPartnershipsByVendorIdQuery,
    GetVendorPartnershipsByVendorIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVendorPartnershipsByVendorIdQuery,
    GetVendorPartnershipsByVendorIdQueryVariables
  >(GetVendorPartnershipsByVendorIdDocument, options);
}
export function useGetVendorPartnershipsByVendorIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVendorPartnershipsByVendorIdQuery,
    GetVendorPartnershipsByVendorIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVendorPartnershipsByVendorIdQuery,
    GetVendorPartnershipsByVendorIdQueryVariables
  >(GetVendorPartnershipsByVendorIdDocument, options);
}
export type GetVendorPartnershipsByVendorIdQueryHookResult = ReturnType<
  typeof useGetVendorPartnershipsByVendorIdQuery
>;
export type GetVendorPartnershipsByVendorIdLazyQueryHookResult = ReturnType<
  typeof useGetVendorPartnershipsByVendorIdLazyQuery
>;
export type GetVendorPartnershipsByVendorIdQueryResult = Apollo.QueryResult<
  GetVendorPartnershipsByVendorIdQuery,
  GetVendorPartnershipsByVendorIdQueryVariables
>;
export const CompanyBankAccountsDocument = gql`
  query CompanyBankAccounts($companyId: uuid!) {
    bank_accounts(where: { company_id: { _eq: $companyId } }) {
      ...BankAccount
    }
  }
  ${BankAccountFragmentDoc}
`;

/**
 * __useCompanyBankAccountsQuery__
 *
 * To run a query within a React component, call `useCompanyBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyBankAccountsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyBankAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyBankAccountsQuery,
    CompanyBankAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyBankAccountsQuery,
    CompanyBankAccountsQueryVariables
  >(CompanyBankAccountsDocument, options);
}
export function useCompanyBankAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyBankAccountsQuery,
    CompanyBankAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyBankAccountsQuery,
    CompanyBankAccountsQueryVariables
  >(CompanyBankAccountsDocument, options);
}
export type CompanyBankAccountsQueryHookResult = ReturnType<
  typeof useCompanyBankAccountsQuery
>;
export type CompanyBankAccountsLazyQueryHookResult = ReturnType<
  typeof useCompanyBankAccountsLazyQuery
>;
export type CompanyBankAccountsQueryResult = Apollo.QueryResult<
  CompanyBankAccountsQuery,
  CompanyBankAccountsQueryVariables
>;
export const ChangeBankAccountDocument = gql`
  mutation ChangeBankAccount(
    $companyVendorPartnershipId: uuid!
    $bankAccountId: uuid
  ) {
    update_company_vendor_partnerships_by_pk(
      pk_columns: { id: $companyVendorPartnershipId }
      _set: { vendor_bank_id: $bankAccountId }
    ) {
      id
      vendor_bank_account {
        ...BankAccount
      }
    }
  }
  ${BankAccountFragmentDoc}
`;
export type ChangeBankAccountMutationFn = Apollo.MutationFunction<
  ChangeBankAccountMutation,
  ChangeBankAccountMutationVariables
>;

/**
 * __useChangeBankAccountMutation__
 *
 * To run a mutation, you first call `useChangeBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBankAccountMutation, { data, loading, error }] = useChangeBankAccountMutation({
 *   variables: {
 *      companyVendorPartnershipId: // value for 'companyVendorPartnershipId'
 *      bankAccountId: // value for 'bankAccountId'
 *   },
 * });
 */
export function useChangeBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeBankAccountMutation,
    ChangeBankAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeBankAccountMutation,
    ChangeBankAccountMutationVariables
  >(ChangeBankAccountDocument, options);
}
export type ChangeBankAccountMutationHookResult = ReturnType<
  typeof useChangeBankAccountMutation
>;
export type ChangeBankAccountMutationResult =
  Apollo.MutationResult<ChangeBankAccountMutation>;
export type ChangeBankAccountMutationOptions = Apollo.BaseMutationOptions<
  ChangeBankAccountMutation,
  ChangeBankAccountMutationVariables
>;
export const UpdateVendorInfoDocument = gql`
  mutation UpdateVendorInfo($id: uuid!, $company: companies_set_input!) {
    update_companies_by_pk(pk_columns: { id: $id }, _set: $company) {
      id
      name
      address
      country
      state
      city
      zip_code
      phone_number
    }
  }
`;
export type UpdateVendorInfoMutationFn = Apollo.MutationFunction<
  UpdateVendorInfoMutation,
  UpdateVendorInfoMutationVariables
>;

/**
 * __useUpdateVendorInfoMutation__
 *
 * To run a mutation, you first call `useUpdateVendorInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorInfoMutation, { data, loading, error }] = useUpdateVendorInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useUpdateVendorInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVendorInfoMutation,
    UpdateVendorInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVendorInfoMutation,
    UpdateVendorInfoMutationVariables
  >(UpdateVendorInfoDocument, options);
}
export type UpdateVendorInfoMutationHookResult = ReturnType<
  typeof useUpdateVendorInfoMutation
>;
export type UpdateVendorInfoMutationResult =
  Apollo.MutationResult<UpdateVendorInfoMutation>;
export type UpdateVendorInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateVendorInfoMutation,
  UpdateVendorInfoMutationVariables
>;
export const UpdateVendorAgreementIdDocument = gql`
  mutation UpdateVendorAgreementId(
    $companyVendorPartnershipId: uuid!
    $vendorAgreementId: uuid
  ) {
    update_company_vendor_partnerships_by_pk(
      pk_columns: { id: $companyVendorPartnershipId }
      _set: { vendor_agreement_id: $vendorAgreementId }
    ) {
      id
      vendor_agreement {
        ...CompanyAgreement
      }
    }
  }
  ${CompanyAgreementFragmentDoc}
`;
export type UpdateVendorAgreementIdMutationFn = Apollo.MutationFunction<
  UpdateVendorAgreementIdMutation,
  UpdateVendorAgreementIdMutationVariables
>;

/**
 * __useUpdateVendorAgreementIdMutation__
 *
 * To run a mutation, you first call `useUpdateVendorAgreementIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorAgreementIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorAgreementIdMutation, { data, loading, error }] = useUpdateVendorAgreementIdMutation({
 *   variables: {
 *      companyVendorPartnershipId: // value for 'companyVendorPartnershipId'
 *      vendorAgreementId: // value for 'vendorAgreementId'
 *   },
 * });
 */
export function useUpdateVendorAgreementIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVendorAgreementIdMutation,
    UpdateVendorAgreementIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVendorAgreementIdMutation,
    UpdateVendorAgreementIdMutationVariables
  >(UpdateVendorAgreementIdDocument, options);
}
export type UpdateVendorAgreementIdMutationHookResult = ReturnType<
  typeof useUpdateVendorAgreementIdMutation
>;
export type UpdateVendorAgreementIdMutationResult =
  Apollo.MutationResult<UpdateVendorAgreementIdMutation>;
export type UpdateVendorAgreementIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateVendorAgreementIdMutation,
  UpdateVendorAgreementIdMutationVariables
>;
export const AddCompanyVendorAgreementDocument = gql`
  mutation AddCompanyVendorAgreement(
    $vendorAgreement: company_agreements_insert_input!
  ) {
    insert_company_agreements_one(object: $vendorAgreement) {
      ...CompanyAgreement
    }
  }
  ${CompanyAgreementFragmentDoc}
`;
export type AddCompanyVendorAgreementMutationFn = Apollo.MutationFunction<
  AddCompanyVendorAgreementMutation,
  AddCompanyVendorAgreementMutationVariables
>;

/**
 * __useAddCompanyVendorAgreementMutation__
 *
 * To run a mutation, you first call `useAddCompanyVendorAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyVendorAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyVendorAgreementMutation, { data, loading, error }] = useAddCompanyVendorAgreementMutation({
 *   variables: {
 *      vendorAgreement: // value for 'vendorAgreement'
 *   },
 * });
 */
export function useAddCompanyVendorAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCompanyVendorAgreementMutation,
    AddCompanyVendorAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCompanyVendorAgreementMutation,
    AddCompanyVendorAgreementMutationVariables
  >(AddCompanyVendorAgreementDocument, options);
}
export type AddCompanyVendorAgreementMutationHookResult = ReturnType<
  typeof useAddCompanyVendorAgreementMutation
>;
export type AddCompanyVendorAgreementMutationResult =
  Apollo.MutationResult<AddCompanyVendorAgreementMutation>;
export type AddCompanyVendorAgreementMutationOptions =
  Apollo.BaseMutationOptions<
    AddCompanyVendorAgreementMutation,
    AddCompanyVendorAgreementMutationVariables
  >;
export const GetArtifactRelationsByCompanyIdDocument = gql`
  query GetArtifactRelationsByCompanyId($company_id: uuid!) {
    companies_by_pk(id: $company_id) {
      id
      settings {
        id
        ...CompanySettingsLimited
      }
      metrc_api_keys {
        id
      }
    }
    vendors(
      where: {
        company_vendor_partnerships: { company_id: { _eq: $company_id } }
      }
      order_by: { name: asc }
    ) {
      id
      ...VendorLimited
      company_vendor_partnerships(where: { company_id: { _eq: $company_id } }) {
        id
        ...VendorPartnershipLimited
      }
    }
  }
  ${CompanySettingsLimitedFragmentDoc}
  ${VendorLimitedFragmentDoc}
  ${VendorPartnershipLimitedFragmentDoc}
`;

/**
 * __useGetArtifactRelationsByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetArtifactRelationsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtifactRelationsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtifactRelationsByCompanyIdQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetArtifactRelationsByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetArtifactRelationsByCompanyIdQuery,
    GetArtifactRelationsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetArtifactRelationsByCompanyIdQuery,
    GetArtifactRelationsByCompanyIdQueryVariables
  >(GetArtifactRelationsByCompanyIdDocument, options);
}
export function useGetArtifactRelationsByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArtifactRelationsByCompanyIdQuery,
    GetArtifactRelationsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetArtifactRelationsByCompanyIdQuery,
    GetArtifactRelationsByCompanyIdQueryVariables
  >(GetArtifactRelationsByCompanyIdDocument, options);
}
export type GetArtifactRelationsByCompanyIdQueryHookResult = ReturnType<
  typeof useGetArtifactRelationsByCompanyIdQuery
>;
export type GetArtifactRelationsByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetArtifactRelationsByCompanyIdLazyQuery
>;
export type GetArtifactRelationsByCompanyIdQueryResult = Apollo.QueryResult<
  GetArtifactRelationsByCompanyIdQuery,
  GetArtifactRelationsByCompanyIdQueryVariables
>;
export const GetAllArtifactRelationsDocument = gql`
  query GetAllArtifactRelations {
    companies {
      id
      settings {
        id
        ...CompanySettingsLimited
      }
      metrc_api_keys {
        id
      }
    }
    vendors(order_by: { name: asc }) {
      id
      ...VendorLimited
      company_vendor_partnerships {
        id
        ...VendorPartnershipLimited
      }
      users {
        id
        ...User
      }
      bank_accounts {
        id
        ...BankAccount
      }
    }
  }
  ${CompanySettingsLimitedFragmentDoc}
  ${VendorLimitedFragmentDoc}
  ${VendorPartnershipLimitedFragmentDoc}
  ${UserFragmentDoc}
  ${BankAccountFragmentDoc}
`;

/**
 * __useGetAllArtifactRelationsQuery__
 *
 * To run a query within a React component, call `useGetAllArtifactRelationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllArtifactRelationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllArtifactRelationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllArtifactRelationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllArtifactRelationsQuery,
    GetAllArtifactRelationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllArtifactRelationsQuery,
    GetAllArtifactRelationsQueryVariables
  >(GetAllArtifactRelationsDocument, options);
}
export function useGetAllArtifactRelationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllArtifactRelationsQuery,
    GetAllArtifactRelationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllArtifactRelationsQuery,
    GetAllArtifactRelationsQueryVariables
  >(GetAllArtifactRelationsDocument, options);
}
export type GetAllArtifactRelationsQueryHookResult = ReturnType<
  typeof useGetAllArtifactRelationsQuery
>;
export type GetAllArtifactRelationsLazyQueryHookResult = ReturnType<
  typeof useGetAllArtifactRelationsLazyQuery
>;
export type GetAllArtifactRelationsQueryResult = Apollo.QueryResult<
  GetAllArtifactRelationsQuery,
  GetAllArtifactRelationsQueryVariables
>;
export const GetVendorPartnershipsByCompanyIdDocument = gql`
  query GetVendorPartnershipsByCompanyId($companyId: uuid!) {
    company_vendor_partnerships(where: { company_id: { _eq: $companyId } }) {
      ...VendorPartnershipLimited
    }
  }
  ${VendorPartnershipLimitedFragmentDoc}
`;

/**
 * __useGetVendorPartnershipsByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetVendorPartnershipsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorPartnershipsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorPartnershipsByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetVendorPartnershipsByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVendorPartnershipsByCompanyIdQuery,
    GetVendorPartnershipsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVendorPartnershipsByCompanyIdQuery,
    GetVendorPartnershipsByCompanyIdQueryVariables
  >(GetVendorPartnershipsByCompanyIdDocument, options);
}
export function useGetVendorPartnershipsByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVendorPartnershipsByCompanyIdQuery,
    GetVendorPartnershipsByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVendorPartnershipsByCompanyIdQuery,
    GetVendorPartnershipsByCompanyIdQueryVariables
  >(GetVendorPartnershipsByCompanyIdDocument, options);
}
export type GetVendorPartnershipsByCompanyIdQueryHookResult = ReturnType<
  typeof useGetVendorPartnershipsByCompanyIdQuery
>;
export type GetVendorPartnershipsByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetVendorPartnershipsByCompanyIdLazyQuery
>;
export type GetVendorPartnershipsByCompanyIdQueryResult = Apollo.QueryResult<
  GetVendorPartnershipsByCompanyIdQuery,
  GetVendorPartnershipsByCompanyIdQueryVariables
>;
export const CompanyVendorPartnershipForVendorDocument = gql`
  query CompanyVendorPartnershipForVendor($companyId: uuid!, $vendorId: uuid!) {
    company_vendor_partnerships(
      where: {
        _and: [
          { company_id: { _eq: $companyId } }
          { vendor_id: { _eq: $vendorId } }
        ]
      }
    ) {
      id
      vendor_bank_account {
        ...BankAccountForVendor
      }
    }
  }
  ${BankAccountForVendorFragmentDoc}
`;

/**
 * __useCompanyVendorPartnershipForVendorQuery__
 *
 * To run a query within a React component, call `useCompanyVendorPartnershipForVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyVendorPartnershipForVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyVendorPartnershipForVendorQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useCompanyVendorPartnershipForVendorQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyVendorPartnershipForVendorQuery,
    CompanyVendorPartnershipForVendorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyVendorPartnershipForVendorQuery,
    CompanyVendorPartnershipForVendorQueryVariables
  >(CompanyVendorPartnershipForVendorDocument, options);
}
export function useCompanyVendorPartnershipForVendorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyVendorPartnershipForVendorQuery,
    CompanyVendorPartnershipForVendorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyVendorPartnershipForVendorQuery,
    CompanyVendorPartnershipForVendorQueryVariables
  >(CompanyVendorPartnershipForVendorDocument, options);
}
export type CompanyVendorPartnershipForVendorQueryHookResult = ReturnType<
  typeof useCompanyVendorPartnershipForVendorQuery
>;
export type CompanyVendorPartnershipForVendorLazyQueryHookResult = ReturnType<
  typeof useCompanyVendorPartnershipForVendorLazyQuery
>;
export type CompanyVendorPartnershipForVendorQueryResult = Apollo.QueryResult<
  CompanyVendorPartnershipForVendorQuery,
  CompanyVendorPartnershipForVendorQueryVariables
>;
export const CompanyVendorPartnershipForCustomerDocument = gql`
  query CompanyVendorPartnershipForCustomer($id: uuid!) {
    company_vendor_partnerships(where: { id: { _eq: $id } }) {
      id
      vendor_bank_account {
        ...BankAccountForVendor
      }
      company {
        id
        name
      }
      vendor {
        id
        name
        users(
          where: {
            _or: [
              { is_deleted: { _is_null: true } }
              { is_deleted: { _eq: false } }
            ]
          }
        ) {
          id
          ...User
        }
      }
      vendor_contacts {
        id
        is_active
        user {
          id
          ...User
        }
      }
    }
  }
  ${BankAccountForVendorFragmentDoc}
  ${UserFragmentDoc}
`;

/**
 * __useCompanyVendorPartnershipForCustomerQuery__
 *
 * To run a query within a React component, call `useCompanyVendorPartnershipForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyVendorPartnershipForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyVendorPartnershipForCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyVendorPartnershipForCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyVendorPartnershipForCustomerQuery,
    CompanyVendorPartnershipForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyVendorPartnershipForCustomerQuery,
    CompanyVendorPartnershipForCustomerQueryVariables
  >(CompanyVendorPartnershipForCustomerDocument, options);
}
export function useCompanyVendorPartnershipForCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyVendorPartnershipForCustomerQuery,
    CompanyVendorPartnershipForCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyVendorPartnershipForCustomerQuery,
    CompanyVendorPartnershipForCustomerQueryVariables
  >(CompanyVendorPartnershipForCustomerDocument, options);
}
export type CompanyVendorPartnershipForCustomerQueryHookResult = ReturnType<
  typeof useCompanyVendorPartnershipForCustomerQuery
>;
export type CompanyVendorPartnershipForCustomerLazyQueryHookResult = ReturnType<
  typeof useCompanyVendorPartnershipForCustomerLazyQuery
>;
export type CompanyVendorPartnershipForCustomerQueryResult = Apollo.QueryResult<
  CompanyVendorPartnershipForCustomerQuery,
  CompanyVendorPartnershipForCustomerQueryVariables
>;
export const GetVendorPartnershipForContactsForCustomersDocument = gql`
  query GetVendorPartnershipForContactsForCustomers($id: uuid!) {
    company_vendor_contacts(
      where: {
        _and: [
          { partnership_id: { _eq: $id } }
          {
            _or: [
              { user: { is_deleted: { _is_null: true } } }
              { user: { is_deleted: { _eq: false } } }
            ]
          }
        ]
      }
    ) {
      id
      vendor_user_id
      is_active
      user {
        id
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useGetVendorPartnershipForContactsForCustomersQuery__
 *
 * To run a query within a React component, call `useGetVendorPartnershipForContactsForCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorPartnershipForContactsForCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorPartnershipForContactsForCustomersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVendorPartnershipForContactsForCustomersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVendorPartnershipForContactsForCustomersQuery,
    GetVendorPartnershipForContactsForCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVendorPartnershipForContactsForCustomersQuery,
    GetVendorPartnershipForContactsForCustomersQueryVariables
  >(GetVendorPartnershipForContactsForCustomersDocument, options);
}
export function useGetVendorPartnershipForContactsForCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVendorPartnershipForContactsForCustomersQuery,
    GetVendorPartnershipForContactsForCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVendorPartnershipForContactsForCustomersQuery,
    GetVendorPartnershipForContactsForCustomersQueryVariables
  >(GetVendorPartnershipForContactsForCustomersDocument, options);
}
export type GetVendorPartnershipForContactsForCustomersQueryHookResult =
  ReturnType<typeof useGetVendorPartnershipForContactsForCustomersQuery>;
export type GetVendorPartnershipForContactsForCustomersLazyQueryHookResult =
  ReturnType<typeof useGetVendorPartnershipForContactsForCustomersLazyQuery>;
export type GetVendorPartnershipForContactsForCustomersQueryResult =
  Apollo.QueryResult<
    GetVendorPartnershipForContactsForCustomersQuery,
    GetVendorPartnershipForContactsForCustomersQueryVariables
  >;
export const GetCustomersWithMetadataDocument = gql`
  query GetCustomersWithMetadata($date: date) {
    customers: companies(
      where: { is_customer: { _eq: true } }
      order_by: { name: asc }
    ) {
      ...CustomersWithMetadata
    }
  }
  ${CustomersWithMetadataFragmentDoc}
`;

/**
 * __useGetCustomersWithMetadataQuery__
 *
 * To run a query within a React component, call `useGetCustomersWithMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersWithMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersWithMetadataQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetCustomersWithMetadataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomersWithMetadataQuery,
    GetCustomersWithMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomersWithMetadataQuery,
    GetCustomersWithMetadataQueryVariables
  >(GetCustomersWithMetadataDocument, options);
}
export function useGetCustomersWithMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomersWithMetadataQuery,
    GetCustomersWithMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomersWithMetadataQuery,
    GetCustomersWithMetadataQueryVariables
  >(GetCustomersWithMetadataDocument, options);
}
export type GetCustomersWithMetadataQueryHookResult = ReturnType<
  typeof useGetCustomersWithMetadataQuery
>;
export type GetCustomersWithMetadataLazyQueryHookResult = ReturnType<
  typeof useGetCustomersWithMetadataLazyQuery
>;
export type GetCustomersWithMetadataQueryResult = Apollo.QueryResult<
  GetCustomersWithMetadataQuery,
  GetCustomersWithMetadataQueryVariables
>;
export const GetActiveCustomersWithMetadataDocument = gql`
  query GetActiveCustomersWithMetadata($date: date) {
    customers: companies(
      where: {
        _and: [
          { is_customer: { _eq: true } }
          {
            _or: [
              { settings: { is_dummy_account: { _is_null: true } } }
              { settings: { is_dummy_account: { _eq: false } } }
            ]
          }
          { contract: { product_type: { _is_null: false } } }
        ]
      }
      order_by: { name: asc }
    ) {
      ...CustomersWithMetadata
    }
  }
  ${CustomersWithMetadataFragmentDoc}
`;

/**
 * __useGetActiveCustomersWithMetadataQuery__
 *
 * To run a query within a React component, call `useGetActiveCustomersWithMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveCustomersWithMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveCustomersWithMetadataQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetActiveCustomersWithMetadataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveCustomersWithMetadataQuery,
    GetActiveCustomersWithMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveCustomersWithMetadataQuery,
    GetActiveCustomersWithMetadataQueryVariables
  >(GetActiveCustomersWithMetadataDocument, options);
}
export function useGetActiveCustomersWithMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveCustomersWithMetadataQuery,
    GetActiveCustomersWithMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveCustomersWithMetadataQuery,
    GetActiveCustomersWithMetadataQueryVariables
  >(GetActiveCustomersWithMetadataDocument, options);
}
export type GetActiveCustomersWithMetadataQueryHookResult = ReturnType<
  typeof useGetActiveCustomersWithMetadataQuery
>;
export type GetActiveCustomersWithMetadataLazyQueryHookResult = ReturnType<
  typeof useGetActiveCustomersWithMetadataLazyQuery
>;
export type GetActiveCustomersWithMetadataQueryResult = Apollo.QueryResult<
  GetActiveCustomersWithMetadataQuery,
  GetActiveCustomersWithMetadataQueryVariables
>;
export const GetCustomersForDropdownDocument = gql`
  query GetCustomersForDropdown {
    customers: companies(
      where: { is_customer: { _eq: true } }
      order_by: { name: asc }
    ) {
      id
      ...CustomerForBank
    }
  }
  ${CustomerForBankFragmentDoc}
`;

/**
 * __useGetCustomersForDropdownQuery__
 *
 * To run a query within a React component, call `useGetCustomersForDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersForDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersForDropdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomersForDropdownQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomersForDropdownQuery,
    GetCustomersForDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomersForDropdownQuery,
    GetCustomersForDropdownQueryVariables
  >(GetCustomersForDropdownDocument, options);
}
export function useGetCustomersForDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomersForDropdownQuery,
    GetCustomersForDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomersForDropdownQuery,
    GetCustomersForDropdownQueryVariables
  >(GetCustomersForDropdownDocument, options);
}
export type GetCustomersForDropdownQueryHookResult = ReturnType<
  typeof useGetCustomersForDropdownQuery
>;
export type GetCustomersForDropdownLazyQueryHookResult = ReturnType<
  typeof useGetCustomersForDropdownLazyQuery
>;
export type GetCustomersForDropdownQueryResult = Apollo.QueryResult<
  GetCustomersForDropdownQuery,
  GetCustomersForDropdownQueryVariables
>;
export const GetActiveCustomersForDropdownDocument = gql`
  query GetActiveCustomersForDropdown {
    customers: companies(
      where: {
        _and: [
          { is_customer: { _eq: true } }
          {
            _or: [
              { settings: { is_dummy_account: { _is_null: true } } }
              { settings: { is_dummy_account: { _eq: false } } }
            ]
          }
          { contract: { product_type: { _is_null: false } } }
        ]
      }
      order_by: { name: asc }
    ) {
      id
      ...CustomerForBank
    }
  }
  ${CustomerForBankFragmentDoc}
`;

/**
 * __useGetActiveCustomersForDropdownQuery__
 *
 * To run a query within a React component, call `useGetActiveCustomersForDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveCustomersForDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveCustomersForDropdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveCustomersForDropdownQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveCustomersForDropdownQuery,
    GetActiveCustomersForDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveCustomersForDropdownQuery,
    GetActiveCustomersForDropdownQueryVariables
  >(GetActiveCustomersForDropdownDocument, options);
}
export function useGetActiveCustomersForDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveCustomersForDropdownQuery,
    GetActiveCustomersForDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveCustomersForDropdownQuery,
    GetActiveCustomersForDropdownQueryVariables
  >(GetActiveCustomersForDropdownDocument, options);
}
export type GetActiveCustomersForDropdownQueryHookResult = ReturnType<
  typeof useGetActiveCustomersForDropdownQuery
>;
export type GetActiveCustomersForDropdownLazyQueryHookResult = ReturnType<
  typeof useGetActiveCustomersForDropdownLazyQuery
>;
export type GetActiveCustomersForDropdownQueryResult = Apollo.QueryResult<
  GetActiveCustomersForDropdownQuery,
  GetActiveCustomersForDropdownQueryVariables
>;
export const CompanyVendorsDocument = gql`
  query CompanyVendors($companyId: uuid!) {
    company_vendor_partnerships(where: { company_id: { _eq: $companyId } }) {
      vendor {
        name
      }
    }
  }
`;

/**
 * __useCompanyVendorsQuery__
 *
 * To run a query within a React component, call `useCompanyVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyVendorsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyVendorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyVendorsQuery,
    CompanyVendorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyVendorsQuery, CompanyVendorsQueryVariables>(
    CompanyVendorsDocument,
    options
  );
}
export function useCompanyVendorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyVendorsQuery,
    CompanyVendorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyVendorsQuery, CompanyVendorsQueryVariables>(
    CompanyVendorsDocument,
    options
  );
}
export type CompanyVendorsQueryHookResult = ReturnType<
  typeof useCompanyVendorsQuery
>;
export type CompanyVendorsLazyQueryHookResult = ReturnType<
  typeof useCompanyVendorsLazyQuery
>;
export type CompanyVendorsQueryResult = Apollo.QueryResult<
  CompanyVendorsQuery,
  CompanyVendorsQueryVariables
>;
export const GetCompaniesWithMetadataDocument = gql`
  query GetCompaniesWithMetadata {
    companies(order_by: { name: asc }) {
      id
      ...Company
      contract {
        id
        ...Contract
      }
      settings {
        id
        ...CompanySettings
      }
    }
  }
  ${CompanyFragmentDoc}
  ${ContractFragmentDoc}
  ${CompanySettingsFragmentDoc}
`;

/**
 * __useGetCompaniesWithMetadataQuery__
 *
 * To run a query within a React component, call `useGetCompaniesWithMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesWithMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesWithMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompaniesWithMetadataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompaniesWithMetadataQuery,
    GetCompaniesWithMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompaniesWithMetadataQuery,
    GetCompaniesWithMetadataQueryVariables
  >(GetCompaniesWithMetadataDocument, options);
}
export function useGetCompaniesWithMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompaniesWithMetadataQuery,
    GetCompaniesWithMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompaniesWithMetadataQuery,
    GetCompaniesWithMetadataQueryVariables
  >(GetCompaniesWithMetadataDocument, options);
}
export type GetCompaniesWithMetadataQueryHookResult = ReturnType<
  typeof useGetCompaniesWithMetadataQuery
>;
export type GetCompaniesWithMetadataLazyQueryHookResult = ReturnType<
  typeof useGetCompaniesWithMetadataLazyQuery
>;
export type GetCompaniesWithMetadataQueryResult = Apollo.QueryResult<
  GetCompaniesWithMetadataQuery,
  GetCompaniesWithMetadataQueryVariables
>;
export const GetPartnershipRequestsCountForBankDocument = gql`
  subscription GetPartnershipRequestsCountForBank {
    company_partnership_requests(
      where: {
        settled_at: { _is_null: true }
        _or: [
          { is_deleted: { _is_null: true } }
          { is_deleted: { _eq: false } }
        ]
      }
    ) {
      id
    }
  }
`;

/**
 * __useGetPartnershipRequestsCountForBankSubscription__
 *
 * To run a query within a React component, call `useGetPartnershipRequestsCountForBankSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnershipRequestsCountForBankSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnershipRequestsCountForBankSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetPartnershipRequestsCountForBankSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetPartnershipRequestsCountForBankSubscription,
    GetPartnershipRequestsCountForBankSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetPartnershipRequestsCountForBankSubscription,
    GetPartnershipRequestsCountForBankSubscriptionVariables
  >(GetPartnershipRequestsCountForBankDocument, options);
}
export type GetPartnershipRequestsCountForBankSubscriptionHookResult =
  ReturnType<typeof useGetPartnershipRequestsCountForBankSubscription>;
export type GetPartnershipRequestsCountForBankSubscriptionResult =
  Apollo.SubscriptionResult<GetPartnershipRequestsCountForBankSubscription>;
export const GetSettledPartnershipRequestsForBankDocument = gql`
  subscription GetSettledPartnershipRequestsForBank {
    company_partnership_requests(
      order_by: [{ created_at: asc }]
      where: {
        settled_at: { _is_null: false }
        _or: [
          { is_deleted: { _is_null: true } }
          { is_deleted: { _eq: false } }
        ]
      }
    ) {
      id
      ...PartnershipRequest
    }
  }
  ${PartnershipRequestFragmentDoc}
`;

/**
 * __useGetSettledPartnershipRequestsForBankSubscription__
 *
 * To run a query within a React component, call `useGetSettledPartnershipRequestsForBankSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetSettledPartnershipRequestsForBankSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettledPartnershipRequestsForBankSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetSettledPartnershipRequestsForBankSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetSettledPartnershipRequestsForBankSubscription,
    GetSettledPartnershipRequestsForBankSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetSettledPartnershipRequestsForBankSubscription,
    GetSettledPartnershipRequestsForBankSubscriptionVariables
  >(GetSettledPartnershipRequestsForBankDocument, options);
}
export type GetSettledPartnershipRequestsForBankSubscriptionHookResult =
  ReturnType<typeof useGetSettledPartnershipRequestsForBankSubscription>;
export type GetSettledPartnershipRequestsForBankSubscriptionResult =
  Apollo.SubscriptionResult<GetSettledPartnershipRequestsForBankSubscription>;
export const GetPartnershipRequestsForBankDocument = gql`
  subscription GetPartnershipRequestsForBank {
    company_partnership_requests(
      order_by: [{ created_at: asc }]
      where: {
        settled_at: { _is_null: true }
        _or: [
          { is_deleted: { _is_null: true } }
          { is_deleted: { _eq: false } }
        ]
      }
    ) {
      id
      ...PartnershipRequest
    }
  }
  ${PartnershipRequestFragmentDoc}
`;

/**
 * __useGetPartnershipRequestsForBankSubscription__
 *
 * To run a query within a React component, call `useGetPartnershipRequestsForBankSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnershipRequestsForBankSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnershipRequestsForBankSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetPartnershipRequestsForBankSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetPartnershipRequestsForBankSubscription,
    GetPartnershipRequestsForBankSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetPartnershipRequestsForBankSubscription,
    GetPartnershipRequestsForBankSubscriptionVariables
  >(GetPartnershipRequestsForBankDocument, options);
}
export type GetPartnershipRequestsForBankSubscriptionHookResult = ReturnType<
  typeof useGetPartnershipRequestsForBankSubscription
>;
export type GetPartnershipRequestsForBankSubscriptionResult =
  Apollo.SubscriptionResult<GetPartnershipRequestsForBankSubscription>;
export const GetPartnershipRequestsForBankByRequestingCompanyIdAndTypeDocument = gql`
  subscription GetPartnershipRequestsForBankByRequestingCompanyIdAndType(
    $requesting_company_id: uuid!
    $company_type: company_type_enum!
  ) {
    company_partnership_requests(
      order_by: [{ created_at: asc }]
      where: {
        requesting_company_id: { _eq: $requesting_company_id }
        settled_at: { _is_null: true }
        _or: [
          { is_deleted: { _is_null: true } }
          { is_deleted: { _eq: false } }
        ]
        company_type: { _eq: $company_type }
      }
    ) {
      id
      ...PartnershipRequest
    }
  }
  ${PartnershipRequestFragmentDoc}
`;

/**
 * __useGetPartnershipRequestsForBankByRequestingCompanyIdAndTypeSubscription__
 *
 * To run a query within a React component, call `useGetPartnershipRequestsForBankByRequestingCompanyIdAndTypeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnershipRequestsForBankByRequestingCompanyIdAndTypeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnershipRequestsForBankByRequestingCompanyIdAndTypeSubscription({
 *   variables: {
 *      requesting_company_id: // value for 'requesting_company_id'
 *      company_type: // value for 'company_type'
 *   },
 * });
 */
export function useGetPartnershipRequestsForBankByRequestingCompanyIdAndTypeSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetPartnershipRequestsForBankByRequestingCompanyIdAndTypeSubscription,
    GetPartnershipRequestsForBankByRequestingCompanyIdAndTypeSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetPartnershipRequestsForBankByRequestingCompanyIdAndTypeSubscription,
    GetPartnershipRequestsForBankByRequestingCompanyIdAndTypeSubscriptionVariables
  >(GetPartnershipRequestsForBankByRequestingCompanyIdAndTypeDocument, options);
}
export type GetPartnershipRequestsForBankByRequestingCompanyIdAndTypeSubscriptionHookResult =
  ReturnType<
    typeof useGetPartnershipRequestsForBankByRequestingCompanyIdAndTypeSubscription
  >;
export type GetPartnershipRequestsForBankByRequestingCompanyIdAndTypeSubscriptionResult =
  Apollo.SubscriptionResult<GetPartnershipRequestsForBankByRequestingCompanyIdAndTypeSubscription>;
export const GetPartnershipInvitationsForBankDocument = gql`
  subscription GetPartnershipInvitationsForBank {
    company_partnership_invitations(
      where: { deleted_at: { _is_null: true }, closed_at: { _is_null: true } }
      order_by: [{ created_at: asc }]
    ) {
      id
      ...PartnershipInvitation
    }
  }
  ${PartnershipInvitationFragmentDoc}
`;

/**
 * __useGetPartnershipInvitationsForBankSubscription__
 *
 * To run a query within a React component, call `useGetPartnershipInvitationsForBankSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnershipInvitationsForBankSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnershipInvitationsForBankSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetPartnershipInvitationsForBankSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetPartnershipInvitationsForBankSubscription,
    GetPartnershipInvitationsForBankSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetPartnershipInvitationsForBankSubscription,
    GetPartnershipInvitationsForBankSubscriptionVariables
  >(GetPartnershipInvitationsForBankDocument, options);
}
export type GetPartnershipInvitationsForBankSubscriptionHookResult = ReturnType<
  typeof useGetPartnershipInvitationsForBankSubscription
>;
export type GetPartnershipInvitationsForBankSubscriptionResult =
  Apollo.SubscriptionResult<GetPartnershipInvitationsForBankSubscription>;
export const GetPartnershipRequestsAndInvitesByRequestingCompanyIdDocument = gql`
  query GetPartnershipRequestsAndInvitesByRequestingCompanyId(
    $requesting_company_id: uuid!
  ) {
    company_partnership_requests(
      order_by: [{ created_at: asc }]
      where: {
        requesting_company_id: { _eq: $requesting_company_id }
        settled_at: { _is_null: true }
        _or: [
          { is_deleted: { _is_null: true } }
          { is_deleted: { _eq: false } }
        ]
      }
    ) {
      id
      company_name
      requested_by_user {
        id
        full_name
      }
      user_info
      license_info
      created_at
    }
    company_partnership_invitations(
      order_by: [{ created_at: asc }]
      where: {
        requesting_company_id: { _eq: $requesting_company_id }
        closed_at: { _is_null: true }
        deleted_at: { _is_null: true }
      }
    ) {
      id
      email
      metadata_info
      requested_at
      submitted_by_user {
        id
        full_name
      }
    }
  }
`;

/**
 * __useGetPartnershipRequestsAndInvitesByRequestingCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetPartnershipRequestsAndInvitesByRequestingCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnershipRequestsAndInvitesByRequestingCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnershipRequestsAndInvitesByRequestingCompanyIdQuery({
 *   variables: {
 *      requesting_company_id: // value for 'requesting_company_id'
 *   },
 * });
 */
export function useGetPartnershipRequestsAndInvitesByRequestingCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPartnershipRequestsAndInvitesByRequestingCompanyIdQuery,
    GetPartnershipRequestsAndInvitesByRequestingCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPartnershipRequestsAndInvitesByRequestingCompanyIdQuery,
    GetPartnershipRequestsAndInvitesByRequestingCompanyIdQueryVariables
  >(GetPartnershipRequestsAndInvitesByRequestingCompanyIdDocument, options);
}
export function useGetPartnershipRequestsAndInvitesByRequestingCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartnershipRequestsAndInvitesByRequestingCompanyIdQuery,
    GetPartnershipRequestsAndInvitesByRequestingCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPartnershipRequestsAndInvitesByRequestingCompanyIdQuery,
    GetPartnershipRequestsAndInvitesByRequestingCompanyIdQueryVariables
  >(GetPartnershipRequestsAndInvitesByRequestingCompanyIdDocument, options);
}
export type GetPartnershipRequestsAndInvitesByRequestingCompanyIdQueryHookResult =
  ReturnType<
    typeof useGetPartnershipRequestsAndInvitesByRequestingCompanyIdQuery
  >;
export type GetPartnershipRequestsAndInvitesByRequestingCompanyIdLazyQueryHookResult =
  ReturnType<
    typeof useGetPartnershipRequestsAndInvitesByRequestingCompanyIdLazyQuery
  >;
export type GetPartnershipRequestsAndInvitesByRequestingCompanyIdQueryResult =
  Apollo.QueryResult<
    GetPartnershipRequestsAndInvitesByRequestingCompanyIdQuery,
    GetPartnershipRequestsAndInvitesByRequestingCompanyIdQueryVariables
  >;
export const GetPartnershipChangeRequestsForBankDocument = gql`
  subscription GetPartnershipChangeRequestsForBank {
    vendor_change_requests(
      order_by: [{ created_at: asc }]
      where: {
        _or: [
          { is_deleted: { _is_null: true } }
          { is_deleted: { _eq: false } }
        ]
      }
    ) {
      ...VendorChangeRequest
      approved_by_user {
        id
        full_name
      }
      reviewed_by_user {
        id
        full_name
      }
      requesting_user {
        id
        full_name
        company {
          id
          name
        }
      }
      requested_vendor {
        id
        name
      }
      requesting_company {
        id
        name
      }
    }
  }
  ${VendorChangeRequestFragmentDoc}
`;

/**
 * __useGetPartnershipChangeRequestsForBankSubscription__
 *
 * To run a query within a React component, call `useGetPartnershipChangeRequestsForBankSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnershipChangeRequestsForBankSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnershipChangeRequestsForBankSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetPartnershipChangeRequestsForBankSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetPartnershipChangeRequestsForBankSubscription,
    GetPartnershipChangeRequestsForBankSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetPartnershipChangeRequestsForBankSubscription,
    GetPartnershipChangeRequestsForBankSubscriptionVariables
  >(GetPartnershipChangeRequestsForBankDocument, options);
}
export type GetPartnershipChangeRequestsForBankSubscriptionHookResult =
  ReturnType<typeof useGetPartnershipChangeRequestsForBankSubscription>;
export type GetPartnershipChangeRequestsForBankSubscriptionResult =
  Apollo.SubscriptionResult<GetPartnershipChangeRequestsForBankSubscription>;
export const GetPartnershipChangeDetailsByIdDocument = gql`
  query GetPartnershipChangeDetailsById($requestId: uuid!) {
    vendor_change_requests_by_pk(id: $requestId) {
      ...VendorChangeRequest
      approved_by_user {
        id
        full_name
      }
      reviewed_by_user {
        id
        full_name
      }
      requesting_user {
        id
        full_name
        company {
          id
        }
      }
      requested_vendor {
        id
        name
        users {
          id
          ...User
        }
        company_vendor_partnerships_by_vendor {
          id
          company {
            id
            name
          }
        }
      }
      requesting_company {
        id
        name
      }
    }
  }
  ${VendorChangeRequestFragmentDoc}
  ${UserFragmentDoc}
`;

/**
 * __useGetPartnershipChangeDetailsByIdQuery__
 *
 * To run a query within a React component, call `useGetPartnershipChangeDetailsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnershipChangeDetailsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnershipChangeDetailsByIdQuery({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useGetPartnershipChangeDetailsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPartnershipChangeDetailsByIdQuery,
    GetPartnershipChangeDetailsByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPartnershipChangeDetailsByIdQuery,
    GetPartnershipChangeDetailsByIdQueryVariables
  >(GetPartnershipChangeDetailsByIdDocument, options);
}
export function useGetPartnershipChangeDetailsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartnershipChangeDetailsByIdQuery,
    GetPartnershipChangeDetailsByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPartnershipChangeDetailsByIdQuery,
    GetPartnershipChangeDetailsByIdQueryVariables
  >(GetPartnershipChangeDetailsByIdDocument, options);
}
export type GetPartnershipChangeDetailsByIdQueryHookResult = ReturnType<
  typeof useGetPartnershipChangeDetailsByIdQuery
>;
export type GetPartnershipChangeDetailsByIdLazyQueryHookResult = ReturnType<
  typeof useGetPartnershipChangeDetailsByIdLazyQuery
>;
export type GetPartnershipChangeDetailsByIdQueryResult = Apollo.QueryResult<
  GetPartnershipChangeDetailsByIdQuery,
  GetPartnershipChangeDetailsByIdQueryVariables
>;
export const GetUserByIdDocument = gql`
  query GetUserById($id: uuid!) {
    users_by_pk(id: $id) {
      id
      ...User
      company {
        id
        name
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserByIdQuery,
    GetUserByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
    GetUserByIdDocument,
    options
  );
}
export function useGetUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserByIdQuery,
    GetUserByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
    GetUserByIdDocument,
    options
  );
}
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<
  typeof useGetUserByIdLazyQuery
>;
export type GetUserByIdQueryResult = Apollo.QueryResult<
  GetUserByIdQuery,
  GetUserByIdQueryVariables
>;
export const GetUserMenuInfoDocument = gql`
  query GetUserMenuInfo($user_id: uuid!) {
    users_by_pk(id: $user_id) {
      id
      ...User
      parent_company {
        id
        name
        customer_companies: companies(
          where: { is_customer: { _eq: true } }
          order_by: { name: asc }
        ) {
          id
          name
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useGetUserMenuInfoQuery__
 *
 * To run a query within a React component, call `useGetUserMenuInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMenuInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMenuInfoQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserMenuInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserMenuInfoQuery,
    GetUserMenuInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserMenuInfoQuery, GetUserMenuInfoQueryVariables>(
    GetUserMenuInfoDocument,
    options
  );
}
export function useGetUserMenuInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserMenuInfoQuery,
    GetUserMenuInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserMenuInfoQuery,
    GetUserMenuInfoQueryVariables
  >(GetUserMenuInfoDocument, options);
}
export type GetUserMenuInfoQueryHookResult = ReturnType<
  typeof useGetUserMenuInfoQuery
>;
export type GetUserMenuInfoLazyQueryHookResult = ReturnType<
  typeof useGetUserMenuInfoLazyQuery
>;
export type GetUserMenuInfoQueryResult = Apollo.QueryResult<
  GetUserMenuInfoQuery,
  GetUserMenuInfoQueryVariables
>;
export const UsersByEmailDocument = gql`
  query UsersByEmail($email: String!) {
    users(where: { email: { _eq: $email } }) {
      id
      company_id
      role
    }
  }
`;

/**
 * __useUsersByEmailQuery__
 *
 * To run a query within a React component, call `useUsersByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUsersByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersByEmailQuery,
    UsersByEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersByEmailQuery, UsersByEmailQueryVariables>(
    UsersByEmailDocument,
    options
  );
}
export function useUsersByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersByEmailQuery,
    UsersByEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersByEmailQuery, UsersByEmailQueryVariables>(
    UsersByEmailDocument,
    options
  );
}
export type UsersByEmailQueryHookResult = ReturnType<
  typeof useUsersByEmailQuery
>;
export type UsersByEmailLazyQueryHookResult = ReturnType<
  typeof useUsersByEmailLazyQuery
>;
export type UsersByEmailQueryResult = Apollo.QueryResult<
  UsersByEmailQuery,
  UsersByEmailQueryVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($id: uuid!, $user: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $user) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
