import Page from "components/Shared/Page";

const VendorOverviewPage = () => {
  return (
    <Page appBarTitle={"Overview"}>
      <div>hello world</div>
    </Page>
  );
};

export default VendorOverviewPage;
